export default {
  results: [
    {
      "title": "Кроссовки спортивные",
      "cover": '/icons/sneakers.jpg',
      "sku": "19426870",
      "price": 637,
      "period_analysis": 30,
      "average_rate": "4.7",
      "feedbacks_count": 40207,
      "feedbacks_photo": 5352,
      "feedbacks_stats": {
        "1": [
          {
            "word": "другая",
            "count": 2,
            "phrase": "машинка пришла совсем другая"
          },
          {
            "word": "сбоку",
            "count": 1,
            "phrase": "еще кнопка включения сбоку"
          },
          {
            "word": "проверяют",
            "count": 2,
            "phrase": "как они проверяют"
          },
          {
            "word": "разъема",
            "count": 1,
            "phrase": "нет разъема для зарядки"
          },
          {
            "word": "разрядилась(хотя",
            "count": 1,
            "phrase": "я думала разрядилась(хотя я ее заряжала около 1,5 часов"
          },
          {
            "word": "видела",
            "count": 3,
            "phrase": "подобные отзывы видела ниже"
          },
          {
            "word": "обходило",
            "count": 1,
            "phrase": "всегда меня обходило стороной все неработающее и бракованное"
          },
          {
            "word": "стороной",
            "count": 1,
            "phrase": "всегда меня обходило стороной все неработающее и бракованное"
          },
          {
            "word": "неработающее",
            "count": 1,
            "phrase": "всегда меня обходило стороной все неработающее и бракованное"
          },
          {
            "word": "бракованное",
            "count": 1,
            "phrase": "всегда меня обходило стороной все неработающее и бракованное"
          },
          {
            "word": "лёгким",
            "count": 1,
            "phrase": "в пункте выдачи проверила лёгким нажатием- все ок"
          },
          {
            "word": "нажатием-",
            "count": 1,
            "phrase": "в пункте выдачи проверила лёгким нажатием- все ок"
          },
          {
            "word": "вчера",
            "count": 2,
            "phrase": "вчера забрала машинку"
          },
          {
            "word": "обшарпаная",
            "count": 1,
            "phrase": "машинка обшарпаная"
          },
          {
            "word": "расстроена",
            "count": 1,
            "phrase": "очень расстроена"
          },
          {
            "word": "любимого",
            "count": 1,
            "phrase": "почистила только перед любимого полувера"
          },
          {
            "word": "полувера",
            "count": 1,
            "phrase": "почистила только перед любимого полувера"
          },
          {
            "word": "кончилась",
            "count": 0,
            "phrase": "кончилась зарядка"
          },
          {
            "word": "потратила",
            "count": 1,
            "phrase": "кучу времени потратила"
          },
          {
            "word": "малюсенький",
            "count": 1,
            "phrase": "эффект совсем малюсенький"
          },
          {
            "word": "ужасном",
            "count": 1,
            "phrase": "машинка в ужасном состоянии"
          },
          {
            "word": "крышка",
            "count": 1,
            "phrase": "не открывается ни одна крышка"
          },
          {
            "word": "соединения",
            "count": 1,
            "phrase": "все соединения крышек с корпусом смазаны"
          },
          {
            "word": "крышек",
            "count": 1,
            "phrase": "все соединения крышек с корпусом смазаны"
          },
          {
            "word": "корпусом",
            "count": 1,
            "phrase": "все соединения крышек с корпусом смазаны"
          },
          {
            "word": "смазаны",
            "count": 1,
            "phrase": "все соединения крышек с корпусом смазаны"
          },
          {
            "word": "крашенный",
            "count": 1,
            "phrase": "корпус крашенный"
          },
          {
            "word": "трудом",
            "count": 1,
            "phrase": "с большим трудом"
          },
          {
            "word": "открывали",
            "count": 1,
            "phrase": "открывали всем чем можно"
          },
          {
            "word": "отправлять",
            "count": 2,
            "phrase": "как вообще в таком состоянии можно было отправлять товар покупателю"
          },
          {
            "word": "покупателю",
            "count": 1,
            "phrase": "как вообще в таком состоянии можно было отправлять товар покупателю"
          },
          {
            "word": "забрал",
            "count": 10,
            "phrase": "сначала забрал"
          },
          {
            "word": "увидел",
            "count": 2,
            "phrase": "а потом увидел что такие же машинки есть дешевле"
          },
          {
            "word": "повелся",
            "count": 0,
            "phrase": "повелся на бренд xiaomi"
          },
          {
            "word": "вранье",
            "count": 2,
            "phrase": "полное вранье"
          },
          {
            "word": "выдает",
            "count": 1,
            "phrase": "выдает по штрих коду наушники"
          },
          {
            "word": "штрих",
            "count": 2,
            "phrase": "выдает по штрих коду наушники"
          },
          {
            "word": "коду",
            "count": 3,
            "phrase": "выдает по штрих коду наушники"
          },
          {
            "word": "наушники",
            "count": 1,
            "phrase": "выдает по штрих коду наушники"
          },
          {
            "word": "записи",
            "count": 1,
            "phrase": "ни одной записи и лейбла xiaomi нет"
          },
          {
            "word": "лейбла",
            "count": 1,
            "phrase": "ни одной записи и лейбла xiaomi нет"
          },
          {
            "word": "коде",
            "count": 1,
            "phrase": "кроме как на штрих коде"
          },
          {
            "word": "непонятном",
            "count": 1,
            "phrase": "тоже непонятном"
          },
          {
            "word": "китайцы",
            "count": 0,
            "phrase": "китайцы дешевле делают"
          },
          {
            "word": "делают",
            "count": 1,
            "phrase": "китайцы дешевле делают"
          },
          {
            "word": "прислали",
            "count": 1,
            "phrase": "прислали совсем не то"
          },
          {
            "word": "поддержка",
            "count": 1,
            "phrase": "тех поддержка"
          },
          {
            "word": "оклоняет",
            "count": 1,
            "phrase": "оклоняет заявки на возврат"
          },
          {
            "word": "отличного",
            "count": 1,
            "phrase": "три отличного качества"
          },
          {
            "word": "беда",
            "count": 1,
            "phrase": "а с этим беда"
          },
          {
            "word": "хотят",
            "count": 2,
            "phrase": "деньги не хотят возвращать"
          },
          {
            "word": "удалитель",
            "count": 1,
            "phrase": "для кого это будет не первый удалитель катышков"
          },
          {
            "word": "всему",
            "count": 4,
            "phrase": "судя по всему"
          },
          {
            "word": "покупавших",
            "count": 1,
            "phrase": "от людей никогда не покупавших такое устройство"
          },
          {
            "word": "бормашина",
            "count": 1,
            "phrase": "и шумит как бормашина в кабинете стоматолога"
          },
          {
            "word": "стоматолога",
            "count": 1,
            "phrase": "и шумит как бормашина в кабинете стоматолога"
          },
          {
            "word": "отвратительно",
            "count": 2,
            "phrase": "бреет он просто отвратительно"
          },
          {
            "word": "типа",
            "count": 1,
            "phrase": "его аналоги типа ves и тд"
          },
          {
            "word": "ves",
            "count": 0,
            "phrase": "его аналоги типа ves и тд"
          },
          {
            "word": "приобрёл",
            "count": 0,
            "phrase": "приобрёл данную машинку в основном из - за аккумулятора"
          },
          {
            "word": "300",
            "count": 5,
            "phrase": "за 200 - 300 рублей имеют кпд в разы больше"
          },
          {
            "word": "имеют",
            "count": 1,
            "phrase": "за 200 - 300 рублей имеют кпд в разы больше"
          },
          {
            "word": "кпд",
            "count": 0,
            "phrase": "за 200 - 300 рублей имеют кпд в разы больше"
          },
          {
            "word": "брак",
            "count": 7,
            "phrase": "машинка мало того что брак"
          },
          {
            "word": "побили",
            "count": 1,
            "phrase": "так ее еще побили в пути"
          },
          {
            "word": "пути",
            "count": 1,
            "phrase": "так ее еще побили в пути"
          },
          {
            "word": "щели",
            "count": 1,
            "phrase": "щели между машинкой"
          },
          {
            "word": "пластикого",
            "count": 1,
            "phrase": "кусок пластикого дерьма проработал час"
          },
          {
            "word": "дерьма",
            "count": 1,
            "phrase": "кусок пластикого дерьма проработал час"
          },
          {
            "word": "проработал",
            "count": 4,
            "phrase": "кусок пластикого дерьма проработал час"
          },
          {
            "word": "радостях",
            "count": 1,
            "phrase": "на радостях распаковала"
          },
          {
            "word": "распаковала",
            "count": 1,
            "phrase": "на радостях распаковала"
          },
          {
            "word": "попользовалась",
            "count": 1,
            "phrase": "попользовалась и все"
          },
          {
            "word": "сдохло",
            "count": 1,
            "phrase": "оно сдохло"
          },
          {
            "word": "возможности",
            "count": 2,
            "phrase": "в пункте выдачи не было возможности"
          },
          {
            "word": "стоял",
            "count": 3,
            "phrase": "дома стоял на зарядке два часа"
          },
          {
            "word": "произошло",
            "count": 1,
            "phrase": "но чуда не произошло"
          },
          {
            "word": "сделали",
            "count": 1,
            "phrase": "плюс ко всему сделали товар не возвратным"
          },
          {
            "word": "возвратным",
            "count": 2,
            "phrase": "плюс ко всему сделали товар не возвратным"
          },
          {
            "word": "категория",
            "count": 1,
            "phrase": "хотя данная категория не подходит не возвратным"
          },
          {
            "word": "браку",
            "count": 3,
            "phrase": "только по браку"
          },
          {
            "word": "принимать",
            "count": 1,
            "phrase": "соответственно по браку тоже хотят принимать"
          },
          {
            "word": "оформляется",
            "count": 1,
            "phrase": "по браку оформляется через проверку товара"
          },
          {
            "word": "приход",
            "count": 12,
            "phrase": "когда он приход в таких упаковках"
          },
          {
            "word": "упаковках",
            "count": 1,
            "phrase": "когда он приход в таких упаковках"
          },
          {
            "word": "работающий",
            "count": 1,
            "phrase": "не советую данного продавца товар пришёл не работающий"
          },
          {
            "word": "ужас",
            "count": 13,
            "phrase": "это просто ужас"
          },
          {
            "word": "пластмасску",
            "count": 1,
            "phrase": "вместо машинки положили какую то пластмасску"
          },
          {
            "word": "вернули",
            "count": 1,
            "phrase": "деньги мне так и не вернули"
          },
          {
            "word": "прошу",
            "count": 0,
            "phrase": "прошу вернуть денежные средства за обратную доставку товара"
          },
          {
            "word": "денежные",
            "count": 1,
            "phrase": "прошу вернуть денежные средства за обратную доставку товара"
          },
          {
            "word": "средства",
            "count": 1,
            "phrase": "прошу вернуть денежные средства за обратную доставку товара"
          },
          {
            "word": "обратную",
            "count": 1,
            "phrase": "прошу вернуть денежные средства за обратную доставку товара"
          },
          {
            "word": "размере",
            "count": 1,
            "phrase": "в размере 100 рублей"
          },
          {
            "word": "бракованный",
            "count": 1,
            "phrase": "прислали бракованный"
          },
          {
            "word": "оплачен",
            "count": 1,
            "phrase": "товар был уже оплачен"
          },
          {
            "word": "получении",
            "count": 1,
            "phrase": "мои его получении меня сразу смутила упаковка"
          },
          {
            "word": "тихом",
            "count": 1,
            "phrase": "решила открыть и была в тихом ужасе"
          },
          {
            "word": "ужасе",
            "count": 1,
            "phrase": "решила открыть и была в тихом ужасе"
          },
          {
            "word": "отказал",
            "count": 2,
            "phrase": "отказал мне сотрудник в возврате товара"
          },
          {
            "word": "сотрудник",
            "count": 2,
            "phrase": "отказал мне сотрудник в возврате товара"
          },
          {
            "word": "возврате",
            "count": 1,
            "phrase": "отказал мне сотрудник в возврате товара"
          },
          {
            "word": "сказал",
            "count": 4,
            "phrase": "сказал невозвратный"
          },
          {
            "word": "невозвратный",
            "count": 1,
            "phrase": "сказал невозвратный"
          },
          {
            "word": "приложении",
            "count": 1,
            "phrase": "в приложении"
          },
          {
            "word": "заявку",
            "count": 2,
            "phrase": "заявку рассматривали больше двух недель- тишина"
          },
          {
            "word": "рассматривали",
            "count": 1,
            "phrase": "заявку рассматривали больше двух недель- тишина"
          },
          {
            "word": "недель-",
            "count": 1,
            "phrase": "заявку рассматривали больше двух недель- тишина"
          },
          {
            "word": "тишина",
            "count": 1,
            "phrase": "заявку рассматривали больше двух недель- тишина"
          },
          {
            "word": "отказывают",
            "count": 1,
            "phrase": "пишу снова и мне отказывают"
          },
          {
            "word": "царапина",
            "count": 0,
            "phrase": "царапина на корпусе"
          },
          {
            "word": "корпусе",
            "count": 2,
            "phrase": "царапина на корпусе"
          },
          {
            "word": "потрепанный",
            "count": 1,
            "phrase": "вообще вид потрепанный"
          },
          {
            "word": "использовали",
            "count": 1,
            "phrase": "уже использовали"
          },
          {
            "word": "презентабельная",
            "count": 1,
            "phrase": "коробка не презентабельная"
          },
          {
            "word": "использованный",
            "count": 1,
            "phrase": "пришел использованный товар"
          },
          {
            "word": "грязный",
            "count": 1,
            "phrase": "весь грязный"
          },
          {
            "word": "ксиоми",
            "count": 1,
            "phrase": "это нн ксиоми"
          },
          {
            "word": "эмблемы",
            "count": 1,
            "phrase": "эмблемы не совпадают"
          },
          {
            "word": "совпадают",
            "count": 1,
            "phrase": "эмблемы не совпадают"
          },
          {
            "word": "указана",
            "count": 1,
            "phrase": "на коробке указана фирма reddot"
          },
          {
            "word": "фирма",
            "count": 1,
            "phrase": "на коробке указана фирма reddot"
          },
          {
            "word": "reddot",
            "count": 2,
            "phrase": "на коробке указана фирма reddot"
          },
          {
            "word": "наклейке",
            "count": 2,
            "phrase": "а на наклейке от продавца упоминание про хесиоми"
          },
          {
            "word": "упоминание",
            "count": 1,
            "phrase": "а на наклейке от продавца упоминание про хесиоми"
          },
          {
            "word": "хесиоми",
            "count": 1,
            "phrase": "а на наклейке от продавца упоминание про хесиоми"
          },
          {
            "word": "куар",
            "count": 1,
            "phrase": "куар код с инструкцией не читается"
          },
          {
            "word": "читается",
            "count": 1,
            "phrase": "куар код с инструкцией не читается"
          },
          {
            "word": "названии",
            "count": 1,
            "phrase": "за вранье о названии ставлю 1 звезду"
          },
          {
            "word": "иероглифами",
            "count": 1,
            "phrase": "инструкция только с иероглифами"
          },
          {
            "word": "недель",
            "count": 3,
            "phrase": "проработала она чуть больше 2х недель"
          },
          {
            "word": "учитывая",
            "count": 1,
            "phrase": "это учитывая при редком использовании"
          },
          {
            "word": "редком",
            "count": 1,
            "phrase": "это учитывая при редком использовании"
          },
          {
            "word": "безрезультатно",
            "count": 1,
            "phrase": "но безрезультатно"
          },
          {
            "word": "свяжитесь",
            "count": 0,
            "phrase": "свяжитесь со мной"
          },
          {
            "word": "решения",
            "count": 1,
            "phrase": "для решения проблемы"
          },
          {
            "word": "отключается",
            "count": 1,
            "phrase": "после включения - машинка через секунду отключается"
          },
          {
            "word": "приехал",
            "count": 1,
            "phrase": "товар приехал в безобразном виде"
          },
          {
            "word": "безобразном",
            "count": 1,
            "phrase": "товар приехал в безобразном виде"
          },
          {
            "word": "забирать",
            "count": 1,
            "phrase": "не стала забирать"
          },
          {
            "word": "удержал",
            "count": 3,
            "phrase": "wb удержал штраф за возврат товара ненадлежащего качества"
          },
          {
            "word": "штраф",
            "count": 1,
            "phrase": "wb удержал штраф за возврат товара ненадлежащего качества"
          },
          {
            "word": "ненадлежащего",
            "count": 1,
            "phrase": "wb удержал штраф за возврат товара ненадлежащего качества"
          },
          {
            "word": "технической",
            "count": 1,
            "phrase": "технической возможности для возврата"
          },
          {
            "word": "категорически",
            "count": 0,
            "phrase": "категорически не советую обращаться к данному продавцу"
          },
          {
            "word": "обращаться",
            "count": 1,
            "phrase": "категорически не советую обращаться к данному продавцу"
          },
          {
            "word": "поменять",
            "count": 1,
            "phrase": "не знаю можно ли вернуть товар или поменять на нормальный"
          },
          {
            "word": "следы",
            "count": 1,
            "phrase": "но самое ужасное внутри следы пыли от стрижки катышков"
          },
          {
            "word": "стрижки",
            "count": 1,
            "phrase": "но самое ужасное внутри следы пыли от стрижки катышков"
          },
          {
            "word": "рассматривают",
            "count": 1,
            "phrase": "еще и заявку на возврат долго рассматривают"
          },
          {
            "word": "поставщик",
            "count": 2,
            "phrase": "уважаемый поставщик"
          },
          {
            "word": "вернувшийся",
            "count": 1,
            "phrase": "вернувшийся к вам б/у"
          },
          {
            "word": "б/у",
            "count": 1,
            "phrase": "вернувшийся к вам б/у"
          },
          {
            "word": "разряжена",
            "count": 1,
            "phrase": "что она разряжена"
          },
          {
            "word": "включили",
            "count": 1,
            "phrase": "когда мы включили зарядку"
          },
          {
            "word": "светилась",
            "count": 1,
            "phrase": "лампочка светилась"
          },
          {
            "word": "пытаюсь",
            "count": 1,
            "phrase": "пытаюсь её включить"
          },
          {
            "word": "включить",
            "count": 1,
            "phrase": "пытаюсь её включить"
          },
          {
            "word": "нажимая",
            "count": 1,
            "phrase": "нажимая на кнопку"
          },
          {
            "word": "кнопку",
            "count": 1,
            "phrase": "нажимая на кнопку"
          },
          {
            "word": "происходит",
            "count": 1,
            "phrase": "а ничего не происходит"
          },
          {
            "word": "оочень",
            "count": 5,
            "phrase": "но справляется с задачей оочень плохо"
          },
          {
            "word": "начиталась",
            "count": 0,
            "phrase": "начиталась отзывов"
          },
          {
            "word": "отзывов",
            "count": 1,
            "phrase": "начиталась отзывов"
          },
          {
            "word": "ужасный",
            "count": 2,
            "phrase": "плюс ко всему - ужасный звук"
          },
          {
            "word": "слух",
            "count": 2,
            "phrase": "который очень сильно режет слух"
          }
        ],
        "2": [
          {
            "word": "отвратительной",
            "count": 1,
            "phrase": "машинка пришла в отвратительной коробке"
          },
          {
            "word": "обмотана",
            "count": 1,
            "phrase": "помятая и вся обмотана скотчем"
          },
          {
            "word": "руб",
            "count": 22,
            "phrase": "возврат 100 руб"
          },
          {
            "word": "уважение",
            "count": 1,
            "phrase": "это не уважение к покупателям в таком состоянии присылать товар"
          },
          {
            "word": "присылать",
            "count": 1,
            "phrase": "это не уважение к покупателям в таком состоянии присылать товар"
          },
          {
            "word": "отслужит",
            "count": 1,
            "phrase": "но как долго отслужит неизвестно"
          },
          {
            "word": "неизвестно",
            "count": 2,
            "phrase": "но как долго отслужит неизвестно"
          },
          {
            "word": "огорчил",
            "count": 1,
            "phrase": "вб огорчил"
          },
          {
            "word": "дико",
            "count": 1,
            "phrase": "я дико извиняюсь"
          },
          {
            "word": "извиняюсь",
            "count": 1,
            "phrase": "я дико извиняюсь"
          },
          {
            "word": "складах",
            "count": 1,
            "phrase": "но на складах коробки куда пихают"
          },
          {
            "word": "пихают",
            "count": 1,
            "phrase": "но на складах коробки куда пихают"
          },
          {
            "word": "какими",
            "count": 1,
            "phrase": "внутри устройство с какими то точками"
          },
          {
            "word": "точками",
            "count": 2,
            "phrase": "внутри устройство с какими то точками"
          },
          {
            "word": "вскрывалось",
            "count": 1,
            "phrase": "вскрывалось ранее"
          },
          {
            "word": "продаётся",
            "count": 1,
            "phrase": "как в таком виде товар продаётся - не понятно"
          },
          {
            "word": "столкнулась",
            "count": 1,
            "phrase": "я столкнулась с такой же проблемой"
          },
          {
            "word": "перестает",
            "count": 1,
            "phrase": "а минуты через 2 перестает"
          },
          {
            "word": "понесла",
            "count": 1,
            "phrase": "я понесла ее обратно на пункт выдачи"
          },
          {
            "word": "обратно",
            "count": 2,
            "phrase": "я понесла ее обратно на пункт выдачи"
          },
          {
            "word": "пункт",
            "count": 26,
            "phrase": "я понесла ее обратно на пункт выдачи"
          },
          {
            "word": "сообщили",
            "count": 1,
            "phrase": "но мне сообщили"
          },
          {
            "word": "девушка",
            "count": 2,
            "phrase": "но приятная девушка подсказала"
          },
          {
            "word": "подсказала",
            "count": 1,
            "phrase": "но приятная девушка подсказала"
          },
          {
            "word": "личном",
            "count": 4,
            "phrase": "что можно сделать проверку товара в личном кабинете"
          },
          {
            "word": "кабинете",
            "count": 2,
            "phrase": "что можно сделать проверку товара в личном кабинете"
          },
          {
            "word": "отправляете",
            "count": 0,
            "phrase": "отправляете фото и пишите"
          },
          {
            "word": "пишите",
            "count": 2,
            "phrase": "отправляете фото и пишите"
          },
          {
            "word": "одобрили",
            "count": 1,
            "phrase": "мне одобрили и от момента отправления заявки до возврата денег"
          },
          {
            "word": "отправления",
            "count": 1,
            "phrase": "мне одобрили и от момента отправления заявки до возврата денег"
          },
          {
            "word": "заявки",
            "count": 2,
            "phrase": "мне одобрили и от момента отправления заявки до возврата денег"
          },
          {
            "word": "возврата",
            "count": 2,
            "phrase": "мне одобрили и от момента отправления заявки до возврата денег"
          },
          {
            "word": "прошли",
            "count": 1,
            "phrase": "прошли сутки"
          },
          {
            "word": "сутки",
            "count": 1,
            "phrase": "прошли сутки"
          },
          {
            "word": "портят",
            "count": 1,
            "phrase": "да еще и не возврат товара портят все впечатление"
          },
          {
            "word": "включаю",
            "count": 2,
            "phrase": "включаю в розетку"
          },
          {
            "word": "гаснет",
            "count": 1,
            "phrase": "и она тут же гаснет"
          },
          {
            "word": "безуспешно",
            "count": 1,
            "phrase": "но безуспешно судя по отзывом"
          },
          {
            "word": "судя",
            "count": 2,
            "phrase": "но безуспешно судя по отзывом"
          },
          {
            "word": "отзывом",
            "count": 1,
            "phrase": "но безуспешно судя по отзывом"
          },
          {
            "word": "покупаю",
            "count": 1,
            "phrase": "покупаю подружкам в подарок и сама ей пользуюсь"
          },
          {
            "word": "подружкам",
            "count": 1,
            "phrase": "покупаю подружкам в подарок и сама ей пользуюсь"
          },
          {
            "word": "убита",
            "count": 1,
            "phrase": "правда первая была не настолько убита"
          },
          {
            "word": "вообщем",
            "count": 0,
            "phrase": "вообщем имейте ввиду"
          },
          {
            "word": "имейте",
            "count": 1,
            "phrase": "вообщем имейте ввиду"
          },
          {
            "word": "важен",
            "count": 3,
            "phrase": "что если вам важен внешний вид"
          },
          {
            "word": "вскрыт",
            "count": 15,
            "phrase": "сам приборчик тоже вскрыт"
          },
          {
            "word": "использовался",
            "count": 1,
            "phrase": "уже использовался"
          },
          {
            "word": "расстроилась",
            "count": 2,
            "phrase": "очень расстроилась"
          },
          {
            "word": "сотрудники",
            "count": 1,
            "phrase": "что сотрудники так халатно относятся к транспортировке"
          },
          {
            "word": "халатно",
            "count": 1,
            "phrase": "что сотрудники так халатно относятся к транспортировке"
          },
          {
            "word": "относятся",
            "count": 1,
            "phrase": "что сотрудники так халатно относятся к транспортировке"
          },
          {
            "word": "транспортировке",
            "count": 1,
            "phrase": "что сотрудники так халатно относятся к транспортировке"
          },
          {
            "word": "комплектацию",
            "count": 1,
            "phrase": "посмотрела на пункте комплектацию"
          },
          {
            "word": "тяжкие",
            "count": 1,
            "phrase": "дома сразу пошла во все тяжкие"
          },
          {
            "word": "брила",
            "count": 7,
            "phrase": "брила все подряд"
          },
          {
            "word": "подряд",
            "count": 1,
            "phrase": "брила все подряд"
          },
          {
            "word": "подключить",
            "count": 1,
            "phrase": "и когда надо было подключить её к зарядке"
          },
          {
            "word": "подключила",
            "count": 0,
            "phrase": "подключила ее к зарядному от телефона"
          },
          {
            "word": "зарядному",
            "count": 1,
            "phrase": "подключила ее к зарядному от телефона"
          },
          {
            "word": "вон",
            "count": 3,
            "phrase": "лежит вон"
          },
          {
            "word": "включала",
            "count": 3,
            "phrase": "ещё не включала"
          },
          {
            "word": "косяк",
            "count": 2,
            "phrase": "пока не увидела этот косяк казалось"
          },
          {
            "word": "разъём",
            "count": 1,
            "phrase": "что разъём - то не подходит"
          },
          {
            "word": "подходит",
            "count": 3,
            "phrase": "что разъём - то не подходит"
          }
        ],
        "3": [
          {
            "word": "расстрелянием",
            "count": 1,
            "phrase": "большое расстрелянием от лезвия до самой ткани"
          },
          {
            "word": "насадки",
            "count": 1,
            "phrase": "за счёт насадки"
          },
          {
            "word": "обеспечивает",
            "count": 1,
            "phrase": "и обеспечивает бережное удаление"
          },
          {
            "word": "удаление",
            "count": 4,
            "phrase": "и обеспечивает бережное удаление"
          },
          {
            "word": "полное",
            "count": 2,
            "phrase": "но не совсем полное"
          },
          {
            "word": "привели",
            "count": 0,
            "phrase": "привели в чувства пальто"
          },
          {
            "word": "чувства",
            "count": 1,
            "phrase": "привели в чувства пальто"
          },
          {
            "word": "трикотажный",
            "count": 1,
            "phrase": "трикотажный костюм"
          },
          {
            "word": "мигала",
            "count": 1,
            "phrase": "мигала лампочка"
          },
          {
            "word": "лампочка",
            "count": 2,
            "phrase": "мигала лампочка"
          },
          {
            "word": "перестала",
            "count": 4,
            "phrase": "потом перестала"
          },
          {
            "word": "возвращать",
            "count": 3,
            "phrase": "но смело можно возвращать без оплаты за"
          },
          {
            "word": "возвратный",
            "count": 3,
            "phrase": "возвратный товар"
          },
          {
            "word": "соответствие",
            "count": 1,
            "phrase": "не соответствие"
          },
          {
            "word": "научитесь",
            "count": 1,
            "phrase": "когда вы уже научитесь указывать точные характеристики товара"
          },
          {
            "word": "указывать",
            "count": 1,
            "phrase": "когда вы уже научитесь указывать точные характеристики товара"
          },
          {
            "word": "точные",
            "count": 1,
            "phrase": "когда вы уже научитесь указывать точные характеристики товара"
          },
          {
            "word": "характеристики",
            "count": 1,
            "phrase": "когда вы уже научитесь указывать точные характеристики товара"
          },
          {
            "word": "сравнила",
            "count": 0,
            "phrase": "сравнила с другой своей машинкой"
          },
          {
            "word": "жужжат",
            "count": 0,
            "phrase": "жужжат по разному"
          },
          {
            "word": "разному",
            "count": 1,
            "phrase": "жужжат по разному"
          },
          {
            "word": "ксяоми",
            "count": 1,
            "phrase": "ксяоми жужжит не приятно слуху"
          },
          {
            "word": "слуху",
            "count": 1,
            "phrase": "ксяоми жужжит не приятно слуху"
          },
          {
            "word": "мощность",
            "count": 1,
            "phrase": "мощность хорошая"
          },
          {
            "word": "меньшего",
            "count": 1,
            "phrase": "отверстия в площадке меньшего диаметра"
          },
          {
            "word": "диаметра",
            "count": 1,
            "phrase": "отверстия в площадке меньшего диаметра"
          },
          {
            "word": "заявлено",
            "count": 1,
            "phrase": "потому что шнур не 50 см как заявлено"
          },
          {
            "word": "звезды",
            "count": 4,
            "phrase": "но 3 звезды за то что доставка подкачала"
          },
          {
            "word": "подкачала",
            "count": 1,
            "phrase": "но 3 звезды за то что доставка подкачала"
          },
          {
            "word": "пинками",
            "count": 1,
            "phrase": "будто её пинками доставляли"
          },
          {
            "word": "доставляли",
            "count": 1,
            "phrase": "будто её пинками доставляли"
          },
          {
            "word": "подводите",
            "count": 1,
            "phrase": "не подводите продавцов и покупателей"
          },
          {
            "word": "продавцов",
            "count": 1,
            "phrase": "не подводите продавцов и покупателей"
          },
          {
            "word": "могут",
            "count": 2,
            "phrase": "ведь могут брать на подарок"
          },
          {
            "word": "пиджаке",
            "count": 1,
            "phrase": "без усилий почистила рукава на пиджаке"
          },
          {
            "word": "зарядными",
            "count": 1,
            "phrase": "пробовала разными зарядными устройствами"
          },
          {
            "word": "устройствами",
            "count": 2,
            "phrase": "пробовала разными зарядными устройствами"
          },
          {
            "word": "дела",
            "count": 51,
            "phrase": "теперь лежит без дела"
          },
          {
            "word": "потраченных",
            "count": 1,
            "phrase": "жаль потраченных денег"
          },
          {
            "word": "неудосужился",
            "count": 1,
            "phrase": "но продавец неудосужился завернуть товар хотя бы в пупырку"
          },
          {
            "word": "завернуть",
            "count": 1,
            "phrase": "но продавец неудосужился завернуть товар хотя бы в пупырку"
          },
          {
            "word": "пупырку",
            "count": 2,
            "phrase": "но продавец неудосужился завернуть товар хотя бы в пупырку"
          },
          {
            "word": "вручили",
            "count": 0,
            "phrase": "вручили мятую коробку без какой - либо упаковки"
          },
          {
            "word": "мятую",
            "count": 3,
            "phrase": "вручили мятую коробку без какой - либо упаковки"
          },
          {
            "word": "ожидалось",
            "count": 0,
            "phrase": "ожидалось лучшего от этой фирмы"
          },
          {
            "word": "плохая",
            "count": 3,
            "phrase": "для своих денег не плохая"
          },
          {
            "word": "порваной",
            "count": 1,
            "phrase": "пришёл в порваной коробке"
          },
          {
            "word": "упаковку(нужно",
            "count": 1,
            "phrase": "минус 1 звезда за упаковку(нужно дополнительную упаковку защитную"
          },
          {
            "word": "защитную",
            "count": 1,
            "phrase": "минус 1 звезда за упаковку(нужно дополнительную упаковку защитную"
          },
          {
            "word": "берете",
            "count": 1,
            "phrase": "но если вы за возврат деньги берете"
          },
          {
            "word": "добры",
            "count": 2,
            "phrase": "то будьте добры доставлять хорошо"
          },
          {
            "word": "доставлять",
            "count": 1,
            "phrase": "то будьте добры доставлять хорошо"
          },
          {
            "word": "страдает",
            "count": 1,
            "phrase": "из - за вас продавец страдает"
          },
          {
            "word": "замятыми",
            "count": 1,
            "phrase": "с замятыми углами"
          },
          {
            "word": "бралась",
            "count": 4,
            "phrase": "что не на подарок бралась машинка"
          },
          {
            "word": "настроение",
            "count": 1,
            "phrase": "настроение было испорчено"
          },
          {
            "word": "испорчено",
            "count": 1,
            "phrase": "настроение было испорчено"
          },
          {
            "word": "частично",
            "count": 1,
            "phrase": "машинка была частично заряжена"
          },
          {
            "word": "притензий",
            "count": 1,
            "phrase": "к самой машинке притензий нет"
          },
          {
            "word": "украден",
            "count": 1,
            "phrase": "шнур украден"
          },
          {
            "word": "зарядные",
            "count": 1,
            "phrase": "дома такие зарядные шнуры есть"
          },
          {
            "word": "шнуры",
            "count": 1,
            "phrase": "дома такие зарядные шнуры есть"
          },
          {
            "word": "воровство",
            "count": 1,
            "phrase": "снимаю звезды за воровство"
          },
          {
            "word": "разочарована",
            "count": 1,
            "phrase": "вторую заказала на подарок и очень была разочарована"
          },
          {
            "word": "открывать",
            "count": 1,
            "phrase": "даже не стала открывать"
          },
          {
            "word": "грустно",
            "count": 0,
            "phrase": "грустно что люди не ценят свою репутацию"
          },
          {
            "word": "ценят",
            "count": 1,
            "phrase": "грустно что люди не ценят свою репутацию"
          },
          {
            "word": "репутацию",
            "count": 1,
            "phrase": "грустно что люди не ценят свою репутацию"
          },
          {
            "word": "хата",
            "count": 1,
            "phrase": "ни чего не знаю - моя хата с краю"
          },
          {
            "word": "краю",
            "count": 1,
            "phrase": "ни чего не знаю - моя хата с краю"
          },
          {
            "word": "прошлым",
            "count": 1,
            "phrase": "покупала прошлым августом"
          },
          {
            "word": "августом",
            "count": 1,
            "phrase": "покупала прошлым августом"
          },
          {
            "word": "пальцам",
            "count": 1,
            "phrase": "пользовалась не часто можно по пальцам пересчитать разы"
          },
          {
            "word": "пересчитать",
            "count": 1,
            "phrase": "пользовалась не часто можно по пальцам пересчитать разы"
          },
          {
            "word": "чаще",
            "count": 3,
            "phrase": "думаю что если пользовалась чаще"
          },
          {
            "word": "вышла",
            "count": 1,
            "phrase": "то и вышла из строя раньше"
          },
          {
            "word": "строя",
            "count": 1,
            "phrase": "то и вышла из строя раньше"
          }
        ],
        "4": [
          {
            "word": "впечатляет",
            "count": 1,
            "phrase": "но до и после впечатляет"
          },
          {
            "word": "ответы",
            "count": 1,
            "phrase": "почитала ответы продавца"
          },
          {
            "word": "поправила",
            "count": 1,
            "phrase": "поправила контейнер и"
          },
          {
            "word": "спортивный",
            "count": 2,
            "phrase": "спортивный костюм мужа"
          },
          {
            "word": "закажу",
            "count": 1,
            "phrase": "но не закажу"
          },
          {
            "word": "скотче",
            "count": 3,
            "phrase": "потому что коробка пришла в скотче"
          },
          {
            "word": "маркером",
            "count": 2,
            "phrase": "рваная и маркером исписаная"
          },
          {
            "word": "исписаная",
            "count": 1,
            "phrase": "рваная и маркером исписаная"
          },
          {
            "word": "немногого",
            "count": 1,
            "phrase": "чтобы продавец немногого больше думал о внешнем виде товара"
          },
          {
            "word": "внешнем",
            "count": 3,
            "phrase": "чтобы продавец немногого больше думал о внешнем виде товара"
          },
          {
            "word": "ленился",
            "count": 1,
            "phrase": "не ленился и упаковывал в дополнительную коробку"
          },
          {
            "word": "упаковывал",
            "count": 1,
            "phrase": "не ленился и упаковывал в дополнительную коробку"
          },
          {
            "word": "дополнительную",
            "count": 3,
            "phrase": "не ленился и упаковывал в дополнительную коробку"
          },
          {
            "word": "старых",
            "count": 1,
            "phrase": "пробовала на старых детских сильно изношенных колготках"
          },
          {
            "word": "изношенных",
            "count": 1,
            "phrase": "пробовала на старых детских сильно изношенных колготках"
          },
          {
            "word": "цепляет",
            "count": 1,
            "phrase": "нитки не цепляет"
          },
          {
            "word": "затупилась",
            "count": 1,
            "phrase": "но уже затупилась и нитки таскать стала"
          },
          {
            "word": "таскать",
            "count": 1,
            "phrase": "но уже затупилась и нитки таскать стала"
          },
          {
            "word": "администратор",
            "count": 0,
            "phrase": "администратор пункта выдачи сразу предложил проверить товар"
          },
          {
            "word": "предложил",
            "count": 1,
            "phrase": "администратор пункта выдачи сразу предложил проверить товар"
          },
          {
            "word": "счастью",
            "count": 1,
            "phrase": "к счастью"
          },
          {
            "word": "безупречно",
            "count": 2,
            "phrase": "работает безупречно"
          },
          {
            "word": "однозадачно",
            "count": 0,
            "phrase": "однозадачно рекомендую к покупке"
          },
          {
            "word": "попросила",
            "count": 1,
            "phrase": "только попросила бы получше упаковывать"
          },
          {
            "word": "получше",
            "count": 1,
            "phrase": "только попросила бы получше упаковывать"
          },
          {
            "word": "упаковывать",
            "count": 2,
            "phrase": "только попросила бы получше упаковывать"
          },
          {
            "word": "звезду",
            "count": 5,
            "phrase": "одну звезду снимаю только за это"
          },
          {
            "word": "снимаю",
            "count": 4,
            "phrase": "одну звезду снимаю только за это"
          },
          {
            "word": "задача",
            "count": 2,
            "phrase": "это задача продавца указать на недостатки"
          },
          {
            "word": "указать",
            "count": 1,
            "phrase": "это задача продавца указать на недостатки"
          },
          {
            "word": "недостатки",
            "count": 1,
            "phrase": "это задача продавца указать на недостатки"
          },
          {
            "word": "атласной",
            "count": 1,
            "phrase": "саму его работу проверила уже дома на атласной окантовке кофточки"
          },
          {
            "word": "окантовке",
            "count": 1,
            "phrase": "саму его работу проверила уже дома на атласной окантовке кофточки"
          },
          {
            "word": "кофточки",
            "count": 2,
            "phrase": "саму его работу проверила уже дома на атласной окантовке кофточки"
          },
          {
            "word": "маловаты",
            "count": 1,
            "phrase": "отверстия в машинке маловаты"
          },
          {
            "word": "испытаю",
            "count": 1,
            "phrase": "на деле испытаю позже"
          },
          {
            "word": "позже",
            "count": 1,
            "phrase": "на деле испытаю позже"
          },
          {
            "word": "короткий",
            "count": 1,
            "phrase": "в комплекте короткий проводок для зарядки type - c"
          },
          {
            "word": "помятый",
            "count": 1,
            "phrase": "пришел помятый"
          },
          {
            "word": "запечатала",
            "count": 1,
            "phrase": "но сама машинка была запечатала в пакете"
          },
          {
            "word": "пакете",
            "count": 1,
            "phrase": "но сама машинка была запечатала в пакете"
          },
          {
            "word": "фотоотчете",
            "count": 1,
            "phrase": "результат работы машинки в фотоотчете"
          },
          {
            "word": "оценка",
            "count": 0,
            "phrase": "оценка занижена за помятую коробку при доставке"
          },
          {
            "word": "занижена",
            "count": 1,
            "phrase": "оценка занижена за помятую коробку при доставке"
          },
          {
            "word": "помятую",
            "count": 1,
            "phrase": "оценка занижена за помятую коробку при доставке"
          },
          {
            "word": "остальные",
            "count": 1,
            "phrase": "но остальные так и остались"
          },
          {
            "word": "возилась",
            "count": 1,
            "phrase": "хотя долго возилась"
          },
          {
            "word": "глобальные",
            "count": 0,
            "phrase": "фото до и после глобальные"
          },
          {
            "word": "бортная",
            "count": 1,
            "phrase": "бортная доставка платная"
          },
          {
            "word": "платная",
            "count": 1,
            "phrase": "бортная доставка платная"
          },
          {
            "word": "удалять",
            "count": 1,
            "phrase": "надеюсь и дальше будет хорошо работать и удалять катышки"
          },
          {
            "word": "порванной",
            "count": 1,
            "phrase": "машинка пришла в порванной коробке"
          },
          {
            "word": "выкупила",
            "count": 3,
            "phrase": "поэтому выкупила"
          },
          {
            "word": "говоря",
            "count": 5,
            "phrase": "честно говоря"
          },
          {
            "word": "высыпалось",
            "count": 1,
            "phrase": "когда открыла коробку это всё высыпалось"
          },
          {
            "word": "нем",
            "count": 31,
            "phrase": "в нем было всё вокруг((сама машинка тоже вся испачкана"
          },
          {
            "word": "вокруг((сама",
            "count": 1,
            "phrase": "в нем было всё вокруг((сама машинка тоже вся испачкана"
          },
          {
            "word": "испачкана",
            "count": 1,
            "phrase": "в нем было всё вокруг((сама машинка тоже вся испачкана"
          },
          {
            "word": "испортило",
            "count": 1,
            "phrase": "это конечно очень испортило настрой от покупки"
          },
          {
            "word": "настрой",
            "count": 1,
            "phrase": "это конечно очень испортило настрой от покупки"
          },
          {
            "word": "насыпан",
            "count": 1,
            "phrase": "в коробке было насыпан какой - то красный порошок"
          },
          {
            "word": "порошок",
            "count": 1,
            "phrase": "в коробке было насыпан какой - то красный порошок"
          },
          {
            "word": "платить",
            "count": 2,
            "phrase": "да и за возврат надо было платить 100р"
          },
          {
            "word": "100р",
            "count": 1,
            "phrase": "да и за возврат надо было платить 100р"
          },
          {
            "word": "упаковали",
            "count": 2,
            "phrase": "за то что не качественно упаковали"
          },
          {
            "word": "заказом",
            "count": 1,
            "phrase": "довольна заказом"
          },
          {
            "word": "великолепный",
            "count": 1,
            "phrase": "результат великолепный"
          },
          {
            "word": "запечанной",
            "count": 1,
            "phrase": "единственный минус - пришла в плохо запечанной упаковке"
          },
          {
            "word": "хорошим",
            "count": 1,
            "phrase": "нужно прям с хорошим нажимом убирать"
          },
          {
            "word": "нажимом",
            "count": 1,
            "phrase": "нужно прям с хорошим нажимом убирать"
          },
          {
            "word": "приемлемо",
            "count": 1,
            "phrase": "вполне приемлемо"
          },
          {
            "word": "стыдно",
            "count": 2,
            "phrase": "а то прям совсем стыдно ходить было"
          },
          {
            "word": "ходить",
            "count": 6,
            "phrase": "а то прям совсем стыдно ходить было"
          },
          {
            "word": "этих",
            "count": 1,
            "phrase": "из - за этих штанов я ее и заказала"
          },
          {
            "word": "помешала",
            "count": 1,
            "phrase": "не помешала бы доп упаковка"
          },
          {
            "word": "вскрывалась",
            "count": 1,
            "phrase": "коробка пришла вся мятая и как будто бы вскрывалась"
          },
          {
            "word": "вжух",
            "count": 1,
            "phrase": "вжух и чисто"
          },
          {
            "word": "шёлковое",
            "count": 1,
            "phrase": "даже шёлковое белье"
          },
          {
            "word": "заход",
            "count": 2,
            "phrase": "плед почистила за один заход и заряд даже не закончился"
          },
          {
            "word": "закончился",
            "count": 1,
            "phrase": "плед почистила за один заход и заряд даже не закончился"
          },
          {
            "word": "слава",
            "count": 2,
            "phrase": "машинка слава богу работает"
          },
          {
            "word": "богу",
            "count": 2,
            "phrase": "машинка слава богу работает"
          },
          {
            "word": "вклучался",
            "count": 1,
            "phrase": "один день пользовалась на второй не вклучался"
          },
          {
            "word": "зашла",
            "count": 1,
            "phrase": "зашла в коменты у многих так"
          },
          {
            "word": "коменты",
            "count": 1,
            "phrase": "зашла в коменты у многих так"
          },
          {
            "word": "включаца",
            "count": 1,
            "phrase": "но он может не включаца и за забытый дисков"
          },
          {
            "word": "забытый",
            "count": 1,
            "phrase": "но он может не включаца и за забытый дисков"
          },
          {
            "word": "дисков",
            "count": 1,
            "phrase": "но он может не включаца и за забытый дисков"
          },
          {
            "word": "откройте",
            "count": 1,
            "phrase": "откройте где сетка почисть диск"
          },
          {
            "word": "почисть",
            "count": 1,
            "phrase": "откройте где сетка почисть диск"
          },
          {
            "word": "унас",
            "count": 1,
            "phrase": "она унас заработала"
          },
          {
            "word": "пригодится",
            "count": 1,
            "phrase": "может кому пригодится"
          },
          {
            "word": "вскрывали",
            "count": 1,
            "phrase": "что её вскрывали"
          },
          {
            "word": "сколы",
            "count": 1,
            "phrase": "звезду снимаю за сколы на контейнере для катышек"
          },
          {
            "word": "зазор",
            "count": 1,
            "phrase": "зазор на кнопке включения сильно бросается в глаза"
          },
          {
            "word": "кнопке",
            "count": 1,
            "phrase": "зазор на кнопке включения сильно бросается в глаза"
          },
          {
            "word": "бросается",
            "count": 1,
            "phrase": "зазор на кнопке включения сильно бросается в глаза"
          },
          {
            "word": "глаза",
            "count": 2,
            "phrase": "зазор на кнопке включения сильно бросается в глаза"
          },
          {
            "word": "оригинальности",
            "count": 1,
            "phrase": "но как - то сомневаюсь в ее оригинальности"
          }
        ],
        "5": [
          {
            "word": "думала",
            "count": 36,
            "phrase": "так как думала"
          },
          {
            "word": "ничего",
            "count": 19,
            "phrase": "что ничего не получится"
          },
          {
            "word": "получится",
            "count": 2,
            "phrase": "что ничего не получится"
          },
          {
            "word": "кошка",
            "count": 2,
            "phrase": "у нас кошка"
          },
          {
            "word": "диван",
            "count": 8,
            "phrase": "диван не наш"
          },
          {
            "word": "наш",
            "count": 11,
            "phrase": "диван не наш"
          },
          {
            "word": "был",
            "count": 162,
            "phrase": "был бы наш"
          },
          {
            "word": "смотрела",
            "count": 7,
            "phrase": "смотрела отзывы"
          },
          {
            "word": "отзывы",
            "count": 10,
            "phrase": "смотрела отзывы"
          },
          {
            "word": "думали",
            "count": 4,
            "phrase": "вот думали думали"
          },
          {
            "word": "решились",
            "count": 1,
            "phrase": "и решились"
          },
          {
            "word": "пришло",
            "count": 14,
            "phrase": "пришло быстро"
          },
          {
            "word": "быстро",
            "count": 78,
            "phrase": "пришло быстро"
          },
          {
            "word": "вауля",
            "count": 1,
            "phrase": "и вауля чудо"
          },
          {
            "word": "чудо",
            "count": 27,
            "phrase": "и вауля чудо"
          },
          {
            "word": "убрало",
            "count": 2,
            "phrase": "все убрало"
          },
          {
            "word": "стало",
            "count": 14,
            "phrase": "стало намного лучше"
          },
          {
            "word": "намного",
            "count": 9,
            "phrase": "стало намного лучше"
          },
          {
            "word": "лучше",
            "count": 30,
            "phrase": "стало намного лучше"
          },
          {
            "word": "новый",
            "count": 19,
            "phrase": "не новый"
          },
          {
            "word": "огромное",
            "count": 6,
            "phrase": "огромное спасибо"
          },
          {
            "word": "однозначно",
            "count": 22,
            "phrase": "однозначно рекомендую"
          },
          {
            "word": "рекомендую",
            "count": 101,
            "phrase": "однозначно рекомендую"
          },
          {
            "word": "еще",
            "count": 80,
            "phrase": "еще понравилось"
          },
          {
            "word": "понравилось",
            "count": 13,
            "phrase": "еще понравилось"
          },
          {
            "word": "ребенок",
            "count": 1,
            "phrase": "ребенок был рядом"
          },
          {
            "word": "рядом",
            "count": 3,
            "phrase": "ребенок был рядом"
          },
          {
            "word": "было",
            "count": 59,
            "phrase": "можно было бы поставить звезд больше - поставила бы"
          },
          {
            "word": "поставить",
            "count": 4,
            "phrase": "можно было бы поставить звезд больше - поставила бы"
          },
          {
            "word": "звезд",
            "count": 19,
            "phrase": "можно было бы поставить звезд больше - поставила бы"
          },
          {
            "word": "больше",
            "count": 24,
            "phrase": "можно было бы поставить звезд больше - поставила бы"
          },
          {
            "word": "поставила",
            "count": 9,
            "phrase": "можно было бы поставить звезд больше - поставила бы"
          },
          {
            "word": "давно",
            "count": 13,
            "phrase": "давно так не радовалась покупке на вайлдбериз"
          },
          {
            "word": "радовалась",
            "count": 2,
            "phrase": "давно так не радовалась покупке на вайлдбериз"
          },
          {
            "word": "покупке",
            "count": 58,
            "phrase": "давно так не радовалась покупке на вайлдбериз"
          },
          {
            "word": "вайлдбериз",
            "count": 1,
            "phrase": "давно так не радовалась покупке на вайлдбериз"
          },
          {
            "word": "учебный",
            "count": 0,
            "phrase": "учебный год только начался"
          },
          {
            "word": "год",
            "count": 41,
            "phrase": "учебный год только начался"
          },
          {
            "word": "только",
            "count": 62,
            "phrase": "учебный год только начался"
          },
          {
            "word": "начался",
            "count": 1,
            "phrase": "учебный год только начался"
          },
          {
            "word": "брюки",
            "count": 9,
            "phrase": "а брюки уже выглядят заношенными"
          },
          {
            "word": "уже",
            "count": 105,
            "phrase": "а брюки уже выглядят заношенными"
          },
          {
            "word": "выглядят",
            "count": 4,
            "phrase": "а брюки уже выглядят заношенными"
          },
          {
            "word": "заношенными",
            "count": 1,
            "phrase": "а брюки уже выглядят заношенными"
          },
          {
            "word": "машинка",
            "count": 340,
            "phrase": "машинка реально их спасла от мусорки"
          },
          {
            "word": "реально",
            "count": 6,
            "phrase": "машинка реально их спасла от мусорки"
          },
          {
            "word": "спасла",
            "count": 11,
            "phrase": "машинка реально их спасла от мусорки"
          },
          {
            "word": "мусорки",
            "count": 1,
            "phrase": "машинка реально их спасла от мусорки"
          },
          {
            "word": "очищение",
            "count": 3,
            "phrase": "и очищение заняло очень мало времени"
          },
          {
            "word": "заняло",
            "count": 1,
            "phrase": "и очищение заняло очень мало времени"
          },
          {
            "word": "очень",
            "count": 205,
            "phrase": "и очищение заняло очень мало времени"
          },
          {
            "word": "времени",
            "count": 8,
            "phrase": "и очищение заняло очень мало времени"
          },
          {
            "word": "поэтому",
            "count": 12,
            "phrase": "поэтому однозначно рекомендую"
          },
          {
            "word": "отличная",
            "count": 25,
            "phrase": "отличная машинка"
          },
          {
            "word": "доставка",
            "count": 16,
            "phrase": "доставка была очень быстрая"
          },
          {
            "word": "была",
            "count": 72,
            "phrase": "доставка была очень быстрая"
          },
          {
            "word": "быстрая",
            "count": 11,
            "phrase": "доставка была очень быстрая"
          },
          {
            "word": "коробка",
            "count": 28,
            "phrase": "коробка не много помятая пришла"
          },
          {
            "word": "много",
            "count": 57,
            "phrase": "коробка не много помятая пришла"
          },
          {
            "word": "помятая",
            "count": 7,
            "phrase": "коробка не много помятая пришла"
          },
          {
            "word": "пришла",
            "count": 64,
            "phrase": "коробка не много помятая пришла"
          },
          {
            "word": "сама",
            "count": 27,
            "phrase": "сама коробка упакована была в пакет"
          },
          {
            "word": "упакована",
            "count": 10,
            "phrase": "сама коробка упакована была в пакет"
          },
          {
            "word": "пакет",
            "count": 5,
            "phrase": "сама коробка упакована была в пакет"
          },
          {
            "word": "проверила",
            "count": 12,
            "phrase": "проверила на пункте выдачи"
          },
          {
            "word": "пункте",
            "count": 23,
            "phrase": "проверила на пункте выдачи"
          },
          {
            "word": "выдачи",
            "count": 21,
            "phrase": "проверила на пункте выдачи"
          },
          {
            "word": "работало",
            "count": 1,
            "phrase": "всё работало"
          },
          {
            "word": "первый",
            "count": 4,
            "phrase": "в первый же день опробовала"
          },
          {
            "word": "день",
            "count": 27,
            "phrase": "в первый же день опробовала"
          },
          {
            "word": "опробовала",
            "count": 15,
            "phrase": "в первый же день опробовала"
          },
          {
            "word": "сам",
            "count": 74,
            "phrase": "сам аппарат стильный и удобный"
          },
          {
            "word": "аппарат",
            "count": 29,
            "phrase": "сам аппарат стильный и удобный"
          },
          {
            "word": "стильный",
            "count": 2,
            "phrase": "сам аппарат стильный и удобный"
          },
          {
            "word": "удобный",
            "count": 9,
            "phrase": "сам аппарат стильный и удобный"
          },
          {
            "word": "приятно",
            "count": 14,
            "phrase": "приятно им пользоваться"
          },
          {
            "word": "пользоваться",
            "count": 10,
            "phrase": "приятно им пользоваться"
          },
          {
            "word": "долго",
            "count": 87,
            "phrase": "долго сомневалась"
          },
          {
            "word": "сомневалась",
            "count": 7,
            "phrase": "долго сомневалась"
          },
          {
            "word": "читала",
            "count": 6,
            "phrase": "читала отзывы"
          },
          {
            "word": "счастлива",
            "count": 3,
            "phrase": "и я просто счастлива"
          },
          {
            "word": "решила",
            "count": 18,
            "phrase": "что решила её купить"
          },
          {
            "word": "купить",
            "count": 12,
            "phrase": "что решила её купить"
          },
          {
            "word": "идеальная",
            "count": 2,
            "phrase": "она просто идеальная"
          },
          {
            "word": "убирает",
            "count": 84,
            "phrase": "убирает и большие катышки тоже"
          },
          {
            "word": "большие",
            "count": 13,
            "phrase": "убирает и большие катышки тоже"
          },
          {
            "word": "катышки",
            "count": 190,
            "phrase": "убирает и большие катышки тоже"
          },
          {
            "word": "легко",
            "count": 36,
            "phrase": "причём это так легко и быстро"
          },
          {
            "word": "звёзд",
            "count": 10,
            "phrase": "если можно было бы поставила 10 звёзд"
          },
          {
            "word": "всем",
            "count": 53,
            "phrase": "всем рекомендую"
          },
          {
            "word": "счёт",
            "count": 5,
            "phrase": "на счёт этой машинки"
          },
          {
            "word": "этой",
            "count": 26,
            "phrase": "на счёт этой машинки"
          },
          {
            "word": "машинки",
            "count": 49,
            "phrase": "на счёт этой машинки"
          },
          {
            "word": "супер",
            "count": 87,
            "phrase": "машинка просто супер"
          },
          {
            "word": "качественно",
            "count": 16,
            "phrase": "качественно чистит все"
          },
          {
            "word": "чистит",
            "count": 94,
            "phrase": "качественно чистит все"
          },
          {
            "word": "довольна",
            "count": 93,
            "phrase": "я довольна"
          },
          {
            "word": "пожалеете",
            "count": 16,
            "phrase": "не пожалеете"
          },
          {
            "word": "благодарю",
            "count": 0,
            "phrase": "благодарю продавцу"
          },
          {
            "word": "продавцу",
            "count": 44,
            "phrase": "благодарю продавцу"
          },
          {
            "word": "залипла",
            "count": 1,
            "phrase": "залипла на 2 часа 😅 берите"
          },
          {
            "word": "часа",
            "count": 13,
            "phrase": "залипла на 2 часа 😅 берите"
          },
          {
            "word": "берите",
            "count": 7,
            "phrase": "залипла на 2 часа 😅 берите"
          },
          {
            "word": "час",
            "count": 59,
            "phrase": "не пожалеете 😊 час без остановки точно работает"
          },
          {
            "word": "остановки",
            "count": 1,
            "phrase": "не пожалеете 😊 час без остановки точно работает"
          },
          {
            "word": "точно",
            "count": 28,
            "phrase": "не пожалеете 😊 час без остановки точно работает"
          },
          {
            "word": "работает",
            "count": 116,
            "phrase": "не пожалеете 😊 час без остановки точно работает"
          },
          {
            "word": "потом",
            "count": 31,
            "phrase": "потом оставила на зарядке на пол часа"
          },
          {
            "word": "оставила",
            "count": 12,
            "phrase": "потом оставила на зарядке на пол часа"
          },
          {
            "word": "зарядке",
            "count": 7,
            "phrase": "потом оставила на зарядке на пол часа"
          },
          {
            "word": "пол",
            "count": 229,
            "phrase": "потом оставила на зарядке на пол часа"
          },
          {
            "word": "хватило",
            "count": 7,
            "phrase": "и хватило еще на час"
          },
          {
            "word": "разрядилась",
            "count": 5,
            "phrase": "так и не разрядилась"
          },
          {
            "word": "комплекте",
            "count": 83,
            "phrase": "в комплекте щёточка и шнур для зарядки"
          },
          {
            "word": "щёточка",
            "count": 15,
            "phrase": "в комплекте щёточка и шнур для зарядки"
          },
          {
            "word": "шнур",
            "count": 39,
            "phrase": "в комплекте щёточка и шнур для зарядки"
          },
          {
            "word": "зарядки",
            "count": 68,
            "phrase": "в комплекте щёточка и шнур для зарядки"
          },
          {
            "word": "выглядит",
            "count": 16,
            "phrase": "выглядит очень эстетично"
          },
          {
            "word": "эстетично",
            "count": 1,
            "phrase": "выглядит очень эстетично"
          },
          {
            "word": "заряжается",
            "count": 24,
            "phrase": "заряжается быстро"
          },
          {
            "word": "огонь",
            "count": 21,
            "phrase": "машинка огонь 🔥 пришла в коробочке"
          },
          {
            "word": "коробочке",
            "count": 3,
            "phrase": "машинка огонь 🔥 пришла в коробочке"
          },
          {
            "word": "классная",
            "count": 40,
            "phrase": "машинка классная"
          },
          {
            "word": "каком",
            "count": 3,
            "phrase": "но вот в каком виде она мне пришла"
          },
          {
            "word": "виде",
            "count": 24,
            "phrase": "но вот в каком виде она мне пришла"
          },
          {
            "word": "ужаснулась",
            "count": 1,
            "phrase": "я ужаснулась"
          },
          {
            "word": "снаружи",
            "count": 1,
            "phrase": "снаружи мрак"
          },
          {
            "word": "мрак",
            "count": 1,
            "phrase": "снаружи мрак"
          },
          {
            "word": "полная",
            "count": 11,
            "phrase": "но внутри полная комплектация"
          },
          {
            "word": "комплектация",
            "count": 8,
            "phrase": "но внутри полная комплектация"
          },
          {
            "word": "использованная",
            "count": 1,
            "phrase": "машинка не использованная"
          },
          {
            "word": "чистенько",
            "count": 1,
            "phrase": "все чистенько было"
          },
          {
            "word": "хорошо",
            "count": 138,
            "phrase": "работает хорошо"
          },
          {
            "word": "крупные",
            "count": 10,
            "phrase": "крупные катышки удаляет здорово"
          },
          {
            "word": "удаляет",
            "count": 39,
            "phrase": "крупные катышки удаляет здорово"
          },
          {
            "word": "мелкими",
            "count": 7,
            "phrase": "с очень мелкими не совсем справляется"
          },
          {
            "word": "совсем",
            "count": 17,
            "phrase": "с очень мелкими не совсем справляется"
          },
          {
            "word": "справляется",
            "count": 136,
            "phrase": "с очень мелкими не совсем справляется"
          },
          {
            "word": "размеру",
            "count": 2,
            "phrase": "по размеру достаточно большая"
          },
          {
            "word": "достаточно",
            "count": 11,
            "phrase": "по размеру достаточно большая"
          },
          {
            "word": "большая",
            "count": 13,
            "phrase": "по размеру достаточно большая"
          },
          {
            "word": "заряженая",
            "count": 2,
            "phrase": "пришла заряженая"
          },
          {
            "word": "понравилась",
            "count": 26,
            "phrase": "машинка понравилась"
          },
          {
            "word": "остановилась",
            "count": 3,
            "phrase": "остановилась на этой и не пожалела"
          },
          {
            "word": "пожалела",
            "count": 13,
            "phrase": "остановилась на этой и не пожалела"
          },
          {
            "word": "есть",
            "count": 44,
            "phrase": "но есть два нюанса"
          },
          {
            "word": "нюанса",
            "count": 1,
            "phrase": "но есть два нюанса"
          },
          {
            "word": "понятно",
            "count": 8,
            "phrase": "не понятно какое отношение машинка имеет к фирме xiaomi"
          },
          {
            "word": "какое",
            "count": 2,
            "phrase": "не понятно какое отношение машинка имеет к фирме xiaomi"
          },
          {
            "word": "отношение",
            "count": 3,
            "phrase": "не понятно какое отношение машинка имеет к фирме xiaomi"
          },
          {
            "word": "имеет",
            "count": 9,
            "phrase": "не понятно какое отношение машинка имеет к фирме xiaomi"
          },
          {
            "word": "фирме",
            "count": 2,
            "phrase": "не понятно какое отношение машинка имеет к фирме xiaomi"
          },
          {
            "word": "xiaomi",
            "count": 1,
            "phrase": "не понятно какое отношение машинка имеет к фирме xiaomi"
          },
          {
            "word": "отсутствуете",
            "count": 0,
            "phrase": "отсутствуете заявленная инструкция на русском языке"
          },
          {
            "word": "заявленная",
            "count": 1,
            "phrase": "отсутствуете заявленная инструкция на русском языке"
          },
          {
            "word": "инструкция",
            "count": 20,
            "phrase": "отсутствуете заявленная инструкция на русском языке"
          },
          {
            "word": "русском",
            "count": 7,
            "phrase": "отсутствуете заявленная инструкция на русском языке"
          },
          {
            "word": "языке",
            "count": 4,
            "phrase": "отсутствуете заявленная инструкция на русском языке"
          },
          {
            "word": "крутая",
            "count": 17,
            "phrase": "машинка супер крутая"
          },
          {
            "word": "считаю",
            "count": 2,
            "phrase": "считаю очень нужная вещь"
          },
          {
            "word": "нужная",
            "count": 13,
            "phrase": "считаю очень нужная вещь"
          },
          {
            "word": "вещь",
            "count": 114,
            "phrase": "считаю очень нужная вещь"
          },
          {
            "word": "думаю",
            "count": 7,
            "phrase": "ну думаю что по фото всё понятно"
          },
          {
            "word": "фото",
            "count": 130,
            "phrase": "ну думаю что по фото всё понятно"
          },
          {
            "word": "никогда",
            "count": 3,
            "phrase": "никогда не оцениваю товар сразу"
          },
          {
            "word": "оцениваю",
            "count": 1,
            "phrase": "никогда не оцениваю товар сразу"
          },
          {
            "word": "товар",
            "count": 94,
            "phrase": "никогда не оцениваю товар сразу"
          },
          {
            "word": "сразу",
            "count": 45,
            "phrase": "никогда не оцениваю товар сразу"
          },
          {
            "word": "даю",
            "count": 4,
            "phrase": "даю испытательный срок"
          },
          {
            "word": "испытательный",
            "count": 1,
            "phrase": "даю испытательный срок"
          },
          {
            "word": "срок",
            "count": 3,
            "phrase": "даю испытательный срок"
          },
          {
            "word": "пользуюсь",
            "count": 5,
            "phrase": "пользуюсь машинкой около года"
          },
          {
            "word": "машинкой",
            "count": 25,
            "phrase": "пользуюсь машинкой около года"
          },
          {
            "word": "года",
            "count": 25,
            "phrase": "пользуюсь машинкой около года"
          },
          {
            "word": "пришел",
            "count": 11,
            "phrase": "товар пришел вовремя и хорошо запакованный"
          },
          {
            "word": "вовремя",
            "count": 7,
            "phrase": "товар пришел вовремя и хорошо запакованный"
          },
          {
            "word": "запакованный",
            "count": 2,
            "phrase": "товар пришел вовремя и хорошо запакованный"
          },
          {
            "word": "целая",
            "count": 20,
            "phrase": "коробка целая"
          },
          {
            "word": "прекрасно",
            "count": 16,
            "phrase": "машинка прекрасно удаляет катышки"
          },
          {
            "word": "купила",
            "count": 41,
            "phrase": "что купила машинку данного производителя"
          },
          {
            "word": "машинку",
            "count": 47,
            "phrase": "что купила машинку данного производителя"
          },
          {
            "word": "данного",
            "count": 4,
            "phrase": "что купила машинку данного производителя"
          },
          {
            "word": "производителя",
            "count": 3,
            "phrase": "что купила машинку данного производителя"
          },
          {
            "word": "надого",
            "count": 1,
            "phrase": "правда зарядки не надого хватает"
          },
          {
            "word": "хватает",
            "count": 31,
            "phrase": "правда зарядки не надого хватает"
          },
          {
            "word": "почитил",
            "count": 0,
            "phrase": "почитил простынь"
          },
          {
            "word": "простынь",
            "count": 3,
            "phrase": "почитил простынь"
          },
          {
            "word": "которую",
            "count": 7,
            "phrase": "которую уже хотел пускать на трпки фото до и после"
          },
          {
            "word": "хотел",
            "count": 33,
            "phrase": "которую уже хотел пускать на трпки фото до и после"
          },
          {
            "word": "пускать",
            "count": 1,
            "phrase": "которую уже хотел пускать на трпки фото до и после"
          },
          {
            "word": "трпки",
            "count": 1,
            "phrase": "которую уже хотел пускать на трпки фото до и после"
          },
          {
            "word": "главное",
            "count": 8,
            "phrase": "главное пр чистке не торопиться"
          },
          {
            "word": "чистке",
            "count": 5,
            "phrase": "главное пр чистке не торопиться"
          },
          {
            "word": "торопиться",
            "count": 1,
            "phrase": "главное пр чистке не торопиться"
          },
          {
            "word": "медленно",
            "count": 3,
            "phrase": "медленно водить по ткани"
          },
          {
            "word": "водить",
            "count": 9,
            "phrase": "медленно водить по ткани"
          },
          {
            "word": "ткани",
            "count": 13,
            "phrase": "медленно водить по ткани"
          },
          {
            "word": "лезвия",
            "count": 23,
            "phrase": "чтобы лезвия успели срезать катышки"
          },
          {
            "word": "успели",
            "count": 1,
            "phrase": "чтобы лезвия успели срезать катышки"
          },
          {
            "word": "срезать",
            "count": 2,
            "phrase": "чтобы лезвия успели срезать катышки"
          },
          {
            "word": "пушка",
            "count": 6,
            "phrase": "машинка огонь пушка бомба"
          },
          {
            "word": "бомба",
            "count": 10,
            "phrase": "машинка огонь пушка бомба"
          },
          {
            "word": "тельняшку",
            "count": 1,
            "phrase": "купила тельняшку пилип пляйн на садоводе"
          },
          {
            "word": "пилип",
            "count": 1,
            "phrase": "купила тельняшку пилип пляйн на садоводе"
          },
          {
            "word": "пляйн",
            "count": 1,
            "phrase": "купила тельняшку пилип пляйн на садоводе"
          },
          {
            "word": "садоводе",
            "count": 1,
            "phrase": "купила тельняшку пилип пляйн на садоводе"
          },
          {
            "word": "раза",
            "count": 14,
            "phrase": "два раза надела"
          },
          {
            "word": "надела",
            "count": 1,
            "phrase": "два раза надела"
          },
          {
            "word": "стала",
            "count": 32,
            "phrase": "стала выглядеть как лицо гуся после драй эффекта"
          },
          {
            "word": "выглядеть",
            "count": 6,
            "phrase": "стала выглядеть как лицо гуся после драй эффекта"
          },
          {
            "word": "лицо",
            "count": 5,
            "phrase": "стала выглядеть как лицо гуся после драй эффекта"
          },
          {
            "word": "гуся",
            "count": 1,
            "phrase": "стала выглядеть как лицо гуся после драй эффекта"
          },
          {
            "word": "драй",
            "count": 1,
            "phrase": "стала выглядеть как лицо гуся после драй эффекта"
          },
          {
            "word": "эффекта",
            "count": 4,
            "phrase": "стала выглядеть как лицо гуся после драй эффекта"
          },
          {
            "word": "одни",
            "count": 5,
            "phrase": "одни катышки"
          },
          {
            "word": "заказать",
            "count": 7,
            "phrase": "решила заказать сей агрегат"
          },
          {
            "word": "сей",
            "count": 10,
            "phrase": "решила заказать сей агрегат"
          },
          {
            "word": "агрегат",
            "count": 1,
            "phrase": "решила заказать сей агрегат"
          },
          {
            "word": "полчаса",
            "count": 2,
            "phrase": "за полчаса убрал все катышки"
          },
          {
            "word": "убрал",
            "count": 18,
            "phrase": "за полчаса убрал все катышки"
          },
          {
            "word": "мерседес",
            "count": 1,
            "phrase": "выглядит как новый мерседес"
          },
          {
            "word": "теперь",
            "count": 27,
            "phrase": "теперь никаких халатаов"
          },
          {
            "word": "никаких",
            "count": 2,
            "phrase": "теперь никаких халатаов"
          },
          {
            "word": "халатаов",
            "count": 1,
            "phrase": "теперь никаких халатаов"
          },
          {
            "word": "тельняшка",
            "count": 1,
            "phrase": "только тельняшка"
          },
          {
            "word": "мой",
            "count": 21,
            "phrase": "мой муж тимофей тоже доволен"
          },
          {
            "word": "муж",
            "count": 11,
            "phrase": "мой муж тимофей тоже доволен"
          },
          {
            "word": "тимофей",
            "count": 0,
            "phrase": "мой муж тимофей тоже доволен"
          },
          {
            "word": "доволен",
            "count": 4,
            "phrase": "мой муж тимофей тоже доволен"
          },
          {
            "word": "чищу",
            "count": 1,
            "phrase": "чищу ему шапки"
          },
          {
            "word": "шапки",
            "count": 6,
            "phrase": "чищу ему шапки"
          },
          {
            "word": "общем",
            "count": 10,
            "phrase": "в общем"
          },
          {
            "word": "10/10",
            "count": 4,
            "phrase": "машинка справляется на 10/10"
          },
          {
            "word": "уберутся",
            "count": 1,
            "phrase": "я думала не уберутся катышки"
          },
          {
            "word": "справилась",
            "count": 36,
            "phrase": "но справилась"
          },
          {
            "word": "щеточка",
            "count": 38,
            "phrase": "в комплекте щеточка и провод для зарядки"
          },
          {
            "word": "провод",
            "count": 39,
            "phrase": "в комплекте щеточка и провод для зарядки"
          },
          {
            "word": "пользовании",
            "count": 21,
            "phrase": "в пользовании уже пол года"
          },
          {
            "word": "может",
            "count": 16,
            "phrase": "может больше"
          },
          {
            "word": "нарадуюсь",
            "count": 2,
            "phrase": "не нарадуюсь"
          },
          {
            "word": "очищается",
            "count": 3,
            "phrase": "легко очищается контейнер и не сложная замена лезвия"
          },
          {
            "word": "контейнер",
            "count": 30,
            "phrase": "легко очищается контейнер и не сложная замена лезвия"
          },
          {
            "word": "сложная",
            "count": 1,
            "phrase": "легко очищается контейнер и не сложная замена лезвия"
          },
          {
            "word": "замена",
            "count": 2,
            "phrase": "легко очищается контейнер и не сложная замена лезвия"
          },
          {
            "word": "кисточка",
            "count": 11,
            "phrase": "в комплекте шнур зарядки и кисточка для чистки"
          },
          {
            "word": "чистки",
            "count": 48,
            "phrase": "в комплекте шнур зарядки и кисточка для чистки"
          },
          {
            "word": "результат",
            "count": 64,
            "phrase": "результат не 10/10"
          },
          {
            "word": "новой",
            "count": 6,
            "phrase": "новой вещь не станет"
          },
          {
            "word": "станет",
            "count": 4,
            "phrase": "новой вещь не станет"
          },
          {
            "word": "9/10",
            "count": 2,
            "phrase": "но на 8 - 9/10 точно тянет"
          },
          {
            "word": "тянет",
            "count": 1,
            "phrase": "но на 8 - 9/10 точно тянет"
          },
          {
            "word": "позволило",
            "count": 1,
            "phrase": "что позволило проверить его сразу в пункте выдачи"
          },
          {
            "word": "проверить",
            "count": 11,
            "phrase": "что позволило проверить его сразу в пункте выдачи"
          },
          {
            "word": "целый",
            "count": 7,
            "phrase": "все прекрасно пришел целый"
          },
          {
            "word": "сказала",
            "count": 2,
            "phrase": "я бы даже сказала мощная"
          },
          {
            "word": "мощная",
            "count": 20,
            "phrase": "я бы даже сказала мощная"
          },
          {
            "word": "носки",
            "count": 33,
            "phrase": "носки ребенка скатываются а она ещё даже не ходит"
          },
          {
            "word": "ребенка",
            "count": 5,
            "phrase": "носки ребенка скатываются а она ещё даже не ходит"
          },
          {
            "word": "скатываются",
            "count": 2,
            "phrase": "носки ребенка скатываются а она ещё даже не ходит"
          },
          {
            "word": "ещё",
            "count": 44,
            "phrase": "носки ребенка скатываются а она ещё даже не ходит"
          },
          {
            "word": "ходит",
            "count": 19,
            "phrase": "носки ребенка скатываются а она ещё даже не ходит"
          },
          {
            "word": "выручалка",
            "count": 1,
            "phrase": "отличная выручалка"
          },
          {
            "word": "натянула",
            "count": 1,
            "phrase": "не натянула носок на руку и вот сразу дырка"
          },
          {
            "word": "носок",
            "count": 2,
            "phrase": "не натянула носок на руку и вот сразу дырка"
          },
          {
            "word": "руку",
            "count": 4,
            "phrase": "не натянула носок на руку и вот сразу дырка"
          },
          {
            "word": "дырка",
            "count": 2,
            "phrase": "не натянула носок на руку и вот сразу дырка"
          },
          {
            "word": "будьте",
            "count": 3,
            "phrase": "будьте аккуратнее"
          },
          {
            "word": "аккуратнее",
            "count": 1,
            "phrase": "будьте аккуратнее"
          },
          {
            "word": "слева",
            "count": 5,
            "phrase": "на фото слева до"
          },
          {
            "word": "справа",
            "count": 7,
            "phrase": "справа после"
          },
          {
            "word": "почистила",
            "count": 26,
            "phrase": "почистила уже кучу вещей"
          },
          {
            "word": "кучу",
            "count": 6,
            "phrase": "почистила уже кучу вещей"
          },
          {
            "word": "вещей",
            "count": 45,
            "phrase": "почистила уже кучу вещей"
          },
          {
            "word": "огромный",
            "count": 5,
            "phrase": "огромный плюс в том"
          },
          {
            "word": "том",
            "count": 77,
            "phrase": "огромный плюс в том"
          },
          {
            "word": "батарейках",
            "count": 17,
            "phrase": "что не на батарейках"
          },
          {
            "word": "заряжаемом",
            "count": 1,
            "phrase": "а на заряжаемом аккумуляторе"
          },
          {
            "word": "аккумуляторе",
            "count": 7,
            "phrase": "а на заряжаемом аккумуляторе"
          },
          {
            "word": "такого",
            "count": 10,
            "phrase": "именно такого плана хотела"
          },
          {
            "word": "плана",
            "count": 1,
            "phrase": "именно такого плана хотела"
          },
          {
            "word": "хотела",
            "count": 25,
            "phrase": "именно такого плана хотела"
          },
          {
            "word": "емкость",
            "count": 1,
            "phrase": "емкость для сбора мусора вместительная"
          },
          {
            "word": "сбора",
            "count": 8,
            "phrase": "емкость для сбора мусора вместительная"
          },
          {
            "word": "мусора",
            "count": 6,
            "phrase": "емкость для сбора мусора вместительная"
          },
          {
            "word": "вместительная",
            "count": 2,
            "phrase": "емкость для сбора мусора вместительная"
          },
          {
            "word": "немного",
            "count": 19,
            "phrase": "немного неудобно ее чистить"
          },
          {
            "word": "неудобно",
            "count": 1,
            "phrase": "немного неудобно ее чистить"
          },
          {
            "word": "чистить",
            "count": 45,
            "phrase": "немного неудобно ее чистить"
          },
          {
            "word": "некритично",
            "count": 1,
            "phrase": "но некритично"
          },
          {
            "word": "пару",
            "count": 12,
            "phrase": "я потом ещё пару минут поводила"
          },
          {
            "word": "минут",
            "count": 29,
            "phrase": "я потом ещё пару минут поводила"
          },
          {
            "word": "поводила",
            "count": 1,
            "phrase": "я потом ещё пару минут поводила"
          },
          {
            "word": "любом",
            "count": 2,
            "phrase": "в любом случае покупкой довольна"
          },
          {
            "word": "случае",
            "count": 2,
            "phrase": "в любом случае покупкой довольна"
          },
          {
            "word": "покупкой",
            "count": 35,
            "phrase": "в любом случае покупкой довольна"
          },
          {
            "word": "успела",
            "count": 1,
            "phrase": "уже успела привести в порядок пару вещей и носочки"
          },
          {
            "word": "привести",
            "count": 5,
            "phrase": "уже успела привести в порядок пару вещей и носочки"
          },
          {
            "word": "порядок",
            "count": 10,
            "phrase": "уже успела привести в порядок пару вещей и носочки"
          },
          {
            "word": "носочки",
            "count": 9,
            "phrase": "уже успела привести в порядок пару вещей и носочки"
          },
          {
            "word": "цена",
            "count": 11,
            "phrase": "хотя цена несколько завышена"
          },
          {
            "word": "несколько",
            "count": 14,
            "phrase": "хотя цена несколько завышена"
          },
          {
            "word": "завышена",
            "count": 1,
            "phrase": "хотя цена несколько завышена"
          },
          {
            "word": "взгляд",
            "count": 5,
            "phrase": "на мой взгляд"
          },
          {
            "word": "пришли",
            "count": 2,
            "phrase": "пришли с батутного центра вот в таких носках"
          },
          {
            "word": "батутного",
            "count": 1,
            "phrase": "пришли с батутного центра вот в таких носках"
          },
          {
            "word": "центра",
            "count": 1,
            "phrase": "пришли с батутного центра вот в таких носках"
          },
          {
            "word": "таких",
            "count": 4,
            "phrase": "пришли с батутного центра вот в таких носках"
          },
          {
            "word": "носках",
            "count": 6,
            "phrase": "пришли с батутного центра вот в таких носках"
          },
          {
            "word": "раньше",
            "count": 40,
            "phrase": "раньше была обычная за 300руб"
          },
          {
            "word": "обычная",
            "count": 3,
            "phrase": "раньше была обычная за 300руб"
          },
          {
            "word": "300руб",
            "count": 1,
            "phrase": "раньше была обычная за 300руб"
          },
          {
            "word": "справлялась",
            "count": 4,
            "phrase": "справлялась плохо"
          },
          {
            "word": "эта",
            "count": 16,
            "phrase": "эта вполне справилась"
          },
          {
            "word": "вполне",
            "count": 4,
            "phrase": "эта вполне справилась"
          },
          {
            "word": "выписала",
            "count": 2,
            "phrase": "только из - за этого и выписала машинку"
          },
          {
            "word": "любовь",
            "count": 3,
            "phrase": "это просто любовь"
          },
          {
            "word": "спасены",
            "count": 4,
            "phrase": "уже спасены несколько кардиганов и пиджак"
          },
          {
            "word": "кардиганов",
            "count": 1,
            "phrase": "уже спасены несколько кардиганов и пиджак"
          },
          {
            "word": "пиджак",
            "count": 6,
            "phrase": "уже спасены несколько кардиганов и пиджак"
          },
          {
            "word": "рука",
            "count": 20,
            "phrase": "рука не поднималась его выбросить"
          },
          {
            "word": "поднималась",
            "count": 1,
            "phrase": "рука не поднималась его выбросить"
          },
          {
            "word": "выбросить",
            "count": 8,
            "phrase": "рука не поднималась его выбросить"
          },
          {
            "word": "зарядка",
            "count": 29,
            "phrase": "в комплекте была зарядка и кисточка"
          },
          {
            "word": "понравился",
            "count": 8,
            "phrase": "результат очень понравился"
          },
          {
            "word": "сделайте",
            "count": 1,
            "phrase": "сделайте более герметичную упаковку товара"
          },
          {
            "word": "более",
            "count": 13,
            "phrase": "сделайте более герметичную упаковку товара"
          },
          {
            "word": "герметичную",
            "count": 1,
            "phrase": "сделайте более герметичную упаковку товара"
          },
          {
            "word": "упаковку",
            "count": 10,
            "phrase": "сделайте более герметичную упаковку товара"
          },
          {
            "word": "товара",
            "count": 22,
            "phrase": "сделайте более герметичную упаковку товара"
          },
          {
            "word": "вся",
            "count": 15,
            "phrase": "коробка вся была мятая и слегка порванная"
          },
          {
            "word": "мятая",
            "count": 21,
            "phrase": "коробка вся была мятая и слегка порванная"
          },
          {
            "word": "слегка",
            "count": 5,
            "phrase": "коробка вся была мятая и слегка порванная"
          },
          {
            "word": "порванная",
            "count": 2,
            "phrase": "коробка вся была мятая и слегка порванная"
          },
          {
            "word": "безнадежные",
            "count": 2,
            "phrase": "все безнадежные вещи были спасены 😅 единственное пожелание"
          },
          {
            "word": "вещи",
            "count": 80,
            "phrase": "все безнадежные вещи были спасены 😅 единственное пожелание"
          },
          {
            "word": "были",
            "count": 10,
            "phrase": "все безнадежные вещи были спасены 😅 единственное пожелание"
          },
          {
            "word": "единственное",
            "count": 2,
            "phrase": "все безнадежные вещи были спасены 😅 единственное пожелание"
          },
          {
            "word": "пожелание",
            "count": 1,
            "phrase": "все безнадежные вещи были спасены 😅 единственное пожелание"
          },
          {
            "word": "посоветовала",
            "count": 1,
            "phrase": "посоветовала подруга"
          },
          {
            "word": "подруга",
            "count": 3,
            "phrase": "посоветовала подруга"
          },
          {
            "word": "200",
            "count": 6,
            "phrase": "до этого была машинка за 200 рублей"
          },
          {
            "word": "рублей",
            "count": 13,
            "phrase": "до этого была машинка за 200 рублей"
          },
          {
            "word": "удаляла",
            "count": 1,
            "phrase": "но она очень долго все удаляла"
          },
          {
            "word": "здесь",
            "count": 8,
            "phrase": "здесь же пару минут и все отлично"
          },
          {
            "word": "отлично",
            "count": 125,
            "phrase": "здесь же пару минут и все отлично"
          },
          {
            "word": "свои",
            "count": 36,
            "phrase": "спасла свои штаны"
          },
          {
            "word": "штаны",
            "count": 7,
            "phrase": "спасла свои штаны"
          },
          {
            "word": "где",
            "count": 16,
            "phrase": "где катышки"
          },
          {
            "word": "сколько",
            "count": 33,
            "phrase": "сколько вещей ушло в мусор"
          },
          {
            "word": "ушло",
            "count": 2,
            "phrase": "сколько вещей ушло в мусор"
          },
          {
            "word": "мусор",
            "count": 11,
            "phrase": "сколько вещей ушло в мусор"
          },
          {
            "word": "могло",
            "count": 2,
            "phrase": "а могло бы получить новую жизнь"
          },
          {
            "word": "получить",
            "count": 2,
            "phrase": "а могло бы получить новую жизнь"
          },
          {
            "word": "новую",
            "count": 7,
            "phrase": "а могло бы получить новую жизнь"
          },
          {
            "word": "жизнь",
            "count": 31,
            "phrase": "а могло бы получить новую жизнь"
          },
          {
            "word": "должен",
            "count": 1,
            "phrase": "должен быть в каждом доме"
          },
          {
            "word": "быть",
            "count": 13,
            "phrase": "должен быть в каждом доме"
          },
          {
            "word": "каждом",
            "count": 5,
            "phrase": "должен быть в каждом доме"
          },
          {
            "word": "доме",
            "count": 15,
            "phrase": "должен быть в каждом доме"
          },
          {
            "word": "понравилпсь",
            "count": 1,
            "phrase": "машинка очень понравилпсь"
          },
          {
            "word": "своей",
            "count": 112,
            "phrase": "со своей работой справилась"
          },
          {
            "word": "работой",
            "count": 29,
            "phrase": "со своей работой справилась"
          },
          {
            "word": "повреждений",
            "count": 9,
            "phrase": "пришла без повреждений"
          },
          {
            "word": "аккумулятор",
            "count": 25,
            "phrase": "аккумулятор заряженный"
          },
          {
            "word": "заряженный",
            "count": 8,
            "phrase": "аккумулятор заряженный"
          },
          {
            "word": "выполняет",
            "count": 18,
            "phrase": "машинка отлично выполняет свою функцию"
          },
          {
            "word": "свою",
            "count": 27,
            "phrase": "машинка отлично выполняет свою функцию"
          },
          {
            "word": "функцию",
            "count": 10,
            "phrase": "машинка отлично выполняет свою функцию"
          },
          {
            "word": "захотите",
            "count": 1,
            "phrase": "если вы захотите избавить свою вещь от больших катышек"
          },
          {
            "word": "избавить",
            "count": 2,
            "phrase": "если вы захотите избавить свою вещь от больших катышек"
          },
          {
            "word": "больших",
            "count": 3,
            "phrase": "если вы захотите избавить свою вещь от больших катышек"
          },
          {
            "word": "катышек",
            "count": 31,
            "phrase": "если вы захотите избавить свою вещь от больших катышек"
          },
          {
            "word": "придётся",
            "count": 2,
            "phrase": "то придётся с этим помучаться и долго сидеть"
          },
          {
            "word": "помучаться",
            "count": 1,
            "phrase": "то придётся с этим помучаться и долго сидеть"
          },
          {
            "word": "сидеть",
            "count": 1,
            "phrase": "то придётся с этим помучаться и долго сидеть"
          },
          {
            "word": "потому",
            "count": 14,
            "phrase": "потому что отверстия в машинке не такие большие"
          },
          {
            "word": "отверстия",
            "count": 2,
            "phrase": "потому что отверстия в машинке не такие большие"
          },
          {
            "word": "машинке",
            "count": 12,
            "phrase": "потому что отверстия в машинке не такие большие"
          },
          {
            "word": "такие",
            "count": 7,
            "phrase": "потому что отверстия в машинке не такие большие"
          },
          {
            "word": "удобная",
            "count": 20,
            "phrase": "удобная и эффективная"
          },
          {
            "word": "эффективная",
            "count": 1,
            "phrase": "удобная и эффективная"
          },
          {
            "word": "слишком",
            "count": 2,
            "phrase": "при этом я не слишком усердствовала"
          },
          {
            "word": "усердствовала",
            "count": 1,
            "phrase": "при этом я не слишком усердствовала"
          },
          {
            "word": "пробовала",
            "count": 28,
            "phrase": "просто пробовала в работе машинку"
          },
          {
            "word": "работе",
            "count": 10,
            "phrase": "просто пробовала в работе машинку"
          },
          {
            "word": "порадовал",
            "count": 11,
            "phrase": "а результат уже порадовал"
          },
          {
            "word": "удобно",
            "count": 43,
            "phrase": "очень удобно"
          },
          {
            "word": "скатывает",
            "count": 1,
            "phrase": "что скатывает в шарик катышки"
          },
          {
            "word": "шарик",
            "count": 1,
            "phrase": "что скатывает в шарик катышки"
          },
          {
            "word": "штука",
            "count": 45,
            "phrase": "классная штука"
          },
          {
            "word": "невредимая",
            "count": 1,
            "phrase": "пришла целая и невредимая"
          },
          {
            "word": "100",
            "count": 39,
            "phrase": "справляется на 100 из 10"
          },
          {
            "word": "приложила",
            "count": 1,
            "phrase": "после приложила"
          },
          {
            "word": "целое",
            "count": 23,
            "phrase": "пришло все целое"
          },
          {
            "word": "восторг",
            "count": 47,
            "phrase": "это просто восторг"
          },
          {
            "word": "новые+классный",
            "count": 1,
            "phrase": "вещи как новые+классный антистресс"
          },
          {
            "word": "антистресс",
            "count": 4,
            "phrase": "вещи как новые+классный антистресс"
          },
          {
            "word": "вечере",
            "count": 1,
            "phrase": "залипла на пол вечере"
          },
          {
            "word": "удаления",
            "count": 28,
            "phrase": "отличная машинка для удаления катышков"
          },
          {
            "word": "катышков",
            "count": 50,
            "phrase": "отличная машинка для удаления катышков"
          },
          {
            "word": "низкая",
            "count": 1,
            "phrase": "достаточно низкая цена"
          },
          {
            "word": "заставил",
            "count": 1,
            "phrase": "потому что результат не заставил себя ждать"
          },
          {
            "word": "ждать",
            "count": 2,
            "phrase": "потому что результат не заставил себя ждать"
          },
          {
            "word": "редко",
            "count": 2,
            "phrase": "очень редко пишу отзывы"
          },
          {
            "word": "пишу",
            "count": 5,
            "phrase": "очень редко пишу отзывы"
          },
          {
            "word": "сейчас",
            "count": 8,
            "phrase": "но сейчас не удержалась"
          },
          {
            "word": "удержалась",
            "count": 1,
            "phrase": "но сейчас не удержалась"
          },
          {
            "word": "настолько",
            "count": 5,
            "phrase": "настолько это крутая штука"
          },
          {
            "word": "вообще",
            "count": 21,
            "phrase": "вообще не ожидала такого результата"
          },
          {
            "word": "ожидала",
            "count": 16,
            "phrase": "вообще не ожидала такого результата"
          },
          {
            "word": "результата",
            "count": 9,
            "phrase": "вообще не ожидала такого результата"
          },
          {
            "word": "прослужит",
            "count": 22,
            "phrase": "прослужит долго"
          },
          {
            "word": "нареканий",
            "count": 2,
            "phrase": "нареканий нет"
          },
          {
            "word": "довольны",
            "count": 6,
            "phrase": "покупкой очень довольны"
          },
          {
            "word": "могу",
            "count": 16,
            "phrase": "не могу остановиться"
          },
          {
            "word": "остановиться",
            "count": 3,
            "phrase": "не могу остановиться"
          },
          {
            "word": "усилий",
            "count": 6,
            "phrase": "без усилий"
          },
          {
            "word": "декоративные",
            "count": 2,
            "phrase": "почистила декоративные наволочки"
          },
          {
            "word": "наволочки",
            "count": 2,
            "phrase": "почистила декоративные наволочки"
          },
          {
            "word": "приступила",
            "count": 2,
            "phrase": "приступила к свитерам и кардиганам"
          },
          {
            "word": "свитерам",
            "count": 3,
            "phrase": "приступила к свитерам и кардиганам"
          },
          {
            "word": "кардиганам",
            "count": 1,
            "phrase": "приступила к свитерам и кардиганам"
          },
          {
            "word": "зарядный",
            "count": 6,
            "phrase": "в комплекте шнур зарядный и щеточка"
          },
          {
            "word": "действительно",
            "count": 7,
            "phrase": "действительно работает на ура"
          },
          {
            "word": "отшлифовали",
            "count": 1,
            "phrase": "отшлифовали пол шкафа одежды"
          },
          {
            "word": "шкафа",
            "count": 2,
            "phrase": "отшлифовали пол шкафа одежды"
          },
          {
            "word": "одежды",
            "count": 13,
            "phrase": "отшлифовали пол шкафа одежды"
          },
          {
            "word": "батарейка",
            "count": 20,
            "phrase": "батарейка не села"
          },
          {
            "word": "села",
            "count": 9,
            "phrase": "батарейка не села"
          },
          {
            "word": "убрала",
            "count": 9,
            "phrase": "убрала все катышки"
          },
          {
            "word": "оправдались",
            "count": 1,
            "phrase": "оправдались надежды"
          },
          {
            "word": "надежды",
            "count": 1,
            "phrase": "оправдались надежды"
          },
          {
            "word": "влюбилась",
            "count": 2,
            "phrase": "я влюбилась в эту малышку"
          },
          {
            "word": "эту",
            "count": 23,
            "phrase": "я влюбилась в эту малышку"
          },
          {
            "word": "малышку",
            "count": 2,
            "phrase": "я влюбилась в эту малышку"
          },
          {
            "word": "такую",
            "count": 27,
            "phrase": "за такую цену"
          },
          {
            "word": "цену",
            "count": 10,
            "phrase": "за такую цену"
          },
          {
            "word": "пальто",
            "count": 34,
            "phrase": "спасла пальто которое собиралась выбрасывать из - за катышек"
          },
          {
            "word": "которое",
            "count": 6,
            "phrase": "спасла пальто которое собиралась выбрасывать из - за катышек"
          },
          {
            "word": "собиралась",
            "count": 2,
            "phrase": "спасла пальто которое собиралась выбрасывать из - за катышек"
          },
          {
            "word": "выбрасывать",
            "count": 7,
            "phrase": "спасла пальто которое собиралась выбрасывать из - за катышек"
          },
          {
            "word": "одной",
            "count": 8,
            "phrase": "коробка мятая и с одной стороны открыта наклейка было"
          },
          {
            "word": "стороны",
            "count": 4,
            "phrase": "коробка мятая и с одной стороны открыта наклейка было"
          },
          {
            "word": "открыта",
            "count": 4,
            "phrase": "коробка мятая и с одной стороны открыта наклейка было"
          },
          {
            "word": "наклейка",
            "count": 1,
            "phrase": "коробка мятая и с одной стороны открыта наклейка было"
          },
          {
            "word": "новая",
            "count": 21,
            "phrase": "но машинка новая и работает хорошо"
          },
          {
            "word": "вине",
            "count": 1,
            "phrase": "достаточно мощная по мой вине даже дырочка появилась небольшая(надавила сильно"
          },
          {
            "word": "дырочка",
            "count": 1,
            "phrase": "достаточно мощная по мой вине даже дырочка появилась небольшая(надавила сильно"
          },
          {
            "word": "появилась",
            "count": 6,
            "phrase": "достаточно мощная по мой вине даже дырочка появилась небольшая(надавила сильно"
          },
          {
            "word": "небольшая(надавила",
            "count": 1,
            "phrase": "достаточно мощная по мой вине даже дырочка появилась небольшая(надавила сильно"
          },
          {
            "word": "сильно",
            "count": 20,
            "phrase": "достаточно мощная по мой вине даже дырочка появилась небольшая(надавила сильно"
          },
          {
            "word": "разбирается",
            "count": 8,
            "phrase": "все разбирается и собирается достаточно легко и просто"
          },
          {
            "word": "собирается",
            "count": 2,
            "phrase": "все разбирается и собирается достаточно легко и просто"
          },
          {
            "word": "очищаю",
            "count": 2,
            "phrase": "на фото я её очищаю от катышков"
          },
          {
            "word": "нажется",
            "count": 1,
            "phrase": "мне нажется"
          },
          {
            "word": "слова",
            "count": 6,
            "phrase": "слова тут лишние"
          },
          {
            "word": "тут",
            "count": 10,
            "phrase": "слова тут лишние"
          },
          {
            "word": "лишние",
            "count": 1,
            "phrase": "слова тут лишние"
          },
          {
            "word": "посмотрите",
            "count": 2,
            "phrase": "просто посмотрите результат"
          },
          {
            "word": "рада",
            "count": 22,
            "phrase": "очень рада"
          },
          {
            "word": "преобрела",
            "count": 1,
            "phrase": "что преобрела такой качественный товар"
          },
          {
            "word": "такой",
            "count": 20,
            "phrase": "что преобрела такой качественный товар"
          },
          {
            "word": "качественный",
            "count": 10,
            "phrase": "что преобрела такой качественный товар"
          },
          {
            "word": "большое",
            "count": 17,
            "phrase": "большое вам спасибо"
          },
          {
            "word": "месяца",
            "count": 4,
            "phrase": "пользуюсь уже больше месяца"
          },
          {
            "word": "заряжала",
            "count": 9,
            "phrase": "еще даже не заряжала"
          },
          {
            "word": "становятся",
            "count": 6,
            "phrase": "вещи после нее становятся как новые"
          },
          {
            "word": "новые",
            "count": 44,
            "phrase": "вещи после нее становятся как новые"
          },
          {
            "word": "идёт",
            "count": 12,
            "phrase": "в комплекте идёт шнур"
          },
          {
            "word": "очищенный",
            "count": 2,
            "phrase": "слева очищенный носок машинкой"
          },
          {
            "word": "второй",
            "count": 8,
            "phrase": "справа второй неочищенный"
          },
          {
            "word": "неочищенный",
            "count": 1,
            "phrase": "справа второй неочищенный"
          },
          {
            "word": "сложный",
            "count": 1,
            "phrase": "это конечно сложный объект"
          },
          {
            "word": "объект",
            "count": 1,
            "phrase": "это конечно сложный объект"
          },
          {
            "word": "упаковке",
            "count": 15,
            "phrase": "машинка пришла в упаковке"
          },
          {
            "word": "щёточкой",
            "count": 1,
            "phrase": "с щёточкой для чистки и usb для зарядки"
          },
          {
            "word": "usb",
            "count": 8,
            "phrase": "с щёточкой для чистки и usb для зарядки"
          },
          {
            "word": "красивая",
            "count": 3,
            "phrase": "машинка красивая и удобная в использовании"
          },
          {
            "word": "использовании",
            "count": 20,
            "phrase": "машинка красивая и удобная в использовании"
          },
          {
            "word": "зверь",
            "count": 4,
            "phrase": "работает как зверь"
          },
          {
            "word": "жила",
            "count": 19,
            "phrase": "как я без нее жила"
          },
          {
            "word": "карман",
            "count": 1,
            "phrase": "почистила карман на пальто - рубашка и пищу от восторга"
          },
          {
            "word": "рубашка",
            "count": 1,
            "phrase": "почистила карман на пальто - рубашка и пищу от восторга"
          },
          {
            "word": "пищу",
            "count": 1,
            "phrase": "почистила карман на пальто - рубашка и пищу от восторга"
          },
          {
            "word": "восторга",
            "count": 2,
            "phrase": "почистила карман на пальто - рубашка и пищу от восторга"
          },
          {
            "word": "лучший",
            "count": 2,
            "phrase": "однозначно лучший девайс для удаления катышек"
          },
          {
            "word": "девайс",
            "count": 7,
            "phrase": "однозначно лучший девайс для удаления катышек"
          },
          {
            "word": "смотрите",
            "count": 7,
            "phrase": "смотрите фото"
          },
          {
            "word": "произведет",
            "count": 0,
            "phrase": "произведет эффект вау"
          },
          {
            "word": "эффект",
            "count": 20,
            "phrase": "произведет эффект вау"
          },
          {
            "word": "соответствует",
            "count": 9,
            "phrase": "всё соответствует описанию"
          },
          {
            "word": "описанию",
            "count": 7,
            "phrase": "всё соответствует описанию"
          },
          {
            "word": "кажется",
            "count": 4,
            "phrase": "мне кажется по фото можно понять что она отлично работает"
          },
          {
            "word": "понять",
            "count": 2,
            "phrase": "мне кажется по фото можно понять что она отлично работает"
          },
          {
            "word": "спасает",
            "count": 3,
            "phrase": "она спасает вещи"
          },
          {
            "word": "чудесное",
            "count": 0,
            "phrase": "чудесное устройство"
          },
          {
            "word": "устройство",
            "count": 10,
            "phrase": "чудесное устройство"
          },
          {
            "word": "катышками",
            "count": 36,
            "phrase": "справляется с катышками"
          },
          {
            "word": "держит",
            "count": 22,
            "phrase": "держит батарею"
          },
          {
            "word": "батарею",
            "count": 1,
            "phrase": "держит батарею"
          },
          {
            "word": "хороший",
            "count": 11,
            "phrase": "хороший аппарат"
          },
          {
            "word": "минутной",
            "count": 1,
            "phrase": "после 15 минутной бесперебойной работы заряд все еще держит"
          },
          {
            "word": "бесперебойной",
            "count": 1,
            "phrase": "после 15 минутной бесперебойной работы заряд все еще держит"
          },
          {
            "word": "работы",
            "count": 13,
            "phrase": "после 15 минутной бесперебойной работы заряд все еще держит"
          },
          {
            "word": "заряд",
            "count": 171,
            "phrase": "после 15 минутной бесперебойной работы заряд все еще держит"
          },
          {
            "word": "розетку",
            "count": 2,
            "phrase": "заряжается через розетку"
          },
          {
            "word": "прекрасная",
            "count": 3,
            "phrase": "прекрасная вещь"
          },
          {
            "word": "понимаю",
            "count": 4,
            "phrase": "не понимаю как жила без нее раньше"
          },
          {
            "word": "прикладывать",
            "count": 1,
            "phrase": "даже не нужно прикладывать больших усилий"
          },
          {
            "word": "шикарно",
            "count": 4,
            "phrase": "выглядит шикарно"
          },
          {
            "word": "отличный",
            "count": 15,
            "phrase": "в использовании отличный"
          },
          {
            "word": "большой",
            "count": 9,
            "phrase": "большой плюс что не на батарейках"
          },
          {
            "word": "увлечься",
            "count": 1,
            "phrase": "если сильно увлечься можно сделать дыру"
          },
          {
            "word": "сделать",
            "count": 3,
            "phrase": "если сильно увлечься можно сделать дыру"
          },
          {
            "word": "дыру",
            "count": 1,
            "phrase": "если сильно увлечься можно сделать дыру"
          },
          {
            "word": "идет",
            "count": 13,
            "phrase": "зарядка идет в комплекте"
          },
          {
            "word": "задачей",
            "count": 90,
            "phrase": "всё отлично со своей задачей справляется ооочень хорошо"
          },
          {
            "word": "ооочень",
            "count": 4,
            "phrase": "всё отлично со своей задачей справляется ооочень хорошо"
          },
          {
            "word": "передаёт",
            "count": 2,
            "phrase": "фото не так передаёт"
          },
          {
            "word": "почему",
            "count": 10,
            "phrase": "почему раньше не заказала блин"
          },
          {
            "word": "заказала",
            "count": 13,
            "phrase": "почему раньше не заказала блин"
          },
          {
            "word": "раз",
            "count": 212,
            "phrase": "первый раз три года назад брали"
          },
          {
            "word": "назад",
            "count": 7,
            "phrase": "первый раз три года назад брали"
          },
          {
            "word": "брали",
            "count": 3,
            "phrase": "первый раз три года назад брали"
          },
          {
            "word": "сих",
            "count": 5,
            "phrase": "до сих пор жива"
          },
          {
            "word": "пор",
            "count": 70,
            "phrase": "до сих пор жива"
          },
          {
            "word": "жива",
            "count": 8,
            "phrase": "до сих пор жива"
          },
          {
            "word": "минуты",
            "count": 5,
            "phrase": "3 минуты и готово!!!супер быстро и"
          },
          {
            "word": "готово!!!супер",
            "count": 1,
            "phrase": "3 минуты и готово!!!супер быстро и"
          },
          {
            "word": "верила",
            "count": 19,
            "phrase": "не верила"
          },
          {
            "word": "будет",
            "count": 38,
            "phrase": "что будет такой эффект"
          },
          {
            "word": "восторге",
            "count": 37,
            "phrase": "в восторге"
          },
          {
            "word": "пожалеете!!!результат",
            "count": 1,
            "phrase": "берите не пожалеете!!!результат на фото"
          },
          {
            "word": "хорошая",
            "count": 27,
            "phrase": "хорошая штука"
          },
          {
            "word": "шустрее",
            "count": 1,
            "phrase": "думала будет немного шустрее конечно"
          },
          {
            "word": "критичного",
            "count": 1,
            "phrase": "но ничего критичного"
          },
          {
            "word": "весь",
            "count": 10,
            "phrase": "весь дом пошла чистить"
          },
          {
            "word": "дом",
            "count": 59,
            "phrase": "весь дом пошла чистить"
          },
          {
            "word": "пошла",
            "count": 5,
            "phrase": "весь дом пошла чистить"
          },
          {
            "word": "разница",
            "count": 9,
            "phrase": "разница очевидна"
          },
          {
            "word": "очевидна",
            "count": 5,
            "phrase": "разница очевидна"
          },
          {
            "word": "сделаешь",
            "count": 2,
            "phrase": "на батарейках такого не сделаешь"
          },
          {
            "word": "одну",
            "count": 5,
            "phrase": "ну или не одну пачку придется использовать"
          },
          {
            "word": "пачку",
            "count": 1,
            "phrase": "ну или не одну пачку придется использовать"
          },
          {
            "word": "придется",
            "count": 3,
            "phrase": "ну или не одну пачку придется использовать"
          },
          {
            "word": "использовать",
            "count": 3,
            "phrase": "ну или не одну пачку придется использовать"
          },
          {
            "word": "гениальная",
            "count": 0,
            "phrase": "гениальная вещь"
          },
          {
            "word": "практический",
            "count": 1,
            "phrase": "спасла мой практический новый брючный костюм"
          },
          {
            "word": "брючный",
            "count": 1,
            "phrase": "спасла мой практический новый брючный костюм"
          },
          {
            "word": "костюм",
            "count": 10,
            "phrase": "спасла мой практический новый брючный костюм"
          },
          {
            "word": "процесс",
            "count": 10,
            "phrase": "процесс удаления катышков затягивает"
          },
          {
            "word": "затягивает",
            "count": 2,
            "phrase": "процесс удаления катышков затягивает"
          },
          {
            "word": "хочется",
            "count": 4,
            "phrase": "хочется ещё"
          },
          {
            "word": "этот",
            "count": 13,
            "phrase": "спасибо за этот товар"
          },
          {
            "word": "идеальна",
            "count": 3,
            "phrase": "она идеальна"
          },
          {
            "word": "считанные",
            "count": 2,
            "phrase": "справляется с задачей за считанные минуты"
          },
          {
            "word": "какая",
            "count": 8,
            "phrase": "какая малышка"
          },
          {
            "word": "малышка",
            "count": 4,
            "phrase": "какая малышка"
          },
          {
            "word": "шустрая",
            "count": 1,
            "phrase": "а какая шустрая и работящая"
          },
          {
            "word": "работящая",
            "count": 1,
            "phrase": "а какая шустрая и работящая"
          },
          {
            "word": "брать",
            "count": 23,
            "phrase": "надо брать"
          },
          {
            "word": "ребята",
            "count": 1,
            "phrase": "надо брать ребята"
          },
          {
            "word": "вкусная",
            "count": 1,
            "phrase": "и цена вкусная"
          },
          {
            "word": "рукав",
            "count": 10,
            "phrase": "1 рукав обработан"
          },
          {
            "word": "обработан",
            "count": 8,
            "phrase": "1 рукав обработан"
          },
          {
            "word": "рукава",
            "count": 7,
            "phrase": "оба рукава обработаны"
          },
          {
            "word": "обработаны",
            "count": 1,
            "phrase": "оба рукава обработаны"
          },
          {
            "word": "комментировать",
            "count": 1,
            "phrase": "можно ничего не комментировать"
          },
          {
            "word": "меньше",
            "count": 9,
            "phrase": "думали будет меньше размером"
          },
          {
            "word": "размером",
            "count": 4,
            "phrase": "думали будет меньше размером"
          },
          {
            "word": "кабель",
            "count": 13,
            "phrase": "в комплекте щеточка и кабель для зарядки"
          },
          {
            "word": "штучка",
            "count": 3,
            "phrase": "супер штучка"
          },
          {
            "word": "маленькими",
            "count": 3,
            "phrase": "справляется как с маленькими катушками на одежде"
          },
          {
            "word": "катушками",
            "count": 3,
            "phrase": "справляется как с маленькими катушками на одежде"
          },
          {
            "word": "одежде",
            "count": 6,
            "phrase": "справляется как с маленькими катушками на одежде"
          },
          {
            "word": "большим",
            "count": 4,
            "phrase": "так и с большим объемом работы"
          },
          {
            "word": "объемом",
            "count": 2,
            "phrase": "так и с большим объемом работы"
          },
          {
            "word": "возьму",
            "count": 1,
            "phrase": "возьму еще"
          },
          {
            "word": "подарки",
            "count": 5,
            "phrase": "как подарки"
          },
          {
            "word": "капроновых",
            "count": 2,
            "phrase": "даже с капроновых колготок убирает катышки без следа"
          },
          {
            "word": "колготок",
            "count": 4,
            "phrase": "даже с капроновых колготок убирает катышки без следа"
          },
          {
            "word": "следа",
            "count": 1,
            "phrase": "даже с капроновых колготок убирает катышки без следа"
          },
          {
            "word": "суперкар",
            "count": 0,
            "phrase": "суперкар штука"
          },
          {
            "word": "сравнения",
            "count": 3,
            "phrase": "для сравнения полоска как было и стало"
          },
          {
            "word": "полоска",
            "count": 1,
            "phrase": "для сравнения полоска как было и стало"
          },
          {
            "word": "срезает",
            "count": 10,
            "phrase": "отлично срезает катышки"
          },
          {
            "word": "аккумулятора",
            "count": 8,
            "phrase": "работает от аккумулятора"
          },
          {
            "word": "заряжать",
            "count": 14,
            "phrase": "заряжать от usb"
          },
          {
            "word": "левой",
            "count": 3,
            "phrase": "в левой стороне уже удалены катышки а в правой нет"
          },
          {
            "word": "стороне",
            "count": 1,
            "phrase": "в левой стороне уже удалены катышки а в правой нет"
          },
          {
            "word": "удалены",
            "count": 1,
            "phrase": "в левой стороне уже удалены катышки а в правой нет"
          },
          {
            "word": "правой",
            "count": 3,
            "phrase": "в левой стороне уже удалены катышки а в правой нет"
          },
          {
            "word": "рвет",
            "count": 4,
            "phrase": "не рвет заряжается"
          },
          {
            "word": "батарейки",
            "count": 15,
            "phrase": "не батарейки"
          },
          {
            "word": "мшинка",
            "count": 0,
            "phrase": "мшинка супер"
          },
          {
            "word": "задачай",
            "count": 1,
            "phrase": "справляется со своей задачай на ура"
          },
          {
            "word": "прикладываю",
            "count": 1,
            "phrase": "фото прикладываю"
          },
          {
            "word": "лучшая",
            "count": 8,
            "phrase": "лучшая реанимация для вещей"
          },
          {
            "word": "реанимация",
            "count": 1,
            "phrase": "лучшая реанимация для вещей"
          },
          {
            "word": "устраивает",
            "count": 5,
            "phrase": "не пожалеете и цена устраивает"
          },
          {
            "word": "убрали",
            "count": 1,
            "phrase": "убрали катышки с кофты и сразу вид как новый"
          },
          {
            "word": "кофты",
            "count": 15,
            "phrase": "убрали катышки с кофты и сразу вид как новый"
          },
          {
            "word": "вид",
            "count": 119,
            "phrase": "убрали катышки с кофты и сразу вид как новый"
          },
          {
            "word": "полезный",
            "count": 2,
            "phrase": "спасибо большое за полезный товар"
          },
          {
            "word": "покупка",
            "count": 12,
            "phrase": "лучшая покупка"
          },
          {
            "word": "целой",
            "count": 3,
            "phrase": "пришла в целой коробке"
          },
          {
            "word": "коробке",
            "count": 26,
            "phrase": "пришла в целой коробке"
          },
          {
            "word": "радует",
            "count": 3,
            "phrase": "радует что можно заряжать"
          },
          {
            "word": "претензий",
            "count": 4,
            "phrase": "к машинке претензий нет"
          },
          {
            "word": "упаковки",
            "count": 11,
            "phrase": "но к упаковки и ее содержимое"
          },
          {
            "word": "содержимое",
            "count": 3,
            "phrase": "но к упаковки и ее содержимое"
          },
          {
            "word": "хотелось",
            "count": 3,
            "phrase": "хотелось бы получить их"
          },
          {
            "word": "еслибы",
            "count": 1,
            "phrase": "и еслибы сделала отказ"
          },
          {
            "word": "сделала",
            "count": 11,
            "phrase": "и еслибы сделала отказ"
          },
          {
            "word": "отказ",
            "count": 6,
            "phrase": "и еслибы сделала отказ"
          },
          {
            "word": "удержали",
            "count": 1,
            "phrase": "то и еще бы удержали 100 рублей"
          },
          {
            "word": "вина",
            "count": 6,
            "phrase": "хотя вина поставщика или упоковщика"
          },
          {
            "word": "поставщика",
            "count": 1,
            "phrase": "хотя вина поставщика или упоковщика"
          },
          {
            "word": "упоковщика",
            "count": 1,
            "phrase": "хотя вина поставщика или упоковщика"
          },
          {
            "word": "работать",
            "count": 16,
            "phrase": "не ожидала что так работать будет"
          },
          {
            "word": "необходимая",
            "count": 2,
            "phrase": "классная и необходимая вещь в быту"
          },
          {
            "word": "быту",
            "count": 4,
            "phrase": "классная и необходимая вещь в быту"
          },
          {
            "word": "невероятная",
            "count": 1,
            "phrase": "невероятная машинка"
          },
          {
            "word": "осталась",
            "count": 5,
            "phrase": "покупкой осталась довольна"
          },
          {
            "word": "скорость",
            "count": 1,
            "phrase": "скорость работы хорошая"
          },
          {
            "word": "самая",
            "count": 5,
            "phrase": "самая удачная покупка за последнее время"
          },
          {
            "word": "удачная",
            "count": 2,
            "phrase": "самая удачная покупка за последнее время"
          },
          {
            "word": "последнее",
            "count": 4,
            "phrase": "самая удачная покупка за последнее время"
          },
          {
            "word": "время",
            "count": 29,
            "phrase": "самая удачная покупка за последнее время"
          },
          {
            "word": "фактурой",
            "count": 1,
            "phrase": "даже на сильно фактурой кофте"
          },
          {
            "word": "кофте",
            "count": 3,
            "phrase": "даже на сильно фактурой кофте"
          },
          {
            "word": "находка",
            "count": 12,
            "phrase": "это просто находка"
          },
          {
            "word": "советую",
            "count": 31,
            "phrase": "советую всем без огворочно"
          },
          {
            "word": "огворочно",
            "count": 1,
            "phrase": "советую всем без огворочно"
          },
          {
            "word": "полезная",
            "count": 12,
            "phrase": "очень полезная вещь в хозяйстве"
          },
          {
            "word": "хозяйстве",
            "count": 2,
            "phrase": "очень полезная вещь в хозяйстве"
          },
          {
            "word": "невооружённым",
            "count": 2,
            "phrase": "до и после видно невооружённым взглядом"
          },
          {
            "word": "взглядом",
            "count": 2,
            "phrase": "до и после видно невооружённым взглядом"
          },
          {
            "word": "придает",
            "count": 1,
            "phrase": "придает вещам опрятность"
          },
          {
            "word": "вещам",
            "count": 23,
            "phrase": "придает вещам опрятность"
          },
          {
            "word": "опрятность",
            "count": 1,
            "phrase": "придает вещам опрятность"
          },
          {
            "word": "создаёт",
            "count": 1,
            "phrase": "создаёт впечатление что вещь становится снова новой"
          },
          {
            "word": "впечатление",
            "count": 4,
            "phrase": "создаёт впечатление что вещь становится снова новой"
          },
          {
            "word": "становится",
            "count": 5,
            "phrase": "создаёт впечатление что вещь становится снова новой"
          },
          {
            "word": "снова",
            "count": 9,
            "phrase": "создаёт впечатление что вещь становится снова новой"
          },
          {
            "word": "моё",
            "count": 3,
            "phrase": "моё мнение"
          },
          {
            "word": "мнение",
            "count": 2,
            "phrase": "моё мнение"
          },
          {
            "word": "разу",
            "count": 69,
            "phrase": "не разу не разрядился еще"
          },
          {
            "word": "разрядился",
            "count": 2,
            "phrase": "не разу не разрядился еще"
          },
          {
            "word": "гаджет",
            "count": 6,
            "phrase": "гаджет топ"
          },
          {
            "word": "топ",
            "count": 5,
            "phrase": "гаджет топ"
          },
          {
            "word": "столько",
            "count": 16,
            "phrase": "столько вещей уже обновила"
          },
          {
            "word": "обновила",
            "count": 4,
            "phrase": "столько вещей уже обновила"
          },
          {
            "word": "обработана",
            "count": 3,
            "phrase": "на фото обработана половина"
          },
          {
            "word": "половина",
            "count": 1,
            "phrase": "на фото обработана половина"
          },
          {
            "word": "дизайн",
            "count": 13,
            "phrase": "отличный дизайн"
          },
          {
            "word": "чудесная",
            "count": 0,
            "phrase": "чудесная машинка"
          },
          {
            "word": "разницу",
            "count": 3,
            "phrase": "разницу видно на фото"
          },
          {
            "word": "пока",
            "count": 30,
            "phrase": "пока все работает"
          },
          {
            "word": "катушки",
            "count": 17,
            "phrase": "катушки удаляет хорошо"
          },
          {
            "word": "коротко",
            "count": 0,
            "phrase": "коротко и ясно"
          },
          {
            "word": "ясно",
            "count": 1,
            "phrase": "коротко и ясно"
          },
          {
            "word": "шустро",
            "count": 2,
            "phrase": "работает шустро"
          },
          {
            "word": "любимую",
            "count": 3,
            "phrase": "машинка спасла не одну любимую вещь"
          },
          {
            "word": "особо",
            "count": 5,
            "phrase": "после может на фото не особо видно"
          },
          {
            "word": "занимает",
            "count": 2,
            "phrase": "занимает минимум места"
          },
          {
            "word": "минимум",
            "count": 2,
            "phrase": "занимает минимум места"
          },
          {
            "word": "места",
            "count": 2,
            "phrase": "занимает минимум места"
          },
          {
            "word": "идеально",
            "count": 21,
            "phrase": "убирает идеально катышки"
          },
          {
            "word": "толстовками",
            "count": 1,
            "phrase": "справилась с двумя толстовками и свитером"
          },
          {
            "word": "свитером",
            "count": 1,
            "phrase": "справилась с двумя толстовками и свитером"
          },
          {
            "word": "слов",
            "count": 16,
            "phrase": "вместо слов фото"
          },
          {
            "word": "говорит",
            "count": 6,
            "phrase": "говорит само за себя"
          },
          {
            "word": "само",
            "count": 21,
            "phrase": "говорит само за себя"
          },
          {
            "word": "упаковано",
            "count": 13,
            "phrase": "упаковано идеально"
          },
          {
            "word": "вещь!спасла",
            "count": 0,
            "phrase": "отличная вещь!спасла вещи от помойки)красивый дизайн"
          },
          {
            "word": "помойки)красивый",
            "count": 0,
            "phrase": "отличная вещь!спасла вещи от помойки)красивый дизайн"
          },
          {
            "word": "надолго",
            "count": 17,
            "phrase": "аккумулятора хватает надолго"
          },
          {
            "word": "лучшее",
            "count": 4,
            "phrase": "лучшее что я когда либо покупала"
          },
          {
            "word": "покупала",
            "count": 11,
            "phrase": "лучшее что я когда либо покупала"
          },
          {
            "word": "коротко-",
            "count": 0,
            "phrase": "коротко- 5 из 5 советую машинку"
          },
          {
            "word": "китайского",
            "count": 1,
            "phrase": "а пальто с китайского сайта не советую"
          },
          {
            "word": "сайта",
            "count": 2,
            "phrase": "а пальто с китайского сайта не советую"
          },
          {
            "word": "аккуратно",
            "count": 13,
            "phrase": "аккуратно удалила катышки с кофты"
          },
          {
            "word": "удалила",
            "count": 6,
            "phrase": "аккуратно удалила катышки с кофты"
          },
          {
            "word": "заменить",
            "count": 1,
            "phrase": "радует что легко можно заменить лезвия"
          },
          {
            "word": "которые",
            "count": 22,
            "phrase": "которые несложно найти"
          },
          {
            "word": "несложно",
            "count": 1,
            "phrase": "которые несложно найти"
          },
          {
            "word": "найти",
            "count": 3,
            "phrase": "которые несложно найти"
          },
          {
            "word": "простая",
            "count": 4,
            "phrase": "простая в эксплуатации"
          },
          {
            "word": "эксплуатации",
            "count": 4,
            "phrase": "простая в эксплуатации"
          },
          {
            "word": "заряда",
            "count": 12,
            "phrase": "заряда хватает надолго"
          },
          {
            "word": "детские",
            "count": 13,
            "phrase": "обновила детские вещи"
          },
          {
            "word": "нравится",
            "count": 10,
            "phrase": "мне нравится"
          },
          {
            "word": "важно",
            "count": 7,
            "phrase": "для меня это было важно"
          },
          {
            "word": "классный",
            "count": 5,
            "phrase": "классный аппарат"
          },
          {
            "word": "покрывало",
            "count": 1,
            "phrase": "покрывало очистила от катышек"
          },
          {
            "word": "очистила",
            "count": 58,
            "phrase": "покрывало очистила от катышек"
          },
          {
            "word": "класссс",
            "count": 0,
            "phrase": "класссс просто"
          },
          {
            "word": "тема",
            "count": 1,
            "phrase": "классная тема"
          },
          {
            "word": "шапкой",
            "count": 1,
            "phrase": "результат на фото с шапкой"
          },
          {
            "word": "стали",
            "count": 19,
            "phrase": "вещи стали как новенькие))смотри фото"
          },
          {
            "word": "новенькие))смотри",
            "count": 1,
            "phrase": "вещи стали как новенькие))смотри фото"
          },
          {
            "word": "отлчный",
            "count": 0,
            "phrase": "отлчный гаджет"
          },
          {
            "word": "катышк",
            "count": 290,
            "phrase": "катышк убирает быстро"
          },
          {
            "word": "работа",
            "count": 168,
            "phrase": "отличная работа машинки"
          },
          {
            "word": "помогает",
            "count": 2,
            "phrase": "помогает обновить вещи и не списывать их со счетов"
          },
          {
            "word": "обновить",
            "count": 3,
            "phrase": "помогает обновить вещи и не списывать их со счетов"
          },
          {
            "word": "списывать",
            "count": 2,
            "phrase": "помогает обновить вещи и не списывать их со счетов"
          },
          {
            "word": "счетов",
            "count": 1,
            "phrase": "помогает обновить вещи и не списывать их со счетов"
          },
          {
            "word": "курьер",
            "count": 1,
            "phrase": "я восторге спасибо курьер доставляет во время"
          },
          {
            "word": "доставляет",
            "count": 1,
            "phrase": "я восторге спасибо курьер доставляет во время"
          },
          {
            "word": "собирает",
            "count": 7,
            "phrase": "катушки собирает хорошо"
          },
          {
            "word": "заказываю",
            "count": 4,
            "phrase": "уже заказываю доп лезвия"
          },
          {
            "word": "доп",
            "count": 19,
            "phrase": "уже заказываю доп лезвия"
          },
          {
            "word": "уберает",
            "count": 1,
            "phrase": "уберает все катышки"
          },
          {
            "word": "результаты",
            "count": 1,
            "phrase": "результаты радуют"
          },
          {
            "word": "радуют",
            "count": 1,
            "phrase": "результаты радуют"
          },
          {
            "word": "хорошем",
            "count": 5,
            "phrase": "машинка пришла в хорошем состоянии"
          },
          {
            "word": "состоянии",
            "count": 19,
            "phrase": "машинка пришла в хорошем состоянии"
          },
          {
            "word": "заряжена",
            "count": 21,
            "phrase": "заряжена что дало возможность её сразу проверить на пвз"
          },
          {
            "word": "дало",
            "count": 9,
            "phrase": "заряжена что дало возможность её сразу проверить на пвз"
          },
          {
            "word": "возможность",
            "count": 8,
            "phrase": "заряжена что дало возможность её сразу проверить на пвз"
          },
          {
            "word": "пвз",
            "count": 2,
            "phrase": "заряжена что дало возможность её сразу проверить на пвз"
          },
          {
            "word": "подарок",
            "count": 24,
            "phrase": "на подарок уже не пойдёт"
          },
          {
            "word": "пойдёт",
            "count": 1,
            "phrase": "на подарок уже не пойдёт"
          },
          {
            "word": "снимает",
            "count": 12,
            "phrase": "катышки хорошо снимает мелкие и крупные"
          },
          {
            "word": "мелкие",
            "count": 11,
            "phrase": "катышки хорошо снимает мелкие и крупные"
          },
          {
            "word": "буду",
            "count": 6,
            "phrase": "буду заказывать еще"
          },
          {
            "word": "заказывать",
            "count": 4,
            "phrase": "буду заказывать еще"
          },
          {
            "word": "позаботьтесь",
            "count": 0,
            "phrase": "позаботьтесь пожалуйста об упаковке"
          },
          {
            "word": "дарить",
            "count": 3,
            "phrase": "чтоб дарить было красиво"
          },
          {
            "word": "красиво",
            "count": 1,
            "phrase": "чтоб дарить было красиво"
          },
          {
            "word": "мечтала",
            "count": 3,
            "phrase": "давно о такой машинке мечтала"
          },
          {
            "word": "батареек",
            "count": 12,
            "phrase": "без батареек"
          },
          {
            "word": "тайпси",
            "count": 4,
            "phrase": "заряжается от тайпси"
          },
          {
            "word": "шикарная",
            "count": 4,
            "phrase": "машинка шикарная"
          },
          {
            "word": "смотря",
            "count": 4,
            "phrase": "не смотря на то"
          },
          {
            "word": "китайском",
            "count": 8,
            "phrase": "что все на китайском"
          },
          {
            "word": "проста",
            "count": 5,
            "phrase": "проста в использовании и дает отличный результат"
          },
          {
            "word": "дает",
            "count": 7,
            "phrase": "проста в использовании и дает отличный результат"
          },
          {
            "word": "целым",
            "count": 3,
            "phrase": "все пришло целым и хорошо упакованным"
          },
          {
            "word": "упакованным",
            "count": 1,
            "phrase": "все пришло целым и хорошо упакованным"
          },
          {
            "word": "комплектность",
            "count": 1,
            "phrase": "комплектность соответствует"
          },
          {
            "word": "помятой",
            "count": 3,
            "phrase": "пришла машинка в слегка помятой коробке"
          },
          {
            "word": "принципе",
            "count": 2,
            "phrase": "в принципе не критично"
          },
          {
            "word": "критично",
            "count": 3,
            "phrase": "в принципе не критично"
          },
          {
            "word": "ощущается",
            "count": 1,
            "phrase": "машинка выглядит и ощущается в руке солидно"
          },
          {
            "word": "руке",
            "count": 29,
            "phrase": "машинка выглядит и ощущается в руке солидно"
          },
          {
            "word": "солидно",
            "count": 1,
            "phrase": "машинка выглядит и ощущается в руке солидно"
          },
          {
            "word": "легкий",
            "count": 1,
            "phrase": "несмотря на относительно легкий вес"
          },
          {
            "word": "вес",
            "count": 26,
            "phrase": "несмотря на относительно легкий вес"
          },
          {
            "word": "небольшая",
            "count": 5,
            "phrase": "небольшая щеточка и инструкция на китайском"
          },
          {
            "word": "шумит",
            "count": 2,
            "phrase": "и шумит не громко"
          },
          {
            "word": "громко",
            "count": 1,
            "phrase": "и шумит не громко"
          },
          {
            "word": "стоит",
            "count": 12,
            "phrase": "стоит отметить"
          },
          {
            "word": "отметить",
            "count": 3,
            "phrase": "стоит отметить"
          },
          {
            "word": "справится",
            "count": 4,
            "phrase": "что она не справится с застарелыми"
          },
          {
            "word": "застарелыми",
            "count": 1,
            "phrase": "что она не справится с застарелыми"
          },
          {
            "word": "залежавшимися",
            "count": 1,
            "phrase": "залежавшимися катышками"
          },
          {
            "word": "набраться",
            "count": 1,
            "phrase": "или придется набраться времени и терпения"
          },
          {
            "word": "терпения",
            "count": 2,
            "phrase": "или придется набраться времени и терпения"
          },
          {
            "word": "убрать",
            "count": 5,
            "phrase": "чтобы их убрать"
          },
          {
            "word": "маме",
            "count": 2,
            "phrase": "маме понравилось"
          },
          {
            "word": "сидит",
            "count": 2,
            "phrase": "сидит и чистит свою одежду уже час"
          },
          {
            "word": "одежду",
            "count": 12,
            "phrase": "сидит и чистит свою одежду уже час"
          },
          {
            "word": "рекомендуем",
            "count": 2,
            "phrase": "однозначно рекомендуем"
          },
          {
            "word": "прилагается",
            "count": 1,
            "phrase": "в комплекте прилагается шнур для зарядки type - c"
          },
          {
            "word": "type",
            "count": 10,
            "phrase": "в комплекте прилагается шнур для зарядки type - c"
          },
          {
            "word": "разобрать",
            "count": 2,
            "phrase": "легко разобрать и почистить"
          },
          {
            "word": "почистить",
            "count": 13,
            "phrase": "легко разобрать и почистить"
          },
          {
            "word": "приятная",
            "count": 4,
            "phrase": "очень приятная цена"
          },
          {
            "word": "раздумий",
            "count": 1,
            "phrase": "берите без раздумий"
          },
          {
            "word": "эпоху",
            "count": 1,
            "phrase": "в эпоху некачественных вещей это просто спасение"
          },
          {
            "word": "некачественных",
            "count": 1,
            "phrase": "в эпоху некачественных вещей это просто спасение"
          },
          {
            "word": "спасение",
            "count": 6,
            "phrase": "в эпоху некачественных вещей это просто спасение"
          },
          {
            "word": "искала",
            "count": 3,
            "phrase": "я искала без батареек"
          },
          {
            "word": "ранее",
            "count": 4,
            "phrase": "потому что ранее у меня была без батареек"
          },
          {
            "word": "магазинах",
            "count": 3,
            "phrase": "потому что в магазинах я не смогла найти"
          },
          {
            "word": "смогла",
            "count": 5,
            "phrase": "потому что в магазинах я не смогла найти"
          },
          {
            "word": "заряжаемая",
            "count": 1,
            "phrase": "что она заряжаемая - огромный плюс"
          },
          {
            "word": "деньги",
            "count": 18,
            "phrase": "товар отличный за свои деньги"
          },
          {
            "word": "потрясающая",
            "count": 2,
            "phrase": "вещь потрясающая 🥹еще и как антистресс"
          },
          {
            "word": "🥹еще",
            "count": 1,
            "phrase": "вещь потрясающая 🥹еще и как антистресс"
          },
          {
            "word": "заряженная",
            "count": 28,
            "phrase": "пришла уже заряженная"
          },
          {
            "word": "качество",
            "count": 17,
            "phrase": "отлично качество рекомендую"
          },
          {
            "word": "залипательный",
            "count": 3,
            "phrase": "и процесс залипательный"
          },
          {
            "word": "последние",
            "count": 2,
            "phrase": "лучшая покупка за последние время"
          },
          {
            "word": "надеюсь",
            "count": 15,
            "phrase": "надеюсь долго прослужит"
          },
          {
            "word": "ставлю",
            "count": 4,
            "phrase": "ставлю 5 звёзд"
          },
          {
            "word": "замечательное",
            "count": 1,
            "phrase": "замечательное качество"
          },
          {
            "word": "смотри",
            "count": 16,
            "phrase": "все на фото видно смотри"
          },
          {
            "word": "замечательная",
            "count": 1,
            "phrase": "замечательная машинка"
          },
          {
            "word": "лежит",
            "count": 19,
            "phrase": "удобно лежит в руке"
          },
          {
            "word": "сохранилось",
            "count": 1,
            "phrase": "столько вещей бы сохранилось"
          },
          {
            "word": "щеткой",
            "count": 1,
            "phrase": "машинка 🔥 пришла с щеткой и зарядкой"
          },
          {
            "word": "зарядкой",
            "count": 1,
            "phrase": "машинка 🔥 пришла с щеткой и зарядкой"
          },
          {
            "word": "цены",
            "count": 5,
            "phrase": "для своей цены с удалением катышков справляется хорошо"
          },
          {
            "word": "удалением",
            "count": 2,
            "phrase": "для своей цены с удалением катышков справляется хорошо"
          },
          {
            "word": "заказывая",
            "count": 0,
            "phrase": "заказывая многого не ожидала"
          },
          {
            "word": "получила",
            "count": 2,
            "phrase": "но получила даже больше"
          },
          {
            "word": "отвечу",
            "count": 0,
            "phrase": "отвечу на все вопросы сразу"
          },
          {
            "word": "вопросы",
            "count": 2,
            "phrase": "отвечу на все вопросы сразу"
          },
          {
            "word": "лезвий",
            "count": 8,
            "phrase": "лезвий запасных нет и провода для зарядки тоже"
          },
          {
            "word": "запасных",
            "count": 1,
            "phrase": "лезвий запасных нет и провода для зарядки тоже"
          },
          {
            "word": "провода",
            "count": 5,
            "phrase": "лезвий запасных нет и провода для зарядки тоже"
          },
          {
            "word": "запаковано",
            "count": 1,
            "phrase": "на вид запаковано аккуратно и качественно"
          },
          {
            "word": "дней",
            "count": 4,
            "phrase": "несколько дней поиспользую и дополню отзыв"
          },
          {
            "word": "поиспользую",
            "count": 1,
            "phrase": "несколько дней поиспользую и дополню отзыв"
          },
          {
            "word": "дополню",
            "count": 1,
            "phrase": "несколько дней поиспользую и дополню отзыв"
          },
          {
            "word": "отзыв",
            "count": 29,
            "phrase": "несколько дней поиспользую и дополню отзыв"
          },
          {
            "word": "вещица",
            "count": 4,
            "phrase": "отличная вещица"
          },
          {
            "word": "плотный",
            "count": 0,
            "phrase": "плотный пластик"
          },
          {
            "word": "пластик",
            "count": 7,
            "phrase": "плотный пластик"
          },
          {
            "word": "достойно",
            "count": 2,
            "phrase": "выглядит достойно"
          },
          {
            "word": "сестре",
            "count": 4,
            "phrase": "сестре и дочке"
          },
          {
            "word": "дочке",
            "count": 1,
            "phrase": "сестре и дочке"
          },
          {
            "word": "вычистила",
            "count": 0,
            "phrase": "вычистила брюки и не оставила ни одного катышка"
          },
          {
            "word": "одного",
            "count": 6,
            "phrase": "вычистила брюки и не оставила ни одного катышка"
          },
          {
            "word": "катышка",
            "count": 48,
            "phrase": "вычистила брюки и не оставила ни одного катышка"
          },
          {
            "word": "хотели",
            "count": 1,
            "phrase": "хотели покупать новые брюки"
          },
          {
            "word": "покупать",
            "count": 10,
            "phrase": "хотели покупать новые брюки"
          },
          {
            "word": "обошлась",
            "count": 2,
            "phrase": "и обошлась в 3 раза дешевле"
          },
          {
            "word": "дешевле",
            "count": 8,
            "phrase": "и обошлась в 3 раза дешевле"
          },
          {
            "word": "полностью",
            "count": 15,
            "phrase": "машинка полностью себя окупила и оправдала все ожидания"
          },
          {
            "word": "окупила",
            "count": 1,
            "phrase": "машинка полностью себя окупила и оправдала все ожидания"
          },
          {
            "word": "оправдала",
            "count": 4,
            "phrase": "машинка полностью себя окупила и оправдала все ожидания"
          },
          {
            "word": "ожидания",
            "count": 11,
            "phrase": "машинка полностью себя окупила и оправдала все ожидания"
          },
          {
            "word": "всю",
            "count": 5,
            "phrase": "всю одежду почистила"
          },
          {
            "word": "сети",
            "count": 15,
            "phrase": "а зарядка от сети"
          },
          {
            "word": "очищает",
            "count": 16,
            "phrase": "очищает очень хорошо"
          },
          {
            "word": "приобрела",
            "count": 10,
            "phrase": "рада что приобрела эту малышку"
          },
          {
            "word": "взяла",
            "count": 7,
            "phrase": "взяла протестировать самые задрипаные носки"
          },
          {
            "word": "протестировать",
            "count": 2,
            "phrase": "взяла протестировать самые задрипаные носки"
          },
          {
            "word": "самые",
            "count": 8,
            "phrase": "взяла протестировать самые задрипаные носки"
          },
          {
            "word": "задрипаные",
            "count": 1,
            "phrase": "взяла протестировать самые задрипаные носки"
          },
          {
            "word": "приобретают",
            "count": 3,
            "phrase": "вещи действительно приобретают нормальный вид"
          },
          {
            "word": "нормальный",
            "count": 3,
            "phrase": "вещи действительно приобретают нормальный вид"
          },
          {
            "word": "домашние",
            "count": 3,
            "phrase": "домашние треники стали не такими уж и домашними"
          },
          {
            "word": "треники",
            "count": 1,
            "phrase": "домашние треники стали не такими уж и домашними"
          },
          {
            "word": "такими",
            "count": 1,
            "phrase": "домашние треники стали не такими уж и домашними"
          },
          {
            "word": "домашними",
            "count": 1,
            "phrase": "домашние треники стали не такими уж и домашними"
          },
          {
            "word": "мужа",
            "count": 7,
            "phrase": "и носки мужа решила пока не выкидывать"
          },
          {
            "word": "выкидывать",
            "count": 6,
            "phrase": "и носки мужа решила пока не выкидывать"
          },
          {
            "word": "бесили",
            "count": 1,
            "phrase": "которые бесили своим видом 😃 пусть еще поживут теперь"
          },
          {
            "word": "своим",
            "count": 10,
            "phrase": "которые бесили своим видом 😃 пусть еще поживут теперь"
          },
          {
            "word": "видом",
            "count": 1,
            "phrase": "которые бесили своим видом 😃 пусть еще поживут теперь"
          },
          {
            "word": "поживут",
            "count": 1,
            "phrase": "которые бесили своим видом 😃 пусть еще поживут теперь"
          },
          {
            "word": "служить",
            "count": 3,
            "phrase": "надеюсь будет служить долго"
          },
          {
            "word": "всёвсё",
            "count": 0,
            "phrase": "всёвсё работает отлично"
          },
          {
            "word": "примеры",
            "count": 1,
            "phrase": "примеры есть в других отзывах"
          },
          {
            "word": "других",
            "count": 7,
            "phrase": "примеры есть в других отзывах"
          },
          {
            "word": "отзывах",
            "count": 2,
            "phrase": "примеры есть в других отзывах"
          },
          {
            "word": "ребёнок",
            "count": 3,
            "phrase": "если ребёнок включит машинку и открутит сетку она выключится"
          },
          {
            "word": "включит",
            "count": 4,
            "phrase": "если ребёнок включит машинку и открутит сетку она выключится"
          },
          {
            "word": "открутит",
            "count": 2,
            "phrase": "если ребёнок включит машинку и открутит сетку она выключится"
          },
          {
            "word": "сетку",
            "count": 2,
            "phrase": "если ребёнок включит машинку и открутит сетку она выключится"
          },
          {
            "word": "выключится",
            "count": 1,
            "phrase": "если ребёнок включит машинку и открутит сетку она выключится"
          },
          {
            "word": "недостатков",
            "count": 2,
            "phrase": "не без недостатков конечно"
          },
          {
            "word": "налипают",
            "count": 1,
            "phrase": "а налипают на стенки"
          },
          {
            "word": "стенки",
            "count": 1,
            "phrase": "а налипают на стенки"
          },
          {
            "word": "остальном",
            "count": 2,
            "phrase": "в остальном очень достойная машинка"
          },
          {
            "word": "достойная",
            "count": 1,
            "phrase": "в остальном очень достойная машинка"
          },
          {
            "word": "отверстие",
            "count": 1,
            "phrase": "отверстие как у других"
          },
          {
            "word": "забилось",
            "count": 1,
            "phrase": "у меня не забилось"
          },
          {
            "word": "верю",
            "count": 1,
            "phrase": "но верю"
          },
          {
            "word": "такое",
            "count": 8,
            "phrase": "что такое может возникнуть"
          },
          {
            "word": "возникнуть",
            "count": 1,
            "phrase": "что такое может возникнуть"
          },
          {
            "word": "чистится",
            "count": 7,
            "phrase": "чистится машинка очень легко"
          },
          {
            "word": "щётка",
            "count": 3,
            "phrase": "в комплекте есть щётка"
          },
          {
            "word": "удобен",
            "count": 2,
            "phrase": "очень удобен в использовании рекомендую"
          },
          {
            "word": "жалею",
            "count": 5,
            "phrase": "жалею что не сделала этого раньше"
          },
          {
            "word": "лёгкая",
            "count": 4,
            "phrase": "при этом лёгкая"
          },
          {
            "word": "обслуживании",
            "count": 1,
            "phrase": "лёгкая в эксплуатации и обслуживании"
          },
          {
            "word": "брала",
            "count": 39,
            "phrase": "что я брала"
          },
          {
            "word": "моем",
            "count": 6,
            "phrase": "как на моем телефоне"
          },
          {
            "word": "телефоне",
            "count": 1,
            "phrase": "как на моем телефоне"
          },
          {
            "word": "осторожнее",
            "count": 1,
            "phrase": "будьте осторожнее"
          },
          {
            "word": "дырки",
            "count": 3,
            "phrase": "я сделала уже две дырки на носках"
          },
          {
            "word": "разъем",
            "count": 3,
            "phrase": "мне кажется это шикарная вещь разъем тайпси"
          },
          {
            "word": "кардиган",
            "count": 11,
            "phrase": "почистила кардиган и блузку за раз"
          },
          {
            "word": "блузку",
            "count": 2,
            "phrase": "почистила кардиган и блузку за раз"
          },
          {
            "word": "выложила",
            "count": 1,
            "phrase": "результат своей работы я выложила"
          },
          {
            "word": "опрятней",
            "count": 1,
            "phrase": "вещи стали опрятней выглядеть"
          },
          {
            "word": "почти",
            "count": 9,
            "phrase": "почти как новые"
          },
          {
            "word": "самое",
            "count": 6,
            "phrase": "а это самое главное"
          },
          {
            "word": "получения",
            "count": 2,
            "phrase": "после ее получения"
          },
          {
            "word": "апреля",
            "count": 1,
            "phrase": "с апреля месяца служит верой и правдой"
          },
          {
            "word": "служит",
            "count": 27,
            "phrase": "с апреля месяца служит верой и правдой"
          },
          {
            "word": "верой",
            "count": 2,
            "phrase": "с апреля месяца служит верой и правдой"
          },
          {
            "word": "правдой",
            "count": 2,
            "phrase": "с апреля месяца служит верой и правдой"
          },
          {
            "word": "размерам",
            "count": 2,
            "phrase": "по размерам даже больше чем я думала"
          },
          {
            "word": "стильно",
            "count": 4,
            "phrase": "выглядит очень стильно"
          },
          {
            "word": "палочка",
            "count": 0,
            "phrase": "палочка - выручалочка прям"
          },
          {
            "word": "выручалочка",
            "count": 1,
            "phrase": "палочка - выручалочка прям"
          },
          {
            "word": "средние",
            "count": 2,
            "phrase": "большие и средние катышки убирает на ура"
          },
          {
            "word": "сеточка",
            "count": 2,
            "phrase": "сеточка из прочного металла"
          },
          {
            "word": "прочного",
            "count": 1,
            "phrase": "сеточка из прочного металла"
          },
          {
            "word": "металла",
            "count": 1,
            "phrase": "сеточка из прочного металла"
          },
          {
            "word": "месяцев",
            "count": 5,
            "phrase": "пользуюсь уже несколько месяцев"
          },
          {
            "word": "опробовал",
            "count": 20,
            "phrase": "уже опробовал"
          },
          {
            "word": "чуть",
            "count": 9,
            "phrase": "коробка чуть пострадала при доставке"
          },
          {
            "word": "пострадала",
            "count": 1,
            "phrase": "коробка чуть пострадала при доставке"
          },
          {
            "word": "доставке",
            "count": 4,
            "phrase": "коробка чуть пострадала при доставке"
          },
          {
            "word": "тяжелее",
            "count": 1,
            "phrase": "мелкие тяжелее удалить"
          },
          {
            "word": "удалить",
            "count": 1,
            "phrase": "мелкие тяжелее удалить"
          },
          {
            "word": "сравнению",
            "count": 2,
            "phrase": "по сравнению с этим аппаратом и его точно хватит надолго"
          },
          {
            "word": "аппаратом",
            "count": 2,
            "phrase": "по сравнению с этим аппаратом и его точно хватит надолго"
          },
          {
            "word": "хватит",
            "count": 5,
            "phrase": "по сравнению с этим аппаратом и его точно хватит надолго"
          },
          {
            "word": "очищать",
            "count": 4,
            "phrase": "машинку также легко очищать"
          },
          {
            "word": "быстрой",
            "count": 1,
            "phrase": "на фото - результат быстрой чистки"
          },
          {
            "word": "прошлась",
            "count": 3,
            "phrase": "я пару раз прошлась и тут же фото сделала"
          },
          {
            "word": "нескрупулезно",
            "count": 1,
            "phrase": "то есть нескрупулезно - иначе эффект мог быть ещё круче"
          },
          {
            "word": "иначе",
            "count": 2,
            "phrase": "то есть нескрупулезно - иначе эффект мог быть ещё круче"
          },
          {
            "word": "мог",
            "count": 37,
            "phrase": "то есть нескрупулезно - иначе эффект мог быть ещё круче"
          },
          {
            "word": "круче",
            "count": 2,
            "phrase": "то есть нескрупулезно - иначе эффект мог быть ещё круче"
          },
          {
            "word": "потрясающие",
            "count": 1,
            "phrase": "просто потрясающие"
          },
          {
            "word": "поразительный",
            "count": 1,
            "phrase": "поразительный результат"
          },
          {
            "word": "приходилось",
            "count": 5,
            "phrase": "столько раз приходилось убирать вручную 😬 быстрая доставка"
          },
          {
            "word": "убирать",
            "count": 5,
            "phrase": "столько раз приходилось убирать вручную 😬 быстрая доставка"
          },
          {
            "word": "вручную",
            "count": 1,
            "phrase": "столько раз приходилось убирать вручную 😬 быстрая доставка"
          },
          {
            "word": "выкинуть",
            "count": 4,
            "phrase": "что вещи можно выкинуть"
          },
          {
            "word": "сверху",
            "count": 4,
            "phrase": "сверху ещё была плёнка"
          },
          {
            "word": "плёнка",
            "count": 1,
            "phrase": "сверху ещё была плёнка"
          },
          {
            "word": "месте",
            "count": 16,
            "phrase": "все на месте"
          },
          {
            "word": "свитеров",
            "count": 4,
            "phrase": "почистила несколько свитеров"
          },
          {
            "word": "машмашинка",
            "count": 0,
            "phrase": "машмашинка отличнпя"
          },
          {
            "word": "отличнпя",
            "count": 1,
            "phrase": "машмашинка отличнпя"
          },
          {
            "word": "восхищении",
            "count": 1,
            "phrase": "но я просто в восхищении от этой машинки"
          },
          {
            "word": "работу",
            "count": 13,
            "phrase": "свои деньги отлично выполняет свою работу"
          },
          {
            "word": "которая",
            "count": 10,
            "phrase": "которая поможет вашей одежде сохранять презентабельный вид"
          },
          {
            "word": "поможет",
            "count": 5,
            "phrase": "которая поможет вашей одежде сохранять презентабельный вид"
          },
          {
            "word": "вашей",
            "count": 1,
            "phrase": "которая поможет вашей одежде сохранять презентабельный вид"
          },
          {
            "word": "сохранять",
            "count": 1,
            "phrase": "которая поможет вашей одежде сохранять презентабельный вид"
          },
          {
            "word": "презентабельный",
            "count": 1,
            "phrase": "которая поможет вашей одежде сохранять презентабельный вид"
          },
          {
            "word": "доставлена",
            "count": 2,
            "phrase": "но была доставлена в лучшем виде"
          },
          {
            "word": "лучшем",
            "count": 1,
            "phrase": "но была доставлена в лучшем виде"
          },
          {
            "word": "упаковка",
            "count": 18,
            "phrase": "упаковка не повреждена"
          },
          {
            "word": "повреждена",
            "count": 5,
            "phrase": "упаковка не повреждена"
          },
          {
            "word": "наборе",
            "count": 6,
            "phrase": "в наборе есть всё что указано продавцом"
          },
          {
            "word": "указано",
            "count": 1,
            "phrase": "в наборе есть всё что указано продавцом"
          },
          {
            "word": "продавцом",
            "count": 1,
            "phrase": "в наборе есть всё что указано продавцом"
          },
          {
            "word": "любых",
            "count": 2,
            "phrase": "машинка отлично удаляет катышки с любых вещей"
          },
          {
            "word": "держать",
            "count": 17,
            "phrase": "удобно держать в руке"
          },
          {
            "word": "кармашек",
            "count": 1,
            "phrase": "кармашек для мусора легко снимается"
          },
          {
            "word": "снимается",
            "count": 4,
            "phrase": "кармашек для мусора легко снимается"
          },
          {
            "word": "тяжёлая",
            "count": 1,
            "phrase": "не тяжёлая"
          },
          {
            "word": "громоздкая",
            "count": 1,
            "phrase": "не громоздкая"
          },
          {
            "word": "комфортна",
            "count": 1,
            "phrase": "хорошо лежит в руке и комфортна в использовании"
          },
          {
            "word": "зарядил",
            "count": 8,
            "phrase": "просто зарядил от юсб и всё"
          },
          {
            "word": "юсб",
            "count": 1,
            "phrase": "просто зарядил от юсб и всё"
          },
          {
            "word": "великолепно",
            "count": 4,
            "phrase": "чистит катышки великолепно"
          },
          {
            "word": "айс",
            "count": 19,
            "phrase": "упаковка конечно не айс"
          },
          {
            "word": "порвана",
            "count": 4,
            "phrase": "коробка порвана и была открыта"
          },
          {
            "word": "важнее",
            "count": 1,
            "phrase": "но мне важнее"
          },
          {
            "word": "исправно",
            "count": 4,
            "phrase": "что машинка целая и работает исправно"
          },
          {
            "word": "посмотрим",
            "count": 3,
            "phrase": "посмотрим сколько прослужит"
          },
          {
            "word": "упакован",
            "count": 34,
            "phrase": "хорошо упакован"
          },
          {
            "word": "смотрится",
            "count": 0,
            "phrase": "смотрится классно"
          },
          {
            "word": "классно",
            "count": 5,
            "phrase": "смотрится классно"
          },
          {
            "word": "руках",
            "count": 6,
            "phrase": "в руках удобен"
          },
          {
            "word": "лютый",
            "count": 1,
            "phrase": "просто топ лютый"
          },
          {
            "word": "особенно",
            "count": 8,
            "phrase": "особенно для бейпа"
          },
          {
            "word": "бейпа",
            "count": 1,
            "phrase": "особенно для бейпа"
          },
          {
            "word": "кабеля",
            "count": 3,
            "phrase": "зарядка от кабеля type"
          },
          {
            "word": "появились",
            "count": 2,
            "phrase": "ещё как только они появились"
          },
          {
            "word": "дешманскую",
            "count": 1,
            "phrase": "дешманскую на батарейках"
          },
          {
            "word": "той",
            "count": 72,
            "phrase": "после той"
          },
          {
            "word": "кароче",
            "count": 1,
            "phrase": "кароче прогресс пришел"
          },
          {
            "word": "прогресс",
            "count": 1,
            "phrase": "кароче прогресс пришел"
          },
          {
            "word": "космические",
            "count": 1,
            "phrase": "это просто космические технологии какие - то"
          },
          {
            "word": "технологии",
            "count": 1,
            "phrase": "это просто космические технологии какие - то"
          },
          {
            "word": "какие",
            "count": 4,
            "phrase": "это просто космические технологии какие - то"
          },
          {
            "word": "думаете",
            "count": 2,
            "phrase": "если вы все ещё думаете надо вам или нет"
          },
          {
            "word": "хату",
            "count": 1,
            "phrase": "я почистила всю хату уже"
          },
          {
            "word": "отвечаю",
            "count": 1,
            "phrase": "отвечаю этот аппарат за свои деньги просто находка"
          },
          {
            "word": "замечательно",
            "count": 8,
            "phrase": "машинка работает просто замечательно"
          },
          {
            "word": "нити",
            "count": 1,
            "phrase": "нити не рвет"
          },
          {
            "word": "закатанными",
            "count": 1,
            "phrase": "справилась даже с сильно закатанными вещами"
          },
          {
            "word": "вещами",
            "count": 4,
            "phrase": "справилась даже с сильно закатанными вещами"
          },
          {
            "word": "дала",
            "count": 37,
            "phrase": "дала им вторую жизнь"
          },
          {
            "word": "вторую",
            "count": 16,
            "phrase": "дала им вторую жизнь"
          },
          {
            "word": "трудновато",
            "count": 1,
            "phrase": "чистить трудновато"
          },
          {
            "word": "золото",
            "count": 1,
            "phrase": "но за свою цену - она просто золото"
          },
          {
            "word": "попробовать",
            "count": 8,
            "phrase": "решила заказать и попробовать эту машинку"
          },
          {
            "word": "хожу",
            "count": 2,
            "phrase": "хожу теперь ищу вещи"
          },
          {
            "word": "ищу",
            "count": 4,
            "phrase": "хожу теперь ищу вещи"
          },
          {
            "word": "скоро",
            "count": 1,
            "phrase": "скоро походу пойду еще по всем соседям"
          },
          {
            "word": "походу",
            "count": 1,
            "phrase": "скоро походу пойду еще по всем соседям"
          },
          {
            "word": "пойду",
            "count": 1,
            "phrase": "скоро походу пойду еще по всем соседям"
          },
          {
            "word": "соседям",
            "count": 1,
            "phrase": "скоро походу пойду еще по всем соседям"
          },
          {
            "word": "больно",
            "count": 1,
            "phrase": "уж больно мне понравился процесс"
          },
          {
            "word": "удивлена",
            "count": 3,
            "phrase": "приятно удивлена"
          },
          {
            "word": "полном",
            "count": 8,
            "phrase": "я в полном восторге от машинки"
          },
          {
            "word": "заказывала",
            "count": 3,
            "phrase": "заказывала специально для матраса"
          },
          {
            "word": "специально",
            "count": 5,
            "phrase": "заказывала специально для матраса"
          },
          {
            "word": "матраса",
            "count": 5,
            "phrase": "заказывала специально для матраса"
          },
          {
            "word": "удалил",
            "count": 9,
            "phrase": "удалил все катышки на ура"
          },
          {
            "word": "держал",
            "count": 4,
            "phrase": "заряд держал почти весь день"
          },
          {
            "word": "заряжает",
            "count": 27,
            "phrase": "заряжает буквально за час с небольшим"
          },
          {
            "word": "буквально",
            "count": 1,
            "phrase": "заряжает буквально за час с небольшим"
          },
          {
            "word": "небольшим",
            "count": 1,
            "phrase": "заряжает буквально за час с небольшим"
          },
          {
            "word": "зарядное",
            "count": 3,
            "phrase": "зарядное устройство есть"
          },
          {
            "word": "отчищает",
            "count": 1,
            "phrase": "отлично отчищает"
          },
          {
            "word": "пожалели",
            "count": 1,
            "phrase": "не пожалели"
          },
          {
            "word": "взяли",
            "count": 1,
            "phrase": "что взяли именно xiaomi"
          },
          {
            "word": "техники",
            "count": 2,
            "phrase": "потому что уже много техники этого бренда в доме"
          },
          {
            "word": "бренда",
            "count": 1,
            "phrase": "потому что уже много техники этого бренда в доме"
          },
          {
            "word": "чайника",
            "count": 1,
            "phrase": "от чайника до массажера"
          },
          {
            "word": "массажера",
            "count": 1,
            "phrase": "от чайника до массажера"
          },
          {
            "word": "мере",
            "count": 5,
            "phrase": "лучше очищать контейнер по мере наполнения"
          },
          {
            "word": "наполнения",
            "count": 1,
            "phrase": "лучше очищать контейнер по мере наполнения"
          },
          {
            "word": "подарил",
            "count": 3,
            "phrase": "подарил старым вещам новую жизнь"
          },
          {
            "word": "старым",
            "count": 1,
            "phrase": "подарил старым вещам новую жизнь"
          },
          {
            "word": "повозиться",
            "count": 1,
            "phrase": "с более мелкими придётся повозиться подольше"
          },
          {
            "word": "подольше",
            "count": 2,
            "phrase": "с более мелкими придётся повозиться подольше"
          },
          {
            "word": "целом",
            "count": 7,
            "phrase": "в целом отличный аппарат"
          },
          {
            "word": "незаменимая",
            "count": 5,
            "phrase": "незаменимая вещь"
          },
          {
            "word": "надежд",
            "count": 3,
            "phrase": "больших надежд не возлагала на этот девайс"
          },
          {
            "word": "возлагала",
            "count": 1,
            "phrase": "больших надежд не возлагала на этот девайс"
          },
          {
            "word": "оказалось",
            "count": 10,
            "phrase": "но как оказалось"
          },
          {
            "word": "крайней",
            "count": 2,
            "phrase": "по крайней мере пока"
          },
          {
            "word": "счищает",
            "count": 1,
            "phrase": "катушки счищает и крупные и очень мелкие"
          },
          {
            "word": "одежда",
            "count": 4,
            "phrase": "одежда и пледы как новые"
          },
          {
            "word": "пледы",
            "count": 1,
            "phrase": "одежда и пледы как новые"
          },
          {
            "word": "отмечу",
            "count": 1,
            "phrase": "сразу отмечу доставка вайлдберриз как обычно помяла всю коробку"
          },
          {
            "word": "вайлдберриз",
            "count": 1,
            "phrase": "сразу отмечу доставка вайлдберриз как обычно помяла всю коробку"
          },
          {
            "word": "обычно",
            "count": 4,
            "phrase": "сразу отмечу доставка вайлдберриз как обычно помяла всю коробку"
          },
          {
            "word": "помяла",
            "count": 2,
            "phrase": "сразу отмечу доставка вайлдберриз как обычно помяла всю коробку"
          },
          {
            "word": "коробку",
            "count": 9,
            "phrase": "сразу отмечу доставка вайлдберриз как обычно помяла всю коробку"
          },
          {
            "word": "всех",
            "count": 5,
            "phrase": "со всех сторон"
          },
          {
            "word": "сторон",
            "count": 10,
            "phrase": "со всех сторон"
          },
          {
            "word": "качестве",
            "count": 40,
            "phrase": "на качестве товара не отразилось"
          },
          {
            "word": "отразилось",
            "count": 2,
            "phrase": "на качестве товара не отразилось"
          },
          {
            "word": "щеточки",
            "count": 2,
            "phrase": "щеточки и инструкция на китайском"
          },
          {
            "word": "наклеечку",
            "count": 1,
            "phrase": "но на коробке есть наклеечку"
          },
          {
            "word": "куаркоду",
            "count": 1,
            "phrase": "по куаркоду можно скачать русскую версию"
          },
          {
            "word": "скачать",
            "count": 1,
            "phrase": "по куаркоду можно скачать русскую версию"
          },
          {
            "word": "русскую",
            "count": 1,
            "phrase": "по куаркоду можно скачать русскую версию"
          },
          {
            "word": "версию",
            "count": 1,
            "phrase": "по куаркоду можно скачать русскую версию"
          },
          {
            "word": "тест",
            "count": 10,
            "phrase": "тест машинке провела"
          },
          {
            "word": "провела",
            "count": 1,
            "phrase": "тест машинке провела"
          },
          {
            "word": "хорошей",
            "count": 3,
            "phrase": "машинка пришла в хорошей упаковке"
          },
          {
            "word": "запечатанная",
            "count": 2,
            "phrase": "коробка была полностью запечатанная"
          },
          {
            "word": "испорченная",
            "count": 1,
            "phrase": "не испорченная"
          },
          {
            "word": "товаром",
            "count": 2,
            "phrase": "товаром осталось полностью довольна"
          },
          {
            "word": "осталось",
            "count": 4,
            "phrase": "товаром осталось полностью довольна"
          },
          {
            "word": "хочу",
            "count": 5,
            "phrase": "хочу отдельно отметить"
          },
          {
            "word": "отдельно",
            "count": 7,
            "phrase": "хочу отдельно отметить"
          },
          {
            "word": "свой",
            "count": 6,
            "phrase": "но взяла товар на свой страх"
          },
          {
            "word": "страх",
            "count": 1,
            "phrase": "но взяла товар на свой страх"
          },
          {
            "word": "итоге",
            "count": 7,
            "phrase": "в итоге открыла дома"
          },
          {
            "word": "открыла",
            "count": 5,
            "phrase": "в итоге открыла дома"
          },
          {
            "word": "дома",
            "count": 19,
            "phrase": "в итоге открыла дома"
          },
          {
            "word": "цела",
            "count": 21,
            "phrase": "сама машинка полностью цела"
          },
          {
            "word": "запечатана",
            "count": 1,
            "phrase": "была запечатана в мешочке"
          },
          {
            "word": "мешочке",
            "count": 1,
            "phrase": "была запечатана в мешочке"
          },
          {
            "word": "вскрыта",
            "count": 10,
            "phrase": "не вскрыта"
          },
          {
            "word": "острые",
            "count": 5,
            "phrase": "лезвия острые"
          },
          {
            "word": "убирают",
            "count": 3,
            "phrase": "хорошо убирают катышки"
          },
          {
            "word": "убирали",
            "count": 1,
            "phrase": "вообще плохо убирали"
          },
          {
            "word": "значительно",
            "count": 1,
            "phrase": "этот значительно лучше"
          },
          {
            "word": "зарядку",
            "count": 16,
            "phrase": "зарядку пока нз сколько держит"
          },
          {
            "word": "изначально",
            "count": 3,
            "phrase": "изначально в ней сомневалась"
          },
          {
            "word": "колготки",
            "count": 17,
            "phrase": "детские колготки"
          },
          {
            "word": "яркими",
            "count": 1,
            "phrase": "носки прям яркими красками заиграли"
          },
          {
            "word": "красками",
            "count": 1,
            "phrase": "носки прям яркими красками заиграли"
          },
          {
            "word": "заиграли",
            "count": 1,
            "phrase": "носки прям яркими красками заиграли"
          },
          {
            "word": "такая",
            "count": 12,
            "phrase": "такая вещь должна быть в каждом доме"
          },
          {
            "word": "должна",
            "count": 4,
            "phrase": "такая вещь должна быть в каждом доме"
          },
          {
            "word": "видна",
            "count": 11,
            "phrase": "на фото разница видна"
          },
          {
            "word": "божественная",
            "count": 0,
            "phrase": "божественная штука"
          },
          {
            "word": "спасти",
            "count": 7,
            "phrase": "думала мой пиджак уже не спасти"
          },
          {
            "word": "новенький",
            "count": 3,
            "phrase": "выглядит как новенький"
          },
          {
            "word": "приобрести",
            "count": 6,
            "phrase": "давно хотела приобрести"
          },
          {
            "word": "руки",
            "count": 4,
            "phrase": "но как то руки не доходили"
          },
          {
            "word": "доходили",
            "count": 1,
            "phrase": "но как то руки не доходили"
          },
          {
            "word": "превзошёл",
            "count": 1,
            "phrase": "результат превзошёл все ожидания"
          },
          {
            "word": "матрац",
            "count": 1,
            "phrase": "на фото матрац до и после"
          },
          {
            "word": "производителю",
            "count": 8,
            "phrase": "спасибо производителю и продавцу"
          },
          {
            "word": "прибор",
            "count": 17,
            "phrase": "отличный прибор"
          },
          {
            "word": "очевиден",
            "count": 4,
            "phrase": "результат очевиден"
          },
          {
            "word": "прошло",
            "count": 2,
            "phrase": "прошло 2 месяца"
          },
          {
            "word": "использую",
            "count": 2,
            "phrase": "использую не регулярно"
          },
          {
            "word": "регулярно",
            "count": 2,
            "phrase": "использую не регулярно"
          },
          {
            "word": "лежали",
            "count": 1,
            "phrase": "внутри также лежали инструкция"
          },
          {
            "word": "сожалению",
            "count": 3,
            "phrase": "к сожалению"
          },
          {
            "word": "разъемом",
            "count": 1,
            "phrase": "небольшая зарядка с разъемом"
          },
          {
            "word": "тактильные",
            "count": 1,
            "phrase": "тактильные ощущения и вид"
          },
          {
            "word": "ощущения",
            "count": 1,
            "phrase": "тактильные ощущения и вид"
          },
          {
            "word": "отдельный",
            "count": 1,
            "phrase": "отдельный кайф"
          },
          {
            "word": "кайф",
            "count": 5,
            "phrase": "отдельный кайф"
          },
          {
            "word": "легкая",
            "count": 5,
            "phrase": "достаточно легкая"
          },
          {
            "word": "передняя",
            "count": 1,
            "phrase": "передняя поверхность матовая"
          },
          {
            "word": "поверхность",
            "count": 3,
            "phrase": "передняя поверхность матовая"
          },
          {
            "word": "матовая",
            "count": 1,
            "phrase": "передняя поверхность матовая"
          },
          {
            "word": "задняя",
            "count": 1,
            "phrase": "задняя глянцевая"
          },
          {
            "word": "глянцевая",
            "count": 1,
            "phrase": "задняя глянцевая"
          },
          {
            "word": "выкл",
            "count": 6,
            "phrase": "выкл и индикатор зарядки"
          },
          {
            "word": "индикатор",
            "count": 7,
            "phrase": "выкл и индикатор зарядки"
          },
          {
            "word": "опробовав",
            "count": 1,
            "phrase": "опробовав её на разных поверхностях"
          },
          {
            "word": "разных",
            "count": 5,
            "phrase": "опробовав её на разных поверхностях"
          },
          {
            "word": "поверхностях",
            "count": 1,
            "phrase": "опробовав её на разных поверхностях"
          },
          {
            "word": "заметно",
            "count": 4,
            "phrase": "стало заметно"
          },
          {
            "word": "ушли",
            "count": 3,
            "phrase": "либо ушли видимые поверхностные"
          },
          {
            "word": "видимые",
            "count": 1,
            "phrase": "либо ушли видимые поверхностные"
          },
          {
            "word": "поверхностные",
            "count": 1,
            "phrase": "либо ушли видимые поверхностные"
          },
          {
            "word": "подводя",
            "count": 0,
            "phrase": "подводя итог"
          },
          {
            "word": "итог",
            "count": 11,
            "phrase": "подводя итог"
          },
          {
            "word": "первых",
            "count": 2,
            "phrase": "во - первых"
          },
          {
            "word": "вторых",
            "count": 1,
            "phrase": "во - вторых"
          },
          {
            "word": "третьих",
            "count": 1,
            "phrase": "в - третьих"
          },
          {
            "word": "мятой",
            "count": 7,
            "phrase": "пришла в мятой и грязной коробке"
          },
          {
            "word": "грязной",
            "count": 2,
            "phrase": "пришла в мятой и грязной коробке"
          },
          {
            "word": "работал",
            "count": 19,
            "phrase": "работал действительно долго до разрядки"
          },
          {
            "word": "разрядки",
            "count": 1,
            "phrase": "работал действительно долго до разрядки"
          },
          {
            "word": "проверена",
            "count": 1,
            "phrase": "проверена в работе"
          },
          {
            "word": "продавца",
            "count": 12,
            "phrase": "продавца рекомендую"
          },
          {
            "word": "мега",
            "count": 2,
            "phrase": "мега находка"
          },
          {
            "word": "любыми",
            "count": 2,
            "phrase": "с любыми катышками на ура справляться"
          },
          {
            "word": "справляться",
            "count": 3,
            "phrase": "с любыми катышками на ура справляться"
          },
          {
            "word": "сегодня",
            "count": 2,
            "phrase": "сегодня забрала машинку и сразу импользовала"
          },
          {
            "word": "забрала",
            "count": 9,
            "phrase": "сегодня забрала машинку и сразу импользовала"
          },
          {
            "word": "импользовала",
            "count": 1,
            "phrase": "сегодня забрала машинку и сразу импользовала"
          },
          {
            "word": "шапку",
            "count": 5,
            "phrase": "почистила шапку от катвшков"
          },
          {
            "word": "катвшков",
            "count": 1,
            "phrase": "почистила шапку от катвшков"
          },
          {
            "word": "шапка",
            "count": 4,
            "phrase": "и правда катышки ушли и стала шапка как первоначально гладкая"
          },
          {
            "word": "первоначально",
            "count": 2,
            "phrase": "и правда катышки ушли и стала шапка как первоначально гладкая"
          },
          {
            "word": "гладкая",
            "count": 1,
            "phrase": "и правда катышки ушли и стала шапка как первоначально гладкая"
          },
          {
            "word": "порадовало",
            "count": 3,
            "phrase": "и конечно порадовало"
          },
          {
            "word": "сломалась",
            "count": 2,
            "phrase": "но сломалась"
          },
          {
            "word": "заказал",
            "count": 14,
            "phrase": "заказал эту от xiaomi"
          },
          {
            "word": "оказалась",
            "count": 3,
            "phrase": "оказалась лучше прежней"
          },
          {
            "word": "прежней",
            "count": 1,
            "phrase": "оказалась лучше прежней"
          },
          {
            "word": "вытащили",
            "count": 1,
            "phrase": "спасибо хоть шнур не вытащили"
          },
          {
            "word": "помята",
            "count": 16,
            "phrase": "но упаковка была вскрыта первоначально и была помята"
          },
          {
            "word": "блока",
            "count": 3,
            "phrase": "блока нет"
          },
          {
            "word": "констатация",
            "count": 1,
            "phrase": "скорее констатация факта"
          },
          {
            "word": "факта",
            "count": 1,
            "phrase": "скорее констатация факта"
          },
          {
            "word": "эти",
            "count": 15,
            "phrase": "что эти школьные брюки уже только выбрасывать"
          },
          {
            "word": "школьные",
            "count": 3,
            "phrase": "что эти школьные брюки уже только выбрасывать"
          },
          {
            "word": "использования",
            "count": 13,
            "phrase": "после использования машинки все отлично"
          },
          {
            "word": "обновляет",
            "count": 3,
            "phrase": "здорово обновляет ткань"
          },
          {
            "word": "ткань",
            "count": 18,
            "phrase": "здорово обновляет ткань"
          },
          {
            "word": "испробовала",
            "count": 5,
            "phrase": "я испробовала на диване и шторах были зацепки от кота"
          },
          {
            "word": "диване",
            "count": 2,
            "phrase": "я испробовала на диване и шторах были зацепки от кота"
          },
          {
            "word": "шторах",
            "count": 1,
            "phrase": "я испробовала на диване и шторах были зацепки от кота"
          },
          {
            "word": "зацепки",
            "count": 3,
            "phrase": "я испробовала на диване и шторах были зацепки от кота"
          },
          {
            "word": "кота",
            "count": 5,
            "phrase": "я испробовала на диване и шторах были зацепки от кота"
          },
          {
            "word": "новое",
            "count": 5,
            "phrase": "как новое все"
          },
          {
            "word": "шоке!потом",
            "count": 1,
            "phrase": "я в шоке!потом обновила также детские носочки"
          },
          {
            "word": "одевать",
            "count": 1,
            "phrase": "и ее снова приятно одевать"
          },
          {
            "word": "удовольствием",
            "count": 3,
            "phrase": "поэтому с удовольствием смогу советовать данное устройство всем"
          },
          {
            "word": "смогу",
            "count": 1,
            "phrase": "поэтому с удовольствием смогу советовать данное устройство всем"
          },
          {
            "word": "советовать",
            "count": 1,
            "phrase": "поэтому с удовольствием смогу советовать данное устройство всем"
          },
          {
            "word": "данное",
            "count": 1,
            "phrase": "поэтому с удовольствием смогу советовать данное устройство всем"
          },
          {
            "word": "проблема",
            "count": 10,
            "phrase": "была проблема"
          },
          {
            "word": "юбка",
            "count": 1,
            "phrase": "юбка плиссе с обратной стороны вся скаталась"
          },
          {
            "word": "плиссе",
            "count": 1,
            "phrase": "юбка плиссе с обратной стороны вся скаталась"
          },
          {
            "word": "обратной",
            "count": 1,
            "phrase": "юбка плиссе с обратной стороны вся скаталась"
          },
          {
            "word": "скаталась",
            "count": 1,
            "phrase": "юбка плиссе с обратной стороны вся скаталась"
          },
          {
            "word": "сравнение",
            "count": 1,
            "phrase": "и фото сравнение"
          },
          {
            "word": "куплена",
            "count": 0,
            "phrase": "куплена на распродаже"
          },
          {
            "word": "распродаже",
            "count": 1,
            "phrase": "куплена на распродаже"
          },
          {
            "word": "сына",
            "count": 3,
            "phrase": "на фото вещи сына"
          },
          {
            "word": "худи",
            "count": 2,
            "phrase": "худи и свитер"
          },
          {
            "word": "свитер",
            "count": 35,
            "phrase": "худи и свитер"
          },
          {
            "word": "ход",
            "count": 57,
            "phrase": "в ход пошли даже носки"
          },
          {
            "word": "пошли",
            "count": 1,
            "phrase": "в ход пошли даже носки"
          },
          {
            "word": "нисколько",
            "count": 1,
            "phrase": "нисколько не жалею о покупке"
          },
          {
            "word": "загрязнённый",
            "count": 1,
            "phrase": "одной зарядки хватило на сильно загрязнённый свитер"
          },
          {
            "word": "часть",
            "count": 6,
            "phrase": "и передняя часть худи"
          },
          {
            "word": "стандартного",
            "count": 1,
            "phrase": "заряжается от стандартного провода type - с от телефона"
          },
          {
            "word": "телефона",
            "count": 3,
            "phrase": "заряжается от стандартного провода type - с от телефона"
          },
          {
            "word": "чудо-",
            "count": 8,
            "phrase": "машинка чудо- спасение"
          },
          {
            "word": "чисти",
            "count": 170,
            "phrase": "чисти всё от колготок детских до покрывал"
          },
          {
            "word": "детских",
            "count": 11,
            "phrase": "чисти всё от колготок детских до покрывал"
          },
          {
            "word": "покрывал",
            "count": 2,
            "phrase": "чисти всё от колготок детских до покрывал"
          },
          {
            "word": "фантастика",
            "count": 1,
            "phrase": "это фантастика"
          },
          {
            "word": "платья",
            "count": 4,
            "phrase": "у меня платья"
          },
          {
            "word": "блузки",
            "count": 1,
            "phrase": "блузки тонкие офисные закатываются от машинного ремня с одной стороны"
          },
          {
            "word": "тонкие",
            "count": 1,
            "phrase": "блузки тонкие офисные закатываются от машинного ремня с одной стороны"
          },
          {
            "word": "офисные",
            "count": 1,
            "phrase": "блузки тонкие офисные закатываются от машинного ремня с одной стороны"
          },
          {
            "word": "закатываются",
            "count": 1,
            "phrase": "блузки тонкие офисные закатываются от машинного ремня с одной стороны"
          },
          {
            "word": "машинного",
            "count": 1,
            "phrase": "блузки тонкие офисные закатываются от машинного ремня с одной стороны"
          },
          {
            "word": "ремня",
            "count": 1,
            "phrase": "блузки тонкие офисные закатываются от машинного ремня с одной стороны"
          },
          {
            "word": "куртка",
            "count": 1,
            "phrase": "даже если куртка сверху"
          },
          {
            "word": "трутся",
            "count": 1,
            "phrase": "все равно трутся"
          },
          {
            "word": "офисе",
            "count": 1,
            "phrase": "и в офисе сидишь"
          },
          {
            "word": "сидишь",
            "count": 1,
            "phrase": "и в офисе сидишь"
          },
          {
            "word": "стула",
            "count": 1,
            "phrase": "от стула спина тоже"
          },
          {
            "word": "спина",
            "count": 1,
            "phrase": "от стула спина тоже"
          },
          {
            "word": "попробовпла",
            "count": 1,
            "phrase": "я попробовпла очистить блузку и платье"
          },
          {
            "word": "очистить",
            "count": 18,
            "phrase": "я попробовпла очистить блузку и платье"
          },
          {
            "word": "платье",
            "count": 6,
            "phrase": "я попробовпла очистить блузку и платье"
          },
          {
            "word": "гладишь",
            "count": 0,
            "phrase": "гладишь как утюжком"
          },
          {
            "word": "утюжком",
            "count": 1,
            "phrase": "гладишь как утюжком"
          },
          {
            "word": "вещицу",
            "count": 1,
            "phrase": "вот спасибо за такую вещицу"
          },
          {
            "word": "свитеры",
            "count": 2,
            "phrase": "думала только свитеры"
          },
          {
            "word": "оказывается",
            "count": 1,
            "phrase": "оказывается тонким вещам тоже вернуло жизнь"
          },
          {
            "word": "тонким",
            "count": 1,
            "phrase": "оказывается тонким вещам тоже вернуло жизнь"
          },
          {
            "word": "вернуло",
            "count": 1,
            "phrase": "оказывается тонким вещам тоже вернуло жизнь"
          },
          {
            "word": "походить",
            "count": 1,
            "phrase": "можно еще походить"
          },
          {
            "word": "блузка",
            "count": 2,
            "phrase": "на фото платье и блузка до и после"
          },
          {
            "word": "чудное",
            "count": 1,
            "phrase": "чудо чудное"
          },
          {
            "word": "диво",
            "count": 1,
            "phrase": "диво дивное"
          },
          {
            "word": "дивное",
            "count": 1,
            "phrase": "диво дивное"
          },
          {
            "word": "штанах",
            "count": 7,
            "phrase": "опробовала на детских штанах"
          },
          {
            "word": "бегу",
            "count": 2,
            "phrase": "на бегу"
          },
          {
            "word": "коленке",
            "count": 1,
            "phrase": "на коленке"
          },
          {
            "word": "комплект",
            "count": 110,
            "phrase": "весь комплект на на месте"
          },
          {
            "word": "другие",
            "count": 7,
            "phrase": "колготки и другие вещи"
          },
          {
            "word": "катышей",
            "count": 2,
            "phrase": "которые я хотела давно выбросить из - за катышей"
          },
          {
            "word": "немаловажно",
            "count": 2,
            "phrase": "и что немаловажно не портит ткань"
          },
          {
            "word": "портит",
            "count": 7,
            "phrase": "и что немаловажно не портит ткань"
          },
          {
            "word": "сменные",
            "count": 5,
            "phrase": "сменные лезвия продаются отдельно"
          },
          {
            "word": "продаются",
            "count": 1,
            "phrase": "сменные лезвия продаются отдельно"
          },
          {
            "word": "неплохой",
            "count": 2,
            "phrase": "достаточно неплохой"
          },
          {
            "word": "мощности",
            "count": 2,
            "phrase": "чуть мощности не хватает"
          },
          {
            "word": "покупки",
            "count": 5,
            "phrase": "возможно потому что еще не заряжала после покупки"
          },
          {
            "word": "ворс",
            "count": 9,
            "phrase": "но ворс собирает"
          },
          {
            "word": "ощупь",
            "count": 1,
            "phrase": "ткань на ощупь становится мягкой"
          },
          {
            "word": "мягкой",
            "count": 1,
            "phrase": "ткань на ощупь становится мягкой"
          },
          {
            "word": "крупными",
            "count": 4,
            "phrase": "думаю даже с крупными катышками справится неплохо"
          },
          {
            "word": "неплохо",
            "count": 5,
            "phrase": "думаю даже с крупными катышками справится неплохо"
          },
          {
            "word": "пробовал",
            "count": 33,
            "phrase": "пробовал флис"
          },
          {
            "word": "флис",
            "count": 3,
            "phrase": "пробовал флис"
          },
          {
            "word": "дорогую",
            "count": 1,
            "phrase": "дорогую куртку и хб футболку"
          },
          {
            "word": "куртку",
            "count": 1,
            "phrase": "дорогую куртку и хб футболку"
          },
          {
            "word": "футболку",
            "count": 2,
            "phrase": "дорогую куртку и хб футболку"
          },
          {
            "word": "пришёл",
            "count": 12,
            "phrase": "пришёл товар быстро"
          },
          {
            "word": "имеется",
            "count": 4,
            "phrase": "имеется провод для зарядки"
          },
          {
            "word": "прикольная",
            "count": 0,
            "phrase": "прикольная игрушка"
          },
          {
            "word": "игрушка",
            "count": 2,
            "phrase": "прикольная игрушка"
          },
          {
            "word": "ходу",
            "count": 1,
            "phrase": "ходу выискиваю катрышки"
          },
          {
            "word": "выискиваю",
            "count": 1,
            "phrase": "ходу выискиваю катрышки"
          },
          {
            "word": "катрышки",
            "count": 1,
            "phrase": "ходу выискиваю катрышки"
          },
          {
            "word": "шли",
            "count": 12,
            "phrase": "которые шли на мусорку теперь опять в надлежащем состоянии"
          },
          {
            "word": "мусорку",
            "count": 1,
            "phrase": "которые шли на мусорку теперь опять в надлежащем состоянии"
          },
          {
            "word": "опять",
            "count": 3,
            "phrase": "которые шли на мусорку теперь опять в надлежащем состоянии"
          },
          {
            "word": "надлежащем",
            "count": 1,
            "phrase": "которые шли на мусорку теперь опять в надлежащем состоянии"
          },
          {
            "word": "нужна",
            "count": 18,
            "phrase": "думала она мне не нужна"
          },
          {
            "word": "чисто",
            "count": 13,
            "phrase": "чисто из - за фирмы"
          },
          {
            "word": "фирмы",
            "count": 5,
            "phrase": "чисто из - за фирмы"
          },
          {
            "word": "продавца-",
            "count": 1,
            "phrase": "однозначно рекомендую продавца- все пришло в наличии"
          },
          {
            "word": "наличии",
            "count": 2,
            "phrase": "однозначно рекомендую продавца- все пришло в наличии"
          },
          {
            "word": "того",
            "count": 38,
            "phrase": "машинка - лучшая из того"
          },
          {
            "word": "хорошие",
            "count": 2,
            "phrase": "хорошие обороты"
          },
          {
            "word": "обороты",
            "count": 1,
            "phrase": "хорошие обороты"
          },
          {
            "word": "захвата",
            "count": 1,
            "phrase": "поверхность захвата достаточно большая за счёт ровной поверхности сетки"
          },
          {
            "word": "ровной",
            "count": 1,
            "phrase": "поверхность захвата достаточно большая за счёт ровной поверхности сетки"
          },
          {
            "word": "поверхности",
            "count": 2,
            "phrase": "поверхность захвата достаточно большая за счёт ровной поверхности сетки"
          },
          {
            "word": "сетки",
            "count": 2,
            "phrase": "поверхность захвата достаточно большая за счёт ровной поверхности сетки"
          },
          {
            "word": "подвела",
            "count": 1,
            "phrase": "чтоб потом не подвела"
          },
          {
            "word": "ремень",
            "count": 1,
            "phrase": "отличный ремень"
          },
          {
            "word": "качественная",
            "count": 7,
            "phrase": "упаковка тоже качественная"
          },
          {
            "word": "конфетка",
            "count": 1,
            "phrase": "в подарок конфетка и открытка подписанная"
          },
          {
            "word": "открытка",
            "count": 1,
            "phrase": "в подарок конфетка и открытка подписанная"
          },
          {
            "word": "подписанная",
            "count": 1,
            "phrase": "в подарок конфетка и открытка подписанная"
          },
          {
            "word": "делает",
            "count": 7,
            "phrase": "что делает покупку ещё приятнее"
          },
          {
            "word": "покупку",
            "count": 1,
            "phrase": "что делает покупку ещё приятнее"
          },
          {
            "word": "приятнее",
            "count": 1,
            "phrase": "что делает покупку ещё приятнее"
          },
          {
            "word": "целости",
            "count": 5,
            "phrase": "всё в целости"
          },
          {
            "word": "сняла",
            "count": 3,
            "phrase": "жаль что не сняла до"
          },
          {
            "word": "счетка",
            "count": 1,
            "phrase": "инструкция но на китайском и счетка"
          },
          {
            "word": "воспользовалась",
            "count": 2,
            "phrase": "получила и сразу дома воспользовалась"
          },
          {
            "word": "пододеяльнику",
            "count": 1,
            "phrase": "прошлась по пододеяльнику"
          },
          {
            "word": "набрила",
            "count": 2,
            "phrase": "и уже набрила свой свитер"
          },
          {
            "word": "привирают",
            "count": 1,
            "phrase": "что в отзывах привирают"
          },
          {
            "word": "склада",
            "count": 2,
            "phrase": "что прям со склада"
          },
          {
            "word": "пакетике",
            "count": 1,
            "phrase": "так как на пакетике портится лента после вскрытия"
          },
          {
            "word": "портится",
            "count": 2,
            "phrase": "так как на пакетике портится лента после вскрытия"
          },
          {
            "word": "лента",
            "count": 1,
            "phrase": "так как на пакетике портится лента после вскрытия"
          },
          {
            "word": "вскрытия",
            "count": 1,
            "phrase": "так как на пакетике портится лента после вскрытия"
          },
          {
            "word": "дело",
            "count": 4,
            "phrase": "дело пошло"
          },
          {
            "word": "пошло",
            "count": 2,
            "phrase": "дело пошло"
          },
          {
            "word": "начал",
            "count": 32,
            "phrase": "начал немного гудеть и вибрировать"
          },
          {
            "word": "гудеть",
            "count": 1,
            "phrase": "начал немного гудеть и вибрировать"
          },
          {
            "word": "вибрировать",
            "count": 1,
            "phrase": "начал немного гудеть и вибрировать"
          },
          {
            "word": "садится",
            "count": 2,
            "phrase": "что садится"
          },
          {
            "word": "увлеклась",
            "count": 2,
            "phrase": "что я увлеклась и набрила больше"
          },
          {
            "word": "помещается",
            "count": 1,
            "phrase": "чем помещается в контейнер"
          },
          {
            "word": "стал",
            "count": 80,
            "phrase": "почистила и он снова стал дерррзким"
          },
          {
            "word": "дерррзким",
            "count": 1,
            "phrase": "почистила и он снова стал дерррзким"
          },
          {
            "word": "проверяйте",
            "count": 0,
            "phrase": "проверяйте не только контейнер"
          },
          {
            "word": "лезвием",
            "count": 1,
            "phrase": "но и под лезвием"
          },
          {
            "word": "удалились",
            "count": 2,
            "phrase": "катышки удалились полностью"
          },
          {
            "word": "дайте",
            "count": 1,
            "phrase": "дайте своим любимым вещам вторую жизнь"
          },
          {
            "word": "любимым",
            "count": 1,
            "phrase": "дайте своим любимым вещам вторую жизнь"
          },
          {
            "word": "увидела",
            "count": 1,
            "phrase": "увидела у знакомых"
          },
          {
            "word": "знакомых",
            "count": 3,
            "phrase": "увидела у знакомых"
          },
          {
            "word": "попробывала",
            "count": 1,
            "phrase": "попробывала очень понравилась"
          },
          {
            "word": "любой",
            "count": 2,
            "phrase": "потрясающая машинка удаляет быстро катушки на любой ткани"
          },
          {
            "word": "идея",
            "count": 1,
            "phrase": "отличная идея"
          },
          {
            "word": "отличное",
            "count": 2,
            "phrase": "отличное сочетание цены и качества"
          },
          {
            "word": "сочетание",
            "count": 1,
            "phrase": "отличное сочетание цены и качества"
          },
          {
            "word": "качества",
            "count": 3,
            "phrase": "отличное сочетание цены и качества"
          },
          {
            "word": "замечательным",
            "count": 1,
            "phrase": "купила исключительно благодаря замечательным отзывам и не прогадала"
          },
          {
            "word": "отзывам",
            "count": 6,
            "phrase": "купила исключительно благодаря замечательным отзывам и не прогадала"
          },
          {
            "word": "прогадала",
            "count": 3,
            "phrase": "купила исключительно благодаря замечательным отзывам и не прогадала"
          },
          {
            "word": "которой",
            "count": 3,
            "phrase": "которой мне не хватало последние лет"
          },
          {
            "word": "хватало",
            "count": 2,
            "phrase": "которой мне не хватало последние лет"
          },
          {
            "word": "лет",
            "count": 9,
            "phrase": "которой мне не хватало последние лет"
          },
          {
            "word": "быструю",
            "count": 5,
            "phrase": "спасибо продавцу за качество товара и быструю доставку"
          },
          {
            "word": "доставку",
            "count": 10,
            "phrase": "спасибо продавцу за качество товара и быструю доставку"
          },
          {
            "word": "прилагаю",
            "count": 9,
            "phrase": "фото прилагаю"
          },
          {
            "word": "машинками",
            "count": 3,
            "phrase": "по сравнению с машинками работающими на батарейках"
          },
          {
            "word": "работающими",
            "count": 1,
            "phrase": "по сравнению с машинками работающими на батарейках"
          },
          {
            "word": "мнгновенно",
            "count": 1,
            "phrase": "катышки убирает мнгновенно"
          },
          {
            "word": "лишнего",
            "count": 1,
            "phrase": "ничего лишнего"
          },
          {
            "word": "прочный",
            "count": 2,
            "phrase": "пластик прочный"
          },
          {
            "word": "дооолго",
            "count": 0,
            "phrase": "дооолго думала брать или нет"
          },
          {
            "word": "потрачу",
            "count": 1,
            "phrase": "что потрачу впустую"
          },
          {
            "word": "впустую",
            "count": 1,
            "phrase": "что потрачу впустую"
          },
          {
            "word": "570₽-",
            "count": 1,
            "phrase": "за 570₽- вообще огонь"
          },
          {
            "word": "сначала",
            "count": 4,
            "phrase": "сначала я убрала катышки на своей яркой водолазке"
          },
          {
            "word": "яркой",
            "count": 1,
            "phrase": "сначала я убрала катышки на своей яркой водолазке"
          },
          {
            "word": "водолазке",
            "count": 1,
            "phrase": "сначала я убрала катышки на своей яркой водолазке"
          },
          {
            "word": "сумки",
            "count": 4,
            "phrase": "от сумки"
          },
          {
            "word": "полный",
            "count": 3,
            "phrase": "полный комплект"
          },
          {
            "word": "попробовала",
            "count": 5,
            "phrase": "попробовала на дедом батнике"
          },
          {
            "word": "дедом",
            "count": 1,
            "phrase": "попробовала на дедом батнике"
          },
          {
            "word": "батнике",
            "count": 1,
            "phrase": "попробовала на дедом батнике"
          },
          {
            "word": "серных",
            "count": 1,
            "phrase": "весь был в серных катышках"
          },
          {
            "word": "катышках",
            "count": 11,
            "phrase": "весь был в серных катышках"
          },
          {
            "word": "одному",
            "count": 5,
            "phrase": "но по одному песту по водить придется"
          },
          {
            "word": "песту",
            "count": 1,
            "phrase": "но по одному песту по водить придется"
          },
          {
            "word": "заряжен",
            "count": 68,
            "phrase": "возможно изначально было не полностью заряжен"
          },
          {
            "word": "фотке",
            "count": 1,
            "phrase": "на фотке видно как сабирает катышки"
          },
          {
            "word": "сабирает",
            "count": 1,
            "phrase": "на фотке видно как сабирает катышки"
          },
          {
            "word": "апарат",
            "count": 0,
            "phrase": "апарат зарежается"
          },
          {
            "word": "зарежается",
            "count": 1,
            "phrase": "апарат зарежается"
          },
          {
            "word": "шикарна",
            "count": 6,
            "phrase": "она шикарна"
          },
          {
            "word": "производительность",
            "count": 1,
            "phrase": "большая производительность"
          },
          {
            "word": "следить",
            "count": 1,
            "phrase": "необходимо следить за наполнением контейнера"
          },
          {
            "word": "наполнением",
            "count": 1,
            "phrase": "необходимо следить за наполнением контейнера"
          },
          {
            "word": "контейнера",
            "count": 7,
            "phrase": "необходимо следить за наполнением контейнера"
          },
          {
            "word": "набирается",
            "count": 1,
            "phrase": "очень быстро набирается"
          },
          {
            "word": "тонкой",
            "count": 1,
            "phrase": "особенно хорошо убирает на тонкой ткани и вязаные вещи"
          },
          {
            "word": "вязаные",
            "count": 1,
            "phrase": "особенно хорошо убирает на тонкой ткани и вязаные вещи"
          },
          {
            "word": "громкий",
            "count": 2,
            "phrase": "есть не очень громкий звук и делать не быстро"
          },
          {
            "word": "звук",
            "count": 2,
            "phrase": "есть не очень громкий звук и делать не быстро"
          },
          {
            "word": "делать",
            "count": 9,
            "phrase": "есть не очень громкий звук и делать не быстро"
          },
          {
            "word": "знаю",
            "count": 12,
            "phrase": "пока не знаю сколько держит"
          },
          {
            "word": "перерыва",
            "count": 1,
            "phrase": "но без перерыва чистила вещи час и не сел заряд"
          },
          {
            "word": "чистила",
            "count": 66,
            "phrase": "но без перерыва чистила вещи час и не сел заряд"
          },
          {
            "word": "сел",
            "count": 12,
            "phrase": "но без перерыва чистила вещи час и не сел заряд"
          },
          {
            "word": "коробки",
            "count": 5,
            "phrase": "есть щеточка для чистки и кабель для зарядки внутри коробки"
          },
          {
            "word": "любые",
            "count": 1,
            "phrase": "любые катышки практически с любых поверхностей"
          },
          {
            "word": "практически",
            "count": 6,
            "phrase": "любые катышки практически с любых поверхностей"
          },
          {
            "word": "поверхностей",
            "count": 1,
            "phrase": "любые катышки практически с любых поверхностей"
          },
          {
            "word": "коронуется",
            "count": 1,
            "phrase": "в коронуется шнур для зарядки и щеточка для чистки контейнера"
          },
          {
            "word": "общий",
            "count": 1,
            "phrase": "тк общий внешний вид вещи спасает - можно продолжать пользоваться"
          },
          {
            "word": "внешний",
            "count": 9,
            "phrase": "тк общий внешний вид вещи спасает - можно продолжать пользоваться"
          },
          {
            "word": "продолжать",
            "count": 2,
            "phrase": "тк общий внешний вид вещи спасает - можно продолжать пользоваться"
          },
          {
            "word": "тихий",
            "count": 1,
            "phrase": "но и не тихий"
          },
          {
            "word": "отсек",
            "count": 12,
            "phrase": "удобный отсек для мусора"
          },
          {
            "word": "требуются",
            "count": 0,
            "phrase": "требуются девушки на подработку"
          },
          {
            "word": "девушки",
            "count": 1,
            "phrase": "требуются девушки на подработку"
          },
          {
            "word": "подработку",
            "count": 1,
            "phrase": "требуются девушки на подработку"
          },
          {
            "word": "удалённая",
            "count": 1,
            "phrase": "работа удалённая"
          },
          {
            "word": "живая",
            "count": 1,
            "phrase": "живая прибыль от 600"
          },
          {
            "word": "прибыль",
            "count": 1,
            "phrase": "живая прибыль от 600"
          },
          {
            "word": "600",
            "count": 5,
            "phrase": "живая прибыль от 600"
          },
          {
            "word": "флакона",
            "count": 1,
            "phrase": "с флакона парфюма"
          },
          {
            "word": "парфюма",
            "count": 1,
            "phrase": "с флакона парфюма"
          },
          {
            "word": "закупок",
            "count": 1,
            "phrase": "без закупок и планов продаж"
          },
          {
            "word": "планов",
            "count": 1,
            "phrase": "без закупок и планов продаж"
          },
          {
            "word": "продаж",
            "count": 3,
            "phrase": "без закупок и планов продаж"
          },
          {
            "word": "любите",
            "count": 1,
            "phrase": "если любите парфюм"
          },
          {
            "word": "парфюм",
            "count": 3,
            "phrase": "если любите парфюм"
          },
          {
            "word": "тевар",
            "count": 0,
            "phrase": "тевар пришёл хорошо упакован"
          },
          {
            "word": "коврик",
            "count": 3,
            "phrase": "коврик в ванной хотела выкинуть но машинка его спасла"
          },
          {
            "word": "ванной",
            "count": 2,
            "phrase": "коврик в ванной хотела выкинуть но машинка его спасла"
          },
          {
            "word": "красном",
            "count": 1,
            "phrase": "на красном фоне плохо видно катышки"
          },
          {
            "word": "фоне",
            "count": 2,
            "phrase": "на красном фоне плохо видно катышки"
          },
          {
            "word": "мои",
            "count": 13,
            "phrase": "но мои штаны полностью закошлатились"
          },
          {
            "word": "закошлатились",
            "count": 1,
            "phrase": "но мои штаны полностью закошлатились"
          },
          {
            "word": "отдельное",
            "count": 2,
            "phrase": "за это отдельное спасибо"
          },
          {
            "word": "данной",
            "count": 2,
            "phrase": "я в восторге от данной машинки"
          },
          {
            "word": "честный",
            "count": 0,
            "phrase": "честный отзыв"
          },
          {
            "word": "шумная",
            "count": 9,
            "phrase": "не очень шумная"
          },
          {
            "word": "щеточкой",
            "count": 3,
            "phrase": "чтоб почистить щеточкой после катышек нужно постараться"
          },
          {
            "word": "постараться",
            "count": 3,
            "phrase": "чтоб почистить щеточкой после катышек нужно постараться"
          },
          {
            "word": "комфортно",
            "count": 1,
            "phrase": "очень комфортно держать в руке"
          },
          {
            "word": "тока",
            "count": 1,
            "phrase": "тока не поняла как понять зарядилась она или нет"
          },
          {
            "word": "поняла",
            "count": 5,
            "phrase": "тока не поняла как понять зарядилась она или нет"
          },
          {
            "word": "зарядилась",
            "count": 3,
            "phrase": "тока не поняла как понять зарядилась она или нет"
          },
          {
            "word": "последнем",
            "count": 1,
            "phrase": "результат на последнем фото в правой части до"
          },
          {
            "word": "части",
            "count": 6,
            "phrase": "результат на последнем фото в правой части до"
          },
          {
            "word": "доставлено",
            "count": 0,
            "phrase": "доставлено в коробке"
          },
          {
            "word": "фотографиях",
            "count": 1,
            "phrase": "на фотографиях свитер до и после"
          },
          {
            "word": "самых",
            "count": 5,
            "phrase": "убрала катышки даже с самых"
          },
          {
            "word": "безнадёжных",
            "count": 1,
            "phrase": "безнадёжных вещей"
          },
          {
            "word": "задиров",
            "count": 1,
            "phrase": "даже задиров не осталось"
          },
          {
            "word": "лучшего",
            "count": 2,
            "phrase": "для лучшего результата во время процесса нужно чуть натянуть ткань"
          },
          {
            "word": "процесса",
            "count": 1,
            "phrase": "для лучшего результата во время процесса нужно чуть натянуть ткань"
          },
          {
            "word": "натянуть",
            "count": 1,
            "phrase": "для лучшего результата во время процесса нужно чуть натянуть ткань"
          },
          {
            "word": "разобрала",
            "count": 2,
            "phrase": "я разобрала и собрала без проблем"
          },
          {
            "word": "собрала",
            "count": 2,
            "phrase": "я разобрала и собрала без проблем"
          },
          {
            "word": "проблем",
            "count": 18,
            "phrase": "я разобрала и собрала без проблем"
          },
          {
            "word": "каталки",
            "count": 1,
            "phrase": "каталки собирает на 100 баллов"
          },
          {
            "word": "баллов",
            "count": 2,
            "phrase": "каталки собирает на 100 баллов"
          },
          {
            "word": "удобной",
            "count": 1,
            "phrase": "удобной формы"
          },
          {
            "word": "формы",
            "count": 3,
            "phrase": "удобной формы"
          },
          {
            "word": "стильная",
            "count": 5,
            "phrase": "стильная машинка"
          },
          {
            "word": "берёт",
            "count": 4,
            "phrase": "крупные берёт на ура"
          },
          {
            "word": "интуитивно",
            "count": 1,
            "phrase": "интуитивно понятна в эксплуатации"
          },
          {
            "word": "понятна",
            "count": 1,
            "phrase": "интуитивно понятна в эксплуатации"
          },
          {
            "word": "ножи",
            "count": 5,
            "phrase": "заказала ещё и сменные ножи"
          },
          {
            "word": "футболка",
            "count": 1,
            "phrase": "футболка до и после"
          },
          {
            "word": "свитере",
            "count": 2,
            "phrase": "на любой ткани - и на свитере"
          },
          {
            "word": "носочках",
            "count": 3,
            "phrase": "и на носочках"
          },
          {
            "word": "упакованная",
            "count": 4,
            "phrase": "пришла аккуратно упакованная"
          },
          {
            "word": "скидкой",
            "count": 1,
            "phrase": "брала со скидкой 662р"
          },
          {
            "word": "662р",
            "count": 1,
            "phrase": "брала со скидкой 662р"
          },
          {
            "word": "школа",
            "count": 1,
            "phrase": "скоро школа"
          },
          {
            "word": "безрукавке",
            "count": 1,
            "phrase": "очень много было на безрукавке катышек"
          },
          {
            "word": "убралось!!очень",
            "count": 0,
            "phrase": "всё убралось!!очень рада что появилась такая машинка"
          },
          {
            "word": "красота",
            "count": 1,
            "phrase": "ну красота же"
          },
          {
            "word": "придумал",
            "count": 1,
            "phrase": "кто придумал аппарат"
          },
          {
            "word": "сделал",
            "count": 14,
            "phrase": "кто сделал именно этот"
          },
          {
            "word": "выполнен",
            "count": 1,
            "phrase": "стильно выполнен"
          },
          {
            "word": "продлевает",
            "count": 0,
            "phrase": "продлевает срок службы вещам"
          },
          {
            "word": "службы",
            "count": 2,
            "phrase": "продлевает срок службы вещам"
          },
          {
            "word": "бережет",
            "count": 1,
            "phrase": "бережет экологию"
          },
          {
            "word": "экологию",
            "count": 1,
            "phrase": "бережет экологию"
          },
          {
            "word": "другой",
            "count": 7,
            "phrase": "ранее покупала другой"
          },
          {
            "word": "неизвестного",
            "count": 1,
            "phrase": "неизвестного производства в обычном бытовом магазине"
          },
          {
            "word": "производства",
            "count": 1,
            "phrase": "неизвестного производства в обычном бытовом магазине"
          },
          {
            "word": "обычном",
            "count": 1,
            "phrase": "неизвестного производства в обычном бытовом магазине"
          },
          {
            "word": "бытовом",
            "count": 1,
            "phrase": "неизвестного производства в обычном бытовом магазине"
          },
          {
            "word": "магазине",
            "count": 2,
            "phrase": "неизвестного производства в обычном бытовом магазине"
          },
          {
            "word": "справлялся",
            "count": 1,
            "phrase": "тоже справлялся со своей задачей"
          },
          {
            "word": "садился",
            "count": 1,
            "phrase": "но ооочень быстро садился заряд"
          },
          {
            "word": "рекомендация",
            "count": 1,
            "phrase": "однозначно рекомендация"
          },
          {
            "word": "сторону",
            "count": 1,
            "phrase": "и даже не смотрите в сторону более дешёвых"
          },
          {
            "word": "дешёвых",
            "count": 3,
            "phrase": "и даже не смотрите в сторону более дешёвых"
          },
          {
            "word": "маст",
            "count": 2,
            "phrase": "однозначно маст - хэв в каждый дом"
          },
          {
            "word": "хэв",
            "count": 1,
            "phrase": "однозначно маст - хэв в каждый дом"
          },
          {
            "word": "каждый",
            "count": 2,
            "phrase": "однозначно маст - хэв в каждый дом"
          },
          {
            "word": "гений",
            "count": 0,
            "phrase": "теперь же все мои носки без катышков 😁 гений"
          },
          {
            "word": "медитативные",
            "count": 1,
            "phrase": "для себя открыла ещё и медитативные свойства данного аппарата"
          },
          {
            "word": "свойства",
            "count": 1,
            "phrase": "для себя открыла ещё и медитативные свойства данного аппарата"
          },
          {
            "word": "аппарата",
            "count": 3,
            "phrase": "для себя открыла ещё и медитативные свойства данного аппарата"
          },
          {
            "word": "родная",
            "count": 1,
            "phrase": "упаковка только родная дополнительной для транспортировки нет"
          },
          {
            "word": "дополнительной",
            "count": 4,
            "phrase": "упаковка только родная дополнительной для транспортировки нет"
          },
          {
            "word": "транспортировки",
            "count": 1,
            "phrase": "упаковка только родная дополнительной для транспортировки нет"
          },
          {
            "word": "потертая",
            "count": 1,
            "phrase": "пришла потертая"
          },
          {
            "word": "пломбы",
            "count": 1,
            "phrase": "все пломбы на месте"
          },
          {
            "word": "сезону",
            "count": 1,
            "phrase": "лучшая покупка к сезону свитеров"
          },
          {
            "word": "добротная",
            "count": 1,
            "phrase": "качественная добротная машинка"
          },
          {
            "word": "один",
            "count": 11,
            "phrase": "которая будет работать не один сезон"
          },
          {
            "word": "сезон",
            "count": 3,
            "phrase": "которая будет работать не один сезон"
          },
          {
            "word": "экономит",
            "count": 2,
            "phrase": "лучше не экономит на такой штуке"
          },
          {
            "word": "штуке",
            "count": 1,
            "phrase": "лучше не экономит на такой штуке"
          },
          {
            "word": "говорят",
            "count": 3,
            "phrase": "фото говорят сами за себя"
          },
          {
            "word": "сами",
            "count": 5,
            "phrase": "фото говорят сами за себя"
          },
          {
            "word": "своими",
            "count": 5,
            "phrase": "со своими обязанностями справляется отлично"
          },
          {
            "word": "обязанностями",
            "count": 1,
            "phrase": "со своими обязанностями справляется отлично"
          },
          {
            "word": "моя",
            "count": 13,
            "phrase": "это моя лучшая покупка на вб"
          },
          {
            "word": "школьной",
            "count": 1,
            "phrase": "покупала для школьной формы"
          },
          {
            "word": "комбинезоне",
            "count": 1,
            "phrase": "решила попробовать на комбинезоне детском 🤩 супер"
          },
          {
            "word": "детском",
            "count": 1,
            "phrase": "решила попробовать на комбинезоне детском 🤩 супер"
          },
          {
            "word": "спасли",
            "count": 1,
            "phrase": "вы спасли мою кофту"
          },
          {
            "word": "мою",
            "count": 4,
            "phrase": "вы спасли мою кофту"
          },
          {
            "word": "кофту",
            "count": 15,
            "phrase": "вы спасли мою кофту"
          },
          {
            "word": "непобедимая",
            "count": 1,
            "phrase": "непобедимая вещь"
          },
          {
            "word": "берет",
            "count": 3,
            "phrase": "спасибо большие катышки не берет"
          },
          {
            "word": "комплексе",
            "count": 1,
            "phrase": "но в комплексе с ножницами и роликом от шерсти"
          },
          {
            "word": "ножницами",
            "count": 1,
            "phrase": "но в комплексе с ножницами и роликом от шерсти"
          },
          {
            "word": "роликом",
            "count": 1,
            "phrase": "но в комплексе с ножницами и роликом от шерсти"
          },
          {
            "word": "шерсти",
            "count": 2,
            "phrase": "но в комплексе с ножницами и роликом от шерсти"
          },
          {
            "word": "галамарта",
            "count": 0,
            "phrase": "до этого была обычная из галамарта за 150 рублей"
          },
          {
            "word": "150",
            "count": 5,
            "phrase": "до этого была обычная из галамарта за 150 рублей"
          },
          {
            "word": "гладила",
            "count": 1,
            "phrase": "которая просто гладила"
          },
          {
            "word": "никак",
            "count": 11,
            "phrase": "но никак не состригала катышки"
          },
          {
            "word": "состригала",
            "count": 1,
            "phrase": "но никак не состригала катышки"
          },
          {
            "word": "понасмотрелась",
            "count": 1,
            "phrase": "понасмотрелась отзывы"
          },
          {
            "word": "люди",
            "count": 2,
            "phrase": "где люди как будто новые вещи показывают"
          },
          {
            "word": "показывают",
            "count": 1,
            "phrase": "где люди как будто новые вещи показывают"
          },
          {
            "word": "сказать",
            "count": 8,
            "phrase": "это ничего не сказать"
          },
          {
            "word": "плюсом",
            "count": 2,
            "phrase": "машинка справляется со своей задачей на 10 с плюсом"
          },
          {
            "word": "последняя",
            "count": 1,
            "phrase": "это была последняя надежда"
          },
          {
            "word": "надежда",
            "count": 1,
            "phrase": "это была последняя надежда"
          },
          {
            "word": "проживёт",
            "count": 1,
            "phrase": "надеюсь проживёт долго и служить будет исправно"
          },
          {
            "word": "материала",
            "count": 3,
            "phrase": "за счёт материала"
          },
          {
            "word": "уходил",
            "count": 1,
            "phrase": "весь мусор не уходил в контейнер"
          },
          {
            "word": "сеточку",
            "count": 1,
            "phrase": "но сеточку снял"
          },
          {
            "word": "снял",
            "count": 5,
            "phrase": "но сеточку снял"
          },
          {
            "word": "щёткой",
            "count": 1,
            "phrase": "щёткой почистил и вуаля"
          },
          {
            "word": "почистил",
            "count": 28,
            "phrase": "щёткой почистил и вуаля"
          },
          {
            "word": "вуаля",
            "count": 3,
            "phrase": "щёткой почистил и вуаля"
          },
          {
            "word": "пробовать",
            "count": 12,
            "phrase": "буду пробовать ещё чистить чехол на матрасе"
          },
          {
            "word": "чехол",
            "count": 1,
            "phrase": "буду пробовать ещё чистить чехол на матрасе"
          },
          {
            "word": "матрасе",
            "count": 3,
            "phrase": "буду пробовать ещё чистить чехол на матрасе"
          },
          {
            "word": "срочно",
            "count": 2,
            "phrase": "то срочно покупайте"
          },
          {
            "word": "покупайте",
            "count": 3,
            "phrase": "то срочно покупайте"
          },
          {
            "word": "обработки",
            "count": 7,
            "phrase": "на фото до и после обработки"
          },
          {
            "word": "надеятся",
            "count": 1,
            "phrase": "буду надеятся на долгую службу"
          },
          {
            "word": "долгую",
            "count": 1,
            "phrase": "буду надеятся на долгую службу"
          },
          {
            "word": "службу",
            "count": 1,
            "phrase": "буду надеятся на долгую службу"
          },
          {
            "word": "нашла",
            "count": 5,
            "phrase": "давно искала и нашла"
          },
          {
            "word": "розетки",
            "count": 2,
            "phrase": "заряжается от розетки"
          },
          {
            "word": "прошел",
            "count": 1,
            "phrase": "прошел уже месяц со дня покупки"
          },
          {
            "word": "месяц",
            "count": 12,
            "phrase": "прошел уже месяц со дня покупки"
          },
          {
            "word": "дня",
            "count": 19,
            "phrase": "прошел уже месяц со дня покупки"
          },
          {
            "word": "вердикт",
            "count": 1,
            "phrase": "и вердикт однозначный"
          },
          {
            "word": "однозначный",
            "count": 1,
            "phrase": "и вердикт однозначный"
          },
          {
            "word": "гораздо",
            "count": 2,
            "phrase": "вещи стали выглядеть гораздо свежее и опрятнее"
          },
          {
            "word": "свежее",
            "count": 1,
            "phrase": "вещи стали выглядеть гораздо свежее и опрятнее"
          },
          {
            "word": "опрятнее",
            "count": 1,
            "phrase": "вещи стали выглядеть гораздо свежее и опрятнее"
          },
          {
            "word": "единственный",
            "count": 3,
            "phrase": "спасибо 🤍 единственный минус - машинка пришла в упаковке"
          },
          {
            "word": "переклеенной",
            "count": 1,
            "phrase": "переклеенной скотчем от и до"
          },
          {
            "word": "скотчем",
            "count": 2,
            "phrase": "переклеенной скотчем от и до"
          },
          {
            "word": "вопрос",
            "count": 4,
            "phrase": "но это уже не к продавцу вопрос"
          },
          {
            "word": "учесть",
            "count": 1,
            "phrase": "если учесть что свитер был очень сильно заношен"
          },
          {
            "word": "заношен",
            "count": 2,
            "phrase": "если учесть что свитер был очень сильно заношен"
          },
          {
            "word": "прекрасен",
            "count": 1,
            "phrase": "то результат прекрасен"
          },
          {
            "word": "замечательный",
            "count": 1,
            "phrase": "видно невооружённым взглядом замечательный прибор"
          },
          {
            "word": "приобретением",
            "count": 1,
            "phrase": "я довольна приобретением"
          },
          {
            "word": "демократичная",
            "count": 1,
            "phrase": "цена демократичная"
          },
          {
            "word": "подойдёт",
            "count": 1,
            "phrase": "прибор вполне подойдёт в качестве подарка на 8 марта маме"
          },
          {
            "word": "подарка",
            "count": 4,
            "phrase": "прибор вполне подойдёт в качестве подарка на 8 марта маме"
          },
          {
            "word": "марта",
            "count": 3,
            "phrase": "прибор вполне подойдёт в качестве подарка на 8 марта маме"
          },
          {
            "word": "очистки",
            "count": 13,
            "phrase": "в комплекте щёточка для очистки прибора и шнур для зарядки"
          },
          {
            "word": "прибора",
            "count": 1,
            "phrase": "в комплекте щёточка для очистки прибора и шнур для зарядки"
          },
          {
            "word": "аккуратную",
            "count": 1,
            "phrase": "спасибо производителю и wb за быструю и аккуратную доставку"
          },
          {
            "word": "работоспособность",
            "count": 3,
            "phrase": "на работоспособность можно сразу проверить в пункте выдачи"
          },
          {
            "word": "функцией",
            "count": 9,
            "phrase": "со своей функцией справляется на ура"
          },
          {
            "word": "своих",
            "count": 9,
            "phrase": "своих денег стоит"
          },
          {
            "word": "денег",
            "count": 11,
            "phrase": "своих денег стоит"
          },
          {
            "word": "вовсе",
            "count": 2,
            "phrase": "и не mi вовсе"
          },
          {
            "word": "приклееный",
            "count": 1,
            "phrase": "несмотря на приклееный ярлычок"
          },
          {
            "word": "ярлычок",
            "count": 1,
            "phrase": "несмотря на приклееный ярлычок"
          },
          {
            "word": "рабочая",
            "count": 4,
            "phrase": "однако штука реально рабочая и в части аккумулятора"
          },
          {
            "word": "прямому",
            "count": 1,
            "phrase": "а так же по прямому назначению"
          },
          {
            "word": "назначению",
            "count": 1,
            "phrase": "а так же по прямому назначению"
          },
          {
            "word": "режет",
            "count": 3,
            "phrase": "режет даже капризный акрил"
          },
          {
            "word": "капризный",
            "count": 1,
            "phrase": "режет даже капризный акрил"
          },
          {
            "word": "акрил",
            "count": 2,
            "phrase": "режет даже капризный акрил"
          },
          {
            "word": "впал",
            "count": 1,
            "phrase": "сначала я впал в ступор"
          },
          {
            "word": "ступор",
            "count": 1,
            "phrase": "сначала я впал в ступор"
          },
          {
            "word": "срезаемого",
            "count": 1,
            "phrase": "а так же и срезаемого ворса"
          },
          {
            "word": "ворса",
            "count": 2,
            "phrase": "а так же и срезаемого ворса"
          },
          {
            "word": "который",
            "count": 13,
            "phrase": "который приходилось чистить"
          },
          {
            "word": "затруднялосб",
            "count": 1,
            "phrase": "затруднялосб вращение от налипания ворсовой массы"
          },
          {
            "word": "вращение",
            "count": 1,
            "phrase": "затруднялосб вращение от налипания ворсовой массы"
          },
          {
            "word": "налипания",
            "count": 1,
            "phrase": "затруднялосб вращение от налипания ворсовой массы"
          },
          {
            "word": "ворсовой",
            "count": 1,
            "phrase": "затруднялосб вращение от налипания ворсовой массы"
          },
          {
            "word": "массы",
            "count": 1,
            "phrase": "затруднялосб вращение от налипания ворсовой массы"
          },
          {
            "word": "продолжал",
            "count": 1,
            "phrase": "а контейнер при этом продолжал быть практически пустым"
          },
          {
            "word": "пустым",
            "count": 1,
            "phrase": "а контейнер при этом продолжал быть практически пустым"
          },
          {
            "word": "сбрызнул",
            "count": 1,
            "phrase": "я слегка сбрызнул полушерстяной свитер"
          },
          {
            "word": "полушерстяной",
            "count": 1,
            "phrase": "я слегка сбрызнул полушерстяной свитер"
          },
          {
            "word": "шерсть",
            "count": 3,
            "phrase": "акрил и шерсть"
          },
          {
            "word": "распылителя",
            "count": 1,
            "phrase": "из распылителя обычной водой"
          },
          {
            "word": "обычной",
            "count": 1,
            "phrase": "из распылителя обычной водой"
          },
          {
            "word": "водой",
            "count": 1,
            "phrase": "из распылителя обычной водой"
          },
          {
            "word": "поступая",
            "count": 1,
            "phrase": "поступая уже в контейнер для мусора"
          },
          {
            "word": "авлажный",
            "count": 0,
            "phrase": "авлажный ворс ещё и лучше срезается"
          },
          {
            "word": "срезается",
            "count": 1,
            "phrase": "авлажный ворс ещё и лучше срезается"
          },
          {
            "word": "милая",
            "count": 1,
            "phrase": "а какая - то милая"
          },
          {
            "word": "шумновато",
            "count": 3,
            "phrase": "шумновато конечно"
          },
          {
            "word": "носить",
            "count": 5,
            "phrase": "которые думала уже носить"
          },
          {
            "word": "занятие",
            "count": 4,
            "phrase": "да и занятие для меня оказалось увлекательное и нервы успокаивает"
          },
          {
            "word": "увлекательное",
            "count": 1,
            "phrase": "да и занятие для меня оказалось увлекательное и нервы успокаивает"
          },
          {
            "word": "нервы",
            "count": 1,
            "phrase": "да и занятие для меня оказалось увлекательное и нервы успокаивает"
          },
          {
            "word": "успокаивает",
            "count": 2,
            "phrase": "да и занятие для меня оказалось увлекательное и нервы успокаивает"
          },
          {
            "word": "шумела",
            "count": 1,
            "phrase": "чуть бы шумела потише и было бы вообще замечательно"
          },
          {
            "word": "потише",
            "count": 1,
            "phrase": "чуть бы шумела потише и было бы вообще замечательно"
          },
          {
            "word": "функциями",
            "count": 2,
            "phrase": "ну со своими функциями справляется"
          },
          {
            "word": "матрас",
            "count": 14,
            "phrase": "чтобы почистить матрас на двуспальной кровати"
          },
          {
            "word": "двуспальной",
            "count": 1,
            "phrase": "чтобы почистить матрас на двуспальной кровати"
          },
          {
            "word": "кровати",
            "count": 2,
            "phrase": "чтобы почистить матрас на двуспальной кровати"
          },
          {
            "word": "кошки",
            "count": 1,
            "phrase": "убрала все катышки и зацепки от кошки"
          },
          {
            "word": "бессчëтное",
            "count": 1,
            "phrase": "матрас и бессчëтное количество носков"
          },
          {
            "word": "количество",
            "count": 2,
            "phrase": "матрас и бессчëтное количество носков"
          },
          {
            "word": "носков",
            "count": 12,
            "phrase": "матрас и бессчëтное количество носков"
          },
          {
            "word": "тогда",
            "count": 2,
            "phrase": "и только тогда разрядилась в ноль"
          },
          {
            "word": "ноль",
            "count": 1,
            "phrase": "и только тогда разрядилась в ноль"
          },
          {
            "word": "проводом",
            "count": 2,
            "phrase": "с проводом и щеточкой"
          },
          {
            "word": "компактная",
            "count": 4,
            "phrase": "компактная коробочка"
          },
          {
            "word": "коробочка",
            "count": 3,
            "phrase": "компактная коробочка"
          },
          {
            "word": "хранить",
            "count": 1,
            "phrase": "в которой потом удобно хранить машинку"
          },
          {
            "word": "начала",
            "count": 21,
            "phrase": "если начала тормозить"
          },
          {
            "word": "тормозить",
            "count": 1,
            "phrase": "если начала тормозить"
          },
          {
            "word": "продолжить",
            "count": 1,
            "phrase": "почистить и продолжить"
          },
          {
            "word": "шайтан-",
            "count": 0,
            "phrase": "шайтан- машина"
          },
          {
            "word": "машина",
            "count": 4,
            "phrase": "шайтан- машина"
          },
          {
            "word": "толстовку",
            "count": 4,
            "phrase": "спасла мою толстовку и ещё спасёт немало вещей от неопрятности"
          },
          {
            "word": "спасёт",
            "count": 2,
            "phrase": "спасла мою толстовку и ещё спасёт немало вещей от неопрятности"
          },
          {
            "word": "неопрятности",
            "count": 1,
            "phrase": "спасла мою толстовку и ещё спасёт немало вещей от неопрятности"
          },
          {
            "word": "момент-",
            "count": 1,
            "phrase": "единственный момент- мелкие шурушки не берёт"
          },
          {
            "word": "шурушки",
            "count": 1,
            "phrase": "единственный момент- мелкие шурушки не берёт"
          },
          {
            "word": "заходят",
            "count": 1,
            "phrase": "они просто не заходят в сетку"
          },
          {
            "word": "соответственно",
            "count": 1,
            "phrase": "и соответственно не срезаются"
          },
          {
            "word": "срезаются",
            "count": 1,
            "phrase": "и соответственно не срезаются"
          },
          {
            "word": "мегашумная",
            "count": 1,
            "phrase": "она мегашумная"
          },
          {
            "word": "вечером",
            "count": 0,
            "phrase": "вечером если дети спят"
          },
          {
            "word": "дети",
            "count": 1,
            "phrase": "вечером если дети спят"
          },
          {
            "word": "спят",
            "count": 1,
            "phrase": "вечером если дети спят"
          },
          {
            "word": "бегло",
            "count": 2,
            "phrase": "один бегло обработан"
          },
          {
            "word": "адаптера",
            "count": 3,
            "phrase": "без адаптера"
          },
          {
            "word": "заряжалась",
            "count": 2,
            "phrase": "заряжалась около часа"
          },
          {
            "word": "насколько",
            "count": 2,
            "phrase": "насколько зарядилась"
          },
          {
            "word": "достать",
            "count": 1,
            "phrase": "кабель нужно достать"
          },
          {
            "word": "процессе",
            "count": 1,
            "phrase": "в процессе зарядки"
          },
          {
            "word": "включится",
            "count": 2,
            "phrase": "не включится"
          },
          {
            "word": "зазоров",
            "count": 1,
            "phrase": "без зазоров"
          },
          {
            "word": "ожидали",
            "count": 1,
            "phrase": "такой эффект даже не ожидали"
          },
          {
            "word": "срезали",
            "count": 1,
            "phrase": "все катышки срезали"
          },
          {
            "word": "приходил",
            "count": 6,
            "phrase": "чтобы товар приходил не мятый"
          },
          {
            "word": "мятый",
            "count": 2,
            "phrase": "чтобы товар приходил не мятый"
          },
          {
            "word": "мамы",
            "count": 1,
            "phrase": "я вот купила для мамы"
          },
          {
            "word": "презентабельно",
            "count": 1,
            "phrase": "а коробка выглядит не презентабельно"
          },
          {
            "word": "какую",
            "count": 2,
            "phrase": "но не хватало бы упаковку сделать какую - нибудь"
          },
          {
            "word": "нибудь",
            "count": 3,
            "phrase": "но не хватало бы упаковку сделать какую - нибудь"
          },
          {
            "word": "заторы",
            "count": 1,
            "phrase": "мелкие катышки и заторы убирает на четверочку"
          },
          {
            "word": "четверочку",
            "count": 1,
            "phrase": "мелкие катышки и заторы убирает на четверочку"
          },
          {
            "word": "судите",
            "count": 0,
            "phrase": "судите по фото"
          },
          {
            "word": "случайно",
            "count": 2,
            "phrase": "штаны случайно задели липучкой"
          },
          {
            "word": "задели",
            "count": 1,
            "phrase": "штаны случайно задели липучкой"
          },
          {
            "word": "липучкой",
            "count": 1,
            "phrase": "штаны случайно задели липучкой"
          },
          {
            "word": "удалению",
            "count": 1,
            "phrase": "сразу приступила к удалению катышков и о"
          },
          {
            "word": "запечатанной",
            "count": 1,
            "phrase": "пришла заряженная в коробке запечатанной"
          },
          {
            "word": "домой",
            "count": 4,
            "phrase": "проверила в пвз и пошла домой реанимировать одежду"
          },
          {
            "word": "реанимировать",
            "count": 3,
            "phrase": "проверила в пвз и пошла домой реанимировать одежду"
          },
          {
            "word": "рабочую",
            "count": 1,
            "phrase": "машинка имеет рабочую поверхность в 5 см"
          },
          {
            "word": "ускоряет",
            "count": 2,
            "phrase": "ускоряет работу"
          },
          {
            "word": "небо",
            "count": 7,
            "phrase": "небо и земля в сравнении с моей старой"
          },
          {
            "word": "земля",
            "count": 1,
            "phrase": "небо и земля в сравнении с моей старой"
          },
          {
            "word": "сравнении",
            "count": 2,
            "phrase": "небо и земля в сравнении с моей старой"
          },
          {
            "word": "моей",
            "count": 2,
            "phrase": "небо и земля в сравнении с моей старой"
          },
          {
            "word": "старой",
            "count": 4,
            "phrase": "небо и земля в сравнении с моей старой"
          },
          {
            "word": "мигает",
            "count": 3,
            "phrase": "мигает точка болого цвета"
          },
          {
            "word": "точка",
            "count": 71,
            "phrase": "мигает точка болого цвета"
          },
          {
            "word": "болого",
            "count": 1,
            "phrase": "мигает точка болого цвета"
          },
          {
            "word": "цвета",
            "count": 1,
            "phrase": "мигает точка болого цвета"
          },
          {
            "word": "применении",
            "count": 3,
            "phrase": "удобная в применении"
          },
          {
            "word": "половину",
            "count": 1,
            "phrase": "на фото почистила половину шапки"
          },
          {
            "word": "афигенная",
            "count": 1,
            "phrase": "машинка афигенная"
          },
          {
            "word": "удалось",
            "count": 3,
            "phrase": "удалось освежить детские"
          },
          {
            "word": "освежить",
            "count": 1,
            "phrase": "удалось освежить детские"
          },
          {
            "word": "презентабельнее",
            "count": 1,
            "phrase": "вид теперь намного презентабельнее"
          },
          {
            "word": "родной",
            "count": 2,
            "phrase": "пришла машинка в родной коробке"
          },
          {
            "word": "вскрытая",
            "count": 4,
            "phrase": "не вскрытая"
          },
          {
            "word": "устает",
            "count": 1,
            "phrase": "рука не устает"
          },
          {
            "word": "пользовалась",
            "count": 8,
            "phrase": "раньше пользовалась другими машинками"
          },
          {
            "word": "другими",
            "count": 1,
            "phrase": "раньше пользовалась другими машинками"
          },
          {
            "word": "цене",
            "count": 2,
            "phrase": "разница в цене была чуть меньше"
          },
          {
            "word": "хуже",
            "count": 4,
            "phrase": "а вот качество работы у них заметно хуже"
          },
          {
            "word": "ничуть",
            "count": 1,
            "phrase": "но ничуть не громче аппаратов той же ценовой категории"
          },
          {
            "word": "громче",
            "count": 1,
            "phrase": "но ничуть не громче аппаратов той же ценовой категории"
          },
          {
            "word": "аппаратов",
            "count": 1,
            "phrase": "но ничуть не громче аппаратов той же ценовой категории"
          },
          {
            "word": "ценовой",
            "count": 1,
            "phrase": "но ничуть не громче аппаратов той же ценовой категории"
          },
          {
            "word": "категории",
            "count": 1,
            "phrase": "но ничуть не громче аппаратов той же ценовой категории"
          },
          {
            "word": "скопившиеся",
            "count": 1,
            "phrase": "скопившиеся катышки легко удаляются из отсека для их сбора рекомендую"
          },
          {
            "word": "удаляются",
            "count": 2,
            "phrase": "скопившиеся катышки легко удаляются из отсека для их сбора рекомендую"
          },
          {
            "word": "отсека",
            "count": 1,
            "phrase": "скопившиеся катышки легко удаляются из отсека для их сбора рекомендую"
          },
          {
            "word": "круто",
            "count": 6,
            "phrase": "действительно круто выполняет свою работу"
          },
          {
            "word": "нужны",
            "count": 8,
            "phrase": "батарейки не нужны"
          },
          {
            "word": "обретают",
            "count": 4,
            "phrase": "вещи обретают с ней вторую жизнь"
          },
          {
            "word": "проверяла",
            "count": 1,
            "phrase": "на сколько хватает зарядки пока не проверяла"
          },
          {
            "word": "шнуром",
            "count": 2,
            "phrase": "в комплекте со шнуром и щеточкой"
          },
          {
            "word": "положили",
            "count": 3,
            "phrase": "в подарок положили тряпочку"
          },
          {
            "word": "тряпочку",
            "count": 1,
            "phrase": "в подарок положили тряпочку"
          },
          {
            "word": "приобретение",
            "count": 8,
            "phrase": "это лучшее приобретение за последнее время"
          },
          {
            "word": "обожаю",
            "count": 1,
            "phrase": "я обожаю обрезать катышки с одежды и других вещей"
          },
          {
            "word": "обрезать",
            "count": 2,
            "phrase": "я обожаю обрезать катышки с одежды и других вещей"
          },
          {
            "word": "придавая",
            "count": 1,
            "phrase": "придавая им снова свежий вид"
          },
          {
            "word": "свежий",
            "count": 2,
            "phrase": "придавая им снова свежий вид"
          },
          {
            "word": "покорила",
            "count": 1,
            "phrase": "но эта машинка меня просто покорила"
          },
          {
            "word": "инструкцию",
            "count": 2,
            "phrase": "инструкцию перевела"
          },
          {
            "word": "перевела",
            "count": 1,
            "phrase": "инструкцию перевела"
          },
          {
            "word": "важного:1",
            "count": 1,
            "phrase": "из важного:1"
          },
          {
            "word": "сбору",
            "count": 1,
            "phrase": "контейнер по сбору можно мыть"
          },
          {
            "word": "мыть",
            "count": 1,
            "phrase": "контейнер по сбору можно мыть"
          },
          {
            "word": "просушить",
            "count": 2,
            "phrase": "только хорошо просушить"
          },
          {
            "word": "машинки-",
            "count": 1,
            "phrase": "полный заряд машинки- 2 часа"
          },
          {
            "word": "дополнительный",
            "count": 1,
            "phrase": "дополнительный плюс"
          },
          {
            "word": "докупить",
            "count": 1,
            "phrase": "можно докупить сменное лезвие"
          },
          {
            "word": "сменное",
            "count": 3,
            "phrase": "можно докупить сменное лезвие"
          },
          {
            "word": "лезвие",
            "count": 10,
            "phrase": "можно докупить сменное лезвие"
          },
          {
            "word": "300р",
            "count": 3,
            "phrase": "до этого была за 300р"
          },
          {
            "word": "2го",
            "count": 1,
            "phrase": "сломалась после чистки 2го носка"
          },
          {
            "word": "носка",
            "count": 9,
            "phrase": "сломалась после чистки 2го носка"
          },
          {
            "word": "аккуратней",
            "count": 1,
            "phrase": "только надо аккуратней"
          },
          {
            "word": "доче",
            "count": 5,
            "phrase": "дала доче"
          },
          {
            "word": "дырку",
            "count": 2,
            "phrase": "она сделала дырку на носке(складку стала чистить"
          },
          {
            "word": "носке(складку",
            "count": 1,
            "phrase": "она сделала дырку на носке(складку стала чистить"
          },
          {
            "word": "кстать",
            "count": 0,
            "phrase": "кстать для детей машинка безопасна"
          },
          {
            "word": "детей",
            "count": 3,
            "phrase": "кстать для детей машинка безопасна"
          },
          {
            "word": "безопасна",
            "count": 2,
            "phrase": "кстать для детей машинка безопасна"
          },
          {
            "word": "хоолщая",
            "count": 1,
            "phrase": "и цена хоолщая"
          },
          {
            "word": "впечатляющий",
            "count": 1,
            "phrase": "результат впечатляющий"
          },
          {
            "word": "приобретению",
            "count": 3,
            "phrase": "советую к приобретению"
          },
          {
            "word": "толстовке",
            "count": 2,
            "phrase": "моей толстовке"
          },
          {
            "word": "второе",
            "count": 3,
            "phrase": "она дала второе дыхание"
          },
          {
            "word": "дыхание",
            "count": 3,
            "phrase": "она дала второе дыхание"
          },
          {
            "word": "приятный",
            "count": 4,
            "phrase": "плюс ещё и дизайн приятный"
          },
          {
            "word": "реанимировала",
            "count": 1,
            "phrase": "реанимировала свое любимое платье"
          },
          {
            "word": "свое",
            "count": 117,
            "phrase": "реанимировала свое любимое платье"
          },
          {
            "word": "любимое",
            "count": 2,
            "phrase": "реанимировала свое любимое платье"
          },
          {
            "word": "затерлось",
            "count": 1,
            "phrase": "которое затерлось от сумки"
          },
          {
            "word": "вычищается",
            "count": 0,
            "phrase": "вычищается легко при помощи щеточки в наборе"
          },
          {
            "word": "помощи",
            "count": 1,
            "phrase": "вычищается легко при помощи щеточки в наборе"
          },
          {
            "word": "какашка",
            "count": 1,
            "phrase": "будет какашка"
          },
          {
            "word": "деле",
            "count": 13,
            "phrase": "на деле офигенный приборчик"
          },
          {
            "word": "офигенный",
            "count": 1,
            "phrase": "на деле офигенный приборчик"
          },
          {
            "word": "приборчик",
            "count": 4,
            "phrase": "на деле офигенный приборчик"
          },
          {
            "word": "дал",
            "count": 158,
            "phrase": "дал 2ю жизнь моему многострадальному шопперу в огромных катышках"
          },
          {
            "word": "моему",
            "count": 3,
            "phrase": "дал 2ю жизнь моему многострадальному шопперу в огромных катышках"
          },
          {
            "word": "многострадальному",
            "count": 1,
            "phrase": "дал 2ю жизнь моему многострадальному шопперу в огромных катышках"
          },
          {
            "word": "шопперу",
            "count": 1,
            "phrase": "дал 2ю жизнь моему многострадальному шопперу в огромных катышках"
          },
          {
            "word": "огромных",
            "count": 1,
            "phrase": "дал 2ю жизнь моему многострадальному шопперу в огромных катышках"
          },
          {
            "word": "забыла",
            "count": 1,
            "phrase": "забыла лезвия к ней заказать"
          },
          {
            "word": "дальше",
            "count": 6,
            "phrase": "как дальше прослужит"
          },
          {
            "word": "нарадоваться",
            "count": 4,
            "phrase": "пока нарадоваться не могу"
          },
          {
            "word": "новенькие",
            "count": 5,
            "phrase": "все детские вещи как новенькие стали"
          },
          {
            "word": "тоненькими",
            "count": 1,
            "phrase": "только аккуратно надо с тоненькими вещами"
          },
          {
            "word": "попали",
            "count": 1,
            "phrase": "у меня детские колготки попали в машинку и сразу дырки"
          },
          {
            "word": "отвлеклась",
            "count": 1,
            "phrase": "отвлеклась на ребенка"
          },
          {
            "word": "разряжала",
            "count": 1,
            "phrase": "полностью не разряжала"
          },
          {
            "word": "ставила",
            "count": 13,
            "phrase": "но уже 2 раза ставила на зарядку"
          },
          {
            "word": "норм",
            "count": 13,
            "phrase": "пока все норм"
          },
          {
            "word": "остроты",
            "count": 1,
            "phrase": "посмотрим насколько хватит остроты лезвий"
          },
          {
            "word": "1-я",
            "count": 2,
            "phrase": "1-я отслужила свой долг)0"
          },
          {
            "word": "отслужила",
            "count": 1,
            "phrase": "1-я отслужила свой долг)0"
          },
          {
            "word": "долг)0",
            "count": 1,
            "phrase": "1-я отслужила свой долг)0"
          },
          {
            "word": "круглые",
            "count": 1,
            "phrase": "она отлично убирает круглые катышки"
          },
          {
            "word": "особой",
            "count": 1,
            "phrase": "а также катышки особой сложности"
          },
          {
            "word": "сложности",
            "count": 1,
            "phrase": "а также катышки особой сложности"
          },
          {
            "word": "появляются",
            "count": 2,
            "phrase": "которые появляются на шарфиках подобного материала"
          },
          {
            "word": "шарфиках",
            "count": 1,
            "phrase": "которые появляются на шарфиках подобного материала"
          },
          {
            "word": "подобного",
            "count": 1,
            "phrase": "которые появляются на шарфиках подобного материала"
          },
          {
            "word": "поводу",
            "count": 2,
            "phrase": "по поводу подзарядки ничего не могу сказать"
          },
          {
            "word": "подзарядки",
            "count": 3,
            "phrase": "по поводу подзарядки ничего не могу сказать"
          },
          {
            "word": "2-я",
            "count": 1,
            "phrase": "тк 2-я машинка пока не хочет разряжаться"
          },
          {
            "word": "хочет",
            "count": 6,
            "phrase": "тк 2-я машинка пока не хочет разряжаться"
          },
          {
            "word": "разряжаться",
            "count": 1,
            "phrase": "тк 2-я машинка пока не хочет разряжаться"
          },
          {
            "word": "партии",
            "count": 1,
            "phrase": "что товар из этой партии проработает столько же"
          },
          {
            "word": "проработает",
            "count": 3,
            "phrase": "что товар из этой партии проработает столько же"
          },
          {
            "word": "проработала",
            "count": 3,
            "phrase": "сколько проработала 1-я моделька"
          },
          {
            "word": "моделька",
            "count": 1,
            "phrase": "сколько проработала 1-я моделька"
          },
          {
            "word": "принципиально",
            "count": 1,
            "phrase": "но это не особо принципиально"
          },
          {
            "word": "изобретение",
            "count": 1,
            "phrase": "это лучшее изобретение человечества"
          },
          {
            "word": "человечества",
            "count": 1,
            "phrase": "это лучшее изобретение человечества"
          },
          {
            "word": "любимые",
            "count": 3,
            "phrase": "за пару дней смогла реанимировать свои любимые толстовку"
          },
          {
            "word": "шерстяную",
            "count": 2,
            "phrase": "шерстяную жилетку и кофту"
          },
          {
            "word": "жилетку",
            "count": 1,
            "phrase": "шерстяную жилетку и кофту"
          },
          {
            "word": "инвестиция",
            "count": 1,
            "phrase": "лучшая моя инвестиция"
          },
          {
            "word": "жизни",
            "count": 6,
            "phrase": "в жизни тактильно разница очень сильно видна"
          },
          {
            "word": "тактильно",
            "count": 2,
            "phrase": "в жизни тактильно разница очень сильно видна"
          },
          {
            "word": "прикрепляю",
            "count": 2,
            "phrase": "не пожалеете ниже прикрепляю фото до и после шерстяной жилетки"
          },
          {
            "word": "шерстяной",
            "count": 2,
            "phrase": "не пожалеете ниже прикрепляю фото до и после шерстяной жилетки"
          },
          {
            "word": "жилетки",
            "count": 1,
            "phrase": "не пожалеете ниже прикрепляю фото до и после шерстяной жилетки"
          },
          {
            "word": "отличном",
            "count": 3,
            "phrase": "пришёл в отличном состоянии"
          },
          {
            "word": "функции",
            "count": 3,
            "phrase": "функции свои выполняет на отлично"
          },
          {
            "word": "одним",
            "count": 1,
            "phrase": "одним словом супер"
          },
          {
            "word": "словом",
            "count": 1,
            "phrase": "одним словом супер"
          },
          {
            "word": "включалась",
            "count": 2,
            "phrase": "сначала не включалась"
          },
          {
            "word": "зарядила",
            "count": 5,
            "phrase": "я не зарядила и в инструкции прочитала"
          },
          {
            "word": "инструкции",
            "count": 3,
            "phrase": "я не зарядила и в инструкции прочитала"
          },
          {
            "word": "прочитала",
            "count": 1,
            "phrase": "я не зарядила и в инструкции прочитала"
          },
          {
            "word": "приоткрыть",
            "count": 1,
            "phrase": "что если где то чуть приоткрыть или недозащелкнуто"
          },
          {
            "word": "недозащелкнуто",
            "count": 1,
            "phrase": "что если где то чуть приоткрыть или недозащелкнуто"
          },
          {
            "word": "помощницу",
            "count": 1,
            "phrase": "вот и я забрала свою помощницу"
          },
          {
            "word": "посмотрела",
            "count": 2,
            "phrase": "но на пункте выдачи посмотрела"
          },
          {
            "word": "зарядник",
            "count": 1,
            "phrase": "работает и все на месте и зарядник и кисточка"
          },
          {
            "word": "оставляет",
            "count": 3,
            "phrase": "ничего за собой не оставляет и ткань не рвет"
          },
          {
            "word": "сфоткала",
            "count": 2,
            "phrase": "жаль не сфоткала пальто которое чистила"
          },
          {
            "word": "невероятное",
            "count": 1,
            "phrase": "в работе это просто что - то невероятное"
          },
          {
            "word": "включила",
            "count": 3,
            "phrase": "включила работает"
          },
          {
            "word": "дошел",
            "count": 1,
            "phrase": "дошел хорошо"
          },
          {
            "word": "доброго",
            "count": 0,
            "phrase": "доброго времени суток"
          },
          {
            "word": "суток",
            "count": 1,
            "phrase": "доброго времени суток"
          },
          {
            "word": "впервые",
            "count": 0,
            "phrase": "впервые решил воспользоваться машинкойи остался доволен"
          },
          {
            "word": "решил",
            "count": 21,
            "phrase": "впервые решил воспользоваться машинкойи остался доволен"
          },
          {
            "word": "воспользоваться",
            "count": 1,
            "phrase": "впервые решил воспользоваться машинкойи остался доволен"
          },
          {
            "word": "машинкойи",
            "count": 1,
            "phrase": "впервые решил воспользоваться машинкойи остался доволен"
          },
          {
            "word": "остался",
            "count": 3,
            "phrase": "впервые решил воспользоваться машинкойи остался доволен"
          },
          {
            "word": "обрела",
            "count": 16,
            "phrase": "теперь собственно она обрела первозданный вид"
          },
          {
            "word": "первозданный",
            "count": 1,
            "phrase": "теперь собственно она обрела первозданный вид"
          },
          {
            "word": "питания",
            "count": 2,
            "phrase": "шнур питания инструкция по применения машинка работает в полном комплекте"
          },
          {
            "word": "применения",
            "count": 4,
            "phrase": "шнур питания инструкция по применения машинка работает в полном комплекте"
          },
          {
            "word": "суппер",
            "count": 1,
            "phrase": "машинка суппер работает быстро"
          },
          {
            "word": "вредный",
            "count": 1,
            "phrase": "я почему раньше вредный был"
          },
          {
            "word": "творит",
            "count": 1,
            "phrase": "эта штука творит настоящие чудеса"
          },
          {
            "word": "настоящие",
            "count": 1,
            "phrase": "эта штука творит настоящие чудеса"
          },
          {
            "word": "чудеса",
            "count": 1,
            "phrase": "эта штука творит настоящие чудеса"
          },
          {
            "word": "корпус",
            "count": 4,
            "phrase": "корпус прочный"
          },
          {
            "word": "люфтит",
            "count": 1,
            "phrase": "ничего не люфтит"
          },
          {
            "word": "красивый",
            "count": 6,
            "phrase": "удобный и красивый дизайн"
          },
          {
            "word": "первое",
            "count": 2,
            "phrase": "пусть первое впечатление не развеется и машинка прослужит долго"
          },
          {
            "word": "развеется",
            "count": 1,
            "phrase": "пусть первое впечатление не развеется и машинка прослужит долго"
          },
          {
            "word": "обновляется",
            "count": 1,
            "phrase": "всё обновляется"
          },
          {
            "word": "вновь",
            "count": 1,
            "phrase": "как вновь из магазина"
          },
          {
            "word": "магазина",
            "count": 5,
            "phrase": "как вновь из магазина"
          },
          {
            "word": "легка",
            "count": 10,
            "phrase": "легка в эксплуатации"
          },
          {
            "word": "зона",
            "count": 2,
            "phrase": "за 2 минут зона обработана"
          },
          {
            "word": "полной",
            "count": 3,
            "phrase": "только до полной зарядки и вперёд"
          },
          {
            "word": "вперёд",
            "count": 1,
            "phrase": "только до полной зарядки и вперёд"
          },
          {
            "word": "диск",
            "count": 3,
            "phrase": "диск - сеточка регулируется по плотности воздействия на лезвия"
          },
          {
            "word": "регулируется",
            "count": 1,
            "phrase": "диск - сеточка регулируется по плотности воздействия на лезвия"
          },
          {
            "word": "плотности",
            "count": 1,
            "phrase": "диск - сеточка регулируется по плотности воздействия на лезвия"
          },
          {
            "word": "воздействия",
            "count": 1,
            "phrase": "диск - сеточка регулируется по плотности воздействия на лезвия"
          },
          {
            "word": "деликатно",
            "count": 1,
            "phrase": "деликатно и хорошо удаляет катышки"
          },
          {
            "word": "открывается",
            "count": 2,
            "phrase": "все легко открывается и чистится"
          },
          {
            "word": "портативная",
            "count": 1,
            "phrase": "отличная портативная машинка для удаления катышков"
          },
          {
            "word": "прошла",
            "count": 5,
            "phrase": "прошла первую проверку на эффективность"
          },
          {
            "word": "первую",
            "count": 2,
            "phrase": "прошла первую проверку на эффективность"
          },
          {
            "word": "проверку",
            "count": 4,
            "phrase": "прошла первую проверку на эффективность"
          },
          {
            "word": "эффективность",
            "count": 1,
            "phrase": "прошла первую проверку на эффективность"
          },
          {
            "word": "результатом",
            "count": 1,
            "phrase": "я результатом очень довольна"
          },
          {
            "word": "можете",
            "count": 1,
            "phrase": "можете сами оценить разницу"
          },
          {
            "word": "оценить",
            "count": 1,
            "phrase": "можете сами оценить разницу"
          },
          {
            "word": "джемпер",
            "count": 5,
            "phrase": "джемпер стал как новый"
          },
          {
            "word": "прибором",
            "count": 1,
            "phrase": "коробка с прибором была вскрыта"
          },
          {
            "word": "повезло",
            "count": 1,
            "phrase": "но повезло"
          },
          {
            "word": "довольно",
            "count": 6,
            "phrase": "со своей задачей справляется довольно хорошо"
          },
          {
            "word": "рвёт",
            "count": 5,
            "phrase": "ткань не рвёт"
          },
          {
            "word": "испробовано",
            "count": 1,
            "phrase": "пока испробовано на штанах"
          },
          {
            "word": "примере",
            "count": 1,
            "phrase": "на примере с диваном видно"
          },
          {
            "word": "диваном",
            "count": 2,
            "phrase": "на примере с диваном видно"
          },
          {
            "word": "первого",
            "count": 5,
            "phrase": "что убирает почти все катышки с первого раза"
          },
          {
            "word": "2ом",
            "count": 1,
            "phrase": "на 2ом - конечный результат"
          },
          {
            "word": "конечный",
            "count": 1,
            "phrase": "на 2ом - конечный результат"
          },
          {
            "word": "районе",
            "count": 2,
            "phrase": "купила в районе 600 рублей"
          },
          {
            "word": "опробована",
            "count": 1,
            "phrase": "была опробована сразу на пальто"
          },
          {
            "word": "применению",
            "count": 1,
            "phrase": "инструкция по применению"
          },
          {
            "word": "считывается",
            "count": 1,
            "phrase": "не считывается"
          },
          {
            "word": "код",
            "count": 8,
            "phrase": "но qr - код на упаковке"
          },
          {
            "word": "честно",
            "count": 1,
            "phrase": "честно сказать я приятно удивлена качеству этой машинки"
          },
          {
            "word": "качеству",
            "count": 3,
            "phrase": "честно сказать я приятно удивлена качеству этой машинки"
          },
          {
            "word": "собственное",
            "count": 1,
            "phrase": "она очень мощная и имеет собственное зарядное устройство"
          },
          {
            "word": "эффективно",
            "count": 5,
            "phrase": "быстро и эффективно производит очистку"
          },
          {
            "word": "производит",
            "count": 13,
            "phrase": "быстро и эффективно производит очистку"
          },
          {
            "word": "очистку",
            "count": 1,
            "phrase": "быстро и эффективно производит очистку"
          },
          {
            "word": "повреждает",
            "count": 2,
            "phrase": "при этом не повреждает ткань изделия"
          },
          {
            "word": "изделия",
            "count": 2,
            "phrase": "при этом не повреждает ткань изделия"
          },
          {
            "word": "белый",
            "count": 2,
            "phrase": "у нее красивый белый дизайн"
          },
          {
            "word": "полупрозрачной",
            "count": 1,
            "phrase": "с полупрозрачной емкостью и светодиодным индикатором"
          },
          {
            "word": "емкостью",
            "count": 1,
            "phrase": "с полупрозрачной емкостью и светодиодным индикатором"
          },
          {
            "word": "светодиодным",
            "count": 1,
            "phrase": "с полупрозрачной емкостью и светодиодным индикатором"
          },
          {
            "word": "индикатором",
            "count": 1,
            "phrase": "с полупрозрачной емкостью и светодиодным индикатором"
          },
          {
            "word": "производство",
            "count": 3,
            "phrase": "спасибо большое за производство такого нужного товара"
          },
          {
            "word": "нужного",
            "count": 1,
            "phrase": "спасибо большое за производство такого нужного товара"
          },
          {
            "word": "всегда",
            "count": 7,
            "phrase": "который помогает мне всегда выглядеть аккуратно и опрятно"
          },
          {
            "word": "опрятно",
            "count": 4,
            "phrase": "который помогает мне всегда выглядеть аккуратно и опрятно"
          },
          {
            "word": "приносит",
            "count": 1,
            "phrase": "пусть работа всегда приносит вам радость"
          },
          {
            "word": "радость",
            "count": 1,
            "phrase": "пусть работа всегда приносит вам радость"
          },
          {
            "word": "человека",
            "count": 2,
            "phrase": "нет для человека ничего лучше"
          },
          {
            "word": "пить",
            "count": 15,
            "phrase": "чем есть и пить и получать удовольствие от своего труда"
          },
          {
            "word": "получать",
            "count": 1,
            "phrase": "чем есть и пить и получать удовольствие от своего труда"
          },
          {
            "word": "удовольствие",
            "count": 4,
            "phrase": "чем есть и пить и получать удовольствие от своего труда"
          },
          {
            "word": "своего",
            "count": 1,
            "phrase": "чем есть и пить и получать удовольствие от своего труда"
          },
          {
            "word": "труда",
            "count": 4,
            "phrase": "чем есть и пить и получать удовольствие от своего труда"
          },
          {
            "word": "говорится",
            "count": 2,
            "phrase": "где говорится"
          },
          {
            "word": "заботьтесь",
            "count": 1,
            "phrase": "заботьтесь не только о своих интересах"
          },
          {
            "word": "интересах",
            "count": 2,
            "phrase": "заботьтесь не только о своих интересах"
          },
          {
            "word": "выражаю",
            "count": 0,
            "phrase": "выражаю вам свою искреннюю благодарность"
          },
          {
            "word": "искреннюю",
            "count": 1,
            "phrase": "выражаю вам свою искреннюю благодарность"
          },
          {
            "word": "благодарность",
            "count": 2,
            "phrase": "выражаю вам свою искреннюю благодарность"
          },
          {
            "word": "желаю",
            "count": 1,
            "phrase": "желаю хорошей работы и новых идей"
          },
          {
            "word": "новых",
            "count": 4,
            "phrase": "желаю хорошей работы и новых идей"
          },
          {
            "word": "идей",
            "count": 1,
            "phrase": "желаю хорошей работы и новых идей"
          },
          {
            "word": "продолжайте",
            "count": 0,
            "phrase": "продолжайте и дальше радовать своих покупателей"
          },
          {
            "word": "радовать",
            "count": 5,
            "phrase": "продолжайте и дальше радовать своих покупателей"
          },
          {
            "word": "покупателей",
            "count": 2,
            "phrase": "продолжайте и дальше радовать своих покупателей"
          },
          {
            "word": "уважением",
            "count": 0,
            "phrase": "с уважением"
          },
          {
            "word": "обновили",
            "count": 2,
            "phrase": "обновили пол гардероба"
          },
          {
            "word": "гардероба",
            "count": 1,
            "phrase": "обновили пол гардероба"
          },
          {
            "word": "скажу",
            "count": 4,
            "phrase": "сразу скажу"
          },
          {
            "word": "порой",
            "count": 1,
            "phrase": "очень много порой"
          },
          {
            "word": "шажками",
            "count": 1,
            "phrase": "чистить мелкими шажками"
          },
          {
            "word": "круговыми",
            "count": 3,
            "phrase": "маленькими круговыми движениями"
          },
          {
            "word": "движениями",
            "count": 3,
            "phrase": "маленькими круговыми движениями"
          },
          {
            "word": "иногда",
            "count": 1,
            "phrase": "иногда часто останавливаться"
          },
          {
            "word": "часто",
            "count": 4,
            "phrase": "иногда часто останавливаться"
          },
          {
            "word": "останавливаться",
            "count": 1,
            "phrase": "иногда часто останавливаться"
          },
          {
            "word": "зависит",
            "count": 2,
            "phrase": "зависит от того"
          },
          {
            "word": "отсутствие",
            "count": 0,
            "phrase": "отсутствие блока питания"
          },
          {
            "word": "приобретать",
            "count": 2,
            "phrase": "либо приобретать дополнительно"
          },
          {
            "word": "дополнительно",
            "count": 9,
            "phrase": "либо приобретать дополнительно"
          },
          {
            "word": "первая",
            "count": 4,
            "phrase": "моя первая такая машинка"
          },
          {
            "word": "старые",
            "count": 5,
            "phrase": "старые неприятные носки быстро стали чистыми и аккуратными"
          },
          {
            "word": "неприятные",
            "count": 2,
            "phrase": "старые неприятные носки быстро стали чистыми и аккуратными"
          },
          {
            "word": "чистыми",
            "count": 1,
            "phrase": "старые неприятные носки быстро стали чистыми и аккуратными"
          },
          {
            "word": "аккуратными",
            "count": 2,
            "phrase": "старые неприятные носки быстро стали чистыми и аккуратными"
          },
          {
            "word": "довести",
            "count": 1,
            "phrase": "если постараться можно довести до идеала"
          },
          {
            "word": "идеала",
            "count": 1,
            "phrase": "если постараться можно довести до идеала"
          },
          {
            "word": "расслабляет",
            "count": 1,
            "phrase": "сам процесс очень расслабляет"
          },
          {
            "word": "обновляются",
            "count": 1,
            "phrase": "но главное конечно то как вещи обновляются"
          },
          {
            "word": "мучалась",
            "count": 1,
            "phrase": "мучалась с катышками"
          },
          {
            "word": "приложу",
            "count": 1,
            "phrase": "приложу фото до"
          },
          {
            "word": "чуда",
            "count": 2,
            "phrase": "как я раньше жила без этого чуда"
          },
          {
            "word": "открытии",
            "count": 1,
            "phrase": "при открытии контейнера"
          },
          {
            "word": "нажать",
            "count": 1,
            "phrase": "если случайно нажать на кнопу вкл"
          },
          {
            "word": "кнопу",
            "count": 1,
            "phrase": "если случайно нажать на кнопу вкл"
          },
          {
            "word": "вкл",
            "count": 29,
            "phrase": "если случайно нажать на кнопу вкл"
          },
          {
            "word": "включаться",
            "count": 2,
            "phrase": "включаться не будет"
          },
          {
            "word": "миг",
            "count": 5,
            "phrase": "в один миг избавилась от ненавистных катышков"
          },
          {
            "word": "избавилась",
            "count": 1,
            "phrase": "в один миг избавилась от ненавистных катышков"
          },
          {
            "word": "ненавистных",
            "count": 1,
            "phrase": "в один миг избавилась от ненавистных катышков"
          },
          {
            "word": "любимому",
            "count": 1,
            "phrase": "огромное спасибо любимому магазину"
          },
          {
            "word": "магазину",
            "count": 2,
            "phrase": "огромное спасибо любимому магазину"
          },
          {
            "word": "пух",
            "count": 2,
            "phrase": "и пух не летит в разные стороны"
          },
          {
            "word": "летит",
            "count": 1,
            "phrase": "и пух не летит в разные стороны"
          },
          {
            "word": "разные",
            "count": 2,
            "phrase": "и пух не летит в разные стороны"
          },
          {
            "word": "остаётся",
            "count": 2,
            "phrase": "пух остаётся в контейнере"
          },
          {
            "word": "контейнере",
            "count": 5,
            "phrase": "пух остаётся в контейнере"
          },
          {
            "word": "дарит",
            "count": 4,
            "phrase": "дарит одежду вторую жизнь"
          },
          {
            "word": "миллион",
            "count": 2,
            "phrase": "ставлю миллион"
          },
          {
            "word": "хорошее",
            "count": 6,
            "phrase": "качество хорошее"
          },
          {
            "word": "дочь",
            "count": 3,
            "phrase": "брала моя дочь и я взяла по её совету"
          },
          {
            "word": "совету",
            "count": 34,
            "phrase": "брала моя дочь и я взяла по её совету"
          },
          {
            "word": "показать",
            "count": 2,
            "phrase": "не могу показать фото вещи"
          },
          {
            "word": "мерзкие",
            "count": 1,
            "phrase": "потому что эти мерзкие катышки настолько мелкие"
          },
          {
            "word": "малышки",
            "count": 2,
            "phrase": "но есть фото результата работы этой малышки"
          },
          {
            "word": "пешком",
            "count": 1,
            "phrase": "будто пешком добиралась сама"
          },
          {
            "word": "добиралась",
            "count": 1,
            "phrase": "будто пешком добиралась сама"
          },
          {
            "word": "маленькая",
            "count": 5,
            "phrase": "раньше у меня была совсем маленькая"
          },
          {
            "word": "снять",
            "count": 2,
            "phrase": "чтобы снять мелкие нужно надавить и подольше подержать"
          },
          {
            "word": "надавить",
            "count": 2,
            "phrase": "чтобы снять мелкие нужно надавить и подольше подержать"
          },
          {
            "word": "подержать",
            "count": 1,
            "phrase": "чтобы снять мелкие нужно надавить и подольше подержать"
          },
          {
            "word": "покупкам",
            "count": 1,
            "phrase": "давно так не радовалась покупкам"
          },
          {
            "word": "неоценимая",
            "count": 1,
            "phrase": "вещь просто неоценимая"
          },
          {
            "word": "некоторым",
            "count": 1,
            "phrase": "благодаря некоторым отзывам на тему"
          },
          {
            "word": "тему",
            "count": 1,
            "phrase": "благодаря некоторым отзывам на тему"
          },
          {
            "word": "тратиться",
            "count": 2,
            "phrase": "а не тратиться на дешевку ибо все равно смомается"
          },
          {
            "word": "дешевку",
            "count": 1,
            "phrase": "а не тратиться на дешевку ибо все равно смомается"
          },
          {
            "word": "смомается",
            "count": 1,
            "phrase": "а не тратиться на дешевку ибо все равно смомается"
          },
          {
            "word": "выбрала",
            "count": 2,
            "phrase": "выбрала эту машинку"
          },
          {
            "word": "привела",
            "count": 8,
            "phrase": "всю одежду привела в порядок"
          },
          {
            "word": "дикий",
            "count": 1,
            "phrase": "у них тоже дикий восторг"
          },
          {
            "word": "обидно",
            "count": 6,
            "phrase": "обидно немного"
          },
          {
            "word": "отвлекаюсь",
            "count": 1,
            "phrase": "если отвлекаюсь при чистке одежды"
          },
          {
            "word": "пропарываю",
            "count": 1,
            "phrase": "пропарываю дырки"
          },
          {
            "word": "косорукость",
            "count": 1,
            "phrase": "но это моя косорукость виновата"
          },
          {
            "word": "виновата",
            "count": 1,
            "phrase": "но это моя косорукость виновата"
          },
          {
            "word": "минусов",
            "count": 1,
            "phrase": "из минусов - у машинки очень острые лезвия"
          },
          {
            "word": "пробу",
            "count": 1,
            "phrase": "брала на пробу"
          },
          {
            "word": "посмотреть",
            "count": 1,
            "phrase": "чтобы посмотреть"
          },
          {
            "word": "подойдет",
            "count": 3,
            "phrase": "подойдет ли в качестве подарка"
          },
          {
            "word": "штуки",
            "count": 1,
            "phrase": "так что возьму еще три штуки"
          },
          {
            "word": "совет",
            "count": 40,
            "phrase": "совет продавцу"
          },
          {
            "word": "вложиться",
            "count": 1,
            "phrase": "лучше немного вложиться во внешнюю упаковку коробки"
          },
          {
            "word": "внешнюю",
            "count": 1,
            "phrase": "лучше немного вложиться во внешнюю упаковку коробки"
          },
          {
            "word": "ставить",
            "count": 6,
            "phrase": "тут все 7 звезд можно ставить"
          },
          {
            "word": "бизиборд",
            "count": 1,
            "phrase": "бизиборд из флиса всё очистила на ура"
          },
          {
            "word": "флиса",
            "count": 1,
            "phrase": "бизиборд из флиса всё очистила на ура"
          },
          {
            "word": "тестить",
            "count": 3,
            "phrase": "но я решила тестить так тестить и пошла к матрасам"
          },
          {
            "word": "матрасам",
            "count": 1,
            "phrase": "но я решила тестить так тестить и пошла к матрасам"
          },
          {
            "word": "матрасах",
            "count": 1,
            "phrase": "на матрасах катышки большие и другие машинки всегда"
          },
          {
            "word": "раскручивать",
            "count": 2,
            "phrase": "приходилось раскручивать и чистить"
          },
          {
            "word": "нитки",
            "count": 9,
            "phrase": "убрала даже нитки от затяжек"
          },
          {
            "word": "затяжек",
            "count": 1,
            "phrase": "убрала даже нитки от затяжек"
          },
          {
            "word": "написать",
            "count": 1,
            "phrase": "хотела в минус написать"
          },
          {
            "word": "огромная",
            "count": 1,
            "phrase": "что огромная она конечно"
          },
          {
            "word": "открытия",
            "count": 1,
            "phrase": "но это первое впечатление после открытия"
          },
          {
            "word": "матрасов",
            "count": 1,
            "phrase": "а после матрасов уже и привыкла к такому размеру"
          },
          {
            "word": "привыкла",
            "count": 2,
            "phrase": "а после матрасов уже и привыкла к такому размеру"
          },
          {
            "word": "такому",
            "count": 2,
            "phrase": "а после матрасов уже и привыкла к такому размеру"
          },
          {
            "word": "лапша",
            "count": 1,
            "phrase": "платье - лапша"
          },
          {
            "word": "полным",
            "count": 2,
            "phrase": "с полным комплектом"
          },
          {
            "word": "комплектом",
            "count": 1,
            "phrase": "с полным комплектом"
          },
          {
            "word": "комплекту",
            "count": 2,
            "phrase": "дала вторую жизнь комплекту постельного белья"
          },
          {
            "word": "постельного",
            "count": 1,
            "phrase": "дала вторую жизнь комплекту постельного белья"
          },
          {
            "word": "белья",
            "count": 1,
            "phrase": "дала вторую жизнь комплекту постельного белья"
          },
          {
            "word": "работала",
            "count": 14,
            "phrase": "работала + - час"
          },
          {
            "word": "использовала",
            "count": 1,
            "phrase": "сразу же использовала"
          },
          {
            "word": "выбросила",
            "count": 3,
            "phrase": "сколько вещей я выбросила"
          },
          {
            "word": "прост",
            "count": 127,
            "phrase": "прост в использовании"
          },
          {
            "word": "носочек",
            "count": 1,
            "phrase": "один носочек на фото после обработки машинкой"
          },
          {
            "word": "любимых",
            "count": 1,
            "phrase": "убрала катышки с любимых легинсов"
          },
          {
            "word": "легинсов",
            "count": 1,
            "phrase": "убрала катышки с любимых легинсов"
          },
          {
            "word": "левая",
            "count": 3,
            "phrase": "левая штанина после удаления"
          },
          {
            "word": "штанина",
            "count": 3,
            "phrase": "левая штанина после удаления"
          },
          {
            "word": "правая",
            "count": 2,
            "phrase": "правая до"
          },
          {
            "word": "эргономически",
            "count": 0,
            "phrase": "эргономически удобная"
          },
          {
            "word": "предоставила",
            "count": 1,
            "phrase": "на фото предоставила результат до"
          },
          {
            "word": "настоящее",
            "count": 0,
            "phrase": "настоящее спасение для вещей на которых очень быстро появляются катышки"
          },
          {
            "word": "которых",
            "count": 6,
            "phrase": "настоящее спасение для вещей на которых очень быстро появляются катышки"
          },
          {
            "word": "покупал",
            "count": 13,
            "phrase": "покупал ещё 2021 году"
          },
          {
            "word": "2021",
            "count": 1,
            "phrase": "покупал ещё 2021 году"
          },
          {
            "word": "году",
            "count": 1,
            "phrase": "покупал ещё 2021 году"
          },
          {
            "word": "нариканий",
            "count": 1,
            "phrase": "работает без нариканий и без с боев"
          },
          {
            "word": "боев",
            "count": 1,
            "phrase": "работает без нариканий и без с боев"
          },
          {
            "word": "заряжал",
            "count": 11,
            "phrase": "заряжал всего 2 раза за время использования"
          },
          {
            "word": "всего",
            "count": 5,
            "phrase": "заряжал всего 2 раза за время использования"
          },
          {
            "word": "катушек",
            "count": 3,
            "phrase": "если вам нужна машинка для удаления катушек с одежды"
          },
          {
            "word": "захотел",
            "count": 1,
            "phrase": "вот захотел поделиться о её работе даже сейчас"
          },
          {
            "word": "поделиться",
            "count": 1,
            "phrase": "вот захотел поделиться о её работе даже сейчас"
          },
          {
            "word": "невероятно",
            "count": 2,
            "phrase": "потому что восторга просто невероятно много"
          },
          {
            "word": "копейки",
            "count": 1,
            "phrase": "стоит копейки"
          },
          {
            "word": "помогла",
            "count": 1,
            "phrase": "зато как помогла"
          },
          {
            "word": "хлипкий",
            "count": 1,
            "phrase": "пластик не хлипкий"
          },
          {
            "word": "кнопка",
            "count": 5,
            "phrase": "кнопка включения в удобном месте"
          },
          {
            "word": "включения",
            "count": 4,
            "phrase": "кнопка включения в удобном месте"
          },
          {
            "word": "удобном",
            "count": 1,
            "phrase": "кнопка включения в удобном месте"
          },
          {
            "word": "шайтан",
            "count": 0,
            "phrase": "шайтан - машина"
          },
          {
            "word": "сражу",
            "count": 0,
            "phrase": "сражу же опробовала аппарат как пришел и это"
          },
          {
            "word": "ниточки",
            "count": 1,
            "phrase": "хорошо убирает катышки и даже срезает ниточки не нужные"
          },
          {
            "word": "нужные",
            "count": 1,
            "phrase": "хорошо убирает катышки и даже срезает ниточки не нужные"
          },
          {
            "word": "одна",
            "count": 8,
            "phrase": "одна из лучших покупок 2023 года"
          },
          {
            "word": "лучших",
            "count": 3,
            "phrase": "одна из лучших покупок 2023 года"
          },
          {
            "word": "покупок",
            "count": 3,
            "phrase": "одна из лучших покупок 2023 года"
          },
          {
            "word": "2023",
            "count": 1,
            "phrase": "одна из лучших покупок 2023 года"
          },
          {
            "word": "девочки",
            "count": 1,
            "phrase": "девочки берите не откладывайте покупку"
          },
          {
            "word": "откладывайте",
            "count": 0,
            "phrase": "девочки берите не откладывайте покупку"
          },
          {
            "word": "выкидывала",
            "count": 2,
            "phrase": "я столько выкидывала вещей а тут такая штука"
          },
          {
            "word": "шикарное",
            "count": 2,
            "phrase": "качество просто шикарное"
          },
          {
            "word": "забит",
            "count": 1,
            "phrase": "пух забит"
          },
          {
            "word": "продавец",
            "count": 5,
            "phrase": "спасибо большое продавец и вайлдберис за возможность покупать классные вещи"
          },
          {
            "word": "вайлдберис",
            "count": 0,
            "phrase": "спасибо большое продавец и вайлдберис за возможность покупать классные вещи"
          },
          {
            "word": "классные",
            "count": 1,
            "phrase": "спасибо большое продавец и вайлдберис за возможность покупать классные вещи"
          },
          {
            "word": "офигенная",
            "count": 1,
            "phrase": "офигенная машинка"
          },
          {
            "word": "гардероб",
            "count": 3,
            "phrase": "просто новый гардероб"
          },
          {
            "word": "порадовала",
            "count": 1,
            "phrase": "особенно порадовала зарядка от сети"
          },
          {
            "word": "малыша",
            "count": 1,
            "phrase": "я очень довольна работой этого малыша"
          },
          {
            "word": "аккуратный",
            "count": 1,
            "phrase": "вещи приобретают более свежий и аккуратный вид"
          },
          {
            "word": "трикотажную",
            "count": 1,
            "phrase": "трикотажную и пальто"
          },
          {
            "word": "ожидания!прекрасная",
            "count": 0,
            "phrase": "машинка оправдала мои ожидания!прекрасная нужная вещь!очистила шерстяную кофту"
          },
          {
            "word": "вещь!очистила",
            "count": 0,
            "phrase": "машинка оправдала мои ожидания!прекрасная нужная вещь!очистила шерстяную кофту"
          },
          {
            "word": "покажет",
            "count": 2,
            "phrase": "покажет время"
          },
          {
            "word": "помощница",
            "count": 4,
            "phrase": "что у меня появилась такая помощница 🥰 надеюсь прослужит долго"
          },
          {
            "word": "нууу",
            "count": 0,
            "phrase": "нууу все понятно по фото"
          },
          {
            "word": "запущенные",
            "count": 1,
            "phrase": "нашла самые запущенные носки дочери и начала чистить"
          },
          {
            "word": "дочери",
            "count": 4,
            "phrase": "нашла самые запущенные носки дочери и начала чистить"
          },
          {
            "word": "девчонки",
            "count": 1,
            "phrase": "девчонки берите"
          },
          {
            "word": "моих",
            "count": 4,
            "phrase": "второе дыхание для моих носков"
          },
          {
            "word": "маленькие",
            "count": 4,
            "phrase": "за такие ещё и маленькие деньги главное аккуратно это делайте"
          },
          {
            "word": "делайте",
            "count": 2,
            "phrase": "за такие ещё и маленькие деньги главное аккуратно это делайте"
          },
          {
            "word": "рабочий",
            "count": 1,
            "phrase": "норм рабочий"
          },
          {
            "word": "вместительный",
            "count": 1,
            "phrase": "вместительный отсек для сбора катышек"
          },
          {
            "word": "свитера",
            "count": 11,
            "phrase": "свитера и вязанное чистит хорошо"
          },
          {
            "word": "вязанное",
            "count": 1,
            "phrase": "свитера и вязанное чистит хорошо"
          },
          {
            "word": "демисезонное",
            "count": 1,
            "phrase": "пальто демисезонное чистит"
          },
          {
            "word": "устройстве",
            "count": 1,
            "phrase": "а не в устройстве"
          },
          {
            "word": "комплектующие",
            "count": 1,
            "phrase": "все комплектующие на месте"
          },
          {
            "word": "доставили",
            "count": 1,
            "phrase": "доставили во время"
          },
          {
            "word": "несомненный",
            "count": 1,
            "phrase": "несомненный плюс"
          },
          {
            "word": "процентов",
            "count": 2,
            "phrase": "пришла заряженная процентов на 20"
          },
          {
            "word": "закатанная",
            "count": 1,
            "phrase": "на фото закатанная школьная кофта"
          },
          {
            "word": "школьная",
            "count": 1,
            "phrase": "на фото закатанная школьная кофта"
          },
          {
            "word": "кофта",
            "count": 4,
            "phrase": "на фото закатанная школьная кофта"
          },
          {
            "word": "беру",
            "count": 3,
            "phrase": "когда беру в руки вещь в катышках"
          },
          {
            "word": "рабочем",
            "count": 2,
            "phrase": "в рабочем состоянии и в полной комплектации"
          },
          {
            "word": "комплектации",
            "count": 3,
            "phrase": "в рабочем состоянии и в полной комплектации"
          },
          {
            "word": "картинке",
            "count": 1,
            "phrase": "полностью соответствует картинке и описанию"
          },
          {
            "word": "уходе",
            "count": 2,
            "phrase": "нужная вещь в уходе за вещами"
          },
          {
            "word": "почистили",
            "count": 0,
            "phrase": "почистили шапку"
          },
          {
            "word": "1фото",
            "count": 1,
            "phrase": "на 1фото видно как поработала машинка"
          },
          {
            "word": "поработала",
            "count": 3,
            "phrase": "на 1фото видно как поработала машинка"
          },
          {
            "word": "любимый",
            "count": 2,
            "phrase": "почистила любимый детский комбинезон и осталось в восторге"
          },
          {
            "word": "детский",
            "count": 2,
            "phrase": "почистила любимый детский комбинезон и осталось в восторге"
          },
          {
            "word": "комбинезон",
            "count": 4,
            "phrase": "почистила любимый детский комбинезон и осталось в восторге"
          },
          {
            "word": "интересно",
            "count": 0,
            "phrase": "интересно только на сколько хватит ножей"
          },
          {
            "word": "ножей",
            "count": 1,
            "phrase": "интересно только на сколько хватит ножей"
          },
          {
            "word": "сработала",
            "count": 0,
            "phrase": "сработала на ура"
          },
          {
            "word": "очищены",
            "count": 1,
            "phrase": "что были в доме с катышками очищены"
          },
          {
            "word": "проводок",
            "count": 2,
            "phrase": "проводок удобный тайп си"
          },
          {
            "word": "тайп",
            "count": 7,
            "phrase": "проводок удобный тайп си"
          },
          {
            "word": "эмоции",
            "count": 1,
            "phrase": "одни эмоции"
          },
          {
            "word": "офигенно",
            "count": 1,
            "phrase": "спасибо большое за такую офигенно полезную вещь"
          },
          {
            "word": "полезную",
            "count": 1,
            "phrase": "спасибо большое за такую офигенно полезную вещь"
          },
          {
            "word": "хлипкая",
            "count": 1,
            "phrase": "не хлипкая"
          },
          {
            "word": "внушительный",
            "count": 1,
            "phrase": "внушительный размер контейнера"
          },
          {
            "word": "размер",
            "count": 12,
            "phrase": "внушительный размер контейнера"
          },
          {
            "word": "акума",
            "count": 0,
            "phrase": "акума хватает на долго"
          },
          {
            "word": "громкая",
            "count": 2,
            "phrase": "не громкая"
          },
          {
            "word": "удобна",
            "count": 23,
            "phrase": "удобна в руке лежит"
          },
          {
            "word": "получилось",
            "count": 4,
            "phrase": "получилось привести парочку вещей в надлежащий вид"
          },
          {
            "word": "парочку",
            "count": 1,
            "phrase": "получилось привести парочку вещей в надлежащий вид"
          },
          {
            "word": "надлежащий",
            "count": 1,
            "phrase": "получилось привести парочку вещей в надлежащий вид"
          },
          {
            "word": "появлением",
            "count": 1,
            "phrase": "с появлением детей не заменима"
          },
          {
            "word": "заменима",
            "count": 8,
            "phrase": "с появлением детей не заменима"
          },
          {
            "word": "проверил",
            "count": 15,
            "phrase": "в деле проверил"
          },
          {
            "word": "переживал",
            "count": 2,
            "phrase": "очень переживал в какой упаковке придут лезвия"
          },
          {
            "word": "какой",
            "count": 9,
            "phrase": "очень переживал в какой упаковке придут лезвия"
          },
          {
            "word": "придут",
            "count": 1,
            "phrase": "очень переживал в какой упаковке придут лезвия"
          },
          {
            "word": "оригинал",
            "count": 3,
            "phrase": "лезвия - оригинал"
          },
          {
            "word": "надёжном",
            "count": 1,
            "phrase": "а оказалось пришли в надёжном родном блистере"
          },
          {
            "word": "родном",
            "count": 1,
            "phrase": "а оказалось пришли в надёжном родном блистере"
          },
          {
            "word": "блистере",
            "count": 1,
            "phrase": "а оказалось пришли в надёжном родном блистере"
          },
          {
            "word": "влияет",
            "count": 2,
            "phrase": "на работу не влияет"
          },
          {
            "word": "ободрано",
            "count": 1,
            "phrase": "было все ободрано котами"
          },
          {
            "word": "котами",
            "count": 1,
            "phrase": "было все ободрано котами"
          },
          {
            "word": "детского",
            "count": 3,
            "phrase": "удалила катышки с детского комбинезона"
          },
          {
            "word": "комбинезона",
            "count": 1,
            "phrase": "удалила катышки с детского комбинезона"
          },
          {
            "word": "пар",
            "count": 55,
            "phrase": "3 пар носков"
          },
          {
            "word": "подушки",
            "count": 4,
            "phrase": "подушки и заряд не понадобился"
          },
          {
            "word": "понадобился",
            "count": 1,
            "phrase": "подушки и заряд не понадобился"
          },
          {
            "word": "наконец",
            "count": 3,
            "phrase": "наконец - то я приобрела такую машинку"
          },
          {
            "word": "штанов",
            "count": 5,
            "phrase": "прошел проверку пальто и штанов"
          },
          {
            "word": "обновились",
            "count": 1,
            "phrase": "мои декоративные подушки обновились"
          },
          {
            "word": "видели",
            "count": 1,
            "phrase": "вы их до не видели"
          },
          {
            "word": "набрала",
            "count": 1,
            "phrase": "полный отсек набрала машинка"
          },
          {
            "word": "механизм",
            "count": 3,
            "phrase": "что механизм безопасный"
          },
          {
            "word": "безопасный",
            "count": 1,
            "phrase": "что механизм безопасный"
          },
          {
            "word": "детям",
            "count": 1,
            "phrase": "детям полезное занятие по дому можно находить"
          },
          {
            "word": "полезное",
            "count": 1,
            "phrase": "детям полезное занятие по дому можно находить"
          },
          {
            "word": "дому",
            "count": 3,
            "phrase": "детям полезное занятие по дому можно находить"
          },
          {
            "word": "находить",
            "count": 1,
            "phrase": "детям полезное занятие по дому можно находить"
          },
          {
            "word": "шнура",
            "count": 2,
            "phrase": "от шнура"
          },
          {
            "word": "круговые",
            "count": 1,
            "phrase": "лучше делать ею круговые движения"
          },
          {
            "word": "движения",
            "count": 4,
            "phrase": "лучше делать ею круговые движения"
          },
          {
            "word": "описании",
            "count": 1,
            "phrase": "как в описании"
          },
          {
            "word": "претензия",
            "count": 0,
            "phrase": "огромная претензия к комплектовщикам и"
          },
          {
            "word": "комплектовщикам",
            "count": 1,
            "phrase": "огромная претензия к комплектовщикам и"
          },
          {
            "word": "доставщикам",
            "count": 1,
            "phrase": "или доставщикам"
          },
          {
            "word": "рваной",
            "count": 3,
            "phrase": "коробка пришла рваной"
          },
          {
            "word": "видите",
            "count": 4,
            "phrase": "неужели вы не видите то"
          },
          {
            "word": "складываете",
            "count": 1,
            "phrase": "что складываете"
          },
          {
            "word": "молчу",
            "count": 1,
            "phrase": "про доп упаковку от wb молчу"
          },
          {
            "word": "экономят",
            "count": 1,
            "phrase": "экономят на всём"
          },
          {
            "word": "всём",
            "count": 1,
            "phrase": "экономят на всём"
          },
          {
            "word": "полиэтилен",
            "count": 1,
            "phrase": "даже в полиэтилен не обернули"
          },
          {
            "word": "обернули",
            "count": 1,
            "phrase": "даже в полиэтилен не обернули"
          },
          {
            "word": "лидирующий",
            "count": 1,
            "phrase": "чуть не лидирующий маркетплэйс в стране"
          },
          {
            "word": "маркетплэйс",
            "count": 1,
            "phrase": "чуть не лидирующий маркетплэйс в стране"
          },
          {
            "word": "стране",
            "count": 1,
            "phrase": "чуть не лидирующий маркетплэйс в стране"
          },
          {
            "word": "покупателем",
            "count": 1,
            "phrase": "а отношение к покупателем хуже некуда"
          },
          {
            "word": "возврат",
            "count": 21,
            "phrase": "зато как списывать деньги за возврат некачественного"
          },
          {
            "word": "некачественного",
            "count": 1,
            "phrase": "зато как списывать деньги за возврат некачественного"
          },
          {
            "word": "первые",
            "count": 3,
            "phrase": "вы первые точно"
          },
          {
            "word": "хороша",
            "count": 29,
            "phrase": "машинка действительно хороша"
          },
          {
            "word": "приноровиться",
            "count": 1,
            "phrase": "пару минут приноровиться"
          },
          {
            "word": "наматрасник",
            "count": 1,
            "phrase": "чистила наматрасник 2метра на 160"
          },
          {
            "word": "2метра",
            "count": 1,
            "phrase": "чистила наматрасник 2метра на 160"
          },
          {
            "word": "160",
            "count": 1,
            "phrase": "чистила наматрасник 2метра на 160"
          },
          {
            "word": "прекрасное",
            "count": 1,
            "phrase": "прекрасное до и после"
          },
          {
            "word": "посильнее",
            "count": 1,
            "phrase": "где - то посильнее надавить"
          },
          {
            "word": "всрывалась",
            "count": 1,
            "phrase": "не всрывалась до меня"
          },
          {
            "word": "сомневаюсь",
            "count": 2,
            "phrase": "поэтому в качестве не сомневаюсь"
          },
          {
            "word": "намучалась",
            "count": 1,
            "phrase": "с ней намучалась"
          },
          {
            "word": "оставляла",
            "count": 2,
            "phrase": "оставляла дырочки"
          },
          {
            "word": "дырочки",
            "count": 1,
            "phrase": "оставляла дырочки"
          },
          {
            "word": "проблемы",
            "count": 2,
            "phrase": "теперь такой проблемы у меня нет"
          },
          {
            "word": "бывает",
            "count": 1,
            "phrase": "просто я даже не думала что такое бывает"
          },
          {
            "word": "рекомендовать",
            "count": 5,
            "phrase": "буду вас всем рекомендовать"
          },
          {
            "word": "проба",
            "count": 1,
            "phrase": "первая проба превзошла ожидания"
          },
          {
            "word": "превзошла",
            "count": 2,
            "phrase": "первая проба превзошла ожидания"
          },
          {
            "word": "больная",
            "count": 1,
            "phrase": "не ожидала что она по размерам такая больная"
          },
          {
            "word": "доставлен",
            "count": 3,
            "phrase": "доставлен в упаковке"
          },
          {
            "word": "дефектов",
            "count": 3,
            "phrase": "пришел полный комплект без дефектов"
          },
          {
            "word": "удивило",
            "count": 1,
            "phrase": "что удивило на пункте выдачи"
          },
          {
            "word": "данный",
            "count": 2,
            "phrase": "очень порадовал данный факт"
          },
          {
            "word": "факт",
            "count": 4,
            "phrase": "очень порадовал данный факт"
          },
          {
            "word": "парня",
            "count": 1,
            "phrase": "уже почистила и вещи парня и свои"
          },
          {
            "word": "сложностей",
            "count": 1,
            "phrase": "в чистке так же нет никаких сложностей"
          },
          {
            "word": "качеством",
            "count": 2,
            "phrase": "товаром очень довольна и качеством и ценой"
          },
          {
            "word": "ценой",
            "count": 1,
            "phrase": "товаром очень довольна и качеством и ценой"
          },
          {
            "word": "куплю",
            "count": 0,
            "phrase": "куплю в подарок родным однозначно"
          },
          {
            "word": "родным",
            "count": 1,
            "phrase": "куплю в подарок родным однозначно"
          },
          {
            "word": "плоскими",
            "count": 1,
            "phrase": "поэтому с крупными катышками и плоскими не справляется"
          },
          {
            "word": "приходится",
            "count": 3,
            "phrase": "приходится помогать руками"
          },
          {
            "word": "помогать",
            "count": 1,
            "phrase": "приходится помогать руками"
          },
          {
            "word": "руками",
            "count": 1,
            "phrase": "приходится помогать руками"
          },
          {
            "word": "кофтой",
            "count": 1,
            "phrase": "с кофтой справилась на 3"
          },
          {
            "word": "остались",
            "count": 2,
            "phrase": "остались плоские катышки"
          },
          {
            "word": "плоские",
            "count": 1,
            "phrase": "остались плоские катышки"
          },
          {
            "word": "крупных",
            "count": 1,
            "phrase": "тут проблема была в крупных катышках"
          },
          {
            "word": "дырочек",
            "count": 1,
            "phrase": "размер дырочек маленький"
          },
          {
            "word": "маленький",
            "count": 2,
            "phrase": "размер дырочек маленький"
          },
          {
            "word": "имбуля",
            "count": 1,
            "phrase": "вещь просто имбуля"
          },
          {
            "word": "бедных",
            "count": 1,
            "phrase": "купила как спасение для моих бедных спартивок и свитеров(рекомендую всем"
          },
          {
            "word": "спартивок",
            "count": 1,
            "phrase": "купила как спасение для моих бедных спартивок и свитеров(рекомендую всем"
          },
          {
            "word": "свитеров(рекомендую",
            "count": 1,
            "phrase": "купила как спасение для моих бедных спартивок и свитеров(рекомендую всем"
          },
          {
            "word": "отличную",
            "count": 2,
            "phrase": "отличный товар за отличную цену"
          },
          {
            "word": "середине",
            "count": 2,
            "phrase": "по середине на фото были катышки"
          },
          {
            "word": "собирать",
            "count": 1,
            "phrase": "не думала что будет собирать катышки с вещей"
          },
          {
            "word": "скептически",
            "count": 2,
            "phrase": "скептически относилась к данному товару"
          },
          {
            "word": "относилась",
            "count": 2,
            "phrase": "скептически относилась к данному товару"
          },
          {
            "word": "данному",
            "count": 2,
            "phrase": "скептически относилась к данному товару"
          },
          {
            "word": "товару",
            "count": 3,
            "phrase": "скептически относилась к данному товару"
          },
          {
            "word": "смог",
            "count": 7,
            "phrase": "он смог меня удивить"
          },
          {
            "word": "удивить",
            "count": 1,
            "phrase": "он смог меня удивить"
          },
          {
            "word": "вилки",
            "count": 2,
            "phrase": "вилки нет"
          },
          {
            "word": "щетка",
            "count": 1,
            "phrase": "щетка для удаления пыли с труднодоступных мест"
          },
          {
            "word": "пыли",
            "count": 5,
            "phrase": "щетка для удаления пыли с труднодоступных мест"
          },
          {
            "word": "труднодоступных",
            "count": 1,
            "phrase": "щетка для удаления пыли с труднодоступных мест"
          },
          {
            "word": "мест",
            "count": 29,
            "phrase": "щетка для удаления пыли с труднодоступных мест"
          },
          {
            "word": "хватил",
            "count": 8,
            "phrase": "думала не хватил мощности или еще что - нибудь"
          },
          {
            "word": "малышом",
            "count": 1,
            "phrase": "очень довольна этим малышом"
          },
          {
            "word": "шумный",
            "count": 2,
            "phrase": "будет не такой шумный"
          },
          {
            "word": "мелочи",
            "count": 1,
            "phrase": "но это мелочи"
          },
          {
            "word": "леггинсах",
            "count": 1,
            "phrase": "проверила на леггинсах"
          },
          {
            "word": "покрываются",
            "count": 2,
            "phrase": "которые покрываются катышками со временем"
          },
          {
            "word": "временем",
            "count": 1,
            "phrase": "которые покрываются катышками со временем"
          },
          {
            "word": "постоянно",
            "count": 5,
            "phrase": "что поверх постоянно трётся кофта"
          },
          {
            "word": "трётся",
            "count": 1,
            "phrase": "что поверх постоянно трётся кофта"
          },
          {
            "word": "пара",
            "count": 33,
            "phrase": "пара минут"
          },
          {
            "word": "леггинсы",
            "count": 1,
            "phrase": "и леггинсы снова как новые"
          },
          {
            "word": "сторона",
            "count": 1,
            "phrase": "на фото левая сторона обработана"
          },
          {
            "word": "упакованные",
            "count": 1,
            "phrase": "пришло всё хорошо упакованные"
          },
          {
            "word": "вскрыто",
            "count": 1,
            "phrase": "не вскрыто"
          },
          {
            "word": "поцарапанное",
            "count": 1,
            "phrase": "не поцарапанное"
          },
          {
            "word": "основном",
            "count": 2,
            "phrase": "в основном из - за того"
          },
          {
            "word": "даром",
            "count": 1,
            "phrase": "за такую цену - практически даром"
          },
          {
            "word": "марки",
            "count": 1,
            "phrase": "до этого у меня была другой марки"
          },
          {
            "word": "садились",
            "count": 2,
            "phrase": "которые садились постоянно"
          },
          {
            "word": "прожила",
            "count": 0,
            "phrase": "прожила мало"
          },
          {
            "word": "дольше",
            "count": 5,
            "phrase": "дольше с ней работать"
          },
          {
            "word": "400",
            "count": 2,
            "phrase": "цена на 400 рублей дороже обошлась"
          },
          {
            "word": "дороже",
            "count": 3,
            "phrase": "цена на 400 рублей дороже обошлась"
          },
          {
            "word": "полугода",
            "count": 1,
            "phrase": "пользуюсь уже больше полугода"
          },
          {
            "word": "вещь!!хочу",
            "count": 0,
            "phrase": "незаменимая вещь!!хочу выразить особую благодарность производителям"
          },
          {
            "word": "выразить",
            "count": 1,
            "phrase": "незаменимая вещь!!хочу выразить особую благодарность производителям"
          },
          {
            "word": "особую",
            "count": 1,
            "phrase": "незаменимая вещь!!хочу выразить особую благодарность производителям"
          },
          {
            "word": "производителям",
            "count": 1,
            "phrase": "незаменимая вещь!!хочу выразить особую благодарность производителям"
          },
          {
            "word": "преобрести",
            "count": 1,
            "phrase": "за возможность без проблем преобрести новое лезвие"
          },
          {
            "word": "хороших",
            "count": 1,
            "phrase": "заказала машинку читала отзывы много хороших"
          },
          {
            "word": "инструмент",
            "count": 1,
            "phrase": "хороший инструмент для удаления катышков"
          },
          {
            "word": "рубашек",
            "count": 1,
            "phrase": "брала для рубашек"
          },
          {
            "word": "одеялу",
            "count": 1,
            "phrase": "так же дала 2 жизнь одеялу"
          },
          {
            "word": "спортивные",
            "count": 1,
            "phrase": "штаны спортивные почистила"
          },
          {
            "word": "пришоа",
            "count": 0,
            "phrase": "пришоа машинка заряженная"
          },
          {
            "word": "ужасные",
            "count": 1,
            "phrase": "катышки были ужасные"
          },
          {
            "word": "неописуемый",
            "count": 1,
            "phrase": "машинка справилась на ура и восторг был неописуемый"
          },
          {
            "word": "знали",
            "count": 1,
            "phrase": "возможно мы не знали"
          },
          {
            "word": "мужу",
            "count": 3,
            "phrase": "мужу так понравилось"
          },
          {
            "word": "друг",
            "count": 40,
            "phrase": "что их друг от друга не оторвать"
          },
          {
            "word": "друга",
            "count": 6,
            "phrase": "что их друг от друга не оторвать"
          },
          {
            "word": "оторвать",
            "count": 2,
            "phrase": "что их друг от друга не оторвать"
          },
          {
            "word": "таким",
            "count": 2,
            "phrase": "он таким способом релаксирует"
          },
          {
            "word": "способом",
            "count": 1,
            "phrase": "он таким способом релаксирует"
          },
          {
            "word": "релаксирует",
            "count": 1,
            "phrase": "он таким способом релаксирует"
          },
          {
            "word": "работодатели",
            "count": 1,
            "phrase": "так как работодатели выбирают на работников что - то подешевле"
          },
          {
            "word": "выбирают",
            "count": 1,
            "phrase": "так как работодатели выбирают на работников что - то подешевле"
          },
          {
            "word": "работников",
            "count": 1,
            "phrase": "так как работодатели выбирают на работников что - то подешевле"
          },
          {
            "word": "подешевле",
            "count": 3,
            "phrase": "так как работодатели выбирают на работников что - то подешевле"
          },
          {
            "word": "500",
            "count": 5,
            "phrase": "за 500 рублей вещь неплохая"
          },
          {
            "word": "неплохая",
            "count": 1,
            "phrase": "за 500 рублей вещь неплохая"
          },
          {
            "word": "вещах",
            "count": 4,
            "phrase": "не на всех вещах катышки убираются идеально"
          },
          {
            "word": "убираются",
            "count": 2,
            "phrase": "не на всех вещах катышки убираются идеально"
          },
          {
            "word": "великолепная",
            "count": 1,
            "phrase": "великолепная вещь"
          },
          {
            "word": "компактный",
            "count": 1,
            "phrase": "компактный аппарат"
          },
          {
            "word": "аккуратным",
            "count": 3,
            "phrase": "но нужно быть аккуратным при использовании"
          },
          {
            "word": "зацепка",
            "count": 1,
            "phrase": "если есть на одежде зацепка или чистите на весу"
          },
          {
            "word": "чистите",
            "count": 1,
            "phrase": "если есть на одежде зацепка или чистите на весу"
          },
          {
            "word": "весу",
            "count": 2,
            "phrase": "если есть на одежде зацепка или чистите на весу"
          },
          {
            "word": "натянуто",
            "count": 1,
            "phrase": "когда не натянуто полотно"
          },
          {
            "word": "полотно",
            "count": 1,
            "phrase": "когда не натянуто полотно"
          },
          {
            "word": "порезать",
            "count": 1,
            "phrase": "то может порезать ткань"
          },
          {
            "word": "зарядили",
            "count": 2,
            "phrase": "уже 1 раз зарядили"
          },
          {
            "word": "хватают",
            "count": 1,
            "phrase": "зарядки хватают достаточно"
          },
          {
            "word": "устанете",
            "count": 1,
            "phrase": "точно вы устанете раньше"
          },
          {
            "word": "контраст",
            "count": 1,
            "phrase": "контраст на фото можно заметить"
          },
          {
            "word": "заметить",
            "count": 1,
            "phrase": "контраст на фото можно заметить"
          },
          {
            "word": "обычный",
            "count": 1,
            "phrase": "раньше был обычный аппарат на батарейке"
          },
          {
            "word": "батарейке",
            "count": 1,
            "phrase": "раньше был обычный аппарат на батарейке"
          },
          {
            "word": "удалял",
            "count": 2,
            "phrase": "не удалял так хорошо и даже самые мелкие"
          },
          {
            "word": "запакована",
            "count": 2,
            "phrase": "но внутри была машинка запакована"
          },
          {
            "word": "шерстяных",
            "count": 6,
            "phrase": "на шерстяных носках твои очень большие катышки"
          },
          {
            "word": "твои",
            "count": 1,
            "phrase": "на шерстяных носках твои очень большие катышки"
          },
          {
            "word": "тупила",
            "count": 4,
            "phrase": "немного тупила машинка"
          },
          {
            "word": "натянута",
            "count": 1,
            "phrase": "если на ткани нитки или не натянута"
          },
          {
            "word": "внимательны",
            "count": 1,
            "phrase": "будьте внимательны"
          },
          {
            "word": "натягивайте",
            "count": 0,
            "phrase": "натягивайте ткань"
          },
          {
            "word": "рекламе",
            "count": 1,
            "phrase": "как в старой рекламе"
          },
          {
            "word": "ногу",
            "count": 2,
            "phrase": "эту ногу я побрила бритвой"
          },
          {
            "word": "побрила",
            "count": 3,
            "phrase": "эту ногу я побрила бритвой"
          },
          {
            "word": "бритвой",
            "count": 1,
            "phrase": "эту ногу я побрила бритвой"
          },
          {
            "word": "кайфово",
            "count": 1,
            "phrase": "и машинка очистила настолько кайфово"
          },
          {
            "word": "очуметь",
            "count": 1,
            "phrase": "что просто очуметь"
          },
          {
            "word": "верится",
            "count": 1,
            "phrase": "мало верится"
          },
          {
            "word": "xaomi",
            "count": 0,
            "phrase": "что xaomi"
          },
          {
            "word": "бредовая",
            "count": 1,
            "phrase": "но есть бредовая отметка"
          },
          {
            "word": "отметка",
            "count": 1,
            "phrase": "но есть бредовая отметка"
          },
          {
            "word": "хорош🤌",
            "count": 1,
            "phrase": "да и прибор хорош🤌 выглядит стильно"
          },
          {
            "word": "первым",
            "count": 0,
            "phrase": "первым делом проверила на колготках"
          },
          {
            "word": "делом",
            "count": 2,
            "phrase": "первым делом проверила на колготках"
          },
          {
            "word": "колготках",
            "count": 2,
            "phrase": "первым делом проверила на колготках"
          },
          {
            "word": "начесом",
            "count": 1,
            "phrase": "катышки от штанов с начесом"
          },
          {
            "word": "шум",
            "count": 19,
            "phrase": "шум приятный"
          },
          {
            "word": "перчатках",
            "count": 1,
            "phrase": "проверила на перчатках шерстяных"
          },
          {
            "word": "вытянутые",
            "count": 1,
            "phrase": "вытянутые нитки подхватывает отлично"
          },
          {
            "word": "подхватывает",
            "count": 1,
            "phrase": "вытянутые нитки подхватывает отлично"
          },
          {
            "word": "700р",
            "count": 1,
            "phrase": "чудо штука за какие - то 700р"
          },
          {
            "word": "удивительно",
            "count": 1,
            "phrase": "но это не удивительно"
          },
          {
            "word": "сломаться",
            "count": 2,
            "phrase": "надеюсь не сломаться быстро"
          },
          {
            "word": "секунды",
            "count": 1,
            "phrase": "результат за секунды"
          },
          {
            "word": "выбрасывали",
            "count": 1,
            "phrase": "раньше выбрасывали такие вещи"
          },
          {
            "word": "приблизить",
            "count": 1,
            "phrase": "если приблизить фото"
          },
          {
            "word": "почищена",
            "count": 2,
            "phrase": "на фото одна штанина уже почищена машинкой"
          },
          {
            "word": "колоссальная",
            "count": 1,
            "phrase": "разница колоссальная"
          },
          {
            "word": "продлить",
            "count": 1,
            "phrase": "помогает продлить жизнь вещам"
          },
          {
            "word": "жужжит",
            "count": 6,
            "phrase": "немного жужжит"
          },
          {
            "word": "напрягает",
            "count": 1,
            "phrase": "но это не напрягает"
          },
          {
            "word": "числе",
            "count": 1,
            "phrase": "почистила несколько вещей в том числе длинное пальто"
          },
          {
            "word": "длинное",
            "count": 1,
            "phrase": "почистила несколько вещей в том числе длинное пальто"
          },
          {
            "word": "помощник",
            "count": 2,
            "phrase": "отличный помощник в уходе за вещами"
          },
          {
            "word": "приложить",
            "count": 1,
            "phrase": "хотела приложить видеоотзыв"
          },
          {
            "word": "видеоотзыв",
            "count": 1,
            "phrase": "хотела приложить видеоотзыв"
          },
          {
            "word": "допускаются",
            "count": 1,
            "phrase": "но допускаются только фотографии"
          },
          {
            "word": "фотографии",
            "count": 3,
            "phrase": "но допускаются только фотографии"
          },
          {
            "word": "довольная",
            "count": 3,
            "phrase": "довольная покупкой на все"
          },
          {
            "word": "момента",
            "count": 4,
            "phrase": "с момента оплаты и дня доставки прошло всего три дня"
          },
          {
            "word": "оплаты",
            "count": 2,
            "phrase": "с момента оплаты и дня доставки прошло всего три дня"
          },
          {
            "word": "доставки",
            "count": 2,
            "phrase": "с момента оплаты и дня доставки прошло всего три дня"
          },
          {
            "word": "сохранности",
            "count": 1,
            "phrase": "в целости и сохранности"
          },
          {
            "word": "стать",
            "count": 3,
            "phrase": "у же попробовала стать вещи"
          },
          {
            "word": "раз-",
            "count": 3,
            "phrase": "режет катышки на раз- два"
          },
          {
            "word": "оставляя",
            "count": 1,
            "phrase": "оставляя ткань чистой и"
          },
          {
            "word": "чистой",
            "count": 1,
            "phrase": "оставляя ткань чистой и"
          },
          {
            "word": "захватывает",
            "count": 2,
            "phrase": "захватывает большой объем"
          },
          {
            "word": "объем",
            "count": 3,
            "phrase": "захватывает большой объем"
          },
          {
            "word": "бережное",
            "count": 2,
            "phrase": "бережное очищение"
          },
          {
            "word": "потереть",
            "count": 1,
            "phrase": "надо прям потереть место"
          },
          {
            "word": "место",
            "count": 3,
            "phrase": "надо прям потереть место"
          },
          {
            "word": "треть",
            "count": 3,
            "phrase": "с 1 раза треть убирает"
          },
          {
            "word": "мини",
            "count": 5,
            "phrase": "в коробочке проводок тайпси и мини щеточка чтобы чистить аппарат"
          },
          {
            "word": "закрались",
            "count": 1,
            "phrase": "у меня тоже закрались сомнения"
          },
          {
            "word": "сомнения",
            "count": 2,
            "phrase": "у меня тоже закрались сомнения"
          },
          {
            "word": "грязная",
            "count": 3,
            "phrase": "коробка немного грязная в остальном упаковано хорошо"
          },
          {
            "word": "условна",
            "count": 1,
            "phrase": "всё условна"
          },
          {
            "word": "достаточное",
            "count": 1,
            "phrase": "надеюсь машинка прослужит достаточное количество времени"
          },
          {
            "word": "ожидал",
            "count": 18,
            "phrase": "даже не ожидал что так будет"
          },
          {
            "word": "вложение",
            "count": 1,
            "phrase": "лучшее вложение денег"
          },
          {
            "word": "удивляет",
            "count": 1,
            "phrase": "результат удивляет"
          },
          {
            "word": "любимом",
            "count": 3,
            "phrase": "опробовала на любимом свитере"
          },
          {
            "word": "превзошел",
            "count": 1,
            "phrase": "результат превзошел ожидания"
          },
          {
            "word": "собираются",
            "count": 1,
            "phrase": "катышки собираются в контейнере"
          },
          {
            "word": "представляю",
            "count": 2,
            "phrase": "не представляю"
          },
          {
            "word": "удешевляют",
            "count": 1,
            "phrase": "сейчас производство удешевляют"
          },
          {
            "word": "неподходящий",
            "count": 1,
            "phrase": "в неподходящий момент не надо их бежать покупать"
          },
          {
            "word": "момент",
            "count": 8,
            "phrase": "в неподходящий момент не надо их бежать покупать"
          },
          {
            "word": "бежать",
            "count": 1,
            "phrase": "в неподходящий момент не надо их бежать покупать"
          },
          {
            "word": "зарядить",
            "count": 1,
            "phrase": "а можно просто зарядить"
          },
          {
            "word": "открыть",
            "count": 4,
            "phrase": "потому что чтобы открыть нужна отвертка звездочка"
          },
          {
            "word": "отвертка",
            "count": 1,
            "phrase": "потому что чтобы открыть нужна отвертка звездочка"
          },
          {
            "word": "звездочка",
            "count": 1,
            "phrase": "потому что чтобы открыть нужна отвертка звездочка"
          },
          {
            "word": "крохотная",
            "count": 1,
            "phrase": "крохотная и все окислилось"
          },
          {
            "word": "окислилось",
            "count": 1,
            "phrase": "крохотная и все окислилось"
          },
          {
            "word": "купили",
            "count": 0,
            "phrase": "купили новую такую же и оно того стоит"
          },
          {
            "word": "разобралась",
            "count": 1,
            "phrase": "сначала не разобралась с принципом работы"
          },
          {
            "word": "принципом",
            "count": 1,
            "phrase": "сначала не разобралась с принципом работы"
          },
          {
            "word": "происходило",
            "count": 1,
            "phrase": "чтобы происходило наиболее эффективное очищение"
          },
          {
            "word": "наиболее",
            "count": 1,
            "phrase": "чтобы происходило наиболее эффективное очищение"
          },
          {
            "word": "эффективное",
            "count": 1,
            "phrase": "чтобы происходило наиболее эффективное очищение"
          },
          {
            "word": "разными",
            "count": 2,
            "phrase": "а держать под разными углами"
          },
          {
            "word": "углами",
            "count": 2,
            "phrase": "а держать под разными углами"
          },
          {
            "word": "разным",
            "count": 3,
            "phrase": "водить по разным траекториям"
          },
          {
            "word": "траекториям",
            "count": 1,
            "phrase": "водить по разным траекториям"
          },
          {
            "word": "запущена",
            "count": 1,
            "phrase": "если вещь уже сильно запущена"
          },
          {
            "word": "соответствуют",
            "count": 1,
            "phrase": "цена - качество полностью соответствуют"
          },
          {
            "word": "файл",
            "count": 1,
            "phrase": "где лежит файл с инструкцией на русском"
          },
          {
            "word": "инструкцией",
            "count": 2,
            "phrase": "где лежит файл с инструкцией на русском"
          },
          {
            "word": "максимально",
            "count": 3,
            "phrase": "всё максимально понятно и без инструкций"
          },
          {
            "word": "инструкций",
            "count": 1,
            "phrase": "всё максимально понятно и без инструкций"
          },
          {
            "word": "одно",
            "count": 49,
            "phrase": "одно гнездо для зарядки"
          },
          {
            "word": "гнездо",
            "count": 1,
            "phrase": "одно гнездо для зарядки"
          },
          {
            "word": "отделение",
            "count": 1,
            "phrase": "и отделение с лезвиями также"
          },
          {
            "word": "лезвиями",
            "count": 2,
            "phrase": "и отделение с лезвиями также"
          },
          {
            "word": "жили",
            "count": 5,
            "phrase": "классная вещь и как мы жили раньше"
          },
          {
            "word": "некоторые",
            "count": 0,
            "phrase": "некоторые вещи вообще как новые стали"
          },
          {
            "word": "предельно",
            "count": 1,
            "phrase": "и без инструкции все предельно понятно"
          },
          {
            "word": "видеть",
            "count": 1,
            "phrase": "но какой же кайф видеть за секунду как убираются катышки"
          },
          {
            "word": "секунду",
            "count": 3,
            "phrase": "но какой же кайф видеть за секунду как убираются катышки"
          },
          {
            "word": "самом",
            "count": 4,
            "phrase": "то есть можно проверить работу уже в самом пункте доставки"
          },
          {
            "word": "взялась",
            "count": 1,
            "phrase": "взялась пробовать её в деле"
          },
          {
            "word": "дошло",
            "count": 1,
            "phrase": "за 3 дня дошло до красноярска"
          },
          {
            "word": "красноярска",
            "count": 0,
            "phrase": "за 3 дня дошло до красноярска"
          },
          {
            "word": "боишься",
            "count": 1,
            "phrase": "удобно пользоваться и не боишься что то испортить"
          },
          {
            "word": "испортить",
            "count": 1,
            "phrase": "удобно пользоваться и не боишься что то испортить"
          },
          {
            "word": "аришло",
            "count": 1,
            "phrase": "аришло все целое"
          },
          {
            "word": "увидев",
            "count": 0,
            "phrase": "увидев комментарии"
          },
          {
            "word": "комментарии",
            "count": 1,
            "phrase": "увидев комментарии"
          },
          {
            "word": "сомнением",
            "count": 1,
            "phrase": "была под сомнением"
          },
          {
            "word": "приятном",
            "count": 1,
            "phrase": "я в приятном шоке от нее"
          },
          {
            "word": "шоке",
            "count": 5,
            "phrase": "я в приятном шоке от нее"
          },
          {
            "word": "виден",
            "count": 6,
            "phrase": "результат виден на 2 фотографии"
          },
          {
            "word": "загрязнений",
            "count": 1,
            "phrase": "в комплекте была щеточка для чистки машинки от загрязнений"
          },
          {
            "word": "мощный",
            "count": 1,
            "phrase": "достаточно мощный аппарат"
          },
          {
            "word": "абсолютно",
            "count": 2,
            "phrase": "все пришло абсолютно целым"
          },
          {
            "word": "людей",
            "count": 2,
            "phrase": "где 50 % людей недовольны покупкой и 50 % довольны"
          },
          {
            "word": "недовольны",
            "count": 1,
            "phrase": "где 50 % людей недовольны покупкой и 50 % довольны"
          },
          {
            "word": "жилетка",
            "count": 1,
            "phrase": "школьная жилетка обрела вторую жизнь"
          },
          {
            "word": "спаситель",
            "count": 1,
            "phrase": "мой спаситель"
          },
          {
            "word": "добрый",
            "count": 1,
            "phrase": "всем добрый день"
          },
          {
            "word": "таком",
            "count": 11,
            "phrase": "раньше о таком приборчике и не задумывалась"
          },
          {
            "word": "приборчике",
            "count": 1,
            "phrase": "раньше о таком приборчике и не задумывалась"
          },
          {
            "word": "задумывалась",
            "count": 1,
            "phrase": "раньше о таком приборчике и не задумывалась"
          },
          {
            "word": "свитшот",
            "count": 2,
            "phrase": "пока не купила свитшот за 3 тр"
          },
          {
            "word": "ужасно",
            "count": 5,
            "phrase": "вещи стали делать ужасно"
          },
          {
            "word": "зарядом",
            "count": 1,
            "phrase": "пришла с полным зарядом"
          },
          {
            "word": "предназначением",
            "count": 1,
            "phrase": "со своим предназначением справляется на отлично"
          },
          {
            "word": "прикреплю",
            "count": 1,
            "phrase": "прикреплю фото тоже"
          },
          {
            "word": "обнаружил",
            "count": 1,
            "phrase": "единственный минус обнаружил при вскрытии"
          },
          {
            "word": "вскрытии",
            "count": 1,
            "phrase": "единственный минус обнаружил при вскрытии"
          },
          {
            "word": "кусок",
            "count": 1,
            "phrase": "может кусок пластика"
          },
          {
            "word": "пластика",
            "count": 2,
            "phrase": "может кусок пластика"
          },
          {
            "word": "исправимо",
            "count": 1,
            "phrase": "но это исправимо"
          },
          {
            "word": "разберем",
            "count": 1,
            "phrase": "разберем посмотрим извлечем"
          },
          {
            "word": "извлечем",
            "count": 1,
            "phrase": "разберем посмотрим извлечем"
          },
          {
            "word": "годный",
            "count": 1,
            "phrase": "а так девайс очень даже годный"
          },
          {
            "word": "зимняя",
            "count": 1,
            "phrase": "шапка зимняя как новая стала"
          },
          {
            "word": "класть",
            "count": 1,
            "phrase": "еще один совет для продавца в комплект класть запасной нож"
          },
          {
            "word": "запасной",
            "count": 3,
            "phrase": "еще один совет для продавца в комплект класть запасной нож"
          },
          {
            "word": "нож",
            "count": 15,
            "phrase": "еще один совет для продавца в комплект класть запасной нож"
          },
          {
            "word": "продукт",
            "count": 1,
            "phrase": "спасибо за хороший продукт"
          },
          {
            "word": "бегаю",
            "count": 0,
            "phrase": "бегаю с этой машинкой теперь по дому в поисках катышек"
          },
          {
            "word": "поисках",
            "count": 1,
            "phrase": "бегаю с этой машинкой теперь по дому в поисках катышек"
          },
          {
            "word": "пледу",
            "count": 1,
            "phrase": "что пледу уже ничего не поможет"
          },
          {
            "word": "выброс",
            "count": 12,
            "phrase": "думала джемпер на выброс"
          },
          {
            "word": "поблагодарить",
            "count": 1,
            "phrase": "хотела поблагодарить за такую чудесную машинку"
          },
          {
            "word": "чудесную",
            "count": 3,
            "phrase": "хотела поблагодарить за такую чудесную машинку"
          },
          {
            "word": "помощью",
            "count": 1,
            "phrase": "с ее помощью обновила кардиган"
          },
          {
            "word": "фрагмент",
            "count": 1,
            "phrase": "на фото фрагмент кардигана до и после обработки машинкой"
          },
          {
            "word": "кардигана",
            "count": 4,
            "phrase": "на фото фрагмент кардигана до и после обработки машинкой"
          },
          {
            "word": "чувство",
            "count": 1,
            "phrase": "привела в чувство все носки"
          },
          {
            "word": "думал",
            "count": 45,
            "phrase": "муж думал"
          },
          {
            "word": "пиджаки",
            "count": 1,
            "phrase": "пиджаки тоже хорошо обработались"
          },
          {
            "word": "обработались",
            "count": 1,
            "phrase": "пиджаки тоже хорошо обработались"
          },
          {
            "word": "дождалась",
            "count": 0,
            "phrase": "дождалась скидки хорошей и сразу купила"
          },
          {
            "word": "скидки",
            "count": 2,
            "phrase": "дождалась скидки хорошей и сразу купила"
          },
          {
            "word": "сборный",
            "count": 1,
            "phrase": "большой сборный контейнер"
          },
          {
            "word": "включается",
            "count": 5,
            "phrase": "быстро включается"
          },
          {
            "word": "сдела",
            "count": 25,
            "phrase": "сдела как новенькую"
          },
          {
            "word": "новенькую",
            "count": 1,
            "phrase": "сдела как новенькую"
          },
          {
            "word": "носочков",
            "count": 2,
            "phrase": "дети сразу носочков своих принесли почистить"
          },
          {
            "word": "принесли",
            "count": 1,
            "phrase": "дети сразу носочков своих принесли почистить"
          },
          {
            "word": "искать",
            "count": 2,
            "phrase": "муж тоже стал искать вещи с катышками"
          },
          {
            "word": "упоковано",
            "count": 1,
            "phrase": "пришло все упоковано"
          },
          {
            "word": "помято",
            "count": 4,
            "phrase": "не помято"
          },
          {
            "word": "сломано",
            "count": 1,
            "phrase": "не сломано и быстро!рекомендую"
          },
          {
            "word": "быстро!рекомендую",
            "count": 0,
            "phrase": "не сломано и быстро!рекомендую"
          },
          {
            "word": "штуку",
            "count": 3,
            "phrase": "так давно хотела эту штуку"
          },
          {
            "word": "суппппер",
            "count": 1,
            "phrase": "вещь суппппер"
          },
          {
            "word": "обработала",
            "count": 2,
            "phrase": "столько вещей уже обработала"
          },
          {
            "word": "проводить",
            "count": 1,
            "phrase": "чуть дольше нужно проводить и медленнее"
          },
          {
            "word": "медленнее",
            "count": 1,
            "phrase": "чуть дольше нужно проводить и медленнее"
          },
          {
            "word": "зафиксировать",
            "count": 1,
            "phrase": "чтоб машинка смогла зафиксировать катышки"
          },
          {
            "word": "прикрепила",
            "count": 1,
            "phrase": "прикрепила фото до и после"
          },
          {
            "word": "прощайте",
            "count": 0,
            "phrase": "прощайте батарейки"
          },
          {
            "word": "ударяет",
            "count": 1,
            "phrase": "мелкие катушки ударяет супер"
          },
          {
            "word": "интенсивной",
            "count": 1,
            "phrase": "большие при интенсивной обработке тоже"
          },
          {
            "word": "обработке",
            "count": 1,
            "phrase": "большие при интенсивной обработке тоже"
          },
          {
            "word": "дешевых",
            "count": 1,
            "phrase": "после двух дешевых сломавшихся купила нормальный наконец - то"
          },
          {
            "word": "сломавшихся",
            "count": 1,
            "phrase": "после двух дешевых сломавшихся купила нормальный наконец - то"
          },
          {
            "word": "преобретала",
            "count": 1,
            "phrase": "которую я преобретала в последнее время"
          },
          {
            "word": "дочка",
            "count": 2,
            "phrase": "ни дочка не можем от нее оторваться"
          },
          {
            "word": "можем",
            "count": 1,
            "phrase": "ни дочка не можем от нее оторваться"
          },
          {
            "word": "оторваться",
            "count": 1,
            "phrase": "ни дочка не можем от нее оторваться"
          },
          {
            "word": "вязала",
            "count": 1,
            "phrase": "который вязала мне моя мама"
          },
          {
            "word": "мама",
            "count": 2,
            "phrase": "который вязала мне моя мама"
          },
          {
            "word": "спасен",
            "count": 12,
            "phrase": "был спасен"
          },
          {
            "word": "создателям",
            "count": 1,
            "phrase": "спасибо создателям и продавцу за качественный товар"
          },
          {
            "word": "домашнее",
            "count": 1,
            "phrase": "обработала домашнее трико на правой ноге"
          },
          {
            "word": "трико",
            "count": 4,
            "phrase": "обработала домашнее трико на правой ноге"
          },
          {
            "word": "ноге",
            "count": 1,
            "phrase": "обработала домашнее трико на правой ноге"
          },
          {
            "word": "обрабатывать",
            "count": 2,
            "phrase": "но обрабатывать надо медленно"
          },
          {
            "word": "данную",
            "count": 2,
            "phrase": "купила данную машинку мужу в подарок"
          },
          {
            "word": "рваная",
            "count": 3,
            "phrase": "коробка пришла немного рваная"
          },
          {
            "word": "криминально",
            "count": 1,
            "phrase": "но не криминально"
          },
          {
            "word": "побрили",
            "count": 1,
            "phrase": "уже побрили покрывало 220"
          },
          {
            "word": "220",
            "count": 1,
            "phrase": "уже побрили покрывало 220"
          },
          {
            "word": "подзаряжали",
            "count": 1,
            "phrase": "даже не подзаряжали"
          },
          {
            "word": "хотите",
            "count": 2,
            "phrase": "если хотите отвлечь ребенка от смартфона - покупайте"
          },
          {
            "word": "отвлечь",
            "count": 1,
            "phrase": "если хотите отвлечь ребенка от смартфона - покупайте"
          },
          {
            "word": "смартфона",
            "count": 1,
            "phrase": "если хотите отвлечь ребенка от смартфона - покупайте"
          },
          {
            "word": "очередь",
            "count": 2,
            "phrase": "у нас очередь на стрижку"
          },
          {
            "word": "стрижку",
            "count": 1,
            "phrase": "у нас очередь на стрижку"
          },
          {
            "word": "машины",
            "count": 1,
            "phrase": "дизайн машины отличный"
          },
          {
            "word": "жуткие",
            "count": 1,
            "phrase": "на фото ну самые жуткие носки"
          },
          {
            "word": "отделяемый",
            "count": 1,
            "phrase": "имеется отделяемый контейнер который можно легко очистить"
          },
          {
            "word": "скупой",
            "count": 1,
            "phrase": "но раз скупой платит дважды"
          },
          {
            "word": "платит",
            "count": 3,
            "phrase": "но раз скупой платит дважды"
          },
          {
            "word": "дважды",
            "count": 1,
            "phrase": "но раз скупой платит дважды"
          },
          {
            "word": "взять",
            "count": 2,
            "phrase": "то решила взять эту и ни разу не пожалела"
          },
          {
            "word": "торчащие",
            "count": 2,
            "phrase": "таки и торчащие ворсинки"
          },
          {
            "word": "ворсинки",
            "count": 1,
            "phrase": "таки и торчащие ворсинки"
          },
          {
            "word": "обрели",
            "count": 3,
            "phrase": "обрели и правда 2 жизнь"
          },
          {
            "word": "каждого",
            "count": 3,
            "phrase": "эта штука должна быть у каждого"
          },
          {
            "word": "справился",
            "count": 5,
            "phrase": "аппарат со своей задачей справился отлично"
          },
          {
            "word": "потратить",
            "count": 2,
            "phrase": "пару минут нужно потратить"
          },
          {
            "word": "сяоми",
            "count": 2,
            "phrase": "от сяоми"
          },
          {
            "word": "тратится",
            "count": 1,
            "phrase": "и не надо тратится на батарейки"
          },
          {
            "word": "забирала",
            "count": 1,
            "phrase": "товар забирала дочь"
          },
          {
            "word": "открытая",
            "count": 2,
            "phrase": "открытая и помятая коробка меня сначала смутила"
          },
          {
            "word": "смутила",
            "count": 2,
            "phrase": "открытая и помятая коробка меня сначала смутила"
          },
          {
            "word": "пакетик",
            "count": 3,
            "phrase": "сама машинка упакована в пакетик"
          },
          {
            "word": "мгновенно",
            "count": 2,
            "phrase": "катышки мгновенно убирает"
          },
          {
            "word": "доступная",
            "count": 1,
            "phrase": "очень хорошая вещь и доступная цена"
          },
          {
            "word": "выкину",
            "count": 6,
            "phrase": "думала уже выкину костюм"
          },
          {
            "word": "этому",
            "count": 17,
            "phrase": "спасибо этому прибору"
          },
          {
            "word": "прибору",
            "count": 2,
            "phrase": "спасибо этому прибору"
          },
          {
            "word": "простыни",
            "count": 1,
            "phrase": "убрала все катышки с простыни"
          },
          {
            "word": "фотки",
            "count": 3,
            "phrase": "фотки до и после"
          },
          {
            "word": "отбрить",
            "count": 1,
            "phrase": "отбрить простынь и две подушки зарядки хватило с запасом"
          },
          {
            "word": "запасом",
            "count": 1,
            "phrase": "отбрить простынь и две подушки зарядки хватило с запасом"
          },
          {
            "word": "проверки",
            "count": 1,
            "phrase": "что удобно для проверки"
          },
          {
            "word": "обрабатываю",
            "count": 0,
            "phrase": "обрабатываю толстовку ребёнку"
          },
          {
            "word": "ребёнку",
            "count": 1,
            "phrase": "обрабатываю толстовку ребёнку"
          },
          {
            "word": "шапочку",
            "count": 1,
            "phrase": "отлично почистила шапочку"
          },
          {
            "word": "обработанная",
            "count": 1,
            "phrase": "на фото одна шапка обработанная машинкой"
          },
          {
            "word": "вторая",
            "count": 3,
            "phrase": "вторая ждёт очереди"
          },
          {
            "word": "ждёт",
            "count": 1,
            "phrase": "вторая ждёт очереди"
          },
          {
            "word": "очереди",
            "count": 1,
            "phrase": "вторая ждёт очереди"
          },
          {
            "word": "суровых",
            "count": 1,
            "phrase": "для уже суровых"
          },
          {
            "word": "сформировавшихся",
            "count": 1,
            "phrase": "сформировавшихся катышков машинка хороша"
          },
          {
            "word": "образовывающихся",
            "count": 1,
            "phrase": "только образовывающихся не возьмёт"
          },
          {
            "word": "возьмёт",
            "count": 1,
            "phrase": "только образовывающихся не возьмёт"
          },
          {
            "word": "приличный",
            "count": 1,
            "phrase": "но и так удалось привести в приличный вид немало вещей"
          },
          {
            "word": "выполнено",
            "count": 0,
            "phrase": "выполнено все отлично"
          },
          {
            "word": "дешёвый",
            "count": 1,
            "phrase": "не дешёвый"
          },
          {
            "word": "предыдущих",
            "count": 1,
            "phrase": "как у предыдущих"
          },
          {
            "word": "нужное",
            "count": 1,
            "phrase": "убирает все не нужное быстро и чисто"
          },
          {
            "word": "чистое",
            "count": 1,
            "phrase": "все чистое и не мятое"
          },
          {
            "word": "мятое",
            "count": 1,
            "phrase": "все чистое и не мятое"
          },
          {
            "word": "порядке!хорошо",
            "count": 0,
            "phrase": "но внутри всё в порядке!хорошо"
          },
          {
            "word": "подарила",
            "count": 3,
            "phrase": "то в таком виде не подарила бы!машинка заряжена"
          },
          {
            "word": "бы!машинка",
            "count": 0,
            "phrase": "то в таком виде не подарила бы!машинка заряжена"
          },
          {
            "word": "модель",
            "count": 2,
            "phrase": "но рада что взяла её и именно эту модель"
          },
          {
            "word": "всяких",
            "count": 1,
            "phrase": "чистится тоже без всяких проблем"
          },
          {
            "word": "чиста",
            "count": 2,
            "phrase": "не супер до чиста"
          },
          {
            "word": "сильные",
            "count": 1,
            "phrase": "но сильные катышки убирает идеально"
          },
          {
            "word": "оооооочень",
            "count": 1,
            "phrase": "ну оооооочень хорошая машинка"
          },
          {
            "word": "варежки",
            "count": 2,
            "phrase": "варежки были полностью в катышках"
          },
          {
            "word": "оооочень",
            "count": 1,
            "phrase": "оооочень довольна!работает отлично"
          },
          {
            "word": "довольна!работает",
            "count": 0,
            "phrase": "оооочень довольна!работает отлично"
          },
          {
            "word": "опробовали",
            "count": 4,
            "phrase": "сразу же опробовали"
          },
          {
            "word": "приобретала",
            "count": 0,
            "phrase": "приобретала для удаления катышков с верхней одежды"
          },
          {
            "word": "верхней",
            "count": 1,
            "phrase": "приобретала для удаления катышков с верхней одежды"
          },
          {
            "word": "следующий",
            "count": 2,
            "phrase": "товар пришёл на следующий день"
          },
          {
            "word": "фурор",
            "count": 1,
            "phrase": "просто фурор"
          },
          {
            "word": "казалось",
            "count": 13,
            "phrase": "которые казалось бы невозможно убрать"
          },
          {
            "word": "невозможно",
            "count": 1,
            "phrase": "которые казалось бы невозможно убрать"
          },
          {
            "word": "принесла",
            "count": 1,
            "phrase": "сразу принесла и испытала её"
          },
          {
            "word": "испытала",
            "count": 1,
            "phrase": "сразу принесла и испытала её"
          },
          {
            "word": "ждала",
            "count": 3,
            "phrase": "очень ждала"
          },
          {
            "word": "прекрасный",
            "count": 1,
            "phrase": "спасибо за прекрасный девайс"
          },
          {
            "word": "сумму",
            "count": 4,
            "phrase": "за такую сумму он просто отлично справляется со своей задачей"
          },
          {
            "word": "заказ",
            "count": 36,
            "phrase": "благодаря отзывам сделала заказ"
          },
          {
            "word": "отзывчивым",
            "count": 1,
            "phrase": "всем отзывчивым отдельное спасибо"
          },
          {
            "word": "заслуженно",
            "count": 1,
            "phrase": "5 звёзд заслуженно"
          },
          {
            "word": "видимых",
            "count": 1,
            "phrase": "без видимых повреждений"
          },
          {
            "word": "наличии+щетка",
            "count": 1,
            "phrase": "зарядка 🔌 в наличии+щетка для чистки"
          },
          {
            "word": "покупаете",
            "count": 1,
            "phrase": "кстати если покупаете"
          },
          {
            "word": "запастись",
            "count": 1,
            "phrase": "лучше запастись заранее"
          },
          {
            "word": "заранее",
            "count": 1,
            "phrase": "лучше запастись заранее"
          },
          {
            "word": "приёма",
            "count": 1,
            "phrase": "пять минут в пункте приёма подзарядилась и зажужжала"
          },
          {
            "word": "подзарядилась",
            "count": 1,
            "phrase": "пять минут в пункте приёма подзарядилась и зажужжала"
          },
          {
            "word": "зажужжала",
            "count": 1,
            "phrase": "пять минут в пункте приёма подзарядилась и зажужжала"
          },
          {
            "word": "очистили",
            "count": 3,
            "phrase": "дома очистили свитер и опять на зарядку"
          },
          {
            "word": "некий",
            "count": 1,
            "phrase": "для меня это ещё некий антистресс"
          },
          {
            "word": "многим",
            "count": 2,
            "phrase": "многим вещам даст вторую жизнь"
          },
          {
            "word": "даст",
            "count": 1,
            "phrase": "многим вещам даст вторую жизнь"
          },
          {
            "word": "заказав",
            "count": 1,
            "phrase": "но заказав и опробовав товар"
          },
          {
            "word": "заводская",
            "count": 0,
            "phrase": "заводская упаковка"
          },
          {
            "word": "690",
            "count": 1,
            "phrase": "тем более за 690"
          },
          {
            "word": "изготовителю",
            "count": 1,
            "phrase": "изготовителю и вб за доставку"
          },
          {
            "word": "добралась",
            "count": 1,
            "phrase": "добралась до детских носков"
          },
          {
            "word": "собралась",
            "count": 1,
            "phrase": "которые собралась выбрасывать"
          },
          {
            "word": "страшный",
            "count": 1,
            "phrase": "уж очень вид у них был страшный"
          },
          {
            "word": "проблемой",
            "count": 2,
            "phrase": "два с этой проблемой"
          },
          {
            "word": "похожа",
            "count": 0,
            "phrase": "похожа на маленький пылесос"
          },
          {
            "word": "пылесос",
            "count": 1,
            "phrase": "похожа на маленький пылесос"
          },
          {
            "word": "стресс",
            "count": 5,
            "phrase": "но и снимает стресс"
          },
          {
            "word": "получаешь",
            "count": 1,
            "phrase": "такой кайф получаешь от результата"
          },
          {
            "word": "вчерашнего",
            "count": 1,
            "phrase": "машинка просто супер офигенная почистила со вчерашнего дня всё"
          },
          {
            "word": "постригла",
            "count": 0,
            "phrase": "постригла две толстовки"
          },
          {
            "word": "толстовки",
            "count": 3,
            "phrase": "постригла две толстовки"
          },
          {
            "word": "заметила",
            "count": 3,
            "phrase": "что заметила"
          },
          {
            "word": "выше",
            "count": 2,
            "phrase": "чем выше качество самой ткани"
          },
          {
            "word": "самой",
            "count": 6,
            "phrase": "чем выше качество самой ткани"
          },
          {
            "word": "стрижет",
            "count": 1,
            "phrase": "тем лучше стрижет машинка"
          },
          {
            "word": "дешевую",
            "count": 1,
            "phrase": "после дешевую сувенирную китайскую толстовку"
          },
          {
            "word": "сувенирную",
            "count": 1,
            "phrase": "после дешевую сувенирную китайскую толстовку"
          },
          {
            "word": "китайскую",
            "count": 1,
            "phrase": "после дешевую сувенирную китайскую толстовку"
          },
          {
            "word": "мелкая",
            "count": 1,
            "phrase": "быстро чистит тк не мелкая"
          },
          {
            "word": "сожалею",
            "count": 1,
            "phrase": "купила по отзывам и не сожалею"
          },
          {
            "word": "космос",
            "count": 1,
            "phrase": "машинка просто космос"
          },
          {
            "word": "классное",
            "count": 1,
            "phrase": "самое классное"
          },
          {
            "word": "проводу",
            "count": 1,
            "phrase": "что она заряжается по проводу"
          },
          {
            "word": "мешает",
            "count": 1,
            "phrase": "не мешает телевизору"
          },
          {
            "word": "телевизору",
            "count": 1,
            "phrase": "не мешает телевизору"
          },
          {
            "word": "преображаются",
            "count": 2,
            "phrase": "вещи преображаются конечно"
          },
          {
            "word": "шумно",
            "count": 4,
            "phrase": "работает долго и не очень шумно"
          },
          {
            "word": "пупер",
            "count": 1,
            "phrase": "супер пупер"
          },
          {
            "word": "минуту",
            "count": 2,
            "phrase": "удалила катышки со штанов за 1 минуту"
          },
          {
            "word": "550р",
            "count": 1,
            "phrase": "в за 550р это сказка машинка"
          },
          {
            "word": "сказка",
            "count": 1,
            "phrase": "в за 550р это сказка машинка"
          },
          {
            "word": "осторожны",
            "count": 1,
            "phrase": "будьте осторожны"
          },
          {
            "word": "задекорировать",
            "count": 1,
            "phrase": "теперь думаю чем задекорировать"
          },
          {
            "word": "разложив",
            "count": 1,
            "phrase": "чистила разложив ровно на гладитьной доске"
          },
          {
            "word": "ровно",
            "count": 2,
            "phrase": "чистила разложив ровно на гладитьной доске"
          },
          {
            "word": "гладитьной",
            "count": 1,
            "phrase": "чистила разложив ровно на гладитьной доске"
          },
          {
            "word": "доске",
            "count": 1,
            "phrase": "чистила разложив ровно на гладитьной доске"
          },
          {
            "word": "придерживать",
            "count": 1,
            "phrase": "по этому советую придерживать вещи при чистке что"
          },
          {
            "word": "случилось",
            "count": 1,
            "phrase": "бы такого не случилось"
          },
          {
            "word": "остаются",
            "count": 2,
            "phrase": "остаются прям малютки"
          },
          {
            "word": "малютки",
            "count": 1,
            "phrase": "остаются прям малютки"
          },
          {
            "word": "макро",
            "count": 1,
            "phrase": "но это видно на макро режиме камеры только"
          },
          {
            "word": "режиме",
            "count": 1,
            "phrase": "но это видно на макро режиме камеры только"
          },
          {
            "word": "камеры",
            "count": 1,
            "phrase": "но это видно на макро режиме камеры только"
          },
          {
            "word": "своём",
            "count": 1,
            "phrase": "сразу опробовала на своём пальто"
          },
          {
            "word": "свитерами",
            "count": 2,
            "phrase": "со свитерами справилась быстро"
          },
          {
            "word": "видимо",
            "count": 2,
            "phrase": "видимо все зависит от ткани"
          },
          {
            "word": "покупался",
            "count": 1,
            "phrase": "но покупался именно с этой целью"
          },
          {
            "word": "целью",
            "count": 2,
            "phrase": "но покупался именно с этой целью"
          },
          {
            "word": "тех",
            "count": 9,
            "phrase": "для тех"
          },
          {
            "word": "живут",
            "count": 2,
            "phrase": "у кого дома живут пушистые"
          },
          {
            "word": "пушистые",
            "count": 1,
            "phrase": "у кого дома живут пушистые"
          },
          {
            "word": "невоспитанные",
            "count": 1,
            "phrase": "невоспитанные хулиганы"
          },
          {
            "word": "хулиганы",
            "count": 1,
            "phrase": "невоспитанные хулиганы"
          },
          {
            "word": "мощнее",
            "count": 2,
            "phrase": "гораздо мощнее машинок"
          },
          {
            "word": "машинок",
            "count": 5,
            "phrase": "гораздо мощнее машинок"
          },
          {
            "word": "медитация",
            "count": 3,
            "phrase": "это как медитация"
          },
          {
            "word": "трудно",
            "count": 4,
            "phrase": "трудно остановиться"
          },
          {
            "word": "дочи",
            "count": 1,
            "phrase": "но вот колготки дочи попортила"
          },
          {
            "word": "попортила",
            "count": 1,
            "phrase": "но вот колготки дочи попортила"
          },
          {
            "word": "поторопилась",
            "count": 1,
            "phrase": "наверно поторопилась"
          },
          {
            "word": "устала",
            "count": 1,
            "phrase": "рука совсем не устала"
          },
          {
            "word": "удобное",
            "count": 0,
            "phrase": "удобное прозрачное стекло"
          },
          {
            "word": "прозрачное",
            "count": 1,
            "phrase": "удобное прозрачное стекло"
          },
          {
            "word": "стекло",
            "count": 1,
            "phrase": "удобное прозрачное стекло"
          },
          {
            "word": "наполненность",
            "count": 1,
            "phrase": "через которое видно наполненность контейнера"
          },
          {
            "word": "высоте",
            "count": 1,
            "phrase": "качество очистки на высоте"
          },
          {
            "word": "неудачно",
            "count": 2,
            "phrase": "которые неудачно постирала"
          },
          {
            "word": "постирала",
            "count": 1,
            "phrase": "которые неудачно постирала"
          },
          {
            "word": "летней",
            "count": 1,
            "phrase": "а также спасла вещь семи летней давности"
          },
          {
            "word": "давности",
            "count": 1,
            "phrase": "а также спасла вещь семи летней давности"
          },
          {
            "word": "хев",
            "count": 1,
            "phrase": "маст хев в быту"
          },
          {
            "word": "действии",
            "count": 2,
            "phrase": "а действии опробовали"
          },
          {
            "word": "дополняю",
            "count": 0,
            "phrase": "дополняю отзыв"
          },
          {
            "word": "машину",
            "count": 1,
            "phrase": "носки даже машину обработала в местах"
          },
          {
            "word": "местах",
            "count": 1,
            "phrase": "носки даже машину обработала в местах"
          },
          {
            "word": "нравит",
            "count": 11,
            "phrase": "мне очень нравит"
          },
          {
            "word": "наглядный",
            "count": 0,
            "phrase": "наглядный пример снизу"
          },
          {
            "word": "пример",
            "count": 7,
            "phrase": "наглядный пример снизу"
          },
          {
            "word": "снизу",
            "count": 3,
            "phrase": "наглядный пример снизу"
          },
          {
            "word": "забывайте",
            "count": 1,
            "phrase": "не забывайте откручивать головку и очищать от засорения"
          },
          {
            "word": "откручивать",
            "count": 1,
            "phrase": "не забывайте откручивать головку и очищать от засорения"
          },
          {
            "word": "головку",
            "count": 1,
            "phrase": "не забывайте откручивать головку и очищать от засорения"
          },
          {
            "word": "засорения",
            "count": 1,
            "phrase": "не забывайте откручивать головку и очищать от засорения"
          },
          {
            "word": "уходят",
            "count": 2,
            "phrase": "катышки уходят"
          },
          {
            "word": "аналогов",
            "count": 1,
            "phrase": "намного лучше дешёвых аналогов"
          },
          {
            "word": "измята",
            "count": 2,
            "phrase": "но коробка измята"
          },
          {
            "word": "пишут",
            "count": 3,
            "phrase": "пишут номер фломастером на ней"
          },
          {
            "word": "номер",
            "count": 3,
            "phrase": "пишут номер фломастером на ней"
          },
          {
            "word": "фломастером",
            "count": 1,
            "phrase": "пишут номер фломастером на ней"
          },
          {
            "word": "стереть",
            "count": 1,
            "phrase": "брала на подарок - ни стереть"
          },
          {
            "word": "отклеить",
            "count": 1,
            "phrase": "ни отклеить"
          },
          {
            "word": "зачем",
            "count": 1,
            "phrase": "зачем - то на нее клеят код"
          },
          {
            "word": "клеят",
            "count": 1,
            "phrase": "зачем - то на нее клеят код"
          },
          {
            "word": "нормальная",
            "count": 1,
            "phrase": "коробка нормальная"
          },
          {
            "word": "поменьше",
            "count": 1,
            "phrase": "думал правда что машинка будет поменьше"
          },
          {
            "word": "запасные",
            "count": 1,
            "phrase": "заказал еще на нее запасные ножи"
          },
          {
            "word": "легинсы",
            "count": 0,
            "phrase": "легинсы были спасены"
          },
          {
            "word": "ошеломляющего",
            "count": 1,
            "phrase": "такого ошеломляющего успеха от машинки я не ожидала"
          },
          {
            "word": "успеха",
            "count": 1,
            "phrase": "такого ошеломляющего успеха от машинки я не ожидала"
          },
          {
            "word": "очищения",
            "count": 3,
            "phrase": "очень затягивает процесс очищения вещей"
          },
          {
            "word": "положительного",
            "count": 1,
            "phrase": "не ожидала такого положительного эффекта от ее использования"
          },
          {
            "word": "гладкими",
            "count": 1,
            "phrase": "стали аккуратными и гладкими"
          },
          {
            "word": "причастным",
            "count": 1,
            "phrase": "спасибо производителю и всем причастным"
          },
          {
            "word": "порадовал!пришло",
            "count": 0,
            "phrase": "товар очень порадовал!пришло всё в отличном состоянии"
          },
          {
            "word": "шла",
            "count": 133,
            "phrase": "в комплекте шла щёточка для чистки!!спасибо за товар"
          },
          {
            "word": "чистки!!спасибо",
            "count": 0,
            "phrase": "в комплекте шла щёточка для чистки!!спасибо за товар"
          },
          {
            "word": "футболки",
            "count": 2,
            "phrase": "катышки с футболки все убрались"
          },
          {
            "word": "убрались",
            "count": 1,
            "phrase": "катышки с футболки все убрались"
          },
          {
            "word": "подглядела",
            "count": 0,
            "phrase": "подглядела у подруги и тут же заказала себе"
          },
          {
            "word": "подруги",
            "count": 5,
            "phrase": "подглядела у подруги и тут же заказала себе"
          },
          {
            "word": "пленки",
            "count": 1,
            "phrase": "но без какой либо пленки"
          },
          {
            "word": "наклейки",
            "count": 1,
            "phrase": "просто наклейки"
          },
          {
            "word": "целые",
            "count": 1,
            "phrase": "которые были целые и не вскрыты"
          },
          {
            "word": "вскрыты",
            "count": 1,
            "phrase": "которые были целые и не вскрыты"
          },
          {
            "word": "распаковки",
            "count": 2,
            "phrase": "после распаковки тут же воспользовалась"
          },
          {
            "word": "слон",
            "count": 2,
            "phrase": "я довольна как слон"
          },
          {
            "word": "превращаются",
            "count": 1,
            "phrase": "детские носки и колготки превращаются в новые"
          },
          {
            "word": "маленьком",
            "count": 1,
            "phrase": "попробовала на маленьком участке"
          },
          {
            "word": "участке",
            "count": 1,
            "phrase": "попробовала на маленьком участке"
          },
          {
            "word": "здоровская",
            "count": 1,
            "phrase": "машинка здоровская"
          },
          {
            "word": "включилась",
            "count": 1,
            "phrase": "в пункте выдачи включилась"
          },
          {
            "word": "проверили",
            "count": 2,
            "phrase": "дома проверили зарядку - работает"
          },
          {
            "word": "теста",
            "count": 1,
            "phrase": "для теста были выбраны перчатки"
          },
          {
            "word": "выбраны",
            "count": 1,
            "phrase": "для теста были выбраны перчатки"
          },
          {
            "word": "перчатки",
            "count": 1,
            "phrase": "для теста были выбраны перчатки"
          },
          {
            "word": "хорошему",
            "count": 1,
            "phrase": "по хорошему"
          },
          {
            "word": "поехало",
            "count": 1,
            "phrase": "потом пошло поехало"
          },
          {
            "word": "новье",
            "count": 2,
            "phrase": "не совсем новье новье стают"
          },
          {
            "word": "стают",
            "count": 3,
            "phrase": "не совсем новье новье стают"
          },
          {
            "word": "ветер",
            "count": 2,
            "phrase": "конечно то было все деньги на ветер"
          },
          {
            "word": "телефон",
            "count": 6,
            "phrase": "это качественный товар и можно заряжать как телефон"
          },
          {
            "word": "рекомендации",
            "count": 4,
            "phrase": "спасибо за рекомендации предыдущим покупателям"
          },
          {
            "word": "предыдущим",
            "count": 1,
            "phrase": "спасибо за рекомендации предыдущим покупателям"
          },
          {
            "word": "покупателям",
            "count": 2,
            "phrase": "спасибо за рекомендации предыдущим покупателям"
          },
          {
            "word": "волшебная",
            "count": 1,
            "phrase": "супер волшебная штука"
          },
          {
            "word": "магия",
            "count": 1,
            "phrase": "просто магия какая то"
          },
          {
            "word": "довольной",
            "count": 1,
            "phrase": "брала по рекомендации подруги и осталась очень довольной покупкой"
          },
          {
            "word": "скажет",
            "count": 1,
            "phrase": "пусть фото само скажет за меня"
          },
          {
            "word": "успешно",
            "count": 2,
            "phrase": "уже много вещей успешно"
          },
          {
            "word": "наглядности",
            "count": 1,
            "phrase": "перчатки мужа до и после для наглядности сфоткала"
          },
          {
            "word": "сжирает",
            "count": 1,
            "phrase": "катышки сжирает отлично"
          },
          {
            "word": "плюс-",
            "count": 1,
            "phrase": "и большой плюс- проводная зарядка"
          },
          {
            "word": "проводная",
            "count": 1,
            "phrase": "и большой плюс- проводная зарядка"
          },
          {
            "word": "нормально",
            "count": 5,
            "phrase": "всё нормально"
          },
          {
            "word": "замечаний",
            "count": 0,
            "phrase": "замечаний к товару нет"
          },
          {
            "word": "прямо",
            "count": 3,
            "phrase": "я прямо в восторге"
          },
          {
            "word": "плотная",
            "count": 1,
            "phrase": "сеточка плотная"
          },
          {
            "word": "отличии",
            "count": 1,
            "phrase": "в отличии от дешёвых моделей"
          },
          {
            "word": "моделей",
            "count": 1,
            "phrase": "в отличии от дешёвых моделей"
          },
          {
            "word": "протестировала",
            "count": 1,
            "phrase": "уже протестировала"
          },
          {
            "word": "вещь!!на",
            "count": 0,
            "phrase": "крутая вещь!!на фото плед"
          },
          {
            "word": "плед",
            "count": 3,
            "phrase": "крутая вещь!!на фото плед"
          },
          {
            "word": "вида",
            "count": 3,
            "phrase": "от вида которого хотелось плакать"
          },
          {
            "word": "которого",
            "count": 1,
            "phrase": "от вида которого хотелось плакать"
          },
          {
            "word": "плакать",
            "count": 1,
            "phrase": "от вида которого хотелось плакать"
          },
          {
            "word": "новый)))спасибо",
            "count": 1,
            "phrase": "слева как новый)))спасибо"
          },
          {
            "word": "жутком",
            "count": 1,
            "phrase": "шапка была в жутком состоянии"
          },
          {
            "word": "новенькая",
            "count": 2,
            "phrase": "сейчас как новенькая"
          },
          {
            "word": "семья",
            "count": 3,
            "phrase": "довольна вся семья"
          },
          {
            "word": "долгой",
            "count": 1,
            "phrase": "а также долгой носки вещей)спасибо за такой товар"
          },
          {
            "word": "вещей)спасибо",
            "count": 1,
            "phrase": "а также долгой носки вещей)спасибо за такой товар"
          },
          {
            "word": "спасатель",
            "count": 1,
            "phrase": "вещь - спасатель"
          },
          {
            "word": "очистил",
            "count": 64,
            "phrase": "убрал и очистил кучу катышков"
          },
          {
            "word": "идеальной",
            "count": 1,
            "phrase": "пришёл в идеальной упаковке"
          },
          {
            "word": "детскую",
            "count": 2,
            "phrase": "почистила детскую шапку"
          },
          {
            "word": "класс",
            "count": 55,
            "phrase": "машинка класс"
          },
          {
            "word": "существенная",
            "count": 1,
            "phrase": "на фото видна существенная разница"
          },
          {
            "word": "сточились",
            "count": 1,
            "phrase": "ножи не сточились"
          },
          {
            "word": "чищенного",
            "count": 1,
            "phrase": "на фото часть чищенного и с катушками на кофте"
          },
          {
            "word": "заводской",
            "count": 2,
            "phrase": "пришел просто в заводской коробке"
          },
          {
            "word": "плотно",
            "count": 2,
            "phrase": "но внутри всё плотно упаковано"
          },
          {
            "word": "батарее",
            "count": 13,
            "phrase": "работает на батарее шнур для зарядки в комплекте"
          },
          {
            "word": "чище",
            "count": 13,
            "phrase": "почистила и пальто даже чище стало"
          },
          {
            "word": "очевидно",
            "count": 2,
            "phrase": "фото до - после очевидно"
          },
          {
            "word": "данная",
            "count": 2,
            "phrase": "данная машинка меня более чем устраивает"
          },
          {
            "word": "свести",
            "count": 1,
            "phrase": "но очень старые катышки трудно теперь свести"
          },
          {
            "word": "отличной",
            "count": 1,
            "phrase": "товар пришёл в отличной упаковке"
          },
          {
            "word": "карточки",
            "count": 1,
            "phrase": "содержимое коробки соответствует карточки товара"
          },
          {
            "word": "часов",
            "count": 3,
            "phrase": "зарядку держит 5 часов"
          },
          {
            "word": "посередине",
            "count": 0,
            "phrase": "посередине как было"
          },
          {
            "word": "краям",
            "count": 1,
            "phrase": "а по краям"
          },
          {
            "word": "подсаживались",
            "count": 1,
            "phrase": "как только они подсаживались чистить начинала хуже"
          },
          {
            "word": "начинала",
            "count": 1,
            "phrase": "как только они подсаживались чистить начинала хуже"
          },
          {
            "word": "заряжай",
            "count": 1,
            "phrase": "а эту заряжай да чисти"
          },
          {
            "word": "шерстяные",
            "count": 1,
            "phrase": "почистила все шерстяные носки и колготки ребенка"
          },
          {
            "word": "недорого",
            "count": 1,
            "phrase": "да ещё так недорого"
          },
          {
            "word": "могла",
            "count": 9,
            "phrase": "я целый час не могла остановиться"
          },
          {
            "word": "перечислила",
            "count": 1,
            "phrase": "все перечислила дома"
          },
          {
            "word": "костюмов",
            "count": 1,
            "phrase": "от костюмов мужа"
          },
          {
            "word": "костюма",
            "count": 2,
            "phrase": "прилагаю пример костюма"
          },
          {
            "word": "опыт",
            "count": 2,
            "phrase": "до этого был опыт пользования машинкой из фикса"
          },
          {
            "word": "пользования",
            "count": 14,
            "phrase": "до этого был опыт пользования машинкой из фикса"
          },
          {
            "word": "фикса",
            "count": 1,
            "phrase": "до этого был опыт пользования машинкой из фикса"
          },
          {
            "word": "разы",
            "count": 3,
            "phrase": "эта в разы мощнее и намного больше диск"
          },
          {
            "word": "упрощает",
            "count": 1,
            "phrase": "это упрощает и ускоряет процесс"
          },
          {
            "word": "некоторых",
            "count": 3,
            "phrase": "на некоторых материал катышки уходят с одного касания"
          },
          {
            "word": "материал",
            "count": 4,
            "phrase": "на некоторых материал катышки уходят с одного касания"
          },
          {
            "word": "касания",
            "count": 1,
            "phrase": "на некоторых материал катышки уходят с одного касания"
          },
          {
            "word": "туда",
            "count": 1,
            "phrase": "водить туда сюда по несколько раз"
          },
          {
            "word": "сюда",
            "count": 1,
            "phrase": "водить туда сюда по несколько раз"
          },
          {
            "word": "тёмные",
            "count": 1,
            "phrase": "тёмные колготки тоже супер результат"
          },
          {
            "word": "понимать",
            "count": 1,
            "phrase": "те надо понимать"
          },
          {
            "word": "разный",
            "count": 1,
            "phrase": "что результат может быть разный и зависит от материала"
          },
          {
            "word": "малюююсенькте",
            "count": 1,
            "phrase": "совсем малюююсенькте катышки не убирает"
          },
          {
            "word": "красный",
            "count": 3,
            "phrase": "на фото красный футер"
          },
          {
            "word": "футер",
            "count": 1,
            "phrase": "на фото красный футер"
          },
          {
            "word": "ерунда",
            "count": 1,
            "phrase": "что все эти машинки ерунда полная"
          },
          {
            "word": "2-х",
            "count": 1,
            "phrase": "но по совету 2-х знакомых- заказала"
          },
          {
            "word": "знакомых-",
            "count": 1,
            "phrase": "но по совету 2-х знакомых- заказала"
          },
          {
            "word": "мечта",
            "count": 4,
            "phrase": "просто моя мечта"
          },
          {
            "word": "шапок",
            "count": 2,
            "phrase": "решила купить машинку для удаления с детских шапок"
          },
          {
            "word": "минута",
            "count": 0,
            "phrase": "минута и шапка как новая"
          },
          {
            "word": "повлияло",
            "count": 1,
            "phrase": "но это никак не повлияло на саму машинку"
          },
          {
            "word": "саму",
            "count": 2,
            "phrase": "но это никак не повлияло на саму машинку"
          },
          {
            "word": "идеале",
            "count": 1,
            "phrase": "но сама машинка в идеале"
          },
          {
            "word": "ужасную",
            "count": 1,
            "phrase": "не смотря на ужасную коробку"
          },
          {
            "word": "обязанностью",
            "count": 1,
            "phrase": "машинка со своей обязанностью справляется"
          },
          {
            "word": "порекомендовать",
            "count": 1,
            "phrase": "но очень хочется порекомендовать"
          },
          {
            "word": "принять",
            "count": 1,
            "phrase": "принять хотя бы транспортировочную упаковку"
          },
          {
            "word": "транспортировочную",
            "count": 1,
            "phrase": "принять хотя бы транспортировочную упаковку"
          },
          {
            "word": "пропадает",
            "count": 1,
            "phrase": "а то пропадает вау - эффект"
          },
          {
            "word": "голову",
            "count": 1,
            "phrase": "в голову сразу кучу сомнений к продавцу"
          },
          {
            "word": "сомнений",
            "count": 1,
            "phrase": "в голову сразу кучу сомнений к продавцу"
          },
          {
            "word": "шерстяного",
            "count": 1,
            "phrase": "детского шерстяного платья"
          },
          {
            "word": "защиты",
            "count": 1,
            "phrase": "без дополнительной защиты"
          },
          {
            "word": "показалось",
            "count": 2,
            "phrase": "сначала показалось"
          },
          {
            "word": "затем",
            "count": 1,
            "phrase": "и затем вообще всю простынь откатышковала"
          },
          {
            "word": "откатышковала",
            "count": 1,
            "phrase": "и затем вообще всю простынь откатышковала"
          },
          {
            "word": "подключился",
            "count": 1,
            "phrase": "муж тоже подключился и откатышковал наши подушки"
          },
          {
            "word": "откатышковал",
            "count": 2,
            "phrase": "муж тоже подключился и откатышковал наши подушки"
          },
          {
            "word": "наши",
            "count": 1,
            "phrase": "муж тоже подключился и откатышковал наши подушки"
          },
          {
            "word": "выдергивает",
            "count": 1,
            "phrase": "не выдергивает нитки"
          },
          {
            "word": "стояла",
            "count": 1,
            "phrase": "даже рядом не стояла с теми машинками"
          },
          {
            "word": "теми",
            "count": 1,
            "phrase": "даже рядом не стояла с теми машинками"
          },
          {
            "word": "начисто",
            "count": 2,
            "phrase": "катышки убирает начисто"
          },
          {
            "word": "костюмчик",
            "count": 1,
            "phrase": "почистила костюмчик"
          },
          {
            "word": "желании",
            "count": 1,
            "phrase": "можно при желании брать с собой"
          },
          {
            "word": "небольшой",
            "count": 1,
            "phrase": "контейнер для сбора небольшой"
          },
          {
            "word": "кот",
            "count": 84,
            "phrase": "ребёнок и кот ни чуть не напугались"
          },
          {
            "word": "напугались",
            "count": 1,
            "phrase": "ребёнок и кот ни чуть не напугались"
          },
          {
            "word": "почитала",
            "count": 1,
            "phrase": "почитала отзывы и решила купить"
          },
          {
            "word": "школьный",
            "count": 1,
            "phrase": "школьный джемпер как новый"
          },
          {
            "word": "друзьям",
            "count": 2,
            "phrase": "буду рекомендовать друзьям"
          },
          {
            "word": "клёвая",
            "count": 1,
            "phrase": "очень клёвая машинка"
          },
          {
            "word": "запущенна",
            "count": 1,
            "phrase": "которая была сильно запущенна удалила все катышки"
          },
          {
            "word": "подругам",
            "count": 2,
            "phrase": "рекомендую подругам"
          },
          {
            "word": "моего",
            "count": 1,
            "phrase": "реанимация моего шарфа"
          },
          {
            "word": "шарфа",
            "count": 1,
            "phrase": "реанимация моего шарфа"
          },
          {
            "word": "портили",
            "count": 1,
            "phrase": "катышки совсем портили вид"
          },
          {
            "word": "проходиться",
            "count": 2,
            "phrase": "советую регулярно проходиться машинкой по вещам"
          },
          {
            "word": "склонным",
            "count": 1,
            "phrase": "склонным к образованию катышек"
          },
          {
            "word": "образованию",
            "count": 1,
            "phrase": "склонным к образованию катышек"
          },
          {
            "word": "случай",
            "count": 3,
            "phrase": "когда случай запущенный очень долго приходиться обрабатывать"
          },
          {
            "word": "запущенный",
            "count": 1,
            "phrase": "когда случай запущенный очень долго приходиться обрабатывать"
          },
          {
            "word": "приходиться",
            "count": 1,
            "phrase": "когда случай запущенный очень долго приходиться обрабатывать"
          },
          {
            "word": "иную",
            "count": 1,
            "phrase": "ту или иную ткань"
          },
          {
            "word": "решение",
            "count": 0,
            "phrase": "решение в спасении вещей номер один"
          },
          {
            "word": "спасении",
            "count": 1,
            "phrase": "решение в спасении вещей номер один"
          },
          {
            "word": "огромнейшее",
            "count": 0,
            "phrase": "спасибо огромнейшее"
          },
          {
            "word": "создали",
            "count": 0,
            "phrase": "что создали такую машинку и запустили ее в продажу"
          },
          {
            "word": "запустили",
            "count": 0,
            "phrase": "что создали такую машинку и запустили ее в продажу"
          },
          {
            "word": "продажу",
            "count": 1,
            "phrase": "что создали такую машинку и запустили ее в продажу"
          },
          {
            "word": "тому",
            "count": 36,
            "phrase": "цена к тому же"
          },
          {
            "word": "подумали",
            "count": 1,
            "phrase": "подруги даже подумали"
          },
          {
            "word": "1000",
            "count": 1,
            "phrase": "в других магазинах по 1000 - 1500р"
          },
          {
            "word": "1500р",
            "count": 1,
            "phrase": "в других магазинах по 1000 - 1500р"
          },
          {
            "word": "закатались",
            "count": 1,
            "phrase": "сильно закатались"
          },
          {
            "word": "упал",
            "count": 19,
            "phrase": "упал в цене"
          },
          {
            "word": "платный",
            "count": 1,
            "phrase": "отказ платный"
          },
          {
            "word": "заморачиваться",
            "count": 1,
            "phrase": "не стала заморачиваться"
          },
          {
            "word": "маленькую",
            "count": 1,
            "phrase": "за такую маленькую стоимость"
          },
          {
            "word": "стоимость",
            "count": 2,
            "phrase": "за такую маленькую стоимость"
          },
          {
            "word": "легкостью",
            "count": 1,
            "phrase": "катышки убирает с легкостью"
          },
          {
            "word": "меняются",
            "count": 1,
            "phrase": "лезвия меняются и можно их заказать отдельно - это плюс"
          },
          {
            "word": "опыта",
            "count": 1,
            "phrase": "у меня опыта не было от слова совсем"
          },
          {
            "word": "выбирала",
            "count": 6,
            "phrase": "дочь выбирала и тестила машинку сегодня"
          },
          {
            "word": "тестила",
            "count": 1,
            "phrase": "дочь выбирала и тестила машинку сегодня"
          },
          {
            "word": "распечатывали",
            "count": 1,
            "phrase": "новый не распечатывали"
          },
          {
            "word": "tpc",
            "count": 1,
            "phrase": "шнур tpc"
          },
          {
            "word": "заявлена",
            "count": 1,
            "phrase": "но она и не заявлена в комплекте"
          },
          {
            "word": "видны",
            "count": 1,
            "phrase": "справа видны катушки"
          },
          {
            "word": "соответсвует",
            "count": 1,
            "phrase": "всё соответсвует"
          },
          {
            "word": "сарафан",
            "count": 1,
            "phrase": "почистила школьный сарафан"
          },
          {
            "word": "сделана",
            "count": 1,
            "phrase": "очень качественно сделана"
          },
          {
            "word": "яблоко",
            "count": 1,
            "phrase": "размером с яблоко"
          },
          {
            "word": "вечер",
            "count": 5,
            "phrase": "в этот же вечер почистила свитер"
          },
          {
            "word": "водите",
            "count": 15,
            "phrase": "не водите ей очень быстро"
          },
          {
            "word": "медленней",
            "count": 1,
            "phrase": "чем медленней"
          },
          {
            "word": "шерстью",
            "count": 1,
            "phrase": "с шерстью и весь был покрыт огромный катышками"
          },
          {
            "word": "покрыт",
            "count": 1,
            "phrase": "с шерстью и весь был покрыт огромный катышками"
          },
          {
            "word": "корейском",
            "count": 1,
            "phrase": "инструкция вся на китайском или корейском"
          },
          {
            "word": "снимать",
            "count": 1,
            "phrase": "но снимать за это думаю нет смысла"
          },
          {
            "word": "смысла",
            "count": 1,
            "phrase": "но снимать за это думаю нет смысла"
          },
          {
            "word": "менять",
            "count": 2,
            "phrase": "есть возможность менять бак с катышками"
          },
          {
            "word": "бак",
            "count": 4,
            "phrase": "есть возможность менять бак с катышками"
          },
          {
            "word": "съемные",
            "count": 1,
            "phrase": "лезвия по - моему съемные и это довольно удобно"
          },
          {
            "word": "крупный",
            "count": 1,
            "phrase": "что такой крупный"
          },
          {
            "word": "ладонь",
            "count": 1,
            "phrase": "у меня маленькая ладонь"
          },
          {
            "word": "сказали",
            "count": 1,
            "phrase": "мои носки сказали мне спасибо"
          },
          {
            "word": "многих",
            "count": 2,
            "phrase": "выбирала из многих и остановилась на ней"
          },
          {
            "word": "пододеяльник",
            "count": 2,
            "phrase": "и пододеяльник"
          },
          {
            "word": "пустить",
            "count": 1,
            "phrase": "который уже хотела пустить на тряпки"
          },
          {
            "word": "тряпки",
            "count": 1,
            "phrase": "который уже хотела пустить на тряпки"
          },
          {
            "word": "ощутимая",
            "count": 0,
            "phrase": "ощутимая разница после применения"
          },
          {
            "word": "тёплых",
            "count": 1,
            "phrase": "проверила на тёплых носках"
          },
          {
            "word": "приспособление",
            "count": 1,
            "phrase": "шикарное приспособление"
          },
          {
            "word": "тестировать",
            "count": 3,
            "phrase": "сегодня забрала и сразу начала тестировать"
          },
          {
            "word": "джемперов",
            "count": 1,
            "phrase": "пару шапок и пару джемперов"
          },
          {
            "word": "вернула",
            "count": 2,
            "phrase": "дома вернула к жизни вещи"
          },
          {
            "word": "оболденная",
            "count": 0,
            "phrase": "оболденная штука"
          },
          {
            "word": "доставкой",
            "count": 1,
            "phrase": "доставкой и качеством этой машинки"
          },
          {
            "word": "дельная",
            "count": 1,
            "phrase": "действительно дельная вещь да ещё и за такую сумму"
          },
          {
            "word": "разьем",
            "count": 1,
            "phrase": "особенно порадовал разьем type c"
          },
          {
            "word": "торговой",
            "count": 1,
            "phrase": "единственный минус только самой торговой площадке"
          },
          {
            "word": "площадке",
            "count": 2,
            "phrase": "единственный минус только самой торговой площадке"
          },
          {
            "word": "доехала",
            "count": 1,
            "phrase": "машинка уже было доехала до пункта выдачи"
          },
          {
            "word": "пункта",
            "count": 2,
            "phrase": "машинка уже было доехала до пункта выдачи"
          },
          {
            "word": "рисковала",
            "count": 1,
            "phrase": "рисковала не успеть получить перед отьездом в командировку"
          },
          {
            "word": "успеть",
            "count": 1,
            "phrase": "рисковала не успеть получить перед отьездом в командировку"
          },
          {
            "word": "отьездом",
            "count": 1,
            "phrase": "рисковала не успеть получить перед отьездом в командировку"
          },
          {
            "word": "командировку",
            "count": 1,
            "phrase": "рисковала не успеть получить перед отьездом в командировку"
          },
          {
            "word": "заводскую",
            "count": 1,
            "phrase": "упакован в заводскую коробочку"
          },
          {
            "word": "коробочку",
            "count": 1,
            "phrase": "упакован в заводскую коробочку"
          },
          {
            "word": "проверял",
            "count": 2,
            "phrase": "проверял на месте всё работает"
          },
          {
            "word": "отлично!и",
            "count": 1,
            "phrase": "всё отлично!и всем спасибо"
          },
          {
            "word": "сдизайнерила",
            "count": 1,
            "phrase": "которая сдизайнерила кошка"
          },
          {
            "word": "образалось",
            "count": 1,
            "phrase": "шикарно всё образалось"
          },
          {
            "word": "дизайнер",
            "count": 2,
            "phrase": "а так как дизайнер постарался и площадь поражения"
          },
          {
            "word": "постарался",
            "count": 1,
            "phrase": "а так как дизайнер постарался и площадь поражения"
          },
          {
            "word": "площадь",
            "count": 1,
            "phrase": "а так как дизайнер постарался и площадь поражения"
          },
          {
            "word": "поражения",
            "count": 1,
            "phrase": "а так как дизайнер постарался и площадь поражения"
          },
          {
            "word": "кровать",
            "count": 1,
            "phrase": "вся кровать решила"
          },
          {
            "word": "затупятся",
            "count": 1,
            "phrase": "что лезвия затупятся и потребуется замена"
          },
          {
            "word": "потребуется",
            "count": 1,
            "phrase": "что лезвия затупятся и потребуется замена"
          },
          {
            "word": "сном",
            "count": 3,
            "phrase": "теперь это моё любимое занятие перед сном"
          },
          {
            "word": "моментально",
            "count": 2,
            "phrase": "чистит от катышков моментально"
          },
          {
            "word": "отчистит",
            "count": 1,
            "phrase": "машинка огонь 🔥 не ожидала что настолько всё отчистит"
          },
          {
            "word": "давольна",
            "count": 1,
            "phrase": "покупкой очень давольна"
          },
          {
            "word": "вечная",
            "count": 0,
            "phrase": "вечная проблема с носками"
          },
          {
            "word": "носками",
            "count": 2,
            "phrase": "вечная проблема с носками"
          },
          {
            "word": "технику",
            "count": 1,
            "phrase": "спасибо за это чудо технику"
          },
          {
            "word": "каких",
            "count": 5,
            "phrase": "без каких либо усилий и быстро все удаляет"
          },
          {
            "word": "месту",
            "count": 4,
            "phrase": "провела два раза по одному месту и все чисто"
          },
          {
            "word": "ссупер",
            "count": 0,
            "phrase": "ссупер вещица"
          },
          {
            "word": "знала",
            "count": 1,
            "phrase": "и как я раньше не знала о таком приборе"
          },
          {
            "word": "приборе",
            "count": 1,
            "phrase": "и как я раньше не знала о таком приборе"
          },
          {
            "word": "должно",
            "count": 1,
            "phrase": "что это должно быть в каждом гардеробе"
          },
          {
            "word": "гардеробе",
            "count": 1,
            "phrase": "что это должно быть в каждом гардеробе"
          },
          {
            "word": "образца",
            "count": 1,
            "phrase": "половина свитера почищена для образца"
          },
          {
            "word": "дарила",
            "count": 4,
            "phrase": "дарила маме мужа"
          },
          {
            "word": "сетка",
            "count": 2,
            "phrase": "зарядка и сетка в комплекте"
          },
          {
            "word": "бомбовая",
            "count": 1,
            "phrase": "ой бомбовая штучка"
          },
          {
            "word": "запечатаное",
            "count": 1,
            "phrase": "все пришло целое и запечатаное без повреждения"
          },
          {
            "word": "повреждения",
            "count": 1,
            "phrase": "все пришло целое и запечатаное без повреждения"
          },
          {
            "word": "попробовали",
            "count": 0,
            "phrase": "попробовали убрать катышки"
          },
          {
            "word": "носила",
            "count": 4,
            "phrase": "которые давно не носила"
          },
          {
            "word": "простыней",
            "count": 1,
            "phrase": "с простыней машинка справилась на ура"
          },
          {
            "word": "фантастическая",
            "count": 1,
            "phrase": "эта фантастическая машинка просто чудо"
          },
          {
            "word": "блузками",
            "count": 1,
            "phrase": "с блузками и даже с безнадежными леггинсами"
          },
          {
            "word": "безнадежными",
            "count": 1,
            "phrase": "с блузками и даже с безнадежными леггинсами"
          },
          {
            "word": "леггинсами",
            "count": 1,
            "phrase": "с блузками и даже с безнадежными леггинсами"
          },
          {
            "word": "убитый",
            "count": 1,
            "phrase": "спасла убитый свитер"
          },
          {
            "word": "потрясающий",
            "count": 0,
            "phrase": "потрясающий аппарат"
          },
          {
            "word": "жалко",
            "count": 4,
            "phrase": "вроде не жалко попробовать"
          },
          {
            "word": "бреет",
            "count": 3,
            "phrase": "машинка бреет одежду"
          },
          {
            "word": "никакие",
            "count": 1,
            "phrase": "зато во время работы провода не нужны никакие"
          },
          {
            "word": "лишних",
            "count": 3,
            "phrase": "тут и лишних слов не нужно"
          },
          {
            "word": "взрослых",
            "count": 1,
            "phrase": "чисто игрушка для взрослых"
          },
          {
            "word": "включаешь",
            "count": 1,
            "phrase": "как включаешь её"
          },
          {
            "word": "крутых",
            "count": 1,
            "phrase": "спасла несколько крутых вещей 👌 рекомендую"
          },
          {
            "word": "вернуть",
            "count": 6,
            "phrase": "что могу вернуть к жизни любимые вещи"
          },
          {
            "word": "капельки",
            "count": 1,
            "phrase": "ни капельки сожаления о приобретении"
          },
          {
            "word": "сожаления",
            "count": 1,
            "phrase": "ни капельки сожаления о приобретении"
          },
          {
            "word": "приобретении",
            "count": 1,
            "phrase": "ни капельки сожаления о приобретении"
          },
          {
            "word": "целостности",
            "count": 3,
            "phrase": "доставлен в целостности"
          },
          {
            "word": "воскресила",
            "count": 1,
            "phrase": "воскресила пальто"
          },
          {
            "word": "которому",
            "count": 2,
            "phrase": "которому больше 5 лет"
          },
          {
            "word": "превращает",
            "count": 1,
            "phrase": "превращает старые вещи в новый вид"
          },
          {
            "word": "избавила",
            "count": 2,
            "phrase": "пальто избавила от катышек"
          },
          {
            "word": "чистится)покупкой",
            "count": 1,
            "phrase": "легко и удобно разбирается и чистится)покупкой довольна"
          },
          {
            "word": "писать",
            "count": 5,
            "phrase": "после получения не стала сразу писать отзыв"
          },
          {
            "word": "подвожу",
            "count": 1,
            "phrase": "итак подвожу итог после 3"
          },
          {
            "word": "дневного",
            "count": 1,
            "phrase": "дневного использования"
          },
          {
            "word": "всеми",
            "count": 3,
            "phrase": "машинка справилась со всеми видами ткани"
          },
          {
            "word": "видами",
            "count": 1,
            "phrase": "машинка справилась со всеми видами ткани"
          },
          {
            "word": "чиститься",
            "count": 1,
            "phrase": "легко чиститься и разбирается"
          },
          {
            "word": "будем",
            "count": 0,
            "phrase": "будем надеяться на долгое сотрудничество с ней"
          },
          {
            "word": "надеяться",
            "count": 1,
            "phrase": "будем надеяться на долгое сотрудничество с ней"
          },
          {
            "word": "долгое",
            "count": 2,
            "phrase": "будем надеяться на долгое сотрудничество с ней"
          },
          {
            "word": "сотрудничество",
            "count": 1,
            "phrase": "будем надеяться на долгое сотрудничество с ней"
          },
          {
            "word": "заряженнным",
            "count": 1,
            "phrase": "пришла с заряженнным аккумулятором"
          },
          {
            "word": "аккумулятором",
            "count": 1,
            "phrase": "пришла с заряженнным аккумулятором"
          },
          {
            "word": "работает!чистит",
            "count": 0,
            "phrase": "все работает!чистит"
          },
          {
            "word": "довольна!!!сразу",
            "count": 0,
            "phrase": "покупкой довольна!!!сразу взяла запасной нож"
          },
          {
            "word": "экономить",
            "count": 1,
            "phrase": "что решила не экономить и взять машинку подороже"
          },
          {
            "word": "подороже",
            "count": 1,
            "phrase": "что решила не экономить и взять машинку подороже"
          },
          {
            "word": "глазах",
            "count": 1,
            "phrase": "вещи преображаются на глазах"
          },
          {
            "word": "домашнем",
            "count": 1,
            "phrase": "в домашнем быту"
          },
          {
            "word": "открутить",
            "count": 1,
            "phrase": "можно открутить крышку где расположена головка с лезвиями"
          },
          {
            "word": "крышку",
            "count": 1,
            "phrase": "можно открутить крышку где расположена головка с лезвиями"
          },
          {
            "word": "расположена",
            "count": 1,
            "phrase": "можно открутить крышку где расположена головка с лезвиями"
          },
          {
            "word": "головка",
            "count": 1,
            "phrase": "можно открутить крышку где расположена головка с лезвиями"
          },
          {
            "word": "специальный",
            "count": 1,
            "phrase": "с целью чистки и снять специальный отсек сбора катышков"
          },
          {
            "word": "торчат",
            "count": 1,
            "phrase": "которые торчат"
          },
          {
            "word": "возвращает",
            "count": 1,
            "phrase": "быстро и легко возвращает к жизни любимые наряды"
          },
          {
            "word": "наряды",
            "count": 1,
            "phrase": "быстро и легко возвращает к жизни любимые наряды"
          },
          {
            "word": "делается",
            "count": 2,
            "phrase": "не за секунду это делается"
          },
          {
            "word": "прадовцу",
            "count": 1,
            "phrase": "спасибо прадовцу"
          },
          {
            "word": "составляющими",
            "count": 1,
            "phrase": "со всеми составляющими"
          },
          {
            "word": "всë",
            "count": 1,
            "phrase": "мне всë очень нравится"
          },
          {
            "word": "школе",
            "count": 1,
            "phrase": "дочка в школе на стульях заработала на штанах катышки"
          },
          {
            "word": "стульях",
            "count": 1,
            "phrase": "дочка в школе на стульях заработала на штанах катышки"
          },
          {
            "word": "заработала",
            "count": 2,
            "phrase": "дочка в школе на стульях заработала на штанах катышки"
          },
          {
            "word": "котором",
            "count": 4,
            "phrase": "в котором верхняя штанина"
          },
          {
            "word": "верхняя",
            "count": 1,
            "phrase": "в котором верхняя штанина"
          },
          {
            "word": "проработана",
            "count": 1,
            "phrase": "не проработана машинкой"
          },
          {
            "word": "летом",
            "count": 0,
            "phrase": "летом выписала на wb кофточку"
          },
          {
            "word": "кофточку",
            "count": 1,
            "phrase": "летом выписала на wb кофточку"
          },
          {
            "word": "стирок",
            "count": 1,
            "phrase": "но после пару стирок появились катышки"
          },
          {
            "word": "естественно",
            "count": 1,
            "phrase": "и вид естественно уже не тот"
          },
          {
            "word": "тот",
            "count": 15,
            "phrase": "и вид естественно уже не тот"
          },
          {
            "word": "безумно",
            "count": 1,
            "phrase": "но выкидывать было жалко так как она безумно нравилась"
          },
          {
            "word": "нравилась",
            "count": 27,
            "phrase": "но выкидывать было жалко так как она безумно нравилась"
          },
          {
            "word": "выписать",
            "count": 1,
            "phrase": "решила выписать эту чудо машинку"
          },
          {
            "word": "вострорг",
            "count": 0,
            "phrase": "вострорг не описать словами"
          },
          {
            "word": "описать",
            "count": 1,
            "phrase": "вострорг не описать словами"
          },
          {
            "word": "словами",
            "count": 2,
            "phrase": "вострорг не описать словами"
          },
          {
            "word": "проблемами",
            "count": 1,
            "phrase": "с проблемами справляется хорошо"
          },
          {
            "word": "щепотка",
            "count": 2,
            "phrase": "щепотка и шнур для зарядки"
          },
          {
            "word": "изумительная",
            "count": 2,
            "phrase": "машинка изумительная"
          },
          {
            "word": "оказались",
            "count": 1,
            "phrase": "отзывы оказались очень полезны"
          },
          {
            "word": "полезны",
            "count": 3,
            "phrase": "отзывы оказались очень полезны"
          },
          {
            "word": "подведёт",
            "count": 1,
            "phrase": "очень нравится пока надеюсь и дальше не подведёт"
          },
          {
            "word": "устройства",
            "count": 4,
            "phrase": "как я раньше жила без этого устройства"
          },
          {
            "word": "эффективности",
            "count": 1,
            "phrase": "всегда сомневалась в эффективности и не решалась купить"
          },
          {
            "word": "решалась",
            "count": 2,
            "phrase": "всегда сомневалась в эффективности и не решалась купить"
          },
          {
            "word": "проблемно",
            "count": 1,
            "phrase": "а это было проблемно"
          },
          {
            "word": "визуально",
            "count": 0,
            "phrase": "визуально очень лаконичная и аккуратная"
          },
          {
            "word": "лаконичная",
            "count": 1,
            "phrase": "визуально очень лаконичная и аккуратная"
          },
          {
            "word": "аккуратная",
            "count": 2,
            "phrase": "визуально очень лаконичная и аккуратная"
          },
          {
            "word": "перерыла",
            "count": 1,
            "phrase": "всё в доме перерыла"
          },
          {
            "word": "испытал",
            "count": 1,
            "phrase": "испытал на свитшотах"
          },
          {
            "word": "свитшотах",
            "count": 1,
            "phrase": "испытал на свитшотах"
          },
          {
            "word": "чтучка",
            "count": 1,
            "phrase": "хорошая чтучка"
          },
          {
            "word": "сделано",
            "count": 2,
            "phrase": "фото сделано с интервалом 30 секунд"
          },
          {
            "word": "интервалом",
            "count": 1,
            "phrase": "фото сделано с интервалом 30 секунд"
          },
          {
            "word": "секунд",
            "count": 7,
            "phrase": "фото сделано с интервалом 30 секунд"
          },
          {
            "word": "собрал",
            "count": 3,
            "phrase": "просто сколько он собрал 😱 фото прилагаю"
          },
          {
            "word": "прохода",
            "count": 1,
            "phrase": "конечно не с одного прохода все сразу убирает"
          },
          {
            "word": "пройти",
            "count": 1,
            "phrase": "надо несколько раз пройти"
          },
          {
            "word": "эффектом",
            "count": 1,
            "phrase": "но эффектом довольна"
          },
          {
            "word": "поносить",
            "count": 1,
            "phrase": "ребёнок в восторге - кофту можно ещё поносить"
          },
          {
            "word": "другую",
            "count": 1,
            "phrase": "до этого заказывала другую"
          },
          {
            "word": "отказалась",
            "count": 1,
            "phrase": "отказалась и купила эту"
          },
          {
            "word": "сравнить",
            "count": 4,
            "phrase": "не сравнить"
          },
          {
            "word": "родную",
            "count": 1,
            "phrase": "зарядку лучше использовать родную"
          },
          {
            "word": "скидке",
            "count": 1,
            "phrase": "во первых по скидке купила за 581"
          },
          {
            "word": "581",
            "count": 1,
            "phrase": "во первых по скидке купила за 581"
          },
          {
            "word": "повторного",
            "count": 1,
            "phrase": "но повторного заказывать не стала"
          },
          {
            "word": "гуляю",
            "count": 1,
            "phrase": "вот решила варежки показать в которых гуляю со своими собаками"
          },
          {
            "word": "собаками",
            "count": 2,
            "phrase": "вот решила варежки показать в которых гуляю со своими собаками"
          },
          {
            "word": "живую",
            "count": 2,
            "phrase": "но в живую страшненько было ахахах"
          },
          {
            "word": "страшненько",
            "count": 1,
            "phrase": "но в живую страшненько было ахахах"
          },
          {
            "word": "ахахах",
            "count": 2,
            "phrase": "но в живую страшненько было ахахах"
          },
          {
            "word": "прогулки",
            "count": 1,
            "phrase": "почистила и они обрели новую жизнь для прогулки с собаками"
          },
          {
            "word": "отнеслась",
            "count": 1,
            "phrase": "сначала отнеслась скептически к этой машинке"
          },
          {
            "word": "вправду",
            "count": 1,
            "phrase": "вещи и вправду как новые"
          },
          {
            "word": "думайте",
            "count": 1,
            "phrase": "даже не думайте"
          },
          {
            "word": "пришлось",
            "count": 2,
            "phrase": "даже заряжать не пришлось"
          },
          {
            "word": "делаю",
            "count": 2,
            "phrase": "редко делаю фотки к отзывам"
          },
          {
            "word": "сложно",
            "count": 5,
            "phrase": "но тут сложно было удержаться"
          },
          {
            "word": "удержаться",
            "count": 1,
            "phrase": "но тут сложно было удержаться"
          },
          {
            "word": "потеряно",
            "count": 1,
            "phrase": "оказалось что не все потеряно"
          },
          {
            "word": "эффектно)",
            "count": 1,
            "phrase": "но выглядит довольно эффектно) 😁 заказала по рекомендации подруги"
          },
          {
            "word": "человеческий",
            "count": 1,
            "phrase": "пришли в человеческий вид"
          },
          {
            "word": "новому",
            "count": 1,
            "phrase": "теперь вся моя одежда будет по новому"
          },
          {
            "word": "портить",
            "count": 2,
            "phrase": "не хочу портить рейтинг продавца"
          },
          {
            "word": "рейтинг",
            "count": 1,
            "phrase": "не хочу портить рейтинг продавца"
          },
          {
            "word": "виноват",
            "count": 2,
            "phrase": "тк он не виноват в доставке товара"
          },
          {
            "word": "ужасное",
            "count": 3,
            "phrase": "а так ужасное отношения к вещам"
          },
          {
            "word": "отношения",
            "count": 1,
            "phrase": "а так ужасное отношения к вещам"
          },
          {
            "word": "валдберис",
            "count": 0,
            "phrase": "валдберис за доставку 1 звезда"
          },
          {
            "word": "звезда",
            "count": 2,
            "phrase": "валдберис за доставку 1 звезда"
          },
          {
            "word": "брал",
            "count": 53,
            "phrase": "брал на подарок"
          },
          {
            "word": "излечим",
            "count": 1,
            "phrase": "к самой излечим претензий нет"
          },
          {
            "word": "потребовалось",
            "count": 1,
            "phrase": "чтобы очистить футболку от катышков потребовалось около 15 минут"
          },
          {
            "word": "инст",
            "count": 44,
            "phrase": "в инст"
          },
          {
            "word": "обзор",
            "count": 2,
            "phrase": "сняла обзор"
          },
          {
            "word": "приходит",
            "count": 5,
            "phrase": "приходит уже заряженной"
          },
          {
            "word": "заряженной",
            "count": 2,
            "phrase": "приходит уже заряженной"
          },
          {
            "word": "опробовать",
            "count": 9,
            "phrase": "сразу удалось опробовать"
          },
          {
            "word": "съела",
            "count": 1,
            "phrase": "все катушки быстро съела"
          },
          {
            "word": "бегать",
            "count": 1,
            "phrase": "а не бегать постоянно за батарейками"
          },
          {
            "word": "батарейками",
            "count": 1,
            "phrase": "а не бегать постоянно за батарейками"
          },
          {
            "word": "аппарате",
            "count": 1,
            "phrase": "давно мечтала об этом аппарате"
          },
          {
            "word": "635",
            "count": 1,
            "phrase": "брала за 635 р"
          },
          {
            "word": "велика",
            "count": 1,
            "phrase": "но не велика потеря"
          },
          {
            "word": "потеря",
            "count": 2,
            "phrase": "но не велика потеря"
          },
          {
            "word": "одном",
            "count": 8,
            "phrase": "жалею только об одном"
          },
          {
            "word": "оставлять",
            "count": 2,
            "phrase": "что будет оставлять затяжки"
          },
          {
            "word": "затяжки",
            "count": 3,
            "phrase": "что будет оставлять затяжки"
          },
          {
            "word": "шапочки",
            "count": 1,
            "phrase": "детские шапочки"
          },
          {
            "word": "даёт",
            "count": 5,
            "phrase": "даёт вещам вторую жизнь"
          },
          {
            "word": "бесполезная",
            "count": 1,
            "phrase": "но просто бесполезная"
          },
          {
            "word": "купленные",
            "count": 1,
            "phrase": "купленные в начале учебного года"
          },
          {
            "word": "начале",
            "count": 2,
            "phrase": "купленные в начале учебного года"
          },
          {
            "word": "учебного",
            "count": 1,
            "phrase": "купленные в начале учебного года"
          },
          {
            "word": "скатанные",
            "count": 1,
            "phrase": "но скатанные до неприличия"
          },
          {
            "word": "неприличия",
            "count": 1,
            "phrase": "но скатанные до неприличия"
          },
          {
            "word": "обязательно",
            "count": 1,
            "phrase": "обязательно купите такую штуку"
          },
          {
            "word": "купите",
            "count": 1,
            "phrase": "обязательно купите такую штуку"
          },
          {
            "word": "крупная",
            "count": 0,
            "phrase": "крупная за счёт этого вещи чистить быстрее в два раза"
          },
          {
            "word": "быстрее",
            "count": 2,
            "phrase": "крупная за счёт этого вещи чистить быстрее в два раза"
          },
          {
            "word": "552р",
            "count": 1,
            "phrase": "преобрела за 552р"
          },
          {
            "word": "посылка",
            "count": 1,
            "phrase": "пришла посылка просто в коробке"
          },
          {
            "word": "помялась",
            "count": 1,
            "phrase": "коробка не помялась и никто ничего не вскрывал"
          },
          {
            "word": "вскрывал",
            "count": 4,
            "phrase": "коробка не помялась и никто ничего не вскрывал"
          },
          {
            "word": "зажать",
            "count": 1,
            "phrase": "ткань зажать никак не сможет"
          },
          {
            "word": "сможет",
            "count": 1,
            "phrase": "ткань зажать никак не сможет"
          },
          {
            "word": "отрезает",
            "count": 1,
            "phrase": "конечно отрезает катышки не прям под корень"
          },
          {
            "word": "корень",
            "count": 1,
            "phrase": "конечно отрезает катышки не прям под корень"
          },
          {
            "word": "немножко",
            "count": 1,
            "phrase": "немножко маленькая нитка остаётся"
          },
          {
            "word": "нитка",
            "count": 1,
            "phrase": "немножко маленькая нитка остаётся"
          },
          {
            "word": "приглядется",
            "count": 1,
            "phrase": "но их видно только если приглядется"
          },
          {
            "word": "особом",
            "count": 2,
            "phrase": "при особом положении на свету"
          },
          {
            "word": "положении",
            "count": 1,
            "phrase": "при особом положении на свету"
          },
          {
            "word": "свету",
            "count": 1,
            "phrase": "при особом положении на свету"
          },
          {
            "word": "меняется",
            "count": 1,
            "phrase": "но вещь меняется до неузнаваемости"
          },
          {
            "word": "неузнаваемости",
            "count": 1,
            "phrase": "но вещь меняется до неузнаваемости"
          },
          {
            "word": "пробывала",
            "count": 2,
            "phrase": "пробывала на разных тканях"
          },
          {
            "word": "тканях",
            "count": 4,
            "phrase": "пробывала на разных тканях"
          },
          {
            "word": "везде",
            "count": 1,
            "phrase": "везде отличный результат"
          },
          {
            "word": "предназначение",
            "count": 2,
            "phrase": "свое предназначение выполняет катышки легко убирает"
          },
          {
            "word": "заряжаться",
            "count": 0,
            "phrase": "заряжаться от тайпси шнур в комплекте имееться"
          },
          {
            "word": "имееться",
            "count": 1,
            "phrase": "заряжаться от тайпси шнур в комплекте имееться"
          },
          {
            "word": "постельное",
            "count": 1,
            "phrase": "очистила постельное белье от катышек и совсем другой вид стал"
          },
          {
            "word": "белье",
            "count": 2,
            "phrase": "очистила постельное белье от катышек и совсем другой вид стал"
          },
          {
            "word": "ползает",
            "count": 1,
            "phrase": "ребёнок ползает"
          },
          {
            "word": "выручает",
            "count": 3,
            "phrase": "машинка оч выручает"
          },
          {
            "word": "мощь",
            "count": 2,
            "phrase": "машинка мощь"
          },
          {
            "word": "подобные",
            "count": 2,
            "phrase": "раньше покупала подобные машинки"
          },
          {
            "word": "нереально",
            "count": 1,
            "phrase": "но эта нереально клевая"
          },
          {
            "word": "клевая",
            "count": 1,
            "phrase": "но эта нереально клевая"
          },
          {
            "word": "ведро",
            "count": 1,
            "phrase": "носок уже хотел в ведро и вот как новый"
          },
          {
            "word": "обожает",
            "count": 1,
            "phrase": "дочь обожает ее"
          },
          {
            "word": "светится",
            "count": 2,
            "phrase": "при работе светится кнопка сзади"
          },
          {
            "word": "сзади",
            "count": 1,
            "phrase": "при работе светится кнопка сзади"
          },
          {
            "word": "смело",
            "count": 3,
            "phrase": "можно смело заказывать"
          },
          {
            "word": "состояние",
            "count": 2,
            "phrase": "на фото состояние рукава до и после"
          },
          {
            "word": "удачных",
            "count": 1,
            "phrase": "одна из моих самых удачных покупок здесь"
          },
          {
            "word": "полгода",
            "count": 1,
            "phrase": "пользуюсь ей почти полгода"
          },
          {
            "word": "показалась",
            "count": 1,
            "phrase": "сначала показалась слабенькой"
          },
          {
            "word": "слабенькой",
            "count": 1,
            "phrase": "сначала показалась слабенькой"
          },
          {
            "word": "убирала",
            "count": 1,
            "phrase": "убирала катышки с детского белого коврика из овечьей шерсти"
          },
          {
            "word": "белого",
            "count": 1,
            "phrase": "убирала катышки с детского белого коврика из овечьей шерсти"
          },
          {
            "word": "коврика",
            "count": 1,
            "phrase": "убирала катышки с детского белого коврика из овечьей шерсти"
          },
          {
            "word": "овечьей",
            "count": 1,
            "phrase": "убирала катышки с детского белого коврика из овечьей шерсти"
          },
          {
            "word": "плохом",
            "count": 1,
            "phrase": "просто коврик был в плохом состоянии"
          },
          {
            "word": "сборка",
            "count": 1,
            "phrase": "отличная сборка"
          },
          {
            "word": "дешевого",
            "count": 1,
            "phrase": "что сделано не из дешевого пластика"
          },
          {
            "word": "языке),зарядный",
            "count": 1,
            "phrase": "только не на русском языке),зарядный шнур(блок нужен свой"
          },
          {
            "word": "шнур(блок",
            "count": 1,
            "phrase": "только не на русском языке),зарядный шнур(блок нужен свой"
          },
          {
            "word": "нужен",
            "count": 1,
            "phrase": "только не на русском языке),зарядный шнур(блок нужен свой"
          },
          {
            "word": "эксплуатировали",
            "count": 1,
            "phrase": "целый час эксплуатировали ее на разных вещах"
          },
          {
            "word": "теплые",
            "count": 1,
            "phrase": "теплые колготки"
          },
          {
            "word": "гладеньким",
            "count": 1,
            "phrase": "все стало гладеньким и чистеньким"
          },
          {
            "word": "чистеньким",
            "count": 1,
            "phrase": "все стало гладеньким и чистеньким"
          },
          {
            "word": "помощнице",
            "count": 1,
            "phrase": "давно мечтала о такой помощнице"
          },
          {
            "word": "нарушена",
            "count": 1,
            "phrase": "упаковка не нарушена"
          },
          {
            "word": "осознанная",
            "count": 1,
            "phrase": "это моя лучшая осознанная покупка"
          },
          {
            "word": "быстренько",
            "count": 1,
            "phrase": "быстренько привела в порядок свою любимую кофту"
          },
          {
            "word": "сравнивать",
            "count": 3,
            "phrase": "машинка правда супер мне есть с чем сравнивать"
          },
          {
            "word": "разработчикам",
            "count": 1,
            "phrase": "спасибо 🙏 разработчикам за такое чудо"
          },
          {
            "word": "еользовалась",
            "count": 1,
            "phrase": "несколько дней еользовалась им и могу скпзпть"
          },
          {
            "word": "скпзпть",
            "count": 1,
            "phrase": "несколько дней еользовалась им и могу скпзпть"
          },
          {
            "word": "хоршо",
            "count": 1,
            "phrase": "что катышки убирает хоршо"
          },
          {
            "word": "большего",
            "count": 1,
            "phrase": "но с большего вещь становится чистая"
          },
          {
            "word": "чистая",
            "count": 1,
            "phrase": "но с большего вещь становится чистая"
          },
          {
            "word": "цели",
            "count": 1,
            "phrase": "каждый берёт её под свои цели"
          },
          {
            "word": "побрито",
            "count": 1,
            "phrase": "но сейчас будет побрито в доме всё"
          },
          {
            "word": "уважаемый",
            "count": 0,
            "phrase": "уважаемый продавец"
          },
          {
            "word": "удачи",
            "count": 1,
            "phrase": "спасибо за скидки и удачи вам в вашем бизнесе"
          },
          {
            "word": "вашем",
            "count": 1,
            "phrase": "спасибо за скидки и удачи вам в вашем бизнесе"
          },
          {
            "word": "бизнесе",
            "count": 1,
            "phrase": "спасибо за скидки и удачи вам в вашем бизнесе"
          },
          {
            "word": "переставая",
            "count": 1,
            "phrase": "машинка жужжит не переставая уже часа два 😀 кто сомневатется"
          },
          {
            "word": "сомневатется",
            "count": 1,
            "phrase": "машинка жужжит не переставая уже часа два 😀 кто сомневатется"
          },
          {
            "word": "заказа",
            "count": 23,
            "phrase": "а потом заказа себе"
          },
          {
            "word": "люблю",
            "count": 1,
            "phrase": "очень люблю бренд xiaomi"
          },
          {
            "word": "бренд",
            "count": 3,
            "phrase": "очень люблю бренд xiaomi"
          },
          {
            "word": "диаметр",
            "count": 3,
            "phrase": "большой диаметр"
          },
          {
            "word": "запечатан",
            "count": 6,
            "phrase": "пришёл запечатан"
          },
          {
            "word": "проверено",
            "count": 2,
            "phrase": "на пункте проверено"
          },
          {
            "word": "эксплуатируется",
            "count": 1,
            "phrase": "дома уже во всю эксплуатируется"
          },
          {
            "word": "крутой",
            "count": 0,
            "phrase": "крутой аппарат 👌 с катышками борется на ура"
          },
          {
            "word": "борется",
            "count": 1,
            "phrase": "крутой аппарат 👌 с катышками борется на ура"
          },
          {
            "word": "полного",
            "count": 1,
            "phrase": "после полного распоряжения и полной зарядки"
          },
          {
            "word": "распоряжения",
            "count": 1,
            "phrase": "после полного распоряжения и полной зарядки"
          },
          {
            "word": "заявленные",
            "count": 1,
            "phrase": "а не заявленные 1,5 часа"
          },
          {
            "word": "1,5",
            "count": 3,
            "phrase": "а не заявленные 1,5 часа"
          },
          {
            "word": "сложнее",
            "count": 1,
            "phrase": "их сложнее зацепить"
          },
          {
            "word": "зацепить",
            "count": 1,
            "phrase": "их сложнее зацепить"
          },
          {
            "word": "закрывающая",
            "count": 1,
            "phrase": "закрывающая лезвия довольно жесткая и не прогибается"
          },
          {
            "word": "жесткая",
            "count": 1,
            "phrase": "закрывающая лезвия довольно жесткая и не прогибается"
          },
          {
            "word": "прогибается",
            "count": 1,
            "phrase": "закрывающая лезвия довольно жесткая и не прогибается"
          },
          {
            "word": "бюджетных",
            "count": 1,
            "phrase": "как обычно на сильно бюджетных машинках"
          },
          {
            "word": "машинках",
            "count": 1,
            "phrase": "как обычно на сильно бюджетных машинках"
          },
          {
            "word": "произойти",
            "count": 1,
            "phrase": "что могло со мной произойти"
          },
          {
            "word": "шторы",
            "count": 1,
            "phrase": "и даже шторы которые кот подрал"
          },
          {
            "word": "подрал",
            "count": 1,
            "phrase": "и даже шторы которые кот подрал"
          },
          {
            "word": "стыдная",
            "count": 1,
            "phrase": "не стыдная по внешнему виду"
          },
          {
            "word": "внешнему",
            "count": 2,
            "phrase": "не стыдная по внешнему виду"
          },
          {
            "word": "виду",
            "count": 3,
            "phrase": "не стыдная по внешнему виду"
          },
          {
            "word": "приспособа",
            "count": 1,
            "phrase": "отличная приспособа"
          },
          {
            "word": "обращении",
            "count": 1,
            "phrase": "простая в обращении вещь"
          },
          {
            "word": "превосходно",
            "count": 1,
            "phrase": "чистит превосходно"
          },
          {
            "word": "огромными",
            "count": 1,
            "phrase": "справляется даже с огромными катышками"
          },
          {
            "word": "гаджету",
            "count": 1,
            "phrase": "раньше относилась к этому гаджету"
          },
          {
            "word": "очередному",
            "count": 1,
            "phrase": "как к очередному бесполезному прибору в доме"
          },
          {
            "word": "бесполезному",
            "count": 1,
            "phrase": "как к очередному бесполезному прибору в доме"
          },
          {
            "word": "сын",
            "count": 4,
            "phrase": "но сын настоял купить"
          },
          {
            "word": "настоял",
            "count": 1,
            "phrase": "но сын настоял купить"
          },
          {
            "word": "стоящая",
            "count": 1,
            "phrase": "и правда стоящая вещь оказалась"
          },
          {
            "word": "прослужил",
            "count": 1,
            "phrase": "чтоб долго прослужил"
          },
          {
            "word": "выкинула",
            "count": 1,
            "phrase": "сколько уже выкинула разных машинок"
          },
          {
            "word": "приобретений",
            "count": 1,
            "phrase": "одно из лучших приобретений"
          },
          {
            "word": "засасывает",
            "count": 0,
            "phrase": "засасывает и обрезает"
          },
          {
            "word": "обрезает",
            "count": 1,
            "phrase": "засасывает и обрезает"
          },
          {
            "word": "телефонной",
            "count": 1,
            "phrase": "заряжается от телефонной зарядки"
          },
          {
            "word": "устраивала",
            "count": 1,
            "phrase": "уже пару раз устраивала чистку вещей и ещё не разрядилась"
          },
          {
            "word": "чистку",
            "count": 5,
            "phrase": "уже пару раз устраивала чистку вещей и ещё не разрядилась"
          },
          {
            "word": "исполнения",
            "count": 1,
            "phrase": "качество исполнения безупречное"
          },
          {
            "word": "безупречное",
            "count": 1,
            "phrase": "качество исполнения безупречное"
          },
          {
            "word": "обработанный",
            "count": 1,
            "phrase": "один обработанный"
          },
          {
            "word": "супруге",
            "count": 0,
            "phrase": "супруге понравилась"
          },
          {
            "word": "впечатлила",
            "count": 1,
            "phrase": "как впечатлила меня с первого раза"
          },
          {
            "word": "недолго",
            "count": 1,
            "phrase": "заряжается недолго"
          },
          {
            "word": "старая",
            "count": 0,
            "phrase": "старая дешевая машинка только распушила катышки"
          },
          {
            "word": "дешевая",
            "count": 2,
            "phrase": "старая дешевая машинка только распушила катышки"
          },
          {
            "word": "распушила",
            "count": 1,
            "phrase": "старая дешевая машинка только распушила катышки"
          },
          {
            "word": "штучку",
            "count": 1,
            "phrase": "спасибо за эту чудесную штучку"
          },
          {
            "word": "дорого",
            "count": 2,
            "phrase": "очень дорого убирает катышки"
          },
          {
            "word": "область",
            "count": 1,
            "phrase": "за минуту очистила область на фото"
          },
          {
            "word": "кашемир",
            "count": 1,
            "phrase": "ткань - шерсть и кашемир"
          },
          {
            "word": "цело",
            "count": 43,
            "phrase": "как видите все цело"
          },
          {
            "word": "дерёт",
            "count": 1,
            "phrase": "ничего не дерёт"
          },
          {
            "word": "аналоги",
            "count": 2,
            "phrase": "как другие аналоги"
          },
          {
            "word": "наша",
            "count": 1,
            "phrase": "вся наша семья в восторге"
          },
          {
            "word": "ладони",
            "count": 1,
            "phrase": "в ладони умещается"
          },
          {
            "word": "умещается",
            "count": 1,
            "phrase": "в ладони умещается"
          },
          {
            "word": "классную",
            "count": 1,
            "phrase": "спасибо большое за такую классную вещь"
          },
          {
            "word": "вернул",
            "count": 5,
            "phrase": "вернул брюкам внешний вид"
          },
          {
            "word": "брюкам",
            "count": 1,
            "phrase": "вернул брюкам внешний вид"
          },
          {
            "word": "выполнил",
            "count": 2,
            "phrase": "свою функцию выполнил уже"
          },
          {
            "word": "костюме",
            "count": 1,
            "phrase": "легко справилась с катышками на костюме"
          },
          {
            "word": "срезала",
            "count": 1,
            "phrase": "на детских штанах срезала все катышки"
          },
          {
            "word": "повреждая",
            "count": 1,
            "phrase": "удаляет хорошо катышки не повреждая ткань"
          },
          {
            "word": "зарядным",
            "count": 2,
            "phrase": "а здесь с зарядным кабелем"
          },
          {
            "word": "кабелем",
            "count": 1,
            "phrase": "а здесь с зарядным кабелем"
          },
          {
            "word": "практично",
            "count": 1,
            "phrase": "очень практично и эффективно"
          }
        ]
      },
      "feedbacks_answer": 28,
      "valuation_distribution": {
        "1": 2,
        "2": 0,
        "3": 1,
        "4": 3,
        "5": 94
      },
      "increase": 5,
      "review_on_sales": 24,
      "increase_top_wb": 2,
      "review_on_sales_top_wb_items": 0
    },
    {
      "title": "Кроссовки спортивные для бега",
      "cover": '/icons/sneakers.jpg',
      "sku": "152823311",
      "price": 3273,
      "period_analysis": 30,
      "average_rate": "4.7",
      "feedbacks_count": 769,
      "feedbacks_photo": 119,
      "feedbacks_stats": {
        "1": [
          {
            "word": "осмотре",
            "count": 1,
            "phrase": "при осмотре брака не выявлено в пункте выдачи"
          },
          {
            "word": "выявлено",
            "count": 1,
            "phrase": "при осмотре брака не выявлено в пункте выдачи"
          },
          {
            "word": "первом",
            "count": 1,
            "phrase": "при первом же включении дома остался негатив"
          },
          {
            "word": "включении",
            "count": 1,
            "phrase": "при первом же включении дома остался негатив"
          },
          {
            "word": "остался",
            "count": 1,
            "phrase": "при первом же включении дома остался негатив"
          },
          {
            "word": "негатив",
            "count": 1,
            "phrase": "при первом же включении дома остался негатив"
          },
          {
            "word": "потрачены",
            "count": 1,
            "phrase": "деньги потрачены впустую"
          },
          {
            "word": "впустую",
            "count": 1,
            "phrase": "деньги потрачены впустую"
          },
          {
            "word": "естественно",
            "count": 1,
            "phrase": "естественно пылесос пришлось выключить"
          },
          {
            "word": "пришлось",
            "count": 1,
            "phrase": "естественно пылесос пришлось выключить"
          },
          {
            "word": "выключить",
            "count": 1,
            "phrase": "естественно пылесос пришлось выключить"
          },
          {
            "word": "заявлено",
            "count": 1,
            "phrase": "хотя в описании заявлено"
          },
          {
            "word": "готов",
            "count": 2,
            "phrase": "что готов к длительной работе"
          },
          {
            "word": "длительной",
            "count": 1,
            "phrase": "что готов к длительной работе"
          },
          {
            "word": "получить",
            "count": 1,
            "phrase": "хотелось бы вернуть товар и получить возврат уплаченных денег"
          },
          {
            "word": "уплаченных",
            "count": 1,
            "phrase": "хотелось бы вернуть товар и получить возврат уплаченных денег"
          },
          {
            "word": "прсле",
            "count": 0,
            "phrase": "прсле 5 - 7 минут работы двигателя стал горячим"
          },
          {
            "word": "двигателя",
            "count": 1,
            "phrase": "прсле 5 - 7 минут работы двигателя стал горячим"
          },
          {
            "word": "стал",
            "count": 14,
            "phrase": "прсле 5 - 7 минут работы двигателя стал горячим"
          },
          {
            "word": "горячим",
            "count": 1,
            "phrase": "прсле 5 - 7 минут работы двигателя стал горячим"
          },
          {
            "word": "полисос",
            "count": 1,
            "phrase": "полисос некачественный"
          },
          {
            "word": "некачественный",
            "count": 1,
            "phrase": "полисос некачественный"
          },
          {
            "word": "полисосить",
            "count": 1,
            "phrase": "ковер полисосить не возможно"
          },
          {
            "word": "никак",
            "count": 2,
            "phrase": "а вертикально никак"
          },
          {
            "word": "детки",
            "count": 1,
            "phrase": "нет детки с ворсом"
          },
          {
            "word": "мама",
            "count": 1,
            "phrase": "у меня мама проживает одна"
          },
          {
            "word": "проживает",
            "count": 1,
            "phrase": "у меня мама проживает одна"
          },
          {
            "word": "одна",
            "count": 3,
            "phrase": "у меня мама проживает одна"
          },
          {
            "word": "кошек",
            "count": 0,
            "phrase": "кошек собак нет"
          },
          {
            "word": "ниточки",
            "count": 1,
            "phrase": "но с ковра этот пылесос не собирает ни ниточки"
          },
          {
            "word": "возврате",
            "count": 1,
            "phrase": "в возврате отказали"
          },
          {
            "word": "отказали",
            "count": 1,
            "phrase": "в возврате отказали"
          },
          {
            "word": "серьезно",
            "count": 1,
            "phrase": "ну вы серьезно"
          },
          {
            "word": "которые",
            "count": 1,
            "phrase": "которые пишут хорошие отзывы"
          },
          {
            "word": "пишут",
            "count": 2,
            "phrase": "которые пишут хорошие отзывы"
          },
          {
            "word": "нифига",
            "count": 0,
            "phrase": "нифига не тянет"
          },
          {
            "word": "игрушечный",
            "count": 1,
            "phrase": "как игрушечный"
          },
          {
            "word": "ребёнка",
            "count": 1,
            "phrase": "у ребёнка на батарейках тяга больше"
          },
          {
            "word": "батарейках",
            "count": 1,
            "phrase": "у ребёнка на батарейках тяга больше"
          },
          {
            "word": "верните",
            "count": 0,
            "phrase": "верните деньги"
          },
          {
            "word": "связеться",
            "count": 1,
            "phrase": "как связеться с продавцом"
          },
          {
            "word": "продавцом",
            "count": 1,
            "phrase": "как связеться с продавцом"
          }
        ],
        "2": [
          {
            "word": "вопросов",
            "count": 1,
            "phrase": "вопросов нет"
          },
          {
            "word": "двигатель",
            "count": 1,
            "phrase": "греется очень быстро двигатель"
          },
          {
            "word": "пары",
            "count": 1,
            "phrase": "больше пары минут работы раскаляется"
          },
          {
            "word": "работы",
            "count": 2,
            "phrase": "больше пары минут работы раскаляется"
          },
          {
            "word": "раскаляется",
            "count": 1,
            "phrase": "больше пары минут работы раскаляется"
          },
          {
            "word": "вижу",
            "count": 1,
            "phrase": "но такое я вижу впервые"
          },
          {
            "word": "впервые",
            "count": 2,
            "phrase": "но такое я вижу впервые"
          },
          {
            "word": "эгидой",
            "count": 1,
            "phrase": "при этом идёт под эгидой xiaomi"
          },
          {
            "word": "xiaomi",
            "count": 1,
            "phrase": "при этом идёт под эгидой xiaomi"
          },
          {
            "word": "подделка",
            "count": 1,
            "phrase": "что мне пришла подделка"
          },
          {
            "word": "оригинал",
            "count": 1,
            "phrase": "а не оригинал"
          },
          {
            "word": "красивым",
            "count": 1,
            "phrase": "у всех на фото он выглядит красивым"
          },
          {
            "word": "довольны",
            "count": 1,
            "phrase": "все очень довольны"
          },
          {
            "word": "трудно",
            "count": 2,
            "phrase": "и он очень трудно собирается"
          },
          {
            "word": "примерить",
            "count": 1,
            "phrase": "надо примерить усилие"
          },
          {
            "word": "усилие",
            "count": 1,
            "phrase": "надо примерить усилие"
          },
          {
            "word": "вниз",
            "count": 1,
            "phrase": "а чуть вниз"
          },
          {
            "word": "отпал",
            "count": 1,
            "phrase": "а ещё 2 раза отпал стакан"
          },
          {
            "word": "стакан",
            "count": 3,
            "phrase": "а ещё 2 раза отпал стакан"
          },
          {
            "word": "сломалось",
            "count": 1,
            "phrase": "и сломалось крепление от удара"
          },
          {
            "word": "удара",
            "count": 1,
            "phrase": "и сломалось крепление от удара"
          },
          {
            "word": "прищелкивании",
            "count": 1,
            "phrase": "он держится на прищелкивании"
          },
          {
            "word": "deerma",
            "count": 1,
            "phrase": "модель deerma единственное"
          },
          {
            "word": "единственное",
            "count": 0,
            "phrase": "модель deerma единственное"
          },
          {
            "word": "большие",
            "count": 1,
            "phrase": "у меня же большие стыки между соединениями"
          },
          {
            "word": "стыки",
            "count": 1,
            "phrase": "у меня же большие стыки между соединениями"
          },
          {
            "word": "соединениями",
            "count": 1,
            "phrase": "у меня же большие стыки между соединениями"
          },
          {
            "word": "сказано",
            "count": 1,
            "phrase": "но в описании сказано"
          },
          {
            "word": "залезет",
            "count": 2,
            "phrase": "что залезет во все щели"
          },
          {
            "word": "стандартную",
            "count": 1,
            "phrase": "это про стандартную насадку"
          },
          {
            "word": "щелевую",
            "count": 1,
            "phrase": "именно щелевую насадку хотелось бы использовать для генеральной уборки"
          },
          {
            "word": "генеральной",
            "count": 1,
            "phrase": "именно щелевую насадку хотелось бы использовать для генеральной уборки"
          },
          {
            "word": "хреначишь",
            "count": 1,
            "phrase": "хреначишь в 2 насадки чуть ли не через день"
          },
          {
            "word": "опоры",
            "count": 1,
            "phrase": "еще минус - нет своей опоры"
          },
          {
            "word": "поставить",
            "count": 1,
            "phrase": "чтобы поставить"
          },
          {
            "word": "облокотить",
            "count": 1,
            "phrase": "нужно на что то облокотить"
          },
          {
            "word": "неудобно",
            "count": 1,
            "phrase": "что очень неудобно"
          },
          {
            "word": "упасть",
            "count": 1,
            "phrase": "пылесос может упасть"
          },
          {
            "word": "минусу",
            "count": 1,
            "phrase": "плюс к минусу - хлипкий пластик"
          },
          {
            "word": "терпимо",
            "count": 1,
            "phrase": "первые три минуты терпимо пылесосит"
          },
          {
            "word": "чуда",
            "count": 1,
            "phrase": "чуда за 3т не ожидала"
          },
          {
            "word": "каждые",
            "count": 1,
            "phrase": "но каждые три минуты отключается"
          },
          {
            "word": "отключается",
            "count": 1,
            "phrase": "но каждые три минуты отключается"
          },
          {
            "word": "ждать",
            "count": 2,
            "phrase": "надо ждать пока мотор охладится"
          },
          {
            "word": "мотор",
            "count": 1,
            "phrase": "надо ждать пока мотор охладится"
          },
          {
            "word": "охладится",
            "count": 1,
            "phrase": "надо ждать пока мотор охладится"
          },
          {
            "word": "снова",
            "count": 1,
            "phrase": "и потом снова пылесосить три минуты"
          },
          {
            "word": "помогу",
            "count": 1,
            "phrase": "не помогу понять как почистить пылесос"
          },
          {
            "word": "понять",
            "count": 1,
            "phrase": "не помогу понять как почистить пылесос"
          },
          {
            "word": "открутить",
            "count": 1,
            "phrase": "как открутить пылесборник"
          },
          {
            "word": "маленькая",
            "count": 0,
            "phrase": "маленькая мощьность"
          },
          {
            "word": "мощьность",
            "count": 1,
            "phrase": "маленькая мощьность"
          },
          {
            "word": "хлюпенький",
            "count": 1,
            "phrase": "какой - то хлюпенький"
          }
        ],
        "3": [
          {
            "word": "насадку",
            "count": 5,
            "phrase": "если бы через насадку для пола всасывал с первого раза"
          },
          {
            "word": "всасывал",
            "count": 1,
            "phrase": "если бы через насадку для пола всасывал с первого раза"
          },
          {
            "word": "проходится",
            "count": 1,
            "phrase": "приходится проходится несколько раз"
          },
          {
            "word": "матрас",
            "count": 2,
            "phrase": "пылесосила им матрас"
          },
          {
            "word": "здесь",
            "count": 1,
            "phrase": "здесь все на отлично"
          },
          {
            "word": "другу",
            "count": 2,
            "phrase": "но использовала при этом другу насадку"
          },
          {
            "word": "усилия",
            "count": 1,
            "phrase": "немного больше нужно усилия"
          },
          {
            "word": "отстегнуть",
            "count": 1,
            "phrase": "что бы отстегнуть насадки"
          },
          {
            "word": "выбрала",
            "count": 0,
            "phrase": "выбрала данную модель только потому что родители покупали такую же"
          },
          {
            "word": "данную",
            "count": 1,
            "phrase": "выбрала данную модель только потому что родители покупали такую же"
          },
          {
            "word": "родители",
            "count": 1,
            "phrase": "выбрала данную модель только потому что родители покупали такую же"
          },
          {
            "word": "покупали",
            "count": 1,
            "phrase": "выбрала данную модель только потому что родители покупали такую же"
          },
          {
            "word": "черного",
            "count": 1,
            "phrase": "только черного цвета"
          },
          {
            "word": "цвета",
            "count": 1,
            "phrase": "только черного цвета"
          },
          {
            "word": "наденьте",
            "count": 1,
            "phrase": "наденьте капроновую калготену"
          },
          {
            "word": "капроновую",
            "count": 1,
            "phrase": "наденьте капроновую калготену"
          },
          {
            "word": "калготену",
            "count": 1,
            "phrase": "наденьте капроновую калготену"
          },
          {
            "word": "помогает",
            "count": 1,
            "phrase": "очень помогает"
          },
          {
            "word": "комплектность",
            "count": 1,
            "phrase": "не вся комплектность"
          },
          {
            "word": "нехватает",
            "count": 1,
            "phrase": "нехватает насадки для пола"
          },
          {
            "word": "поэтому",
            "count": 1,
            "phrase": "поэтому не могу оценить в полном объеме"
          },
          {
            "word": "могу",
            "count": 2,
            "phrase": "поэтому не могу оценить в полном объеме"
          },
          {
            "word": "оценить",
            "count": 1,
            "phrase": "поэтому не могу оценить в полном объеме"
          },
          {
            "word": "полном",
            "count": 1,
            "phrase": "поэтому не могу оценить в полном объеме"
          },
          {
            "word": "объеме",
            "count": 1,
            "phrase": "поэтому не могу оценить в полном объеме"
          },
          {
            "word": "могли",
            "count": 1,
            "phrase": "не могли бы мне прислать эту щётку"
          },
          {
            "word": "прислать",
            "count": 1,
            "phrase": "не могли бы мне прислать эту щётку"
          },
          {
            "word": "щётку",
            "count": 1,
            "phrase": "не могли бы мне прислать эту щётку"
          },
          {
            "word": "поняла",
            "count": 1,
            "phrase": "не поняла восхищенных отзывов"
          },
          {
            "word": "восхищенных",
            "count": 1,
            "phrase": "не поняла восхищенных отзывов"
          },
          {
            "word": "веник",
            "count": 5,
            "phrase": "веник от розетки"
          },
          {
            "word": "розетки",
            "count": 1,
            "phrase": "веник от розетки"
          },
          {
            "word": "скользит",
            "count": 1,
            "phrase": "щетка для пола скользит на колесиках без ворса"
          },
          {
            "word": "колесиках",
            "count": 1,
            "phrase": "щетка для пола скользит на колесиках без ворса"
          },
          {
            "word": "высыпается",
            "count": 1,
            "phrase": "мусор высыпается"
          },
          {
            "word": "удаётся",
            "count": 1,
            "phrase": "все пропылесосить не удаётся чисто"
          },
          {
            "word": "почистить",
            "count": 2,
            "phrase": "чтобы почистить небольшой коврик сменила насадку с щетиной"
          },
          {
            "word": "сменила",
            "count": 1,
            "phrase": "чтобы почистить небольшой коврик сменила насадку с щетиной"
          },
          {
            "word": "щетиной",
            "count": 1,
            "phrase": "чтобы почистить небольшой коврик сменила насадку с щетиной"
          },
          {
            "word": "хрень",
            "count": 1,
            "phrase": "вот эту хрень и не сдать теперь"
          },
          {
            "word": "сдать",
            "count": 1,
            "phrase": "вот эту хрень и не сдать теперь"
          },
          {
            "word": "здоров",
            "count": 3,
            "phrase": "что здоров кого"
          },
          {
            "word": "заявленная",
            "count": 1,
            "phrase": "мощность заявленная всасывания не соответствует"
          },
          {
            "word": "явно",
            "count": 1,
            "phrase": "явно около 200,может 250 максимум"
          },
          {
            "word": "200,может",
            "count": 1,
            "phrase": "явно около 200,может 250 максимум"
          },
          {
            "word": "250",
            "count": 1,
            "phrase": "явно около 200,может 250 максимум"
          },
          {
            "word": "качественного",
            "count": 1,
            "phrase": "но для качественного сбора мусора и пыли с ковра"
          },
          {
            "word": "сбора",
            "count": 3,
            "phrase": "но для качественного сбора мусора и пыли с ковра"
          },
          {
            "word": "бесполезна",
            "count": 1,
            "phrase": "мебели это вещь бесполезна"
          },
          {
            "word": "первых",
            "count": 1,
            "phrase": "во - первых нет щётки для сбора волос"
          },
          {
            "word": "щётки",
            "count": 1,
            "phrase": "во - первых нет щётки для сбора волос"
          },
          {
            "word": "волос",
            "count": 14,
            "phrase": "во - первых нет щётки для сбора волос"
          },
          {
            "word": "вторых",
            "count": 1,
            "phrase": "во - вторых щётка крутится во все стороны"
          },
          {
            "word": "машину",
            "count": 1,
            "phrase": "пылесосить машину внутри"
          },
          {
            "word": "какао",
            "count": 1,
            "phrase": "самое какао"
          },
          {
            "word": "проводкой",
            "count": 1,
            "phrase": "уже проводкой завонял"
          },
          {
            "word": "завонял",
            "count": 1,
            "phrase": "уже проводкой завонял"
          },
          {
            "word": "потом",
            "count": 4,
            "phrase": "а потом уже движок греется"
          },
          {
            "word": "движок",
            "count": 1,
            "phrase": "а потом уже движок греется"
          },
          {
            "word": "греется",
            "count": 3,
            "phrase": "а потом уже движок греется"
          },
          {
            "word": "недели",
            "count": 2,
            "phrase": "3 недели"
          }
        ],
        "4": [
          {
            "word": "остальному",
            "count": 1,
            "phrase": "к остальному претензий нет"
          },
          {
            "word": "прикрепляю",
            "count": 1,
            "phrase": "фото прикрепляю"
          },
          {
            "word": "внешне",
            "count": 0,
            "phrase": "внешне есть кое - какие замечания"
          },
          {
            "word": "кое",
            "count": 8,
            "phrase": "внешне есть кое - какие замечания"
          },
          {
            "word": "какие",
            "count": 1,
            "phrase": "внешне есть кое - какие замечания"
          },
          {
            "word": "замечания",
            "count": 1,
            "phrase": "внешне есть кое - какие замечания"
          },
          {
            "word": "впечатления",
            "count": 0,
            "phrase": "впечатления нормальное"
          },
          {
            "word": "нормальное",
            "count": 1,
            "phrase": "впечатления нормальное"
          },
          {
            "word": "режим",
            "count": 1,
            "phrase": "один режим"
          },
          {
            "word": "труба",
            "count": 2,
            "phrase": "труба заходит и снимается с трудом"
          },
          {
            "word": "заходит",
            "count": 1,
            "phrase": "труба заходит и снимается с трудом"
          },
          {
            "word": "трудом",
            "count": 1,
            "phrase": "труба заходит и снимается с трудом"
          },
          {
            "word": "хотелось",
            "count": 2,
            "phrase": "хотелось бы на насадке щетку"
          },
          {
            "word": "щетку",
            "count": 1,
            "phrase": "хотелось бы на насадке щетку"
          },
          {
            "word": "окуратную",
            "count": 1,
            "phrase": "спасибо производителю и wb за окуратную доставку"
          },
          {
            "word": "доставлен",
            "count": 1,
            "phrase": "пылесос доставлен в заводской упаковке"
          },
          {
            "word": "упаковке",
            "count": 1,
            "phrase": "пылесос доставлен в заводской упаковке"
          },
          {
            "word": "опробован",
            "count": 1,
            "phrase": "опробован 2 раза"
          },
          {
            "word": "оценку",
            "count": 0,
            "phrase": "оценку снизила за хрупкость соединений мусорного контейнера к основной части"
          },
          {
            "word": "снизила",
            "count": 1,
            "phrase": "оценку снизила за хрупкость соединений мусорного контейнера к основной части"
          },
          {
            "word": "хрупкость",
            "count": 1,
            "phrase": "оценку снизила за хрупкость соединений мусорного контейнера к основной части"
          },
          {
            "word": "соединений",
            "count": 1,
            "phrase": "оценку снизила за хрупкость соединений мусорного контейнера к основной части"
          },
          {
            "word": "мусорного",
            "count": 1,
            "phrase": "оценку снизила за хрупкость соединений мусорного контейнера к основной части"
          },
          {
            "word": "основной",
            "count": 2,
            "phrase": "оценку снизила за хрупкость соединений мусорного контейнера к основной части"
          },
          {
            "word": "части",
            "count": 1,
            "phrase": "оценку снизила за хрупкость соединений мусорного контейнера к основной части"
          },
          {
            "word": "слабый",
            "count": 2,
            "phrase": "слабый намотать невозможно провод на него"
          },
          {
            "word": "намотать",
            "count": 1,
            "phrase": "слабый намотать невозможно провод на него"
          },
          {
            "word": "невозможно",
            "count": 1,
            "phrase": "слабый намотать невозможно провод на него"
          },
          {
            "word": "держится",
            "count": 2,
            "phrase": "он просто не держится на самом пылесосе"
          },
          {
            "word": "самом",
            "count": 1,
            "phrase": "он просто не держится на самом пылесосе"
          },
          {
            "word": "снимаю",
            "count": 1,
            "phrase": "только за это снимаю звезду"
          },
          {
            "word": "звезду",
            "count": 1,
            "phrase": "только за это снимаю звезду"
          },
          {
            "word": "функцией",
            "count": 2,
            "phrase": "с функцией уборки справляется хорошо"
          },
          {
            "word": "шумноват",
            "count": 1,
            "phrase": "немного шумноват"
          },
          {
            "word": "30’кв",
            "count": 1,
            "phrase": "на 30’кв кв мне хватило"
          },
          {
            "word": "потому",
            "count": 2,
            "phrase": "только потому что сделано все не качественно"
          },
          {
            "word": "ковром",
            "count": 1,
            "phrase": "с ковром не справляется"
          },
          {
            "word": "безворсовый",
            "count": 1,
            "phrase": "если только безворсовый"
          },
          {
            "word": "приемлимо",
            "count": 1,
            "phrase": "однако за такую цену приемлимо"
          },
          {
            "word": "крутиться",
            "count": 1,
            "phrase": "но щётка крутиться"
          },
          {
            "word": "сойдет",
            "count": 1,
            "phrase": "но сойдет"
          }
        ],
        "5": [
          {
            "word": "мощный",
            "count": 46,
            "phrase": "мощный пылесос"
          },
          {
            "word": "пылесос",
            "count": 189,
            "phrase": "мощный пылесос"
          },
          {
            "word": "собаки",
            "count": 3,
            "phrase": "у собаки началась страшная линька и переживала"
          },
          {
            "word": "началась",
            "count": 1,
            "phrase": "у собаки началась страшная линька и переживала"
          },
          {
            "word": "страшная",
            "count": 1,
            "phrase": "у собаки началась страшная линька и переживала"
          },
          {
            "word": "линька",
            "count": 1,
            "phrase": "у собаки началась страшная линька и переживала"
          },
          {
            "word": "переживала",
            "count": 1,
            "phrase": "у собаки началась страшная линька и переживала"
          },
          {
            "word": "паласы",
            "count": 1,
            "phrase": "что паласы будут слишком для такого мини пылесоса"
          },
          {
            "word": "будут",
            "count": 1,
            "phrase": "что паласы будут слишком для такого мини пылесоса"
          },
          {
            "word": "слишком",
            "count": 2,
            "phrase": "что паласы будут слишком для такого мини пылесоса"
          },
          {
            "word": "такого",
            "count": 3,
            "phrase": "что паласы будут слишком для такого мини пылесоса"
          },
          {
            "word": "мини",
            "count": 3,
            "phrase": "что паласы будут слишком для такого мини пылесоса"
          },
          {
            "word": "пылесоса",
            "count": 7,
            "phrase": "что паласы будут слишком для такого мини пылесоса"
          },
          {
            "word": "справился",
            "count": 2,
            "phrase": "справился идеально"
          },
          {
            "word": "идеально",
            "count": 0,
            "phrase": "справился идеально"
          },
          {
            "word": "пока",
            "count": 14,
            "phrase": "и пока изучала отзывы"
          },
          {
            "word": "изучала",
            "count": 1,
            "phrase": "и пока изучала отзывы"
          },
          {
            "word": "отзывы",
            "count": 5,
            "phrase": "и пока изучала отзывы"
          },
          {
            "word": "нашла",
            "count": 1,
            "phrase": "нашла отзыв девушки"
          },
          {
            "word": "отзыв",
            "count": 9,
            "phrase": "нашла отзыв девушки"
          },
          {
            "word": "девушки",
            "count": 1,
            "phrase": "нашла отзыв девушки"
          },
          {
            "word": "которая",
            "count": 3,
            "phrase": "которая рассказала о капроновом носке"
          },
          {
            "word": "рассказала",
            "count": 1,
            "phrase": "которая рассказала о капроновом носке"
          },
          {
            "word": "капроновом",
            "count": 1,
            "phrase": "которая рассказала о капроновом носке"
          },
          {
            "word": "носке",
            "count": 1,
            "phrase": "которая рассказала о капроновом носке"
          },
          {
            "word": "фильтр",
            "count": 8,
            "phrase": "что бы фильтр прослужил дольше и меньше забивался"
          },
          {
            "word": "прослужил",
            "count": 1,
            "phrase": "что бы фильтр прослужил дольше и меньше забивался"
          },
          {
            "word": "дольше",
            "count": 1,
            "phrase": "что бы фильтр прослужил дольше и меньше забивался"
          },
          {
            "word": "меньше",
            "count": 4,
            "phrase": "что бы фильтр прослужил дольше и меньше забивался"
          },
          {
            "word": "забивался",
            "count": 1,
            "phrase": "что бы фильтр прослужил дольше и меньше забивался"
          },
          {
            "word": "первого",
            "count": 2,
            "phrase": "с первого же применения натянула носок и реально"
          },
          {
            "word": "применения",
            "count": 2,
            "phrase": "с первого же применения натянула носок и реально"
          },
          {
            "word": "натянула",
            "count": 1,
            "phrase": "с первого же применения натянула носок и реально"
          },
          {
            "word": "носок",
            "count": 1,
            "phrase": "с первого же применения натянула носок и реально"
          },
          {
            "word": "реально",
            "count": 3,
            "phrase": "с первого же применения натянула носок и реально"
          },
          {
            "word": "мой",
            "count": 5,
            "phrase": "мой маленький и теперь любимый помощник"
          },
          {
            "word": "маленький",
            "count": 8,
            "phrase": "мой маленький и теперь любимый помощник"
          },
          {
            "word": "теперь",
            "count": 9,
            "phrase": "мой маленький и теперь любимый помощник"
          },
          {
            "word": "любимый",
            "count": 1,
            "phrase": "мой маленький и теперь любимый помощник"
          },
          {
            "word": "помощник",
            "count": 7,
            "phrase": "мой маленький и теперь любимый помощник"
          },
          {
            "word": "манëвристый",
            "count": 1,
            "phrase": "манëвристый но как оказалось мощный пылесос"
          },
          {
            "word": "оказалось",
            "count": 2,
            "phrase": "манëвристый но как оказалось мощный пылесос"
          },
          {
            "word": "постирал",
            "count": 1,
            "phrase": "постирал и готово"
          },
          {
            "word": "готово",
            "count": 1,
            "phrase": "постирал и готово"
          },
          {
            "word": "всех",
            "count": 3,
            "phrase": "не на всех пылесосах он есть"
          },
          {
            "word": "пылесосах",
            "count": 1,
            "phrase": "не на всех пылесосах он есть"
          },
          {
            "word": "есть",
            "count": 8,
            "phrase": "не на всех пылесосах он есть"
          },
          {
            "word": "доставка",
            "count": 4,
            "phrase": "доставка быстрая"
          },
          {
            "word": "быстрая",
            "count": 4,
            "phrase": "доставка быстрая"
          },
          {
            "word": "целости",
            "count": 2,
            "phrase": "всё в целости"
          },
          {
            "word": "большое",
            "count": 5,
            "phrase": "спасибо большое"
          },
          {
            "word": "качество",
            "count": 9,
            "phrase": "качество превзошло цену"
          },
          {
            "word": "превзошло",
            "count": 1,
            "phrase": "качество превзошло цену"
          },
          {
            "word": "цену",
            "count": 19,
            "phrase": "качество превзошло цену"
          },
          {
            "word": "очень",
            "count": 74,
            "phrase": "пылесос очень удобный в использовании"
          },
          {
            "word": "удобный",
            "count": 33,
            "phrase": "пылесос очень удобный в использовании"
          },
          {
            "word": "использовании",
            "count": 10,
            "phrase": "пылесос очень удобный в использовании"
          },
          {
            "word": "занимает",
            "count": 10,
            "phrase": "занимает мало места"
          },
          {
            "word": "места",
            "count": 11,
            "phrase": "занимает мало места"
          },
          {
            "word": "хорошо",
            "count": 56,
            "phrase": "хорошо убирает шерсть"
          },
          {
            "word": "убирает",
            "count": 12,
            "phrase": "хорошо убирает шерсть"
          },
          {
            "word": "шерсть",
            "count": 21,
            "phrase": "хорошо убирает шерсть"
          },
          {
            "word": "уборкой",
            "count": 2,
            "phrase": "с уборкой справляется прекрасно"
          },
          {
            "word": "справляется",
            "count": 15,
            "phrase": "с уборкой справляется прекрасно"
          },
          {
            "word": "прекрасно",
            "count": 3,
            "phrase": "с уборкой справляется прекрасно"
          },
          {
            "word": "дочке",
            "count": 0,
            "phrase": "дочке 14"
          },
          {
            "word": "пылесосит",
            "count": 24,
            "phrase": "пылесосит с удовольствием"
          },
          {
            "word": "удовольствием",
            "count": 4,
            "phrase": "пылесосит с удовольствием"
          },
          {
            "word": "раньше",
            "count": 2,
            "phrase": "раньше не заставить старым!очень красивый дизайн"
          },
          {
            "word": "заставить",
            "count": 1,
            "phrase": "раньше не заставить старым!очень красивый дизайн"
          },
          {
            "word": "старым!очень",
            "count": 0,
            "phrase": "раньше не заставить старым!очень красивый дизайн"
          },
          {
            "word": "красивый",
            "count": 7,
            "phrase": "раньше не заставить старым!очень красивый дизайн"
          },
          {
            "word": "дизайн",
            "count": 4,
            "phrase": "раньше не заставить старым!очень красивый дизайн"
          },
          {
            "word": "работает",
            "count": 37,
            "phrase": "все работает"
          },
          {
            "word": "сложностей",
            "count": 2,
            "phrase": "сложностей в эксплуатации нет"
          },
          {
            "word": "эксплуатации",
            "count": 1,
            "phrase": "сложностей в эксплуатации нет"
          },
          {
            "word": "чистит",
            "count": 11,
            "phrase": "хорошо чистит диван и убирает пыль!спасибо за товар"
          },
          {
            "word": "диван",
            "count": 5,
            "phrase": "хорошо чистит диван и убирает пыль!спасибо за товар"
          },
          {
            "word": "пыль!спасибо",
            "count": 0,
            "phrase": "хорошо чистит диван и убирает пыль!спасибо за товар"
          },
          {
            "word": "товар",
            "count": 17,
            "phrase": "хорошо чистит диван и убирает пыль!спасибо за товар"
          },
          {
            "word": "кошка",
            "count": 1,
            "phrase": "так как у нас кошка"
          },
          {
            "word": "отличный",
            "count": 25,
            "phrase": "пылесос отличный"
          },
          {
            "word": "эти",
            "count": 9,
            "phrase": "я за эти деньги вообще не ожидала"
          },
          {
            "word": "деньги",
            "count": 29,
            "phrase": "я за эти деньги вообще не ожидала"
          },
          {
            "word": "вообще",
            "count": 3,
            "phrase": "я за эти деньги вообще не ожидала"
          },
          {
            "word": "ожидала",
            "count": 10,
            "phrase": "я за эти деньги вообще не ожидала"
          },
          {
            "word": "сосет",
            "count": 10,
            "phrase": "сосет очень мощно"
          },
          {
            "word": "мощно",
            "count": 26,
            "phrase": "сосет очень мощно"
          },
          {
            "word": "затестила",
            "count": 1,
            "phrase": "просто затестила пропылесосив кухню"
          },
          {
            "word": "пропылесосив",
            "count": 1,
            "phrase": "просто затестила пропылесосив кухню"
          },
          {
            "word": "кухню",
            "count": 1,
            "phrase": "просто затестила пропылесосив кухню"
          },
          {
            "word": "казалась",
            "count": 1,
            "phrase": "которая казалась еще пока не грязной"
          },
          {
            "word": "еще",
            "count": 4,
            "phrase": "которая казалась еще пока не грязной"
          },
          {
            "word": "грязной",
            "count": 1,
            "phrase": "которая казалась еще пока не грязной"
          },
          {
            "word": "подарок",
            "count": 21,
            "phrase": "в подарок тряпочку положили"
          },
          {
            "word": "тряпочку",
            "count": 5,
            "phrase": "в подарок тряпочку положили"
          },
          {
            "word": "положили",
            "count": 5,
            "phrase": "в подарок тряпочку положили"
          },
          {
            "word": "приятно",
            "count": 5,
            "phrase": "а приятно"
          },
          {
            "word": "столько",
            "count": 3,
            "phrase": "столько пыли собрал за 1 - 2 минуты"
          },
          {
            "word": "пыли",
            "count": 12,
            "phrase": "столько пыли собрал за 1 - 2 минуты"
          },
          {
            "word": "собрал",
            "count": 5,
            "phrase": "столько пыли собрал за 1 - 2 минуты"
          },
          {
            "word": "минуты",
            "count": 5,
            "phrase": "столько пыли собрал за 1 - 2 минуты"
          },
          {
            "word": "пришел",
            "count": 7,
            "phrase": "пришел в коробке аккуратно упакован"
          },
          {
            "word": "коробке",
            "count": 7,
            "phrase": "пришел в коробке аккуратно упакован"
          },
          {
            "word": "аккуратно",
            "count": 4,
            "phrase": "пришел в коробке аккуратно упакован"
          },
          {
            "word": "упакован",
            "count": 8,
            "phrase": "пришел в коробке аккуратно упакован"
          },
          {
            "word": "покупкой",
            "count": 4,
            "phrase": "перед покупкой читала отзывы"
          },
          {
            "word": "читала",
            "count": 1,
            "phrase": "перед покупкой читала отзывы"
          },
          {
            "word": "повезло",
            "count": 1,
            "phrase": "или мне так повезло или люди наговаривают"
          },
          {
            "word": "люди",
            "count": 2,
            "phrase": "или мне так повезло или люди наговаривают"
          },
          {
            "word": "наговаривают",
            "count": 1,
            "phrase": "или мне так повезло или люди наговаривают"
          },
          {
            "word": "исправно",
            "count": 6,
            "phrase": "но у меня все исправно"
          },
          {
            "word": "отлично",
            "count": 40,
            "phrase": "работает отлично"
          },
          {
            "word": "послушала",
            "count": 1,
            "phrase": "что не послушала отрицательные отзывы и купила этого помощника"
          },
          {
            "word": "отрицательные",
            "count": 1,
            "phrase": "что не послушала отрицательные отзывы и купила этого помощника"
          },
          {
            "word": "купила",
            "count": 3,
            "phrase": "что не послушала отрицательные отзывы и купила этого помощника"
          },
          {
            "word": "помощника",
            "count": 1,
            "phrase": "что не послушала отрицательные отзывы и купила этого помощника"
          },
          {
            "word": "брала",
            "count": 5,
            "phrase": "брала для кухни"
          },
          {
            "word": "кухни",
            "count": 1,
            "phrase": "брала для кухни"
          },
          {
            "word": "пылесосом",
            "count": 4,
            "phrase": "пылесосом уже пользовалась 😅 очень довольна покупкой"
          },
          {
            "word": "уже",
            "count": 9,
            "phrase": "пылесосом уже пользовалась 😅 очень довольна покупкой"
          },
          {
            "word": "пользовалась",
            "count": 2,
            "phrase": "пылесосом уже пользовалась 😅 очень довольна покупкой"
          },
          {
            "word": "довольна",
            "count": 24,
            "phrase": "пылесосом уже пользовалась 😅 очень довольна покупкой"
          },
          {
            "word": "этот",
            "count": 4,
            "phrase": "этот малыш творит чудеса"
          },
          {
            "word": "малыш",
            "count": 1,
            "phrase": "этот малыш творит чудеса"
          },
          {
            "word": "творит",
            "count": 1,
            "phrase": "этот малыш творит чудеса"
          },
          {
            "word": "чудеса",
            "count": 1,
            "phrase": "этот малыш творит чудеса"
          },
          {
            "word": "собирает",
            "count": 29,
            "phrase": "прекрасно собирает мусор и с ковра"
          },
          {
            "word": "мусор",
            "count": 25,
            "phrase": "прекрасно собирает мусор и с ковра"
          },
          {
            "word": "ковра",
            "count": 12,
            "phrase": "прекрасно собирает мусор и с ковра"
          },
          {
            "word": "ламината",
            "count": 4,
            "phrase": "и с ламината"
          },
          {
            "word": "шумный",
            "count": 7,
            "phrase": "не шумный"
          },
          {
            "word": "единственный",
            "count": 1,
            "phrase": "единственный минус для меня это короткий шнур"
          },
          {
            "word": "короткий",
            "count": 2,
            "phrase": "единственный минус для меня это короткий шнур"
          },
          {
            "word": "шнур",
            "count": 6,
            "phrase": "единственный минус для меня это короткий шнур"
          },
          {
            "word": "серии",
            "count": 1,
            "phrase": "но это из серии можно доколупаться до чего угодно"
          },
          {
            "word": "доколупаться",
            "count": 1,
            "phrase": "но это из серии можно доколупаться до чего угодно"
          },
          {
            "word": "угодно",
            "count": 3,
            "phrase": "но это из серии можно доколупаться до чего угодно"
          },
          {
            "word": "шикарен",
            "count": 1,
            "phrase": "пылесос шикарен"
          },
          {
            "word": "рпботает",
            "count": 0,
            "phrase": "рпботает тихо"
          },
          {
            "word": "тихо",
            "count": 6,
            "phrase": "рпботает тихо"
          },
          {
            "word": "пластика",
            "count": 1,
            "phrase": "качество пластика неплохое"
          },
          {
            "word": "неплохое",
            "count": 1,
            "phrase": "качество пластика неплохое"
          },
          {
            "word": "купил",
            "count": 5,
            "phrase": "как купил"
          },
          {
            "word": "сразу",
            "count": 6,
            "phrase": "сразу проверил"
          },
          {
            "word": "проверил",
            "count": 1,
            "phrase": "сразу проверил"
          },
          {
            "word": "маленького",
            "count": 1,
            "phrase": "с маленького коврика"
          },
          {
            "word": "коврика",
            "count": 1,
            "phrase": "с маленького коврика"
          },
          {
            "word": "который",
            "count": 7,
            "phrase": "который пылесосился через день старым пылесосом вытянул ещё жменю"
          },
          {
            "word": "пылесосился",
            "count": 1,
            "phrase": "который пылесосился через день старым пылесосом вытянул ещё жменю"
          },
          {
            "word": "день",
            "count": 37,
            "phrase": "который пылесосился через день старым пылесосом вытянул ещё жменю"
          },
          {
            "word": "старым",
            "count": 3,
            "phrase": "который пылесосился через день старым пылесосом вытянул ещё жменю"
          },
          {
            "word": "вытянул",
            "count": 1,
            "phrase": "который пылесосился через день старым пылесосом вытянул ещё жменю"
          },
          {
            "word": "ещё",
            "count": 11,
            "phrase": "который пылесосился через день старым пылесосом вытянул ещё жменю"
          },
          {
            "word": "жменю",
            "count": 1,
            "phrase": "который пылесосился через день старым пылесосом вытянул ещё жменю"
          },
          {
            "word": "рекомендую",
            "count": 24,
            "phrase": "рекомендую продавца"
          },
          {
            "word": "продавца",
            "count": 2,
            "phrase": "рекомендую продавца"
          },
          {
            "word": "топ",
            "count": 4,
            "phrase": "за эти деньги товар топ"
          },
          {
            "word": "такую",
            "count": 16,
            "phrase": "за такую цену отличный вариант"
          },
          {
            "word": "вариант",
            "count": 4,
            "phrase": "за такую цену отличный вариант"
          },
          {
            "word": "получила",
            "count": 2,
            "phrase": "всё получила вовремя"
          },
          {
            "word": "вовремя",
            "count": 3,
            "phrase": "всё получила вовремя"
          },
          {
            "word": "подарочек",
            "count": 3,
            "phrase": "ещё и подарочек положили- салфетку из микрофибры"
          },
          {
            "word": "положили-",
            "count": 1,
            "phrase": "ещё и подарочек положили- салфетку из микрофибры"
          },
          {
            "word": "салфетку",
            "count": 1,
            "phrase": "ещё и подарочек положили- салфетку из микрофибры"
          },
          {
            "word": "микрофибры",
            "count": 1,
            "phrase": "ещё и подарочек положили- салфетку из микрофибры"
          },
          {
            "word": "всасывает",
            "count": 14,
            "phrase": "всасывает супер"
          },
          {
            "word": "супер",
            "count": 27,
            "phrase": "всасывает супер"
          },
          {
            "word": "громкий",
            "count": 2,
            "phrase": "не очень громкий"
          },
          {
            "word": "волосы",
            "count": 11,
            "phrase": "видно как волосы и мусор крутятся в вакуумном стакане"
          },
          {
            "word": "крутятся",
            "count": 1,
            "phrase": "видно как волосы и мусор крутятся в вакуумном стакане"
          },
          {
            "word": "вакуумном",
            "count": 1,
            "phrase": "видно как волосы и мусор крутятся в вакуумном стакане"
          },
          {
            "word": "стакане",
            "count": 1,
            "phrase": "видно как волосы и мусор крутятся в вакуумном стакане"
          },
          {
            "word": "щётка",
            "count": 4,
            "phrase": "щётка гнётся полностью во все стороны"
          },
          {
            "word": "гнётся",
            "count": 1,
            "phrase": "щётка гнётся полностью во все стороны"
          },
          {
            "word": "полностью",
            "count": 2,
            "phrase": "щётка гнётся полностью во все стороны"
          },
          {
            "word": "стороны",
            "count": 5,
            "phrase": "щётка гнётся полностью во все стороны"
          },
          {
            "word": "вертикально",
            "count": 4,
            "phrase": "вертикально и горизонтально"
          },
          {
            "word": "горизонтально",
            "count": 2,
            "phrase": "вертикально и горизонтально"
          },
          {
            "word": "долго",
            "count": 11,
            "phrase": "долго выбирал"
          },
          {
            "word": "выбирал",
            "count": 1,
            "phrase": "долго выбирал"
          },
          {
            "word": "качественно",
            "count": 2,
            "phrase": "качественно сделано"
          },
          {
            "word": "сделано",
            "count": 2,
            "phrase": "качественно сделано"
          },
          {
            "word": "комфортный",
            "count": 1,
            "phrase": "пылесос очень комфортный в использовании"
          },
          {
            "word": "мощность",
            "count": 14,
            "phrase": "мощность удивила"
          },
          {
            "word": "удивила",
            "count": 2,
            "phrase": "мощность удивила"
          },
          {
            "word": "думала",
            "count": 4,
            "phrase": "думала будет менее мощный"
          },
          {
            "word": "будет",
            "count": 10,
            "phrase": "думала будет менее мощный"
          },
          {
            "word": "менее",
            "count": 2,
            "phrase": "думала будет менее мощный"
          },
          {
            "word": "мое",
            "count": 11,
            "phrase": "но на мое удивление"
          },
          {
            "word": "удивление",
            "count": 1,
            "phrase": "но на мое удивление"
          },
          {
            "word": "своей",
            "count": 12,
            "phrase": "он очень хорошо справляется со своей задачей за свою стоимость"
          },
          {
            "word": "задачей",
            "count": 6,
            "phrase": "он очень хорошо справляется со своей задачей за свою стоимость"
          },
          {
            "word": "свою",
            "count": 6,
            "phrase": "он очень хорошо справляется со своей задачей за свою стоимость"
          },
          {
            "word": "стоимость",
            "count": 3,
            "phrase": "он очень хорошо справляется со своей задачей за свою стоимость"
          },
          {
            "word": "коротко-",
            "count": 0,
            "phrase": "коротко- мощно всасывает пыль с полок"
          },
          {
            "word": "пыль",
            "count": 24,
            "phrase": "коротко- мощно всасывает пыль с полок"
          },
          {
            "word": "полок",
            "count": 1,
            "phrase": "коротко- мощно всасывает пыль с полок"
          },
          {
            "word": "гладкими",
            "count": 1,
            "phrase": "с гладкими коврами с небольшим ворсом тоже"
          },
          {
            "word": "коврами",
            "count": 2,
            "phrase": "с гладкими коврами с небольшим ворсом тоже"
          },
          {
            "word": "небольшим",
            "count": 2,
            "phrase": "с гладкими коврами с небольшим ворсом тоже"
          },
          {
            "word": "ворсом",
            "count": 3,
            "phrase": "с гладкими коврами с небольшим ворсом тоже"
          },
          {
            "word": "нагревается",
            "count": 1,
            "phrase": "нагревается мин через 15,ему нужно передохнуть"
          },
          {
            "word": "мин",
            "count": 33,
            "phrase": "нагревается мин через 15,ему нужно передохнуть"
          },
          {
            "word": "15,ему",
            "count": 1,
            "phrase": "нагревается мин через 15,ему нужно передохнуть"
          },
          {
            "word": "передохнуть",
            "count": 1,
            "phrase": "нагревается мин через 15,ему нужно передохнуть"
          },
          {
            "word": "норм",
            "count": 14,
            "phrase": "это норм"
          },
          {
            "word": "хватит",
            "count": 1,
            "phrase": "хватит убрать небольшую квартиру за считанные минуты"
          },
          {
            "word": "убрать",
            "count": 1,
            "phrase": "хватит убрать небольшую квартиру за считанные минуты"
          },
          {
            "word": "небольшую",
            "count": 2,
            "phrase": "хватит убрать небольшую квартиру за считанные минуты"
          },
          {
            "word": "квартиру",
            "count": 5,
            "phrase": "хватит убрать небольшую квартиру за считанные минуты"
          },
          {
            "word": "считанные",
            "count": 1,
            "phrase": "хватит убрать небольшую квартиру за считанные минуты"
          },
          {
            "word": "целом",
            "count": 6,
            "phrase": "в целом"
          },
          {
            "word": "быстро",
            "count": 12,
            "phrase": "пришел быстро"
          },
          {
            "word": "работать",
            "count": 3,
            "phrase": "что будет работать годами"
          },
          {
            "word": "годами",
            "count": 1,
            "phrase": "что будет работать годами"
          },
          {
            "word": "сами",
            "count": 3,
            "phrase": "сами понимаете"
          },
          {
            "word": "понимаете",
            "count": 1,
            "phrase": "сами понимаете"
          },
          {
            "word": "было",
            "count": 4,
            "phrase": "то что мне было нужно"
          },
          {
            "word": "шкафов",
            "count": 1,
            "phrase": "шкафов и ламината"
          },
          {
            "word": "упаковано",
            "count": 2,
            "phrase": "все упаковано хорошо за эти деньги не рассчитывайте"
          },
          {
            "word": "рассчитывайте",
            "count": 1,
            "phrase": "все упаковано хорошо за эти деньги не рассчитывайте"
          },
          {
            "word": "бомба",
            "count": 6,
            "phrase": "за такую цену просто бомба"
          },
          {
            "word": "крошки",
            "count": 2,
            "phrase": "я думала он максимум крошки соберёт"
          },
          {
            "word": "соберёт",
            "count": 1,
            "phrase": "я думала он максимум крошки соберёт"
          },
          {
            "word": "кажется",
            "count": 0,
            "phrase": "кажется немного хлипким"
          },
          {
            "word": "немного",
            "count": 4,
            "phrase": "кажется немного хлипким"
          },
          {
            "word": "хлипким",
            "count": 1,
            "phrase": "кажется немного хлипким"
          },
          {
            "word": "жаловаться",
            "count": 1,
            "phrase": "но за такую цену жаловаться просто неприлично 😁 посмотрим"
          },
          {
            "word": "неприлично",
            "count": 1,
            "phrase": "но за такую цену жаловаться просто неприлично 😁 посмотрим"
          },
          {
            "word": "посмотрим",
            "count": 6,
            "phrase": "но за такую цену жаловаться просто неприлично 😁 посмотрим"
          },
          {
            "word": "сколько",
            "count": 7,
            "phrase": "сколько проживёт"
          },
          {
            "word": "проживёт",
            "count": 1,
            "phrase": "сколько проживёт"
          },
          {
            "word": "шикарный",
            "count": 2,
            "phrase": "пылесос шикарный"
          },
          {
            "word": "такие",
            "count": 10,
            "phrase": "думала что не очень за такие деньги"
          },
          {
            "word": "включила",
            "count": 1,
            "phrase": "а когда включила удивилась всасываемая мощной просто на все"
          },
          {
            "word": "удивилась",
            "count": 1,
            "phrase": "а когда включила удивилась всасываемая мощной просто на все"
          },
          {
            "word": "всасываемая",
            "count": 1,
            "phrase": "а когда включила удивилась всасываемая мощной просто на все"
          },
          {
            "word": "мощной",
            "count": 1,
            "phrase": "а когда включила удивилась всасываемая мощной просто на все"
          },
          {
            "word": "коробка",
            "count": 2,
            "phrase": "коробка не мятая все целое"
          },
          {
            "word": "мятая",
            "count": 1,
            "phrase": "коробка не мятая все целое"
          },
          {
            "word": "целое",
            "count": 4,
            "phrase": "коробка не мятая все целое"
          },
          {
            "word": "хороший",
            "count": 29,
            "phrase": "пылесос очень хороший"
          },
          {
            "word": "минут",
            "count": 11,
            "phrase": "за 5 минут уборки собрал пыли достаточно много"
          },
          {
            "word": "уборки",
            "count": 17,
            "phrase": "за 5 минут уборки собрал пыли достаточно много"
          },
          {
            "word": "достаточно",
            "count": 9,
            "phrase": "за 5 минут уборки собрал пыли достаточно много"
          },
          {
            "word": "много",
            "count": 15,
            "phrase": "за 5 минут уборки собрал пыли достаточно много"
          },
          {
            "word": "смотря",
            "count": 2,
            "phrase": "не смотря на то"
          },
          {
            "word": "уборка",
            "count": 1,
            "phrase": "что уборка проводится каждый день"
          },
          {
            "word": "проводится",
            "count": 1,
            "phrase": "что уборка проводится каждый день"
          },
          {
            "word": "каждый",
            "count": 2,
            "phrase": "что уборка проводится каждый день"
          },
          {
            "word": "продавец",
            "count": 1,
            "phrase": "продавец не обманул на счёт подарка"
          },
          {
            "word": "обманул",
            "count": 1,
            "phrase": "продавец не обманул на счёт подарка"
          },
          {
            "word": "счёт",
            "count": 1,
            "phrase": "продавец не обманул на счёт подарка"
          },
          {
            "word": "подарка",
            "count": 1,
            "phrase": "продавец не обманул на счёт подарка"
          },
          {
            "word": "совсем",
            "count": 3,
            "phrase": "единственный минус не совсем крепко дердится кронштейн для намотки провода"
          },
          {
            "word": "крепко",
            "count": 1,
            "phrase": "единственный минус не совсем крепко дердится кронштейн для намотки провода"
          },
          {
            "word": "дердится",
            "count": 1,
            "phrase": "единственный минус не совсем крепко дердится кронштейн для намотки провода"
          },
          {
            "word": "кронштейн",
            "count": 1,
            "phrase": "единственный минус не совсем крепко дердится кронштейн для намотки провода"
          },
          {
            "word": "намотки",
            "count": 1,
            "phrase": "единственный минус не совсем крепко дердится кронштейн для намотки провода"
          },
          {
            "word": "провода",
            "count": 7,
            "phrase": "единственный минус не совсем крепко дердится кронштейн для намотки провода"
          },
          {
            "word": "беда",
            "count": 1,
            "phrase": "но это не беда"
          },
          {
            "word": "котором",
            "count": 1,
            "phrase": "о котором я мечтала всю жизнь🥹 пришло всё отлично запаковано"
          },
          {
            "word": "мечтала",
            "count": 2,
            "phrase": "о котором я мечтала всю жизнь🥹 пришло всё отлично запаковано"
          },
          {
            "word": "всю",
            "count": 12,
            "phrase": "о котором я мечтала всю жизнь🥹 пришло всё отлично запаковано"
          },
          {
            "word": "жизнь🥹",
            "count": 1,
            "phrase": "о котором я мечтала всю жизнь🥹 пришло всё отлично запаковано"
          },
          {
            "word": "пришло",
            "count": 3,
            "phrase": "о котором я мечтала всю жизнь🥹 пришло всё отлично запаковано"
          },
          {
            "word": "запаковано",
            "count": 1,
            "phrase": "о котором я мечтала всю жизнь🥹 пришло всё отлично запаковано"
          },
          {
            "word": "своими",
            "count": 1,
            "phrase": "очень хорошо справляется со своими обязанностями"
          },
          {
            "word": "обязанностями",
            "count": 1,
            "phrase": "очень хорошо справляется со своими обязанностями"
          },
          {
            "word": "чистейшие",
            "count": 1,
            "phrase": "диван чистейшие"
          },
          {
            "word": "нарадуюсь",
            "count": 1,
            "phrase": "не нарадуюсь на него"
          },
          {
            "word": "пылью",
            "count": 2,
            "phrase": "справляется с пылью и мусором"
          },
          {
            "word": "мусором",
            "count": 2,
            "phrase": "справляется с пылью и мусором"
          },
          {
            "word": "маневренный",
            "count": 7,
            "phrase": "маневренный и легкий в управлении"
          },
          {
            "word": "легкий",
            "count": 12,
            "phrase": "маневренный и легкий в управлении"
          },
          {
            "word": "управлении",
            "count": 3,
            "phrase": "маневренный и легкий в управлении"
          },
          {
            "word": "моется",
            "count": 1,
            "phrase": "моется легко"
          },
          {
            "word": "легко",
            "count": 17,
            "phrase": "моется легко"
          },
          {
            "word": "заказ",
            "count": 6,
            "phrase": "заказ сделан по ошибке"
          },
          {
            "word": "сделан",
            "count": 5,
            "phrase": "заказ сделан по ошибке"
          },
          {
            "word": "ошибке",
            "count": 1,
            "phrase": "заказ сделан по ошибке"
          },
          {
            "word": "вернуть",
            "count": 2,
            "phrase": "можно ли вернуть товар пожалуйста"
          },
          {
            "word": "заявку",
            "count": 0,
            "phrase": "заявку на возврат к сожалению отклонили"
          },
          {
            "word": "возврат",
            "count": 3,
            "phrase": "заявку на возврат к сожалению отклонили"
          },
          {
            "word": "сожалению",
            "count": 3,
            "phrase": "заявку на возврат к сожалению отклонили"
          },
          {
            "word": "отклонили",
            "count": 1,
            "phrase": "заявку на возврат к сожалению отклонили"
          },
          {
            "word": "решить",
            "count": 2,
            "phrase": "как решить проблему"
          },
          {
            "word": "проблему",
            "count": 1,
            "phrase": "как решить проблему"
          },
          {
            "word": "замечательный",
            "count": 1,
            "phrase": "замечательный пылесос за свои деньги"
          },
          {
            "word": "свои",
            "count": 18,
            "phrase": "замечательный пылесос за свои деньги"
          },
          {
            "word": "отличная",
            "count": 6,
            "phrase": "отличная мощность"
          },
          {
            "word": "мелкий",
            "count": 2,
            "phrase": "убирает мелкий мусор на ура"
          },
          {
            "word": "щетка",
            "count": 2,
            "phrase": "щетка отлично собирает шерсть"
          },
          {
            "word": "умеренно",
            "count": 0,
            "phrase": "умеренно тихо работает"
          },
          {
            "word": "удобно",
            "count": 13,
            "phrase": "удобно держать в руке"
          },
          {
            "word": "держать",
            "count": 5,
            "phrase": "удобно держать в руке"
          },
          {
            "word": "руке",
            "count": 3,
            "phrase": "удобно держать в руке"
          },
          {
            "word": "высоте",
            "count": 1,
            "phrase": "качество на высоте"
          },
          {
            "word": "покупке",
            "count": 22,
            "phrase": "рекомендую к покупке"
          },
          {
            "word": "труднодоступные",
            "count": 1,
            "phrase": "во все труднодоступные места достал"
          },
          {
            "word": "достал",
            "count": 2,
            "phrase": "во все труднодоступные места достал"
          },
          {
            "word": "доставила",
            "count": 1,
            "phrase": "уборка с ним доставила положительные эмоции"
          },
          {
            "word": "положительные",
            "count": 1,
            "phrase": "уборка с ним доставила положительные эмоции"
          },
          {
            "word": "эмоции",
            "count": 1,
            "phrase": "уборка с ним доставила положительные эмоции"
          },
          {
            "word": "однозначно",
            "count": 3,
            "phrase": "к покупке однозначно рекомендую!!!буду заказывать и маме"
          },
          {
            "word": "рекомендую!!!буду",
            "count": 0,
            "phrase": "к покупке однозначно рекомендую!!!буду заказывать и маме"
          },
          {
            "word": "заказывать",
            "count": 2,
            "phrase": "к покупке однозначно рекомендую!!!буду заказывать и маме"
          },
          {
            "word": "маме",
            "count": 5,
            "phrase": "к покупке однозначно рекомендую!!!буду заказывать и маме"
          },
          {
            "word": "которой",
            "count": 1,
            "phrase": "которой 87 лет"
          },
          {
            "word": "лет",
            "count": 3,
            "phrase": "которой 87 лет"
          },
          {
            "word": "днях",
            "count": 1,
            "phrase": "на днях купили пылесос"
          },
          {
            "word": "купили",
            "count": 1,
            "phrase": "на днях купили пылесос"
          },
          {
            "word": "волосами",
            "count": 1,
            "phrase": "с волосами и мусором справляется на ура"
          },
          {
            "word": "задержек",
            "count": 1,
            "phrase": "пришел быстро без задержек"
          },
          {
            "word": "упаковка",
            "count": 3,
            "phrase": "упаковка целая"
          },
          {
            "word": "целая",
            "count": 2,
            "phrase": "упаковка целая"
          },
          {
            "word": "имеются",
            "count": 0,
            "phrase": "имеются несколько насадок"
          },
          {
            "word": "несколько",
            "count": 4,
            "phrase": "имеются несколько насадок"
          },
          {
            "word": "насадок",
            "count": 1,
            "phrase": "имеются несколько насадок"
          },
          {
            "word": "шикарная",
            "count": 0,
            "phrase": "шикарная находка для меня"
          },
          {
            "word": "находка",
            "count": 7,
            "phrase": "шикарная находка для меня"
          },
          {
            "word": "убрал",
            "count": 2,
            "phrase": "достал - убрал"
          },
          {
            "word": "покупал",
            "count": 1,
            "phrase": "покупал в подарок жене"
          },
          {
            "word": "жене",
            "count": 3,
            "phrase": "покупал в подарок жене"
          },
          {
            "word": "понравился",
            "count": 26,
            "phrase": "жене понравился"
          },
          {
            "word": "огромное",
            "count": 1,
            "phrase": "огромное спасибо"
          },
          {
            "word": "пропылесосил",
            "count": 4,
            "phrase": "пропылесосил две комнаты"
          },
          {
            "word": "комнаты",
            "count": 4,
            "phrase": "пропылесосил две комнаты"
          },
          {
            "word": "ковры",
            "count": 9,
            "phrase": "ковры дались на ура"
          },
          {
            "word": "дались",
            "count": 1,
            "phrase": "ковры дались на ура"
          },
          {
            "word": "грязи",
            "count": 1,
            "phrase": "много собрал пыли и грязи"
          },
          {
            "word": "нагрелся",
            "count": 1,
            "phrase": "немного нагрелся"
          },
          {
            "word": "была",
            "count": 3,
            "phrase": "коробка конечно была немного грязная"
          },
          {
            "word": "грязная",
            "count": 1,
            "phrase": "коробка конечно была немного грязная"
          },
          {
            "word": "местами",
            "count": 1,
            "phrase": "местами покоцанная"
          },
          {
            "word": "покоцанная",
            "count": 1,
            "phrase": "местами покоцанная"
          },
          {
            "word": "сам",
            "count": 22,
            "phrase": "сам пылесос целый"
          },
          {
            "word": "целый",
            "count": 2,
            "phrase": "сам пылесос целый"
          },
          {
            "word": "был",
            "count": 17,
            "phrase": "даже подарок был в коробке"
          },
          {
            "word": "надеюсь",
            "count": 3,
            "phrase": "надеюсь долго прослужит"
          },
          {
            "word": "прослужит",
            "count": 7,
            "phrase": "надеюсь долго прослужит"
          },
          {
            "word": "вещь",
            "count": 8,
            "phrase": "отличная вещь"
          },
          {
            "word": "пожалеете",
            "count": 2,
            "phrase": "не пожалеете"
          },
          {
            "word": "радость",
            "count": 1,
            "phrase": "уборка теперь в радость"
          },
          {
            "word": "кота",
            "count": 4,
            "phrase": "у меня 2 кота"
          },
          {
            "word": "улицы",
            "count": 1,
            "phrase": "так ещё и с улицы в дом несут кучу песка"
          },
          {
            "word": "дом",
            "count": 17,
            "phrase": "так ещё и с улицы в дом несут кучу песка"
          },
          {
            "word": "несут",
            "count": 1,
            "phrase": "так ещё и с улицы в дом несут кучу песка"
          },
          {
            "word": "кучу",
            "count": 1,
            "phrase": "так ещё и с улицы в дом несут кучу песка"
          },
          {
            "word": "песка",
            "count": 1,
            "phrase": "так ещё и с улицы в дом несут кучу песка"
          },
          {
            "word": "того",
            "count": 7,
            "phrase": "мало того что с них шерсть сыпется"
          },
          {
            "word": "сыпется",
            "count": 1,
            "phrase": "мало того что с них шерсть сыпется"
          },
          {
            "word": "претензий",
            "count": 2,
            "phrase": "пока претензий нет"
          },
          {
            "word": "пропылисосила",
            "count": 0,
            "phrase": "пропылисосила весь дом"
          },
          {
            "word": "весь",
            "count": 1,
            "phrase": "пропылисосила весь дом"
          },
          {
            "word": "этажа",
            "count": 1,
            "phrase": "3 этажа"
          },
          {
            "word": "сгорит",
            "count": 1,
            "phrase": "что сгорит"
          },
          {
            "word": "ламинат",
            "count": 7,
            "phrase": "ламинат хорошо пылесосит"
          },
          {
            "word": "знаю",
            "count": 4,
            "phrase": "про ковры не знаю"
          },
          {
            "word": "доме",
            "count": 4,
            "phrase": "в доме кот"
          },
          {
            "word": "кот",
            "count": 23,
            "phrase": "в доме кот"
          },
          {
            "word": "дачу",
            "count": 1,
            "phrase": "наверное на дачу закажу ещё один"
          },
          {
            "word": "закажу",
            "count": 1,
            "phrase": "наверное на дачу закажу ещё один"
          },
          {
            "word": "один",
            "count": 5,
            "phrase": "наверное на дачу закажу ещё один"
          },
          {
            "word": "пришёл",
            "count": 4,
            "phrase": "товар пришёл вовремя"
          },
          {
            "word": "лёгкий",
            "count": 15,
            "phrase": "лёгкий и красивый пылесос"
          },
          {
            "word": "хорошая",
            "count": 8,
            "phrase": "упаковка хорошая"
          },
          {
            "word": "продавцу",
            "count": 11,
            "phrase": "спасибо продавцу"
          },
          {
            "word": "компактный",
            "count": 12,
            "phrase": "компактный пылесос"
          },
          {
            "word": "восторге",
            "count": 6,
            "phrase": "я в восторге"
          },
          {
            "word": "ребёнок",
            "count": 1,
            "phrase": "даже ребёнок теперь с удовольствием пылесосит"
          },
          {
            "word": "заказывала",
            "count": 2,
            "phrase": "заказывала по отзывам"
          },
          {
            "word": "отзывам",
            "count": 1,
            "phrase": "заказывала по отзывам"
          },
          {
            "word": "пылисос",
            "count": 3,
            "phrase": "пылисос отличный"
          },
          {
            "word": "дкньги",
            "count": 1,
            "phrase": "еще и за такие дкньги"
          },
          {
            "word": "сильно",
            "count": 7,
            "phrase": "но не сильно"
          },
          {
            "word": "чистить",
            "count": 3,
            "phrase": "удобно чистить"
          },
          {
            "word": "минимум",
            "count": 2,
            "phrase": "занимает минимум места контейнер большой"
          },
          {
            "word": "контейнер",
            "count": 10,
            "phrase": "занимает минимум места контейнер большой"
          },
          {
            "word": "большой",
            "count": 8,
            "phrase": "занимает минимум места контейнер большой"
          },
          {
            "word": "нереальное",
            "count": 1,
            "phrase": "это что то нереальное"
          },
          {
            "word": "такое",
            "count": 3,
            "phrase": "за такую цену такое шикарное качество"
          },
          {
            "word": "шикарное",
            "count": 1,
            "phrase": "за такую цену такое шикарное качество"
          },
          {
            "word": "удобен",
            "count": 4,
            "phrase": "удобен в использовании"
          },
          {
            "word": "рада",
            "count": 3,
            "phrase": "я очень рада покупке"
          },
          {
            "word": "эффекта",
            "count": 1,
            "phrase": "не ожидала такого эффекта"
          },
          {
            "word": "буду",
            "count": 2,
            "phrase": "с удовольствием буду пылесосить"
          },
          {
            "word": "пылесосить",
            "count": 7,
            "phrase": "с удовольствием буду пылесосить"
          },
          {
            "word": "тяжеловат",
            "count": 2,
            "phrase": "немного тяжеловат"
          },
          {
            "word": "привыкнуть",
            "count": 2,
            "phrase": "нужно привыкнуть зав свои деньги"
          },
          {
            "word": "зав",
            "count": 3,
            "phrase": "нужно привыкнуть зав свои деньги"
          },
          {
            "word": "грязь",
            "count": 4,
            "phrase": "пыль и грязь всю всасывает"
          },
          {
            "word": "общем",
            "count": 5,
            "phrase": "в общем я осталась довольна"
          },
          {
            "word": "осталась",
            "count": 1,
            "phrase": "в общем я осталась довольна"
          },
          {
            "word": "смотрите",
            "count": 0,
            "phrase": "смотрите фото"
          },
          {
            "word": "фото",
            "count": 3,
            "phrase": "смотрите фото"
          },
          {
            "word": "нарушена",
            "count": 1,
            "phrase": "упаковка не нарушена"
          },
          {
            "word": "комплекте",
            "count": 5,
            "phrase": "всё в комплекте"
          },
          {
            "word": "хотела",
            "count": 4,
            "phrase": "то что я хотела"
          },
          {
            "word": "разу",
            "count": 7,
            "phrase": "ни разу не пожалели"
          },
          {
            "word": "пожалели",
            "count": 1,
            "phrase": "ни разу не пожалели"
          },
          {
            "word": "приобрели",
            "count": 1,
            "phrase": "что приобрели такой пылесос"
          },
          {
            "word": "такой",
            "count": 12,
            "phrase": "что приобрели такой пылесос"
          },
          {
            "word": "работой",
            "count": 1,
            "phrase": "хорошо справляется со своей работой"
          },
          {
            "word": "корвалана",
            "count": 0,
            "phrase": "но для корвалана все же пылесос нужно помощнее"
          },
          {
            "word": "помощнее",
            "count": 1,
            "phrase": "но для корвалана все же пылесос нужно помощнее"
          },
          {
            "word": "провели",
            "count": 1,
            "phrase": "провели работает"
          },
          {
            "word": "надеемся",
            "count": 1,
            "phrase": "надеемся будет прослужит долго"
          },
          {
            "word": "первый",
            "count": 6,
            "phrase": "пользовалась первый раз"
          },
          {
            "word": "раз",
            "count": 36,
            "phrase": "пользовалась первый раз"
          },
          {
            "word": "дальше",
            "count": 4,
            "phrase": "посмотрим как будет дальше"
          },
          {
            "word": "эту",
            "count": 6,
            "phrase": "но за эту цену качество хорошее"
          },
          {
            "word": "хорошее",
            "count": 4,
            "phrase": "но за эту цену качество хорошее"
          },
          {
            "word": "остальное",
            "count": 1,
            "phrase": "отлично убирает всю пыль и остальное"
          },
          {
            "word": "доволен",
            "count": 9,
            "phrase": "очень доволен"
          },
          {
            "word": "замечательно",
            "count": 2,
            "phrase": "все замечательно"
          },
          {
            "word": "место",
            "count": 4,
            "phrase": "мало место занимает"
          },
          {
            "word": "всос",
            "count": 3,
            "phrase": "всос хороший"
          },
          {
            "word": "главное",
            "count": 5,
            "phrase": "а главное не тяжёлый"
          },
          {
            "word": "тяжёлый",
            "count": 3,
            "phrase": "а главное не тяжёлый"
          },
          {
            "word": "цена",
            "count": 3,
            "phrase": "цена качество топчик"
          },
          {
            "word": "топчик",
            "count": 1,
            "phrase": "цена качество топчик"
          },
          {
            "word": "выглядит",
            "count": 2,
            "phrase": "выглядит стильно убирает хорошо"
          },
          {
            "word": "стильно",
            "count": 1,
            "phrase": "выглядит стильно убирает хорошо"
          },
          {
            "word": "пропылесосила",
            "count": 3,
            "phrase": "пропылесосила всю мебель в квартире и ковры"
          },
          {
            "word": "мебель",
            "count": 2,
            "phrase": "пропылесосила всю мебель в квартире и ковры"
          },
          {
            "word": "квартире",
            "count": 4,
            "phrase": "пропылесосила всю мебель в квартире и ковры"
          },
          {
            "word": "нормальный",
            "count": 3,
            "phrase": "пылесос нормальный"
          },
          {
            "word": "подскажите",
            "count": 1,
            "phrase": "подскажите он разве без щетки идёт"
          },
          {
            "word": "щетки",
            "count": 2,
            "phrase": "подскажите он разве без щетки идёт"
          },
          {
            "word": "идёт",
            "count": 2,
            "phrase": "подскажите он разве без щетки идёт"
          },
          {
            "word": "вопрос",
            "count": 4,
            "phrase": "если с ней то как решить этот вопрос"
          },
          {
            "word": "всего",
            "count": 2,
            "phrase": "удобный в использовании для всего"
          },
          {
            "word": "коврах",
            "count": 1,
            "phrase": "шерсть на коврах нормально собирает"
          },
          {
            "word": "нормально",
            "count": 7,
            "phrase": "шерсть на коврах нормально собирает"
          },
          {
            "word": "понрпонравилсямой",
            "count": 1,
            "phrase": "пылесос отличный жене понрпонравилсямой мой подподарок стоит радрадуется реком"
          },
          {
            "word": "подподарок",
            "count": 1,
            "phrase": "пылесос отличный жене понрпонравилсямой мой подподарок стоит радрадуется реком"
          },
          {
            "word": "стоит",
            "count": 3,
            "phrase": "пылесос отличный жене понрпонравилсямой мой подподарок стоит радрадуется реком"
          },
          {
            "word": "радрадуется",
            "count": 1,
            "phrase": "пылесос отличный жене понрпонравилсямой мой подподарок стоит радрадуется реком"
          },
          {
            "word": "реком",
            "count": 27,
            "phrase": "пылесос отличный жене понрпонравилсямой мой подподарок стоит радрадуется реком"
          },
          {
            "word": "возьму",
            "count": 1,
            "phrase": "возьму ещё тете своей на подарок"
          },
          {
            "word": "тете",
            "count": 1,
            "phrase": "возьму ещё тете своей на подарок"
          },
          {
            "word": "класс",
            "count": 12,
            "phrase": "пылесос класс"
          },
          {
            "word": "вполне",
            "count": 2,
            "phrase": "за такие деньги вполне отличный пылесос"
          },
          {
            "word": "порадовал",
            "count": 2,
            "phrase": "меня очень порадовал"
          },
          {
            "word": "соответствует",
            "count": 6,
            "phrase": "все соответствует"
          },
          {
            "word": "огонь",
            "count": 8,
            "phrase": "пылесос огонь"
          },
          {
            "word": "всасывание",
            "count": 3,
            "phrase": "просто супер всасывание"
          },
          {
            "word": "всасал",
            "count": 1,
            "phrase": "и пыль всасал и волосы"
          },
          {
            "word": "шустрый",
            "count": 2,
            "phrase": "очень шустрый"
          },
          {
            "word": "всем",
            "count": 6,
            "phrase": "всем рекомендую"
          },
          {
            "word": "сборка",
            "count": 3,
            "phrase": "сборка идеальная"
          },
          {
            "word": "идеальная",
            "count": 1,
            "phrase": "сборка идеальная"
          },
          {
            "word": "аппарат",
            "count": 3,
            "phrase": "отличный аппарат за свои деньги"
          },
          {
            "word": "симпатичный",
            "count": 1,
            "phrase": "симпатичный пылесосик"
          },
          {
            "word": "пылесосик",
            "count": 1,
            "phrase": "симпатичный пылесосик"
          },
          {
            "word": "довольно",
            "count": 2,
            "phrase": "работает довольно тихо"
          },
          {
            "word": "насадки",
            "count": 12,
            "phrase": "3 насадки в комплекте"
          },
          {
            "word": "товаром",
            "count": 3,
            "phrase": "товаром доволен"
          },
          {
            "word": "ожидал",
            "count": 13,
            "phrase": "даже не ожидал"
          },
          {
            "word": "понравится",
            "count": 1,
            "phrase": "что он так понравится"
          },
          {
            "word": "пылесос!компактно",
            "count": 1,
            "phrase": "хороший пылесос!компактно упакован!пришло все целое"
          },
          {
            "word": "упакован!пришло",
            "count": 1,
            "phrase": "хороший пылесос!компактно упакован!пришло все целое"
          },
          {
            "word": "понравилось",
            "count": 2,
            "phrase": "всем понравилось"
          },
          {
            "word": "советую",
            "count": 5,
            "phrase": "советую к покупке"
          },
          {
            "word": "полнополностью",
            "count": 1,
            "phrase": "я полнополностью лгаолкедоволен"
          },
          {
            "word": "лгаолкедоволен",
            "count": 1,
            "phrase": "я полнополностью лгаолкедоволен"
          },
          {
            "word": "насадка",
            "count": 2,
            "phrase": "насадка вращается на 180градусов"
          },
          {
            "word": "вращается",
            "count": 2,
            "phrase": "насадка вращается на 180градусов"
          },
          {
            "word": "180градусов",
            "count": 1,
            "phrase": "насадка вращается на 180градусов"
          },
          {
            "word": "быструю",
            "count": 2,
            "phrase": "спасибо за быструю доставку"
          },
          {
            "word": "доставку",
            "count": 4,
            "phrase": "спасибо за быструю доставку"
          },
          {
            "word": "классный",
            "count": 3,
            "phrase": "классный пылесос"
          },
          {
            "word": "характеристике",
            "count": 1,
            "phrase": "полностью соответствует характеристике"
          },
          {
            "word": "безворсовые",
            "count": 1,
            "phrase": "безворсовые ковры тоже чистит"
          },
          {
            "word": "трёшки",
            "count": 2,
            "phrase": "шнур для трёшки коротковат"
          },
          {
            "word": "коротковат",
            "count": 3,
            "phrase": "шнур для трёшки коротковат"
          },
          {
            "word": "переставить",
            "count": 1,
            "phrase": "но переставить в другую розетку не сложно"
          },
          {
            "word": "другую",
            "count": 1,
            "phrase": "но переставить в другую розетку не сложно"
          },
          {
            "word": "розетку",
            "count": 1,
            "phrase": "но переставить в другую розетку не сложно"
          },
          {
            "word": "сложно",
            "count": 3,
            "phrase": "но переставить в другую розетку не сложно"
          },
          {
            "word": "заводской",
            "count": 2,
            "phrase": "пришел в заводской коробке"
          },
          {
            "word": "аккуратное",
            "count": 1,
            "phrase": "всё аккуратное"
          },
          {
            "word": "картинке",
            "count": 1,
            "phrase": "пылесос пришёл один в один как на картинке"
          },
          {
            "word": "пользуюсь",
            "count": 0,
            "phrase": "пользуюсь каждый день"
          },
          {
            "word": "никаких",
            "count": 1,
            "phrase": "никаких нареканий нет"
          },
          {
            "word": "нареканий",
            "count": 1,
            "phrase": "никаких нареканий нет"
          },
          {
            "word": "длины",
            "count": 1,
            "phrase": "длины провода хватает"
          },
          {
            "word": "хватает",
            "count": 8,
            "phrase": "длины провода хватает"
          },
          {
            "word": "пылесо",
            "count": 193,
            "phrase": "супер пылесо"
          },
          {
            "word": "2700",
            "count": 1,
            "phrase": "за 2700 сойдёт"
          },
          {
            "word": "сойдёт",
            "count": 2,
            "phrase": "за 2700 сойдёт"
          },
          {
            "word": "заказала",
            "count": 1,
            "phrase": "заказала такой пылесоск"
          },
          {
            "word": "пылесоск",
            "count": 1,
            "phrase": "заказала такой пылесоск"
          },
          {
            "word": "муж",
            "count": 1,
            "phrase": "как муж назвал"
          },
          {
            "word": "назвал",
            "count": 1,
            "phrase": "как муж назвал"
          },
          {
            "word": "доставать",
            "count": 1,
            "phrase": "а доставать"
          },
          {
            "word": "собирать",
            "count": 1,
            "phrase": "собирать большой пылесос каждое утро тоже время"
          },
          {
            "word": "каждое",
            "count": 1,
            "phrase": "собирать большой пылесос каждое утро тоже время"
          },
          {
            "word": "утро",
            "count": 1,
            "phrase": "собирать большой пылесос каждое утро тоже время"
          },
          {
            "word": "время",
            "count": 9,
            "phrase": "собирать большой пылесос каждое утро тоже время"
          },
          {
            "word": "удобным",
            "count": 1,
            "phrase": "с этим небольшим и удобным помощником стало намного удобнее"
          },
          {
            "word": "помощником",
            "count": 2,
            "phrase": "с этим небольшим и удобным помощником стало намного удобнее"
          },
          {
            "word": "стало",
            "count": 1,
            "phrase": "с этим небольшим и удобным помощником стало намного удобнее"
          },
          {
            "word": "намного",
            "count": 3,
            "phrase": "с этим небольшим и удобным помощником стало намного удобнее"
          },
          {
            "word": "удобнее",
            "count": 2,
            "phrase": "с этим небольшим и удобным помощником стало намного удобнее"
          },
          {
            "word": "собирается",
            "count": 3,
            "phrase": "собирается легко и просто"
          },
          {
            "word": "убирать",
            "count": 3,
            "phrase": "можно убирать в машине"
          },
          {
            "word": "машине",
            "count": 1,
            "phrase": "можно убирать в машине"
          },
          {
            "word": "объем",
            "count": 3,
            "phrase": "т к объем контейнера маленький"
          },
          {
            "word": "контейнера",
            "count": 3,
            "phrase": "т к объем контейнера маленький"
          },
          {
            "word": "быстенькую",
            "count": 1,
            "phrase": "а быстенькую очень классно"
          },
          {
            "word": "классно",
            "count": 2,
            "phrase": "а быстенькую очень классно"
          },
          {
            "word": "производителям",
            "count": 1,
            "phrase": "спасибо производителям"
          },
          {
            "word": "пришла",
            "count": 2,
            "phrase": "доставка тоже пришла быстро"
          },
          {
            "word": "проблем",
            "count": 5,
            "phrase": "без проблем коробка целая"
          },
          {
            "word": "ничего",
            "count": 3,
            "phrase": "ничего не побилось"
          },
          {
            "word": "побилось",
            "count": 1,
            "phrase": "ничего не побилось"
          },
          {
            "word": "более",
            "count": 1,
            "phrase": "конечно более объёмную и длительную уборку будет провести проблематично"
          },
          {
            "word": "объёмную",
            "count": 1,
            "phrase": "конечно более объёмную и длительную уборку будет провести проблематично"
          },
          {
            "word": "длительную",
            "count": 1,
            "phrase": "конечно более объёмную и длительную уборку будет провести проблематично"
          },
          {
            "word": "уборку",
            "count": 4,
            "phrase": "конечно более объёмную и длительную уборку будет провести проблематично"
          },
          {
            "word": "провести",
            "count": 1,
            "phrase": "конечно более объёмную и длительную уборку будет провести проблематично"
          },
          {
            "word": "проблематично",
            "count": 1,
            "phrase": "конечно более объёмную и длительную уборку будет провести проблематично"
          },
          {
            "word": "высокого",
            "count": 1,
            "phrase": "с высокого ворса конечно не дотягивает"
          },
          {
            "word": "ворса",
            "count": 2,
            "phrase": "с высокого ворса конечно не дотягивает"
          },
          {
            "word": "дотягивает",
            "count": 1,
            "phrase": "с высокого ворса конечно не дотягивает"
          },
          {
            "word": "ковровое",
            "count": 2,
            "phrase": "но ковровое покрытие берет на ура"
          },
          {
            "word": "покрытие",
            "count": 3,
            "phrase": "но ковровое покрытие берет на ура"
          },
          {
            "word": "берет",
            "count": 2,
            "phrase": "но ковровое покрытие берет на ура"
          },
          {
            "word": "чистится",
            "count": 2,
            "phrase": "чистится прекрасно"
          },
          {
            "word": "хранить",
            "count": 1,
            "phrase": "хранить можно и разобранным"
          },
          {
            "word": "разобранным",
            "count": 1,
            "phrase": "хранить можно и разобранным"
          },
          {
            "word": "вобше",
            "count": 1,
            "phrase": "это вобше по объему чуть больше обувной коробки"
          },
          {
            "word": "объему",
            "count": 1,
            "phrase": "это вобше по объему чуть больше обувной коробки"
          },
          {
            "word": "чуть",
            "count": 5,
            "phrase": "это вобше по объему чуть больше обувной коробки"
          },
          {
            "word": "больше",
            "count": 4,
            "phrase": "это вобше по объему чуть больше обувной коробки"
          },
          {
            "word": "обувной",
            "count": 1,
            "phrase": "это вобше по объему чуть больше обувной коробки"
          },
          {
            "word": "коробки",
            "count": 1,
            "phrase": "это вобше по объему чуть больше обувной коробки"
          },
          {
            "word": "положил",
            "count": 6,
            "phrase": "в подарок продавец положил тряпочку для уборки"
          },
          {
            "word": "которую",
            "count": 1,
            "phrase": "которую как раз приспособила для чистки контейнера"
          },
          {
            "word": "приспособила",
            "count": 1,
            "phrase": "которую как раз приспособила для чистки контейнера"
          },
          {
            "word": "чистки",
            "count": 2,
            "phrase": "которую как раз приспособила для чистки контейнера"
          },
          {
            "word": "чудесный",
            "count": 1,
            "phrase": "пылесос чудесный"
          },
          {
            "word": "крепкий",
            "count": 1,
            "phrase": "крепкий корпус"
          },
          {
            "word": "корпус",
            "count": 2,
            "phrase": "крепкий корпус"
          },
          {
            "word": "длинный",
            "count": 7,
            "phrase": "длинный шнур"
          },
          {
            "word": "приспособлен",
            "count": 1,
            "phrase": "он не приспособлен для чистки ковровых покрытий"
          },
          {
            "word": "ковровых",
            "count": 1,
            "phrase": "он не приспособлен для чистки ковровых покрытий"
          },
          {
            "word": "покрытий",
            "count": 1,
            "phrase": "он не приспособлен для чистки ковровых покрытий"
          },
          {
            "word": "только",
            "count": 15,
            "phrase": "если только с совсем коротким орсом"
          },
          {
            "word": "коротким",
            "count": 2,
            "phrase": "если только с совсем коротким орсом"
          },
          {
            "word": "орсом",
            "count": 4,
            "phrase": "если только с совсем коротким орсом"
          },
          {
            "word": "тянет",
            "count": 2,
            "phrase": "тянет отлично"
          },
          {
            "word": "самое",
            "count": 5,
            "phrase": "для уборки пыли самое то"
          },
          {
            "word": "любую",
            "count": 1,
            "phrase": "насадки в любую щель залезут"
          },
          {
            "word": "щель",
            "count": 1,
            "phrase": "насадки в любую щель залезут"
          },
          {
            "word": "залезут",
            "count": 1,
            "phrase": "насадки в любую щель залезут"
          },
          {
            "word": "верхний",
            "count": 0,
            "phrase": "верхний фильтр легко снимается и стирается"
          },
          {
            "word": "снимается",
            "count": 2,
            "phrase": "верхний фильтр легко снимается и стирается"
          },
          {
            "word": "стирается",
            "count": 1,
            "phrase": "верхний фильтр легко снимается и стирается"
          },
          {
            "word": "начитавшись",
            "count": 0,
            "phrase": "начитавшись отзывов"
          },
          {
            "word": "отзывов",
            "count": 2,
            "phrase": "начитавшись отзывов"
          },
          {
            "word": "практически",
            "count": 2,
            "phrase": "практически все хорошие"
          },
          {
            "word": "хорошие",
            "count": 2,
            "phrase": "практически все хорошие"
          },
          {
            "word": "решилась",
            "count": 2,
            "phrase": "решилась купить"
          },
          {
            "word": "купить",
            "count": 1,
            "phrase": "решилась купить"
          },
          {
            "word": "ожидания",
            "count": 3,
            "phrase": "ожидания были меньше чем сотворило"
          },
          {
            "word": "были",
            "count": 2,
            "phrase": "ожидания были меньше чем сотворило"
          },
          {
            "word": "сотворило",
            "count": 1,
            "phrase": "ожидания были меньше чем сотворило"
          },
          {
            "word": "чудо",
            "count": 2,
            "phrase": "это чудо"
          },
          {
            "word": "моей",
            "count": 1,
            "phrase": "в моей квартире везде ковровое покрытие"
          },
          {
            "word": "везде",
            "count": 2,
            "phrase": "в моей квартире везде ковровое покрытие"
          },
          {
            "word": "мелкую",
            "count": 2,
            "phrase": "что без проблем собирает даже мелкую кошачью шерсть"
          },
          {
            "word": "кошачью",
            "count": 1,
            "phrase": "что без проблем собирает даже мелкую кошачью шерсть"
          },
          {
            "word": "вся",
            "count": 4,
            "phrase": "вся семья рада этой покупке"
          },
          {
            "word": "семья",
            "count": 2,
            "phrase": "вся семья рада этой покупке"
          },
          {
            "word": "этой",
            "count": 3,
            "phrase": "вся семья рада этой покупке"
          },
          {
            "word": "одного",
            "count": 1,
            "phrase": "кроме одного члена"
          },
          {
            "word": "члена",
            "count": 1,
            "phrase": "кроме одного члена"
          },
          {
            "word": "шуня",
            "count": 0,
            "phrase": "кот шуня"
          },
          {
            "word": "девушка",
            "count": 0,
            "phrase": "девушка писала что лучше одеть чулок"
          },
          {
            "word": "писала",
            "count": 2,
            "phrase": "девушка писала что лучше одеть чулок"
          },
          {
            "word": "лучше",
            "count": 5,
            "phrase": "девушка писала что лучше одеть чулок"
          },
          {
            "word": "одеть",
            "count": 1,
            "phrase": "девушка писала что лучше одеть чулок"
          },
          {
            "word": "чулок",
            "count": 1,
            "phrase": "девушка писала что лучше одеть чулок"
          },
          {
            "word": "кошачья",
            "count": 2,
            "phrase": "так как кошачья шерсть забивается в фильтр"
          },
          {
            "word": "забивается",
            "count": 1,
            "phrase": "так как кошачья шерсть забивается в фильтр"
          },
          {
            "word": "забрала",
            "count": 3,
            "phrase": "как забрала"
          },
          {
            "word": "опробовала",
            "count": 2,
            "phrase": "сразу опробовала"
          },
          {
            "word": "своих",
            "count": 3,
            "phrase": "для своих размеров хорошо сосёт"
          },
          {
            "word": "размеров",
            "count": 1,
            "phrase": "для своих размеров хорошо сосёт"
          },
          {
            "word": "сосёт",
            "count": 2,
            "phrase": "для своих размеров хорошо сосёт"
          },
          {
            "word": "помощь",
            "count": 1,
            "phrase": "брала в помощь к роботу пылесосу"
          },
          {
            "word": "роботу",
            "count": 1,
            "phrase": "брала в помощь к роботу пылесосу"
          },
          {
            "word": "пылесосу",
            "count": 2,
            "phrase": "брала в помощь к роботу пылесосу"
          },
          {
            "word": "запрыгивает",
            "count": 1,
            "phrase": "который на диван не запрыгивает"
          },
          {
            "word": "собрать",
            "count": 3,
            "phrase": "что бы собрать волосы собак и коврик у входной двери"
          },
          {
            "word": "собак",
            "count": 8,
            "phrase": "что бы собрать волосы собак и коврик у входной двери"
          },
          {
            "word": "коврик",
            "count": 3,
            "phrase": "что бы собрать волосы собак и коврик у входной двери"
          },
          {
            "word": "входной",
            "count": 1,
            "phrase": "что бы собрать волосы собак и коврик у входной двери"
          },
          {
            "word": "двери",
            "count": 1,
            "phrase": "что бы собрать волосы собак и коврик у входной двери"
          },
          {
            "word": "песочница",
            "count": 1,
            "phrase": "который как песочница"
          },
          {
            "word": "конца",
            "count": 1,
            "phrase": "до конца не вычещает"
          },
          {
            "word": "вычещает",
            "count": 1,
            "phrase": "до конца не вычещает"
          },
          {
            "word": "берем",
            "count": 1,
            "phrase": "берем уже 2-й этой фирмы"
          },
          {
            "word": "2-й",
            "count": 1,
            "phrase": "берем уже 2-й этой фирмы"
          },
          {
            "word": "фирмы",
            "count": 1,
            "phrase": "берем уже 2-й этой фирмы"
          },
          {
            "word": "прошлый",
            "count": 1,
            "phrase": "прошлый был 3 года и по сей день работает исправно"
          },
          {
            "word": "года",
            "count": 4,
            "phrase": "прошлый был 3 года и по сей день работает исправно"
          },
          {
            "word": "сей",
            "count": 4,
            "phrase": "прошлый был 3 года и по сей день работает исправно"
          },
          {
            "word": "недостаток",
            "count": 1,
            "phrase": "недостаток маленький- неудобный каркас для накручивания провода но это ерунда"
          },
          {
            "word": "маленький-",
            "count": 1,
            "phrase": "недостаток маленький- неудобный каркас для накручивания провода но это ерунда"
          },
          {
            "word": "неудобный",
            "count": 1,
            "phrase": "недостаток маленький- неудобный каркас для накручивания провода но это ерунда"
          },
          {
            "word": "каркас",
            "count": 1,
            "phrase": "недостаток маленький- неудобный каркас для накручивания провода но это ерунда"
          },
          {
            "word": "накручивания",
            "count": 1,
            "phrase": "недостаток маленький- неудобный каркас для накручивания провода но это ерунда"
          },
          {
            "word": "ерунда",
            "count": 1,
            "phrase": "недостаток маленький- неудобный каркас для накручивания провода но это ерунда"
          },
          {
            "word": "слабее",
            "count": 2,
            "phrase": "мощность чуть слабее стандартного большого пылесоса"
          },
          {
            "word": "стандартного",
            "count": 1,
            "phrase": "мощность чуть слабее стандартного большого пылесоса"
          },
          {
            "word": "большого",
            "count": 2,
            "phrase": "мощность чуть слабее стандартного большого пылесоса"
          },
          {
            "word": "ежедневной",
            "count": 4,
            "phrase": "для ежедневной уборки очень комфортен"
          },
          {
            "word": "комфортен",
            "count": 1,
            "phrase": "для ежедневной уборки очень комфортен"
          },
          {
            "word": "зверь",
            "count": 1,
            "phrase": "пылесосит как зверь"
          },
          {
            "word": "мешает",
            "count": 1,
            "phrase": "единственный минус мешает кабель"
          },
          {
            "word": "кабель",
            "count": 1,
            "phrase": "единственный минус мешает кабель"
          },
          {
            "word": "путается",
            "count": 1,
            "phrase": "путается в ногах приходится держать в руках"
          },
          {
            "word": "ногах",
            "count": 1,
            "phrase": "путается в ногах приходится держать в руках"
          },
          {
            "word": "приходится",
            "count": 4,
            "phrase": "путается в ногах приходится держать в руках"
          },
          {
            "word": "руках",
            "count": 2,
            "phrase": "путается в ногах приходится держать в руках"
          },
          {
            "word": "покупала",
            "count": 0,
            "phrase": "покупала себе но отдам маме"
          },
          {
            "word": "отдам",
            "count": 1,
            "phrase": "покупала себе но отдам маме"
          },
          {
            "word": "пожилых",
            "count": 1,
            "phrase": "для пожилых людей это просто находка"
          },
          {
            "word": "людей",
            "count": 2,
            "phrase": "для пожилых людей это просто находка"
          },
          {
            "word": "разбирается",
            "count": 2,
            "phrase": "легко разбирается и собирается"
          },
          {
            "word": "неимоверно",
            "count": 0,
            "phrase": "неимоверно обалденный"
          },
          {
            "word": "обалденный",
            "count": 1,
            "phrase": "неимоверно обалденный"
          },
          {
            "word": "обычный",
            "count": 3,
            "phrase": "это обычный бытовой пылесос"
          },
          {
            "word": "бытовой",
            "count": 1,
            "phrase": "это обычный бытовой пылесос"
          },
          {
            "word": "домашний",
            "count": 1,
            "phrase": "всасывает как обычный домашний"
          },
          {
            "word": "вместительный",
            "count": 1,
            "phrase": "контейнер вместительный"
          },
          {
            "word": "одну",
            "count": 2,
            "phrase": "на одну уборку достаточно"
          },
          {
            "word": "детали",
            "count": 1,
            "phrase": "все детали качественные не хлюпкие"
          },
          {
            "word": "качественные",
            "count": 1,
            "phrase": "все детали качественные не хлюпкие"
          },
          {
            "word": "хлюпкие",
            "count": 1,
            "phrase": "все детали качественные не хлюпкие"
          },
          {
            "word": "шумит",
            "count": 1,
            "phrase": "и шумит даже меньше стационарного"
          },
          {
            "word": "стационарного",
            "count": 1,
            "phrase": "и шумит даже меньше стационарного"
          },
          {
            "word": "тряпрчка",
            "count": 1,
            "phrase": "тряпрчка в подарок"
          },
          {
            "word": "задержалась",
            "count": 1,
            "phrase": "только доставка задержалась 2 дня"
          },
          {
            "word": "дня",
            "count": 7,
            "phrase": "только доставка задержалась 2 дня"
          },
          {
            "word": "цены",
            "count": 7,
            "phrase": "пылесос класс для такой цены"
          },
          {
            "word": "перегривается",
            "count": 1,
            "phrase": "то что перегривается почти сразу же"
          },
          {
            "word": "почти",
            "count": 1,
            "phrase": "то что перегривается почти сразу же"
          },
          {
            "word": "самая",
            "count": 1,
            "phrase": "насадка самая широкая болтается в разные стороны"
          },
          {
            "word": "широкая",
            "count": 1,
            "phrase": "насадка самая широкая болтается в разные стороны"
          },
          {
            "word": "болтается",
            "count": 1,
            "phrase": "насадка самая широкая болтается в разные стороны"
          },
          {
            "word": "разные",
            "count": 3,
            "phrase": "насадка самая широкая болтается в разные стороны"
          },
          {
            "word": "колесики",
            "count": 1,
            "phrase": "колесики уводят пылесос в разные стороны"
          },
          {
            "word": "уводят",
            "count": 1,
            "phrase": "колесики уводят пылесос в разные стороны"
          },
          {
            "word": "маленкой",
            "count": 1,
            "phrase": "приходится пылесосить маленкой насадкой"
          },
          {
            "word": "насадкой",
            "count": 1,
            "phrase": "приходится пылесосить маленкой насадкой"
          },
          {
            "word": "оптимальной",
            "count": 0,
            "phrase": "оптимальной мощности пылесос"
          },
          {
            "word": "мощности",
            "count": 3,
            "phrase": "оптимальной мощности пылесос"
          },
          {
            "word": "матрасы",
            "count": 1,
            "phrase": "диван и матрасы пропылесосить хватает"
          },
          {
            "word": "пропылесосить",
            "count": 3,
            "phrase": "диван и матрасы пропылесосить хватает"
          },
          {
            "word": "проживут",
            "count": 1,
            "phrase": "единственный вопрос сколько проживут фильтры"
          },
          {
            "word": "фильтры",
            "count": 1,
            "phrase": "единственный вопрос сколько проживут фильтры"
          },
          {
            "word": "продаже",
            "count": 1,
            "phrase": "но пока в продаже есть сменные"
          },
          {
            "word": "сменные",
            "count": 1,
            "phrase": "но пока в продаже есть сменные"
          },
          {
            "word": "удобная",
            "count": 0,
            "phrase": "удобная ручка"
          },
          {
            "word": "ручка",
            "count": 2,
            "phrase": "удобная ручка"
          },
          {
            "word": "высота",
            "count": 1,
            "phrase": "хорошая высота"
          },
          {
            "word": "сильный",
            "count": 1,
            "phrase": "сильный мощный"
          },
          {
            "word": "может",
            "count": 4,
            "phrase": "может есть какое то специальное крепление на стену"
          },
          {
            "word": "какое",
            "count": 3,
            "phrase": "может есть какое то специальное крепление на стену"
          },
          {
            "word": "специальное",
            "count": 1,
            "phrase": "может есть какое то специальное крепление на стену"
          },
          {
            "word": "крепление",
            "count": 3,
            "phrase": "может есть какое то специальное крепление на стену"
          },
          {
            "word": "стену",
            "count": 1,
            "phrase": "может есть какое то специальное крепление на стену"
          },
          {
            "word": "ответа",
            "count": 0,
            "phrase": "ответа нет"
          },
          {
            "word": "вилимо",
            "count": 0,
            "phrase": "вилимо работники компании даже не читают"
          },
          {
            "word": "работники",
            "count": 1,
            "phrase": "вилимо работники компании даже не читают"
          },
          {
            "word": "компании",
            "count": 1,
            "phrase": "вилимо работники компании даже не читают"
          },
          {
            "word": "читают",
            "count": 1,
            "phrase": "вилимо работники компании даже не читают"
          },
          {
            "word": "кудато",
            "count": 1,
            "phrase": "просто его кудато надо ставить"
          },
          {
            "word": "ставить",
            "count": 4,
            "phrase": "просто его кудато надо ставить"
          },
          {
            "word": "углу",
            "count": 1,
            "phrase": "а в углу он едет"
          },
          {
            "word": "едет",
            "count": 1,
            "phrase": "а в углу он едет"
          },
          {
            "word": "провод",
            "count": 16,
            "phrase": "провод длинный"
          },
          {
            "word": "пол",
            "count": 64,
            "phrase": "хорошо чистит и пол и ковёр"
          },
          {
            "word": "ковёр",
            "count": 3,
            "phrase": "хорошо чистит и пол и ковёр"
          },
          {
            "word": "достаточная",
            "count": 1,
            "phrase": "достаточная длина провода"
          },
          {
            "word": "длина",
            "count": 1,
            "phrase": "достаточная длина провода"
          },
          {
            "word": "шумно",
            "count": 2,
            "phrase": "конечно шумно работает"
          },
          {
            "word": "мелочи",
            "count": 2,
            "phrase": "но для пылесоса такой цены это мелочи"
          },
          {
            "word": "целой",
            "count": 1,
            "phrase": "отличный пылесос пришёл в целой небольшой коробке"
          },
          {
            "word": "небольшой",
            "count": 4,
            "phrase": "отличный пылесос пришёл в целой небольшой коробке"
          },
          {
            "word": "длинношёрстных",
            "count": 1,
            "phrase": "а у меня не много от двух длинношёрстных собак"
          },
          {
            "word": "шнура",
            "count": 1,
            "phrase": "длины шнура очень даже хватает"
          },
          {
            "word": "шёл",
            "count": 9,
            "phrase": "пылесос шёл неделю"
          },
          {
            "word": "неделю",
            "count": 2,
            "phrase": "пылесос шёл неделю"
          },
          {
            "word": "дней",
            "count": 2,
            "phrase": "5 дней по складам казани"
          },
          {
            "word": "складам",
            "count": 1,
            "phrase": "5 дней по складам казани"
          },
          {
            "word": "казани",
            "count": 0,
            "phrase": "5 дней по складам казани"
          },
          {
            "word": "возвращать",
            "count": 1,
            "phrase": "уже хотела возвращать деньги но решила ещё 2 дней подождать"
          },
          {
            "word": "решила",
            "count": 3,
            "phrase": "уже хотела возвращать деньги но решила ещё 2 дней подождать"
          },
          {
            "word": "подождать",
            "count": 1,
            "phrase": "уже хотела возвращать деньги но решила ещё 2 дней подождать"
          },
          {
            "word": "вобщем",
            "count": 1,
            "phrase": "вобщем получила"
          },
          {
            "word": "поробывала",
            "count": 1,
            "phrase": "и поробывала в действии хорош"
          },
          {
            "word": "действии",
            "count": 1,
            "phrase": "и поробывала в действии хорош"
          },
          {
            "word": "хорош",
            "count": 101,
            "phrase": "и поробывала в действии хорош"
          },
          {
            "word": "тяга",
            "count": 3,
            "phrase": "тяга отличная ковры чистит хорошо"
          },
          {
            "word": "нем",
            "count": 6,
            "phrase": "на нем ни царапины"
          },
          {
            "word": "царапины",
            "count": 1,
            "phrase": "на нем ни царапины"
          },
          {
            "word": "сейчас",
            "count": 2,
            "phrase": "это сейчас встречается все реже"
          },
          {
            "word": "встречается",
            "count": 1,
            "phrase": "это сейчас встречается все реже"
          },
          {
            "word": "реже",
            "count": 1,
            "phrase": "это сейчас встречается все реже"
          },
          {
            "word": "работе",
            "count": 4,
            "phrase": "в работе показал себя на отлично"
          },
          {
            "word": "показал",
            "count": 3,
            "phrase": "в работе показал себя на отлично"
          },
          {
            "word": "нибудь",
            "count": 1,
            "phrase": "если бы ещё было какое нибудь крепление к нему"
          },
          {
            "word": "керагранита",
            "count": 1,
            "phrase": "как с керагранита"
          },
          {
            "word": "паласа",
            "count": 1,
            "phrase": "так и с паласа"
          },
          {
            "word": "360",
            "count": 2,
            "phrase": "насадка вращается на 360 градусов"
          },
          {
            "word": "градусов",
            "count": 2,
            "phrase": "насадка вращается на 360 градусов"
          },
          {
            "word": "мыть",
            "count": 2,
            "phrase": "удобно мыть контейнер после уборки"
          },
          {
            "word": "забрали",
            "count": 0,
            "phrase": "забрали пылесос"
          },
          {
            "word": "отличное",
            "count": 0,
            "phrase": "отличное всасывание"
          },
          {
            "word": "удобные",
            "count": 2,
            "phrase": "насадки очень удобные для дальних углов"
          },
          {
            "word": "дальних",
            "count": 1,
            "phrase": "насадки очень удобные для дальних углов"
          },
          {
            "word": "углов",
            "count": 1,
            "phrase": "насадки очень удобные для дальних углов"
          },
          {
            "word": "ставится",
            "count": 1,
            "phrase": "что он просто ставится к стене"
          },
          {
            "word": "стене",
            "count": 1,
            "phrase": "что он просто ставится к стене"
          },
          {
            "word": "потрясающая",
            "count": 0,
            "phrase": "потрясающая находка за эту сумму"
          },
          {
            "word": "сумму",
            "count": 2,
            "phrase": "потрясающая находка за эту сумму"
          },
          {
            "word": "подарила",
            "count": 0,
            "phrase": "подарила сестре"
          },
          {
            "word": "сестре",
            "count": 2,
            "phrase": "подарила сестре"
          },
          {
            "word": "лаконичный",
            "count": 1,
            "phrase": "лаконичный и стильный дизайн"
          },
          {
            "word": "стильный",
            "count": 5,
            "phrase": "лаконичный и стильный дизайн"
          },
          {
            "word": "зхвататет",
            "count": 1,
            "phrase": "провода правда на дом не зхвататет 80кв"
          },
          {
            "word": "80кв",
            "count": 1,
            "phrase": "провода правда на дом не зхвататет 80кв"
          },
          {
            "word": "хотябы",
            "count": 1,
            "phrase": "хотя думала хотябы на две хватило комнаты"
          },
          {
            "word": "хватило",
            "count": 5,
            "phrase": "хотя думала хотябы на две хватило комнаты"
          },
          {
            "word": "каждой",
            "count": 1,
            "phrase": "но приходится в каждой включать"
          },
          {
            "word": "включать",
            "count": 2,
            "phrase": "но приходится в каждой включать"
          },
          {
            "word": "песня",
            "count": 1,
            "phrase": "пылесос - песня"
          },
          {
            "word": "комната",
            "count": 1,
            "phrase": "за 5 минут комната в 15 метров без шерсти"
          },
          {
            "word": "метров",
            "count": 2,
            "phrase": "за 5 минут комната в 15 метров без шерсти"
          },
          {
            "word": "шерсти",
            "count": 4,
            "phrase": "за 5 минут комната в 15 метров без шерсти"
          },
          {
            "word": "гудит",
            "count": 0,
            "phrase": "гудит не сильно"
          },
          {
            "word": "собака",
            "count": 2,
            "phrase": "правда собака всё равно шарахался"
          },
          {
            "word": "шарахался",
            "count": 1,
            "phrase": "правда собака всё равно шарахался"
          },
          {
            "word": "близко",
            "count": 1,
            "phrase": "который он так близко видит и слышит"
          },
          {
            "word": "видит",
            "count": 1,
            "phrase": "который он так близко видит и слышит"
          },
          {
            "word": "слышит",
            "count": 1,
            "phrase": "который он так близко видит и слышит"
          },
          {
            "word": "тому",
            "count": 7,
            "phrase": "к тому как держать мне надо привыкнуть"
          },
          {
            "word": "вертикального",
            "count": 1,
            "phrase": "раньше вертикального не было"
          },
          {
            "word": "берите",
            "count": 2,
            "phrase": "я даже не ожидал берите не пожалеете"
          },
          {
            "word": "результата",
            "count": 1,
            "phrase": "я даже не ожидала супер результата"
          },
          {
            "word": "служить",
            "count": 1,
            "phrase": "будет служить долго"
          },
          {
            "word": "месяц",
            "count": 4,
            "phrase": "пользуюсь месяц"
          },
          {
            "word": "просов",
            "count": 2,
            "phrase": "всё отлично по работе во просов нет"
          },
          {
            "word": "скручивать",
            "count": 1,
            "phrase": "а вот куда провод скручивать эта штука всё время отваливатся"
          },
          {
            "word": "эта",
            "count": 3,
            "phrase": "а вот куда провод скручивать эта штука всё время отваливатся"
          },
          {
            "word": "штука",
            "count": 2,
            "phrase": "а вот куда провод скручивать эта штука всё время отваливатся"
          },
          {
            "word": "отваливатся",
            "count": 1,
            "phrase": "а вот куда провод скручивать эта штука всё время отваливатся"
          },
          {
            "word": "ковров",
            "count": 11,
            "phrase": "очень хорошо собирает мусор и с ковров тоже"
          },
          {
            "word": "хлипкий",
            "count": 4,
            "phrase": "единственный минус хлипкий держатель для провода"
          },
          {
            "word": "держатель",
            "count": 2,
            "phrase": "единственный минус хлипкий держатель для провода"
          },
          {
            "word": "постоянно",
            "count": 2,
            "phrase": "постоянно слетает"
          },
          {
            "word": "слетает",
            "count": 1,
            "phrase": "постоянно слетает"
          },
          {
            "word": "мамы",
            "count": 2,
            "phrase": "закажу ещё один для мамы"
          },
          {
            "word": "вчера",
            "count": 0,
            "phrase": "вчера забрала и сразу принялась за уборку"
          },
          {
            "word": "принялась",
            "count": 1,
            "phrase": "вчера забрала и сразу принялась за уборку"
          },
          {
            "word": "знала",
            "count": 1,
            "phrase": "я не знала"
          },
          {
            "word": "тысячи",
            "count": 1,
            "phrase": "что за 3 тысячи можно хороший пылесос вщять"
          },
          {
            "word": "вщять",
            "count": 1,
            "phrase": "что за 3 тысячи можно хороший пылесос вщять"
          },
          {
            "word": "лёгкая",
            "count": 1,
            "phrase": "лёгкая и довольно - таки компактная вещь"
          },
          {
            "word": "компактная",
            "count": 1,
            "phrase": "лёгкая и довольно - таки компактная вещь"
          },
          {
            "word": "сила",
            "count": 8,
            "phrase": "сила всасывания отличная"
          },
          {
            "word": "всасывания",
            "count": 6,
            "phrase": "сила всасывания отличная"
          },
          {
            "word": "всосет",
            "count": 2,
            "phrase": "всосет так всосет"
          },
          {
            "word": "сравнении",
            "count": 1,
            "phrase": "еще очень удобный в сравнении с классическими пылесосами"
          },
          {
            "word": "классическими",
            "count": 1,
            "phrase": "еще очень удобный в сравнении с классическими пылесосами"
          },
          {
            "word": "пылесосами",
            "count": 1,
            "phrase": "еще очень удобный в сравнении с классическими пылесосами"
          },
          {
            "word": "всегда",
            "count": 1,
            "phrase": "как всегда топ за свои деньги"
          },
          {
            "word": "отличный!!!!мощный",
            "count": 0,
            "phrase": "пылесос отличный!!!!мощный"
          },
          {
            "word": "дешевый",
            "count": 1,
            "phrase": "а главное дешевый при таких качествах"
          },
          {
            "word": "таких",
            "count": 1,
            "phrase": "а главное дешевый при таких качествах"
          },
          {
            "word": "качествах",
            "count": 1,
            "phrase": "а главное дешевый при таких качествах"
          },
          {
            "word": "долгое",
            "count": 1,
            "phrase": "что будет работать долгое время"
          },
          {
            "word": "минусов",
            "count": 0,
            "phrase": "минусов на него пока не обнаружили"
          },
          {
            "word": "обнаружили",
            "count": 1,
            "phrase": "минусов на него пока не обнаружили"
          },
          {
            "word": "сначала",
            "count": 1,
            "phrase": "сначала показался маленький и хлипкий"
          },
          {
            "word": "показался",
            "count": 2,
            "phrase": "сначала показался маленький и хлипкий"
          },
          {
            "word": "звëзд",
            "count": 1,
            "phrase": "пять звëзд за дизайн"
          },
          {
            "word": "чëрном",
            "count": 1,
            "phrase": "в чëрном варианте"
          },
          {
            "word": "варианте",
            "count": 1,
            "phrase": "в чëрном варианте"
          },
          {
            "word": "локальной",
            "count": 1,
            "phrase": "купил для локальной уборки шерсти от кота"
          },
          {
            "word": "полноценой",
            "count": 1,
            "phrase": "для полноценой уборки дома применяется другой пылесос"
          },
          {
            "word": "дома",
            "count": 7,
            "phrase": "для полноценой уборки дома применяется другой пылесос"
          },
          {
            "word": "применяется",
            "count": 1,
            "phrase": "для полноценой уборки дома применяется другой пылесос"
          },
          {
            "word": "другой",
            "count": 1,
            "phrase": "для полноценой уборки дома применяется другой пылесос"
          },
          {
            "word": "похожий",
            "count": 1,
            "phrase": "до этого купила похожий пылесос в два раза дороже"
          },
          {
            "word": "раза",
            "count": 5,
            "phrase": "до этого купила похожий пылесос в два раза дороже"
          },
          {
            "word": "дороже",
            "count": 1,
            "phrase": "до этого купила похожий пылесос в два раза дороже"
          },
          {
            "word": "треснула",
            "count": 1,
            "phrase": "сразу же треснула колба"
          },
          {
            "word": "колба",
            "count": 1,
            "phrase": "сразу же треснула колба"
          },
          {
            "word": "прибор",
            "count": 2,
            "phrase": "этот прибор лучше"
          },
          {
            "word": "могла",
            "count": 1,
            "phrase": "чем могла предположить"
          },
          {
            "word": "предположить",
            "count": 1,
            "phrase": "чем могла предположить"
          },
          {
            "word": "дорольно",
            "count": 1,
            "phrase": "дорольно мощный и маневренный"
          },
          {
            "word": "скажите",
            "count": 2,
            "phrase": "скажите где покупать фильтр"
          },
          {
            "word": "где",
            "count": 3,
            "phrase": "скажите где покупать фильтр"
          },
          {
            "word": "покупать",
            "count": 1,
            "phrase": "скажите где покупать фильтр"
          },
          {
            "word": "инструкции",
            "count": 1,
            "phrase": "ведь его по инструкции нужно менять раз в 3 месяца"
          },
          {
            "word": "менять",
            "count": 1,
            "phrase": "ведь его по инструкции нужно менять раз в 3 месяца"
          },
          {
            "word": "месяца",
            "count": 2,
            "phrase": "ведь его по инструкции нужно менять раз в 3 месяца"
          },
          {
            "word": "узких",
            "count": 1,
            "phrase": "очень удобно пылесосить в узких местах"
          },
          {
            "word": "местах",
            "count": 1,
            "phrase": "очень удобно пылесосить в узких местах"
          },
          {
            "word": "функции",
            "count": 2,
            "phrase": "все функции выполняет на ура"
          },
          {
            "word": "выполняет",
            "count": 4,
            "phrase": "все функции выполняет на ура"
          },
          {
            "word": "бысря",
            "count": 1,
            "phrase": "доставка бысря"
          },
          {
            "word": "упаковно",
            "count": 1,
            "phrase": "упаковно в коробку"
          },
          {
            "word": "коробку",
            "count": 1,
            "phrase": "упаковно в коробку"
          },
          {
            "word": "работоспособность",
            "count": 0,
            "phrase": "работоспособность пооверено в пункте выдачи"
          },
          {
            "word": "пооверено",
            "count": 1,
            "phrase": "работоспособность пооверено в пункте выдачи"
          },
          {
            "word": "пункте",
            "count": 2,
            "phrase": "работоспособность пооверено в пункте выдачи"
          },
          {
            "word": "выдачи",
            "count": 2,
            "phrase": "работоспособность пооверено в пункте выдачи"
          },
          {
            "word": "брали",
            "count": 3,
            "phrase": "брали в подарок"
          },
          {
            "word": "ребёнку",
            "count": 1,
            "phrase": "ребёнку не поползать"
          },
          {
            "word": "поползать",
            "count": 1,
            "phrase": "ребёнку не поползать"
          },
          {
            "word": "этому",
            "count": 3,
            "phrase": "благодаря этому пылесосу"
          },
          {
            "word": "счастливы",
            "count": 1,
            "phrase": "все счастливы"
          },
          {
            "word": "мелкого",
            "count": 1,
            "phrase": "а для мелкого мусора"
          },
          {
            "word": "мусора",
            "count": 5,
            "phrase": "а для мелкого мусора"
          },
          {
            "word": "веника",
            "count": 3,
            "phrase": "вместо веника"
          },
          {
            "word": "прост",
            "count": 33,
            "phrase": "прост в применении"
          },
          {
            "word": "применении",
            "count": 1,
            "phrase": "прост в применении"
          },
          {
            "word": "боялся",
            "count": 1,
            "phrase": "немного боялся"
          },
          {
            "word": "получу",
            "count": 1,
            "phrase": "что получу очередную лажу"
          },
          {
            "word": "очередную",
            "count": 1,
            "phrase": "что получу очередную лажу"
          },
          {
            "word": "лажу",
            "count": 1,
            "phrase": "что получу очередную лажу"
          },
          {
            "word": "сделанная",
            "count": 1,
            "phrase": "сделанная по уму"
          },
          {
            "word": "уму",
            "count": 1,
            "phrase": "сделанная по уму"
          },
          {
            "word": "лучший",
            "count": 1,
            "phrase": "лучший пылесос"
          },
          {
            "word": "супруга",
            "count": 0,
            "phrase": "супруга довольна"
          },
          {
            "word": "пожилой",
            "count": 2,
            "phrase": "покупала пожилой женщине"
          },
          {
            "word": "женщине",
            "count": 1,
            "phrase": "покупала пожилой женщине"
          },
          {
            "word": "нагибаться",
            "count": 2,
            "phrase": "не нужно сильно нагибаться"
          },
          {
            "word": "пользоваться",
            "count": 1,
            "phrase": "но пользоваться будем"
          },
          {
            "word": "будем",
            "count": 1,
            "phrase": "но пользоваться будем"
          },
          {
            "word": "плох",
            "count": 9,
            "phrase": "за такую стоимость очень даже не плох"
          },
          {
            "word": "работаетюбрала",
            "count": 0,
            "phrase": "все исправно работаетюбрала в подарок"
          },
          {
            "word": "перемещать",
            "count": 1,
            "phrase": "легко перемещать его по дому"
          },
          {
            "word": "дому",
            "count": 1,
            "phrase": "легко перемещать его по дому"
          },
          {
            "word": "хранении",
            "count": 1,
            "phrase": "занимает мало места при хранении"
          },
          {
            "word": "здоровская",
            "count": 1,
            "phrase": "очень здоровская вещь"
          },
          {
            "word": "настолько",
            "count": 0,
            "phrase": "настолько понравилась"
          },
          {
            "word": "понравилась",
            "count": 3,
            "phrase": "настолько понравилась"
          },
          {
            "word": "сегодня",
            "count": 1,
            "phrase": "что сегодня несколько раз пылесосила и получала наслаждение"
          },
          {
            "word": "пылесосила",
            "count": 4,
            "phrase": "что сегодня несколько раз пылесосила и получала наслаждение"
          },
          {
            "word": "получала",
            "count": 1,
            "phrase": "что сегодня несколько раз пылесосила и получала наслаждение"
          },
          {
            "word": "наслаждение",
            "count": 1,
            "phrase": "что сегодня несколько раз пылесосила и получала наслаждение"
          },
          {
            "word": "использовала",
            "count": 1,
            "phrase": "использовала пока два раза но в целом довольна"
          },
          {
            "word": "ламинату",
            "count": 1,
            "phrase": "по ламинату убирает классно"
          },
          {
            "word": "справляться",
            "count": 1,
            "phrase": "не знаю как будет справляться с ковровым покрытием"
          },
          {
            "word": "ковровым",
            "count": 1,
            "phrase": "не знаю как будет справляться с ковровым покрытием"
          },
          {
            "word": "покрытием",
            "count": 1,
            "phrase": "не знаю как будет справляться с ковровым покрытием"
          },
          {
            "word": "прекрасный",
            "count": 0,
            "phrase": "прекрасный пылесос"
          },
          {
            "word": "прекрасная",
            "count": 0,
            "phrase": "прекрасная находка для любителей животных"
          },
          {
            "word": "любителей",
            "count": 1,
            "phrase": "прекрасная находка для любителей животных"
          },
          {
            "word": "животных",
            "count": 2,
            "phrase": "прекрасная находка для любителей животных"
          },
          {
            "word": "мгновенно",
            "count": 2,
            "phrase": "компактный и мгновенно собирает мусор"
          },
          {
            "word": "пушка",
            "count": 3,
            "phrase": "пылесос пушка бомба"
          },
          {
            "word": "шкур",
            "count": 0,
            "phrase": "шкур правда коротковат для трёшки"
          },
          {
            "word": "критично",
            "count": 1,
            "phrase": "но это не критично"
          },
          {
            "word": "тяжеловатым",
            "count": 1,
            "phrase": "сначала показался чуть тяжеловатым"
          },
          {
            "word": "привыкла",
            "count": 1,
            "phrase": "но сейчас привыкла"
          },
          {
            "word": "маленьких",
            "count": 1,
            "phrase": "для маленьких квартир очень удобно"
          },
          {
            "word": "квартир",
            "count": 13,
            "phrase": "для маленьких квартир очень удобно"
          },
          {
            "word": "крошками",
            "count": 1,
            "phrase": "с крошками и пылью справляется"
          },
          {
            "word": "насадке",
            "count": 2,
            "phrase": "что на насадке нет щетинок"
          },
          {
            "word": "щетинок",
            "count": 1,
            "phrase": "что на насадке нет щетинок"
          },
          {
            "word": "думаю",
            "count": 1,
            "phrase": "думаю из - за того"
          },
          {
            "word": "техника",
            "count": 3,
            "phrase": "отличная техника"
          },
          {
            "word": "съемный",
            "count": 1,
            "phrase": "съемный корпус"
          },
          {
            "word": "использовать",
            "count": 4,
            "phrase": "можно использовать для мебели"
          },
          {
            "word": "мебели",
            "count": 3,
            "phrase": "можно использовать для мебели"
          },
          {
            "word": "щелям",
            "count": 1,
            "phrase": "отлично работает по щелям"
          },
          {
            "word": "маленькую",
            "count": 1,
            "phrase": "нам в маленькую комнату с паласом самое то"
          },
          {
            "word": "комнату",
            "count": 2,
            "phrase": "нам в маленькую комнату с паласом самое то"
          },
          {
            "word": "паласом",
            "count": 1,
            "phrase": "нам в маленькую комнату с паласом самое то"
          },
          {
            "word": "высосал",
            "count": 1,
            "phrase": "столько пыли высосал мы в шоке"
          },
          {
            "word": "шоке",
            "count": 1,
            "phrase": "столько пыли высосал мы в шоке"
          },
          {
            "word": "оправдал",
            "count": 2,
            "phrase": "пылесос оправдал себя"
          },
          {
            "word": "вынимается",
            "count": 1,
            "phrase": "контейнер легко вынимается и вставляется"
          },
          {
            "word": "вставляется",
            "count": 1,
            "phrase": "контейнер легко вынимается и вставляется"
          },
          {
            "word": "дочери",
            "count": 1,
            "phrase": "брала дочери на второй этаж"
          },
          {
            "word": "второй",
            "count": 1,
            "phrase": "брала дочери на второй этаж"
          },
          {
            "word": "этаж",
            "count": 2,
            "phrase": "брала дочери на второй этаж"
          },
          {
            "word": "сама",
            "count": 3,
            "phrase": "чтобы сама пылисосила"
          },
          {
            "word": "пылисосила",
            "count": 2,
            "phrase": "чтобы сама пылисосила"
          },
          {
            "word": "размер",
            "count": 3,
            "phrase": "несмотря на размер"
          },
          {
            "word": "заказали",
            "count": 0,
            "phrase": "заказали опираясь на отзывы"
          },
          {
            "word": "опираясь",
            "count": 1,
            "phrase": "заказали опираясь на отзывы"
          },
          {
            "word": "чисто",
            "count": 3,
            "phrase": "чисто убирает разные покрытия и ковры в том числе"
          },
          {
            "word": "покрытия",
            "count": 2,
            "phrase": "чисто убирает разные покрытия и ковры в том числе"
          },
          {
            "word": "том",
            "count": 16,
            "phrase": "чисто убирает разные покрытия и ковры в том числе"
          },
          {
            "word": "числе",
            "count": 2,
            "phrase": "чисто убирает разные покрытия и ковры в том числе"
          },
          {
            "word": "сильное",
            "count": 1,
            "phrase": "сильное всасывание"
          },
          {
            "word": "проблема",
            "count": 2,
            "phrase": "если для вас не проблема"
          },
          {
            "word": "ковер",
            "count": 0,
            "phrase": "ковер в том числе"
          },
          {
            "word": "мусоросборник",
            "count": 1,
            "phrase": "маленький мусоросборник"
          },
          {
            "word": "должен",
            "count": 1,
            "phrase": "этот пылесос должен быть в каждом доме"
          },
          {
            "word": "быть",
            "count": 1,
            "phrase": "этот пылесос должен быть в каждом доме"
          },
          {
            "word": "каждом",
            "count": 1,
            "phrase": "этот пылесос должен быть в каждом доме"
          },
          {
            "word": "мощное",
            "count": 1,
            "phrase": "всасывание мощное"
          },
          {
            "word": "минусы",
            "count": 1,
            "phrase": "но это не минусы товара"
          },
          {
            "word": "товара",
            "count": 2,
            "phrase": "но это не минусы товара"
          },
          {
            "word": "пылесочить",
            "count": 1,
            "phrase": "легко пылесочить"
          },
          {
            "word": "первые",
            "count": 3,
            "phrase": "первые две уборки - отлично"
          },
          {
            "word": "простая",
            "count": 1,
            "phrase": "сборка простая"
          },
          {
            "word": "съемной",
            "count": 1,
            "phrase": "до этого на съемной квартире был хозяйский пылесос"
          },
          {
            "word": "хозяйский",
            "count": 1,
            "phrase": "до этого на съемной квартире был хозяйский пылесос"
          },
          {
            "word": "ощущениям",
            "count": 1,
            "phrase": "но по ощущениям выбрасывающий всю пыль обратно"
          },
          {
            "word": "выбрасывающий",
            "count": 1,
            "phrase": "но по ощущениям выбрасывающий всю пыль обратно"
          },
          {
            "word": "обратно",
            "count": 1,
            "phrase": "но по ощущениям выбрасывающий всю пыль обратно"
          },
          {
            "word": "тихий",
            "count": 1,
            "phrase": "этот не маленький и не тихий"
          },
          {
            "word": "первую",
            "count": 1,
            "phrase": "в первую очередь пропылесосили компьютер"
          },
          {
            "word": "очередь",
            "count": 1,
            "phrase": "в первую очередь пропылесосили компьютер"
          },
          {
            "word": "пропылесосили",
            "count": 1,
            "phrase": "в первую очередь пропылесосили компьютер"
          },
          {
            "word": "компьютер",
            "count": 1,
            "phrase": "в первую очередь пропылесосили компьютер"
          },
          {
            "word": "переднюю",
            "count": 1,
            "phrase": "переднюю стенку"
          },
          {
            "word": "стенку",
            "count": 1,
            "phrase": "переднюю стенку"
          },
          {
            "word": "пыльную",
            "count": 1,
            "phrase": "замечательно и мгновенно убрал пыльную махру"
          },
          {
            "word": "махру",
            "count": 1,
            "phrase": "замечательно и мгновенно убрал пыльную махру"
          },
          {
            "word": "искала",
            "count": 0,
            "phrase": "искала не беспроводной"
          },
          {
            "word": "беспроводной",
            "count": 1,
            "phrase": "искала не беспроводной"
          },
          {
            "word": "беспроводные",
            "count": 1,
            "phrase": "имхо беспроводные по определению менее мощные"
          },
          {
            "word": "определению",
            "count": 1,
            "phrase": "имхо беспроводные по определению менее мощные"
          },
          {
            "word": "мощные",
            "count": 1,
            "phrase": "имхо беспроводные по определению менее мощные"
          },
          {
            "word": "заряжать",
            "count": 1,
            "phrase": "да и заряжать технику не люблю"
          },
          {
            "word": "технику",
            "count": 1,
            "phrase": "да и заряжать технику не люблю"
          },
          {
            "word": "люблю",
            "count": 1,
            "phrase": "да и заряжать технику не люблю"
          },
          {
            "word": "срочно",
            "count": 0,
            "phrase": "срочно понадобилось"
          },
          {
            "word": "понадобилось",
            "count": 1,
            "phrase": "срочно понадобилось"
          },
          {
            "word": "включаешь",
            "count": 1,
            "phrase": "включаешь и убираешься"
          },
          {
            "word": "убираешься",
            "count": 1,
            "phrase": "включаешь и убираешься"
          },
          {
            "word": "длиннее",
            "count": 2,
            "phrase": "даже длиннее"
          },
          {
            "word": "представляла",
            "count": 1,
            "phrase": "чем я представляла"
          },
          {
            "word": "симпатично",
            "count": 1,
            "phrase": "выглядит симпатично"
          },
          {
            "word": "отличном",
            "count": 2,
            "phrase": "пришёл в отличном состоянии"
          },
          {
            "word": "состоянии",
            "count": 2,
            "phrase": "пришёл в отличном состоянии"
          },
          {
            "word": "выше",
            "count": 1,
            "phrase": "мощность выше ожидаемой для такого типа устройств"
          },
          {
            "word": "ожидаемой",
            "count": 1,
            "phrase": "мощность выше ожидаемой для такого типа устройств"
          },
          {
            "word": "типа",
            "count": 2,
            "phrase": "мощность выше ожидаемой для такого типа устройств"
          },
          {
            "word": "устройств",
            "count": 1,
            "phrase": "мощность выше ожидаемой для такого типа устройств"
          },
          {
            "word": "швабра",
            "count": 1,
            "phrase": "хорошая швабра"
          },
          {
            "word": "насколько",
            "count": 1,
            "phrase": "не знаю насколько хватить"
          },
          {
            "word": "хватить",
            "count": 1,
            "phrase": "не знаю насколько хватить"
          },
          {
            "word": "бюджетный",
            "count": 0,
            "phrase": "бюджетный пылесос"
          },
          {
            "word": "добротно",
            "count": 1,
            "phrase": "сделан добротно"
          },
          {
            "word": "перевозить",
            "count": 1,
            "phrase": "легко перевозить с собой куда угодно"
          },
          {
            "word": "задумываясь",
            "count": 1,
            "phrase": "берите не задумываясь"
          },
          {
            "word": "весу",
            "count": 2,
            "phrase": "по весу не тяжёлый"
          },
          {
            "word": "руки",
            "count": 1,
            "phrase": "руки с ним не устают"
          },
          {
            "word": "устают",
            "count": 1,
            "phrase": "руки с ним не устают"
          },
          {
            "word": "рост",
            "count": 40,
            "phrase": "на мой рост этот пылесос то что нужно"
          },
          {
            "word": "оперативно",
            "count": 0,
            "phrase": "оперативно доставили"
          },
          {
            "word": "доставили",
            "count": 3,
            "phrase": "оперативно доставили"
          },
          {
            "word": "успели",
            "count": 1,
            "phrase": "как раз успели к дню рождения свекрови"
          },
          {
            "word": "дню",
            "count": 2,
            "phrase": "как раз успели к дню рождения свекрови"
          },
          {
            "word": "рождения",
            "count": 1,
            "phrase": "как раз успели к дню рождения свекрови"
          },
          {
            "word": "свекрови",
            "count": 1,
            "phrase": "как раз успели к дню рождения свекрови"
          },
          {
            "word": "описании",
            "count": 3,
            "phrase": "все как в описании"
          },
          {
            "word": "производителю",
            "count": 2,
            "phrase": "спасибо производителю за хороший товар"
          },
          {
            "word": "рады",
            "count": 0,
            "phrase": "рады такой выгодной и нужной покупке"
          },
          {
            "word": "выгодной",
            "count": 1,
            "phrase": "рады такой выгодной и нужной покупке"
          },
          {
            "word": "нужной",
            "count": 1,
            "phrase": "рады такой выгодной и нужной покупке"
          },
          {
            "word": "облегчил",
            "count": 1,
            "phrase": "облегчил нам жизнь и избавил квартиру от крошек"
          },
          {
            "word": "жизнь",
            "count": 2,
            "phrase": "облегчил нам жизнь и избавил квартиру от крошек"
          },
          {
            "word": "избавил",
            "count": 1,
            "phrase": "облегчил нам жизнь и избавил квартиру от крошек"
          },
          {
            "word": "крошек",
            "count": 1,
            "phrase": "облегчил нам жизнь и избавил квартиру от крошек"
          },
          {
            "word": "засасывает",
            "count": 3,
            "phrase": "шерсть кота засасывает шустро"
          },
          {
            "word": "шустро",
            "count": 1,
            "phrase": "шерсть кота засасывает шустро"
          },
          {
            "word": "слегка",
            "count": 1,
            "phrase": "пылесос пришёл в слегка помятой коробке но внутри все цело"
          },
          {
            "word": "помятой",
            "count": 1,
            "phrase": "пылесос пришёл в слегка помятой коробке но внутри все цело"
          },
          {
            "word": "цело",
            "count": 14,
            "phrase": "пылесос пришёл в слегка помятой коробке но внутри все цело"
          },
          {
            "word": "взял",
            "count": 3,
            "phrase": "взял себе и маме 80 лет"
          },
          {
            "word": "давно",
            "count": 0,
            "phrase": "давно такой хотела"
          },
          {
            "word": "учетом",
            "count": 1,
            "phrase": "с учетом наличия двух котов"
          },
          {
            "word": "наличия",
            "count": 1,
            "phrase": "с учетом наличия двух котов"
          },
          {
            "word": "котов",
            "count": 2,
            "phrase": "с учетом наличия двух котов"
          },
          {
            "word": "покупаю",
            "count": 0,
            "phrase": "покупаю не первый"
          },
          {
            "word": "залезла",
            "count": 0,
            "phrase": "залезла во все щели где не достает робот пылесос"
          },
          {
            "word": "щели",
            "count": 2,
            "phrase": "залезла во все щели где не достает робот пылесос"
          },
          {
            "word": "достает",
            "count": 1,
            "phrase": "залезла во все щели где не достает робот пылесос"
          },
          {
            "word": "робот",
            "count": 2,
            "phrase": "залезла во все щели где не достает робот пылесос"
          },
          {
            "word": "первое",
            "count": 0,
            "phrase": "первое впечатление на отлично"
          },
          {
            "word": "впечатление",
            "count": 1,
            "phrase": "первое впечатление на отлично"
          },
          {
            "word": "качественный",
            "count": 4,
            "phrase": "пришел качественный"
          },
          {
            "word": "боялась",
            "count": 0,
            "phrase": "боялась заказывать с вб"
          },
          {
            "word": "пожалела",
            "count": 3,
            "phrase": "но решилась и не пожалела классный пылесос"
          },
          {
            "word": "плохой",
            "count": 6,
            "phrase": "не плохой пылесос"
          },
          {
            "word": "маленькой",
            "count": 1,
            "phrase": "для маленькой квартиры или же для одной комнаты самый раз"
          },
          {
            "word": "квартиры",
            "count": 3,
            "phrase": "для маленькой квартиры или же для одной комнаты самый раз"
          },
          {
            "word": "одной",
            "count": 4,
            "phrase": "для маленькой квартиры или же для одной комнаты самый раз"
          },
          {
            "word": "самый",
            "count": 3,
            "phrase": "для маленькой квартиры или же для одной комнаты самый раз"
          },
          {
            "word": "качеству",
            "count": 2,
            "phrase": "по качеству сборки и сам пластик понравился"
          },
          {
            "word": "сборки",
            "count": 3,
            "phrase": "по качеству сборки и сам пластик понравился"
          },
          {
            "word": "пластик",
            "count": 3,
            "phrase": "по качеству сборки и сам пластик понравился"
          },
          {
            "word": "денег",
            "count": 2,
            "phrase": "однозначно стоит своих денег"
          },
          {
            "word": "уровень",
            "count": 0,
            "phrase": "уровень шума невысокий"
          },
          {
            "word": "шума",
            "count": 1,
            "phrase": "уровень шума невысокий"
          },
          {
            "word": "невысокий",
            "count": 1,
            "phrase": "уровень шума невысокий"
          },
          {
            "word": "респект",
            "count": 0,
            "phrase": "респект продавцу за качественный товар"
          },
          {
            "word": "нравиться",
            "count": 1,
            "phrase": "пока все нравиться"
          },
          {
            "word": "средняя",
            "count": 1,
            "phrase": "мощность средняя"
          },
          {
            "word": "быстрой",
            "count": 3,
            "phrase": "для быстрой уборки подходит"
          },
          {
            "word": "подходит",
            "count": 2,
            "phrase": "для быстрой уборки подходит"
          },
          {
            "word": "выдрал",
            "count": 1,
            "phrase": "выдрал все под ноль"
          },
          {
            "word": "ноль",
            "count": 1,
            "phrase": "выдрал все под ноль"
          },
          {
            "word": "класнючий",
            "count": 1,
            "phrase": "пылесос класнючий"
          },
          {
            "word": "зверюга",
            "count": 1,
            "phrase": "мощный как зверюга"
          },
          {
            "word": "сборке",
            "count": 1,
            "phrase": "удобный и легкий в сборке"
          },
          {
            "word": "заморочек",
            "count": 1,
            "phrase": "сам контейнер от мусора чистится без заморочек"
          },
          {
            "word": "лвмиеате",
            "count": 1,
            "phrase": "на лвмиеате хорошо собирает тоже"
          },
          {
            "word": "вертикальном",
            "count": 1,
            "phrase": "давно мечтала о вертикальном пылесосе и наконец взяла"
          },
          {
            "word": "пылесосе",
            "count": 2,
            "phrase": "давно мечтала о вертикальном пылесосе и наконец взяла"
          },
          {
            "word": "наконец",
            "count": 1,
            "phrase": "давно мечтала о вертикальном пылесосе и наконец взяла"
          },
          {
            "word": "взяла",
            "count": 2,
            "phrase": "давно мечтала о вертикальном пылесосе и наконец взяла"
          },
          {
            "word": "порадовала",
            "count": 1,
            "phrase": "цена порадовала"
          },
          {
            "word": "продовцу",
            "count": 1,
            "phrase": "большое спасибо продовцу"
          },
          {
            "word": "пришол",
            "count": 1,
            "phrase": "товар пришол во время и в отличном состоянии"
          },
          {
            "word": "пробовала",
            "count": 4,
            "phrase": "сегодня пробовала в деле"
          },
          {
            "word": "деле",
            "count": 4,
            "phrase": "сегодня пробовала в деле"
          },
          {
            "word": "разы",
            "count": 1,
            "phrase": "который в разы уступает"
          },
          {
            "word": "уступает",
            "count": 1,
            "phrase": "который в разы уступает"
          },
          {
            "word": "ребенок",
            "count": 1,
            "phrase": "ребенок сам собрал"
          },
          {
            "word": "дополнительно",
            "count": 1,
            "phrase": "теперь не нужно дополнительно палас проходить щёткой от шерсти"
          },
          {
            "word": "палас",
            "count": 4,
            "phrase": "теперь не нужно дополнительно палас проходить щёткой от шерсти"
          },
          {
            "word": "проходить",
            "count": 2,
            "phrase": "теперь не нужно дополнительно палас проходить щёткой от шерсти"
          },
          {
            "word": "щёткой",
            "count": 1,
            "phrase": "теперь не нужно дополнительно палас проходить щёткой от шерсти"
          },
          {
            "word": "мобильный",
            "count": 0,
            "phrase": "мобильный пылесос"
          },
          {
            "word": "недорогой",
            "count": 1,
            "phrase": "пылесос недорогой но мощный"
          },
          {
            "word": "надеяться",
            "count": 1,
            "phrase": "будем надеяться"
          },
          {
            "word": "прослудит",
            "count": 1,
            "phrase": "что прослудит долго"
          },
          {
            "word": "спокойно",
            "count": 1,
            "phrase": "можно спокойно дотянуться до любого угла комнаты"
          },
          {
            "word": "дотянуться",
            "count": 1,
            "phrase": "можно спокойно дотянуться до любого угла комнаты"
          },
          {
            "word": "любого",
            "count": 1,
            "phrase": "можно спокойно дотянуться до любого угла комнаты"
          },
          {
            "word": "угла",
            "count": 1,
            "phrase": "можно спокойно дотянуться до любого угла комнаты"
          },
          {
            "word": "превосходно",
            "count": 1,
            "phrase": "сосет превосходно"
          },
          {
            "word": "лучшем",
            "count": 1,
            "phrase": "доставили в лучшем виде"
          },
          {
            "word": "виде",
            "count": 1,
            "phrase": "доставили в лучшем виде"
          },
          {
            "word": "брака",
            "count": 3,
            "phrase": "без брака и видимых дефектов"
          },
          {
            "word": "видимых",
            "count": 1,
            "phrase": "без брака и видимых дефектов"
          },
          {
            "word": "дефектов",
            "count": 1,
            "phrase": "без брака и видимых дефектов"
          },
          {
            "word": "сперва",
            "count": 0,
            "phrase": "сперва купила себе теперь взяла в подарок"
          },
          {
            "word": "классная",
            "count": 2,
            "phrase": "классная модель"
          },
          {
            "word": "модель",
            "count": 3,
            "phrase": "классная модель"
          },
          {
            "word": "запросто",
            "count": 0,
            "phrase": "запросто справляется и с коврами и ковролином"
          },
          {
            "word": "ковролином",
            "count": 1,
            "phrase": "запросто справляется и с коврами и ковролином"
          },
          {
            "word": "ценовой",
            "count": 1,
            "phrase": "для своей ценовой категории зачётный прибор"
          },
          {
            "word": "категории",
            "count": 1,
            "phrase": "для своей ценовой категории зачётный прибор"
          },
          {
            "word": "зачётный",
            "count": 1,
            "phrase": "для своей ценовой категории зачётный прибор"
          },
          {
            "word": "крутится",
            "count": 2,
            "phrase": "крутится во все стороны можно подлезть куда угодно"
          },
          {
            "word": "подлезть",
            "count": 1,
            "phrase": "крутится во все стороны можно подлезть куда угодно"
          },
          {
            "word": "меняются",
            "count": 1,
            "phrase": "насадки меняются легко"
          },
          {
            "word": "проверен",
            "count": 1,
            "phrase": "пылесос в деле проверен"
          },
          {
            "word": "дочь",
            "count": 1,
            "phrase": "дочь в восторге"
          },
          {
            "word": "проработает",
            "count": 1,
            "phrase": "надеюсь проработает долго"
          },
          {
            "word": "максимально",
            "count": 0,
            "phrase": "максимально простой без новаторов"
          },
          {
            "word": "простой",
            "count": 2,
            "phrase": "максимально простой без новаторов"
          },
          {
            "word": "новаторов",
            "count": 1,
            "phrase": "максимально простой без новаторов"
          },
          {
            "word": "пробу",
            "count": 1,
            "phrase": "купила сестре на пробу"
          },
          {
            "word": "беру",
            "count": 1,
            "phrase": "теперь беру себе"
          },
          {
            "word": "уборки(кухня",
            "count": 1,
            "phrase": "брали для ежедневной уборки(кухня"
          },
          {
            "word": "смену",
            "count": 1,
            "phrase": "взяла на смену своему старому"
          },
          {
            "word": "своему",
            "count": 1,
            "phrase": "взяла на смену своему старому"
          },
          {
            "word": "старому",
            "count": 1,
            "phrase": "взяла на смену своему старому"
          },
          {
            "word": "крутой",
            "count": 2,
            "phrase": "очень крутой"
          },
          {
            "word": "подруги",
            "count": 1,
            "phrase": "закажу ещё для подруги"
          },
          {
            "word": "очищается",
            "count": 2,
            "phrase": "легко очищается"
          },
          {
            "word": "внешний",
            "count": 1,
            "phrase": "красивый внешний вид"
          },
          {
            "word": "вид",
            "count": 6,
            "phrase": "красивый внешний вид"
          },
          {
            "word": "прозрачный",
            "count": 1,
            "phrase": "прозрачный пылесборник"
          },
          {
            "word": "пылесборник",
            "count": 2,
            "phrase": "прозрачный пылесборник"
          },
          {
            "word": "времени",
            "count": 1,
            "phrase": "уборка с этим пылесосом занимает минимум времени и сил"
          },
          {
            "word": "сил",
            "count": 23,
            "phrase": "уборка с этим пылесосом занимает минимум времени и сил"
          },
          {
            "word": "чистота",
            "count": 1,
            "phrase": "дома чистота и порядок"
          },
          {
            "word": "порядок",
            "count": 1,
            "phrase": "дома чистота и порядок"
          },
          {
            "word": "помята",
            "count": 1,
            "phrase": "упаковка не помята"
          },
          {
            "word": "шумны",
            "count": 8,
            "phrase": "не сильно шумны и мощный"
          },
          {
            "word": "любовь",
            "count": 1,
            "phrase": "это любовь"
          },
          {
            "word": "сравнению",
            "count": 1,
            "phrase": "работает супер тихо по сравнению с моим старым пылесосом"
          },
          {
            "word": "моим",
            "count": 1,
            "phrase": "работает супер тихо по сравнению с моим старым пылесосом"
          },
          {
            "word": "функцию",
            "count": 1,
            "phrase": "свою функцию выполняет"
          },
          {
            "word": "неплохой",
            "count": 1,
            "phrase": "неплохой пылесос за свои деньги"
          },
          {
            "word": "элементарный",
            "count": 1,
            "phrase": "в управлении элементарный"
          },
          {
            "word": "пожилого",
            "count": 1,
            "phrase": "для людей пожилого возраста очень хороший пылесос"
          },
          {
            "word": "возраста",
            "count": 1,
            "phrase": "для людей пожилого возраста очень хороший пылесос"
          },
          {
            "word": "превзошёл",
            "count": 1,
            "phrase": "превзошёл все мои ожидания"
          },
          {
            "word": "мои",
            "count": 2,
            "phrase": "превзошёл все мои ожидания"
          },
          {
            "word": "пола",
            "count": 6,
            "phrase": "хорошо убирает пыль с пола и шерсть с мебели"
          },
          {
            "word": "комплектующие",
            "count": 1,
            "phrase": "все комплектующие на месте"
          },
          {
            "word": "месте",
            "count": 1,
            "phrase": "все комплектующие на месте"
          },
          {
            "word": "кошки",
            "count": 2,
            "phrase": "в доме собака и две кошки"
          },
          {
            "word": "выручает",
            "count": 1,
            "phrase": "пылесос очень выручает"
          },
          {
            "word": "принципе",
            "count": 2,
            "phrase": "в принципе хороший и цена не кусается"
          },
          {
            "word": "кусается",
            "count": 1,
            "phrase": "в принципе хороший и цена не кусается"
          },
          {
            "word": "аккуратный",
            "count": 1,
            "phrase": "отличный лёгкий аккуратный пылесос сосет сильно"
          },
          {
            "word": "оптимальное",
            "count": 0,
            "phrase": "оптимальное соотношение цены и качества"
          },
          {
            "word": "соотношение",
            "count": 1,
            "phrase": "оптимальное соотношение цены и качества"
          },
          {
            "word": "качества",
            "count": 3,
            "phrase": "оптимальное соотношение цены и качества"
          },
          {
            "word": "дентги",
            "count": 1,
            "phrase": "за свои дентги неплохой"
          },
          {
            "word": "дети",
            "count": 1,
            "phrase": "пользуюсь сам и дети тоже пылесосят"
          },
          {
            "word": "пылесосят",
            "count": 1,
            "phrase": "пользуюсь сам и дети тоже пылесосят"
          },
          {
            "word": "убираться",
            "count": 1,
            "phrase": "убираться удобно"
          },
          {
            "word": "подобный",
            "count": 1,
            "phrase": "был подобный но намного слабее"
          },
          {
            "word": "лишнего",
            "count": 1,
            "phrase": "ничего лишнего"
          },
          {
            "word": "достойная",
            "count": 1,
            "phrase": "за эти деньги достойная техника"
          },
          {
            "word": "вайлбериз",
            "count": 1,
            "phrase": "а вайлбериз даставка ужасная"
          },
          {
            "word": "даставка",
            "count": 1,
            "phrase": "а вайлбериз даставка ужасная"
          },
          {
            "word": "ужасная",
            "count": 1,
            "phrase": "а вайлбериз даставка ужасная"
          },
          {
            "word": "повседневного",
            "count": 1,
            "phrase": "для повседневного использования лучше не придумаешь"
          },
          {
            "word": "использования",
            "count": 2,
            "phrase": "для повседневного использования лучше не придумаешь"
          },
          {
            "word": "придумаешь",
            "count": 1,
            "phrase": "для повседневного использования лучше не придумаешь"
          },
          {
            "word": "гладкого",
            "count": 1,
            "phrase": "щетка только для гладкого покрытия"
          },
          {
            "word": "новый",
            "count": 0,
            "phrase": "новый пока работает хорошо дальше посмотрим"
          },
          {
            "word": "срок",
            "count": 0,
            "phrase": "срок использования 1,5 месяца"
          },
          {
            "word": "1,5",
            "count": 1,
            "phrase": "срок использования 1,5 месяца"
          },
          {
            "word": "лучшая",
            "count": 0,
            "phrase": "лучшая покупка за этот месяц"
          },
          {
            "word": "покупка",
            "count": 1,
            "phrase": "лучшая покупка за этот месяц"
          },
          {
            "word": "крутая",
            "count": 1,
            "phrase": "крутая вещь"
          },
          {
            "word": "крутяцкий",
            "count": 0,
            "phrase": "крутяцкий пылесос"
          },
          {
            "word": "дачи",
            "count": 3,
            "phrase": "брали для дачи"
          },
          {
            "word": "моему",
            "count": 1,
            "phrase": "моему счастью нет предела"
          },
          {
            "word": "счастью",
            "count": 1,
            "phrase": "моему счастью нет предела"
          },
          {
            "word": "предела",
            "count": 1,
            "phrase": "моему счастью нет предела"
          },
          {
            "word": "нужен",
            "count": 2,
            "phrase": "для чего нужен обмоткодержатель"
          },
          {
            "word": "обмоткодержатель",
            "count": 1,
            "phrase": "для чего нужен обмоткодержатель"
          },
          {
            "word": "друг",
            "count": 7,
            "phrase": "для быстрой уборки просто лучший друг"
          },
          {
            "word": "клёвый",
            "count": 0,
            "phrase": "клёвый пылесос именно такой как я и хотела"
          },
          {
            "word": "брать",
            "count": 6,
            "phrase": "буду ещё брать в подарок"
          },
          {
            "word": "782р",
            "count": 1,
            "phrase": "брала за 2 782р"
          },
          {
            "word": "данный",
            "count": 1,
            "phrase": "очень понравилась данный товар"
          },
          {
            "word": "офигенная",
            "count": 0,
            "phrase": "офигенная вещь"
          },
          {
            "word": "какой",
            "count": 1,
            "phrase": "но какой - то хлипенький"
          },
          {
            "word": "хлипенький",
            "count": 1,
            "phrase": "но какой - то хлипенький"
          },
          {
            "word": "описанию",
            "count": 1,
            "phrase": "товар соответствует описанию"
          },
          {
            "word": "приятной",
            "count": 1,
            "phrase": "качественный пылесос по приятной цене"
          },
          {
            "word": "цене",
            "count": 2,
            "phrase": "качественный пылесос по приятной цене"
          },
          {
            "word": "стоимости",
            "count": 1,
            "phrase": "для такой стоимости товара"
          },
          {
            "word": "виду",
            "count": 1,
            "phrase": "взяла на подарок с виду хороший"
          },
          {
            "word": "офигенный",
            "count": 0,
            "phrase": "офигенный пылесос"
          },
          {
            "word": "пашет",
            "count": 0,
            "phrase": "пашет как настоящий"
          },
          {
            "word": "настоящий",
            "count": 1,
            "phrase": "пашет как настоящий"
          },
          {
            "word": "воздуха",
            "count": 1,
            "phrase": "хорошая тяга воздуха"
          },
          {
            "word": "нравится",
            "count": 2,
            "phrase": "мне нравится"
          },
          {
            "word": "высокая",
            "count": 1,
            "phrase": "высокая мощность всасывания"
          },
          {
            "word": "электровеник",
            "count": 2,
            "phrase": "отличный электровеник"
          },
          {
            "word": "нормал",
            "count": 10,
            "phrase": "нормал за свои деньги"
          },
          {
            "word": "пользуемся",
            "count": 1,
            "phrase": "пользуемся с удовольствием"
          },
          {
            "word": "пылессос",
            "count": 1,
            "phrase": "классный пылессос"
          },
          {
            "word": "сидит",
            "count": 1,
            "phrase": "в руке сидит удобно"
          },
          {
            "word": "всë",
            "count": 0,
            "phrase": "всë хорошо"
          },
          {
            "word": "компактно",
            "count": 1,
            "phrase": "компактно и удобно"
          },
          {
            "word": "нешумный",
            "count": 1,
            "phrase": "лёгкий и нешумный"
          },
          {
            "word": "оправдывает",
            "count": 1,
            "phrase": "свои деньги оправдывает"
          }
        ]
      },
      "feedbacks_answer": 93,
      "valuation_distribution": {
        "1": 1,
        "2": 1,
        "3": 1,
        "4": 5,
        "5": 92
      },
      "increase": 2,
      "review_on_sales": 20,
      "increase_top_wb": 3,
      "review_on_sales_top_wb_items": 4
    },
  ]
}