import axios from 'axios'
import API_ENV from '@/config/api.config'
import queryString from 'query-string'

const { apiWbProductCard } = API_ENV
const apiWbCardList = apiWbProductCard + 'list/'
const apiWbCardParams = apiWbProductCard + 'parameters/'
const apiWbCardPresets = apiWbProductCard + 'preset/'

function convertPresetsData(data, params) {
  const presets = data
  const colNames = {
    column_code: 'Метка товара на ВБ',
    column_nm_id: 'Артикул ВБ',
    column_vendor_code: 'Внутренний артикул',
    column_photo: 'Изображения',
    column_size: 'Размер',
    column_barcode: 'Баркод',
    column_add_photo: 'Дополнительные изображения',
  }

  for (const preset of presets) {
    const columns = []

    for (const colName in colNames) {
      const colValue = preset[colName]

      if (colValue) columns.push(colNames[colName])
    }

    if (preset.parameters) for (const paramId of preset.parameters) {
      const param = params.find(param => param.id == paramId)
      if (param) columns.push(param.title)
    }

    preset.columns = columns
  }

  return presets
}

export default {
  state: {
    goodsTableData: null,
    isTableLoading: true,
    limit: null,
    params: [],
    editedCells: [],
    editedPhotos: [],
  },
  getters: {
    getWbCardsGoodsTableRows: ({ goodsTableData }) => goodsTableData?.items,
    getWbCardsGoodsTableTotalPages: ({ goodsTableData, limit }) => Math.ceil(goodsTableData?.count / limit),
    getWbCardsGoodsTableLoading: ({ isTableLoading }) => isTableLoading,
    getWbCardsParams: ({ params }) => params,
    wbCardsEditedCellData: ({ editedCells }) => id => editedCells.find(cell => cell.id == id),
    wbCardsEditedPhotosData: ({ editedPhotos }) => id => editedPhotos.find(photo => photo.id == id),
  },
  mutations: {
    setWbCardGoodsTableData(state, data) {
      state.goodsTableData = data
    },
    deleteWbCardsGoodById() {
      state.goodsTableData = state.goodsTableData.filter(item => item.id != id)
    },
    setWbCardGoodsTableLoading(state, isLoading) {
      state.isTableLoading = isLoading
    },
    setWbCardGoodsTableLimit(state, limit) {
      state.limit = limit
    },
    setWbCardsFilters(state, filters) {
      state.filters = filters
    },
    setWbCardParamsTableData(state, params) {
      state.params = params
    },
    wbCardsAddEditedCell(state, { paramId, value, id }) {
      const changedRowData = state.goodsTableData.items.find(row => row.id == id)
      const editedCells = state.editedCells
      let rowIndex = editedCells.findIndex(el => el.id == id)

      if (rowIndex < 0) {
        rowIndex = editedCells.length
        editedCells.push({ id, parameters: [...changedRowData.parameters] })
      }

      const rowParams = editedCells[rowIndex].parameters

      let paramIndex = rowParams.findIndex(row => row.id == paramId)

      if (paramIndex < 0) rowParams.push({ id: paramId, value })
      else rowParams[paramIndex].value = value

      if (!value) rowParams.splice(paramIndex, 1)

      editedCells[rowIndex].parameters = rowParams
    },
    wbCardsEditedPhotos(state, { id, images }) {
      const editedPhotos = state.editedPhotos
      let rowIndex = editedPhotos.findIndex(el => el.id == id)

      if (rowIndex < 0) {
        rowIndex = editedPhotos.length
        editedPhotos.push({ id })
      }

      editedPhotos[rowIndex].user_photos = images
    },
    clearWbCardsEditedCells(state) {
      state.editedCells = []
    },
  },
  actions: {
    async getWbCardsGoods({ state, commit }, { limit = state.limit, page = 1, filters }) {
      if (filters) commit('setWbCardsFilters', filters)
      else filters = state.filters

      try {
        commit('setWbCardGoodsTableLoading', true)

        const result = await axios.get(`${apiWbCardList}?${queryString.stringify({ limit, page, ...filters })}`)

        commit('setWbCardGoodsTableData', result.data)
        commit('setWbCardGoodsTableLimit', limit)

        return result.data
      } catch (error) {
        commit('showAlert')
        throw error
      } finally {
        commit('setWbCardGoodsTableLoading', false)
      }
    },
    async wbCardsUpdateRows({ state, commit, dispatch }) {
      if (!state.editedCells.filter(Boolean).length) return commit('setWbCardGoodsTableData', state.rowData.map(row => ({ ...row, isEditable: false })))

      try {
        await axios.patch(apiWbCardList, state.editedCells)

        commit('clearWbCardsEditedCells')

        await dispatch('getWbCardsGoods')

      } catch (error) {
        commit('showAlert')
        throw error
      }
    },
    async wbCardsUpdateRowById({ state, commit }, id) {
      const editedRow = state.editedCells.find(cell => cell.id == id)

      if (!editedRow) return

      try {
        const result = await axios.patch(apiWbCardList, [editedRow])

        return result.data
      } catch (error) {
        commit('showAlert')
        throw error
      }
    },
    async postWbCardsOtherPhotos({ commit }, { id, file }) {
      try {
        const result = await axios.post(`${apiWbCardList}${id}/photo/`, { file }, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        return result.data
      } catch (error) {
        commit('showAlert')
        throw error
      }
    },
    async deleteWbCardsOtherPhotos({ commit }, { cardId, id }) {
      try {
        const result = await axios.delete(`${apiWbCardList}${cardId}/photo/${id}/`)

        return result.data
      } catch (error) {
        commit('showAlert')
        throw error
      }
    },
    async getWbCardsParams({ commit }) {
      try {
        const result = await axios.get(apiWbCardParams)

        commit('setWbCardParamsTableData', result.data)

        return result.data
      } catch (error) {
        commit('showAlert')
        throw error
      }
    },
    async createWbCardsParam({ state, commit }, data) {
      try {
        const result = await axios.post(apiWbCardParams, data)

        commit('setWbCardParamsTableData', [...state.params, result.data])

        return result.data
      } catch (error) {
        commit('showAlert')
        throw error
      }
    },
    async editWbCardsParam({ commit }, { id, data }) {
      try {
        const result = await axios.patch(apiWbCardParams + id + '/', data)

        return result.data
      } catch (error) {
        commit('showAlert')
        throw error
      }
    },
    async deleteWbCardsParam({ commit }, id) {
      try {
        const result = await axios.delete(apiWbCardParams + id)

        return result.data
      } catch (error) {
        commit('showAlert')
        throw error
      }
    },
    async getWbCardsPresets({ commit, dispatch }) {
      try {
        const presetsData = (await axios.get(apiWbCardPresets)).data

        const params = await dispatch('getWbCardsParams')
        const presets = convertPresetsData(presetsData, params)

        return presets
      } catch (error) {
        commit('showAlert')
        throw error
      }
    },
    async getWbCardsPresetById({ commit }, id) {
      try {
        const result = await axios.get(apiWbCardPresets + id)

        return result.data
      } catch (error) {
        commit('showAlert')
        throw error
      }
    },
    async createWbCardsPreset({ commit }, data) {
      try {
        const result = await axios.post(apiWbCardPresets, data)

        return result.data
      } catch (error) {
        commit('showAlert')
        throw error
      }
    },
    async editWbCardsPreset({ commit }, { id, data }) {
      try {
        const result = await axios.patch(`${apiWbCardPresets}${id}/`, data)

        return result.data
      } catch (error) {
        commit('showAlert')
        throw error
      }
    },
    async deleteWbCardsPreset({ commit }, id) {
      try {
        const result = await axios.delete(apiWbCardPresets + id)

        return result.data
      } catch (error) {
        commit('showAlert')
        throw error
      }
    },
  }
}