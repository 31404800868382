<template>
  <svg
    data-v-7356d660=""
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      data-v-7356d660=""
      d="M7 9H17C17.7956 9 18.5587 9.31607 19.1213 9.87868C19.6839 10.4413 20 11.2044 20 12V19C20 19.7956 19.6839 20.5587 19.1213 21.1213C18.5587 21.6839 17.7956 22 17 22H7C6.20435 22 5.44129 21.6839 4.87868 21.1213C4.31607 20.5587 4 19.7956 4 19V12C4 11.2044 4.31607 10.4413 4.87868 9.87868C5.44129 9.31607 6.20435 9 7 9Z"
      fill="#767A9B"
    ></path>
    <g data-v-7356d660="" opacity="0.5">
      <path
        data-v-7356d660=""
        d="M15.3716 6.8419C15.1457 6.71462 14.978 6.50455 14.904 6.256V9L16.1345 6.9329C16.128 6.93473 16.1216 6.93648 16.1151 6.93818C15.8641 7.0037 15.5976 6.96918 15.3716 6.8419Z"
        fill="#767A9B"
      ></path>
      <path
        data-v-7356d660=""
        d="M16.838 6.19024C16.8143 6.29541 16.7736 6.39699 16.7165 6.49073C16.5851 6.7065 16.3771 6.86432 16.1345 6.9329L14.904 9H16.838V6.19024Z"
        fill="#767A9B"
      ></path>
      <path
        data-v-7356d660=""
        d="M9 7C9.00028 6.40743 9.17593 5.8282 9.50481 5.33528C9.8337 4.84236 10.3011 4.45779 10.8482 4.23003C11.3952 4.00227 11.9974 3.94151 12.5789 4.05539C13.1605 4.16928 13.6953 4.45271 14.116 4.87C14.494 5.253 14.766 5.728 14.902 6.249L14.904 6.256C14.978 6.50455 15.1457 6.71462 15.3716 6.8419C15.5976 6.96918 15.8641 7.0037 16.1151 6.93818L16.1345 6.9329L16.838 5.751C16.6118 4.88515 16.1613 4.09423 15.532 3.458C14.8318 2.76053 13.9407 2.28613 12.9711 2.09465C12.0015 1.90318 10.9969 2.00322 10.0842 2.38215C9.17138 2.76109 8.39131 3.40192 7.84241 4.22378C7.29351 5.04565 7.00037 6.01169 7 7V9H9V7Z"
        fill="#767A9B"
      ></path>
      <path
        data-v-7356d660=""
        d="M16.838 5.751L16.1345 6.9329C16.3771 6.86432 16.5851 6.7065 16.7165 6.49073C16.7736 6.39699 16.8143 6.29541 16.838 6.19024V5.751Z"
        fill="#767A9B"
      ></path>
    </g>
  </svg>
</template>
<script>
export default {
  name: "LockIcon",
};
</script>
<style lang=""></style>
