<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="width"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M2.25856 3.77791C1.75434 3.21437 1.50222 2.93259 1.49271 2.69312C1.48445 2.48509 1.57385 2.28515 1.73439 2.15259C1.91919 2 2.29729 2 3.05349 2H12.9488C13.705 2 14.0831 2 14.2679 2.15259C14.4284 2.28515 14.5178 2.48509 14.5096 2.69312C14.5 2.93259 14.2479 3.21437 13.7437 3.77791L9.93954 8.02962C9.83903 8.14196 9.78877 8.19813 9.75294 8.26205C9.72116 8.31875 9.69784 8.37978 9.68372 8.44323C9.6678 8.51476 9.6678 8.59013 9.6678 8.74087V12.3056C9.6678 12.436 9.6678 12.5011 9.64677 12.5575C9.62819 12.6073 9.59797 12.6519 9.55864 12.6877C9.51412 12.7281 9.45359 12.7524 9.33254 12.8008L7.06587 13.7074C6.82085 13.8054 6.69833 13.8545 6.59998 13.834C6.51398 13.8161 6.4385 13.765 6.38997 13.6918C6.33447 13.6081 6.33447 13.4762 6.33447 13.2122V8.74087C6.33447 8.59013 6.33447 8.51476 6.31855 8.44323C6.30443 8.37978 6.28111 8.31875 6.24933 8.26205C6.21349 8.19813 6.16324 8.14196 6.06272 8.02962L2.25856 3.77791Z"
      :stroke="color"
      stroke-width="1.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'Filter2',
  props: {
    width: {
      type: Number | String,
      default: 16,
    },
    color: {
      type: String,
      default: '#3A416F99',
    },
  },
};
</script>

<style lang="scss" scoped></style>
