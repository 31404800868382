import axios from 'axios';
import API_ENV from '@/config/api.config';

let { apiAutoanswers } = API_ENV;

const apiAutoanswersArgs = `${apiAutoanswers}arg-words/`;
const apiAutoanswersTable = `${apiAutoanswers}auto-answers/`;
const apiAutoanswersFeedbacks = `${apiAutoanswers}feedbacks/`;
const apiAutoanswersProducts = `${apiAutoanswers}products/`;
const apiAutoanswersVariables = `${apiAutoanswers}variables/`;
const apiGetUsersSKU = `${apiAutoanswers}auto-answers/get-all-skus/`;

export default {
  state: {
    aiActiveSamples: [],
  },
  getters: {
    isAutoanswersActiveAISample: (state) => (cabinetId) =>
      state.aiActiveSamples.some((el) => el.cabinet == cabinetId),
  },
  mutations: {
    setAutoanswersAiActiveCabinets(state, samples) {
      state.aiActiveSamples = samples;
    },
  },
  actions: {
    // Autoanswers
    async getUsersSKU({ commit }, id) {
      try {
        const response = await axios.get(`${apiGetUsersSKU}?cabinet_id=${id}`);
        return response.data;
      } catch (error) {
        commit('showAlert', error.response.data.detail);
        throw error;
      }
    },

    getAutoanswers({ commit, dispatch }, { page, pageSize }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${apiAutoanswersTable}?page_num=${page}&page_size=${pageSize}&neuralink_response=false`
          )
          .then(async (response) => {
            const answeredFeedbacksResponses = [];
            const unansweredFeedbacksResponses = [];
            const rows = response.data.results;

            rows.forEach((row) => {
              const answeredFeedbackInfo = { isAnswered: true, autoanswerId: row.id };
              const unansweredFeedbackInfo = { isAnswered: false, autoanswerId: row.id };

              answeredFeedbacksResponses.push(dispatch('getFeedbacks', answeredFeedbackInfo));
              unansweredFeedbacksResponses.push(dispatch('getFeedbacks', unansweredFeedbackInfo));
            });

            const answeredFeedbacks = await Promise.all(answeredFeedbacksResponses);
            const unansweredFeedbacks = await Promise.all(unansweredFeedbacksResponses);

            answeredFeedbacks.forEach((result, index) => {
              rows[index].answeredFeedbacksNum = result.count;
            });
            unansweredFeedbacks.forEach((result, index) => {
              rows[index].unansweredFeedbacksNum = result.count;
            });

            resolve(response.data);
          })
          .catch((err) => {
            commit('showAlert');
            reject(err);
          });
      });
    },
    async getAIAutoanswers({ commit }) {
      try {
        const result = await axios.get(apiAutoanswersTable, {
          params: { neuralink_response: true },
        });

        const autoanswers = result.data.results;

        commit(
          'setAutoanswersAiActiveCabinets',
          autoanswers.filter((el) => el.is_active)
        );

        return autoanswers;
      } catch (error) {
        commit('showAlert');
      }
    },
    deleteAutoanswer({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${apiAutoanswersTable}${id}`)
          .then((response) => {
            const data = response.data;
            
            resolve(data);
          })
          .catch((err) => {
            commit('showAlert');
            reject(err);
          });
      });
    },
    createAutoanswer({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(apiAutoanswersTable, data)
          .then((response) => {
            if (data.neuralink_response && response.data.is_active)
              commit('setAutoanswersAiActiveCabinets', [...state.aiActiveSamples, response.data]);
            resolve(response);
          })
          .catch((err) => {
            commit('showAlert');
            reject(err);
          });
      });
    },
    async updateStoreAutoanswer({ state, commit }, { data, id }) {
      try {
        const response = await axios.patch(`${apiAutoanswersTable}${id}/`, data);
        const result = response.data;

        if (result.neuralink_response && !result.is_active)
          commit(
            'setAutoanswersAiActiveCabinets',
            state.aiActiveSamples.filter((sample) => sample.id != id)
          );

        return response.data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    getAutoanswerInfo({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${apiAutoanswersTable}${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            commit('showAlert');
            reject(err);
          });
      });
    },

    // Autoanswer Variables
    getAutoanswerVariables({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${apiAutoanswersVariables}?auto_answer=${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            commit('showAlert');
            reject(err);
          });
      });
    },
    addAutoanswerVariable({ commit }, newVariable) {
      return new Promise((resolve, reject) => {
        axios
          .post(apiAutoanswersVariables, newVariable)
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            commit('showAlert');
            reject(err);
          });
      });
    },
    deleteAutoanswerVariable({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${apiAutoanswersVariables}${id}/`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            commit('showAlert');
            reject(err);
          });
      });
    },
    changeVariableChildren({ commit }, { id, values }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`${apiAutoanswersVariables}${id}/`, { values })
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            commit('showAlert');
            reject(err);
          });
      });
    },
    changeVariableOrder({ commit }, { order, id }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`${apiAutoanswersVariables}${id}/`, { order })
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            commit('showAlert');
            reject(err);
          });
      });
    },

    // Autoanswer Arguments
    getArgWords({ commit }, { id, type }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${apiAutoanswersArgs}?auto_answer=${id}&word_type=${type}`)
          .then((response) => {
            const argWords = response.data.results;

            resolve(argWords);
          })
          .catch((err) => {
            commit('showAlert');
            reject(err);
          });
      });
    },
    deleteArgWord({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${apiAutoanswersArgs}${id}/`)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            commit('showAlert');
            reject(err);
          });
      });
    },
    addArgWord({ commit }, argInfo) {
      return new Promise((resolve, reject) => {
        axios
          .post(apiAutoanswersArgs, argInfo)
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            commit('showAlert');
            reject(err);
          });
      });
    },

    // Feedbacks
    getFeedbacks({ commit }, { isAnswered, autoanswerId, ai_response }) {
      const data = {
        page_size: 1000
      };
      if (isAnswered != undefined) data.is_answered = isAnswered;

      if (autoanswerId == 'ai') data.neuralink_response = true;
      else if (autoanswerId) data.autoanswer = autoanswerId;

      if (ai_response != undefined) data.neuralink_response = ai_response;

      return new Promise((resolve, reject) => {
        axios
          .get(apiAutoanswersFeedbacks, { params: data })
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            commit('showAlert');
            reject(err);
          });
      });
    },
    deleteFeedback({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${apiAutoanswersFeedbacks}${id}/`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            commit('showAlert');
            reject(err);
          });
      });
    },
    replyToFeedback({ commit }, { id, cabinet_id }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`${apiAutoanswersFeedbacks}${id}/reply/?cabinet_id=${cabinet_id}`,)
          .then((response) => {
            resolve(response.data);
            commit('showAlert', ['Ответ на отзыв был успешно доставлен.', 'success']);
          })
          .catch((err) => {
            commit('showAlert');
            reject(err);
          });
      });
    },
    async changeFeedbackText({ commit }, { id, answer_text }) {
      try {
        const result = await axios.patch(`${apiAutoanswersFeedbacks}${id}/`, { answer_text });
    
        return result.data;
      } catch (error) {
        commit("showAlert");
      }
    },
  },
};
