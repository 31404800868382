<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="width"
    viewBox="0 0 28 27"
    fill="none"
  >
    <path
      d="M13.4294 0.756228C13.609 0.203442 14.391 0.203444 14.5706 0.75623L17.4575 9.64114C17.5378 9.88835 17.7682 10.0557 18.0282 10.0557H27.3703C27.9515 10.0557 28.1932 10.7995 27.723 11.1411L20.165 16.6323C19.9547 16.7851 19.8667 17.0559 19.947 17.3031L22.8339 26.188C23.0135 26.7408 22.3809 27.2005 21.9106 26.8589L14.3527 21.3677C14.1424 21.2149 13.8576 21.2149 13.6473 21.3677L6.08937 26.8589C5.61914 27.2005 4.98646 26.7408 5.16607 26.188L8.05295 17.3031C8.13328 17.0559 8.04528 16.7851 7.83499 16.6323L0.277032 11.1411C-0.193196 10.7995 0.0484717 10.0557 0.629706 10.0557H9.97185C10.2318 10.0557 10.4622 9.88835 10.5425 9.64114L13.4294 0.756228Z"
      fill="#FEBE5F"
    />
  </svg>
</template>

<script>
export default {
  name: 'StarIcon',
  props: {
    width: {
      type: Number | String,
      default: 28
    }
  }
};
</script>
