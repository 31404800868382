<template>
	<svg :width="width" :height="width" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M13.4633 7.94357C13.2701 7.67403 12.9179 7.63033 12.6764 7.84596L9.29425 10.8669L5.91205 7.84596C5.67064 7.63033 5.31837 7.67403 5.12524 7.94357C4.93211 8.21311 4.97125 8.60642 5.21267 8.82205L8.94455 12.1554C9.149 12.338 9.4395 12.338 9.64394 12.1554L13.3758 8.82205C13.6172 8.60642 13.6564 8.21311 13.4633 7.94357Z" fill="#3A416F" stroke="#3A416F" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
</template>

<script>
export default {
	name: 'ArrowDown',
	props: {
		width: {
			type: Number | String,
			default: 20,
		}
	}
}
</script>

<style lang="scss" scoped></style>
