<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="11" r="11" fill="#EB8DC5" fill-opacity="0.1"/>
    <g clip-path="url(#clip0_8192_45730)">
    <path d="M8.24935 10.5423V12.3757M13.7493 9.62565V11.459M13.291 7.33398C14.4133 7.33398 15.0204 7.50575 15.3224 7.63898C15.3626 7.65672 15.3827 7.66559 15.4408 7.72099C15.4756 7.75419 15.5391 7.85162 15.5554 7.89686C15.5827 7.97232 15.5827 8.01357 15.5827 8.09607V13.0224C15.5827 13.4389 15.5827 13.6472 15.5202 13.7543C15.4567 13.8631 15.3954 13.9138 15.2765 13.9556C15.1596 13.9968 14.9236 13.9514 14.4516 13.8607C14.1212 13.7972 13.7294 13.7507 13.291 13.7507C11.916 13.7507 10.541 14.6673 8.70768 14.6673C7.58536 14.6673 6.97833 14.4956 6.6763 14.3623C6.63607 14.3446 6.61596 14.3357 6.55792 14.2803C6.52312 14.2471 6.45961 14.1497 6.44327 14.1044C6.41602 14.029 6.41602 13.9877 6.41602 13.9052L6.41602 8.97888C6.41602 8.56236 6.41602 8.3541 6.47848 8.24705C6.54201 8.13816 6.60329 8.08754 6.72221 8.04569C6.83912 8.00455 7.07511 8.04989 7.5471 8.14058C7.87747 8.20406 8.26928 8.25065 8.70768 8.25065C10.0827 8.25065 11.4577 7.33398 13.291 7.33398ZM12.1452 11.0007C12.1452 11.6335 11.6322 12.1465 10.9993 12.1465C10.3665 12.1465 9.85352 11.6335 9.85352 11.0007C9.85352 10.3678 10.3665 9.85482 10.9993 9.85482C11.6322 9.85482 12.1452 10.3678 12.1452 11.0007Z" stroke="#EB8DC5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_8192_45730">
    <rect width="11" height="11" fill="white" transform="translate(5.5 5.5)"/>
    </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'CashIcon',
}
</script>

<style lang="scss" scoped></style>
