<template>
  <div class="header box">
    <button v-if="isMobile" @click="openSidebar" class="main-app__header-menu">
      <MenuIcon class="main-app__icon" />
    </button>
    <WTracer
      v-if="
        !isWayDone &&
        (personalInfo?.current_tariff?.tariff?.title === 'L' ||
          personalInfo?.current_tariff?.tariff?.title === 'free_3_days' ||
          personalInfo?.current_tariff?.tariff?.title === 'XXL') &&
        personalInfo?.current_tariff
      "
      class="header__way"
      :wayTracer="editedStageListWithRates"
    />
    <div class="bell" @click.stop="openNotifications">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M14.9997 19C14.9997 20.6569 13.6566 22 11.9997 22C10.3429 22 8.99972 20.6569 8.99972 19M13.7962 6.23856C14.2317 5.78864 14.4997 5.17562 14.4997 4.5C14.4997 3.11929 13.3804 2 11.9997 2C10.619 2 9.49972 3.11929 9.49972 4.5C9.49972 5.17562 9.76772 5.78864 10.2032 6.23856M17.9997 11.2C17.9997 9.82088 17.3676 8.49823 16.2424 7.52305C15.1171 6.54786 13.591 6 11.9997 6C10.4084 6 8.8823 6.54786 7.75708 7.52305C6.63186 8.49823 5.99972 9.82088 5.99972 11.2C5.99972 13.4818 5.43385 15.1506 4.72778 16.3447C3.92306 17.7056 3.5207 18.3861 3.53659 18.5486C3.55476 18.7346 3.58824 18.7933 3.73906 18.9036C3.87089 19 4.53323 19 5.85791 19H18.1415C19.4662 19 20.1286 19 20.2604 18.9036C20.4112 18.7933 20.4447 18.7346 20.4629 18.5486C20.4787 18.3861 20.0764 17.7056 19.2717 16.3447C18.5656 15.1506 17.9997 13.4818 17.9997 11.2Z"
          stroke="#3A416F"
          stroke-width="1.7"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <p v-if="hasNewNotifications" class="bell__count">
        {{ allNotifications.length }}
      </p>
      <transition name="modalFade">
        <div class="bell__notifications" v-if="showNotifications">
          <ul class="bell__notifications-list" v-if="allNotifications.length">
            <li
              class="bell__notifications-listItem"
              v-for="(notification, index) in allNotifications"
              :key="index"
              :class="{ isNotRead: !notification.is_read }"
              @click.stop="showNotifications = false"
            >
              <p class="bell__notifications-message" v-html="notification.message"></p>
              <span class="bell__notifications-date">{{
                convertDate(notification.created_at)
              }}</span>
            </li>
          </ul>
          <p class="bell__notifications-empty" v-else @click="showNotifications = false">
            У вас нет новых уведомлений
          </p>
        </div>
      </transition>
    </div>
    <HTariff :tariff="sidebarPersonalInfo?.tariff" :dateEnd="dateEnd" />
    <div class="user" @click.stop="togglePopup">
      <div class="user__image">
        {{ sidebarPersonalInfo?.name[0] ?? '' }}{{ sidebarPersonalInfo?.surname[0] ?? '' }}
      </div>
      <div class="user__data">
        <h4 class="user__name">
          {{ `${sidebarPersonalInfo?.name ?? ''} ${sidebarPersonalInfo?.surname[0] ?? ''}.` }}
        </h4>
      </div>
      <svg
        class="user__arrow"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M9 13L12 10L15 13"
          stroke="#3586FF"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <div id="userPopup" class="popup user__popup" ref="popup">
        <div
          v-for="(item, index) of links"
          :key="index"
          @click="pushTo(item.to)"
          class="popup__item"
        >
          <span v-html="item.icon"></span>
          <p class="popup__text">{{ item.title }}</p>
          <svg
            class="popup__arrow"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <g opacity="0.4">
              <path
                d="M6 12L10 8L6 4"
                stroke="#3A416F"
                stroke-width="1.6"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
        </div>
        <button class="button popup__logout" @click="logout">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
          >
            <path
              d="M10.5 2H11.3C12.4201 2 12.9802 2 13.408 2.21799C13.7843 2.40973 14.0903 2.71569 14.282 3.09202C14.5 3.51984 14.5 4.0799 14.5 5.2V10.8C14.5 11.9201 14.5 12.4802 14.282 12.908C14.0903 13.2843 13.7843 13.5903 13.408 13.782C12.9802 14 12.4201 14 11.3 14H10.5M7.16667 4.66667L10.5 8M10.5 8L7.16667 11.3333M10.5 8L2.5 8"
              stroke="#DF5353"
              stroke-width="1.6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Выйти
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { MenuIcon } from '@/components/UI/icons';
import moment from 'moment';
import WTracer from '@/components/Header/HTracer.vue';
import HTariff from '@/components/Header/HTariff.vue';

export default {
  name: 'AppHeader',
  components: { MenuIcon, WTracer, HTariff },
  props: {
    isMobile: Boolean,
  },
  data() {
    return {
      sections: [{ percent: 25, offset: 0, color: '#ff0000' }],
      links: [
        {
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M2 5.33203L10 5.33203M10 5.33203C10 6.4366 10.8954 7.33203 12 7.33203C13.1046 7.33203 14 6.4366 14 5.33203C14 4.22746 13.1046 3.33203 12 3.33203C10.8954 3.33203 10 4.22746 10 5.33203ZM6 10.6654L14 10.6654M6 10.6654C6 11.7699 5.10457 12.6654 4 12.6654C2.89543 12.6654 2 11.7699 2 10.6654C2 9.5608 2.89543 8.66537 4 8.66537C5.10457 8.66537 6 9.5608 6 10.6654Z" stroke="#3A416F" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
          title: 'Настройки профиля',
          to: '/settings',
        },
        {
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M10 14V10.4C10 10.0266 10 9.83995 9.92734 9.69734C9.86342 9.5719 9.76144 9.46991 9.63599 9.406C9.49339 9.33333 9.3067 9.33333 8.93333 9.33333H7.06667C6.6933 9.33333 6.50661 9.33333 6.36401 9.406C6.23857 9.46991 6.13658 9.5719 6.07266 9.69734C6 9.83995 6 10.0266 6 10.4V14M2 4.66667C2 5.77124 2.89543 6.66667 4 6.66667C5.10457 6.66667 6 5.77124 6 4.66667C6 5.77124 6.89543 6.66667 8 6.66667C9.10457 6.66667 10 5.77124 10 4.66667C10 5.77124 10.8954 6.66667 12 6.66667C13.1046 6.66667 14 5.77124 14 4.66667M4.13333 14H11.8667C12.6134 14 12.9868 14 13.272 13.8547C13.5229 13.7268 13.7268 13.5229 13.8547 13.272C14 12.9868 14 12.6134 14 11.8667V4.13333C14 3.3866 14 3.01323 13.8547 2.72801C13.7268 2.47713 13.5229 2.27316 13.272 2.14532C12.9868 2 12.6134 2 11.8667 2H4.13333C3.3866 2 3.01323 2 2.72801 2.14532C2.47713 2.27316 2.27316 2.47713 2.14532 2.72801C2 3.01323 2 3.3866 2 4.13333V11.8667C2 12.6134 2 12.9868 2.14532 13.272C2.27316 13.5229 2.47713 13.7268 2.72801 13.8547C3.01323 14 3.3866 14 4.13333 14Z" stroke="#3A416F" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
          title: 'Тарифы',
          to: '/rates',
        },
        {
          icon: `<svg style="scale: 1.8" version="1.0" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 920.000000 512.000000" preserveAspectRatio="xMidYMid meet">

                  <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                  fill="#3a416f" stroke="none">
                  <path d="M2793 4620 c-115 -10 -166 -36 -200 -99 -22 -42 -23 -54 -23 -248 l0
                  -203 -195 0 c-213 0 -239 -5 -287 -56 -15 -16 -32 -42 -37 -57 -15 -38 -15
                  -3296 -1 -3349 13 -47 77 -105 127 -114 25 -5 59 -1 103 11 343 93 532 133
                  790 166 206 26 616 31 785 11 222 -28 437 -81 617 -153 54 -21 112 -39 129
                  -39 17 0 75 18 128 39 399 161 883 206 1441 136 163 -21 485 -86 636 -129 159
                  -45 184 -49 230 -37 48 13 101 65 114 112 6 21 10 630 10 1677 0 1788 4 1678
                  -56 1734 -47 43 -73 48 -280 48 l-194 0 0 203 c0 194 -1 206 -23 248 -36 68
                  -81 88 -229 101 -147 12 -422 5 -568 -17 -480 -70 -852 -247 -1123 -536 l-87
                  -92 -87 92 c-271 289 -642 466 -1123 536 -124 18 -466 27 -597 15z m372 -340
                  c432 -28 782 -156 1022 -373 89 -80 129 -125 192 -216 l51 -75 0 -1228 c0
                  -675 -3 -1228 -6 -1228 -3 0 -29 19 -57 43 -214 178 -495 306 -819 372 -154
                  31 -258 42 -470 51 l-168 7 0 1323 0 1323 23 4 c38 7 88 7 232 -3z m3103 0
                  l22 0 0 -1323 0 -1324 -161 -7 c-205 -8 -323 -21 -479 -52 -307 -61 -619 -203
                  -817 -370 -28 -24 -54 -44 -57 -44 -3 0 -6 552 -6 1228 l0 1227 38 56 c242
                  363 658 570 1222 609 58 4 130 6 160 4 30 -2 65 -3 78 -4z m-3696 -1723 l3
                  -1173 25 -37 c13 -20 41 -45 61 -56 34 -19 47 -20 270 -14 203 5 254 3 389
                  -16 230 -31 376 -71 545 -151 114 -53 215 -115 175 -106 -179 36 -741 44 -985
                  13 -229 -29 -553 -90 -657 -122 -17 -6 -18 39 -18 1414 l0 1421 95 0 95 0 2
                  -1173z m4248 -248 c0 -1373 -1 -1420 -18 -1415 -102 33 -449 97 -662 123 -194
                  24 -720 24 -885 0 -60 -8 -111 -14 -112 -13 -2 1 30 21 71 45 297 170 624 240
                  1065 228 213 -6 226 -6 260 14 20 11 48 36 61 56 l25 37 3 1173 2 1173 95 0
                  95 0 0 -1421z"/>
                  </g>
                </svg>`,
          title: 'База знаний',
          to: 'https://wiki.mamod.ai/',
        },
        {
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M11.333 9.66508V7.66137C11.333 7.54171 11.333 7.48189 11.3148 7.42907C11.2987 7.38236 11.2724 7.33982 11.2378 7.30452C11.1987 7.2646 11.1452 7.23785 11.0382 7.18434L7.99967 5.66508M2.66634 6.33175V10.8695C2.66634 11.1174 2.66634 11.2414 2.70502 11.3499C2.73921 11.4459 2.79494 11.5328 2.86793 11.6038C2.95048 11.6842 3.06319 11.7359 3.28858 11.8392L7.55524 13.7947C7.71873 13.8697 7.80048 13.9071 7.88563 13.9219C7.9611 13.935 8.03825 13.935 8.11371 13.9219C8.19887 13.9071 8.28062 13.8697 8.44411 13.7947L12.7108 11.8392C12.9362 11.7359 13.0489 11.6842 13.1314 11.6038C13.2044 11.5328 13.2601 11.4459 13.2943 11.3499C13.333 11.2414 13.333 11.1174 13.333 10.8695V6.33175M1.33301 5.66508L7.76116 2.45101C7.84862 2.40728 7.89234 2.38541 7.93821 2.37681C7.97883 2.36919 8.02052 2.36919 8.06114 2.37681C8.10701 2.38541 8.15073 2.40728 8.23819 2.45101L14.6663 5.66508L8.23819 8.87916C8.15073 8.92289 8.10701 8.94475 8.06114 8.95336C8.02052 8.96098 7.97883 8.96098 7.93821 8.95336C7.89234 8.94475 7.84862 8.92289 7.76116 8.87916L1.33301 5.66508Z" stroke="#3A416F" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>`,
          title: 'Обучение',
          to: '/study',
        },
        //         {
        //           icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        //   <path d="M8 10.6667V8M8 5.33333H8.00667M5.2 14H10.8C11.9201 14 12.4802 14 12.908 13.782C13.2843 13.5903 13.5903 13.2843 13.782 12.908C14 12.4802 14 11.9201 14 10.8V5.2C14 4.0799 14 3.51984 13.782 3.09202C13.5903 2.71569 13.2843 2.40973 12.908 2.21799C12.4802 2 11.9201 2 10.8 2H5.2C4.0799 2 3.51984 2 3.09202 2.21799C2.71569 2.40973 2.40973 2.71569 2.21799 3.09202C2 3.51984 2 4.0799 2 5.2V10.8C2 11.9201 2 12.4802 2.21799 12.908C2.40973 13.2843 2.71569 13.5903 3.09202 13.782C3.51984 14 4.0799 14 5.2 14Z" stroke="#3A416F" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
        // </svg>`,
        //           title: 'FAQ',
        //           to: '/faq',
        //         },
        {
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <g clip-path="url(#clip0_9722_49788)">
    <path d="M7.99967 3.9987V14.6654M7.99967 3.9987H5.64253C5.29522 3.9987 4.96214 3.85822 4.71656 3.60817C4.47098 3.35813 4.33301 3.01899 4.33301 2.66536C4.33301 2.31174 4.47098 1.9726 4.71656 1.72256C4.96214 1.47251 5.29522 1.33203 5.64253 1.33203C7.47587 1.33203 7.99967 3.9987 7.99967 3.9987ZM7.99967 3.9987H10.3568C10.7041 3.9987 11.0372 3.85822 11.2828 3.60817C11.5284 3.35813 11.6663 3.01899 11.6663 2.66536C11.6663 2.31174 11.5284 1.9726 11.2828 1.72256C11.0372 1.47251 10.7041 1.33203 10.3568 1.33203C8.52348 1.33203 7.99967 3.9987 7.99967 3.9987ZM13.333 7.33203V12.532C13.333 13.2788 13.333 13.6521 13.1877 13.9374C13.0599 14.1882 12.8559 14.3922 12.605 14.52C12.3198 14.6654 11.9464 14.6654 11.1997 14.6654L4.79967 14.6654C4.05294 14.6654 3.67957 14.6654 3.39435 14.52C3.14347 14.3922 2.9395 14.1882 2.81167 13.9374C2.66634 13.6521 2.66634 13.2788 2.66634 12.532V7.33203M1.33301 5.06536L1.33301 6.26536C1.33301 6.63873 1.33301 6.82542 1.40567 6.96802C1.46959 7.09347 1.57157 7.19545 1.69701 7.25937C1.83962 7.33203 2.02631 7.33203 2.39968 7.33203L13.5997 7.33203C13.973 7.33203 14.1597 7.33203 14.3023 7.25937C14.4278 7.19545 14.5298 7.09347 14.5937 6.96803C14.6663 6.82542 14.6663 6.63873 14.6663 6.26536V5.06536C14.6663 4.692 14.6663 4.50531 14.5937 4.3627C14.5298 4.23726 14.4278 4.13528 14.3023 4.07136C14.1597 3.9987 13.973 3.9987 13.5997 3.9987L2.39967 3.9987C2.02631 3.9987 1.83962 3.9987 1.69701 4.07136C1.57157 4.13528 1.46959 4.23726 1.40567 4.3627C1.33301 4.50531 1.33301 4.692 1.33301 5.06536Z" stroke="#3A416F" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_9722_49788">
      <rect width="16" height="16" fill="white"/>
    </clipPath>
  </defs>
</svg>`,
          title: 'Реферальная программа',
          to: '/referals',
        },
      ],
      showNotifications: false,
    };
  },

  methods: {
    ...mapActions(['getNotifications', 'readAllNotifications']),
    openNotifications() {
      this.showNotifications = !this.showNotifications;
      this.readAllNotifications();
    },
    convertDate(date) {
      return new Date(date).toLocaleString('ru', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
    },
    pushTo(route) {
      if (this.$route.path !== route) {
        const isInternalTransition = route.split('')[0];
        isInternalTransition === '/' ? this.$router.push(route) : window.open(route);
      }
    },
    togglePopup() {
      this.$refs.popup.classList.toggle('active');
    },
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login');
      });
    },
    openSidebar() {
      this.$emit('input', true);
    },
  },
  computed: {
    ...mapGetters([
      'sidebarPersonalInfo',
      'personalInfo',
      'hasNewNotifications',
      'userInfo',
      'allNotifications',
      'stageList',
    ]),

    editedStageListWithRates() {
      if (this.userInfo?.current_tariff.tariff?.level === 3) {
        let stageList = [...this.stageList];
        return stageList;
      }
      return this.stageList;
    },
    dateEnd() {
      return moment(this.personalInfo?.current_tariff?.end_date).format('DD.MM.YYYY');
    },
    isWayDone() {
      if (this.editedStageListWithRates.length) {
        return this.editedStageListWithRates.find(item => item.procent < 100) ? false : true;
      }
      return true;
    },
  },
  mounted() {
    document.body.addEventListener('click', () => {
      this.$refs.popup?.classList.remove('active');
      this.showNotifications = false;
    });
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/mixin.scss';
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  gap: 28px;
}
.bell {
  cursor: pointer;
  margin-left: auto;
  position: relative;
  &__count {
    position: absolute;
    top: 0;
    right: 0;
    box-sizing: content-box;
    width: 12px;
    height: 12px;
    background: #3586ff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 8px;
    font-style: normal;
    font-weight: 700;
    line-height: 8px; /* 100% */
    letter-spacing: -0.099px;
  }
  &__notifications {
    width: 300px;
    max-width: 400px;
    background: #eaebec;
    border-radius: 12px;
    position: absolute;
    z-index: 100;
    right: 0;
    top: 60px;
    padding: 10px;
    box-shadow: 0px 0px 7px #0000003d;
    overflow: auto;
    max-height: 300px;
    @include customScrollbar;

    @media (max-width: 1000px) {
      left: -50px;
    }

    &.empty {
      padding: 7.5px;
    }

    &-listItem {
      position: relative;
      background: #fff;
      padding: 10px;
      border-radius: 12px;
      margin-bottom: 9px;
      display: flex;
      flex-direction: column;
      transition: all 0.3s ease;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 0px 7px #0000003d;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.isNotRead {
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 10px;
          right: 10px;
          width: 8px;
          height: 8px;
          background: #ff0079;
          border-radius: 50%;
        }
      }
    }

    &-message {
      font-weight: 600;
      font-size: 0.85em;
      line-height: 1.3em;
    }

    &-date {
      margin-left: auto;
      font-size: 0.7em;
      font-family: 'Manrope';
      font-weight: 600;
      letter-spacing: -0.4px;
      color: #3a416f;
      opacity: 0.6;
    }

    &-empty {
      font-weight: 700;
      background: #fff;
      padding: 8px;
      border-radius: 12px;
    }
  }
}
.user {
  display: flex;
  align-items: center;
  cursor: pointer;
  // position: relative;
  gap: 10px;
  &__fake {
    width: 100%;
    // border-radius: 50%;
    transform: scale(1.6);
    position: absolute;
    top: 10px;
    left: 3px;
  }
  &__image {
    width: 40px;
    height: 40px;
    background: #ebecf2;
    border-radius: 50%;
    color: #5647ff;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px; /* 100% */
    letter-spacing: -0.161px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    &::after {
      // content: '';
      background: #28c87b;
      width: 10px;
      height: 10px;
      border: 1px solid #fff;
      border-radius: 50%;
      position: absolute;
      top: 1px;
      right: 1px;
    }
  }
  &__data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
  }
  &__name {
    color: #3a416f;
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 100% */
    letter-spacing: -0.174px;
  }
  &__rate {
    color: rgba(58, 65, 111, 0.7);
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: -0.136px;
    &-untill {
      color: #3a416f;
      font-weight: 600;
    }
  }
  &__arrow {
    transition: all 0.2s;
    transform: rotate(180deg);
  }
  &__popup {
    top: 100%;
    right: 0;
    min-width: 251px;
    z-index: 103;
  }
}
.popup {
  position: absolute;
  transform: translate(0px, 20%);
  border-radius: 12px;
  background: #fff;
  padding: 10px 5px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
  box-shadow: 0px 5.65186px 22.9218px 0px rgba(51, 56, 67, 0.05),
    0px 16.87448px 44.74324px 0px rgba(51, 56, 67, 0.1), 0px 80px 186px 0px rgba(51, 56, 67, 0.1);
  &.active {
    transform: translate(0px, 4%);
    visibility: visible;
    opacity: 1;
  }
  svg g {
    transition: all 0.3s;
  }
  &__item {
    display: flex;
    align-items: center;
    padding: 10px;
    transition: all 0.3s;

    &:hover {
      border-radius: 12px;
      background: #f7f7f7;
      svg g {
        opacity: 1;
      }
    }
  }
  &__text {
    color: #3a416f;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.174px;
    margin-left: 10px;
  }
  &__arrow {
    margin-left: auto;
  }
  &__logout {
    color: #df5353;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.174px;
    border-radius: 12px;
    background: rgba(223, 83, 83, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 221px;
    margin-top: 5px;
    margin: 5px auto 0 auto;
  }
}

.main-app {
  &__header-menu {
    margin-right: auto;
  }
}

@media screen and (max-width: 500px) {
  .user {
    gap: 0px;
    &__name {
      display: none;
    }
  }
}
</style>
