const Module = () => import('./Module.vue');
const ABTests = () => import('./views');

const mainRoutes = {
  path: '/ab-tests',
  name: 'abTests',
  children: [
    {
      path: '',
      name: 'abTestsPage',
      component: ABTests,
      breadName: 'АБ Тесты',
      meta: {
        requiresAuth: true,
        isCloseForShowroom: true,
        sectionKey: 'ab_tests',
        noPageTour: true,
      },
    },
  ],
  component: Module,
};

export default mainRoutes;
