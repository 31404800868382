/**
 * Generates the URL for the card poster image based on the provided article number, size, and number.
 *
 * @param {string|number} article - The article number.
 * @param {string} [size="big"] - The size of the poster image. Default is "big".
 * @param {number} [number=1] - The number of the poster image. Default is 1.
 * @return {string} The URL for the card poster image.
 * @throws {Error} If the article parameter is not a valid number.
 */
function getCardPosterUrl(article, size = 'big', number = 1) {
  const getBasketId = vol => {
    if (0 <= vol && vol <= 143) return '01';
    if (144 <= vol && vol <= 287) return '02';
    if (288 <= vol && vol <= 431) return '03';
    if (432 <= vol && vol <= 719) return '04';
    if (720 <= vol && vol <= 1007) return '05';
    if (1008 <= vol && vol <= 1061) return '06';
    if (1062 <= vol && vol <= 1115) return '07';
    if (1116 <= vol && vol <= 1169) return '08';
    if (1170 <= vol && vol <= 1313) return '09';
    if (1314 <= vol && vol <= 1601) return '10';
    if (1602 <= vol && vol <= 1655) return '11';
    if (1656 <= vol && vol <= 1919) return '12';
    if (1920 <= vol && vol <= 2045) return '13';
    if (2046 <= vol && vol <= 2189) return '14';
    if (2090 <= vol && vol <= 2405) return '15';
    if (2406 <= vol && vol <= 2621) return '16';
    return '17';
  };

  let articleInt, articleStr;
  if (typeof article === 'string' && isNaN(article)) {
    throw new Error('article must be a number');
  }

  if (typeof article === 'number') {
    articleStr = article.toString();
    articleInt = article;
  } else {
    articleStr = article;
    articleInt = parseInt(article, 10);
  }

  const vol = Math.floor(articleInt / 1e5);
  const part = Math.floor(articleInt / 1e3);
  const basket = getBasketId(vol);

  const GET_CARD_POSTER =
    'https://basket-{basket}.wbbasket.ru/vol{vol}/part{part}/{article}/images/{size}/{number}.jpg';
  return GET_CARD_POSTER.replace('{basket}', basket)
    .replace('{vol}', vol)
    .replace('{part}', part)
    .replace('{article}', articleStr)
    .replace('{size}', size)
    .replace('{number}', number);
}

export default getCardPosterUrl;
