<template>
  <div class="calc">
    <div
      @click="editBoolean"
      :disabled="!isPrevClose"
      class="calc__boolean"
      :class="{
        disabled: !isPrevClose,
      }"
      v-if="typeof element.value === 'boolean'"
    >
      <template v-if="currentValue">
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.5 0C2.91576 0 0 2.91576 0 6.5C0 10.0842 2.91576 13 6.5 13C10.0842 13 13 10.0842 13 6.5C13 2.91576 10.0842 0 6.5 0Z"
            fill="#513CD3"
          />
          <path
            d="M9.79391 5.12204L6.27305 8.64281C6.16742 8.74844 6.02876 8.8016 5.89011 8.8016C5.75145 8.8016 5.61279 8.74844 5.50716 8.64281L3.74678 6.88242C3.53493 6.67067 3.53493 6.32829 3.74678 6.11654C3.95853 5.90469 4.30081 5.90469 4.51266 6.11654L5.89011 7.49398L9.02803 4.35616C9.23978 4.1443 9.58206 4.1443 9.79391 4.35616C10.0057 4.56791 10.0057 4.91019 9.79391 5.12204Z"
            fill="#FAFAFA"
          />
        </svg>
        <span class="calc__text">Удалить</span>
      </template>
      <template v-else>
        <div class="calc__circle"></div>
        <span class="calc__text">Добавить</span>
      </template>
    </div>

    <template v-else>
      <div class="calc__calc">
        <button
          :disabled="currentValue <= element.value"
          @click="subtractValue"
          class="button calc__btn"
        >
          -
        </button>
        <p class="calc__value">
          <input
            class="calc__input"
            type="number"
            :min="currentValue"
            v-model.number="currentValue"
          />
          {{ valueDescription }}
        </p>

        <button @click="addValue" class="button calc__btn">+</button>
      </div>
    </template>
  </div>
</template>
<script>
import checkMark from '@/components/checkMark.vue';

export default {
  name: 'CTariffCalculator',
  components: { checkMark },
  props: {
    element: Object,
    valueDescription: String,
    isPrevClose: Boolean,
  },
  data() {
    return {
      currentValue: this.element.value,
    };
  },
  methods: {
    editBoolean() {
      if (!this.isPrevClose) return;
      this.$emit('valueSwitched', {
        ...this.element,
        newValue: !this.currentValue,
      });
    },
    addValue() {
      this.currentValue = typeof this.currentValue === 'number' ? this.currentValue + 1 : 1;
    },
    subtractValue() {
      this.currentValue = typeof this.currentValue === 'number' ? this.currentValue - 1 : 0;
    },
  },
  watch: {
    'element.value': {
      handler(newValue) {
        this.currentValue = newValue;
      },
      deep: true,
    },
    currentValue(value) {
      // this.currentValue = Math.max(this.element.value, value);
      this.$emit('editPrice', { ...this.element, newValue: this.currentValue });
    },
  },
};
</script>
<style lang="scss" scoped>
.calc {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  &__calc {
    width: 160px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__circle {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    border: 1px solid #513cd3;
  }
  &__boolean {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__text {
    color: #513cd3;
    font-size: 11px;
    font-weight: 600;
    line-height: 13px;
    letter-spacing: -0.22px;
  }
  &__btn {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background: #e9ebf4;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    &:disabled {
      opacity: 0.2;
    }
  }
  &__input {
    width: 50px;
    text-align: center;
    outline: none;
    border: 1px solid #00000008;
    border-radius: 5px;
    text-align: right;
  }

  .disabled {
    opacity: 0.3;
  }
}
</style>
