<template>
  <div class="dropdown box">
    <header class="dropdown-header" :class="{ open: opened || isOpen }" @click="isOpen = !isOpen">
      <h3 v-if="title">{{ title }}</h3>
      <slot name="title"></slot>

      <div class="dropdown__arrows">
        <slot v-if="$slots.arrow" name="arrow"></slot>
        <ArrowDown v-else width="24" />
      </div>
    </header>
    <transition name="dropdown">
      <div v-if="opened || isOpen" class="dropdown-block">
        <div>
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ArrowDown } from './icons';

export default {
  name: 'CDropdownBlock',
  props: {
    title: String,
    initialyClosed: Boolean,
    opened: Boolean,
  },
  components: { ArrowDown },
  data: () => ({
    isOpen: false,
  }),
  mounted() {
    !this.initialyClosed && (this.isOpen = true);
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  padding: 0;

  &-header {
    padding: 20px 30px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.4s ease;

    svg {
      transition: all 0.3s ease;
    }
  }
  // &__arrows{
  //   margin-left: auto;
  // }
}
</style>

<style lang="scss">
.dropdown {
  header.open {
    border-bottom: 1px solid #e9eff7;

    .dropdown__arrows {
      // margin-left: 0;

      svg {
        transform: rotate(-180deg);
      }
    }
  }
}
</style>

<docs lang="md">
```vue
<template>
  <CDropdownBlock>
    <template #title>
      <h3>Показатели для раздела <span class="blue">«Планирование закупки»</span></h3>
    </template>

    <div class="example__indicators">
      <div class="example__indicators-header">
        <h4>Индикация остатков на складе:</h4>
      </div>
      <div class="inputs-wrapper">
        <div class="input-wrapper">
          <label for="remainsDenger">
            Опасный запас, дней <span class="red">(красный)</span>
          </label>
          <input type="text" class="input" placeholder="0" />
        </div>
        <div class="input-wrapper">
          <label for="remainsFew">
            Небольшой запас, дней <span class="yellowColor">(желтый)</span>
          </label>
          <input type="text" class="input" placeholder="0" />
        </div>
      </div>
    </div>
  </CDropdownBlock>
</template>

<style lang="scss" scoped>
.example__indicators {
  padding: 20px 30px 30px;
}
.example__indicators-header {
  display: flex;
}
</style>
```
</docs>
