<template>
  <div class="videoInstructionButton">
    <button class="button prime-outline-button" @click="modal = true">
      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
        <path
          d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"
        />
      </svg>
      Видео
    </button>
    <CModal v-model="modal" empty class="videoModal">
      <div class="videoModal-close" @click="modal = false">
        <Plus :color="'#222'" :width="20" rotate />
      </div>
      <video autoplay controls :src="`/videos/${name}`" :poster="poster"></video>
    </CModal>
  </div>
</template>

<script>
import CModal from './CModal.vue';
import { Plus } from './icons';

export default {
  name: 'CVideoInstruction',
  props: {
    name: String,
    poster: String,
  },
  components: { CModal, Plus },
  data: () => ({
    modal: false,
  }),
};
</script>

<style lang="scss" scoped>
.videoInstructionButton {
  margin-left: auto;
  align-self: end;

  .button {
    padding: 12px 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 10px;
      height: 15px;
      fill: #3586ff;
    }
  }
}
</style>

<style lang="scss">
.videoModal {
  &-close {
    background: #00000030;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: absolute;
    cursor: pointer;
    z-index: 10;
    top: 20px;
    right: 20px;
  }

  .modalBox {
    background: transparent;
    padding: 0;
    width: 96%;
    max-width: 900px;
  }

  video {
    width: 100%;
  }
}
</style>

<docs lang="md">
```vue
<template>
  <CVideoInstruction name="name" />
</template>
```
</docs>
