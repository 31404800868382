<template>
  <div class="d-flex login-form">
    <div class="c-autocomplete">
      <v-select
        v-if="!loading"
        :search-input.sync="search"
        v-model="country"
        :items="items"
        :return-object="true"
        item-text="country"
        :filter="customFilter"
        :hide-details="true"
        no-data-text="Нет данных"
        append-icon="mdi-chevron-down"
        solo
        :disabled="disabled"
      >
        <template v-slot:item="data">
          <img
            :src="data.item.image"
            style="width: 16px"
            class="mr-2 mb-1"
            :alt="data.item.country"
          />
          <span class="v-list-item__title"
            >({{ data.item.phone_code }}) {{ data.item.country }}</span
          >
        </template>
        <template v-slot:selection="data">
          <img
            :src="data.item.image"
            style="width: 16px"
            class="mr-2 mb-1"
            :alt="data.item.country"
          />
        </template>
      </v-select>
      <CSpinner v-else class="mr-2 mt-4 c-loader" />
    </div>
    <div class="login-form__wrapper">
      <input
        type="text"
        v-maska
        :data-maska="`${country.phone_code} ${country.mask}`"
        class="input login-form__input ml-2"
        :class="{ err: error }"
        :value="value"
        :disabled="disabled"
        @input="onInput"
        @click="$emit('click')"
        id="phone"
        placeholder="Введите номер телефона"
        required
        tabindex="-1"
      />
      <span class="login-form__error">{{ error }}</span>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import CSpinner from '@/components/UI/CSpinner.vue';
import API_ENV from '@/config/api.config';

const { apiGetCountries } = API_ENV;

export default {
  name: 'CNumberField',
  components: { CSpinner },
  props: {
    disabled: {
      type: Boolean,
    },
    error: String,
    value: {
      type: String,
    },
  },
  data: () => ({
    loading: false,
    search: '',
    phone: '',
    items: [],
    country: {
      id: 1,
      country: 'Россия',
      phone_code: '+7',
      image: 'https://flags.fmcdn.net/data/flags/mini/ru.png',
      mask: '(###) ###-##-##',
    },
  }),
  async created() {
    await this.getCountries();
  },
  methods: {
    async getCountries() {
      this.loading = true;
      try {
        const result = await axios.get(apiGetCountries);
        this.items = result.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    customFilter(item, queryText, itemText) {
      const searchWords = queryText.toLowerCase().split(' ');
      const listText = itemText.replace(/[\s+,.]/g, '').toLowerCase();

      for (let i = 0; i < searchWords.length; i++) {
        if (!listText.includes(searchWords[i])) {
          return false;
        }
      }
      return true;
    },
    onInput(e) {
      this.$emit('input', e.target.value);
    },
  },
  watch: {
    country(newValue, prevValue) {
      let inputValue = this.value;
      const newCode = newValue.phone_code;
      const prevCode = prevValue.phone_code;
      if (!inputValue) {
        this.$nextTick(() => this.$emit('input', newCode));
        return;
      }
      this.$nextTick(() => this.$emit('input', inputValue.replace(prevCode, newCode)));
    },
  },
};
</script>

<style lang="scss" scoped>
.login-form {
  gap: 5px;
  &__wrapper {
    width: 100%;
  }
  &__input {
    margin: 0 !important;
    &.err {
      border: 1px solid #d94646;
      animation: shake 0.5s;
    }
  }
  &__error {
    color: #d94646;
    font-size: 12px;
  }
}
</style>

<style lang="scss">
.c-loader {
  border: 2px solid #3a416f !important;
  border-bottom-color: #fff !important;
}
.login-form {
  .c-autocomplete {
    max-width: 65px;
  }
}
.c-autocomplete {
  &__label {
    display: block;
    font-size: 14px;
    font-family: 'Manrope';
    font-weight: 600;
    color: #3a416f;
    margin-bottom: 8px;
  }

  & .v-input__slot {
    box-shadow: none !important;
    background: #f8fafc !important;
    border: 1px solid #e9eff7;
    border-radius: 8px;
  }

  & .v-text-field.v-text-field--solo .v-input__control {
    max-height: 46.6px !important;
    overflow: hidden;
  }

  & .v-select__selection {
    font-family: 'Manrope';
    font-weight: 500;
    color: #3a416f;
    font-size: 14px;
    line-height: 21px;
    transition: all 0.3s;
  }
  .theme--light.v-input--is-disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
}
.v-select__slot {
  input {
    font-family: 'Manrope' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 13px !important;
    line-height: 21px !important;
    letter-spacing: -0.15px !important;
    color: #3a416f !important;
  }
}
.v-list-item__title {
  font-family: 'Manrope' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 21px !important;
  letter-spacing: -0.15px !important;
  color: #3a416f !important;
}

.v-list-item__action {
  display: none !important;
}
</style>
