const Module = () => import("./Module.vue");

const mainRoutes = {
  path: "",
  name: "AIProducts",
  children: [
    {
      path: "/products",
      name: "AIProductsList",
      component: () => import("./views/AIProducts.vue"),
      breadName: "Главная",
      meta: {
        requiresAuth: true,
        sectionKey: 'ai_helper'
      },
    },
    {
      path: "/products/:id",
      name: "AIProduct",
      component: () => import("./views/AIProduct.vue"),
      breadName: "Товар",
      meta: {
        requiresAuth: true,
        // isWorkingNow:true
      },
    },
  ],
  component: Module,
};

export default mainRoutes;
