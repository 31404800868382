const answers = [
  {
    isExample: true,
    id: 2177341,
    owner: 24617,
    is_answered: false,
    ai_response: true,
    cabinet_id: 1456,
    id_on_wb: 'QwhI5Y0B-uQDHp63xQ4C',
    rating: '1',
    brand_name: 'tovar1001',
    product_name: 'Миниварка суповарка электрическая',
    nomenclature_id: 66416106,
    text: 'Крутая, но большая и острые края под держателем',
    answer_text:
      'Мы рады, что вам понравилась толкушка для пюре от Sokoloff store! Приносим свои извинения за неудобства с размерами и острыми краями. Ваше замечание очень важно для нас, и мы учтем это при производстве будущих партий товара. Если у вас возникнут дополнительные вопросы или пожелания, пожалуйста, не стесняйтесь обращаться. Благодарим вас за обратную связь и надеемся видеть вас снова',
    date_created: '2024-02-26T14:59:28+03:00',
    commentator_name: 'Рузанна',
    updated_at: '2024-03-06T15:51:35.998682+03:00',
    created_at: '2024-02-27T06:00:24.729556+03:00',
    autoanswer: 565,
    cabinet: null,
  },
  {
    isExample: true,
    id: 2176833,
    owner: 24617,
    is_answered: false,
    ai_response: true,
    cabinet_id: 1456,
    id_on_wb: 'WcgsKBZd7NM0lP3ugXPx',
    rating: '1',
    brand_name: 'tovar1001',
    product_name: 'Миостимулятор для тела и мышц электрический',
    nomenclature_id: 66416106,
    text: 'Метал мягкий, одноразовая',
    answer_text:
      'Благодарим за ваш отзыв и приносим извинения за возникшие неудобства. Мы всегда стремимся к тому, чтобы наша продукция соответствовала высоким стандартам качества. Пожалуйста, свяжитесь с нами по электронной почте или по телефону, чтобы мы могли обсудить возможность замены товара или возврата средств. Ваше мнение крайне важно для нас, и мы хотели бы решить эту проблему к вашему удовлетворению. С уважением, Ваш Sokoloff store',
    date_created: '2024-02-25T22:39:34+03:00',
    commentator_name: 'Мавлия',
    updated_at: '2024-03-06T15:51:36.022443+03:00',
    created_at: '2024-02-26T06:00:36.017478+03:00',
    autoanswer: 565,
    cabinet: null,
  },
  {
    isExample: true,
    id: 2176735,
    owner: 24617,
    is_answered: false,
    ai_response: false,
    cabinet_id: 1456,
    id_on_wb: 'nWpe4I0BhH9mrcYdx4XT',
    rating: '1',
    brand_name: 'tovar1001',
    product_name: 'Миостимулятор для тела и мышц электрический',
    nomenclature_id: 141936084,
    text: 'Бракованный не включается и  кнопка с однай стороны  вообще не как не защелкивает, к сожелению возврат не как не сделать за свою не внимательность!!! Это просто ужассс...',
    answer_text:
      'Привет! Признательны за то, что нашли время, чтобы оценить нас и написать отзыв. <span style="color: #0000ff">Галина</span>',
    date_created: '2024-02-25T16:05:24+03:00',
    commentator_name: 'Галина',
    updated_at: '2024-03-06T15:51:36.040729+03:00',
    created_at: '2024-02-25T19:50:11.905858+03:00',
    autoanswer: 565,
    cabinet: null,
  },
  {
    isExample: true,
    id: 2176580,
    owner: 24617,
    is_answered: false,
    ai_response: false,
    cabinet_id: 1456,
    id_on_wb: 'sjAU3Y0BRacRZYaKhl7O',
    rating: '1',
    brand_name: 'tovar1001',
    product_name: 'Миостимулятор для тела и мышц электрический',
    nomenclature_id: 141936084,
    text: 'Поломался при первой работе. Перестал делать нагрузки. Работает в одном режиме.',
    answer_text:
      'Приветствуем! Наша команда всегда рада вашей искренней обратной связи. <span style="color: #0000ff">Михаил</span>',
    date_created: '2024-02-25T00:45:26+03:00',
    commentator_name: 'Михаил',
    updated_at: '2024-03-06T15:51:36.064858+03:00',
    created_at: '2024-02-25T08:30:25.205508+03:00',
    autoanswer: 565,
    cabinet: null,
  },
];

export default { answers };
