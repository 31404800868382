import axios from "axios";
import API_ENV from "@/config/api.config";

let {} = API_ENV;

export default {
  state: {
    chapterParams: {
      sort: "gmv_desc",
      metric: "gmv",
      categoryId: "",
      brands_ids: [],
      store_ids: [],
      type: "",
    },
  },
  getters: {},
  mutations: {
    change_chapter_params() {},
  },
  actions: {
    GET_CHAPTER_CHART() {},
    GET_CHAPTER_CHART() {},
  },
};
