<template>
	<svg width="42" height="12" viewBox="0 0 42 12" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g opacity="0.6">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M6.67269 1.30242C7.00289 1.30054 7.98421 1.21636 8.39988 2.55245C8.67988 3.45245 9.12591 4.92669 9.73795 6.97517H9.98721C10.6436 4.81552 11.0945 3.34127 11.3399 2.55245C11.7599 1.20245 12.8099 1.30245 13.2299 1.30245L16.4703 1.30245V10.9024H13.1676V5.245H12.9461L11.105 10.9024H8.62015L6.77906 5.24081H6.55759V10.9024H3.25488V1.30245L6.67269 1.30242ZM21.2129 1.30245V6.96409H21.4764L23.7159 2.30856C24.1506 1.3821 25.0772 1.30245 25.0772 1.30245H28.2732V10.9024H24.9015V5.24081H24.6381L22.4424 9.89633C22.0077 10.8186 21.0373 10.9024 21.0373 10.9024H17.8412V1.30245H21.2129ZM39.9272 5.86443C39.4569 7.13373 37.9799 8.04276 36.3447 8.04276H32.8089V10.9024H29.6026V5.86443H39.9272Z"
				fill="#3A416F"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M36.4995 1.30273H29.4346C29.6027 3.44002 31.5353 5.26967 33.536 5.26967H40.1501C40.5318 3.49334 39.2179 1.30273 36.4995 1.30273Z"
				fill="#6E76A6"
			/>
		</g>
	</svg>
</template>

<script>
export default {
	name: 'MIRCardLogo',
};
</script>
