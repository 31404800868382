<template>
	<button
		class="c-btn"
		:variant="variant"
		@click="$emit('click', $event)"
	>
		<slot name="prepend"></slot>
		<slot></slot>
		<slot name="append" class="c-btn__icon"></slot>
	</button>
</template>

<script>


export default {
	name: 'CBtn',
	props: {
		variant: {
			type: 'primary' | 'reverse-primary' | 'default',
			default: 'primary'
		},
	},
};
</script>

<style lang="scss" scoped>
.c-btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-family: Inter;
	font-size: 13px;
	font-style: normal;
	font-weight: 600;
	line-height: 13px; /* 100% */
	letter-spacing: -0.24px;
	border-radius: 10px;
	padding: 12px 24px;

	&[variant=primary] {
		color: #FFF;
		background: #497ADA;
	}

	&[variant=reverse-primary] {
		color: #497ADA;
		background: rgba(73, 122, 218, 0.10);
	}
	&[variant=default] {
		color: #3A416F;
		background: rgba(58, 65, 111, 0.10);
	}
	&:disabled {
		opacity: 0.5;
	}
}
</style>
