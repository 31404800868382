<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path
      d="M3.51667 11.2867L4.40567 10.5756L4.4115 10.5709C4.597 10.4222 4.69092 10.3475 4.79533 10.2944C4.88867 10.2466 4.98842 10.2116 5.09167 10.1906C5.20775 10.1667 5.3285 10.1667 5.57117 10.1667H10.6351C11.2872 10.1667 11.6139 10.1667 11.863 10.0395C12.0825 9.92765 12.261 9.74918 12.3728 9.52967C12.5 9.28 12.5 8.95333 12.5 8.30233V3.86492C12.5 3.21275 12.5 2.88608 12.3728 2.637C12.2608 2.41742 12.0821 2.23895 11.8624 2.12717C11.6133 2 11.2867 2 10.6333 2H3.86667C3.21333 2 2.88667 2 2.637 2.12717C2.41746 2.23898 2.23898 2.41746 2.12717 2.637C2 2.88667 2 3.21333 2 3.86667V10.5581C2 11.1799 2 11.4908 2.12717 11.6501C2.18185 11.7187 2.25132 11.7742 2.3304 11.8123C2.40949 11.8503 2.49614 11.8701 2.58392 11.87C2.78808 11.87 3.03075 11.6752 3.51667 11.2867Z"
      stroke="#497ADA"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'MessageIcon',
};
</script>
