<template>
	<svg
		:width="width"
		:height="+width + width / 14"
		viewBox="0 0 14 15"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M13.7871 13.2518L10.3359 9.78631C11.2233 8.76788 11.7095 7.48648 11.7095 6.15249C11.7095 3.03576 9.08299 0.5 5.85474 0.5C2.62649 0.5 0 3.03576 0 6.15249C0 9.26923 2.62649 11.805 5.85474 11.805C7.06667 11.805 8.22158 11.4521 9.20899 10.7821L12.6864 14.2739C12.8318 14.4196 13.0273 14.5 13.2368 14.5C13.4351 14.5 13.6232 14.427 13.766 14.2943C14.0694 14.0124 14.0791 13.545 13.7871 13.2518ZM5.85474 1.97456C8.24092 1.97456 10.1821 3.84873 10.1821 6.15249C10.1821 8.45625 8.24092 10.3304 5.85474 10.3304C3.46855 10.3304 1.52732 8.45625 1.52732 6.15249C1.52732 3.84873 3.46855 1.97456 5.85474 1.97456Z"
			fill="#3A416F"
			fill-opacity="0.3"
		/>
	</svg>
</template>

<script>
export default {
	name: 'LoupeIcon',
	props: {
		width: {
			type: Number | String,
			default: 14,
		}
	}
}
</script>

<style lang="scss" scoped></style>
