import axios from 'axios';
import API_ENV from '@/config/api.config';
import connectWebSocket from '@/utils/connectWebSocket';

const { apiWbTokens, apiGetSymbankNum } = API_ENV;

function isFilledTokens(tokens) {
  for (const token in tokens.apiTokens) if (!tokens.apiTokens[token].title) return false;

  return true;
}

export default {
  state: {
    // Старые токены
    wbToken: null,
    // Переделанные Никитой(бэк) Токены
    wbSettingsTokens: [],
    cabinets: [],
  },
  getters: {
    userCabinetsInfo: ({ wbToken }) => wbToken,
    hasCompletedCabinet: (state, getters) =>
      state.wbSettingsTokens?.some(cabinet => cabinet.api_loading_status == 100) ||
      getters.getAnyTourActive,
    mostCompletedCabinet: ({ wbSettingsTokens }) =>
      wbSettingsTokens?.sort(
        (cabinetA, cabinetB) => cabinetB.api_loading_status - cabinetA.api_loading_status,
      )[0],
    wbSettingsTokens: ({ wbSettingsTokens }) => wbSettingsTokens,
    unconvertedCabinets: ({ cabinets }) => cabinets,
    hasFilledTokensCabinet: ({ wbSettingsTokens }) =>
      wbSettingsTokens.some(tokens => tokens.api_loading_status), // isFilledTokens(tokens)
  },
  mutations: {
    editCurrentWbToken(state, obj) {
      const { id, ...rest } = obj;
      let findedEl = state.wbSettingsTokens.find(el => el.id === id);
      findedEl = Object.assign(findedEl, rest);
    },
    setWbToken(state, data) {
      state.wbToken = data;
    },
    hideErrors(state, data) {
      state.wbToken = state.wbToken.map(cabinet => ({
        ...cabinet,
        api_loading_errors_list: [],
      }));
    },
    setSettingWbToken(state, data) {
      state.wbSettingsTokens = data;
    },
    addNewWbToken(state, obj) {
      state.wbSettingsTokens.unshift(obj);
    },
    addFakeWbToken(state) {
      if (state.wbSettingsTokens.length) return;

      const newData = {
        isExample: false,
        id: -1,
        name: 'Новый кабинет',
        isAutomaticlyEdit: true,
        is_active: true,
        apiTokens: {
          open_api_standart: {
            title: null,
            error: null,
          },
        },
      };
      state.wbSettingsTokens.unshift(newData);
      console.log({ s1: state.wbSettingsTokens });
    },
    editAutomaticleForCabinet(state, obj) {
      const { id, value } = obj;
      const findedCabinet = state.wbSettingsTokens.find(item => item.id === id);
      findedCabinet.isAutomaticlyEdit = value;
    },
    setWbTokenForSetting(state, data) {
      state.cabinets = data;

      const newData = data.map(item => {
        const tokensData = {
          isAutomaticlyEdit: true,
          minimum_percent_for_stock_availability: item?.minimum_percent_for_stock_availability
            ? item.minimum_percent_for_stock_availability
            : 0.33,
          old_minimum_percent_for_stock_availability: item?.minimum_percent_for_stock_availability
            ? item.minimum_percent_for_stock_availability
            : 0.33,
          apiTokens: {
            open_api_standart: {
              title: item.open_api_standart,
              error: '',
            },
          },
        };
        return {
          ...item,
          ...tokensData,
        };
      });
      state.wbSettingsTokens = newData;
    },
    setTokenError(state, obj) {
      const { tokensKind, currentToken, item, id } = obj;
      const findedCabinet = state.wbSettingsTokens.find(item => item.id === id);
      findedCabinet[tokensKind][currentToken].error = '';
      if (Object.keys(item).length > 5) {
        const error = Object.values(item).find(item => item.status_code !== 200);
        error ? (findedCabinet[tokensKind][currentToken].error = 400) : '';
      } else {
        findedCabinet[tokensKind][currentToken].error = item.status_code;
      }
    },
  },
  actions: {
    async patchCabinet({ commit }, data) {
      const { id, ...rest } = data;
      try {
        const { data } = await axios.patch(`${apiWbTokens}${id}`, rest);
        return data;
      } catch (error) {
        throw error;
      }
    },

    // ===================
    async checkSymbankNum({ commit }, id) {
      try {
        const { data } = await axios.get(`${apiGetSymbankNum}${id}/check`);
        return data;
      } catch (error) {
        commit('showAlert', error.response.data); //API-токен «Стандартный»
        console.log('error', new Error(error));
        throw error;
      }
    },

    async editTokensError({ commit, state }, obj) {
      const { id, item } = obj;
      if (item[1]?.status_code === 400) {
        commit('showAlert', 'Неверные токены!');
      }
      const findedCabinet = state.wbSettingsTokens.find(item => item.id === id);
      console.log(findedCabinet.apiTokens);
      // Проверка проходит по двум разделам токена: Ищет этот токен в API, если его нет, значит этот токен в "cookieTokens"
      if (findedCabinet.apiTokens[item[0]]) {
        commit('setTokenError', {
          id,
          tokensKind: 'apiTokens',
          currentToken: item[0],
          item: item[1],
        });
      }
    },
    async getWbToken({ commit }, id) {
      try {
        if (id) {
          const { data } = await axios.get(`${apiWbTokens}${id}`);
          return data;
        } else {
          const { data } = await axios.get(apiWbTokens);
          commit('setWbTokenForSetting', data);
          commit('setCurrencySympbolByCabinets', data)
          return data;
        }
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async editWbTokens({ commit }, data) {
      try {
        const { id, name, minimum_percent_for_stock_availability, is_active, apiTokens } = data;
        const sendData = {
          name,
          minimum_percent_for_stock_availability,
          is_active,
          open_api_standart: apiTokens.open_api_standart.title,
        };
        console.log('editWbTokens', sendData);
        const response = await axios.patch(`${apiWbTokens}${id}`, sendData);
        return response.data;
      } catch (error) {
        console.error(new Error(error));
        commit('showAlert', error.response.data);
        throw error;
      }
    },
    async removeWbTokens({ commit, state }, id) {
      try {
        console.log('removeWbTokens', id);
        await axios.delete(`${apiWbTokens}${id}`);
        const leftTokens = state.wbSettingsTokens.filter(item => item.id !== id);
        commit('setSettingWbToken', leftTokens);
      } catch (error) {
        console.log('error - error: ', error);
        console.error(new Error(error));
        throw error;
      }
    },
    async checkMainApiToken({ commit, state }, ids) {
      try {
        const arr = await Promise.all(
          ids.map(async item => {
            const response = await axios.get(`${apiWbTokens}check_cookie/${item}`);
            return [item, response.data.statistica];
          }),
        );
      } catch (error) {
        console.error(new Error(error));
        throw error;
      }
    },
    async checkWbTokens({ commit }, id) {
      try {
        const { data } = await axios.get(`${apiWbTokens}check_cookie/${id}`);
        return data;
      } catch (error) {
        console.error(new Error(error));
        throw error;
      }
    },
    async createWbTokens({ commit }) {
      try {
        const { data } = await axios.post(apiWbTokens);

        const newData = {
          id: data.id,
          name: data.name,
          isAutomaticlyEdit: true,
          isCreating: true, // откроется ли блок создания
          is_active: true,
          apiTokens: {
            open_api_standart: {
              title: null,
              error: null,
            },
          },
        };

        commit('addNewWbToken', newData);
        return newData;
      } catch (error) {
        console.error(new Error(error));
        commit('showAlert', error.response.data.error);
        throw error;
      }
    },
    connectCabinetsWebSocket({ commit }) {
      const ws = connectWebSocket('cabinets');

      ws.addEventListener('message', message => {
        const data = JSON.parse(message.data);

        commit('setWbTokenForSetting', data);
      });

      return new Promise(resolve => ws.addEventListener('message', resolve, { once: true }));
    },
  },
};
