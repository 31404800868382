import axios from 'axios';
import API_ENV from '@/config/api.config';
import getCardPosterUrl from '@/utils/getCardPosterUrl';

const { apiABTest, apiABTestVariants, apiABTestsCampaigns } = API_ENV;

export default {
  state: {
    filter: {
      page: 1,
      limit: 10,
      search: null,
      cabinet_id: null,
    },
    abTestsInfo: null,
    isTableLoading: false,
    limit: 0,
  },
  getters: {
    getABTestsFilter: ({ filter }) => filter,
    getABTestsRowData: ({ abTestsInfo }) => abTestsInfo?.items,
    getABTestsTotalPages: ({ abTestsInfo, filter }) => Math.ceil(abTestsInfo?.count / filter.limit),
    getABTestsTableLoading: ({ isTableLoading }) => isTableLoading,
    getAbTestsLimits: ({ limit }) => limit,
  },
  mutations: {
    setABTestsFilter(state, filter) {
      if (filter) state.filter = { ...state.filter, ...filter };
    },
    setABTestsData(state, data) {
      state.abTestsInfo = data;
    },
    setABTestsLimit(state, limit) {
      state.limit = limit;
    },
    setABTestsRowData(state, items) {
      state.abTestsInfo.items = items.map(item => ({ ...item, photo: item.variants[0]?.photo }));
    },
    deleteABTestById(state, id) {
      const items = state.abTestsInfo.items.filter(abTest => abTest.id != id);

      state.abTestsInfo.items = items;
    },
    deleteABTestVariantById(state, { variantId, abTestId }) {
      const item = state.abTestsInfo.items.find(el => el.id == abTestId);
      item.variants = item.variants.filter(variant => variant.id != variantId);
    },
    setABTestsTableLoading(state, loading) {
      state.isTableLoading = loading;
    },
  },
  actions: {
    async getABTests({ state, commit }, filters) {
      commit('setABTestsFilter', filters);

      const params = state.filter;

      try {
        commit('setABTestsTableLoading', true);
        const abTests = (await axios.get(apiABTest, { params })).data;

        commit('setABTestsData', abTests);
        commit('setABTestsRowData', abTests.items);

        return abTests;
      } catch (error) {
        commit('showAlert');
        throw error;
      } finally {
        commit('setABTestsTableLoading', false);
      }
    },
    async createABTest({ state, commit, dispatch }, body) {
      try {
        const abTest = (await axios.post(apiABTest, body)).data;

        const items = [abTest, ...state.abTestsInfo.items];

        commit('setABTestsRowData', items);
        dispatch('getAbTestsLimits');

        return abTest;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async deleteABTest({ commit }, id) {
      try {
        const result = await axios.delete(apiABTest + id);

        commit('deleteABTestById', id);

        return result.data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async getAbTestsLimits({ commit, dispatch }) {
      try {
        const limits = await dispatch('getLimits');

        commit('setABTestsLimit', limits.ab_tests || 0);

        return limits;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async changeABTestStatus({ commit }, { id, status }) {
      try {
        const body = { status };

        const result = await axios.patch(`${apiABTest}${id}/`, body);

        return result.data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async deleteABTestVariant({ commit }, { variantId, abTestId }) {
      try {
        const result = await axios.delete(apiABTestVariants + variantId);

        commit('deleteABTestVariantById', { variantId, abTestId });

        return result.data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async getABTestsCampaigns({ commit }, cabinet_id) {
      try {
        const result = await axios.get(apiABTestsCampaigns, { params: { cabinet_id } });

        result.data = result.data.map(campaign => ({
          ...campaign,
          photo: campaign.photo || getCardPosterUrl(campaign.nm_id),
        }));

        return result.data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
  },
};
