<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M11.3334 10.6667L8.66669 8.00004L11.3334 5.33337M7.33335 10.6667L4.66669 8.00004L7.33335 5.33337"
      stroke="#3C72FF"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowDuoBack",
};
</script>

<style lang="scss" scoped></style>
