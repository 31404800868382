<template>
  <transition name="copyChip">
    <div class="copyChip" v-if="isCopyChipVisible">
      <span class="copyChip-text">Скопировано!</span>
    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CopyPopUpChip",
  computed: {
    ...mapState({
      isCopyChipVisible: (state) => state.isCopyChipVisible,
    }),
  },
};
</script>

<style lang="scss" scoped>
.copyChip {
  position: fixed;
  z-index: 110;
  display: flex;
  align-items: center;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -30px);
  border: 1px solid #ced1d5;
  border-radius: 12.5px;
  padding: 3.5px 20px;
  background: #e9eff7;

  // background: #e2e2e2;
  // opacity: 0.9;

  .copyChip-text {
    font-family: "Manrope";
    font-weight: 700;
    font-size: 13px;
    letter-spacing: -0.4px;
    color: #3a416f;
    opacity: 0.8;
  }
}

.copyChip-enter-active,
.copyChip-leave-active {
  transition: all 0.3s ease;
}
.copyChip-enter,
.copyChip-leave-to {
  transform: translate(-50%, 30px);
}
</style>
