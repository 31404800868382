import axios from 'axios';
import API_ENV from '@/config/api.config';
import queryString from 'query-string';
import { sales } from '@/mocks';

const {
  apiSalesAndOrders,
  apiTopProducts,
  apiDownloadOrderProducts,
  apiDownloadRefundProducts,
  apiDownloadSellProducts,
} = API_ENV;

export default {
  state: {
    tableRowData: null,
    tableType: null,
    tableParams: {},
    isTableLoading: false,
    selectedSelfbuyouts: [],
  },
  getters: {
    getSalesAndOrdersTableRows: ({ tableRowData }) => tableRowData,
    getSalesAndOrdersTableLoading: ({ isTableLoading }) => isTableLoading,
    isSelectedSelfbuyoutId: state => srid =>
      state.selectedSelfbuyouts.find(sale => sale.srid == srid)?.isSelected,
    allSelectedSelfbuyouts: state => state.selectedSelfbuyouts.filter(sale => sale.isSelected),
  },
  mutations: {
    changeSalesAndOrdersTableRowData(state, tableRowData) {
      state.tableRowData = tableRowData;
    },
    setSalesExampleTableRowData(state) {
      if (!state.tableRowData?.count) state.tableRowData = sales.sales;
    },
    changeSalesAndOrdersTableType(state, tableType) {
      state.tableType = tableType;
    },
    changeSalesAndOrdersTableFilters(state, params) {
      state.tableParams = { ...state.tableParams, ...params };
    },
    changeSalesAndOrdersTableLoading(state, isTableLoading) {
      state.isTableLoading = isTableLoading;
    },
    selectSaleAsBuyout(state, { srid, isSelected }) {
      let saleIndex = state.selectedSelfbuyouts.findIndex(sale => sale.srid == srid);

      if (saleIndex < 0) state.selectedSelfbuyouts.push({ srid, isSelected });
      else state.selectedSelfbuyouts[saleIndex] = isSelected;
    },
  },
  actions: {
    async getSalesTableData(
      { state, commit },
      { tableType, page, limit, search, brand_title, cabinet, date },
    ) {
      const newParams = { ...state.tableParams, page, limit, search, brand_title, cabinet, date };
      console.log('newParams', newParams);
      commit('changeSalesAndOrdersTableFilters', newParams);

      if (!tableType) tableType = state.tableType;
      else commit('changeSalesAndOrdersTableType', tableType);

      try {
        commit('changeSalesAndOrdersTableLoading', true);

        const result = await axios.get(
          `${apiSalesAndOrders}${tableType}/list/?` + queryString.stringify(newParams),
        );

        commit(
          'changeSalesAndOrdersTableRowData',
          !result.data?.items ? { items: result.data } : result.data,
        );

        return !result.data?.items ? { items: result.data } : result.data;
      } catch (error) {
        commit('showAlert');
        throw error;
      } finally {
        commit('changeSalesAndOrdersTableLoading', false);
      }
    },
    async saveSalesSelectedSelftbuyouts({ state, commit }) {
      try {
        for (const sale of state.selectedSelfbuyouts)
          axios.patch(`${apiSalesAndOrders}sales/${sale.srid}/set_selfbuy/`, {
            is_selfbuy: sale.isSelected,
          });
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async getTopProducts({ commit }, params) {
      try {
        const result = await axios.get(`${apiTopProducts}?${params}`);

        return result.data;
      } catch (error) {
        console.error('error getTopProducts: ', new Error(error));
      }
    },
    async downloadOrderProducts({ state }, { params, page, limit }) {
      try {
        const { date, ...rest } = state.tableParams;
        const newParams = { date_range: date.split(' ').join(''), ...rest, ...params, page, limit };
        const result = await axios.get(`${apiDownloadSellProducts}`, { params: newParams });

        return result.data;
      } catch (error) {
        console.error('error downloadOrderProducts: ', new Error(error));
      }
    },
    async downloadRefundProducts({ state }, { params, page, limit }) {
      try {
        const { date, ...rest } = state.tableParams;
        const newParams = { date_range: date.split(' ').join(''), ...rest, ...params, page, limit };
        const result = await axios.get(`${apiDownloadSellProducts}`, { params: newParams });

        return result.data;
      } catch (error) {
        console.error('error downloadRefundProducts: ', new Error(error));
      }
    },
    async downloadSellProducts({ state }, { params, page, limit }) {
      try {
        const { date, ...rest } = state.tableParams;
        const newParams = { date_range: date.split(' ').join(''), ...rest, ...params, page, limit };
        const result = await axios.get(`${apiDownloadSellProducts}`, { params: newParams });

        return result.data;
      } catch (error) {
        console.error('error downloadSellProducts: ', new Error(error));
      }
    },
  },
};
