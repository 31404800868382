var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-wrapper"},[_c('transition',{attrs:{"name":"slide"}},[(_vm.showSidebar || !_vm.isMobile)?_c('SideBar',{attrs:{"isMobile":_vm.isMobile},on:{"showModal":_vm.showModal,"changeRouteName":_vm.changeRouteName},model:{value:(_vm.showSidebar),callback:function ($$v) {_vm.showSidebar=$$v},expression:"showSidebar"}}):_vm._e()],1),_c('div',{staticClass:"main-app",class:{
      fullWidth: _vm.isMobile,
    },style:(`min-width: ${_vm.minWidthMainBlock}`)},[_c('AppHeader',{attrs:{"isMobile":_vm.isMobile,"title":_vm.selectedRouteName},model:{value:(_vm.showSidebar),callback:function ($$v) {_vm.showSidebar=$$v},expression:"showSidebar"}}),_c('div',{staticClass:"main-section"},[(_vm.isPageWroking)?_c('DevWorking'):(_vm.isCloseSectionShown || _vm.isFirstLoad)?[(_vm.isFirstLoad)?_c('div',{staticClass:"app__loader"},[_c('img',{attrs:{"src":require("@/assets/img/mobileHeaderLogo.png"),"alt":"logo"}})]):(!_vm.isEnableSection)?_c('CloseBecauseTariff',{attrs:{"buttonsAvailable":"","rate-button-text":"Докупить раздел"}},[_vm._v(" Для работы с этим блоком "),_c('router-link',{staticClass:"link",attrs:{"to":"/rates"}},[_vm._v("купите")]),_vm._v(" раздел «"+_vm._s(_vm.sectionName)+"» ")],1):(_vm.isCloseForTariff)?_c('CloseBecauseTariff',{attrs:{"buttonsAvailable":""}},[_vm._v(" В текущем тарифе данный раздел недоступен. ")]):(!_vm.personalInfo?.current_tariff && !_vm.isFirstLoad)?_c('CloseBecauseTariff',{attrs:{"buttonsAvailable":"","rateButtonText":"Выбрать тариф"}},[_vm._v(" У вас не приобретен тариф. ")]):(_vm.isTokensRequirePage && _vm.isNotFilled)?_c('CloseBecauseTariff',{attrs:{"ownRateBtnAction":"","rateButtonText":"Подключить кабинет"},on:{"rateButtonClicked":_vm.changeVisibleForCreateCabinet}},[_c('p',{staticStyle:{"font-size":"18px"}},[_vm._v(" У вас отсутствуют необходимые API-токены. Перейдите в "),_c('router-link',{staticClass:"rates-link",attrs:{"tag":"a","to":"/settings"}},[_vm._v("настройки")]),_vm._v(" , чтобы их добавить. ")],1)]):(_vm.isTokensRequirePage && !_vm.$store.getters.hasCompletedCabinet)?_c('CloseSection',{attrs:{"empty":""}},[_c('CDataLoader')],1):_c('router-view',{staticClass:"main-contentView"})]:[_c('router-view',{staticClass:"main-contentView"}),(
            _vm.userInfo.current_tariff &&
            _vm.isAddTokenModalVisible &&
            !_vm.findedCabinet?.is_modal_about_100_percent_loading_viewed
          )?_c('WBCabinetsFirst',{attrs:{"cabinet":_vm.findedCabinet}}):(
            !_vm.findedCabinetToShowModal?.is_modal_about_100_percent_loading_viewed &&
            _vm.findedCabinetToShowModal?.api_loading_status === 100
          )?_c('CModal',{attrs:{"modal-title":"Поздравляем!"},on:{"closeModal":_vm.closeModal},model:{value:(_vm.isFreeTariffModalVisible),callback:function ($$v) {_vm.isFreeTariffModalVisible=$$v},expression:"isFreeTariffModalVisible"}},[(_vm.userInfo?.is_free_tariff_can_be_applied)?_c('CInformation',{attrs:{"text":"Вам начислен бесплатный тариф на 5 дней!"}}):_vm._e(),_c('p',[_vm._v("Ваши данные успешно загружены! Теперь Вы можете начать работу вместе с Mamod!")])],1):_vm._e()]],2),_c('FooterApp',{attrs:{"isMobile":_vm.isMobile}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }