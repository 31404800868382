<template>
  <svg
    @click="$emit('click')"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="width"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M5.83333 3V5.83333C5.83333 6.30004 5.83333 6.5334 5.92416 6.71166C6.00406 6.86846 6.13154 6.99594 6.28834 7.07584C6.4666 7.16667 6.69996 7.16667 7.16667 7.16667H12.8333C13.3 7.16667 13.5334 7.16667 13.7117 7.07584C13.8685 6.99594 13.9959 6.86846 14.0758 6.71166C14.1667 6.5334 14.1667 6.30004 14.1667 5.83333V3.83333M14.1667 18V12.6667C14.1667 12.2 14.1667 11.9666 14.0758 11.7883C13.9959 11.6315 13.8685 11.5041 13.7117 11.4242C13.5334 11.3333 13.3 11.3333 12.8333 11.3333H7.16667C6.69996 11.3333 6.4666 11.3333 6.28834 11.4242C6.13154 11.5041 6.00406 11.6315 5.92416 11.7883C5.83333 11.9666 5.83333 12.2 5.83333 12.6667V18M17.5 8.27124V14C17.5 15.4001 17.5 16.1002 17.2275 16.635C16.9878 17.1054 16.6054 17.4878 16.135 17.7275C15.6002 18 14.9001 18 13.5 18H6.5C5.09987 18 4.3998 18 3.86502 17.7275C3.39462 17.4878 3.01217 17.1054 2.77248 16.635C2.5 16.1002 2.5 15.4001 2.5 14V7C2.5 5.59987 2.5 4.8998 2.77248 4.36502C3.01217 3.89462 3.39462 3.51217 3.86502 3.27248C4.3998 3 5.09987 3 6.5 3H12.2288C12.6364 3 12.8402 3 13.0321 3.04605C13.2021 3.08688 13.3647 3.15422 13.5138 3.2456C13.682 3.34867 13.8261 3.4928 14.1144 3.78105L16.719 6.38562C17.0072 6.67387 17.1513 6.818 17.2544 6.98619C17.3458 7.13531 17.4131 7.29789 17.4539 7.46795C17.5 7.65976 17.5 7.86358 17.5 8.27124Z"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'Save',
  props: {
    color: {
      type: String,
      default: 'white',
    },
    width: {
      type: String | Number,
      default: 20,
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
	cursor: pointer;
}
</style>