import axios from 'axios';
import queryString from 'query-string';
import _ from 'lodash';

import API_ENV from '@/config/api.config';

let { apiExpensesCategory } = API_ENV;

export default {
  state: {
    expensesCategories: null,
  },
  getters: {
    expensesCategories: state => state.expensesCategories,
  },
  mutations: {
    setNewEditValue(state, { id, val }) {
      state.expensesCategories.items = state.expensesCategories.items.map(el => {
        if (el.id === id)
          el.title = val;

        return el;
      })
    },
    setIsEditCategory(state, id) {
      state.expensesCategories.items = state.expensesCategories.items.map(el => {
        el.isEdit = false;

        if (el.id === id)
          el.isEdit = true;

        return el;
      });
    },
    setExpensesCategories(state, payload) {
      state.expensesCategories = null;
      state.expensesCategories = payload;
    },
    setExampleExpensesCategories(state) {
      if (!state.expensesCategories?.count) state.expensesCategories = {
        isExample: true,
        "count": 4,
        "items": [
          {
            "id": 172,
            "title": "Категория 1",
            "exp_cat_type": "Для отчетов ВБ",
            "exp_cat_individual_num": 4
          },
          {
            "id": 171,
            "title": "Категория 2",
            "exp_cat_type": "Обычные",
            "exp_cat_individual_num": 3
          },
          {
            "id": 164,
            "title": "Категория 3",
            "exp_cat_type": "Для отчетов ВБ",
            "exp_cat_individual_num": 2
          },
          {
            "id": 161,
            "title": "Категория 4",
            "exp_cat_type": "Для отчетов ВБ",
            "exp_cat_individual_num": 1
        }
        ],
        "currentPage": 1,
        "lastPage": 1
      };
    },
    removeExampleExpensesCategories(state) {
      if (state.expensesCategories.isExample) state.expensesCategories = {
        "count": 0,
        "items": [],
        "currentPage": 1,
        "lastPage": 1
      }
    }
  },
  actions: {
    async addNewExpensesCategory({ commit }, { title, exp_cat_type }) {
      try {
        const result = await axios.post(`${apiExpensesCategory}/`, {
          title,
          exp_cat_type,
        });

        commit('setExpensesCategories', result.data);

        return result.data;
      } catch (error) {
        console.error('Error addNewExpensesCategory: ', new Error(error));
        throw error;
      }
    },
    async editNewExpensesCategory({ commit }, { id, title, exp_cat_type }) {
      try {
        const result = await axios.patch(`${apiExpensesCategory}/${id}`, {
          title,
          exp_cat_type,
        });

        return result.data;
      } catch (error) {
        console.error('Error editNewExpensesCategory: ', new Error(error));
      }
    },
    async removeNewExpensesCategory({ commit }, id) {
      try {
        const result = await axios.delete(`${apiExpensesCategory}/${id}`);

        return result.data;
      } catch (error) {
        console.error('Error removeNewExpensesCategory: ', new Error(error));
      }
    },
    async getOnceExpensesCategories({ commit }, id) {
      try {
        const result = await axios.get(`${apiExpensesCategory}/${id}`);

        return result.data;
      } catch (error) {
        console.error('Error getExpensesCategories: ', new Error(error));
      }
    },
    async getExpensesCategories({ commit }, { limit, page }) {
      try {
        const query = queryString.stringify({ limit, page });
        const result = await axios.get(`${apiExpensesCategory}?${query}`);

        commit('setExpensesCategories', _.cloneDeep(result.data));

        return result.data;
      } catch (error) {
        console.error('Error getExpensesCategories: ', new Error(error));
      }
    },
  },
};