<template>
  <div class="step">
    <div class="step__wrapper">
      <h4 class="step__title">Шаг {{ step }}</h4>
      <CPreviewThumbnail
        class="step__image"
        :src="require(`./${image}`)"
        :isCanBeOpen="true"
      />
    </div>
    <p class="step__description" v-html="description"></p>
  </div>
</template>
<script>
import CPreviewThumbnail from "@/components/UI/CPreviewThumbnail.vue";

export default {
  name: "WbCabinetsStep",
  props: {
    step: Number,
    image: String,
    description: String,
  },
  components: { CPreviewThumbnail },
};
</script>
<style lang="scss">
.step {
  display: flex;
  align-items: flex-start;
  flex: 1;
  justify-content: center;
  gap: 5px;
  &__title {
    font-size: 20px;
    white-space: nowrap;
  }
  &__description {
    font-size: 12px;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &__image {
    cursor: pointer;
  }
}
</style>

<style lang="scss">
.preview-thumbnail {
  &__preview {
    width: 500px;
  }
  &__image {
    border: 1px solid #c9c9c9;
  }
}
</style>
