import axios from 'axios';
import API_ENV from '@/config/api.config';
import queryString from 'query-string';
import downloadFile from '@/utils/downloadFile';

const { apiProductParameters, apiExpenseType, apiUpdateSales, apiExcelImportPurchasePrice } =
  API_ENV;

export default {
  state: {
    tableLoading: false,
    rowData: null,
    rowDataCount: 0,
    editedCells: [],
    tableData: {
      pageSize: 10,
      page: 1,
      goodsType: 'not_archive',
    },
    expensesFields: [],
    cabinet: { id: -1, name: 'Все кабинеты' },
  },
  getters: {
    goodParamsTableRows: ({ rowData }) => rowData,
    goodParamsExpensesFields: ({ expensesFields }) => expensesFields,
    goodParamsTableLoading: ({ tableLoading }) => tableLoading,
    goodParamsTableInfo: ({ tableData }) => tableData,
    goodParamsTotalPages: ({ rowDataCount, tableData }) =>
      Math.ceil(rowDataCount / tableData.pageSize),
    goodParamsEditedCellData:
      ({ editedCells }) =>
      id =>
        editedCells.find(cell => cell.id == id),
  },
  mutations: {
    goodParamsChangeRowValue(state, { field, value, index }) {
      state.rowData[index][field] = value;
    },
    changeGoodParamsRows(state, data) {
      const rowData = data || state.rowData;

      state.rowData = [...rowData];
    },
    changeGoodParamsRowByIndex(state, { index, data }) {
      // const tableRows = [...state.rowData]
      // tableRows[index] = { ...tableRows[index], ...data }
      // state.rowData = tableRows
      state.rowData[index] = { ...state.rowData[index], ...data };
    },
    changeGoodParamsExpensesFields(state, fields) {
      state.expensesFields = fields;
    },
    changeGoodParamsTableData(state, data) {
      state.tableData = { ...state.tableData, ...data };
    },
    changeGoodParamsTableLoading(state, tableLoading) {
      state.tableLoading = tableLoading;
    },
    changeGoodParamsTableCount(state, count) {
      state.rowDataCount = count;
    },
    goodParamsAddEditedCell(state, { field, value, id }) {
      let rowIndex = state.editedCells.findIndex(el => el.id == id);

      if (rowIndex < 0) {
        rowIndex = state.editedCells.length;
        state.editedCells.push({ id });
      }

      const expenseField = state.expensesFields.find(expense => expense.name == field);
      if (expenseField) {
        const rowExpenses =
          state.editedCells[rowIndex].expenses ||
          JSON.parse(JSON.stringify(state.rowData.find(row => row.id == id).expenses));

        let expenseIndex = rowExpenses.findIndex(row => row.name == field);

        if (expenseIndex < 0) rowExpenses.push({ id: expenseField.id, name: field, value });
        else rowExpenses[expenseIndex].value = value;

        if (!value) rowExpenses.splice(expenseIndex, 1);

        state.editedCells[rowIndex].expenses = rowExpenses;
        return;
      }

      if (field == 'nalog') value = parseFloat(value);
      else if (field == 'first_time') value = value.split('.').reverse().join('-');

      state.editedCells[rowIndex][field] = value;
    },
    clearEditedCells(state) {
      state.editedCells = [];
    },
    changeRowDataField(state, { field, value, index }) {
      const rowData = state.rowData;
      if (index) return (rowData[index][field] = value);

      rowData.forEach(row => (row[field] = value));
    },
    deleteRowsExpenseById(state, id) {
      for (const row of state.rowData) {
        const expenseIndex = row.expenses.findIndex(expense => expense.id == id);

        if (expenseIndex < 0) return;

        row.expenses.splice(expenseIndex, 1);
      }
      // commit('changeGoodParamsRows')
    },
    setGoodParamsExampleRows(state) {
      if (!state.rowData?.length)
        state.rowData = [
          {
            isExample: true,
            id: 5908,
            nm_id: 86196800,
            cabinet: 1279,
            vendor_code: 'Джоггеры чёрные',
            barcodes: '2033879144123',
            purchase_price: 3434,
            nalog: 0,
            first_time: '2023-10-26',
            stock_multiplicator: 1,
            code: null,
            expenses: [],
            stock_blocked: false,
            photo: null,
            size: '47',
          },
          {
            isExample: true,
            id: 5909,
            nm_id: 86196801,
            cabinet: 1279,
            vendor_code: 'Джоггеры белые',
            barcodes: '2033879144218',
            purchase_price: 2404,
            nalog: 0,
            first_time: '2023-10-16',
            stock_multiplicator: 1,
            code: null,
            expenses: [],
            stock_blocked: false,
            photo: null,
            size: '48',
          },
          {
            isExample: true,
            id: 5910,
            nm_id: 86196800,
            cabinet: 1279,
            vendor_code: 'Джоггеры женские',
            barcodes: '2033879144658',
            purchase_price: 4434,
            nalog: 0,
            first_time: '2023-10-21',
            stock_multiplicator: 1,
            code: null,
            expenses: [],
            stock_blocked: false,
            photo: null,
            size: '45',
          },
        ];
    },
    removeGoodParamsExampleRows(state) {
      state.rowData = state.rowData.filter(row => !row.isExample);
    },
    changeGoodParamsSelectedCabinet(state, cabinet) {
      if (cabinet.id < 0) state.cabinet = null;
      else state.cabinet = cabinet;
    },
  },
  actions: {
    async goodParamsUpdateRow({ state, commit, dispatch }) {
      if (!state.editedCells.length)
        return commit(
          'changeGoodParamsRows',
          state.rowData.map(row => ({ ...row, isEditable: false })),
        );

      try {
        await axios.patch(apiProductParameters, state.editedCells);

        commit('clearEditedCells');

        await dispatch('getGoodParamsTableData');
      } catch (error) {
        commit('showAlert', error.response.data[0]?.first_time && 'Введите существующую дату');
        throw error;
      }
    },
    async goodParamsUpdateRowById({ state, commit }, id) {
      const rowIndex = state.rowData.findIndex(row => row.id == id);
      const editedRowIndex = state.editedCells.findIndex(cell => cell.id == id);
      const editedRow = state.editedCells[editedRowIndex];

      commit('changeGoodParamsRowByIndex', {
        index: rowIndex,
        data: editedRow,
      });

      try {
        await axios.patch(apiProductParameters, [editedRow]);
      } catch (error) {
        commit('showAlert', error.response.data[0]?.first_time && 'Введите существующую дату');
        throw error;
      }
    },
    async goodParamsChangeRowArchive({ state, commit }, { id, isArchive }) {
      const row = state.rowData.find(row => row.id == id);

      try {
        await axios.patch(apiProductParameters, [{ id, stock_blocked: isArchive }]);

        row.stock_blocked = isArchive;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async getGoodParamsTableData({ state, commit }, tableParams) {
      try {
        commit('changeGoodParamsTableLoading', true);
        commit('changeGoodParamsTableData', tableParams);

        const { pageSize, goodsType } = state.tableData;

        const params = {
          ...state.tableData,
          limit: pageSize,
          products: goodsType == '-' ? undefined : goodsType,
        };

        const result = await axios.get(`${apiProductParameters}?${queryString.stringify(params)}`);

        commit('changeGoodParamsTableCount', result.data.count);
        commit('changeGoodParamsRows', result.data.items);

        return result.data;
      } catch (error) {
        commit('showAlert');
        throw error;
      } finally {
        commit('changeGoodParamsTableLoading', false);
      }
    },
    async goodParamsUpdateSalesActivate({ commit }) {
      try {
        const result = await axios.get(apiUpdateSales);

        return result.data;
      } catch (error) {
        throw error;
      }
    },
    async goodParamsUpdateSalesCheckCode({ getters, commit }, data) {
      try {
        const cabinetId = getters?.getSelectedCabinet?.cabinet_id;

        data.cabinet_id = cabinetId;

        const result = await axios.post(apiUpdateSales, data);

        return result.data;
      } catch (error) {
        const errorText = error.response?.data?.error || error.response?.data?.confirmationCode;
        commit('showAlert', errorText);
        throw error;
      }
    },
    async getExpenseTypes({ commit }) {
      try {
        const result = await axios.get(apiExpenseType);

        commit('changeGoodParamsExpensesFields', result.data);

        return result.data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async createExpenseType({ getters, commit }, typeInfo) {
      const { goodParamsExpensesFields } = getters;

      try {
        const result = await axios.post(apiExpenseType, typeInfo);

        commit('changeGoodParamsExpensesFields', [...goodParamsExpensesFields, result.data]);

        return result.data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async deleteExpenseType({ getters, commit }, id) {
      const { goodParamsExpensesFields } = getters;

      try {
        const result = await axios.delete(`${apiExpenseType}${id}`);

        const fieldIndex = goodParamsExpensesFields.findIndex(field => field.id == id);
        goodParamsExpensesFields.splice(fieldIndex, 1);
        commit('changeGoodParamsExpensesFields', goodParamsExpensesFields);
        commit('deleteRowsExpenseById', id);

        return result.data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async editExpenseType({ getters, commit }, { id, data }) {
      const { goodParamsExpensesFields } = getters;

      try {
        const result = await axios.patch(`${apiExpenseType}${id}`, data);

        const { name, type } = result.data;
        const fieldIndex = goodParamsExpensesFields.findIndex(field => field.id == id);

        goodParamsExpensesFields[fieldIndex].name = name;
        goodParamsExpensesFields[fieldIndex].type = type;
        commit('changeGoodParamsExpensesFields', goodParamsExpensesFields);

        return result.data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async changeFieldForAllRows({ state, commit }, { field, value }) {
      try {
        const rowData = state.rowData;
        const editData = [];

        for (const row of rowData) {
          const data = { id: row.id, [field]: value };

          editData.push(data);
        }

        commit('changeRowDataField', { field, value });

        await axios.patch(apiProductParameters, editData);
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async changeNalogForAllRows({ state, commit }, { nalog_system, nalog }) {
      try {
        const taxesConverter = { 'От Оборота': 'УСН', 'От Дохода': 'УСН Д-Р' };

        const rowData = state.rowData;
        const editData = [];

        for (const row of rowData) {
          const data = { id: row.id, nalog_system: taxesConverter[nalog_system], nalog };

          editData.push(data);
        }

        commit('changeRowDataField', {
          field: 'nalog_system',
          value: nalog_system,
        });
        commit('changeRowDataField', { field: 'nalog', value: nalog });

        await axios.patch(apiProductParameters, editData);
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async changeExpenseFieldForAllRows({ state, commit }, { expense }) {
      try {
        const rowData = state.rowData;
        const editData = [];

        for (const rowIndex in rowData) {
          const row = rowData[rowIndex];
          let expenses = row.expenses;

          if (expenses) {
            const expenseIndex = expenses.findIndex(el => el.id == expense.id);

            if (expenseIndex < 0) expenses.push(expense);
            else expenses[expenseIndex] = expense;
          } else expenses = [expense];

          const data = { id: row.id, expenses };

          editData.push(data);

          commit('changeRowDataField', {
            field: 'expense',
            value: expenses,
            index: rowIndex,
          });
        }

        commit('changeGoodParamsRows');
        await axios.patch(apiProductParameters, editData);
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async downloadExpensesExcelExampleFile({ commit }) {
      try {
        const result = await axios.get(apiExcelImportPurchasePrice, { responseType: 'blob' });

        downloadFile(result.data, 'Себестоимости товаров.xlsx');

        return result.data;
      } catch (error) {
        throw error;
      }
    },
    async uploadExpensesExcelFile({ commit }, file) {
      const formData = new FormData();
      formData.set('file', file);

      try {
        const result = await axios.post(apiExcelImportPurchasePrice, formData);

        return result.data;
      } catch (error) {
        throw error;
      }
    },
  },
};
