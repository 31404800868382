<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <g clip-path="url(#clip0_13374_84012)">
      <path
        d="M13.9996 4.2998L10.0996 6.8998L13.9996 9.4998V4.2998Z"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.8 3H2.3C1.58203 3 1 3.58203 1 4.3V9.5C1 10.218 1.58203 10.8 2.3 10.8H8.8C9.51797 10.8 10.1 10.218 10.1 9.5V4.3C10.1 3.58203 9.51797 3 8.8 3Z"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_13374_84012">
        <rect
          width="14"
          height="14"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'VideoIcon',
};
</script>
