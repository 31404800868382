<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.5 12.25H10.5M7 1.75V9.91667M7 9.91667L9.91667 7M7 9.91667L4.08333 7" stroke="#2486E1" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'DownloadIcon',
}
</script>

<style lang="scss" scoped></style>
