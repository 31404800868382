const Module = () => import('./Module.vue');

const stockRoutes = {
  path: '/external',
  name: 'external',
  breadName: 'Внешняя реклама',
  meta: {
    requiresAuth: true,
    sectionKey: 'external_advertising'
  },
  component: Module,
  children: [
    {
      path: '/external/list',
      name: 'ExternalList',
      component: () => import('./views/ExternalList.vue'),
      breadName: 'Внешняя реклама',
      meta: {
        // isCloseForShowroom: true,
        requiresAuth: true,
        sectionKey: 'external_advertising',
        noPageTour: true,
      },
      children: [],
    },
    {
      path: '/external/create/:id',
      name: 'ExternalCreate',
      component: () => import('./views/ExternalEdit.vue'),
      breadName: 'Создать ссылку',
      meta: {
        // isCloseForShowroom: true,
        requiresAuth: true,
      },
    },
    {
      path: '/external/edit/:id',
      name: 'ExternalEdit',
      component: () => import('./views/ExternalEdit.vue'),
      breadName: 'Изменить ссылку',
      meta: {
        // isCloseForShowroom: true,
        requiresAuth: true,
      },
    },
    {
      path: '/external/link/:id',
      name: 'ExternalLink',
      component: () => import('./views/ExternalLink.vue'),
      breadName: 'Статистика ссылки',
      meta: {
        // isCloseForShowroom: true,
        requiresAuth: false,
      },
    },
    {
      path: '/land/:code',
      name: 'ExternalLanding',
      component: () => import('./views/ExternalLanding.vue'),
      breadName: 'Статистика ссылки',
      meta: {
        // isCloseForShowroom: true,
        requiresAuth: true,
      },
    },
  ],
};

export default stockRoutes;
