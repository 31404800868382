<template>
	<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M6.5 0C2.91576 0 0 2.91576 0 6.5C0 10.0842 2.91576 13 6.5 13C10.0842 13 13 10.0842 13 6.5C13 2.91576 10.0842 0 6.5 0Z" fill="#B22323" fill-opacity="0.2"/>
		<path d="M3 6.5C3 6.22386 3.22386 6 3.5 6H9.5C9.77614 6 10 6.22386 10 6.5C10 6.77614 9.77614 7 9.5 7H3.5C3.22386 7 3 6.77614 3 6.5Z" fill="#B22323"/>
	</svg>
</template>

<script>
export default {
	name: 'DangerIcon',
}
</script>

<style lang="scss" scoped></style>
