import axios from 'axios'
import API_ENV from '@/config/api.config'

let { apiActualBids } = API_ENV

export default {
  state: {
    searchValue: {},
    page1: [],
    page2: []
  },
  mutations: {
    changeActualRatesPages(state, { page1, page2 }) {
      state.page1 = page1
      state.page2 = page2
    },
    changeActualBidsSearchCity(state, city) {
      state.searchValue.proxy_city = city
    }
  },
  actions: {
    getActualBidsData({ commit, state }, params) {
      // изменение стейта напрямую
      state.searchValue = params

      return new Promise((resolve, reject) => {
        axios.post(apiActualBids, params)
          .then(response => {
            let data = response.data

            let page1 = data.page1
            let page2 = data.page2

            commit('changeActualRatesPages', { page1, page2 })

            resolve(response)
          })
          .catch(err => {
            console.log(err);
            commit('showAlert', err.response.data.error)
            reject(err)
          })
      })
    },
  }
}