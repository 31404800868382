<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="Interface / Check">
			<path
				d="M5 10.0004L8.53553 13.5359L15.6058 6.46484"
				:stroke="color"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
	</svg>
</template>

<script>
export default {
	name: 'CheckIcon',
	props: {
		color: { type: String, default: 'white' },
	},
};
</script>

<style lang="scss" scoped></style>
