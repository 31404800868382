import axios from "axios";
import moment from "moment";
import reviewAnalysis from "./exampleDatas/reviewAnalysis";
import API_ENV from "@/config/api.config";

let {
  apiCreateAnalysis,
  apiGetAllTask,
  apiCurrentAnalysis,
  apiRemoveAnalysis,
  apiExportAnalysis,
  apiGetCurrentInfoTask,
} = API_ENV;

export default {
  state: {
    currentAnalysis: [],
    allTasks: [],
    activeItem: [],
  },
  mutations: {
    choiceCurrentAnalysis() {},
    setAllTasks(state, data) {
      state.allTasks = data;
    },
    mutateActtiveAnalysis(state, newActiveAnalysis) {
      state.activeItem = newActiveAnalysis;
    },
    setReviewAnalysisExample(state) {
      state.allTasks = [{
        isExample: true,
        "status": "done",
        "date": "2023-10-30",
        "numb_reviews": 59307,
        "average_rate": 4.7,
        "sku_list": [
          {
            "title": "Кроссовки спортивные",
            "cover": "/icons/sneakers.jpg",
            "sku": "152184256"
          },
          {
            "title": "Кроссовки спортивные для бега",
            "cover": "/icons/sneakers.jpg",
            "sku": "17524244"
          }
        ],
        "period_analysis": 30
      }]
    },
    removeReviewAnalysisExample(state) {
      state.allTasks = state.allTasks.filter(task => !task.isExample)
    }
  },
  getters: {
    getCurrentAnalysis: ({ currentAnalysis }) => currentAnalysis,
    getAllTasks: ({ allTasks }) => allTasks,
    getActiveItem: ({ activeItem }) => activeItem,
  },
  actions: {
    changeActiveItem({ commit }, activeAnalysis) {
      commit("mutateActtiveAnalysis", activeAnalysis);
    },
    async fetchAllTasks({ commit }, result) {
      try {
        // let result = await axios.get(apiGetAllTask);
        result = result.map((item) => {
          return {
            ...item,
            status: item.status === "done" ? true : false,
            date: moment(item.date).format("DD.MM.YYYY"),
            numb_reviews: Number(item.numb_reviews),
            average_rate: Number(item.average_rate),
          };
        });

        commit("setAllTasks", result);
        return result;
      } catch (error) {
        console.error(error);
      }
    },
    removeTask({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${apiRemoveAnalysis}${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },

    CREATE_ANALYSIS({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${apiCreateAnalysis}`, data)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    EXPORT_ANALYSIS({ commit }, data) {
      return new Promise((resolve, reject) => {
        if (data.option === "csv") {
          axios
            .get(`${apiExportAnalysis}?taskId=${data.id}&type=${data.option}`, {
              responseType: "blob",
            })
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "data.csv");
              document.body.appendChild(link);
              link.click();
              resolve(response.data);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        } else {
          axios
            .get(`${apiExportAnalysis}?taskId=${data.id}&type=${data.option}`, {
              responseType: "arraybuffer",
            })
            .then((response) => {
              const data = new Uint8Array(response.data);
              const blob = new Blob([data], { type: "application/octet-stream" });
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "file.xlsx");
              document.body.appendChild(link);
              link.click();
              resolve(response.data);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        }
      });
    },

    GET_CURRENT_ANALYSIS({ commit }, id) {
      if (id == 'example')
        return reviewAnalysis

      return new Promise((resolve, reject) => {
        axios
          .get(`${apiCurrentAnalysis}${id}`)
          .then((response) => {
            console.log(response);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    GET_CURRENT_INFO_ANALYSIS({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(apiGetCurrentInfoTask, {
            sku: [55844342, 140847650, 90343403],
            time_range: 14,
          })
          .then((response) => {
            console.log(response);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
