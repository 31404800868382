<template>
  <p class="information">
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="information__icon"
    >
      <path
        d="M8 10.6667V8M8 5.33333H8.00667M5.2 14H10.8C11.9201 14 12.4802 14 12.908 13.782C13.2843 13.5903 13.5903 13.2843 13.782 12.908C14 12.4802 14 11.9201 14 10.8V5.2C14 4.0799 14 3.51984 13.782 3.09202C13.5903 2.71569 13.2843 2.40973 12.908 2.21799C12.4802 2 11.9201 2 10.8 2H5.2C4.0799 2 3.51984 2 3.09202 2.21799C2.71569 2.40973 2.40973 2.71569 2.21799 3.09202C2 3.51984 2 4.0799 2 5.2V10.8C2 11.9201 2 12.4802 2.21799 12.908C2.40973 13.2843 2.71569 13.5903 3.09202 13.782C3.51984 14 4.0799 14 5.2 14Z"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <span v-if="htmlText" v-html="htmlText"></span>
    <span v-else>{{ text }}</span>
  </p>
</template>
<script>
export default {
  name: 'CInformation',
  props: {
    text: {
      type: String,
    },
    htmlText: String,
  },
};
</script>
<style lang="scss" scoped>
.information {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 18px;
  padding: 10px;
  border-radius: 6px;
  margin-top: 6px;
  background: #edf2fb;
  color: #497ada;
  &__icon {
    min-width: 24px;
    height: 24px;
    stroke: #8caae8;
    margin-right: 10px;
  }
}
</style>
