import axios from 'axios';
import API_ENV from '@/config/api.config';
import _ from 'lodash';

const {
  apiWarehouseProduct,
  apiWarehouseCategories,
  apiGetProductFromWb,
  apiSetCategoriesFromWb,
  apiGetWBCards,
} = API_ENV;

export default {
  state: {
    header: 'Goods',
    limit: 9,
    page: 1,
    productsCount: 1,
    filtredValues: [],
    isModalVisible: false,
    stockGoodsList: [], // Изменяемыый массив
    fakeGoodsList: [], // Неизмененный массив В котором "старые" данные
    goodsDiffList: [], // различия между прошлым массивом и новым
    categoryFilterList: [],
    goodsFilters: {},
  },
  getters: {
    filtredValues: ({ filtredValues }) => filtredValues,
    goodsFilters: ({ goodsFilters }) => goodsFilters,
    goodsCurrentPage: ({ page }) => page,
    productsCount: ({ productsCount }) => productsCount,
    isModalVisible: ({ isModalVisible }) => isModalVisible,
    stockGoodsList: ({ stockGoodsList }) => stockGoodsList,
    fakeGoodsList: ({ fakeGoodsList }) => fakeGoodsList,
    goodsDiffList: ({ goodsDiffList }) => goodsDiffList,
    categoryFilterList: ({ categoryFilterList }) => categoryFilterList,
  },
  mutations: {
    EDIT_CURRENT_PAGE(state, value) {
      state.page = value;
    },
    EDIT_LIMITS(state, value) {
      state.limit = value;
    },
    EDIT_PAGES_COUNTS(state, value) {
      state.productsCount = value;
    },
    EDIT_FILTERS(state, value) {
      state.goodsFilters = { ...state.goodsFilters, ...value };
    },
    EDOT_CATEGORY_FILTER_LIST(state, newList) {
      state.categoryFilterList = newList;
    },
    EDIT_FILTRED_VALUES(state, newValue) {
      state.filtredValues = newValue.map(item => item.title);
    },
    CHANGE_MODAL_VISIBLE(state, boolean) {
      if (boolean) {
        state.isModalVisible = boolean;
      } else {
        state.isModalVisible = !state.isModalVisible;
      }
    },
    EDIT_GOODS_LIST(state, data) {
      state.stockGoodsList = data;
    },
    EDIT_FAKE_GOODS_LIST(state, data) {
      state.fakeGoodsList = data;
    },
    EDIT_GOODS_DIIF_LIST(state, data) {
      state.goodsDiffList = data;
    },
  },

  actions: {
    async removeConntectionWithProduct({ commit }, data) {
      const { stock_product_id, wb_product_id } = data;

      try {
        await axios.delete(`${apiWarehouseProduct}${stock_product_id}/related/${wb_product_id}`);
      } catch (error) {
        console.error('Error removeConntectionWithProduct: ', error);
      }
    },
    async setEmptyCategory({ commit }) {
      try {
        await axios.post(apiSetCategoriesFromWb);
        return true;
      } catch (error) {
        console.error(error);
      }
    },
    async removeGoodItem({ commit }, id) {
      try {
        await axios.delete(`${apiWarehouseProduct}${id}`);
        return true;
      } catch (error) {
        console.error(error);
      }
    },
    async getProductsFromWB({ commit }) {
      try {
        await axios.post(apiGetProductFromWb);
        return true;
      } catch (error) {
        console.error(error);
        commit('showAlert', error.response.data.error);
      }
    },
    async editCountProduct({ commit }, data) {
      let { product_id, related_wb_products } = data;
      const relatedWbProducts = JSON.parse(JSON.stringify(related_wb_products));
      relatedWbProducts.forEach(item => {
        item.count_in_card = item.count;
      });

      await axios.patch(`${apiWarehouseProduct}${product_id}`, {
        related_wb_products: relatedWbProducts,
      });
    },
    async editStockGoodProduct({ commit }, { id, data }) {
      try {
        const result = await axios.patch(`${apiWarehouseProduct}${id}`, data);

        return result.data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async saveEditedProduct({ commit, state }, obj) {
      try {
        if (obj) {
          const { id } = obj;
          const sentObj = JSON.parse(
            JSON.stringify({
              ...state.stockGoodsList.find(item => item.id === id),
              category: obj.category.title,
            }),
          );
          delete sentObj.user_photos;
          const respose = await axios.put(`${apiWarehouseProduct}${id}`, sentObj);
          return respose;
        } else {
          // Функция проверки на изменение данных в объектах.
          const changedObject = state.stockGoodsList.filter(item => {
            const correspondingEl = state.fakeGoodsList.find(el => el.id === item.id);
            if (!correspondingEl) {
              return true; // Новый объект
            }
            // Проверяет, является ли ключ объектом. Иначе сравнивает заголовки в объекте
            return Object.keys(item).some(key => {
              if (typeof item[key] !== 'object' || typeof correspondingEl[key] !== 'object') {
                return item[key] !== correspondingEl[key];
              } else {
                if (item[key] === null || correspondingEl[key] == null) return;
                return JSON.stringify(item[key]) !== JSON.stringify(correspondingEl[key]);
              }
            });
          });
          commit('EDIT_GOODS_DIIF_LIST', changedObject);
          const goodsWithDiff = JSON.parse(JSON.stringify(state.goodsDiffList));
          const promises = await goodsWithDiff.map(async element => {
            const commingElement = element;
            if (commingElement?.user_photos) {
              delete commingElement.user_photos;
            }
            const data = {
              ...commingElement,
              category: commingElement?.category?.title,
            };
            return await axios.put(`${apiWarehouseProduct}${commingElement.id}`, data);
          });
          const results = await Promise.all(promises);
          commit('EDIT_GOODS_DIIF_LIST', []);
          return results;
        }
      } catch (error) {
        console.error(new Error(error));
        commit('showAlert');
      }
    },
    editGoodsList({ commit, state }, newData) {
      let newValue;
      if (state.stockGoodsList.length) {
        newValue = state.stockGoodsList.map(item => {
          if (item.id === newData.data.id) {
            if (newData.currentColumn === 'category') {
              item[newData.currentColumn] = newData.data.category;
            } else {
              item[newData.currentColumn] = newData.currentValue;
            }
          }
          return item;
        });
      }
      commit('EDIT_GOODS_LIST', newValue);
    },
    editFakeGoodsList({ commit }, newData) {
      commit('EDIT_FAKE_GOODS_LIST', newData);
    },
    async addNewCategory({ commit }, data) {
      try {
        const response = await axios.post(apiWarehouseCategories, data);

        return response.data;
      } catch (error) {
        showAlert(error);
      }
    },
    async getAllGoods({ commit, state }) {
      try {
        let paramsList;
        if (state.goodsFilters && Object.keys(state.goodsFilters).length) {
          paramsList = Object.entries(state.goodsFilters)
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join('&');
        }
        if (Object.keys(state.goodsFilters).length) {
          commit('EDIT_CURRENT_PAGE', 1);
        }
        const { data } = await axios.get(
          `${apiWarehouseProduct}?${paramsList}&limit=${state.limit}&page=${
            state.page
          }&${state.filtredValues.map(item => `category=${item}`).join('&')}`,
        );
        const editebleResponse = _.cloneDeep(data.items);
        editebleResponse.forEach(item => {
          item.isEdit = false;
          item.canRowEdit = true;
        });

        const newRes2 = _.cloneDeep(data.items);

        commit('EDIT_GOODS_LIST', editebleResponse);
        commit('EDIT_FAKE_GOODS_LIST', newRes2);
        commit('EDIT_PAGES_COUNTS', data.count);
        return data.items;
      } catch (error) {
        console.error('Error getAllGoods: ', error);
      }
    },
    async createGood({ commit }, data) {
      try {
        const response = await axios.post(apiWarehouseProduct, data);

        return response.data;
      } catch (error) {
        console.log('error', error);
        switch (Object.keys(error.response.data)[0]) {
          case 'title':
            commit('showAlert', 'Необходимо заполнить название товара');
            break;
          case 'user_photos':
            commit('showAlert', Object.values(error.response.data)[0]);
            break;
          case 'category':
            commit('showAlert', 'Необходимо выбрать категорию');
            break;
          case 'internal_article':
            commit('showAlert', 'Необходимо заполнить внутренний артикул');
            break;
          default:
            break;
        }
        throw error;
      }
    },
    async getWBProducts() {
      try {
        const response = await axios.get(apiGetWBCards);

        return response.data;
      } catch (error) {
        console.error('Error getWBProducts: ', error);
      }
    },
    async getAllCategories({ commit }) {
      try {
        const response = await axios.get(apiWarehouseCategories);
        commit('EDOT_CATEGORY_FILTER_LIST', response.data);

        return response.data;
      } catch (error) {
        console.error('Error getAllCategories: ', error);
      }
    },
  },
};
