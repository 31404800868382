<template>
  <div class="switch" :class="{ 'switch--disabled': !enabled }">
    <input
      type="checkbox"
      :checked="value"
      class="input-checkbox"
      @input="onInput"
      :disabled="!enabled"
      :id="switchId"
    />
    <label
      :for="switchId"
      :disabled="!enabled"
      class="input-checkbox__label"
      :style="{ background: value ? background : '#c0ccdc' }"
    ></label>
    <label :for="switchId" class="switch__label">{{ label }}</label>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'CSwitch',
  props: {
    label: {
      type: String,
      required: false,
    },
    value: {
      type: Boolean,
    },
    enabled: { type: Boolean, default: true },
    background: { type: String, default: '#3586ff' },
  },
  data() {
    return {
      switchId: uuidv4(),
    };
  },
  methods: {
    onInput() {
      this.$emit('input', !this.value);
    },
    handleClick() {
      if (this.enabled) {
        this.onInput();
      }
    },
  },
};
</script>

</script>

<style lang="scss">
.switch {
  display: flex;
  align-items: center;
  min-height: 1.5rem;

  &__label {
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: #3a416f;
    margin-left: 15px;
    cursor: pointer;
  }

  &--disabled {
    .input-checkbox__label {
      cursor: not-allowed;
      background: #e0e0e0; /* Цвет фона для отключенного состояния */
    }

    .switch__label {
      cursor: not-allowed;
      color: #a0a0a0; /* Цвет текста для отключенного состояния */
    }
  }
}

</style>
