import axios from "axios";
import API_ENV from "@/config/api.config";

const { apiSendFile, apiSendFileToWb } = API_ENV;

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async sendFile({ commit }, data) {
      try {
        const response = await axios.post(apiSendFile, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("sendFile", response.data);
        return response.data;
      } catch (error) {
        const keyError = Object.entries(error?.response?.data)[0][0];
        const valueError = Object.entries(error?.response?.data)[0][1][0];
        const currentError = () => {
          switch (keyError) {
            case "excel_file":
              commit("showAlert", valueError);
              console.log(valueError);
              break;
            case "maximum_price_increase":
              commit(
                "showAlert",
                `Ошибка в максимально повышенной цене: ${valueError}`
              );
              console.log(valueError);
              break;
            case "maximum_price_reduction":
              commit(
                "showAlert",
                `Ошибка в максимально пониженной цене: ${valueError}`
              );
              console.log(valueError);
              break;
            default:
              commit("showAlert", error?.response?.data);

              break;
          }
          console.log(error?.response?.data);
        };
        currentError();
      }
    },
    async sendFileToWB({ commit }, id) {
      try {
        const response = await axios.post(apiSendFileToWb, { cabinet_id: id });
        return response;
      } catch (error) {
        commit("showAlert", error.response.data.error);
        console.error(new Error(error), error);
        throw error;
      }
    },
  },
};
