<template>
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.5 0C2.91576 0 0 2.91576 0 6.5C0 10.0842 2.91576 13 6.5 13C10.0842 13 13 10.0842 13 6.5C13 2.91576 10.0842 0 6.5 0Z" fill="#31B223" fill-opacity="0.2"/>
    <path d="M9.79196 5.12204L6.2711 8.64281C6.16547 8.74844 6.02681 8.8016 5.88815 8.8016C5.7495 8.8016 5.61084 8.74844 5.50521 8.64281L3.74483 6.88242C3.53297 6.67067 3.53297 6.32829 3.74483 6.11654C3.95658 5.90469 4.29886 5.90469 4.51071 6.11654L5.88815 7.49398L9.02608 4.35616C9.23783 4.1443 9.58011 4.1443 9.79196 4.35616C10.0037 4.56791 10.0037 4.91019 9.79196 5.12204Z" fill="#31B223"/>
  </svg>
</template>

<script>
export default {
	name: 'SuccessIcon',
}
</script>

<style lang="scss" scoped></style>
