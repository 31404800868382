import axios from 'axios';
import queryString from 'query-string';
import store from '@/store';

import API_ENV from '@/config/api.config';

let {
  apiStockPlanning,
  apiStockPlaningUpload,
  apiAllActiveCabinets,
  apiCabinetsWhithoutExcel,
  apiUserBrandsForPlanning,
  apiUserCategoriesForPlanning,
  apiUserCardsForPlanning,
  apiWbStocksForPlanning,
  apiMinimumDateForPurchePlanning,
  apiDownloadProcurement,
} = API_ENV;

export default {
  state: {
    currentCabinet: {
      cabinet: null,
      cabinets: [],
    },
    allActiveCabinets: [],
    selectedCabinet: null,
    userCabinets: [],
    userBrands: [],
    userCategories: [],
    userCards: [],
    wbStocks: [],
    minimumDateForPurchePlanning: [],
  },
  getters: {
    currentCabinet: state => state.currentCabinet,
    allActiveCabinets: state => state.allActiveCabinets,
    getSelectedCabinet: state => state.selectedCabinet,
    userCabinets: state => state.userCabinets,
    userCategories: state => state.userCategories,
    userCards: state => state.userCards,
    wbStocks: state => state.wbStocks,
    userBrands: state => state.userBrands,
    minimumDateForPurchePlanning: state => state.minimumDateForPurchePlanning,
  },
  mutations: {
    changeSelectedCabinet(state, cabinet) {
      state.selectedCabinet = cabinet;
    },
    setCurrenCabinet(state, payload) {
      state.currentCabinet = payload;
    },
    setAllActiveCabinets(state, payload) {
      state.allActiveCabinets = payload;
    },
    setUserCabinets(state, payload) {
      state.userCabinets = payload;
    },
    setUserBrands(state, payload) {
      state.userBrands = payload;
    },
    setUserCategories(state, payload) {
      state.userCategories = payload;
    },
    setUserCards(state, payload) {
      state.userCards = payload;
    },
    setWbStocks(state, payload) {
      state.wbStocks = payload;
    },
    setMinimumDateForPurchePlanning(state, payload) {
      state.minimumDateForPurchePlanning = payload;
    },
  },
  actions: {
    async downloadProcurementFile({ commit }, req) {
      try {
        const result = await axios.post(
          `${apiDownloadProcurement}?cabinet=${req.cabinet}`,
          req.list,
        );

        return result.data;
      } catch (error) {
        console.error('Error downloadProcurementFile: ', new Error(error));
        throw error;
      }
    },
    async setSettingsForReport({ commit }, req) {
      try {
        const result = await axios.post(`${apiStockPlanning}/set_settings_for_report/`, req);

        return result.data;
      } catch (error) {
        console.error('Error setSettingsForReport: ', new Error(error));
      }
    },
    async uploadPlanningFile({ commit }, { file, cabinet }) {
      try {
        let formData = new FormData();
        formData.append('excel_file', file);

        const result = await axios.post(
          `${apiStockPlaningUpload}?cabinet=${cabinet?.cabinet_name}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );

        return result.data;
      } catch (error) {
        console.error('Error uploadPlanningFile: ', new Error(error));
        throw error;
      }
    },
    async getStockPlanningReport({ commit }, obj) {
      const { id, remnantsOfWB, ...rest } = obj;
      try {
        const result = await axios.get(
          `${apiStockPlanning}/report/${id}?take_into_account_the_remnants_of_WB=${remnantsOfWB}`,
          { params: rest },
        );

        return result.data;
      } catch (error) {
        console.error(new Error(error));
      }
    },
    async getUserCabinets({ commit }) {
      try {
        const result = await axios.get(`${apiCabinetsWhithoutExcel}`);

        commit('setUserCabinets', result.data);

        return result.data;
      } catch (error) {
        console.error(new Error(error));
      }
    },
    async getAllActiveCabinets({ commit }) {
      try {
        const result = await axios.get(`${apiAllActiveCabinets}`);

        commit('setAllActiveCabinets', result.data);
        commit('changeSelectedCabinet', result.data[0]);

        return result.data;
      } catch (error) {
        console.error(new Error(error));
      }
    },
    async getUserBrandsForPlanning({ commit }, payload) {
      try {
        const req = queryString.stringify(payload);
        const result = await axios.get(`${apiUserBrandsForPlanning}${req ? '?' + req : ''}`);

        commit('setUserBrands', result.data);

        return result.data;
      } catch (error) {
        console.error(new Error(error));
      }
    },
    async getUserCategoriesForPlanning({ commit }, payload) {
      try {
        const req = queryString.stringify(payload);
        const result = await axios.get(`${apiUserCategoriesForPlanning}${req ? '?' + req : ''}`);

        commit('setUserCategories', result.data);

        return result.data;
      } catch (error) {
        console.error(new Error(error));
      }
    },
    async getUserCardsForPlanning({ commit }, payload) {
      try {
        const req = queryString.stringify(payload);
        const result = await axios.get(`${apiUserCardsForPlanning}${req ? '?' + req : ''}`);

        commit('setUserCards', JSON.parse(JSON.stringify(result.data)));

        return result.data;
      } catch (error) {
        console.error(new Error(error));
      }
    },
    async getWbStocksForPlanning({ commit }, req) {
      try {
        const result = await axios.get(`${apiWbStocksForPlanning}`);

        commit('setWbStocks', result.data);

        return result.data;
      } catch (error) {
        console.error(new Error(error));
      }
    },
    async getMinimumDateForPurchePlanning({ commit }, req) {
      try {
        const result = await axios.get(`${apiMinimumDateForPurchePlanning}`);

        commit('setMinimumDateForPurchePlanning', result.data);

        return result.data;
      } catch (error) {
        console.error('Error getMinimumDateForPurchePlanning: ', new Error(error));
      }
    },
  },
};
