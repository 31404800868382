<template>
  <svg width="41" height="12" viewBox="0 0 41 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.7666 11.8273H14.4453L16.5227 0.210938H19.8438L17.7666 11.8273Z" fill="white"/>
    <path d="M29.8067 0.494553C29.1516 0.259513 28.1126 0 26.8278 0C23.5479 0 21.2382 1.58171 21.2241 3.84309C21.1968 5.51153 22.8777 6.43822 24.1349 6.99453C25.4198 7.56299 25.8566 7.93403 25.8566 8.44074C25.8436 9.21895 24.8183 9.57767 23.8621 9.57767C22.5361 9.57767 21.8256 9.39272 20.7459 8.95976L20.3085 8.77416L19.8438 11.3818C20.6227 11.7027 22.0579 11.9875 23.5479 12C27.0328 12 29.3016 10.4428 29.3285 8.03291C29.3418 6.71054 28.4542 5.69729 26.5406 4.86932C25.379 4.33781 24.6676 3.97942 24.6676 3.43559C24.6812 2.9412 25.2693 2.43482 26.5806 2.43482C27.6603 2.41002 28.4536 2.64473 29.0546 2.87961L29.355 3.00296L29.8067 0.494553Z" fill="white"/>
    <path d="M34.2206 7.71201C34.4941 7.04467 35.5466 4.46186 35.5466 4.46186C35.5328 4.48666 35.8196 3.7822 35.9836 3.34973L36.2157 4.35066C36.2157 4.35066 36.8447 7.13122 36.9812 7.71201C36.4622 7.71201 34.8766 7.71201 34.2206 7.71201ZM38.3203 0.210938H35.7513C34.9591 0.210938 34.3572 0.420847 34.0154 1.17475L29.082 11.8271H32.567C32.567 11.8271 33.1407 10.3934 33.264 10.0846C33.6463 10.0846 37.0364 10.0846 37.5283 10.0846C37.6236 10.4924 37.9246 11.8271 37.9246 11.8271H40.9998L38.3203 0.210938Z" fill="white"/>
    <path d="M11.6707 0.210938L8.41804 8.13215L8.0626 6.52564C7.46126 4.67193 5.57532 2.65791 3.4707 1.65649L6.45 11.8149H9.96217L15.1827 0.210938H11.6707Z" fill="white"/>
    <path d="M5.39829 0.210938H0.0546674L0 0.445649C4.16836 1.40962 6.92899 3.73325 8.06321 6.52613L6.90157 1.1874C6.71032 0.445484 6.1226 0.235411 5.39829 0.210938Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'VISACardLogoLight'
}
</script>

<style lang="scss" scoped></style>
