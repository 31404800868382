import axios from 'axios';
import API_ENV from '@/config/api.config';

const { apiPositions } = API_ENV;

export default {
  state: {
    date_end: null,
    date_start: null,
  },
  getters: {},
  mutations: {
    convertDate(state, { date_start, date_end }) {
      if (!date_end) state.date_end = new Date().toJSON().split('T')[0];
      else state.date_end = date_end;

      if (!date_start)
        state.date_start = new Date(new Date(state.date_end) - 30 * 24 * 60 * 60 * 1000)
          .toJSON()
          .split('T')[0];
      else state.date_start = date_start;
    },
    setChartsData(state, data) {
      state.chartsData = data;
    },
    setKeywordsTableData(state, data) {
      state.keywordsTableData = data;
    },
  },
  actions: {
    async getPositions({ commit }, { limit, offset }) {
      try {
        const { data } = await axios.get(`${apiPositions}?limit=${limit}&offset=${offset}`);
        return data;
      } catch (error) {
        console.error(new Error(error));
        commit('showAlert');
        return error;
      }
    },
    async addPosition({ commit }, { sku, sampleId }) {
      const data = { sku };
      if (sampleId) data.kw_list_id = sampleId;
      try {
        const response = axios.post(apiPositions, data);
        return response;
      } catch (error) {
        commit('showAlert', error.response.data.errors);
        throw error;
      }
    },
    deletePosition({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${apiPositions}${id}/`)
          .then(response => {
            resolve(response);
          })
          .catch(err => {
            commit('showAlert');
            reject(err);
          });
      });
    },
    openPositionDetail({ commit, state }, { id, date_end, date_start }) {
      commit('convertDate', { date_start, date_end });

      return new Promise((resolve, reject) => {
        axios
          .get(
            `${apiPositions}${id}/charts/?date_end=${state.date_end}&date_start=${state.date_start}`,
          )
          .then(response => resolve(response))
          .catch(err => {
            commit('showAlert');
            reject(err);
          });
      });
    },
    getKeywordsTableData({ commit, state }, { id, date_end, date_start }) {
      commit('convertDate', { date_start, date_end });

      return new Promise((resolve, reject) => {
        axios
          .get(
            `${apiPositions}${id}/key-words/?date_end=${state.date_end}&date_start=${state.date_start}`,
          )
          .then(response => resolve(response))
          .catch(err => {
            commit('showAlert');
            reject(err);
          });
      });
    },
  },
};
