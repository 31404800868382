import axios from 'axios';
import API_ENV from '@/config/api.config';

const { apiRepricer } = API_ENV;

const apiRepricerTask = apiRepricer + 'price_task/';
const apiRepricerTaskSettings = apiRepricer + 'price_task/settings/';
const apiRepricerCompetitor = apiRepricerTask + 'competitor/';

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getRepricerAllTasks({ commit }, body) {
      try {
        const result = await axios.get(apiRepricerTask + 'all', {
          params: body,
        });

        return result.data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async getRepricerTask({ commit }, nm_id) {
      try {
        const result = await axios.get(apiRepricerTask, { params: { nm_id } });

        return result.data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async createRepricerTask({ commit }, nm_id) {
      try {
        const result = await axios.post(apiRepricerTask + '?nm_id=' + nm_id);

        return result.data;
      } catch (error) {
        commit('showAlert', error.response.data.detail);
        throw error;
      }
    },
    async deleteRepricerTask({ commit }, id) {
      try {
        const result = await axios.delete(apiRepricerTask + id);

        return result.data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async getRepricerCompetitorsList({ commit }, id) {
      try {
        const result = await axios.get(apiRepricerCompetitor + 'all?task_id=' + id);

        return result.data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async createRepricerCompetitor({ commit }, data) {
      try {
        const result = await axios.post(apiRepricerCompetitor, data);

        return result.data;
      } catch (error) {
        commit('showAlert', error.response.data.detail);
        throw error;
      }
    },
    async deleteRepricerCompetitor({ commit }, id) {
      try {
        const result = await axios.delete(apiRepricerCompetitor + id);

        return result.data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async changeRepricerTaskParams({ commit }, data) {
      try {
        const result = await axios.put(apiRepricerTask, data);

        result.data.current_margin = +(result.data.current_margin * 100).toFixed(2);
        result.data.discount_percent = +(result.data.discount_percent * 100).toFixed(2);

        return result.data;
      } catch (error) {
        const errorData = error.response.data;
        commit('showAlert', errorData.detail || errorData.error);
        throw error;
      }
    },
    async changeRepricerTaskSettings({ commit }, data) {
      try {
        const result = await axios.put(apiRepricerTaskSettings, data);

        return result.data;
      } catch (error) {
        const errorData = error.response.data;
        commit('showAlert', errorData.detail || errorData.error);
        throw error;
      }
    },
    async changeRepricerTask({ commit }, { taskParams, taskSettings }) {
      const paramPromise = new Promise((res, rej) =>
        axios.put(apiRepricerTask, taskParams).then(res).catch(rej),
      );
      const settingsPromise = new Promise((res, rej) =>
        axios.put(apiRepricerTaskSettings, taskSettings).then(res).catch(rej),
      );

      return Promise.all([paramPromise, settingsPromise]);
    },
  },
};
