<template>
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.12246 17.025C9.12174 17.912 11.503 17.8638 13.5421 16.6865C16.93 14.7305 18.0908 10.3984 16.1348 7.01052L15.9265 6.64968M3.86571 14.094C1.9097 10.7061 3.07048 6.37395 6.45839 4.41794C8.49754 3.24064 10.8788 3.19246 12.878 4.07943M2.07812 14.1637L4.35483 14.7737L4.96488 12.497M15.0358 8.60704L15.6459 6.33033L17.9226 6.94037" stroke="#497ADA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'UpdateIcon',
}
</script>

<style lang="scss" scoped></style>
