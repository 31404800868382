const index = () => import('./index.vue');
const Main = () => import('./views/Main.vue');
const CreateTask = () => import('./views/CreateTask.vue');

const router = {
  path: '/price-tasks',
  name: 'price-tasks',
  breadName: 'Репрайсер',
  meta: {
    requiresAuth: true,
    isCloseForShowroom: true,
  },
  component: index,
  children: [
    {
      path: '',
      name: 'PriceTasksMain',
      component: Main,
      meta: {
        requiresAuth: true,
        isCloseForShowroom: true,
        sectionKey: 'price_change_analysis',
      },
    },
    {
      path: 'task-page/:id',
      name: 'PriceTasksCreateTask',
      component: CreateTask,
      meta: {
        requiresAuth: true,
        isCloseForShowroom: true,
      },
    },
  ],
};

export default router;
