<template>
	<svg @click="$emit('click')" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M8.75017 4.375H7.17517C6.19508 4.375 5.70467 4.375 5.33032 4.56574C5.00104 4.73352 4.73352 5.00104 4.56574 5.33032C4.375 5.70467 4.375 6.19508 4.375 7.17517V13.8252C4.375 14.8053 4.375 15.2951 4.56574 15.6694C4.73352 15.9987 5.00104 16.2667 5.33032 16.4344C5.7043 16.625 6.19412 16.625 7.1723 16.625H13.8277C14.8059 16.625 15.295 16.625 15.669 16.4344C15.9983 16.2667 16.2667 15.9984 16.4344 15.6691C16.625 15.2952 16.625 14.8059 16.625 13.8277V12.25M17.5 7.875V3.5M17.5 3.5H13.125M17.5 3.5L11.375 9.625"
			:stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'LinkIcon',
	props: {
		color: {
			type: String,
			default: '#2486E1'
		}
	}
}
</script>

<style lang="scss" scoped></style>
