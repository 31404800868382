<template>
  <div class="inf">
    <h1 class="inf__title">
      {{ product.name }}
    </h1>
    <div class="wrapper">
      <p class="inf__articul">
        Артикул: <span class="inf__articul-current">{{ product.nm_id }}</span>
      </p>
      <p class="inf__articul">
        Размер: <span class="inf__articul-current">{{ product.size }}</span>
      </p>
      <p class="inf__articul">
        Скидка:
        <span class="inf__articul-current">{{ product.discount }} %</span>
      </p>
    </div>

    <div class="inf__info">
      <h2 class="inf__info-title">Информация о товаре</h2>
      <p class="inf__info-description">
        {{ product.description }}
      </p>
    </div>
    <button @click="onClick" class="button inf__button body-button">
      Заказать на Wildberries
    </button>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "ELInfo",
  props: {
    product: Object,
  },
  methods: {
    ...mapActions("externalAds", ["sendClick"]),
    async onClick() {
      const data = {
        id: this.$route.params.code,
        click: true,
      };
      const response = await this.sendClick(data);
      const isAndroid = /Android/.test(navigator.userAgent);
      if (isAndroid) {
        window.location.href = response.android_url;
      } else {
        window.location.href = response.target_url;
      }
      console.log(response, isAndroid);
    },
  },
};
</script>
<style lang="scss" scoped>
.inf {
  display: flex;
  flex-direction: column;
  gap: 20px;
  &__title {
    font-size: 30px;
  }
  &__articul {
    &-current {
    }
  }
  &__info {
    &-title {
      font-size: 18px;
    }
    &-description {
      font-size: 14px;
    }
  }
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
  }
}
</style>
