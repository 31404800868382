import axios from 'axios';
import API_ENV from '@/config/api.config';

const {
  apiLogin,
  apiCheckCode,
  apiRefresh,
  apiRegistration,
  apiRestorePhone,
  apiConfirmReg,
  apiSendCodeAgain,
  apiGetCountries,
  apiReferralRegistration,
} = API_ENV;

// console.log(apiLogin);
export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getCountries() {
      try {
        const result = await axios.get(apiGetCountries);
        return result;
      } catch (error) {
        console.error(error);
      }
    },
    RESTORE_ACCOUNT() {
      return new Promise((resolve, reject) => {
        axios
          .post(apiCheckCode, data)
          .then(response => resolve(response.data))
          .catch(error => reject(error));
      });
    },
    async CHECK_CODE({ commit }, data) {
      try {
        const response = await axios.post(apiCheckCode, data);
        return response.data;
      } catch (error) {
        commit('showAlert', 'Вы ввели неверный код из смс');

        throw error;
      }
    },
    SEND_SMS_AGAIN({ commit }, number) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${apiSendCodeAgain}?phone=${number}`)
          .then(responese => resolve(responese.data))
          .catch(error => reject(error));
      });
    },
    async login({ commit }, user) {
      try {
        const response = await axios.post(apiLogin, user);
        const token = response.data.access;
        const refreshToken = response.data.refresh;
        axios.defaults.headers.common['Authorization'] = `JWT ${token}`;
        commit('auth_success', { token: token, refresh: refreshToken });
        return response;
      } catch (error) {
        commit('showAlert', 'Вы ввели неверный номер телефона или пароль');
        commit('auth_error');
        localStorage.removeItem('token');
        reject(err);
      }
    },
    refreshToken: ({ commit }, data) => {
      return new Promise((resolve, reject) => {
        axios
          .post(apiRefresh, data)
          .then(response => {
            const token = response.data.access;
            const refreshToken = response.data.refresh;
            axios.defaults.headers.common['Authorization'] = `JWT ${token}`;
            commit('auth_success', { token: token, refresh: refreshToken });
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    async register({ commit }, user) {
      try {
        const responese = await axios.post(apiRegistration, user);
        return responese.data;
      } catch (error) {
        // error почему-то не отображается
        if (error.response) {
          throw error.response.data;
        }
      }
    },
    async confirmReg({ commit }, data) {
      console.log('confirmReg1');
      try {
        const response = await axios.post(`${apiConfirmReg}${data.code}/`, {
          phone: data.phone,
          referrer: data?.referrer,
        });
        console.log('confirmReg2');
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    RESTORE_ACCOUNT({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({ url: apiRestorePhone, data: data, method: 'POST' })
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    async sendReferallLinkOnRegistration({ commit }, code) {
      try {
        console.log('code', code);
        const result = await axios.get(`${apiReferralRegistration}?referrer=${code}`);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
