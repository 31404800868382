<template>
  <div class="trace">
    <div
      v-for="(item, index) of wayTracer"
      :class="{
        disabled: !item.isActive && index != 0,
      }"
      :key="index"
      class="trace__item"
    >
      <div @click="handleItemClick(item.name)" class="trace__item-el">
        <CircularProgressBar :precent="item.procent" />
        <p class="trace__description">{{ item.title }}</p>
      </div>
      <div
        v-if="index < wayTracer.length - 1"
        :class="{ done: item.procent === 100 }"
        class="trace__line"
      ></div>
    </div>
    <button @click="$router.push('/stages')" class="button trace__button">Заполнить данные</button>
  </div>
</template>
<script>
import CircularProgressBar from '@/components/CircularProgressBar.vue';
export default {
  name: 'WTracer',
  components: { CircularProgressBar },
  props: {
    wayTracer: {
      type: Array,
    },
  },
  methods: {
    handleItemClick(anchor) {
      const hash = `#${anchor}`;
      this.$router.push(`/stages${hash}`);

      const targetElement = document.querySelector(hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.trace {
  display: flex;
  width: 50%;
  &__item {
    display: flex;
    width: 100%;
    &-el {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
    }
    &.disabled {
      opacity: 0.3;
    }
  }
  &__description {
    width: min-content;
    text-align: center;
    color: #3a416f;
    font-size: 10px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.15px;
  }
  &__line {
    height: 2px;
    border-radius: 50%;
    background: #cbd6eb;
    transform: translate(0, 500%);
    width: inherit;
  }
  &__button {
    position: relative;
    display: inline-block;
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 166.667% */
    letter-spacing: -0.24px;
    padding: 6px 20px;
    overflow: hidden;
    border-radius: 6px;
    background: var(--2, linear-gradient(90deg, #6c29bf 0%, #ff634e 100%));
    align-self: center;
    // margin-left: 30px;
    min-width: 160px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1.5em;
      height: 100%;
      background-color: rgba(#fff, 0.6);
      box-shadow: 0 0 10px #fff;
      transform: translateX(-4em) skewX(-45deg);
      animation: move-light 5s linear infinite;
    }
  }
}

@media screen and (max-width: 1300px) {
  .trace {
    display: none;
    &__button {
      transform: translate(0);
    }
  }
}
</style>
