const Module = () => import("./Module.vue");
const GoodsTable = () => import("./views/GoodsTable.vue");
const TableParams = () => import("./views/TableParams.vue");
const Prestes = () => import("./views/Presets.vue");
const PresetPage = () => import("./views/PresetPage.vue");

const routes = {
  path: "/wb-products",
  name: "wbCards",
  breadName: "Карточки WB",
  meta: {
    isCloseForShowroom: true,
    isCloseForMarkert: true,
    requiresAuth: true,
  },
  component: Module,
  redirect: (to) => ({ path: "/wb-products/table" }),
  children: [
    {
      path: "table",
      name: "wbCardsGoodsTable",
      component: GoodsTable,
      meta: {
        isCloseForShowroom: true,
        isCloseForMarkert: true,
        requiresAuth: true,
      },
    },
    {
      path: "table-params",
      name: "wbCardsGoodsAnalyseParams",
      component: TableParams,
      meta: {
        isCloseForShowroom: true,
        isCloseForMarkert: true,
      },
    },
    {
      path: "presets",
      name: "wbCardsPrestes",
      component: Prestes,
      meta: {
        isCloseForMarkert: true,
        isCloseForShowroom: true,
      },
    },
    {
      path: "preset-page",
      name: "wbCardsPrestesPage",
      component: PresetPage,
      children: [
        {
          path: ":id",
        },
      ],
    },
  ],
};

export default routes;
