import axios from 'axios';
import API_ENV from '@/config/api.config';
import downloadFile from '@/utils/downloadFile';

let {
  apiStockList,
  apiStocksAndRegions,
  apiPercentageByOkrug,
  apiStockPlanning,
  apiStockTotalCost,
  apiExcelImportWarehouseProducts,
} = API_ENV;
const apiStockAlerts = apiStockPlanning + '/main/availability/';
const apiStockOnTheWay = apiStockPlanning + '/main/on-the-way/';
const apiStockQuantity = apiStockPlanning + '/main/quantity/';

export default {
  state: {
    stockListInfo: null,
    currentLimit: null,
    isLoading: false,
    stockInfo: null,
    totalStocksCost: null,
  },
  getters: {
    getStockList: ({ stockListInfo }) => stockListInfo?.items,
    getStockListTotalPages: ({ stockListInfo, currentLimit }) =>
      Math.ceil(stockListInfo?.count / currentLimit),
    getStockListIsLoading: ({ isLoading }) => isLoading,
    getStockInfo: ({ stockInfo }) => stockInfo,
    getTotalStocksCost: ({ totalStocksCost }) => totalStocksCost,
  },
  mutations: {
    changeStockListInfo(state, data) {
      state.stockListInfo = data;
    },
    changeStockListIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    changeStockListCurrentLimit(state, limit) {
      state.currentLimit = limit;
    },
    deleteStockById(state, id) {
      state.stockListInfo.items = state.stockListInfo.items.filter(item => item.id != id);
    },
    changeStockInfo(state, info) {
      state.stockInfo = { ...state.stockInfo, ...info };
    },
    setStockListExmpleInfo(state) {
      if (!state.stockListInfo.count)
        state.stockListInfo = {
          isExample: true,
          links: {
            next: null,
            previous: null,
          },
          count: 1,
          items: [
            {
              id: 49,
              title: 'Пользовательский склад',
              wh_individual_num: 1,
              totalCost: 1500,
              is_active: true,
              purchase_plan_dangerous_stock_days: 2,
              purchase_plan_small_stock_days: 10,
              delivery_plan_dangerous_stock_days: 2,
              delivery_plan_small_stock_days: 12,
              product_count_in_warehouse: 10,
            },
          ],
          currentPage: 1,
          lastPage: 1,
        };
    },
    setTotalStocksCost(state, cost) {
      state.totalStocksCost = cost;
    },
    removeStockListExmpleInfo(state) {
      if (state.stockListInfo?.isExample) state.stockListInfo = { count: 0, items: [] };
    },
  },
  actions: {
    async getStocksTotalCost({ commit }) {
      try {
        const result = await axios.get(apiStockTotalCost);

        const totalCost = result.data.total_sum || 0;

        commit('setTotalStocksCost', totalCost);

        return totalCost;
      } catch (error) {
        console.error(error);
        commit('showAlert');
        throw error;
      }
    },
    async getStockListRequest({ commit }, { limit, page } = { limit: 1000, page: 1 }) {
      commit('changeStockListIsLoading', true);
      try {
        const result = await axios.get(apiStockList, { params: { limit, page } });
        const stocks = result.data;
        const totalCostNum = stocks.items.reduce((prevVal, stock) => prevVal + stock.total_cost, 0);

        commit('setTotalStocksCost', totalCostNum);
        commit('changeStockListInfo', stocks);
        commit('changeStockListCurrentLimit', limit);

        return stocks;
      } catch (error) {
        commit('showAlert');
        throw error;
      } finally {
        commit('changeStockListIsLoading', false);
      }
    },
    getStockCostById({ commit }, id) {
      return new Promise((res, rej) =>
        axios.get(`${apiStockList}${id}/total_cost`).then(res).catch(rej),
      );
    },
    async editStockInfoById({ commit }, { id, stockInfo }) {
      try {
        const result = await axios.patch(`${apiStockList}${id}`, stockInfo);

        commit('changeStockInfo', stockInfo);

        return result.data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async getWbRegionsStocks({ commit }) {
      try {
        const stocksData = (await axios.get(apiStocksAndRegions)).data;

        const stocks = stocksData.map(stock => ({
          stock_name: stock.OUR_warehouse_name,
          coefficient: null,
          dangerous_stock_days: null,
          small_stock_days: null,
          okrug: stock.okrug,
          okrug_coefficient: 0,
        }));

        return stocks;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async getStockInfoById({ commit }, id) {
      try {
        const stockInfoData = (await axios.get(`${apiStockList}${id}`)).data;
        const stocksData = (await axios.get(apiStocksAndRegions)).data;
        const stockInfoIndicators = stockInfoData.stockindicationfordeliveryplanningonwb;

        if (!stockInfoIndicators)
          stockInfoData.stockindicationfordeliveryplanningonwb = stocksData.map(stock => ({
            stock_name: stock.OUR_warehouse_name,
            coefficient: null,
            dangerous_stock_days: null,
            small_stock_days: null,
            okrug: stock.okrug,
            okrug_coefficient: stock.share,
          }));
        else
          for (const stock of stocksData) {
            const stockIndex = stockInfoIndicators.findIndex(
              stockIndicator => stockIndicator.stock_name == stock.OUR_warehouse_name,
            );

            if (stockIndex == -1) {
              const stockInfo = {
                stock_name: stock.OUR_warehouse_name,
                coefficient: null,
                dangerous_stock_days: null,
                small_stock_days: null,
                okrug: stock.okrug,
                okrug_coefficient: stock.share,
              };

              stockInfoIndicators.push(stockInfo);

              continue;
            }

            stockInfoIndicators[stockIndex].okrug =
              stockInfoIndicators[stockIndex].okrug || stock.okrug;
            stockInfoIndicators[stockIndex].okrug_coefficient =
              stockInfoIndicators[stockIndex].okrug_coefficient || stock.share;
          }

        return stockInfoData;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async createStock({ commit }, stockInfo) {
      try {
        const result = await axios.post(apiStockList, stockInfo);

        return result.data;
      } catch (error) {
        if (error.response.data.title?.[0])
          commit('showAlert', 'Склад с таким наименованием уже существует');
        else commit('showAlert');

        throw error;
      }
    },
    async deleteStock({ commit }, id) {
      try {
        const result = await axios.delete(`${apiStockList}${id}`);

        commit('deleteStockById', id);

        return result.data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async getStockInfoRequest({ commit }, { id, limit = 10000, page = 1 }) {
      try {
        const result = await axios.get(`${apiStockList}${id}`, { params: { limit, page } });

        result.data.id = id;

        commit('changeStockInfo', result.data);

        return result.data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async getPercentageByOkrug({ commit }, cabinet_id) {
      try {
        const result = await axios.get(apiPercentageByOkrug, { params: { cabinet_id } });

        return result.data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async getStockAlerts({ commit }, oos) {
      try {
        const result = await axios.get(apiStockAlerts, { params: { out_of_stock_required: oos } });

        return result.data || [];
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async getStockOnTheWay({ commit }) {
      try {
        const result = await axios.get(apiStockOnTheWay);

        return result.data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async getStockQuantity({ commit }) {
      try {
        const result = await axios.get(apiStockQuantity);

        return result.data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async downloadStockExcelExampleFile({ commit }, id) {
      try {
        const result = await axios.get(apiExcelImportWarehouseProducts + id, {
          responseType: 'blob',
        });

        downloadFile(result.data, 'Текущие остатки склада.xlsx');

        return result.data;
      } catch (error) {
        throw error;
      }
    },
    async uploadStockExcelFile({ commit }, { id, file }) {
      const formData = new FormData();

      formData.set('file', file);
      try {
        const result = await axios.post(`${apiExcelImportWarehouseProducts}${id}/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        return result.data;
      } catch (error) {
        throw error;
      }
    },
  },
};
