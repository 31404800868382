import axios from 'axios';
import API_ENV from '@/config/api.config';
import downloadFile from '@/utils/downloadFile';

let { apiStockDelivery, apiDownloadSupply } = API_ENV;

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async downloadSuppliesFile({ commit }, req) {
      try {
        const result = await axios.post(`${apiDownloadSupply}?cabinet=${req.cabinet}`, req.list);

        const fileName = 'Поставка ' + new Date().toLocaleDateString('ru') + '.xlsx';
        const link = document.createElement('a');
        link.href = result.data.file_content;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();

        return result.data;
      } catch (error) {
        console.log('error: ', error);
        console.error('Error downloadSuppliesFile: ', new Error(error));
        throw error;
      }
    },
    async setSettingsForSupplyReport({ commit }, req) {
      try {
        const result = await axios.post(`${apiStockDelivery}/set_settings_for_report/`, req);

        return result.data;
      } catch (error) {
        console.error('Error setSettingsForSupplyReport: ', new Error(error));
      }
    },
    async getStockSupplyPlanningReport({ commit }, id) {
      try {
        const result = await axios.get(`${apiStockDelivery}/report/${id}`);

        return result.data;
      } catch (error) {
        console.error(new Error(error));
      }
    },
  },
};
