import axios from 'axios'
import API_ENV from '@/config/api.config'

let {
  apiUnitCalculator,
  apiUnitCategories,
  apiUnitCategoriesMerch,
} = API_ENV

function convertCategories(categories) {
  const result = []

  for (const categoryInfo of categories) {
    if (categoryInfo.category == '-') {
      result.push(categoryInfo)
      continue
    }

    const category = result.find(el => el.category == categoryInfo.category)

    if (category) category.categories.push({ name: categoryInfo.name })
    else result.push({ category: categoryInfo.category, name: categoryInfo.category, categories: [{ name: categoryInfo.name }] })
  }

  return result
}

export default {
  state: {
    result: [],
    productCategories: [],
  },
  getters: {
    resultList: ({ result }) => result,
    productCategoriesList: ({ productCategories }) => productCategories,
  },
  mutations: {
    setProductCategories(state, data) {
      state.productCategories = data
    },
    setResult(state, data) {
      state.result = data
    },
  },
  actions: {
    async getProductCategories({ commit }) {
      try {
        const result = await axios.get(apiUnitCategories)
        commit('setProductCategories', result.data)
      } catch (error) {
        if (error.response.data.detail === 'proxy: unauthorized')
          commit('showAlert', 'Необходимый токен отсутствует или устарел')
        console.error('Error getCategories: ', new Error(error))
      }
    },
    async getProductCategoriesMerch({ commit }) {
      try {
        const result = await axios.get(apiUnitCategoriesMerch)

        const categories = convertCategories(result.data)

        commit('setProductCategories', categories)
      } catch (error) {
        if (error.response.data.detail === 'proxy: unauthorized')
          commit('showAlert', 'Необходимый токен отсутствует или устарел')
        console.error('Error getCategories: ', new Error(error))
      }
    },
    async getResult({ commit }, request) {
      try {
        const result = await axios.post(apiUnitCalculator, request)
        commit('setResult', result.data)
        return result.data
      } catch (error) {
        console.error('Error getResult: ', new Error(error))
      }
    },
  },
}
