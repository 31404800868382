<template>
  <CModal class="modal" :modalTitle="title" v-model="isModalVisble" @closeModal="closeModal">
    <div
      v-if="!cabinet.api_loading_status || cabinet.open_api_standart === null"
      key="api"
      class="modal__cabinet"
    >
      <p>
        Для полноценной работы на платформе введите
        <a
          href="https://seller.wildberries.ru/supplier-settings/access-to-api"
          class="link"
          target="_blank"
          >токен</a
        >
        кабинета Wildberries по
        <a href="https://wiki.mamod.ai/ru/office/api" class="link" target="_blank">инструкции</a>.
      </p>
      <CInformaition
        v-if="shouldShowMessage"
        text="До 16:00 мск на Wildberries ведутся технические работы в связи с чем скорость создания Вашего кабинета уменьшится. Благодарим за понимание!"
      />
      <form class="modal__form" @submit.prevent="addToken">
        <input
          v-model="input"
          @click="error = null"
          type="text"
          placeholder="Введите API-токен"
          class="input"
          :class="{ err: error }"
        />
        <button class="button body-button">Подключить</button>
      </form>
    </div>
    <div v-else class="modal__cabinet">
      <CDataLoader :text="text" />
      <button class="modal__button button body-button" @click="$router.push('/study')">
        Посмотреть возможности Mamod
      </button>
    </div>
  </CModal>
</template>
<script>
import CModal from '@/components/UI/CModal.vue';
import { mapActions, mapGetters } from 'vuex';
import CDataLoader from '@/components/UI/CDataLoader.vue';
import CInformaition from '@/components/UI/CInformaition.vue';
import moment from 'moment';
export default {
  name: 'WBCabinetsFirst',
  components: {
    CModal,
    CDataLoader,
    CInformaition,
  },
  props: { cabinet: Object },
  data() {
    return {
      title: 'Добро пожаловать в Mamod!',
      text: 'Мы загружаем информацию по вашим товарам с WB. Скорость загрузки зависит от количества данных, но как правило занимает до 5 минут.',
      input: null,
      error: null,
      isModalVisble: true,
      isCompleate: false,
      shouldShowMessage: false,
    };
  },
  methods: {
    ...mapActions(['patchCabinet', 'removeWbTokens']),
    closeModal() {
      if (!this.cabinet.api_loading_status || this.cabinet.open_api_standart === null) {
        this.removeWbTokens(this.cabinet.id);
      }
    },
    checkTimeToShowMessage() {
      const now = moment().utcOffset('+03:00');
      const dayOfWeek = now.day();
      const hour = now.hour();

      if (
        (dayOfWeek === 0 && hour >= 23) || // Воскресенье с 23:00
        (dayOfWeek === 1 && hour < 16) // Понедельник до 16:00
      ) {
        this.shouldShowMessage = true;
      } else {
        this.shouldShowMessage = false;
      }
    },
    checkToken() {
      if (!this.input) {
        const error = 'Введите токен';
        this.error = error;
        throw new Error(error);
      }
    },
    async addToken() {
      try {
        this.checkToken();
        await this.patchCabinet({
          id: this.cabinet.id,
          open_api_standart: this.input,
          is_cabinet_done: true,
        });
        this.title = 'Поздравляем! Вы успешно подключили кабинет!';
        this.isCompleate = true;
      } catch (error) {
        console.log('error', error.message);
        let errorMessage = error.message;
        if (error?.response?.data) {
          errorMessage = Object.values(Object.values(error?.response?.data)[0]);
        }
        this.error = errorMessage;
        this.$store.commit('showAlert', errorMessage);
      }
    },
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  mounted() {
    this.checkTimeToShowMessage();
  },
};
</script>
<style lang="scss" scoped>
.err {
  border: 1px solid #d94646;
  animation: shake 0.5s;
}

.modal {
  &__cabinet {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &__description {
    text-align: center;
  }
  &__form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-end;
  }
  &__button {
    align-self: center;
  }
}
</style>
<style lang="scss">
.modal {
  .dataLoaderWrapper {
    min-height: 300px;
  }
}
</style>
