<template>
  <transition name="modalFade">
    <div class="tooltip__modal">
      <span class="tooltip__text" v-if="htmlText" v-html="htmlText"></span>
      <p v-else class="tooltip__text">{{ text }}</p>
    </div>
  </transition>
</template>
<script>
export default {
  name: "CModalFade",
  props: {
    text: String,
    htmlText: String,
  },
};
</script>
<style lang="scss" scoped>
.tooltip {
  svg {
    height: 8px;
  }

  path {
    fill: #3a416f80;
  }

  &__modal {
    position: absolute;
    bottom: 20px;
    right: -130px;
    padding: 5px 10px;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 10px 70px rgba(206, 208, 213, 0.7);
    z-index: 101;
    display: flex;
    justify-content: center;
    min-width: 320px;
    align-items: center;

    @media (max-width: 492px) {
      right: 0;
    }
  }
  &__text {
    font-size: 12px;
    line-height: 21px;
    text-align: center;
    color: rgba(58, 65, 111, 0.8);
    font-weight: 500;
  }
}
</style>
