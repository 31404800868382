const chartsData = {
  multiple: {
    labels: [],
    datasets: [
      { label: 'Заказы', data: [] },
      { label: 'Оборотка', data: [] },
      { label: 'Продажи', data: [] },
      { label: 'Прибыль', data: [] },
      { label: 'Возвраты', data: [] },
    ],
  },
  expense: {
    labels: ['Комиссия ВБ', 'Логистика', 'Налоги', 'Ежемесячные', 'По отчётам WB', 'На SKU'],
    datasets: [
      {
        data: [27374.08, 269457.55, 203946.04, 20180.37, 27160.27, 117051],
        backgroundColor: ['#49AAE0', '#97E3D5', '#E8A838', '#E8C1A0', '#F1E15B', '#F47560'],
        hoverOffset: 4,
      },
    ],
  },
  ABC: {
    labels: ['Группа A', 'Группа B', 'Группа C'],
    dataset: {
      data: [10, 12, 49],
      backgroundColor: ['#49AAE0', '#97E3D5', '#F47560'],
      textColor: ['#fff', '#214D45', '#fff'],
    },
  },
  sales: {
    labels: [
      '21.01.2024',
      '22.01.2024',
      '23.01.2024',
      '24.01.2024',
      '25.01.2024',
      '26.01.2024',
      '27.01.2024',
      '28.01.2024',
    ],
    datasets: [
      { label: 'Заказы', data: [250, 223, 299, 260, 327, 228, 278, 244] },
      { label: 'Возвраты', data: [0, 0, 0, 0, 0, 0, 0, 0] },
      { label: 'Продажи', data: [918, 765, 934, 694, 621, 228, 152, 56] },
    ],
  },
  finance: {
    labels: [
      '21.01.2024',
      '22.01.2024',
      '23.01.2024',
      '24.01.2024',
      '25.01.2024',
      '26.01.2024',
      '27.01.2024',
      '28.01.2024',
    ],
    datasets: [
      { label: 'Оборотка', data: [499111, 470725, 502162, 312499, 301932, 107051, 60173, 3573] },
      { label: 'Прибыль', data: [396781, 390334, 412027, 245928, 236084, 87496, 47639, 1415] },
    ],
  },
};

const tableData = {
  rowData: [
    {
      average_spp_percent: 0,
      cabinet_id: 1461,
      cabinet_name: 'Мой кабинет',
      nm_id: 141937472,
      abc: 'A',
      size: '0',
      photo: 'https://basket-10.wbbasket.ru/vol1419/part141937/141937472/images/big/1.jpg',
      vendor_code: 'Sterilizer for bottles',
      barcode: '2037344039135',
      income_percent: 21.682226608287806,
      income_percent2: 21.682226608287806,
      orders_count: 0,
      sale_amount: 681,
      sale_price: 1051406.34,
      return_amount: 0,
      for_pay: 1014929.8000000002,
      vw_nds: -7988.32,
      kvw_sales_commission: -14447.48,
      delivery_amount: 841,
      delivery_price: 58912.34,
      return_price: 0,
      retail_amount: 1051406.34,
      month_expense: 9024.884320001294,
      month_expense2: 0,
      report_expense: 11673.373361897407,
      purchase_price: 6810,
      per_one_expense: 17025,
      nalog: 102930.86323181014,
      nalog_system: null,
      income: 867465.6790862912,
      wb_code: '-',
      full_product: 121010052,
      size_product: 240205313,
      supplier_vendor_code: 'Sterilizer for bottles',
      margin: 82.50527375422628,
      rent: 82.50527375422628,
      price_add: 12738.115698770796,
      orders_rate: 0,
      retail_amount_per_unit: 1543.9153303964758,
      kvw_sales_commission_per_unit: -21.215095447870777,
      vw_nds_per_unit: -11.730279001468428,
      delivery_price_per_unit: 86.50857562408223,
      month_expense_per_unit: 13.252399882527598,
      report_expense_per_unit: 17.14151741835155,
      purchase_price_per_unit: 10,
      income_per_unit: 1273.8115698770796,
      per_one_expense_per_unit: 25,
      all_expenses_per_unit: 55.393917300879146,
      all_expenses: 37723.2576818987,
      nalog_per_unit: 151.14664204377408,
      wbComision: -22436,
      wbComisionPerUnit: -33,
    },
    {
      average_spp_percent: 0,
      cabinet_id: 1461,
      cabinet_name: 'Мой кабинет',
      nm_id: 100959669,
      abc: 'A',
      size: '0',
      photo: 'https://basket-06.wbbasket.ru/vol1009/part100959/100959669/images/big/1.jpg',
      vendor_code: 'soup cooker',
      barcode: '2036816806329',
      income_percent: 13.579059140573635,
      income_percent2: 35.26128574886144,
      orders_count: 0,
      sale_amount: 525,
      sale_price: 620887.71,
      return_amount: 0,
      for_pay: 639795.0499999999,
      vw_nds: -14203.25,
      kvw_sales_commission: -55041.689999999995,
      delivery_amount: 599,
      delivery_price: 50337.6,
      return_price: 0,
      retail_amount: 620887.71,
      month_expense: 5329.471152381024,
      month_expense2: 0,
      report_expense: 6860.81749965522,
      purchase_price: 5250,
      per_one_expense: 13125,
      nalog: 65956.78613479638,
      nalog_system: null,
      income: 543272.9752131674,
      wb_code: '-',
      full_product: 78573423,
      size_product: 159038636,
      supplier_vendor_code: 'soup cooker',
      margin: 87.49939263786803,
      rent: 87.49939263786803,
      price_add: 10348.056670726997,
      orders_rate: 0,
      retail_amount_per_unit: 1182.643257142857,
      kvw_sales_commission_per_unit: -104.84131428571428,
      vw_nds_per_unit: -27.053809523809523,
      delivery_price_per_unit: 95.88114285714285,
      month_expense_per_unit: 10.151373623582902,
      report_expense_per_unit: 13.068223808867085,
      purchase_price_per_unit: 10,
      income_per_unit: 1034.8056670726999,
      per_one_expense_per_unit: 25,
      all_expenses_per_unit: 48.21959743244999,
      all_expenses: 25315.288652036244,
      nalog_per_unit: 125.63197359008834,
      wbComision: -69245,
      wbComisionPerUnit: -132,
    },
    {
      average_spp_percent: 4,
      cabinet_id: 1461,
      cabinet_name: 'Мой кабинет',
      nm_id: 138145332,
      abc: 'B',
      size: '0',
      photo: 'https://basket-10.wbbasket.ru/vol1381/part138145/138145332/images/big/1.jpg',
      vendor_code: 'grill-drate-R001',
      barcode: '2037240941211',
      income_percent: 8.318894438945458,
      income_percent2: 43.580180187806896,
      orders_count: 0,
      sale_amount: 1301,
      sale_price: 550248.09,
      return_amount: 4,
      for_pay: 435113.92000000004,
      vw_nds: 1948.49,
      kvw_sales_commission: 29994.629999999997,
      delivery_amount: 1512,
      delivery_price: 81447.05,
      return_price: -1744,
      retail_amount: 548504.09,
      month_expense: 4723.126702423708,
      month_expense2: 0,
      report_expense: 6142.215577477476,
      purchase_price: 12970,
      per_one_expense: 32425,
      nalog: 46030.037772009884,
      nalog_system: null,
      income: 332823.53994808893,
      wb_code: '-',
      full_product: 70487222,
      size_product: 235003087,
      supplier_vendor_code: 'grill-drate-R001',
      margin: 60.67840623541911,
      rent: 60.67840623541911,
      price_add: 2566.1028523368454,
      orders_rate: 0,
      retail_amount_per_unit: 422.90215111796454,
      kvw_sales_commission_per_unit: 23.126160370084808,
      vw_nds_per_unit: 1.5023053199691596,
      delivery_price_per_unit: 62.796491904394756,
      month_expense_per_unit: 3.641578028083044,
      report_expense_per_unit: 4.735709774462202,
      purchase_price_per_unit: 10,
      income_per_unit: 256.6102852336846,
      per_one_expense_per_unit: 25,
      all_expenses_per_unit: 33.37728780254525,
      all_expenses: 43290.34227990118,
      nalog_per_unit: 35.489620487285954,
      wbComision: 31943,
      wbComisionPerUnit: 25,
    },
    {
      average_spp_percent: 0,
      cabinet_id: 1461,
      cabinet_name: 'Мой кабинет',
      nm_id: 141936084,
      abc: 'B',
      size: '0',
      photo: 'https://basket-10.wbbasket.ru/vol1419/part141936/141936084/images/big/1.jpg',
      vendor_code: 'neck massager',
      barcode: '2037343888031',
      income_percent: 8.163147781647643,
      income_percent2: 51.74332796945454,
      orders_count: 0,
      sale_amount: 3993,
      sale_price: 835337.23,
      return_amount: 0,
      for_pay: 538363.3999999999,
      vw_nds: 13654.46,
      kvw_sales_commission: 109626.87,
      delivery_amount: 4426,
      delivery_price: 173692.5,
      return_price: 0,
      retail_amount: 835337.23,
      month_expense: 7159.625969922843,
      month_expense2: 39.63237506127652,
      report_expense: 9268.940689927154,
      purchase_price: 39930,
      per_one_expense: 99825,
      nalog: 55587.42833401499,
      nalog_system: null,
      income: 326592.4050061349,
      wb_code: '-',
      full_product: 121009379,
      size_product: 240203660,
      supplier_vendor_code: 'neck massager',
      margin: 39.10181728416394,
      rent: 39.09707280808435,
      price_add: 817.9123591438392,
      orders_rate: 0,
      retail_amount_per_unit: 209.20040821437516,
      kvw_sales_commission_per_unit: 27.454763335837715,
      vw_nds_per_unit: 3.419599298772852,
      delivery_price_per_unit: 43.4992486851991,
      month_expense_per_unit: 1.7930443200407822,
      report_expense_per_unit: 2.321297443007051,
      purchase_price_per_unit: 10,
      income_per_unit: 81.79123591438389,
      per_one_expense_per_unit: 25,
      all_expenses_per_unit: 29.114341763047833,
      all_expenses: 116253.56665985,
      nalog_per_unit: 13.921219217133732,
      wbComision: 123281,
      wbComisionPerUnit: 31,
    },
    {
      average_spp_percent: 0,
      cabinet_id: 1461,
      cabinet_name: 'Мой кабинет',
      nm_id: 97393678,
      abc: 'C',
      size: '0',
      photo: 'https://basket-05.wbbasket.ru/vol973/part97393/97393678/images/big/1.jpg',
      vendor_code: 'Electric chopper',
      barcode: '2036771522906',
      income_percent: 5.531603529317213,
      income_percent2: 57.27493149877175,
      orders_count: 0,
      sale_amount: 463,
      sale_price: 259702.45,
      return_amount: 0,
      for_pay: 268979.77,
      vw_nds: -5675.16,
      kvw_sales_commission: -20315.16,
      delivery_amount: 494,
      delivery_price: 16713,
      return_price: 0,
      retail_amount: 259702.45,
      month_expense: 2198.5200652345297,
      month_expense2: 0,
      report_expense: 2820.156151569552,
      purchase_price: 4630,
      per_one_expense: 11575,
      nalog: 26446.894378319594,
      nalog_system: null,
      income: 221309.19940487633,
      wb_code: '-',
      full_product: 75917372,
      size_product: 154408041,
      supplier_vendor_code: 'Electric chopper',
      margin: 85.21644651595557,
      rent: 85.21644651595557,
      price_add: 4779.896315440093,
      orders_rate: 0,
      retail_amount_per_unit: 560.9124190064795,
      kvw_sales_commission_per_unit: -43.877235421166304,
      vw_nds_per_unit: -12.257365010799136,
      delivery_price_per_unit: 36.09719222462203,
      month_expense_per_unit: 4.748423467029222,
      report_expense_per_unit: 6.091050003389961,
      purchase_price_per_unit: 10,
      income_per_unit: 477.98963154400934,
      per_one_expense_per_unit: 25,
      all_expenses_per_unit: 35.83947347041918,
      all_expenses: 16593.67621680408,
      nalog_per_unit: 57.12072219939437,
      wbComision: -25990,
      wbComisionPerUnit: -56,
    },
    {
      average_spp_percent: 0,
      cabinet_id: 1461,
      cabinet_name: 'Мой кабинет',
      nm_id: 51458358,
      abc: 'C',
      size: '0',
      photo: 'https://basket-04.wbbasket.ru/vol514/part51458/51458358/images/big/1.jpg',
      vendor_code: 'калимба-17-прозрачнаяtransparent',
      barcode: '2015293483001',
      income_percent: 5.149759624547877,
      income_percent2: 62.424691123319626,
      orders_count: 0,
      sale_amount: 213,
      sale_price: 246348,
      return_amount: 0,
      for_pay: 242273.47,
      vw_nds: -1947.27,
      kvw_sales_commission: -3544.34,
      delivery_amount: 228,
      delivery_price: 9566.14,
      return_price: 0,
      retail_amount: 246348,
      month_expense: 2114.560392001885,
      month_expense2: 0,
      report_expense: 2716.1575344191915,
      purchase_price: 2130,
      per_one_expense: 5325,
      nalog: 23955.419207357896,
      nalog_system: null,
      income: 206032.33286622106,
      wb_code: '-',
      full_product: 38273645,
      size_product: 97211125,
      supplier_vendor_code: 'калимба-17-прозрачнаяtransparent',
      margin: 83.63466838221582,
      rent: 83.63466838221582,
      price_add: 9672.879477287373,
      orders_rate: 0,
      retail_amount_per_unit: 1156.5633802816901,
      kvw_sales_commission_per_unit: -16.640093896713616,
      vw_nds_per_unit: -9.142112676056337,
      delivery_price_per_unit: 44.91145539906103,
      month_expense_per_unit: 9.927513577473638,
      report_expense_per_unit: 12.751913307132355,
      purchase_price_per_unit: 10,
      income_per_unit: 967.2879477287373,
      per_one_expense_per_unit: 25,
      all_expenses_per_unit: 47.67942688460599,
      all_expenses: 10155.717926421075,
      nalog_per_unit: 112.46675684205584,
      wbComision: -5492,
      wbComisionPerUnit: -26,
    },
  ],
  totalRow: {
    cabinet_id: '',
    cabinet_name: '',
    nm_id: 'ИТОГО',
    vendor_code: '',
    size: '',
    barcode: '',
    wb_code: '',
    sale_amount: 11694,
    sale_amount_with_selfbuys: 0,
    sale_price: 5897140.010000001,
    retail_amount: 5882672.010000001,
    retail_amount_with_selfbuys: 0,
    for_pay: 5188800.970000001,
    vw_nds: -39209.740000000034,
    kvw_sales_commission: 37693.19000000002,
    delivery_amount: 13533,
    delivery_price: 695387.5899999997,
    return_price: -14468,
    return_amount: 13,
    month_expense: 50446.875805510725,
    month_expense2: 441.7325042254828,
    report_expense: 65475.213461968095,
    purchase_price: 263940,
    nalog: 503977.3206876974,
    nalog_system: null,
    per_one_expense: 304147,
    income: 4000814.5600448227,
    income_percent: 0,
    income_percent2: 0,
    abc: '-',
    orders_count: 0,
    full_product: '',
    size_product: '',
    supplier_vendor_code: '',
    margin: 68.0176675794143,
    rent: 68.01015853414583,
    price_add: 1515.8045616597803,
    orders_rate: 0,
    retail_amount_per_unit: 503.61030819279176,
    kvw_sales_commission_per_unit: 3.226880404074995,
    vw_nds_per_unit: -3.3567108980395544,
    delivery_price_per_unit: 59.53151185686155,
    month_expense_per_unit: 4.318712079917021,
    report_expense_per_unit: 5.6052746735697365,
    purchase_price_per_unit: 22.595668179094254,
    income_per_unit: 342.50616899621804,
    per_one_expense_per_unit: 26.037753616984848,
    all_expenses_per_unit: 35.96174037047161,
    all_expenses: 420069.08926747885,
    nalog_per_unit: 43.145049284110726,
    wbComision: -1517,
    wbComisionPerUnit: 0,
    hidePhoto: true,
  },
  unfilledTax: false,
  unfilledPurchase: false,
};

export default {
  chartsData,
  tableData
};
