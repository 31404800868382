import axios from 'axios';
import API_ENV from '@/config/api.config';
import queryString from 'query-string';

const { apiCollectionRequests, apiCollectionRequestsExport, apiCollectionRequestsClusters } =
  API_ENV;

export default {
  state: {
    results: [],
    keywordsListResults: [],
    isLoading: false,
    resultsCount: {
      collectionRequests: 0,
      keywordsList: 0,
    },
    phrase: null,
    selectedPhrases: [],
    normquery: [],
  },
  getters: {
    selectedCollectionRequestsKeywords: ({ selectedPhrases }) => selectedPhrases,
  },
  mutations: {
    setCollectionRequestsResults(state, results) {
      state.results = results;
    },
    setCollectionRequestsResultsExampleData(state) {
      state.resultsCount.collectionRequests = 10;
      state.results = [
        {
          phrase: 'кроссовки женские',
          frequency_week: 243630,
          frequency_month: 1564157.5,
          frequency_3month: 6475575,
          frequency_average_month: 52138.6,
          count_products: 101757,
        },
        {
          phrase: 'кроссовки мужские',
          frequency_week: 273765,
          frequency_month: 1496770,
          frequency_3month: 4935917.5,
          frequency_average_month: 49892.3,
          count_products: 112451,
        },
        {
          phrase: 'кроссовки',
          frequency_week: 158695,
          frequency_month: 783102.5,
          frequency_3month: 3857257.5,
          frequency_average_month: 26103.4,
          count_products: 155574,
        },
        {
          phrase: 'кроссовки для мальчика',
          frequency_week: 11420,
          frequency_month: 113347.5,
          frequency_3month: 995742.5,
          frequency_average_month: 3778.2,
          count_products: 146578,
        },
        {
          phrase: 'кроссовки для девочки',
          frequency_week: 11230,
          frequency_month: 95465,
          frequency_3month: 950055,
          frequency_average_month: 3182.2,
          count_products: 129770,
        },
        {
          phrase: 'кроссовки nike',
          frequency_week: 54200,
          frequency_month: 301522.5,
          frequency_3month: 1235172.5,
          frequency_average_month: 10050.8,
          count_products: 96982,
        },
        {
          phrase: 'кроссовки мужские зимние',
          frequency_week: 168780,
          frequency_month: 474927.5,
          frequency_3month: 616492.5,
          frequency_average_month: 15830.9,
          count_products: 116939,
        },
        {
          phrase: 'зимние кроссовки мужские',
          frequency_week: 128700,
          frequency_month: 370245,
          frequency_3month: 532090,
          frequency_average_month: 12341.5,
          count_products: 60104,
        },
        {
          phrase: 'кроссовки женские зимние',
          frequency_week: 139555,
          frequency_month: 387705,
          frequency_3month: 473150,
          frequency_average_month: 12923.5,
          count_products: 370451,
        },
        {
          phrase: 'зимние кроссовки',
          frequency_week: 123285,
          frequency_month: 334630,
          frequency_3month: 446392.5,
          frequency_average_month: 11154.3,
          count_products: 120940,
        },
      ];
    },
    setKeywordsListResultsExample(state) {
      state.keywordsListResults = [
        {
          isExample: true,
          phrase: 'боди водолазка',
          trand: [3290, 3290, 3525, 3525, 3525, 3525, 3525, 3525],
          value: 3525,
          increase: 0,
          ads_cost: 2676,
          product_count: 0,
        },
      ];
    },
    removeKeywordsListResultsExample(state) {
      state.keywordsListResults = state.keywordsListResults.filter(el => !el.isExample);
    },
    setKeywordsListResults(state, results) {
      state.keywordsListResults = results;
    },
    removeKeywordsListItemByPhrase(state, phrase) {
      const phraseIndex = state.keywordsListResults.findIndex(item => item.phrase == phrase);

      state.keywordsListResults.splice(phraseIndex, 1);
    },
    setCollectionRequestsResultsCount(state, { collectionRequests, keywordsList }) {
      if (collectionRequests) state.resultsCount.collectionRequests = collectionRequests;
      if (keywordsList) state.resultsCount.keywordsList = keywordsList;
    },
    setCollectionRequestsResultsPhrase(state, phrase) {
      state.phrase = phrase;
    },
    selectAllCollectionRequestsResults(state, { isSelected, isCollectionRequests, phrases }) {
      if (isSelected) state.selectedPhrases.push(...phrases);
      else
        state.selectedPhrases = state.selectedPhrases.filter(phrase => !phrases.includes(phrase));
    },
    clearSelectedPhrases(state) {
      state.selectedPhrases = [];
    },
    selectCollectionRequestsResult(state, { isSelected, name }) {
      if (isSelected) state.selectedPhrases.push(name);
      else state.selectedPhrases = state.selectedPhrases.filter(phrase => phrase != name);
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setCollectionRequestsResultsNormquery(state, normquery) {
      state.normquery = normquery;
    },
  },
  actions: {
    async getDownloadFile({ commit }, data) {
      const { type, phrase } = data;
      try {
        if (type === 'csv') {
          const response = await axios.get(
            `${apiCollectionRequestsExport}?type=${type}&phrase=${phrase}`,
            {
              responseType: 'blob',
            },
          );
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'data.csv');
          document.body.appendChild(link);
          link.click();
          resolve(response.data);
        } else {
          const response = await axios.get(
            `${apiCollectionRequestsExport}?type=${type}&phrase=${phrase}`,
            {
              responseType: 'arraybuffer',
            },
          );
          const data = new Uint8Array(response.data);
          const blob = new Blob([data], { type: 'application/octet-stream' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'file.xlsx');
          document.body.appendChild(link);
          link.click();
          resolve(response.data);
        }
      } catch (error) {}
    },
    getCollectionRequestsResults(
      { commit, state },
      { phrase, page_size = 10, page_num = 1, normquery },
    ) {
      if (!phrase && !normquery) phrase = state.phrase;
      else {
        commit('setCollectionRequestsResultsPhrase', phrase);
        commit('setCollectionRequestsResultsNormquery', []);
      }

      if (!normquery) normquery = state.normquery;
      else {
        commit('setCollectionRequestsResultsNormquery', normquery);
        commit('setCollectionRequestsResultsPhrase', undefined);
      }

      commit('setIsLoading', true);
      return new Promise((resolve, reject) => {
        axios
          .get(
            apiCollectionRequests +
              '?' +
              queryString.stringify({ phrase, page_size, page_num, normquery }),
          )
          .then(response => {
            const data = response.data;

            const results = data.results || [];
            const resultsCount = data.count || 0;

            commit('setCollectionRequestsResults', results);
            commit('setCollectionRequestsResultsCount', {
              collectionRequests: resultsCount,
            });

            resolve(response);
          })
          .catch(error => {
            console.log('error', error);
            commit('showAlert', error.response.data.errors);
            reject(error);
          })
          .finally(() => {
            commit('setIsLoading', false);
          });
      });
    },
    async getCollectionRequestsResultsForCluster({ commit }, normquery) {
      try {
        const result = await axios.get(
          apiCollectionRequests + '?' + queryString.stringify({ normquery }),
        );

        return result.data.results;
      } catch (error) {
        console.log('err', error);
        commit('showAlert', error.response.data.errors);
        throw error;
      }
    },
    async getCollectionRequestsClusters({ commit }, phrase) {
      try {
        const result = await axios.get(apiCollectionRequestsClusters, {
          params: { phrase },
        });

        return result.data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    setKeywordsListDetailInfoData({ dispatch, commit }, id) {
      if (id === -999) {
        return;
      }

      commit('setIsLoading', true);
      dispatch('getKeywordsDetailInfoData', id)
        .then(res => commit('setKeywordsListResults', res))
        .finally(() => commit('setIsLoading', false));
    },
  },
};
