<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
		<g clip-path="url(#clip0_1154_16422)">
			<path
				d="M7.49935 4.33464V6.33463C7.49935 6.64397 7.37668 6.94064 7.15735 7.1593C6.93868 7.37864 6.64202 7.5013 6.33268 7.5013H4.33268C4.02335 7.5013 3.72668 7.37864 3.50802 7.1593C3.28868 6.94064 3.16602 6.64397 3.16602 6.33463V4.33464C3.16602 4.0253 3.28868 3.72864 3.50802 3.50997C3.72668 3.29064 4.02335 3.16797 4.33268 3.16797H6.33268C6.64202 3.16797 6.93868 3.29064 7.15735 3.50997C7.37668 3.72864 7.49935 4.0253 7.49935 4.33464ZM6.49935 4.33464C6.49935 4.29064 6.48202 4.24797 6.45068 4.21664C6.41935 4.1853 6.37668 4.16797 6.33268 4.16797H4.33268C4.28868 4.16797 4.24602 4.1853 4.21468 4.21664C4.18335 4.24797 4.16602 4.29064 4.16602 4.33464V6.33463C4.16602 6.37863 4.18335 6.4213 4.21468 6.45263C4.24602 6.48397 4.28868 6.5013 4.33268 6.5013H6.33268C6.37668 6.5013 6.41935 6.48397 6.45068 6.45263C6.48202 6.4213 6.49935 6.37863 6.49935 6.33463V4.33464Z"
				fill="#3A416F" />
			<path
				d="M7.49935 9.66667V11.6667C7.49935 11.976 7.37668 12.2727 7.15735 12.4913C6.93868 12.7107 6.64202 12.8333 6.33268 12.8333H4.33268C4.02335 12.8333 3.72668 12.7107 3.50802 12.4913C3.28868 12.2727 3.16602 11.976 3.16602 11.6667V9.66667C3.16602 9.35733 3.28868 9.06067 3.50802 8.842C3.72668 8.62267 4.02335 8.5 4.33268 8.5H6.33268C6.64202 8.5 6.93868 8.62267 7.15735 8.842C7.37668 9.06067 7.49935 9.35733 7.49935 9.66667ZM6.49935 9.66667C6.49935 9.62267 6.48202 9.58 6.45068 9.54867C6.41935 9.51733 6.37668 9.5 6.33268 9.5H4.33268C4.28868 9.5 4.24602 9.51733 4.21468 9.54867C4.18335 9.58 4.16602 9.62267 4.16602 9.66667V11.6667C4.16602 11.7107 4.18335 11.7533 4.21468 11.7847C4.24602 11.816 4.28868 11.8333 4.33268 11.8333H6.33268C6.37668 11.8333 6.41935 11.816 6.45068 11.7847C6.48202 11.7533 6.49935 11.7107 6.49935 11.6667V9.66667Z"
				fill="#3A416F" />
			<path
				d="M12.8333 4.33464V6.33463C12.8333 6.64397 12.7107 6.94064 12.4913 7.1593C12.2727 7.37864 11.976 7.5013 11.6667 7.5013H9.66667C9.35733 7.5013 9.06067 7.37864 8.842 7.1593C8.62267 6.94064 8.5 6.64397 8.5 6.33463V4.33464C8.5 4.0253 8.62267 3.72864 8.842 3.50997C9.06067 3.29064 9.35733 3.16797 9.66667 3.16797H11.6667C11.976 3.16797 12.2727 3.29064 12.4913 3.50997C12.7107 3.72864 12.8333 4.0253 12.8333 4.33464V4.33464ZM11.8333 4.33464C11.8333 4.29064 11.816 4.24797 11.7847 4.21664C11.7533 4.1853 11.7107 4.16797 11.6667 4.16797H9.66667C9.62267 4.16797 9.58 4.1853 9.54867 4.21664C9.51733 4.24797 9.5 4.29064 9.5 4.33464V6.33463C9.5 6.37863 9.51733 6.4213 9.54867 6.45263C9.58 6.48397 9.62267 6.5013 9.66667 6.5013H11.6667C11.7107 6.5013 11.7533 6.48397 11.7847 6.45263C11.816 6.4213 11.8333 6.37863 11.8333 6.33463V4.33464Z"
				fill="#3A416F" />
			<path
				d="M12.8333 9.66667V11.6667C12.8333 11.976 12.7107 12.2727 12.4913 12.4913C12.2727 12.7107 11.976 12.8333 11.6667 12.8333H9.66667C9.35733 12.8333 9.06067 12.7107 8.842 12.4913C8.62267 12.2727 8.5 11.976 8.5 11.6667V9.66667C8.5 9.35733 8.62267 9.06067 8.842 8.842C9.06067 8.62267 9.35733 8.5 9.66667 8.5H11.6667C11.976 8.5 12.2727 8.62267 12.4913 8.842C12.7107 9.06067 12.8333 9.35733 12.8333 9.66667V9.66667ZM11.8333 9.66667C11.8333 9.62267 11.816 9.58 11.7847 9.54867C11.7533 9.51733 11.7107 9.5 11.6667 9.5H9.66667C9.62267 9.5 9.58 9.51733 9.54867 9.54867C9.51733 9.58 9.5 9.62267 9.5 9.66667V11.6667C9.5 11.7107 9.51733 11.7533 9.54867 11.7847C9.58 11.816 9.62267 11.8333 9.66667 11.8333H11.6667C11.7107 11.8333 11.7533 11.816 11.7847 11.7847C11.816 11.7533 11.8333 11.7107 11.8333 11.6667V9.66667Z"
				fill="#3A416F" />
			<path
				d="M15.1667 6.00065C15.1667 6.27665 14.9427 6.50065 14.6667 6.50065C14.3907 6.50065 14.1667 6.27665 14.1667 6.00065V2.66732C14.1667 2.20732 13.7933 1.83398 13.3333 1.83398H10C9.724 1.83398 9.5 1.60998 9.5 1.33398C9.5 1.05798 9.724 0.833984 10 0.833984H13.3333C14.346 0.833984 15.1667 1.65465 15.1667 2.66732V6.00065Z"
				fill="#3A416F" />
			<path
				d="M1.83301 6.00065C1.83301 6.27665 1.60901 6.50065 1.33301 6.50065C1.05701 6.50065 0.833008 6.27665 0.833008 6.00065V2.66732C0.833008 1.65465 1.65367 0.833984 2.66634 0.833984H5.99968C6.27568 0.833984 6.49968 1.05798 6.49968 1.33398C6.49968 1.60998 6.27568 1.83398 5.99968 1.83398H2.66634C2.20634 1.83398 1.83301 2.20732 1.83301 2.66732V6.00065Z"
				fill="#3A416F" />
			<path
				d="M10 15.1667C9.724 15.1667 9.5 14.9427 9.5 14.6667C9.5 14.3907 9.724 14.1667 10 14.1667H13.3333C13.7933 14.1667 14.1667 13.7933 14.1667 13.3333V10C14.1667 9.724 14.3907 9.5 14.6667 9.5C14.9427 9.5 15.1667 9.724 15.1667 10V13.3333C15.1667 14.346 14.346 15.1667 13.3333 15.1667H10Z"
				fill="#3A416F" />
			<path
				d="M5.99968 14.1667C6.27568 14.1667 6.49968 14.3907 6.49968 14.6667C6.49968 14.9427 6.27568 15.1667 5.99968 15.1667H2.66634C1.65367 15.1667 0.833008 14.346 0.833008 13.3333V10C0.833008 9.724 1.05701 9.5 1.33301 9.5C1.60901 9.5 1.83301 9.724 1.83301 10V13.3333C1.83301 13.7933 2.20634 14.1667 2.66634 14.1667H5.99968Z"
				fill="#3A416F" />
		</g>
		<defs>
			<clipPath id="clip0_1154_16422">
				<rect width="16" height="16" fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>

<script>
export default {
	name: 'QRCodeIcon',
}
</script>

<style lang="scss" scoped></style>
