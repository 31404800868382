<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7188 4H5.78125C4.79749 4 4 4.79749 4 5.78125V12.7188C4 13.7025 4.79749 14.5 5.78125 14.5H12.7188C13.7025 14.5 14.5 13.7025 14.5 12.7188V5.78125C14.5 4.79749 13.7025 4 12.7188 4Z"
      :stroke="color"
      stroke-linejoin="round"
    />
    <path
      d="M11.9844 4L12 3.25C11.9987 2.78628 11.8139 2.34192 11.486 2.01402C11.1581 1.68612 10.7137 1.50132 10.25 1.5H3.5C2.97005 1.50157 2.46225 1.71278 2.08752 2.08752C1.71278 2.46225 1.50157 2.97005 1.5 3.5V10.25C1.50132 10.7137 1.68612 11.1581 2.01402 11.486C2.34192 11.8139 2.78628 11.9987 3.25 12H4"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "CopyIcon",
  props: {
    color: {
      type: String,
      default: "#717598",
    },
  },
};
</script>
