<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
  >
    <path
      d="M14.5 14.5H2.5V13.1667H14.5V14.5ZM5.83333 7.16667H3.16667V11.8333H5.83333V7.16667ZM9.83333 2.5H7.16667V11.8333H9.83333V2.5ZM13.8333 4.5H11.1667V11.8333H13.8333V4.5Z"
      fill="#717598"
    />
  </svg>
</template>
<script>
export default {
  name: "Stats",
};
</script>
<style lang="scss" scoped></style>
