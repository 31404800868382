<template>
  <section class="working">
    <img src="@/assets/img/emptyContent/borken.png" alt="page" />
    <h1 class="working__title">Упс... Данная страница находится на техническом обслуживании</h1>
    <p class="working__text">
      Мы прикладываем все усилия, чтобы поднять её на ноги как можно быстрее.
    </p>
    <button class="working__button" @click="reloadPage">Обновить сейчас</button>
  </section>
</template>
<script>
export default {
  methods: {
    reloadPage(){
      location.reload()
    }
  },
};
</script>
<style lang="scss">
.v-application {
  .working {
    background: #f6f7f9;
    z-index: 9;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 130px 0;
    &__title {
      color: #3a416f;
      font-size: 22px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: -0.4px;
      margin-top: 40px;
    }
    &__text {
      color: rgba(58, 65, 111, 0.6);
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: -0.4px;
      text-align: center;
      max-width: 486px;
      margin-top: 10px ;
    }
    &__button {
      border-radius: 8px;
      background: #3a416f;
      padding: 16px 48px;
      color: #f5f8fe;
      font-size: 12px;
      font-weight: 700;
      line-height: 12px;
      letter-spacing: -0.24px;
      margin-top: 20px;

    }
  }
}
</style>
