import axios from 'axios';
import API_ENV from '@/config/api.config';

const { apiAiGenerationData, apiAiGenerationHistory } = API_ENV;

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async createGeneration({ commit }, obj) {
      try {
        const { data } = await axios.post(apiAiGenerationData, obj);
        return data;
      } catch (error) {
        // commit('showAlert', error.response.data);
      }
    },
    async fetchTableData({ commit }, obj) {
      try {
        const { data } = await axios.get(apiAiGenerationHistory);
        return data;
      } catch (error) {}
    },
  },
};
