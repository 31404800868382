<template>
  <p :class="{ prime: icon === 'prime', err: icon === 'err' }">
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      v-if="icon === 'prime'"
    >
      <path
        d="M6.5 0C2.91576 0 0 2.91576 0 6.5C0 10.0842 2.91576 13 6.5 13C10.0842 13 13 10.0842 13 6.5C13 2.91576 10.0842 0 6.5 0Z"
        fill="#513CD3"
      />
      <path
        d="M9.79391 5.12204L6.27305 8.64281C6.16742 8.74844 6.02876 8.8016 5.89011 8.8016C5.75145 8.8016 5.61279 8.74844 5.50716 8.64281L3.74678 6.88242C3.53493 6.67067 3.53493 6.32829 3.74678 6.11654C3.95853 5.90469 4.30081 5.90469 4.51266 6.11654L5.89011 7.49398L9.02803 4.35616C9.23978 4.1443 9.58206 4.1443 9.79391 4.35616C10.0057 4.56791 10.0057 4.91019 9.79391 5.12204Z"
        fill="#FAFAFA"
      />
    </svg>
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      v-if="icon === 'err'"
    >
      <path
        d="M11.0985 1.90178C8.56225 -0.633927 4.4376 -0.633927 1.90136 1.90178C-0.633787 4.43749 -0.633787 8.5632 1.90136 11.0989C3.16948 12.3665 4.83483 13 6.50021 13C8.16558 13 9.83037 12.3665 11.0985 11.0989C13.6342 8.56323 13.6342 4.43749 11.0985 1.90178ZM9.18221 8.41635C9.3941 8.62823 9.3941 8.97074 9.18221 9.18263C9.07654 9.28831 8.93781 9.34142 8.79906 9.34142C8.66033 9.34142 8.52158 9.28831 8.4159 9.18263L6.50018 7.26635L4.58499 9.18207C4.47878 9.28775 4.34003 9.34087 4.20184 9.34087C4.06311 9.34087 3.92436 9.28775 3.81868 9.18207C3.6068 8.97018 3.6068 8.62714 3.81868 8.41579L5.73387 6.50007L3.81815 4.58434C3.60626 4.37246 3.60626 4.02942 3.81815 3.81806C4.0295 3.60618 4.37255 3.60618 4.58443 3.81806L6.50016 5.73378L8.41588 3.81806C8.62776 3.60618 8.97027 3.60618 9.18216 3.81806C9.39405 4.02942 9.39405 4.37246 9.18216 4.58434L7.26644 6.50007L9.18221 8.41635Z"
        fill="#D33C3C"
      />
    </svg>
  </p>
</template>

<script>
export default {
  name: "check-mark",
  props: {
    icon: String,
  },
};
</script>
<style lang="scss" scoped>
.prime {
  background: #503cd30d;
}
.err {
  background: #ffecec;
}
</style>
