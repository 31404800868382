<template>
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.90187 11.5855L7.57929 16.1227C8.04081 16.1227 8.2407 15.9244 8.4804 15.6864L10.6442 13.6184L15.1278 16.9019C15.9501 17.3602 16.5295 17.1189 16.7513 16.1455L19.6943 2.35497L19.6951 2.35416C19.9559 1.13859 19.2555 0.663256 18.4544 0.961459L1.15532 7.5845C-0.0253077 8.04278 -0.00743181 8.70094 0.95462 8.99914L5.37729 10.3748L15.6503 3.94674C16.1337 3.6266 16.5733 3.80374 16.2117 4.12388L7.90187 11.5855Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "Telegram",
};
</script>

<style lang="scss" scoped></style>
