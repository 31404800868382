<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_1154_16407)">
      <path
        d="M9.61644 14.5739C9.48451 14.6068 9.34999 14.636 9.21648 14.6606C8.86745 14.7255 8.6367 15.0612 8.70126 15.4105C8.73314 15.5824 8.83075 15.7255 8.96327 15.819C9.09985 15.9151 9.27367 15.9585 9.4508 15.9255C9.6098 15.896 9.77005 15.8613 9.92729 15.8221C10.272 15.7363 10.4819 15.3871 10.3959 15.0427C10.3102 14.6979 9.96131 14.4881 9.61644 14.5739Z"
        fill="#3A416F" />
      <path
        d="M14.3732 5.95251C14.4182 6.0883 14.5043 6.19915 14.6129 6.27574C14.774 6.38921 14.9848 6.42739 15.186 6.36087C15.5233 6.24888 15.706 5.8852 15.5944 5.54811C15.5436 5.39444 15.4872 5.24028 15.4271 5.09019C15.2952 4.76035 14.921 4.59977 14.591 4.7317C14.2613 4.86357 14.1007 5.23781 14.2327 5.56775C14.2832 5.6939 14.3305 5.8234 14.3732 5.95251Z"
        fill="#3A416F" />
      <path
        d="M11.7066 13.6555C11.5931 13.7304 11.476 13.8029 11.358 13.8708C11.0502 14.0484 10.9448 14.4418 11.1223 14.7495C11.1705 14.8332 11.2347 14.9016 11.3086 14.9538C11.507 15.0934 11.7765 15.1144 12.0008 14.9851C12.1411 14.9042 12.2805 14.8182 12.4157 14.7288C12.712 14.5331 12.7935 14.134 12.5977 13.8376C12.4019 13.5411 12.003 13.4596 11.7066 13.6555Z"
        fill="#3A416F" />
      <path
        d="M15.9939 7.74883C15.9799 7.39382 15.6809 7.1175 15.3258 7.13136C14.9711 7.14536 14.6946 7.44448 14.7085 7.79936C14.7138 7.93504 14.7152 8.07275 14.7121 8.2083C14.7071 8.43093 14.816 8.62938 14.9852 8.74866C15.086 8.81965 15.2084 8.86261 15.341 8.86563C15.696 8.87351 15.9902 8.59193 15.9981 8.23674C16.0017 8.07478 16.0003 7.91069 15.9939 7.74883Z"
        fill="#3A416F" />
      <path
        d="M14.2677 11.9629C13.983 11.7493 13.5803 11.8073 13.3671 12.0914C13.2854 12.2003 13.1994 12.3077 13.1114 12.4112C12.8813 12.6816 12.9138 13.0876 13.1841 13.3179C13.1995 13.331 13.2151 13.343 13.2313 13.3542C13.5 13.5437 13.8735 13.5002 14.0908 13.2452C14.196 13.1217 14.2985 12.9933 14.396 12.8633C14.6093 12.5792 14.5516 12.1762 14.2677 11.9629Z"
        fill="#3A416F" />
      <path
        d="M15.2132 9.63705C14.8743 9.53077 14.5133 9.71946 14.4071 10.0584C14.3664 10.1879 14.3214 10.318 14.2728 10.4454C14.166 10.726 14.2683 11.0346 14.5028 11.2C14.5459 11.2302 14.5933 11.2559 14.6448 11.2753C14.9766 11.402 15.3482 11.2355 15.4747 10.9035C15.5325 10.752 15.5861 10.5972 15.6345 10.4432C15.7406 10.1042 15.5521 9.74333 15.2132 9.63705Z"
        fill="#3A416F" />
      <path
        d="M6.80993 14.6661C6.23486 14.5629 5.68322 14.3871 5.16013 14.1418C5.15393 14.1386 5.1484 14.1349 5.1419 14.132C5.01863 14.0739 4.89557 14.0118 4.77629 13.9469C4.77588 13.9464 4.77512 13.9461 4.77447 13.9458C4.55562 13.8253 4.34203 13.6919 4.13454 13.5459C1.10884 11.4145 0.381345 7.21885 2.51287 4.19319C2.97636 3.53551 3.53725 2.98683 4.16257 2.55195C4.17027 2.54658 4.17798 2.54125 4.18561 2.53585C6.38912 1.01757 9.38114 0.915252 11.7209 2.47542L11.2184 3.20151C11.0787 3.40361 11.1646 3.55088 11.4092 3.52887L13.5921 3.33345C13.837 3.31143 13.9834 3.0996 13.9175 2.86311L13.3314 0.751125C13.2658 0.514358 13.0977 0.486017 12.9579 0.688081L12.4542 1.41589C10.7371 0.263249 8.67765 -0.17627 6.63352 0.178193C6.42764 0.213825 6.22465 0.257436 6.02444 0.308201C6.02289 0.308476 6.02166 0.308648 6.02042 0.308924C6.01268 0.310815 6.00484 0.313326 5.99731 0.315424C4.23462 0.768081 2.6967 1.79615 1.5993 3.26297C1.59005 3.27395 1.58052 3.28468 1.57178 3.29661C1.53529 3.34576 1.49908 3.39604 1.46362 3.44633C1.40563 3.52873 1.34847 3.61321 1.29378 3.69768C1.28693 3.70786 1.28171 3.71821 1.27572 3.72849C0.370133 5.1318 -0.0666683 6.75415 0.00824147 8.40581C0.00841344 8.41125 0.0081039 8.41671 0.00824147 8.42229C0.0154986 8.58363 0.0282931 8.7472 0.0456619 8.90824C0.0465906 8.91862 0.048895 8.92846 0.0506491 8.93885C0.0686026 9.10077 0.0911306 9.26308 0.119368 9.42535C0.40635 11.081 1.18736 12.5708 2.35768 13.7301C2.3604 13.7328 2.36322 13.7358 2.36597 13.7386C2.36694 13.7396 2.368 13.7402 2.36893 13.7412C2.68336 14.0513 3.02537 14.3382 3.39362 14.5975C4.35734 15.2766 5.43046 15.7255 6.58293 15.9323C6.93261 15.9951 7.26664 15.7623 7.32938 15.4128C7.39208 15.0631 7.15951 14.7288 6.80993 14.6661Z"
        fill="#3A416F" />
      <path
        d="M7.60558 2.86133C7.31794 2.86133 7.08496 3.09452 7.08496 3.38174V8.5665L11.8268 11.0178C11.9032 11.0573 11.9849 11.0759 12.0653 11.0759C12.2536 11.0759 12.4355 10.9733 12.528 10.7944C12.6599 10.539 12.5601 10.2252 12.3047 10.0933L8.12561 7.93276V3.38174C8.12558 3.09452 7.89287 2.86133 7.60558 2.86133Z"
        fill="#3A416F" />
    </g>
    <defs>
      <clipPath id="clip0_1154_16407">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'TimerIcon',
}
</script>

<style lang="scss" scoped></style>
