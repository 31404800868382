import axios from 'axios';
import API_ENV from '@/config/api.config';
import Vue from 'vue';
import { routeToItems, routeMark, routeTooltip } from './updatePoint';
import connectWebSocket from '@/utils/connectWebSocket';
const { apiGetStage } = API_ENV;

export default {
  state: {
    stageList: [
      {
        title: 'Создание кабинета',
        name: 'cabinet',
        description:
          'Создайте свой первый кабинет в настройках и добавьте официальный API-токен. Токен нужен, чтобы мы смогли загрузить все данные с вашего кабинета WB в нашу систему и обеспечить вам работоспособность большинства инструментов.',
        isCanBeOpen: true,
        isActive: true,
        procent: 0,
        routeTo: '/settings',
        markBlock: '.wbCabinets',
      },
      {
        title: 'Телеграм-бот',
        name: 'telegram_bot',
        description: `Добавьте нашего <a class="link" href="https://t.me/mamod_assistant_bot" target="_blank">бота-ассистента</a> в свой Телеграм аккаунт. Он будет присылать вам коды подтверждения операций, рекомендации и важные предупреждения.`,
        isCanBeOpen: true,
        isActive: false,
      },
      {
        title: 'Складские документы',
        name: 'warehouse_status',
        description: `Заведите свой физический склад в систему и внесите актуальные складские остатки, с помощью складских документов. На основании этих данных мы дадим вам рекомендации по поставкам и закупкам.`,
        isCanBeOpen: false,
        isActive: false,
        routeTo: '/stock/documents/create',
      },
      {
        title: 'Закупочная стоимость и расходы',
        name: 'Details',
        description: `Внесите в систему данные о закупочной стоимости товаров, вашей системе налогообложения и примените указанные значения для предыдущих поставок. Затем заполните расходы по вашему бизнесу. На основании этих данных, мы предоставим вам развернутую аналитическую информацию и дадим рекомендации.`,
        isCanBeOpen: false,
        isActive: false,
        routeTo: '/expenses/goods-params',
        markBlock: '.main-fields',
      },
    ],
    ws: null,
  },
  getters: {
    stageList: ({ stageList }) => stageList,
    isStageWayDone: ({ stageList }) => (stageList.find(item => item.procent === 0) ? false : true),
  },
  mutations: {
    setStagesWebSocket(state, ws) {
      state.ws = ws;
    },
  },
  actions: {
    async getStageList({ state, dispatch }, data) {
      try {
        // const { data } = await axios.get(apiGetStage);
        state.stageList.forEach(async (item, index) => {
          const findedItem = Object.entries(data.data).find(el => item.name === el[0]);
          if (!findedItem) return;
          const points = await dispatch('addRouteToItems', findedItem[1]);
          Vue.set(item, 'points', points);
          const pointsLength = Object.entries(item.points).length;
          const actualPoints = Object.entries(item.points).map(value => value[1]);
          const countActual = actualPoints.filter(item => item.value).length;

          Vue.set(item, 'procent', Math.floor((countActual * 100) / pointsLength));
          if (
            index === 0 ||
            (index !== 0 &&
              state.stageList[index - 1].isActive &&
              state.stageList[index - 1].procent === 100) ||
            (index > 2 && state.stageList[2].isActive && state.stageList[2].procent === 100)
          ) {
            Vue.set(item, 'isActive', true);
          }
        });
      } catch (error) {
        console.error(new Error(error));
      }
    },
    async addRouteToItems({ commit }, obj) {
      Object.entries(obj).forEach(item => {
        item[1].routeTo = routeToItems[item[0]];
        item[1].markBlock = routeMark[item[0]];
        item[1].tooltip = routeTooltip[item[0]];
      });
      return obj;
    },
    connectStageWebSocket({ state, commit, dispatch }) {
      if (state.ws) return state.ws;

      const ws = connectWebSocket('stages');

      ws.addEventListener('message', message => {
        const response = JSON.parse(message.data);
        dispatch('getStageList', response);
      });

      commit('setStagesWebSocket', ws);

      return new Promise(resolve => ws.addEventListener('message', resolve, { once: true }));
    },
  },
};
