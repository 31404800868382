const financeInfo = {
  goodsMarginInfo: {
    lowMarj: [
      {
        nm_id: 141954987,
        image: 'https://basket-10.wbbasket.ru/vol1419/part141954/141954987/images/big/1.jpg',
        sale_price: 616,
        orders_rate_percentage: 939,
        expenses_percentage: 2091.75,
        month_expense: 19.83,
        month_expense2: 24.71,
        report_expense: '39.13',
        sale_amount: 4,
        orders_count: 213,
        orders_rate: 9.39,
        sale_price_if_take_recomm_price: 19866,
        size: '0',
        margin: 23.5,
        income: 699.03,
        abc: 'C',
        per_one_expense: '500.00',
        recommendation_price: 662.2,
      },
      {
        nm_id: 79453862,
        image: 'https://basket-05.wbbasket.ru/vol794/part79453/79453862/images/big/1.jpg',
        sale_price: 162,
        orders_rate_percentage: 161,
        expenses_percentage: 1514,
        month_expense: 6.95,
        month_expense2: 0,
        report_expense: '8.19',
        sale_amount: 1,
        orders_count: 310,
        orders_rate: 1.61,
        sale_price_if_take_recomm_price: 5108.35,
        size: '0',
        margin: 25.89,
        income: 209.67,
        abc: 'C',
        per_one_expense: '125.00',
        recommendation_price: 170.28,
      },
    ],
    soldInNeg: [],
    ordersPerDay: {
      goods: [
        {
          date: '2024-02-19',
          nmId: 151447829,
          photo: 'https://basket-10.wbbasket.ru/vol1514/part151447/151447829/images/big/1.jpg',
          stock_name: 'Казань',
          totalPrice: 477,
          discountPercent: 78,
          oblast: 'Пензенская область',
          oblastOkrugName: 'Приволжский Федеральный Округ',
          name: 'Пластина для миостимулятора бабочка',
          price_with_discount: 104.94,
        },
        {
          date: '2024-02-19',
          nmId: 138145332,
          photo: 'https://basket-10.wbbasket.ru/vol1381/part138145/138145332/images/big/1.jpg',
          stock_name: 'Тула',
          totalPrice: 1984,
          discountPercent: 55,
          oblast: 'Саратовская область',
          oblastOkrugName: 'Приволжский Федеральный Округ',
          name: 'Решетка для гриля / мангала',
          price_with_discount: 892.8000000000001,
        },
      ],
      dynamic: -17.83,
      salesSum: 196018,
    },
  },
  abcChart: {
    labels: ['Группа A', 'Группа B', 'Группа C'],
    dataset: {
      data: [228982, 120450, 130500],
      backgroundColor: ['#49AAE0', '#97E3D5', '#F47560'],
      textColor: ['#fff', '#214D45', '#fff'],
    },
  },
};

const logisticInfo = {
  turnover: {
    labels: ['>240 д.', '>120 д.', '>60 д.', '>30 д.', '<30 д.'],
    datasets: [
      {
        backgroundColor: ['#8764EB', '#EB9564', '#93D75D', '#5D7FD7', '#EB648C', '#5E91C7'],
        data: [2, 2, 0, 5, 20],
        borderColor: '#fff',
        borderWidth: 2,
        borderRadius: 6,
      },
    ],
  },
  wbStocks: {
    labels: ['OOS', '< 14 дней', '< 30 дней', '> 30 дней'],
    datasets: [
      {
        backgroundColor: [
          '#c13030',
          '#8764EB',
          '#EB9564',
          '#93D75D',
          '#5D7FD7',
          '#EB648C',
          '#5E91C7',
        ],
        data: [111, 24, 6, 4],
        borderColor: '#fff',
        borderWidth: 2,
        borderRadius: 6,
      },
    ],
  },
  ownStocks: {
    labels: ['< 7 дней', '< 14 дней', '> 14 дней', 'OOS'],
    datasets: [
      {
        data: [31, 10, 306, 1407],
        backgroundColor: ['#49AAE0', '#97E3D5', '#ff6f00', '#F47560'],
        hoverOffset: 4,
      },
    ],
  },
};

const turnoverDynamics = {
  avg_turnover: 10,
  less_then_30: 20,
  more_then_30: 5,
  more_then_60: 0,
  more_then_120: 2,
  more_then_240: 2,
  turnover_less_than_30_list: [
    {
      id: 27812,
      nm_id: 100959669,
      name: 'Миниварка суповарка электрическая',
      turnover: 5,
      image: 'https://basket-06.wbbasket.ru/vol1009/part100959/100959669/images/big/1.jpg',
    },
    {
      id: 27847,
      nm_id: 141936085,
      name: 'Миостимулятор для тела и мышц ',
      turnover: 1,
      image: 'https://basket-10.wbbasket.ru/vol1419/part141936/141936085/images/big/1.jpg',
    },
  ],
  turnover_more_than_30_list: [
    {
      id: 27895,
      nm_id: 79453859,
      name: 'Шнурки',
      turnover: 38,
      image: 'https://basket-05.wbbasket.ru/vol794/part79453/79453859/images/big/1.jpg',
    },
    {
      id: 27890,
      nm_id: 90257148,
      name: 'Видеоняня беспроводная',
      turnover: 43,
      image: 'https://basket-05.wbbasket.ru/vol902/part90257/90257148/images/big/1.jpg',
    },
  ],
  turnover_more_than_60_list: [],
  turnover_more_than_120_list: [
    {
      id: 28598,
      nm_id: 205796949,
      name: 'Массажер для ног электрический коврик',
      turnover: 144,
      image: 'https://basket-14.wbbasket.ru/vol2057/part205796/205796949/images/big/1.jpg',
    },
    {
      id: 27849,
      nm_id: 183831732,
      name: 'Тендерайзер для мяса черный',
      turnover: 220,
      image: 'https://basket-12.wbbasket.ru/vol1838/part183831/183831732/images/big/1.jpg',
    },
  ],
  turnover_more_than_240_list: [
    {
      id: 27893,
      nm_id: 141954987,
      name: '',
      turnover: 324,
      image: 'https://basket-10.wbbasket.ru/vol1419/part141954/141954987/images/big/1.jpg',
    },
    {
      id: 27950,
      nm_id: 96347079,
      name: 'Погремушка для новорожденного 4 в 1 зайка прорезыватель',
      turnover: 301,
      image: 'https://basket-05.wbbasket.ru/vol963/part96347/96347079/images/big/1.jpg',
    },
  ],
};

const reputationInfo = {
  total_feedbacks: 14,
  total_refund_amount: -11411,
  product_count_by_rating: [
    { rating: '1', product_count: 2 },
    { rating: '2', product_count: 1 },
    { rating: '3', product_count: 1 },
    { rating: '4', product_count: 3 },
    { rating: '5', product_count: 9 },
  ],
  refund_count_change: -3,
  refund_count_7_day_ago: 5,
  refund_count_14_day_ago: 8,
  ratings_count: [
    {
      product_name: 'Калимба 17 нот',
      nomenclature_id: 141964934,
      reviews_count: 1,
      average_rating: 4.3,
      required_additional_reviews: 2,
    },
    {
      product_name: 'Калимба 17 нот прозрачная музыкальный инструмент акрил ',
      nomenclature_id: 51458358,
      reviews_count: 1,
      average_rating: 3.4,
      required_additional_reviews: 5,
    },
  ],
  unanswered_feedbacks_count: 14,
  negative_feedbacks_count: 1,
  last_feedbacks: [
    {
      rating: '5',
      brand_name: 'tovar1001',
      date_created: '2024-02-19T19:51:39Z',
      commentator_name: 'Надежда',
      nomenclature_id: 138145332,
      text: 'Ещё не использовалась,пришла без повреждений,соответствует описанию',
      product_name: 'Решетка для гриля / мангала',
    },
    {
      rating: '4',
      brand_name: 'tovar1001',
      date_created: '2024-02-19T18:03:20Z',
      commentator_name: 'Анна',
      nomenclature_id: 141936084,
      text: 'У меня на работе тяжелый физический труд, сегодня получила массажёр, сразу помассажировала где болело, так усталости как и не бывало.',
      product_name: 'Миостимулятор для тела и мышц электрический',
    },
    {
      rating: '3',
      text: 'Не понравился внешний вид товара',
      brand_name: 'tovar1001',
      is_answered: false,
      date_created: '2024-02-19T18:03:20Z',
      nomenclature_id: 123456789,
    },
    {
      rating: '1',
      text: 'Не работает, несколько раз пыталась включить его',
      brand_name: 'tovar1001',
      is_answered: false,
      date_created: '2024-02-19T18:03:20Z',
      nomenclature_id: 129876542,
    },
  ],
  rating_dynamics: [
    {
      sku: 141936084,
      product_name: 'Миостимулятор для тела и мышц электрический',
      current_rating: '5.0',
      dynamics: 13.207547169811312,
    },
    {
      sku: 145921936,
      product_name: 'Набор разделочных досок на подставке',
      current_rating: '5.0',
      dynamics: 66.66666666666666,
    },
  ],
  negative_feedbacks: [
    {
      rating: '1',
      text: 'Не работает вообще! Продавец ненадежный , не хочет менять товар. НЕ РЕКОМЕНДУЮ!',
      brand_name: 'tovar1001',
      is_answered: false,
      date_created: '2024-02-19T17:25:46Z',
      nomenclature_id: 155847964,
    },
  ],
  unanswered_feedbacks: [
    {
      rating: '5',
      text: 'Ещё не использовалась,пришла без повреждений,соответствует описанию',
      brand_name: 'tovar1001',
      is_answered: false,
      date_created: '2024-02-19T19:51:39Z',
      nomenclature_id: 138145332,
    },
    {
      rating: '4',
      text: 'У меня на работе тяжелый физический труд, сегодня получила массажёр, сразу помассажировала где болело, так усталости как и не бывало.',
      brand_name: 'tovar1001',
      is_answered: false,
      date_created: '2024-02-19T18:03:20Z',
      nomenclature_id: 141936084,
    },
  ],
  current_week_refunds: [
    {
      photo: 'https://basket-05.wbbasket.ru/vol963/part96347/96347078/images/big/1.jpg',
      nmId: 96347078,
      totalPrice: -1064,
      name: 'Погремушка для новорожденного 4 в 1 зайка прорезыватель',
      type: 'Возврат',
      discountPercent: 68,
    },
    {
      photo: 'https://basket-10.wbbasket.ru/vol1423/part142305/142305279/images/big/1.jpg',
      nmId: 142305279,
      totalPrice: -1951,
      name: 'Решетка для гриля / мангала',
      type: 'Возврат',
      discountPercent: 78,
    },
  ],
  previous_week_refunds: [
    {
      photo: 'https://basket-10.wbbasket.ru/vol1419/part141936/141936084/images/big/1.jpg',
      nmId: 141936084,
      type: 'Возврат',
      totalPrice: -4127,
      name: 'Миостимулятор для тела и мышц электрический',
      discountPercent: 95,
    },
    {
      photo: 'https://basket-10.wbbasket.ru/vol1423/part142305/142305278/images/big/1.jpg',
      nmId: 142305278,
      type: 'Возврат',
      totalPrice: -2241,
      name: 'Решетка для гриля / мангала',
      discountPercent: 71,
    },
  ],
};

const reputatioSalesDynamic = {
  salesDynamic: 13.8,
  returnDynamic: 100,
  returnChart: {
    labels: [
      '11.09.2023',
      '18.09.2023',
      '25.09.2023',
      '02.10.2023',
      '09.10.2023',
      '16.10.2023',
      '23.10.2023',
      '30.10.2023',
      '06.11.2023',
      '13.11.2023',
      '20.11.2023',
      '27.11.2023',
      '04.12.2023',
      '11.12.2023',
      '18.12.2023',
      '25.12.2023',
      '28.12.2023',
      '01.01.2024',
      '08.01.2024',
      '15.01.2024',
      '22.01.2024',
    ],
    datasets: [
      {
        label: 'Динамика возвратов',
        data: [13, 67, 27, 60, 40, 18, 30, 13, 20, 59, 15, 60, 30, 10, 27, 11, 18, 15, 28, 24, 26],
      },
    ],
  },
  salesChart: {
    labels: [
      '11.09.2023',
      '18.09.2023',
      '25.09.2023',
      '02.10.2023',
      '09.10.2023',
      '16.10.2023',
      '23.10.2023',
      '30.10.2023',
      '06.11.2023',
      '13.11.2023',
      '20.11.2023',
      '27.11.2023',
      '04.12.2023',
      '11.12.2023',
      '18.12.2023',
      '25.12.2023',
      '28.12.2023',
      '01.01.2024',
      '08.01.2024',
      '15.01.2024',
      '22.01.2024',
    ],
    datasets: [
      {
        label: 'Процент выкупа',
        data: [
          5.94, 16.44, 15.07, 16.83, 16.06, 17.82, 23.31, 16.02, 21.12, 15.76, 21.55, 21.26, 13.93,
          14.5, 20.93, 14.96, 10.98, 7.73, 13.06, 10.89, 12.39,
        ],
      },
    ],
  },
};

const seoRecomendations = [
  {
    nm_id: 51458358,
    image: 'https://basket-04.wbbasket.ru/vol514/part51458/51458358/images/big/1.jpg',
    product_name: 'Калимба 17 нот прозрачная музыкальный инструмент акрил ',
    seo_name_current: 'Калимба 17 нот прозрачная музыкальный инструмент акрил ',
    seo_name_recommendation:
      '"Калимба 17 нот: Акриловый музыкальный инструмент, прозрачный дизайн для лучшего звука"',
    seo_name_core: -1,
    seo_description_current:
      'Любите музыку? Увлекаетесь игрой на ударных музыкальных инструментах? Обратите свое внимание на наш профессиональный музыкальный инструмент, который вы точно полюбите! Это щипковый язычковый идиофон родом из Африки. Это ручное пианино, вот только вместо клавиш на деревянный резонатор укладываются специальные язычки. Чтобы сыграть мелодию на ней, нажмите на них большим и указательным пальцем. Подходит опытным и начинающим музыкантам. Кстати она послужит отличным подарком. Чтобы научиться играть на африканском пианино, понадобится знание нот на базовом уровне. Поскольку песни на играют большими и указательными пальцами, имеет роль их очередность и ритм. Также можно опираться на табулатуры. Как правильно выбрать колимбу? Для начинающего подойдет kalimba с небольшим количеством язычков, с молоточком для игры на них, к примеру, 15-17. Звучание у каждого инструмента разное. Мы предлагаем лучшие варианты, которые способны пленить ваше сердце и влюбить в этот самобытный этнический инструмент.',
    seo_description_recommendation:
      'Этот прозрачный музыкальный инструмент Калимба 17 нот, изготовленный из высококачественного акрила, предназначен для всех любителей музыки. Восхитительно прозрачный, он создаёт прекрасные, глубокие и резонирующие звуки. Калимба это идеальный музыкальный подарок для детей и взрослых. Изумительно прост в обучении, она подойдет как для новичков, так и для профессионалов. Каждая из 17 нот на этой калимбе чётко помечена, что облегчит процесс игры. Инструмент замечательно звучит, создавая чистые, ясные и плавные мелодии. Он удобно лежит в руке, а его размеры идеально подходят для переноски, позволяя вам наслаждаться музыкой в любое время и в любом месте. Если вы ищете доступный, но все же высококачественный музыкальный инструмент, прозрачная 17-нотная калимба - это то, что вы ищете.',
    seo_description_core: -1,
    seo_characteristic_current: [
      { 'Вес товара с упаковкой (г)': 500 },
      { Цвет: ['прозрачный'] },
      {
        Комплектация: [
          'Инструмент музыкальный калимба',
          'кейс',
          'Молоточек настройка инструмента',
          'чехол',
          'салфетка',
        ],
      },
      { 'Количество язычков': 17 },
      { 'Материал изделия': ['металл', 'акрил', 'дерево'] },
      { 'Страна производства': ['Китай'] },
      { 'Вес товара без упаковки (г)': 500 },
    ],
    seo_characteristic_recommendation: [
      {
        Цвет: 0.1994367536,
        Модель: 0.0738396005,
        Действие: 0.0334666978,
        'Тип посадки': 0.0362922163,
        'Вид застежки': 0.0924214434,
        'Объем товара': 0.0528259702,
        'Тип карманов': 0.0651966399,
        'Срок годности': 0.1366154592,
        'Формат листов': 0.0363774685,
        Совместимость: 0.0347971032,
        'Длина предмета': 0.0340199396,
        'Длина упаковки': 0.268560869,
        'Высота предмета': 0.2442516245,
        'Высота упаковки': 0.2706586479,
        'Ширина предмета': 0.2628246316,
        'Ширина упаковки': 0.2707346144,
        'Размер на модели': 0.0577987664,
        'Гарантийный срок': 0.0385691196,
        'Глубина предмета': 0.0805012264,
        'Материал изделия': 0.2014752256,
        'Вес с упаковкой (кг)': 0.0645197623,
        'Особенности модели': 0.1176654516,
        'Рост модели на фото': 0.0746641831,
        'Тренируемые навыки': 0.0337686467,
        'Страна производства': 0.9708076398,
        'Особенности продукта': 0.0403516103,
        'Декоративные элементы': 0.1903333453,
        'Вес товара с упаковкой (г)': 0.1808154618,
        'Вес товара без упаковки (г)': 0.112459695,
        'Пищевая ценность углеводы': 0.0343481216,
        'Количество предметов в упаковке': 0.2278903409,
        'Минимальная температура хранения': 0.0392925143,
        'Параметры модели на фото (ОГ-ОТ-ОБ)': 0.0538286162,
        'Максимальная температура хранения': 0.0416822583,
        'Энергетическая ценность калории (на 100 гр.)': 0.0349818485,
      },
    ],
    seo_characteristic_core: -30,
    seo_value_characteristics: [
      'совместимость',
      'тип карманов',
      'ширина упаковки',
      'длина упаковки',
      'энергетическая ценность калории (на 100 гр.)',
      'тип посадки',
      'особенности продукта',
      'длина предмета',
      'максимальная температура хранения',
      'ширина предмета',
      'количество предметов в упаковке',
      'срок годности',
      'тренируемые навыки',
      'вид застежки',
      'модель',
      'размер на модели',
      'высота предмета',
      'формат листов',
      'пищевая ценность углеводы',
      'высота упаковки',
      'декоративные элементы',
      'параметры модели на фото (ог-от-об)',
      'рост модели на фото',
      'особенности модели',
      'минимальная температура хранения',
      'гарантийный срок',
      'действие',
      'вес с упаковкой (кг)',
      'глубина предмета',
      'объем товара',
    ],
  },
  {
    nm_id: 51457192,
    image: 'https://basket-04.wbbasket.ru/vol514/part51457/51457192/images/big/1.jpg',
    product_name: 'Калимба 17 нот музыкальный инструмент акрил ',
    seo_name_current: 'Калимба 17 нот музыкальный инструмент акрил ',
    seo_name_recommendation:
      '"Калимба 17 Нот, Профессиональный Акриловый Музыкальный Инструмент, Идеальный для Начинающих и Профессионалов"',
    seo_name_core: -1,
    seo_description_current:
      'Любите музыку? Увлекаетесь игрой на ударных музыкальных инструментах? Обратите свое внимание на наш профессиональный музыкальный инструмент, который вы точно полюбите! Это щипковый язычковый идиофон родом из Африки. Это ручное пианино, вот только вместо клавиш на деревянный резонатор укладываются специальные язычки. Чтобы сыграть мелодию на ней, нажмите на них большим и указательным пальцем. Подходит опытным и начинающим музыкантам. Кстати она послужит отличным подарком. Чтобы научиться играть на африканском пианино, понадобится знание нот на базовом уровне. Поскольку песни на играют большими и указательными пальцами, имеет роль их очередность и ритм. Также можно опираться на табулатуры. Как правильно выбрать колимбу? Для начинающего подойдет kalimba с небольшим количеством язычков, с молоточком для игры на них, к примеру, 15-17. Звучание у каждого инструмента разное. Мы предлагаем лучшие варианты, которые способны пленить ваше сердце и влюбить в этот самобытный этнический инструмент.',
    seo_description_recommendation:
      'Этот калимба обладает 17 нотами и изготовлен из высококачественного акрила, что обеспечивает достижение превосходного звука и длительного срока службы. Мелодичные тембры этого музыкального инструмента простые и душевные, делая его не только отличным способом для расслабления и самовыражения, но и прекрасной помощью в музыкальном обучении. Акриловая калимба позволяет создавать ясные и чистые ноты, которые идут прямо от сердца. Инструмент легко подстраивается под любую ноту, что подойдет как новичкам, так и профессионалам. Наличие 17 нот делает его универсальным для игры в любом стиле. Этот калимба - прекрасный подарок для детей, взрослых, начинающих или опытных музыкантов. Благодаря его компактному размеру, вы можете взять его с собой куда угодно и играть в любое время. Комплект поставки включает в себя удобный чехол для переноски и хранения инструмента, а также инструкцию по настройке.',
    seo_description_core: -1,
    seo_characteristic_current: [
      { 'Вес товара с упаковкой (г)': 480 },
      {
        Комплектация: [
          'кейс',
          'Молоточек настройка инструмента',
          'Инструкция ноты табулатуры',
          'Нотная наклейка на язычки калимбы - 1 шт',
          'салфетка',
        ],
      },
      { Цвет: ['черный'] },
      { 'Материал изделия': ['металл', 'акрил', 'дерево'] },
      { 'Страна производства': ['Китай'] },
      { 'Вес товара без упаковки (г)': 500 },
    ],
    seo_characteristic_recommendation: [
      {
        Цвет: 0.1958111364,
        Модель: 0.0738396005,
        Разлиновка: 0.0530708218,
        'Тип посадки': 0.0397604927,
        'Вид застежки': 0.1029566519,
        'Объем товара': 0.0505313131,
        'Тип карманов': 0.0677288561,
        'Срок годности': 0.1273239119,
        'Формат листов': 0.0562632915,
        'Длина упаковки': 0.268560869,
        'Высота предмета': 0.2334148213,
        'Высота упаковки': 0.2706586479,
        'Ширина предмета': 0.261103666,
        'Ширина упаковки': 0.2707346144,
        'Размер на модели': 0.0624128674,
        'Гарантийный срок': 0.0385691196,
        'Глубина предмета': 0.0808946391,
        'Материал изделия': 0.1969332632,
        'Вес с упаковкой (кг)': 0.0645197623,
        'Мощность микроволн': 0.0419523475,
        'Особенности модели': 0.1327536648,
        'Рост модели на фото': 0.0820205162,
        'Особенности тетради': 0.0521195917,
        'Страна производства': 0.9708076398,
        'Особенности продукта': 0.0384427568,
        'Декоративные элементы': 0.2030474651,
        'Вес товара с упаковкой (г)': 0.1781496002,
        'Объем микроволновой печи': 0.0419569164,
        'Вес товара без упаковки (г)': 0.1151654165,
        'Диаметр поворотного стола': 0.0609718432,
        'Режимы работы микроволновки': 0.0422627163,
        'Количество предметов в упаковке': 0.2166933599,
        'Минимальная температура хранения': 0.0364670136,
        'Параметры модели на фото (ОГ-ОТ-ОБ)': 0.0587030078,
        'Максимальная температура хранения': 0.0396972056,
      },
    ],
    seo_characteristic_core: -30,
    seo_value_characteristics: [
      'высота предмета',
      'тип посадки',
      'ширина предмета',
      'ширина упаковки',
      'вид застежки',
      'тип карманов',
      'гарантийный срок',
      'модель',
      'высота упаковки',
      'максимальная температура хранения',
      'декоративные элементы',
      'количество предметов в упаковке',
      'диаметр поворотного стола',
      'режимы работы микроволновки',
      'объем товара',
      'параметры модели на фото (ог-от-об)',
      'особенности модели',
      'мощность микроволн',
      'особенности тетради',
      'глубина предмета',
      'особенности продукта',
      'вес с упаковкой (кг)',
      'рост модели на фото',
      'срок годности',
      'минимальная температура хранения',
      'размер на модели',
      'длина упаковки',
      'разлиновка',
      'объем микроволновой печи',
      'формат листов',
    ],
  },
  {
    nm_id: 66619854,
    image: 'https://basket-04.wbbasket.ru/vol666/part66619/66619854/images/big/1.jpg',
    product_name: 'Подставка для гитары напольная и электрогитары',
    seo_name_current: 'Подставка для гитары напольная и электрогитары',
    seo_name_recommendation:
      '"Напольная подставка для аккустической и электрогитары: удобное хранение и безопасность для инструмента"',
    seo_name_core: -1,
    seo_description_current:
      'Один из самых важных навыков гитариста умение правильно хранить инструмент. Гриф и корпус акустической гитары изготовлены из древесины натурального материала, требовательного к условиям хранения. Имеют значение и температура воздуха в помещении, и влажность, и даже то, в каком положении хранится инструмент. Наилучший вариант вертикальное хранение на подставке под гитару. Чем хорош этот способ:- инструмент защищён от посягательств со стороны детей и домашних животных;- уменьшается количество пыли, которая любит забиваться в колковую механику, негативно влияя на звучание;- сохраняется прогиб грифа;- гитара не занимает полезную площадь в помещении и никому не мешает.',
    seo_description_recommendation:
      'Эта подставка для гитары и электрогитары - идеальный аксессуар для любого музыканта. Она обеспечивает безопасное и удобное хранение вашего инструмента, защищая его от повреждений и царапин. Подставка напольная, что облегчает доступ к гитаре в любой момент. Изготовлена из прочных материалов, эта подставка прослужит долгие годы. Устойчивый и надежный дизайн предотвращает ненужное опрокидывание. Падающие гитары - больше не проблема! Подставка подойдет для различных типов гитар, включая акустические, классические, электрогитары и бас-гитары. Кроме того, она легко собирается и разбирается, идеальна для использования дома, в студии или на сцене. Сделайте свою музыкальную жизнь немного проще с нашей подставкой для гитары.',
    seo_description_core: -1,
    seo_characteristic_current: [
      { 'Материал изделия': ['металл'] },
      { Комплектация: ['Подставка для гитары напольная', 'упаковка'] },
      { 'Высота предмета': 30 },
      { 'Глубина предмета': 5 },
      { 'Вес товара без упаковки (г)': 780 },
      { 'Страна производства': ['Китай'] },
      { Цвет: ['черный'] },
      { 'Вес товара с упаковкой (г)': 800 },
      { 'Ширина предмета': 30 },
    ],
    seo_characteristic_recommendation: [
      {
        Цвет: 0.1986996683,
        Модель: 0.0738396005,
        Питание: 0.0342987354,
        'Тип посадки': 0.0397950665,
        'Вид застежки': 0.1029566519,
        'Калибр струн': 0.0476528301,
        'Объем товара': 0.0505313131,
        'Тип карманов': 0.069193232,
        'Срок годности': 0.1273239119,
        'Длина упаковки': 0.293278866,
        'Высота предмета': 0.3218733767,
        'Высота упаковки': 0.2956397106,
        'Форма медиатора': 0.0446311547,
        'Ширина предмета': 0.3596146291,
        'Ширина упаковки': 0.2954572003,
        'Размер на модели': 0.0623199881,
        'Гарантийный срок': 0.0427808997,
        'Глубина предмета': 0.0999998556,
        'Материал изделия': 0.2464377249,
        'Назначение стойки': 0.08106429,
        'Вес с упаковкой (кг)': 0.1620096451,
        'Материал подставки': 0.0814053693,
        'Особенности модели': 0.1327536648,
        'Особенности посуды': 0.0345328489,
        'Рост модели на фото': 0.0820205162,
        'Страна производства': 0.9708076398,
        'Особенности продукта': 0.0384427568,
        'Декоративные элементы': 0.2030474651,
        'Вес товара с упаковкой (г)': 0.1845258396,
        'Вес товара без упаковки (г)': 0.1151654165,
        'Количество предметов в упаковке': 0.2166933599,
        'Минимальная температура хранения': 0.0364670136,
        'Параметры модели на фото (ОГ-ОТ-ОБ)': 0.0587030078,
        'Максимальная температура хранения': 0.0396972056,
        'Энергетическая ценность калории (на 100 гр.)': 0.0342156041,
      },
    ],
    seo_characteristic_core: -27,
    seo_value_characteristics: [
      'тип карманов',
      'ширина упаковки',
      'длина упаковки',
      'назначение стойки',
      'калибр струн',
      'тип посадки',
      'энергетическая ценность калории (на 100 гр.)',
      'особенности продукта',
      'максимальная температура хранения',
      'форма медиатора',
      'количество предметов в упаковке',
      'особенности посуды',
      'материал подставки',
      'срок годности',
      'вид застежки',
      'модель',
      'размер на модели',
      'высота упаковки',
      'декоративные элементы',
      'параметры модели на фото (ог-от-об)',
      'рост модели на фото',
      'особенности модели',
      'минимальная температура хранения',
      'гарантийный срок',
      'питание',
      'вес с упаковкой (кг)',
      'объем товара',
    ],
  },
  {
    nm_id: 80322105,
    image: 'https://basket-05.wbbasket.ru/vol803/part80322/80322105/images/big/1.jpg',
    product_name: 'Форма для сосисок',
    seo_name_current: 'Форма для сосисок',
    seo_name_recommendation:
      '"Форма для сосисок: идеальное приобретение для любителей домашней кулинарии. Не пропустите!"',
    seo_name_core: -1,
    seo_description_current:
      '**Силиконовая формочка для колбасок и сосисок подойдет для запекания в духовке фарша (например, кебабы, чивапчичи) и сосисок. Покрытие подходит для приготовления блюд на противне и в микроволновке (СВЧ печи), не боится высоких температур. Силиконовая антипригарная форма подойдет для разнообразия детских блюд на пару и обычных домашних изделий, которые едим ежедневно. Набор емкостей изготовлен из пищевого силикона со съемной крышкой в несколько см, чтобы было удобно доставать колбасу из контейнера. Формочка проста в использовании, не уступает популярным моделям: bradex (брадекс), silikolove, папа может, посуда от юлькин дом. Жаропрочная кулинарная прямоугольная силиконовая форма не подойдет как упаковка для хранения сосисок. Для этого лучше использовать, квадратные пластиковые контейнеры (только пищевой пластик с твердым дном) или закрытые подносы, как для хлеба. Сосисочница заменит дорогие машины мечты для изготовления колбасы, которые большие по размеру и займут весь стол. Сосисочница не занимает много места.**',
    seo_description_recommendation:
      'Эта форма для сосисок превратит приготовление пищи в удовольствие. Изготовленная из качественных, безопасных материалов, форма не нанесет вреда вашему здоровью и обеспечит превосходные результаты при использовании. Она идеально подходит для того, чтобы просто и быстро создать сосиски идеальной формы, что особенно практично при сервировке праздничного стола или приготовлении блюда для большой компании. Форма для сосисок - это не только удобный помощник на практике, но и отличный подарок для любителей кулинарии. Очень легко моется, не требует сложного ухода. Благодаря своему эргономичному дизайну, она не занимает много места на вашей кухне. Приготовьтесь сэкономить время и энергию при приготовлении ваших любимых сосисок!',
    seo_description_core: -1,
    seo_characteristic_current: [
      { 'Возможность хранения в холодильнике': ['да'] },
      { 'Дата регистрации сертификата/декларации': ['10.04.2023'] },
      { 'Дата окончания действия сертификата/декларации': ['23.03.2026'] },
      { 'Вес с упаковкой (кг)': 0.12 },
      { Цвет: ['желтый'] },
      { Комплектация: ['крышка', 'форма'] },
      { 'Номер декларации соответствия': ['РОСС RU Д-CN.РА01.В.10196/23'] },
      { 'Материал изделия': ['силикон'] },
      { 'Страна производства': ['Китай'] },
    ],
    seo_characteristic_recommendation: [
      {
        Цвет: 0.1901853254,
        Модель: 0.0701793572,
        'Тип посадки': 0.0368471368,
        'Вид застежки': 0.0924214434,
        'Объем товара': 0.0528259702,
        'Съемный борт': 0.0617632816,
        'Тип карманов': 0.0666610158,
        'Форма посуды': 0.1053149558,
        'Срок годности': 0.1408853774,
        'Длина предмета': 0.0414834227,
        'Длина упаковки': 0.2780504431,
        'Материал борта': 0.0491064856,
        'Материал ручек': 0.0383592739,
        'Высота предмета': 0.2561674315,
        'Высота упаковки': 0.2801428419,
        'Ширина предмета': 0.2897967483,
        'Ширина упаковки': 0.2802231141,
        'Размер на модели': 0.0577987664,
        'Глубина предмета': 0.0956736191,
        'Материал изделия': 0.2512441133,
        'Вес с упаковкой (кг)': 0.067809409,
        'Особенности модели': 0.1176654516,
        'Особенности посуды': 0.0902234922,
        'Рост модели на фото': 0.0746641831,
        'Рабочая температура': 0.0878720674,
        'Страна производства': 0.9708076398,
        'Особенности продукта': 0.0403516103,
        'Декоративные элементы': 0.1903333453,
        'Вес товара с упаковкой (г)': 0.1808154618,
        'Вес товара без упаковки (г)': 0.1166987008,
        'Количество предметов в упаковке': 0.2488574432,
        'Минимальная температура хранения': 0.0392925143,
        'Параметры модели на фото (ОГ-ОТ-ОБ)': 0.0538286162,
        'Максимальная температура хранения': 0.0417132123,
        'Возможность хранения в холодильнике': 0.1167481582,
      },
    ],
    seo_characteristic_core: -30,
    seo_value_characteristics: [
      'тип карманов',
      'ширина упаковки',
      'длина упаковки',
      'тип посадки',
      'материал ручек',
      'длина предмета',
      'особенности продукта',
      'вес товара без упаковки (г)',
      'максимальная температура хранения',
      'ширина предмета',
      'количество предметов в упаковке',
      'особенности посуды',
      'материал борта',
      'срок годности',
      'вид застежки',
      'модель',
      'размер на модели',
      'высота предмета',
      'высота упаковки',
      'декоративные элементы',
      'параметры модели на фото (ог-от-об)',
      'рабочая температура',
      'форма посуды',
      'особенности модели',
      'рост модели на фото',
      'съемный борт',
      'минимальная температура хранения',
      'вес товара с упаковкой (г)',
      'глубина предмета',
      'объем товара',
    ],
  },
  {
    nm_id: 79453863,
    image: 'https://basket-05.wbbasket.ru/vol794/part79453/79453863/images/big/1.jpg',
    product_name: 'Шнурки резинки для обуви',
    seo_name_current: 'Шнурки резинки для обуви',
    seo_name_recommendation:
      '"Универсальные Шнурки-резинки для Обуви: Идеальное Качество, Удобство, Долговечность и Модный Дизайн!"',
    seo_name_core: 0,
    seo_description_current:
      'Магнитные застежки шнурков для обуви анти завязки - хит 2023. Короткие ленты резинки невероятно удобны для любого типа обуви. Подойдут для детских и взрослых кед, туфель, спортивных кроссовок и ботинок. Самозавязывающиеся застежки научат детей быстро обуваться без завязок банта. Эластичные умные шнурки в ярких цветах станут акцентом любой пары обуви. На выбор представлены базовые и яркие расцветки. Используется плотный и крепкий силикон для безопасного растяжения сверху покрыты нейлоном. Сами ленивые шнурки не длинные, затягивающиеся, наконечники — мощные магниты с фиксатором. Быстрые шнурки с замком довольно тонкие и плоские, не широкие, но прочные. За счёт этого они отлично сядут в трекинговые и рефлективные кроссовки, тянущиеся резиновые концы плотно закрываются круглыми магнитами.**',
    seo_description_recommendation:
      '"Представляем Вашему вниманию высококачественные шнурки-резинки для обуви. Эти прочные и долговечные шнурки станут идеальным дополнением к любой паре ботинок, кед или кроссовок. Они обеспечивают совершенный обтяжной контур, гарантирующий комфортное ношение в течение всего дня. Шнурки изготовлены из прочного материала, устойчивого к износу. Благодаря специальной резиновой текстуре, они не распускаются и не сползают, что является отличной особенностью для активного образа жизни. Наша продукция предназначена для того, чтобы каждый шаг был удобным и уверенным. Шнурки доступны в различных цветовых вариантах, что позволяет подобрать идеальный вариант для каждой пары обуви. Не упустите возможность обновить вашу обувь с помощью этих блестящих шнурков. Они дадут вашей обуви новый, свежий вид и придадут ощущение комфорта при ходьбе. Закажите сегодня и испытайте разницу с высококачественными шнурками-резинками!"',
    seo_description_core: -1,
    seo_characteristic_current: [
      { 'Материал изделия': ['Полиэстр', 'никелевый металл'] },
      { 'Страна производства': ['Китай'] },
      { Комплектация: ['магнитная металлическая застежка 2 шт', 'эластичные шнурки'] },
      { Цвет: ['черный'] },
      { ТНВЭД: ['5609000000'] },
    ],
    seo_characteristic_recommendation: [
      {
        Цвет: 0.1975325782,
        Модель: 0.0701793572,
        'Тип посадки': 0.0521327452,
        'Вид застежки': 0.1123499757,
        'Объем товара': 0.0505313131,
        'Тип карманов': 0.0737706686,
        'Срок годности': 0.1200463091,
        'Длина предмета': 0.0864543992,
        'Длина упаковки': 0.2631455185,
        'Высота предмета': 0.2290883709,
        'Высота упаковки': 0.2652380766,
        'Ширина предмета': 0.261103666,
        'Ширина упаковки': 0.2653176687,
        'Размер на модели': 0.0609171365,
        'Гарантийный срок': 0.0359356758,
        'Глубина предмета': 0.0808946391,
        'Материал изделия': 0.3125603847,
        'Вес с упаковкой (кг)': 0.06479123,
        'Материал подкладки': 0.0326556358,
        'Особенности модели': 0.1336496456,
        'Рост модели на фото': 0.0815779019,
        'Особенности шнурков': 0.1050112674,
        'Страна производства': 0.9706995444,
        'Особенности продукта': 0.0384427568,
        'Декоративные элементы': 0.2180346178,
        'Вес товара с упаковкой (г)': 0.3137756047,
        'Вес товара без упаковки (г)': 0.1151654165,
        'Пищевая ценность углеводы': 0.0335840289,
        'Действие косметики для обуви': 0.0409372524,
        'Количество предметов в упаковке': 0.2166933599,
        'Минимальная температура хранения': 0.0364470814,
        'Параметры модели на фото (ОГ-ОТ-ОБ)': 0.0584373816,
        'Максимальная температура хранения': 0.0396972056,
        'Назначение косметики для обуви/одежды': 0.0412541535,
        'Энергетическая ценность калории (на 100 гр.)': 0.0329946738,
      },
    ],
    seo_characteristic_core: -32,
    seo_value_characteristics: [
      'назначение косметики для обуви/одежды',
      'тип карманов',
      'ширина упаковки',
      'длина упаковки',
      'энергетическая ценность калории (на 100 гр.)',
      'тип посадки',
      'особенности шнурков',
      'длина предмета',
      'особенности продукта',
      'вес товара без упаковки (г)',
      'максимальная температура хранения',
      'ширина предмета',
      'количество предметов в упаковке',
      'срок годности',
      'вид застежки',
      'модель',
      'размер на модели',
      'высота предмета',
      'пищевая ценность углеводы',
      'высота упаковки',
      'декоративные элементы',
      'параметры модели на фото (ог-от-об)',
      'действие косметики для обуви',
      'рост модели на фото',
      'особенности модели',
      'материал подкладки',
      'минимальная температура хранения',
      'гарантийный срок',
      'вес товара с упаковкой (г)',
      'вес с упаковкой (кг)',
      'глубина предмета',
      'объем товара',
    ],
  },
];

const regionsTable = [
  {
    oblast: 'РОСТОВСКАЯ ОБЛАСТЬ',
    country_name: 'РОССИЯ',
    previous_week_orders: 80,
    current_week_orders: 57,
    percent_change: -28.749999999999996,
  },
  {
    oblast: 'РЕСПУБЛИКА СЕВЕРНАЯ ОСЕТИЯ — АЛАНИЯ',
    country_name: 'РОССИЯ',
    previous_week_orders: 14,
    current_week_orders: 2,
    percent_change: -85.71428571428571,
  },
  {
    oblast: 'КАРАЧАЕВО-ЧЕРКЕССКАЯ РЕСПУБЛИКА',
    country_name: 'РОССИЯ',
    previous_week_orders: 10,
    current_week_orders: 1,
    percent_change: -90,
  },
  {
    oblast: 'ЧЕЛЯБИНСКАЯ ОБЛАСТЬ',
    country_name: 'РОССИЯ',
    previous_week_orders: 84,
    current_week_orders: 51,
    percent_change: -39.285714285714285,
  },
];

const recommendations = {
  user_warehouses: [
    {
      date: '2024-02-15',
      warehouse_name: 'Ямышева',
      recommended_warehouse_name: 'Академика 11',
    },
    {
      date: '2024-02-16',
      warehouse_name: 'Сибирьский тракт',
      recommended_warehouse_name: 'Гоголя',
    },
    {
      date: '2024-02-18',
      warehouse_name: 'Сибирьский тракт',
      recommended_warehouse_name: 'Гоголя',
    },
    {
      date: '2024-02-18',
      warehouse_name: 'Ямышева',
      recommended_warehouse_name: 'Академика 11',
    },
  ],
  all_warehouses: [],
};

const badReviewsReasons = [
  {
    product_name: 'Калимба 17 нот прозрачная музыкальный инструмент акрил ',
    image: 'https://basket-04.wbbasket.ru/vol514/part51458/51458358/images/big/1.jpg',
    nm_id: 51458358,
    rating: '4.86',
    conclusion: [
      '1. Проблемы с настройкой инструмента молоточком. ',
      '2. При игре на калимбе возникает боль в пальцах, тяжело играть без напальчников.',
      '3. Некоторые клиенты получили бракованный товар (дефект ушках).',
      '4. При игре на инструменте некоторые язычки звучат неадекватно, в особенности крайние.',
      '5. При использовании приложения для настройки звучанию не соответствует зеленый цвет на шкале.',
    ],
  },
  {
    product_name: 'Измельчитель электрический кухонный блендер для овощей',
    image: 'https://basket-13.wbbasket.ru/vol1970/part197044/197044878/images/big/1.jpg',
    nm_id: 197044878,
    rating: '4.7',
    conclusion: [
      '1. Проблемы с функционалом: перестает работать, не измельчает продукты, возникновение сбоев.',
      '2. Проблемы с качеством материалов: покоцанная при получении, быстрое износ.',
      '3. Проблемы с крышкой: постоянно открывается, не защелкивается.',
      '4. Проблема с возвратом: не успевает вернуть товар в срок или сложности с процессом возврата.',
      '5. Ошибка при отправке: получили не тот товар, который заказывали.',
    ],
  },
  {
    product_name: 'Набор разделочных досок на подставке',
    image: 'https://basket-12.wbbasket.ru/vol1689/part168923/168923000/images/big/1.jpg',
    nm_id: 168923000,
    rating: '4.7',
    conclusion: [
      '1. Некачественная упаковка товара со стороны продавца.',
      '2. Доски приходят сломанными или с трещинами.',
      '3. Отсутствие согласованности за заказанным и полученным товаром (заказовали овальные, пришли квадратные доски).',
      '4. Подставка для досок также приходит в сломанном состоянии.',
      '5. При обработке пищи, доски царапаются или образуются сколы.',
    ],
  },
];

const aiAutoanswersInfo = {
  isActive: true,
  answeredNum: 35,
  unansweredNum: 9,
  stars: ['1', '2', '3', '4', '5'],
  samplesLoaded: true,
  feedbacksLoaded: true,
};

const cardsImagesInfo = [
  {
    nm_id: 51458358,
    product_name: 'Калимба 17 нот прозрачная музыкальный инструмент акрил ',
    photo_count_db: 9,
    first_photo_url: 'https://basket-04.wbbasket.ru/vol514/part51458/51458358/images/big/1.jpg',
    photo_count_api: 12,
    video_count_db: 1,
  },
  {
    nm_id: 197044878,
    product_name: 'Измельчитель электрический кухонный блендер для овощей',
    photo_count_db: 7,
    first_photo_url: 'https://basket-13.wbbasket.ru/vol1970/part197044/197044878/images/big/1.jpg',
    photo_count_api: 8,
    video_count_db: 0,
  },
  {
    nm_id: 168923000,
    product_name: 'Набор разделочных досок на подставке',
    photo_count_db: 10,
    first_photo_url: 'https://basket-12.wbbasket.ru/vol1689/part168923/168923000/images/big/1.jpg',
    photo_count_api: 10,
    video_count_db: 1,
  },
  {
    nm_id: 51457192,
    product_name: 'Калимба 17 нот музыкальный инструмент акрил ',
    photo_count_db: 8,
    first_photo_url: 'https://basket-04.wbbasket.ru/vol514/part51457/51457192/images/big/1.jpg',
    photo_count_api: 11,
    video_count_db: 1,
  },
];

const promotions = {
  goingAndGood: [
    {
      brand: 'tovar1001',
      item: 'Сковороды',
      supplier_article: 'Frying pan 24',
      wb_article: '146850578',
      last_barcode: '2037476826337',
      turnover: 0,
      wb_stock_balance: 0,
      seller_stock_balance: 0,
      planned_action_price: 1831,
      current_retail_price: 2579,
      current_discount: 24,
      discount_for_action: 29,
      start_date: '16 февраля',
      end_date: '29 февраля',
      name: 'Подарки',
    },
  ],
  goingAndBad: [
    {
      brand: 'tovar1001',
      item: 'Сковороды',
      supplier_article: 'Frying pan with removable handle 18',
      wb_article: '146868505',
      last_barcode: '2037477186379',
      turnover: 6,
      wb_stock_balance: 0,
      seller_stock_balance: 0,
      planned_action_price: 1672,
      current_retail_price: 2091,
      current_discount: 28,
      discount_for_action: 28,
      start_date: '16 февраля',
      end_date: '29 февраля',
      name: 'Подарки',
    },
  ],
  notGoingAndBad: [
    {
      brand: 'tovar1001',
      item: 'Сковороды',
      supplier_article: 'Frying pan with removable handle 28',
      wb_article: '146868512',
      last_barcode: '2037477272461',
      turnover: 71,
      wb_stock_balance: 12,
      seller_stock_balance: 0,
      planned_action_price: 2179,
      current_retail_price: 2753,
      current_discount: 21,
      discount_for_action: 21,
      start_date: '16 февраля',
      end_date: '29 февраля',
      name: 'Подарки',
    },
  ],
  length: 3,
};

const predictedSales = [
  {
    product_name: 'Калимба 17 нот прозрачная музыкальный инструмент акрил ',
    product_price: 1426.9,
    recommended_price: 1142,
    upper_price: 1244.78,
    lower_price: 1039.22,
    product_discount: 62,
    image: 'https://basket-04.wbbasket.ru/vol514/part51458/51458358/images/big/1.jpg',
    nm_id: 51458358,
    price_status: 'Завышенная цена',
    rating: '4.86',
  },
  {
    product_name: 'Измельчитель электрический кухонный блендер для овощей',
    product_price: 1653,
    recommended_price: 1270,
    upper_price: 1409.7,
    lower_price: 1130.3,
    product_discount: 0,
    image: 'https://basket-13.wbbasket.ru/vol1970/part197044/197044878/images/big/1.jpg',
    nm_id: 197044878,
    price_status: 'Завышенная цена',
    rating: '4.7',
  },
  {
    product_name: 'Набор разделочных досок на подставке',
    product_price: 765.14,
    recommended_price: 941,
    upper_price: 1006.87,
    lower_price: 875.13,
    product_discount: 62,
    image: 'https://basket-12.wbbasket.ru/vol1689/part168923/168923000/images/big/1.jpg',
    nm_id: 168923000,
    price_status: 'Заниженная цена',
    rating: '4.7',
  },
  {
    product_name: 'Набор разделочных досок на подставке',
    product_price: 965.14,
    recommended_price: 941,
    upper_price: 1006.87,
    lower_price: 875.13,
    product_discount: 62,
    image: 'https://basket-12.wbbasket.ru/vol1689/part168923/168923000/images/big/1.jpg',
    nm_id: 168923000,
    price_status: 'Нормальная цена',
    rating: '4.7',
  },
  {
    product_name: 'Калимба 17 нот музыкальный инструмент акрил ',
    product_price: 1277.1,
    recommended_price: 1266,
    upper_price: 1354.62,
    lower_price: 1177.38,
    product_discount: 58,
    image: 'https://basket-04.wbbasket.ru/vol514/part51457/51457192/images/big/1.jpg',
    nm_id: 51457192,
    price_status: 'Нормальная цена',
    rating: '4.87',
  },
];

const availability = [
  {
    wb_product: {
      nm_id: 75303998,
      name: 'Кроссовки из ткани',
      photo: 'https://basket-05.wbbasket.ru/vol753/part75303/75303998/images/big/1.jpg',
    },
    warehouse: 'Санкт-Петербург',
    amount: 12,
    days: 30,
    recommend: 8,
    less_than_14: false,
    less_than_30: false,
    more_than_30: false,
    out_of_stock: true,
  },
  {
    wb_product: {
      nm_id: 75303998,
      name: 'Кроссовки из ткани',
      photo: 'https://basket-05.wbbasket.ru/vol753/part75303/75303998/images/big/1.jpg',
    },
    warehouse: 'Санкт-Петербург',
    amount: 12,
    days: 30,
    recommend: 8,
    less_than_14: false,
    less_than_30: false,
    more_than_30: false,
    out_of_stock: true,
  },
];

export default {
  financeInfo,
  logisticInfo,
  reputatioSalesDynamic,
  reputationInfo,
  seoRecomendations,
  turnoverDynamics,
  regionsTable,
  recommendations,
  badReviewsReasons,
  aiAutoanswersInfo,
  cardsImagesInfo,
  promotions,
  predictedSales,
  availability,
};
