import axios from 'axios'
import API_ENV from '@/config/api.config'

let { apiAlerts } = API_ENV

const apiAllNotifications = `${apiAlerts}notifications/`

export default {
  state: {
    notifications: [],
    hasNewNotifications: null,
  },
  getters: {
    allNotifications: state => state.notifications,
    hasNewNotifications: state => state.hasNewNotifications
  },
  mutations: {
    setNotifications(state, notifications) {
      state.notifications = notifications
    },
    setHasNewNotifications(state, hasNewNotifications) {
      state.hasNewNotifications = hasNewNotifications
    }
  },
  actions: {
    getNotifications({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(apiAllNotifications)
          .then(response => {
            const data = response.data

            commit('setNotifications', data.notifications)
            commit('setHasNewNotifications', data.has_new_notifications)

            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    readAllNotifications({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${apiAllNotifications}read/`)
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

  }
}