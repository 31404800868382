<template>
	<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M4.66602 9.66667H1.33268V13M7.33268 4.33333H10.666V1M1.05469 5.00227C1.42848 4.07711 2.05431 3.27536 2.86105 2.68815C3.66779 2.10094 4.62392 1.75168 5.61918 1.68034C6.61445 1.609 7.60917 1.81827 8.49142 2.28441C9.37366 2.75055 10.1068 3.45503 10.6088 4.31742M10.9444 8.99805C10.5706 9.92321 9.94476 10.725 9.13802 11.3122C8.33128 11.8994 7.37613 12.2482 6.38086 12.3195C5.38559 12.3909 4.39006 12.1816 3.50781 11.7155C2.62557 11.2494 1.8919 10.545 1.38997 9.68262"
			:stroke="color"
			stroke-width="1.66667"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>

<script>
export default {
	name: 'RefreshArrows',
	props: {
		color: {
			type: String,
			default: '#039BE5'
		}
	}
};
</script>
