<template>
	<svg :width="width" :height="width" viewBox="0 0 16 16" :fill="color" xmlns="http://www.w3.org/2000/svg" :class="{ rotate }" @click="$emit('click')">
		<path d="M7.84358 15.9992C7.64057 15.9992 7.44587 15.9191 7.30232 15.7766C7.15877 15.634 7.07813 15.4407 7.07812 15.2391V1.19178C7.07812 0.990175 7.15877 0.796832 7.30232 0.654279C7.44587 0.511726 7.64057 0.431641 7.84358 0.431641C8.04659 0.431641 8.24128 0.511726 8.38483 0.654279C8.52838 0.796832 8.60903 0.990175 8.60903 1.19178V15.2391C8.60903 15.4407 8.52838 15.634 8.38483 15.7766C8.24128 15.9191 8.04659 15.9992 7.84358 15.9992Z"/>
		<path d="M14.9188 8.97535H0.773263C0.570253 8.97535 0.375558 8.89526 0.232008 8.75271C0.088458 8.61016 0.0078125 8.41681 0.0078125 8.21521C0.0078125 8.01361 0.088458 7.82027 0.232008 7.67772C0.375558 7.53516 0.570253 7.45508 0.773263 7.45508H14.9188C15.1218 7.45508 15.3165 7.53516 15.46 7.67772C15.6036 7.82027 15.6842 8.01361 15.6842 8.21521C15.6842 8.41681 15.6036 8.61016 15.46 8.75271C15.3165 8.89526 15.1218 8.97535 14.9188 8.97535Z"/>
	</svg>
</template>

<script>
export default {
	name: 'PlusIcon',
	props: {
		width: {
			type: Number | String,
			default: 16
		},
		rotate: {
			type: Boolean,
			default: false
		},
		color: {
			type: String,
			default: '#3A416F'
		}
	}
}
</script>

<style lang="scss" scoped>
.rotate {
	transform: rotate(45deg);
}
</style>
