<template>
  <div :class="{ empty: !wbSettingsTokens.length }" class="wbCabinets box" data-v-step="2">
    <div class="wbCabinets__header wrapper">
      <div class="wbCabinets__wrap wrapper">
        <div class="wbCabinets__header-wrapper">
          <h4 class="wbCabinets__title">Кабинеты WB</h4>
          <CInformation v-if="!wbSettingsTokens.length" :htmlText="textForAddCabinet"/>
        </div>
        <div class="wbCabinets__wrapper wrapper">
          <!-- {{userInfo?.current_tariff?.tariff}} -->
          <button
            v-if="userInfo?.current_tariff?.tariff?.title !== 'S' || $store.getters.getGlobalTourActive"
            data-v-step="1"
            :class="{ empty: !wbSettingsTokens.length }"
            @click="createNewCabinet"
            class="wbCabinets__button button primary-outline-button"
            style="padding: 10px"
          >
            <span v-if="!wbSettingsTokens.length">Добавить кабинет</span>
            <Plus v-else color="#497ADA" style="scale: 0.8" />
          </button>
          <!-- Видео скрыто на данный момент  -->
          <CVideoInstruction
            v-if="false"
            ref="videoButton"
            class="wbCabinets__videoBtn"
            name="tokens.mp4"
            poster="tokens.png"
          />
        </div>
      </div>
    </div>
    <CDropdownBlock
      v-for="(cabinet, index) in wbSettingsTokens"
      :opened="index === 0"
      :key="cabinet.id"
      class="cabinet"
      @click.stop
      initialyClosed
    >
      <template #title>
        <h3>{{ cabinet.name }}</h3>
         <div
          v-if="(
          cabinet.is_cabinet_done || 
          ( Object.hasOwn(cabinet,'isCreating') && !cabinet.isCreating))"

          class="tooltip tooltip__header"
          @mouseover="setActiveTooltip(index)"
          @mouseout="setActiveTooltip(null)"
        >
          <svg
            v-if="cabinet.api_loading_status === 100"
            class="cabinet__svg"
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.5 17.8824C4.87741 17.8824 1.11765 14.1226 1.11765 9.5C1.11765 4.87741 4.87741 1.11765 9.5 1.11765C14.1226 1.11765 17.8824 4.87741 17.8824 9.5C17.8824 14.1226 14.1226 17.8824 9.5 17.8824ZM9.5 0C4.26159 0 0 4.26159 0 9.5C0 14.7384 4.26159 19 9.5 19C14.7384 19 19 14.7384 19 9.5C19 4.26159 14.7384 0 9.5 0ZM9.16155 10.752C8.77103 11.1425 8.13786 11.1425 7.74734 10.752L6.16495 9.16963C5.94674 8.95142 5.59297 8.95142 5.37476 9.16963V9.16963C5.15656 9.38783 5.15656 9.74161 5.37477 9.95981L7.74734 12.3324C8.13786 12.7229 8.77103 12.7229 9.16155 12.3324L13.9974 7.49651C14.2156 7.27831 14.2156 6.92453 13.9974 6.70633V6.70633C13.7792 6.48812 13.4254 6.48813 13.2072 6.70633L9.16155 10.752Z"
              fill="#72C849"
            />
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M10 14.99V15M10 5V12M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
              stroke="#E11414"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <transition name="modalFade">
            <div class="tooltip__modal" v-show="activeTooltip === index">
              <p
                v-if="cabinet.api_loading_status === 100"
                class="tooltip__text"
              >
                Кабинет готов к работе!
              </p>
              <p
                v-else-if="
                  cabinet.api_loading_status !== 100 && 
                  cabinet.api_loading_warning_list &&
                  cabinet.api_loading_warning_list?.length && !cabinet.isCreating
                "
                class="tooltip__text"
              >
                <ul class="tooltip__data">
                  <li v-for="(item, index) of cabinet.api_loading_warning_list" :key="index">{{ item.detail }} : {{item.error}}</li>
                </ul>
              </p>
              <p
                v-else-if="
                  cabinet.api_loading_status !== 100 && 
                  cabinet.is_valid_cabinet_token === false &&
                  cabinet.api_loading_errors_list?.length
                "
                class="tooltip__text"
              >
                <ul class="tooltip__data">
                  <li v-for="(item, index) of cabinet.api_loading_errors_list" :key="index">{{item.error}}</li>
                </ul>
              </p>
            </div>
          </transition>
        </div>
      </template>
      <template v-if="cabinet.isCreating || !cabinet.is_cabinet_done">
        <WbCreateAccount :cabinet="cabinet" class="cabinet__create" @deleteCabinet="openConfirm"/> 
      </template>
      <template v-else>
        <div
        :class="{
          cabinet__wrapper:cabinet.api_loading_status < 100 && cabinet.is_valid_cabinet_tokens && cabinet.is_cabinet_done

        }"
      >
        <div class="cabinet-header wrapper">
          <h4>Настройки</h4>
          <a
            href="https://wiki.mamod.ai/ru/office/api"
            class="apiTokens__help"
            target="_blank"
            style="color: #497ada !important"
          >
            Как добавить API-токены?
          </a>
        </div>

        <div class="cabinet-tokensColumns">
          <div class="cabinet-tokensColumn">
            <div class="input-wrapper">
              <label for="name">Название кабинета</label>
              <input
                type="text"
                v-model="cabinet.name"
                class="input"
                id="name"
              />
            </div>
         
          </div>
          <div class="cabinet-tokensColumn">
            <div
              class="input-wrapper"
              v-for="(token, tokenName) in cabinet.apiTokens"
              :key="tokenName"
            >
              <div class="wrapper cabinet-tokensColumn__wrapper">
                <label class="cabinet-tokensColumn__label" :for="tokenName">{{
                  tokensName[tokenName]?.title
                }}</label>
                <CTooltip
                  v-if="tokensName[tokenName]?.tooltip"
                  :htmlText="tokensName[tokenName]?.tooltip"
                />
              </div>
              <input
                ref="apiToken"
                type="text"
                @click="removeTokenError({ cabinet, tokenName })"
                class="input"
                v-model="cabinet?.apiTokens[tokenName].title"
                :class="{ input__err: cabinet.apiTokens[tokenName].error }"
                :id="tokenName"
              />
            </div>
            <span class="cabinet-tokensColumn__span"><a class="link" href="https://seller.wildberries.ru/supplier-settings/access-to-api" target="_blank">Получить официальный токен Wildberries</a> </span>
          </div>
          <div class="cabinet-tokensColumn">
             <div data-v-step="4" class="input-wrapper">
              <label for="name">Коэф. товара в наличии</label>
              <input
                id="minimum_percent_for_stock_availability"
                type="number"
                class="input"
                v-model.number="cabinet.minimum_percent_for_stock_availability"
              />
              <CInformation text="При изменении коэффициента, пересчет параметров для планирования закупок будет произведен в течении суток"/>
            </div>
          </div>
        </div>
        <div class="cabinet-tokenButtons">
          <ButtonLoader
            data-v-step="5"
            :isLoading="loading.save"
            @click="saveFullChanges(cabinet.id)"
            class="button primary-button"
          >
            {{ saveValue }}
          </ButtonLoader>
          <ButtonLoader
            :isLoading="loading.remove"
            @click="openConfirm(cabinet.id)"
            class="button danger-outline-button delete-button"
          >
            Удалить кабинет
          </ButtonLoader>
        </div>
      </div>
      <div
        v-if="
            cabinet.api_loading_status < 100 && cabinet.is_valid_cabinet_tokens
        "
        class="cabinet__alert"
      >
        <CCabinetDataLoader
          :precent="
            cabinet.collecting_status_of_cookie_wb_tokens === 'LOADED'
              ? cabinet.api_loading_status
              : ''
          "
          :text="
            cabinet.collecting_status_of_cookie_wb_tokens === 'COLLECTING'
            ? `Наш автоматический ассистент собирает API-Токены с вашего кабинета на WB. Как правило это занимает около пяти минут. После завершения процесса, мы начнем автоматическую загрузку данных с кабинета.`
            : ''

          "
        />
      </div>
      </template>
    </CDropdownBlock>
    <!-- Модальное окно удаления кабинета -->
    <CModal
      :is-modal-visible="showConfirmModalRemove"
      modalTitle="Подтвердите удаление"
      @closeModal="closeConfirmModal()"
      class="modal"
    >
      <div class="pt-5">
        <p class="mb-5">Вы уверены, что хотите удалить кабинет?</p>
        <div class="modal__buttons d-flex">
          <CBtn
            @click="removeCabinet(selectedCabinetId)"
            :disabled="loading.remove"
          >
            <template v-if="loading.remove" v-slot:prepend>
              <CSpinner class="mr-3" />
            </template>

            Подтвердить
          </CBtn>
          <CBtn class="ml-2" variant="default" @click="closeConfirmModal()">
            Отмена
          </CBtn>
        </div>
      </div>
    </CModal>
    <!-- Модальное окно сохранения кабинета -->
    <CModal
      :is-modal-visible="showConfirmModal"
      modalTitle="Подтвердите сохранение"
      @closeModal="closeConfirmModal()"
      class="modal"
    >
      <div class="pt-5">
        <p class="mb-5">
          При изменении коэффициента, пересчет параметров для планирования
          закупок будет произведен в течении суток.
        </p>

        <div class="modal__buttons d-flex">
          <CBtn @click="saveChanges(selectedCabinet)" :disabled="loading.save">
            <template v-if="loading.save" v-slot:prepend>
              <CSpinner class="mr-3" />
            </template>

            Подтвердить
          </CBtn>
          <CBtn class="ml-2" variant="default" @click="closeConfirmModal()">
            Отмена
          </CBtn>
        </div>
      </div>
    </CModal>
    <CModal
      :is-modal-visible="showConfirmModal"
      modalTitle="Подтвердите сохранение"
      @closeModal="closeConfirmModal()"
    >
      <div class="pt-5">
        <p class="mb-5">
          При изменении коэффициента, пересчет параметров для планирования
          закупок будет произведен в течении суток.
        </p>

        <div class="d-flex">
          <CBtn
            @click="saveChanges(selectedCabinet)"
            :disabled="loading.save"
          >
            <template v-if="loading.save" v-slot:prepend>
              <CSpinner class="mr-3" />
            </template>

            Подтвердить
          </CBtn>
          <CBtn class="ml-2" variant="default" @click="closeConfirmModal()">
            Отмена
          </CBtn>
        </div>
      </div>
    </CModal>
  </div>
</template>

<script>
import _ from "lodash";

import CDropdownBlock from "@/components/UI/CDropdownBlock.vue";
import CSwitch from "@/components/UI/CSwitch.vue";
import { Plus,Return,RobotIcon } from "@/components/UI/icons";
import ButtonLoader from "@/components/buttonLoader.vue";
import { mapActions, mapGetters } from "vuex";
import CModal from "@/components/UI/CModal.vue";
import CSpinner from "@/components/UI/CSpinner.vue";
import { CBtn } from "@/components/UI/CBtn";
import CVideoInstruction from "@/components/UI/CVideoInstruction.vue";
import CTooltip from "@/components/UI/CTooltip.vue";
import CCabinetDataLoader from "@/components/UI/CCabinetDataLoader.vue";
import WbCreateAccount from "./WbCreateAccount.vue";
import CInformation from "@/components/UI/CInformaition.vue"
export default {
  name: "PersonalAccountWbCabinets",
  components: {
    Plus,
    RobotIcon,
    CDropdownBlock,
    CSwitch,
    ButtonLoader,
    CModal,
    CSpinner,
    CBtn,
    CVideoInstruction,
    CTooltip,
    CCabinetDataLoader,
    WbCreateAccount,
    CInformation
  },
  data: () => ({
    saveValue: "Внести изменения",
    activeTooltip: null,
    isConfirmLoading: false,
    showConfirmModal: false,
    showConfirmModalRemove: false,
    selectedCabinet: null,
    selectedCabinetId: null,
    loading: {
      cookie: false,
      remove: false,
      save: false,
      add: false,
    },
    tokensName: {
      open_api_standart: {
        title: "API-токен «Стандартный»",
        tooltip:
          "Введите API-токен полученный в вашем кабинете WB и сохраните его. <a class='link' href='https://wiki.mamod.ai/ru/office/api'>Как получить токен</a>?",
      },
    },
    
  }),

  methods: {
    ...mapActions([
      "getWbToken",
      "editWbTokens",
      "removeWbTokens",
      "checkWbTokens",
      "createWbTokens",
      "editTokensError",
      "checkMainApiToken",
      'patchCabinet'
    ]),
    setActiveTooltip(index) {
      this.activeTooltip = index;
    },
    updateTokens(obj) {
      this.$refs.apiToken[0].classList.add("backlit");

      const { id } = obj;
      const requiredEl = this.wbSettingsTokens.find((el) => el.id === id);
    },
    startVideo() {
      // this.$refs.videoButton.modal = true;
    },
    openConfirm(id) {
      console.log(id)
      this.selectedCabinetId = id;
      this.showConfirmModalRemove = true;
    },
    removeTokenError(obj) {
      this.$refs.apiToken[0].classList.remove("backlit");

      const { tokenName } = obj;
      this.editTokensError({
        id: obj.cabinet.id,
        item: [tokenName, ""],
      });
    },
    closeConfirmModal() {
      this.selectedCabinet = null;
      this.showConfirmModal = false;
      this.showConfirmModalRemove = false;
    },
    createNewCabinet() {
      this.createWbTokens();
    },

    async saveFullChanges(id) {
      try {
        this.loading.save = true;
        const {name, apiTokens,open_api_standart, minimum_percent_for_stock_availability} = this.wbSettingsTokens.find(item=>item.id === id)
        const data = {
          id,
          name,
          minimum_percent_for_stock_availability
        }
        if(open_api_standart !==  apiTokens.open_api_standart.title) data.open_api_standart = apiTokens.open_api_standart.title
        await this.patchCabinet(data);
        // await this.checkCabinet(obj);
        this.saveValue = "Успешно сохранено!";
      } catch (error) {
        console.error('saveFullChanges',error);
        this.$store.commit('showAlert', 
         Object.values(Object.values(error.response.data)[0])[0],)
      } finally {
        this.loading.save = false;
        setTimeout(() => {
          this.saveValue = "Внести изменения";
        }, 2000);
      }
    },
    async removeCabinet(id) {
      try {
        this.loading.remove = true;
        await this.removeWbTokens(id);
        this.$emit("removeTokens", true);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading.remove = false;
        this.showConfirmModalRemove = false;
      }
    },
  },
  computed: {
    ...mapGetters(["wbSettingsTokens",'userInfo']),
    textForAddCabinet(){
      return this.userInfo?.current_tariff?.tariff?.title === 'S' ? 'Для использования полного функционала, добавьте Кабинет Wildberries. Чтобы добавить кабинет приобретите любой <a href="/rates" class="link">тариф</a>' : 'Для использования полного функционала, Вам необходимо добавить Кабинет Wildberries.'
    },
    cabinetButtons(){
      return [
        {
          name:this.saveValue,
          loading: this.loading.save,
          action:(cabinet)=>{
            this.saveFullChanges(cabinet)
          },
          class:'primary-button',
          step:'5'
        },{
          name:'Удалить кабинет',
          loading: this.loading.remove,
          action:(cabinet)=>{
            this.openConfirm(cabinet.id)
          },
          class:'danger-outline-button delete-button'
        }
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
.tooltip {
  background: transparent;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &__header{
    margin-left: auto;
  }
  &__modal {
    position: absolute;
    bottom: 30px;
    padding: 5px 10px;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 10px 70px rgba(206, 208, 213, 0.7);
    z-index: 101;
    display: flex;
    justify-content: center;
    min-width: 320px;
    align-items: center;

    @media (max-width: 492px) {
      right: 0;
    }
  }
  &__data{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  &__text {
    font-size: 12px;
    line-height: 21px;
    text-align: center;
    color: rgba(58, 65, 111, 0.8);
    font-weight: 500;
  }
}
.wbCabinets {
  padding: 20px;
  &__videoBtn {
    margin-left: 0;
  }
  &__wrap {
    width: 100%;
  }
  &__header {
    width: 100%;
    &-wrapper {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }
  &__title {
    width: 100%;
  }
  &.empty {
    box-shadow: 0 0 12px #3586ff;
  }
  &__button {
    &.empty {
      padding: 10px;
      font-size: 13px;
      width: 200px;
      padding: 12px 10px;
    }
  }
  &__wrapper {
    justify-content: flex-end;
    gap: 10px;
  }
  &__alert {
    // display: flex;
    // align-items: center;
    font-size: 15px;
    margin-right: 20px;

    &-link {
      color: #8caae8 !important;
      margin-left: 3px;
    }

    svg {
      float: left;
    }
  }
  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  .dropdown header.open {
    padding-bottom: 0;
    border-bottom: none;
  }

  .cabinet-tokensColumns,
  .cabinet-tokenButtons {
    margin: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    & > div,
    & > .button {
      flex: 1;
      min-width: 150px;
    }

    .input-wrapper + .input-wrapper {
      margin-top: 20px;
    }

    label {
      opacity: 0.6;
    }

    .button {
      width: 100%;
    }

    .delete-button {
      background: transparent;
      border-radius: 8px;
      border: 1px solid rgba(223, 83, 83, 0.2);
    }
  }

  .cabinet{
    &-tokenButtons{
      flex-wrap: nowrap;
      gap:5px;
      margin-left: auto;
      width: 50%;
      font-size: 10px;
      margin-top: 40px;
      justify-content: flex-end;
    }
  }
  &__status{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:15px;
    margin-top: 57px;
    &-text{
      font-size: 13px;
    }
  }
}
.cabinet{
  &-tokensColumn {
    &__span{
      font-size: 12px;
      line-height: 18px;
      padding: 10px;
      border-radius: 6px;
      margin-top: 26px;
    }
    &__wrapper {
      justify-content: flex-start;
      gap: 10px;
      margin-bottom: 8px;
    }
    &__label {
      margin-bottom: 0;
    }
  }
  &__create{
    min-height: 300px;
  }
}
.modal {
  &__buttons {
    justify-content: flex-end;
  }
}
.backlit {
  box-shadow: 0 0 10px #3586ff;
}
@media screen and (max-width:1300px) {
  .wbCabinets{
    &__status{
      align-items: flex-start;
      &-text{
        font-size: 11px;
      }
    }
  }
}

@media screen and (max-width:492px) {
  .wbCabinets{
    .cabinet{
      &-tokenButtons{
        flex-wrap: wrap;
        flex-direction: column;
        margin: 20px;
        .button{
          max-width: none;
        }
        }
      }
  }
  
}
</style>

<style lang="scss">
.cabinet{
  .dropdown__arrows{
    margin-left: auto !important;
  }
    &-tokenButtons{
      .slot{
        font-size: 10px;
      }
    }
}
.dropdown-block {
  position: relative;
}
.cabinet {
  background: #f6f8fa !important;
  margin: 20px 0;

  &-header {
    background: #fff;
    border-radius: 13px;
    margin: 20px 10px;
    padding: 15px 20px;

    a {
      text-align: right;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
      letter-spacing: -0.174px;
      text-decoration-line: underline;
    }

    .dropdown__arrows{
      margin-left: auto !important;
    }
  }

  &-tokensColumn {
    .input {
      background: #fff;
      &__error {
        color: #d94646;
      }
      &__err {
        border: 1px solid #d94646;
        animation: shake 0.5s;
      }
    }
  }
  &__wrapper {
    filter: blur(10px) grayscale(20%);
  }
  &__alert {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &-box {
      background: #fff;
      align-items: flex-start;
      gap: 15px;
      max-width: 50%;
    }
    &-button {
      align-self: center;
    }
  }
}





</style>
