<template>
  <svg width="28" height="28">
    <circle r="12" cx="50%" cy="50%" class="track"></circle>
    <circle
      ref="progressCircle"
      r="12"
      cx="50%"
      cy="50%"
      class="progress"
      :class="{ done: precent === 100 }"
    ></circle>
    <text
      x="50%"
      y="50%"
      text-anchor="middle"
      dominant-baseline="middle"
      class="text"
    >
      {{ precent }}
    </text>
  </svg>
</template>

<script>
export default {
  props: {
    precent: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  methods: {
    setProgress() {
      const progressCircle = this.$refs.progressCircle;
      const radius = progressCircle.r.baseVal.value;
      const circumference = radius * 2 * Math.PI;
      progressCircle.style.strokeDasharray = circumference;
      progressCircle.style.strokeDashoffset =
        circumference - (this.precent / 100) * circumference;
    },
  },
  watch: {
    precent() {
      this.setProgress();
    },
  },
  mounted() {
    this.setProgress();
  },
};
</script>

<style lang="scss" scoped>
.track {
  stroke-width: 1;
  stroke: lightgrey;
  fill: none;
}
.text {
  font-size: 9px;
  color: #3a416f;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.15px;
}
.progress {
  stroke-width: 2;
  stroke: #497ada;
  stroke-linecap: round;
  fill: none;
  transform: rotate(270deg);
  transform-origin: center;
  transition: all 0.8s;
  &.done {
    stroke: #31c363;
  }
}
</style>
