import axios from 'axios';
import API_ENV from '@/config/api.config';

const { apiPersonalRoom, apiWbTokens, apiCheckWbTokens } = API_ENV;

export default {
  state: {
    personalInfo: null,
  },
  getters: {
    personalInfo: ({ personalInfo }) => personalInfo,
    sidebarPersonalInfo(state) {
      if (state.personalInfo) {
        const {
          user_info: { name, surname },
        } = state.personalInfo;

        console.log('state.personalInfo', state.personalInfo);
        const tariff = state.personalInfo?.current_tariff?.tariff.description;
        const tgToken = state.personalInfo?.settings?.tg_chat_id_assistant;
        return { name, surname, tariff, tgToken };
      }
    },
  },
  mutations: {
    setPersonalInfo(state, data) {
      state.personalInfo = data;
    },
    changeName(state, { name, surname }) {
      state.personalInfo.name = name;
      state.personalInfo.surname = surname;
    },
  },
  actions: {
    async getPersonalInfo({ commit }) {
      try {
        const { data } = await axios.get(apiPersonalRoom);
        commit('setPersonalInfo', JSON.parse(JSON.stringify(data)));
        console.log('getPersonalInfodata', data);
        return data;
      } catch (error) {
        console.error(new Error(error));
      }
    },
    async changePassword({ commit }, passwords) {
      try {
        const { data } = await axios.patch(`${apiPersonalRoom}password/`, passwords);
        return data;
      } catch (error) {
        console.log('error', error.response?.data);
        commit('showAlert', error.response?.data?.error);
        throw error;
      }
    },
    changeUserInfo({ commit }, userInfo) {
      let { name, surname } = userInfo;
      commit('changeName', { name, surname });

      return new Promise((resolve, reject) => {
        axios
          .patch(`${apiPersonalRoom}`, userInfo)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getWbTokens({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(apiWbTokens)
          .then(res => {
            const tokens = res.data;

            const tokensEntries = Object.entries(tokens); // .filter(([key, _]) => key !== 'cookie_wild_auth_new_v3')
            const resolveData = {};

            const openApiTokens = tokensEntries.slice(1, 4);

            const wbTokens = tokensEntries.slice(4, 8);

            resolveData.openApiTokens = Object.fromEntries(openApiTokens);

            resolveData.wbTokens = Object.fromEntries(wbTokens);

            resolve(resolveData);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    changeTokens({ commit }, tokens) {
      return new Promise((resolve, reject) => {
        axios
          .patch(apiWbTokens, tokens)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            commit('showAlert');
            reject(err);
          });
      });
    },
    checkTokens({ commit }, tokens) {
      return new Promise((resolve, reject) => {
        axios
          .post(apiCheckWbTokens, tokens)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            commit('showAlert');
            reject(err);
          });
      });
    },
  },
};
