const Module = () => import('./Module.vue');
const List = () => import('./views/List.vue');
const Create = () => import('./views/Create.vue');
const CampaignPage = () => import('./views/CampaignPage.vue');
const Statistics = () => import('./views/Statistics.vue');

const mainRoutes = {
  path: '/ad-campaign',
  name: 'adCampaign',
  children: [
    {
      path: '',
      name: 'adCampaingList',
      component: List,
      breadName: 'Рекламные кампании',
    },
    {
      path: 'create',
      name: 'adCampaignCreatePage',
      component: Create,
      breadName: 'Создание рекламной кампании',
    },
    {
      path: ':type/:id',
      name: 'adCampaingPage',
      component: CampaignPage,
      breadName: 'Редактирование кампании',
    },
    {
      path: ':type/:id/:wbId/statistics',
      name: 'adCampaingStatistics',
      component: Statistics,
      breadName: 'Статистика кампании',
    },
  ],
  component: Module,
};

export default mainRoutes;
