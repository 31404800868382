<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="width"
    viewBox="0 0 21 21"
    fill="none"
  >
    <path
      d="M13.8333 5.50033V4.83366C13.8333 3.90024 13.8333 3.43353 13.6517 3.07701C13.4919 2.7634 13.2369 2.50844 12.9233 2.34865C12.5668 2.16699 12.1001 2.16699 11.1667 2.16699H9.83333C8.89991 2.16699 8.4332 2.16699 8.07668 2.34865C7.76308 2.50844 7.50811 2.7634 7.34832 3.07701C7.16667 3.43353 7.16667 3.90024 7.16667 4.83366V5.50033M8.83333 10.0837V14.2503M12.1667 10.0837V14.2503M3 5.50033H18M16.3333 5.50033V14.8337C16.3333 16.2338 16.3333 16.9339 16.0608 17.4686C15.8212 17.939 15.4387 18.3215 14.9683 18.5612C14.4335 18.8337 13.7335 18.8337 12.3333 18.8337H8.66667C7.26654 18.8337 6.56647 18.8337 6.03169 18.5612C5.56129 18.3215 5.17883 17.939 4.93915 17.4686C4.66667 16.9339 4.66667 16.2338 4.66667 14.8337V5.50033"
      :stroke="color"
      stroke-width="1.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'Basket2',
  props: {
    color: {
      type: String,
      default: '#DF5353',
    },
    width: {
      type: String | Number,
      default: 21,
    },
  },
};
</script>
