<template>
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.5781 8.08594C13.9665 8.08594 14.2812 7.77114 14.2812 7.38281C14.2812 6.99449 13.9665 6.67969 13.5781 6.67969C13.1898 6.67969 12.875 6.99449 12.875 7.38281C12.875 7.77114 13.1898 8.08594 13.5781 8.08594Z" fill="#3A416F"/>
    <path d="M15.1875 1.40625H14.2734V0.703125C14.2734 0.314789 13.9586 0 13.5703 0C13.182 0 12.8672 0.314789 12.8672 0.703125V1.40625H9.66797V0.703125C9.66797 0.314789 9.35318 0 8.96484 0C8.57651 0 8.26172 0.314789 8.26172 0.703125V1.40625H5.09766V0.703125C5.09766 0.314789 4.78287 0 4.39453 0C4.0062 0 3.69141 0.314789 3.69141 0.703125V1.40625H2.8125C1.26169 1.40625 0 2.66794 0 4.21875V15.1875C0 16.7383 1.26169 18 2.8125 18H8.19141C8.57974 18 8.89453 17.6852 8.89453 17.2969C8.89453 16.9085 8.57974 16.5938 8.19141 16.5938H2.8125C2.03709 16.5938 1.40625 15.9629 1.40625 15.1875V4.21875C1.40625 3.44334 2.03709 2.8125 2.8125 2.8125H3.69141V3.51562C3.69141 3.90396 4.0062 4.21875 4.39453 4.21875C4.78287 4.21875 5.09766 3.90396 5.09766 3.51562V2.8125H8.26172V3.51562C8.26172 3.90396 8.57651 4.21875 8.96484 4.21875C9.35318 4.21875 9.66797 3.90396 9.66797 3.51562V2.8125H12.8672V3.51562C12.8672 3.90396 13.182 4.21875 13.5703 4.21875C13.9586 4.21875 14.2734 3.90396 14.2734 3.51562V2.8125H15.1875C15.9629 2.8125 16.5938 3.44334 16.5938 4.21875V8.22656C16.5938 8.6149 16.9085 8.92969 17.2969 8.92969C17.6852 8.92969 18 8.6149 18 8.22656V4.21875C18 2.66794 16.7383 1.40625 15.1875 1.40625Z" fill="#3A416F"/>
    <path d="M13.7539 9.49219C11.4083 9.49219 9.5 11.4005 9.5 13.7461C9.5 16.0917 11.4083 18 13.7539 18C16.0995 18 18.0078 16.0917 18.0078 13.7461C18.0078 11.4005 16.0995 9.49219 13.7539 9.49219ZM13.7539 16.5938C12.1837 16.5938 10.9062 15.3163 10.9062 13.7461C10.9062 12.1759 12.1837 10.8984 13.7539 10.8984C15.3241 10.8984 16.6016 12.1759 16.6016 13.7461C16.6016 15.3163 15.3241 16.5938 13.7539 16.5938Z" fill="#3A416F"/>
    <path d="M14.7695 13.043H14.4531V12.3047C14.4531 11.9164 14.1383 11.6016 13.75 11.6016C13.3617 11.6016 13.0469 11.9164 13.0469 12.3047V13.7461C13.0469 14.1344 13.3617 14.4492 13.75 14.4492H14.7695C15.1579 14.4492 15.4727 14.1344 15.4727 13.7461C15.4727 13.3578 15.1579 13.043 14.7695 13.043Z" fill="#3A416F"/>
    <path d="M10.5156 8.08594C10.904 8.08594 11.2188 7.77114 11.2188 7.38281C11.2188 6.99449 10.904 6.67969 10.5156 6.67969C10.1273 6.67969 9.8125 6.99449 9.8125 7.38281C9.8125 7.77114 10.1273 8.08594 10.5156 8.08594Z" fill="#3A416F"/>
    <path d="M7.45312 11.1445C7.84145 11.1445 8.15625 10.8297 8.15625 10.4414C8.15625 10.0531 7.84145 9.73828 7.45312 9.73828C7.0648 9.73828 6.75 10.0531 6.75 10.4414C6.75 10.8297 7.0648 11.1445 7.45312 11.1445Z" fill="#3A416F"/>
    <path d="M4.39062 8.08594C4.77895 8.08594 5.09375 7.77114 5.09375 7.38281C5.09375 6.99449 4.77895 6.67969 4.39062 6.67969C4.0023 6.67969 3.6875 6.99449 3.6875 7.38281C3.6875 7.77114 4.0023 8.08594 4.39062 8.08594Z" fill="#3A416F"/>
    <path d="M4.39062 11.1445C4.77895 11.1445 5.09375 10.8297 5.09375 10.4414C5.09375 10.0531 4.77895 9.73828 4.39062 9.73828C4.0023 9.73828 3.6875 10.0531 3.6875 10.4414C3.6875 10.8297 4.0023 11.1445 4.39062 11.1445Z" fill="#3A416F"/>
    <path d="M4.39062 14.2031C4.77895 14.2031 5.09375 13.8883 5.09375 13.5C5.09375 13.1117 4.77895 12.7969 4.39062 12.7969C4.0023 12.7969 3.6875 13.1117 3.6875 13.5C3.6875 13.8883 4.0023 14.2031 4.39062 14.2031Z" fill="#3A416F"/>
    <path d="M7.45312 14.2031C7.84145 14.2031 8.15625 13.8883 8.15625 13.5C8.15625 13.1117 7.84145 12.7969 7.45312 12.7969C7.0648 12.7969 6.75 13.1117 6.75 13.5C6.75 13.8883 7.0648 14.2031 7.45312 14.2031Z" fill="#3A416F"/>
    <path d="M7.45312 8.08594C7.84145 8.08594 8.15625 7.77114 8.15625 7.38281C8.15625 6.99449 7.84145 6.67969 7.45312 6.67969C7.0648 6.67969 6.75 6.99449 6.75 7.38281C6.75 7.77114 7.0648 8.08594 7.45312 8.08594Z" fill="#3A416F"/>
  </svg>
</template>

<script>
export default {
  name: 'CalendarIcon',
}
</script>

<style lang="scss" scoped></style>
