<template>
	<span
		class="loader"
		:style="{ border: `${borderWidth}px solid ${color}`, width: `${width}px`, height: `${width}px` }"
	></span>
</template>
<script>
export default {
	name: 'AppSpinner',
	props: {
		color: { type: String, default: '#fff' },
		width: { type: String | Number, default: 12 },
		borderWidth: { type: String | Number, default: 2 },
	},
};
</script>
<style scoped>
.loader {
	border-bottom-color: transparent !important;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
	background:transparent;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
