import axios from 'axios';
import API_ENV from '@/config/api.config';
import queryString from 'query-string';
import downloadFile from '@/utils/downloadFile';

let {
  apiWarehouseDocument,
  apiWarehouseDocumentCategory,
  apiWarehouseForDoc,
  apiGetLastDateUploading,
  apiExcelImportDocumentProduct,
} = API_ENV;

export default {
  state: {
    lastDateUploading: null,
    documentProductsList: null,
    documentsList: null,
    currentDocumentCategory: null,
    documentCategories: [],
    stockList: [],
  },
  mutations: {
    setLastDateUploading(state, payload) {
      state.lastDateUploading = payload;
    },
    setStockDocumentProducts(state, payload) {
      state.documentProductsList = payload;
    },
    setStockDocumentProductsExample(state) {
      state.documentProductsList = [
        {
          id: -1,
          isExample: true,
          internal_article: { label: 'Товар', value: true },
          size: 'L',
          label: null,
          category: 163,
          product_count_in_warehouse: 0,
          product_count_in_document: 999,
          final_total: 999,
          photo: null,
          showCounter: true,
          documentType: 'Корректировка',
          disabled: false,
        },
      ];
    },
    removeStockDocumentProductsExample(state) {
      state.documentProductsList = state.documentProductsList.filter(el => !el.isExample);
    },
    calcFinalTotal(state, { params, val }) {
      for (let i = 0; i < state.documentProductsList.length; i++) {
        const element = state.documentProductsList[i];

        if (params.data.id !== element.id) continue;

        element.product_count_in_document = Number(val);

        if (params.data.documentType === 'Списание')
          element.final_total =
            element.product_count_in_warehouse - element.product_count_in_document;
        else
          element.final_total =
            element.product_count_in_warehouse + element.product_count_in_document;
      }
    },
    updateFinalTotal(state, { params, val }) {
      for (let i = 0; i < state.documentProductsList.length; i++) {
        const element = state.documentProductsList[i];

        if (params.data.id !== element.id) continue;

        element.final_total = Number(val);
        element.product_count_in_document =
          element.final_total - element.product_count_in_warehouse;
      }
    },
    updateStockDocumentProduct(state, payload) {
      state.documentProductsList = state.documentProductsList?.map(el => {
        if (payload.id === el.id) {
          el.product_count_in_document = payload.value;
          el.final_total = el.product_count_in_warehouse + payload.value;
        }

        return el;
      });
    },
    setStockDocumentProductsInternalArticle(state, payload) {
      state.documentProductsList = state.documentProductsList?.map(el => {
        if (el.id === payload.id) el.internal_article.value = payload.value;

        return el;
      });
    },
    setStockDocumentProductsShowCounter(state, payload) {
      state.documentProductsList = state.documentProductsList?.map(el => {
        if (el.id === payload.id) el.showCounter = payload.showCounter;

        return el;
      });
    },
    setStockDocuments(state, payload) {
      state.documentsList = payload;
    },
    setDocumentExample(state) {
      if (!state.documentsList?.length)
        state.documentsList = [
          {
            id: 246,
            title: 'Инвентаризация',
            isExample: true,
            warehouse: {
              id: 90,
              title: 'Alex',
              wh_individual_num: 1,
              is_active: true,
              purchase_plan_dangerous_stock_days: 5,
              purchase_plan_small_stock_days: 10,
              delivery_plan_dangerous_stock_days: 5,
              delivery_plan_small_stock_days: 10,
              stockindicationfordeliveryplanningonwb: [
                {
                  stock_name: 'Алексин',
                  coefficient: 0.3,
                  dangerous_stock_days: 10,
                  small_stock_days: 50,
                  okrug: null,
                  okrug_coefficient: 1,
                },
                {
                  stock_name: 'Астана',
                  coefficient: 0.08,
                  dangerous_stock_days: 10,
                  small_stock_days: 50,
                  okrug: null,
                  okrug_coefficient: 1,
                },
                {
                  stock_name: 'Вёшки',
                  coefficient: 0.3,
                  dangerous_stock_days: 10,
                  small_stock_days: 50,
                  okrug: null,
                  okrug_coefficient: 1,
                },
                {
                  stock_name: 'Домодедово',
                  coefficient: 0.3,
                  dangerous_stock_days: 10,
                  small_stock_days: 50,
                  okrug: null,
                  okrug_coefficient: 1,
                },
                {
                  stock_name: 'Екатеринбург',
                  coefficient: 0.08,
                  dangerous_stock_days: 10,
                  small_stock_days: 50,
                  okrug: null,
                  okrug_coefficient: 1,
                },
                {
                  stock_name: 'Иваново',
                  coefficient: 0.3,
                  dangerous_stock_days: 10,
                  small_stock_days: 50,
                  okrug: null,
                  okrug_coefficient: 1,
                },
                {
                  stock_name: 'Казань',
                  coefficient: 0.11,
                  dangerous_stock_days: 10,
                  small_stock_days: 50,
                  okrug: null,
                  okrug_coefficient: 1,
                },
                {
                  stock_name: 'Коледино',
                  coefficient: 0.3,
                  dangerous_stock_days: 10,
                  small_stock_days: 50,
                  okrug: null,
                  okrug_coefficient: 1,
                },
                {
                  stock_name: 'Краснодар',
                  coefficient: 0.13,
                  dangerous_stock_days: 10,
                  small_stock_days: 50,
                  okrug: null,
                  okrug_coefficient: 1,
                },
                {
                  stock_name: 'Крыловское',
                  coefficient: 0.13,
                  dangerous_stock_days: 10,
                  small_stock_days: 50,
                  okrug: null,
                  okrug_coefficient: 1,
                },
                {
                  stock_name: 'Минск',
                  coefficient: 0.1,
                  dangerous_stock_days: 10,
                  small_stock_days: 50,
                  okrug: null,
                  okrug_coefficient: 1,
                },
                {
                  stock_name: 'Невинномысск',
                  coefficient: 0.13,
                  dangerous_stock_days: 10,
                  small_stock_days: 50,
                  okrug: null,
                  okrug_coefficient: 1,
                },
                {
                  stock_name: 'Новосибирск',
                  coefficient: 0.12,
                  dangerous_stock_days: 10,
                  small_stock_days: 50,
                  okrug: null,
                  okrug_coefficient: 1,
                },
                {
                  stock_name: 'Нур-Султан',
                  coefficient: 0.12,
                  dangerous_stock_days: 10,
                  small_stock_days: 50,
                  okrug: null,
                  okrug_coefficient: 1,
                },
                {
                  stock_name: 'Обухово',
                  coefficient: 0.3,
                  dangerous_stock_days: 10,
                  small_stock_days: 50,
                  okrug: null,
                  okrug_coefficient: 1,
                },
                {
                  stock_name: 'Подольск',
                  coefficient: 0.3,
                  dangerous_stock_days: 10,
                  small_stock_days: 50,
                  okrug: null,
                  okrug_coefficient: 1,
                },
                {
                  stock_name: 'Пушкино',
                  coefficient: 0.3,
                  dangerous_stock_days: 10,
                  small_stock_days: 50,
                  okrug: null,
                  okrug_coefficient: 1,
                },
                {
                  stock_name: 'Санкт-Петербург',
                  coefficient: 0.11,
                  dangerous_stock_days: 10,
                  small_stock_days: 50,
                  okrug: null,
                  okrug_coefficient: 1,
                },
                {
                  stock_name: 'Тула',
                  coefficient: 0.3,
                  dangerous_stock_days: 10,
                  small_stock_days: 50,
                  okrug: null,
                  okrug_coefficient: 1,
                },
                {
                  stock_name: 'Хабаровск',
                  coefficient: 0.06,
                  dangerous_stock_days: 10,
                  small_stock_days: 50,
                  okrug: null,
                  okrug_coefficient: 1,
                },
                {
                  stock_name: 'Чехов',
                  coefficient: 0.3,
                  dangerous_stock_days: 10,
                  small_stock_days: 50,
                  okrug: null,
                  okrug_coefficient: 1,
                },
                {
                  stock_name: 'Электросталь',
                  coefficient: 0.3,
                  dangerous_stock_days: 10,
                  small_stock_days: 50,
                  okrug: null,
                  okrug_coefficient: 1,
                },
              ],
            },
            document_type: 'Корректировка',
            category: {
              id: 115,
              title: 'Инвентаризация',
              operation_type: 'Корректировка',
              doc_cat_individual_num: 1,
            },
            operation_date: '2023-10-15',
            status: 'Утвержден',
            doc_individual_id: 1,
            warehouseTitle: 'Мой склад',
            categoryTitle: 'Инвентаризация',
          },
        ];
    },
    removeDocumentExample(state) {
      state.documentsList = state.documentsList.filter(doc => !doc.isExample);
    },
    setDocumentCategories(state, payload) {
      state.documentCategories = null;
      state.documentCategories = payload.map(el => {
        el.isEdit = false;

        return el;
      });
    },
    setDocumentCategoriesExample(state) {
      if (!state.documentCategories?.length)
        state.documentCategories = [
          {
            isExample: true,
            id: 116,
            title: 'Категории складских документов 1',
            operation_type: 'Поступление',
            doc_cat_individual_num: 1,
          },
          {
            isExample: true,
            id: 86,
            title: 'Категории складских документов 2',
            operation_type: 'Корректировка',
            doc_cat_individual_num: 2,
          },
        ];
    },
    removeDocumentCategoriesExample(state) {
      state.documentCategories = state.documentCategories.filter(category => !category.isExample);
    },
    setIsEditDocumentCategories(state, id) {
      const category = state.documentCategories.find(el => el.id === id);
      let oldEditCategory = state.documentCategories.find(el => el.isEdit);

      if (category) {
        category.isEdit = true;
        category.old_title = category.title;

        if (oldEditCategory) oldEditCategory.isEdit = false;
      }
    },
    updateDocumentCategoriesValue(state, data) {
      state.documentCategories = state.documentCategories.map(el => {
        if (el.id === data.id) el.title = data.value;

        return el;
      });
    },
    setCurrentCategory(state, payload) {
      state.currentDocumentCategory = payload;
    },
    setStockList(state, payload) {
      state.stockList = payload;
    },
  },
  actions: {
    async unlockArchive() {
      try {
        const result = await axios.get(apiWarehouseDocument + 'unlock_archive');

        return result.data;
      } catch (error) {
        console.error('unlockArchive error: ', new Error(error));
        throw error;
      }
    },
    async confirmUnlockArchive({ commit }, code) {
      try {
        const result = await axios.post(apiWarehouseDocument + 'unlock_archive', {
          archive_code: code,
        });

        return result.data;
      } catch (error) {
        console.error('unlockArchive error: ', new Error(error));
        throw error;
      }
    },
    async getLastDateUploading({ commit }) {
      try {
        const result = await axios.get(apiGetLastDateUploading);

        commit('setLastDateUploading', result.data.date);

        return result.data;
      } catch (error) {
        console.error('Error getLastDateUploading: ', new Error(error));
      }
    },
    async getStockDocumentProducts({ commit }, data) {
      const query = queryString.stringify(data);
      try {
        const result = await axios.get(`${apiWarehouseDocument + data.id}/products`);
        const newResult = result.data.map(el => {
          el.internal_article = {
            label: el.internal_article,
            value: !!el.product_count_in_document,
          };
          el.showCounter = !!el.product_count_in_document;
          return el;
        });

        commit('setStockDocumentProducts', newResult);

        return newResult;
      } catch (error) {
        console.error('addDocument: ', new Error(error));
      }
    },
    async updateProductInStockDocument({ commit }, payload) {
      try {
        const docId = payload.docId;
        delete payload.docId;
        const req = payload;

        if (payload.count_in_document === 0) {
          const result = await axios.delete(
            `${apiWarehouseDocument + docId}/products/${req.product}`,
            req,
          );

          return true;
        }

        if (payload.old_count_in_document === 0) {
          const result = await axios.post(`${apiWarehouseDocument + docId}/products/`, req);

          return true;
        }

        if (payload.old_count_in_document !== payload.count_in_document) {
          const result = await axios.patch(
            `${apiWarehouseDocument + docId}/products/${req.product}`,
            req,
          );

          return true;
        }

        return false;
      } catch (error) {
        console.error(new Error(error));
      }
    },
    async addMultipleProductsInDoc({ commit }, payload) {
      try {
        const docId = payload.docId;
        delete payload.docId;
        const request = payload.products.map(product => ({
          ...product,
          count_in_document: Math.round(product.count_in_document),
        }));
        const result = await axios.post(`${apiWarehouseDocument + docId}/products/many/`, request);

        return result;
      } catch (error) {
        console.error(new Error(error));
      }
    },
    async updateMultipleProductsInDoc({ commit }, payload) {
      try {
        const docId = payload.docId;
        delete payload.docId;
        const request = payload.products.map(product => ({
          ...product,
          count_in_document: Math.round(product.count_in_document),
        }));
        const result = await axios.patch(`${apiWarehouseDocument + docId}/products/many/`, request);

        return result;
      } catch (error) {
        console.error(new Error(error));
        throw error;
      }
    },
    async addStockDocument({ commit }, payload) {
      try {
        const result = await axios.post(apiWarehouseDocument, payload);

        return result.data;
      } catch (error) {
        commit('showAlert', error.response.data);
        console.error('addDocument: ', new Error(error));
      }
    },
    async editStockDocument({ commit }, payload) {
      try {
        const result = await axios.patch(apiWarehouseDocument + payload.id, payload);

        return result.data;
      } catch (error) {
        console.error('addDocument: ', new Error(error));
      }
    },
    async removeStockDocument({ commit }, id) {
      try {
        const result = await axios.delete(apiWarehouseDocument + id);

        return result.data;
      } catch (error) {
        console.error('addDocument: ', new Error(error));
      }
    },
    async getOnceStockDocument({ commit }, id) {
      try {
        const result = await axios.get(apiWarehouseDocument + id);

        return result.data;
      } catch (error) {
        console.error('addDocument: ', new Error(error));
      }
    },
    async getStockDocuments({ commit }, payload) {
      try {
        const result = await axios.get(apiWarehouseDocument);

        commit('setStockDocuments', result.data);

        return result.data;
      } catch (error) {
        console.error('addDocument: ', new Error(error));
      }
    },
    async addDocumentCategories({ commit }, payload) {
      try {
        const result = await axios.post(apiWarehouseDocumentCategory, payload);

        return result.data;
      } catch (error) {
        console.error('Error addDocumentCategories(): ', new Error(error));
        throw error;
      }
    },
    async removeDocumentCategories({ commit }, id) {
      try {
        const result = await axios.delete(apiWarehouseDocumentCategory + id);

        return result.data;
      } catch (error) {
        console.error('Error removeDocumentCategories(): ', new Error(error));
      }
    },
    async updateDocumentCategories({ commit }, payload) {
      try {
        const result = await axios.patch(apiWarehouseDocumentCategory + payload.id, payload);

        return result.data;
      } catch (error) {
        console.error('Error updateDocumentCategories(): ', new Error(error));
      }
    },
    async getDocumentCategories({ commit }) {
      try {
        const result = await axios.get(apiWarehouseDocumentCategory);

        commit('setDocumentCategories', result.data);

        return result.data;
      } catch (error) {
        console.error('Error getDocumentCategories(): ', new Error(error));
      }
    },
    async getOnceDocumentCategory({ commit }, id) {
      try {
        const result = await axios.get(apiWarehouseDocumentCategory + id);

        commit('setCurrentCategory', result.data);

        return result.data;
      } catch (error) {
        console.error('Error getDocumentCategories(): ', new Error(error));
      }
    },
    async getStocks({ commit }) {
      try {
        const result = await axios.get(apiWarehouseForDoc);

        commit('setStockList', result.data);

        return result.data;
      } catch (error) {
        console.error('addDocument: ', new Error(error));
      }
    },
    async downloadDocumentExcelExampleFile({ commit }, id) {
      try {
        const result = await axios.get(`${apiExcelImportDocumentProduct}${id}/`, {
          responseType: 'blob',
        });

        downloadFile(result.data, 'Складские документы.xlsx');

        return result.data;
      } catch (error) {
        throw error;
      }
    },
    async uploadDocumentExcelExampleFile({ commit }, { id, file }) {
      const formData = new FormData();
      formData.set('file', file);

      try {
        const result = await axios.post(`${apiExcelImportDocumentProduct}${id}/`, formData);

        return result.data;
      } catch (error) {
        throw error;
      }
    },
  },
  getters: {
    lastDateUploading: state => state.lastDateUploading,
  },
};
