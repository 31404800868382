import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { vMaska } from 'maska';
import Spinner from '@/components/Spinner.vue';
import vuetify from './plugins/vuetify';
import VCalendar from 'v-calendar';
import { LicenseManager } from 'ag-grid-enterprise';
import VueApexCharts from 'vue-apexcharts';
import directives from '@/directives';

import registerModules from '@/utils/registerModules';
import stockModule from '@/modules/stock';
import expensesModule from '@/modules/expenses';
import wbCardsModule from '@/modules/wbCards';
import homePageModule from '@/modules/homePage';
import aIPageModule from '@/modules/aiProduct';
import externalAdsModule from '@/modules/externalAds';
import abTestsModule from '@/modules/abTests';
import adCampaignsModule from './modules/adCampaigns';

import Router from 'vue-router';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import VueTour from 'vue-tour';
import aiGeneration from './modules/aiGeneration';

require('vue-tour/dist/vue-tour.css');

Vue.use(VueTour);

registerModules({
  stock: stockModule,
  expenses: expensesModule,
  wbCards: wbCardsModule,
  homePage: homePageModule,
  aIPage: aIPageModule,
  externalAds: externalAdsModule,
  abTests: abTestsModule,
  adCampaigns: adCampaignsModule,
  aiGeneration,
});

directives.forEach(directive => {
  Vue.directive(directive.name, directive);
});
Vue.use(Router);
Vue.use(VueApexCharts);

Vue.component('apexchart', VueApexCharts);

Sentry.init({
  Vue,
  dsn: 'https://e7064dfa6c844514bc134bdba905c19f@sentry.mplab.io/7',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['app.mamod.ai', 'https://app.mamod.ai/', /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
});

Vue.component('spinner', Spinner);
LicenseManager.setLicenseKey(
  'CompanyName=MPLAB,LicensedApplication=MPLAB,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-034191,SupportServicesEnd=23_October_2023_[v2]_MTY5ODAxNTYwMDAwMA==3b4ce1e31fc57311e3399877777ff001',
);

Vue.config.productionTip = false;
Vue.use(VCalendar, {
  componentPrefix: 'vc',
});

Vue.directive('maska', vMaska);
Vue.config.productionTip = false;
Vue.prototype.$http = axios;

Vue.use(VueAxios, axios);

const token = localStorage.getItem('token');
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = `JWT ${token}`;
}

new Vue({
  axios,
  store,
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app');
