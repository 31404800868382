<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
    <path
      d="M2.66602 5.16667H9.33268C11.5418 5.16667 13.3327 6.95753 13.3327 9.16667C13.3327 11.3758 11.5418 13.1667 9.33268 13.1667H2.66602M2.66602 5.16667L5.33268 2.5M2.66602 5.16667L5.33268 7.83333"
      stroke="#3A416F"
      stroke-opacity="0.7"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'Return',
};
</script>
