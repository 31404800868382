<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="width"
    viewBox="0 0 17 16"
    :fill="color"
    @click="$emit('click')"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.66679 3.16732C6.66679 2.1548 7.4876 1.33398 8.50012 1.33398C9.51264 1.33398 10.3335 2.1548 10.3335 3.16732V3.27775C11.9234 3.47125 13.4088 3.91263 14.7191 4.55118C14.9674 4.67215 15.0706 4.97145 14.9496 5.21969C14.8286 5.46792 14.5293 5.57109 14.2811 5.45012C12.62 4.64064 10.6363 4.16732 8.50011 4.16732C6.36394 4.16732 4.38024 4.64063 2.71915 5.45012C2.47091 5.57109 2.17161 5.46792 2.05064 5.21969C1.92967 4.97145 2.03284 4.67215 2.28108 4.55118C3.5914 3.91263 5.0768 3.47125 6.66679 3.27775V3.16732ZM12.677 12.1893C12.5774 13.5848 11.4162 14.666 10.0172 14.666H6.98313C5.58409 14.666 4.42291 13.5848 4.32324 12.1893L3.86442 5.76592C5.27597 5.27371 6.84596 4.99935 8.50014 4.99935C10.1543 4.99935 11.7243 5.27371 13.1359 5.76592L12.677 12.1893ZM7.66681 7.33268C7.66681 7.05654 7.44295 6.83268 7.16681 6.83268C6.89067 6.83268 6.66681 7.05654 6.66681 7.33268V11.3327C6.66681 11.6088 6.89067 11.8327 7.16681 11.8327C7.44295 11.8327 7.66681 11.6088 7.66681 11.3327V7.33268ZM9.83348 6.83268C10.1096 6.83268 10.3335 7.05654 10.3335 7.33268V11.3327C10.3335 11.6088 10.1096 11.8327 9.83348 11.8327C9.55733 11.8327 9.33348 11.6088 9.33348 11.3327V7.33268C9.33348 7.05654 9.55733 6.83268 9.83348 6.83268Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'BasketIcon',
  props: {
    color: {
      type: String,
      default: '#3A416F',
    },
    width: {
      type: String | Number,
      default: 17,
    },
  },
};
</script>
