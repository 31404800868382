<template>
  <span class="loader"></span>
</template>

<script>
export default {
  name: "CSpinner",
};
</script>

<style scoped>
.loader {
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  border: 2px solid #3586ff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>

<docs lang="md">
```vue
<template>
  <div class="d-flex example">
    <CButtonOutline @click="isLoading = !isLoading">
      {{ !isLoading ? "Загрузка" : "Не загрузка" }}
    </CButtonOutline>
    <br />
    <div class="loading-block">
      <CSpinner v-if="isLoading" />
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isLoading: false,
  }),
};
</script>

<style lang="scss" scoped>
.example {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.loading-block {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}
</style>
```
</docs>
