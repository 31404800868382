<template>
  <div
    v-if="!vuetify"
    class="tooltip"
    @mouseover="isTooltipVisible = true"
    @mouseout="isTooltipVisible = false"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
      <path
        d="M80 160c0-35.3 28.7-64 64-64h32c35.3 0 64 28.7 64 64v3.6c0 21.8-11.1 42.1-29.4 53.8l-42.2 27.1c-25.2 16.2-40.4 44.1-40.4 74V320c0 17.7 14.3 32 32 32s32-14.3 32-32v-1.4c0-8.2 4.2-15.8 11-20.2l42.2-27.1c36.6-23.6 58.8-64.1 58.8-107.7V160c0-70.7-57.3-128-128-128H144C73.3 32 16 89.3 16 160c0 17.7 14.3 32 32 32s32-14.3 32-32zm80 320a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"
      />
    </svg>
    <CModalFade v-show="isTooltipVisible" :htmlText="htmlText" :text="text" />
  </div>
  <v-tooltip v-else top>
    <template v-slot:activator="{ on, attrs }">
      <span class="tooltip-icon" v-bind="attrs" v-on="on">
        <QuestionCircle />
      </span>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>

<script>
import { QuestionCircle } from './icons';
import CModalFade from '@/components/CFades/CModalFade.vue';

export default {
  name: 'CTooltip',
  data() {
    return {
      isTooltipVisible: false,
    };
  },
  props: {
    text: String,
    htmlText: String,
    vuetify: Boolean,
  },
  components: { QuestionCircle, CModalFade },
};
</script>

<style lang="scss" scoped>
.tooltip {
  background: transparent;
  width: 15px;
  height: 15px;
  border: 1px solid #3a416f80;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 8px;
  }

  path {
    fill: #3a416f80;
  }

  &__modal {
    position: absolute;
    bottom: 20px;
    right: -130px;
    padding: 5px 10px;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 10px 70px rgba(206, 208, 213, 0.7);
    z-index: 101;
    display: flex;
    justify-content: center;
    min-width: 320px;
    align-items: center;

    @media (max-width: 492px) {
      right: 0;
    }
  }
  &__text {
    font-size: 12px;
    line-height: 21px;
    text-align: center;
    color: rgba(58, 65, 111, 0.8);
    font-weight: 500;
  }
}
</style>

<docs lang="md">
```vue
<template>
  <CTooltip text="Здесь мог быть Ваш текст" />
</template>
```
</docs>
