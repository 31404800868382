import axios from 'axios';
import API_ENV from '@/config/api.config';

let {
  apiRates,
  apiCreateOrder,
  apiSendOrder,
  apiProlongateOrder,
  // apiPreorderPay,
  apiUsePromocode,
  apiCalculateOrder,
  apiExtraFreeTariff,
} = API_ENV;

export default {
  state: {
    chosenRate: '',
  },
  mutations: {
    CHANGE_CHOSEN_RATE(state, rate) {
      state.chosenRate = rate;
    },
  },
  getters: {
    getChosenRate: ({ chosenRate }) => chosenRate,
  },
  actions: {
    async getExtraFreeTariff({ commit }) {
      try {
        const { data } = await axios.post(apiExtraFreeTariff);
        return data;
      } catch (error) {}
    },
    changeChosenRate({ commit }, data) {
      commit('CHANGE_CHOSEN_RATE', data);
    },
    usePromocode({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(apiUsePromocode, data)
          .then(response => resolve(response.data))
          .catch(err => {
            console.log(err?.response?.data.error);
            commit('showAlert', err?.response?.data?.error);
            reject(err.response.data);
          });
      });
    },
    async getRatesInfo() {
      try {
        const { data } = await axios.get(apiRates);
        return data;
      } catch (error) {
        console.log('erroe', new Error(error));
        return error;
      }
    },
    // getOrderInfo({ commit }) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get(apiPreorderPay)
    //       .then((response) => resolve(response.data))
    //       .catch((err) => {
    //         commit("showAlert");
    //         reject(err);
    //       });
    //   });
    // },

    async createOrder({ commit }, data) {
      const { isProlongate, transmitData } = data;
      const api = isProlongate ? apiProlongateOrder : apiCreateOrder;
      try {
        const response = await axios.post(api, transmitData);
        return response;
      } catch (error) {
        commit('showAlert', error.response.data.detail || error.response.data.errors);
        throw error;
      }
    },
    createCalculateOrder({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(apiCalculateOrder, data)
          .then(response => resolve(response))
          .catch(err => {
            commit('showAlert');
            reject(err);
          });
      });
    },

    sendOrder({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(apiSendOrder, data)
          .then(response => resolve(response))
          .catch(err => {
            commit('showAlert');
            reject(err);
          });
      });
    },
  },
};
