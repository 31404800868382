<template>
  <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.4693 2.92901L23.4993 3.12501C23.3625 2.62625 23.1008 2.17059 22.739 1.80105C22.3771 1.43152 21.9271 1.1603 21.4313 1.01301L21.4103 1.00801C19.5393 0.500014 12.0103 0.500014 12.0103 0.500014C12.0103 0.500014 4.50029 0.490014 2.61029 1.00801C2.11367 1.15174 1.66183 1.41959 1.2974 1.7863C0.932958 2.15301 0.667927 2.6065 0.527294 3.10401L0.522294 3.12501C-0.176706 6.77601 -0.181706 11.163 0.553294 15.072L0.522294 14.874C0.659111 15.3728 0.920793 15.8284 1.28263 16.198C1.64447 16.5675 2.09452 16.8387 2.59029 16.986L2.61129 16.991C4.48029 17.5 12.0113 17.5 12.0113 17.5C12.0113 17.5 19.5203 17.5 21.4113 16.991C21.9081 16.8475 22.3602 16.5798 22.7249 16.213C23.0895 15.8463 23.3547 15.3927 23.4953 14.895L23.5003 14.874C23.8183 13.176 24.0003 11.222 24.0003 9.22601L23.9993 9.00501L24.0003 8.77501C24.0003 6.77801 23.8183 4.82401 23.4693 2.92901ZM9.6083 12.651V5.35801L15.8743 9.01001L9.6083 12.651Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'YouTube',
}
</script>

<style lang="scss" scoped></style>
