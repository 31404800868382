<template>
	<svg
		@click="$emit('click')"
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="17"
		viewBox="0 0 16 17"
		fill="none"
		:style="{ opacity }"
	>
		<path
			d="M14 12.5008L13.3332 13.2302C12.9796 13.6169 12.5001 13.8341 12.0001 13.8341C11.5001 13.8341 11.0205 13.6169 10.6669 13.2302C10.3128 12.8442 9.83332 12.6275 9.33345 12.6275C8.83359 12.6275 8.35409 12.8442 7.99998 13.2302M2 13.8341H3.11636C3.44248 13.8341 3.60554 13.8341 3.75899 13.7973C3.89504 13.7646 4.0251 13.7108 4.1444 13.6377C4.27895 13.5552 4.39425 13.4399 4.62486 13.2093L13 4.83414C13.5523 4.28185 13.5523 3.38642 13 2.83413C12.4477 2.28185 11.5523 2.28185 11 2.83414L2.62484 11.2093C2.39424 11.4399 2.27894 11.5552 2.19648 11.6898C2.12338 11.8091 2.0695 11.9391 2.03684 12.0752C2 12.2286 2 12.3917 2 12.7178V13.8341Z"
			:stroke="color"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>

<script>
export default {
	name: 'NewEditIcon',
	props: {
		color: {
			type: String,
			default: '#3A416F',
		},
		opacity: {
			type: String | Number,
			default: 0.5,
		},
	},
};
</script>

<style lang="scss" scoped>
svg {
	cursor: pointer;
}
</style>
