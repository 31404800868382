<template>
  <div class="gallery">
    <ul class="gallery__side">
      <li
        v-for="(item, index) of galleryList"
        :key="index"
        @click="currentImage = galleryList[index]"
        class="gallery__side-item"
      >
        <img
          :src="item"
          alt=""
          :class="{ active: currentImage === galleryList[index] }"
          class="gallery__side-image"
        />
      </li>
    </ul>
    <div class="gallery__main">
      <img :src="currentImage" @click="isModalVisible = true" alt="" />
    </div>
    <CModal v-model="isModalVisible">
      <img :src="currentImage" class="modal__image" alt="" />
    </CModal>
  </div>
</template>
<script>
import CModal from './CModal.vue';

export default {
  name: 'CGallery',
  components: { CModal },
  props: {
    galleryList: {
      type: Array,
    },
  },
  data() {
    return {
      isModalVisible: false,
      currentImage: null,
    };
  },
  methods: {
    updateCurrentImage() {
      if (this.galleryList.length > 0) {
        this.currentImage = this.galleryList[0];
      }
    },
  },
  computed: {},
  watch: {
    galleryList: {
      immediate: true,
      handler() {
        this.updateCurrentImage();
      },
    },
  },
  mounted() {
    this.currentImage = this.galleryList[1];
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/mixin.scss';

.gallery {
  max-height: 500px;
  display: flex;
  gap: 30px;
  margin-bottom: 40px;
  &__side {
    display: flex;
    flex-direction: column;
    min-width: 50px;
    gap: 4px;
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: none; /* IE и Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    &-image {
      object-fit: cover;
      width: 100%;
      height: 60px;
      cursor: pointer;
      opacity: 0.2;
      transition: all 0.3s;
      &:hover {
        opacity: 0.5;
      }
      &.active {
        opacity: 1;
      }
    }
  }
  &__main {
    overflow: hidden;
    img {
      cursor: pointer;
      width: 100%;
      transition: all 0.3s;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
.modal {
  &__image {
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .gallery {
    gap: 10px;
  }
}
@media screen and (max-width: 768px) {
  .gallery {
    flex-direction: column;
    &__side {
      flex-direction: row;
      overflow-y: hidden;
      overflow-x: auto;
      width: 100%;
      &-image {
        height: 110px;
      }
    }
  }
}
</style>
