<template>
  <svg :style="`width: ${width}px;`" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.00521 4.66667V7.5M11.3385 6.20024C11.3385 10.8528 8.0265 12.9386 6.6224 13.6139L6.62063 13.6147C6.47295 13.6857 6.39893 13.7213 6.23112 13.752C6.12485 13.7713 5.88624 13.7713 5.77997 13.752C5.61149 13.7212 5.53669 13.6855 5.38784 13.6139C3.98374 12.9386 0.671875 10.8528 0.671875 6.20024V3.3918C0.671875 2.5984 0.671875 2.2014 0.8172 1.89836C0.945031 1.63179 1.14886 1.41523 1.39974 1.27941C1.68496 1.125 2.0586 1.125 2.80534 1.125H9.20534C9.95208 1.125 10.3249 1.125 10.6101 1.27941C10.861 1.41523 11.0655 1.63179 11.1934 1.89836C11.3385 2.2011 11.3385 2.59762 11.3385 3.38948V6.20024ZM6.03841 9.625V9.69583L5.97201 9.69597V9.625H6.03841Z" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'WarningIcon',
  props: {
    width: {
      type: String | Number,
      default: 12,
    },
    color: {
      type: String,
      default: '#E25353',
    },
  }
}
</script>

<style lang="scss" scoped></style>
