import API_ENV from '@/config/api.config';
import store from '@/store';

const { apiStageWebsocket, apiReviewAnalysisWebsocket, apiCabinetsWebsocket, apiSkynetWebsocket } =
  API_ENV;

const apiConverter = {
  stages: apiStageWebsocket,
  reviewAnalysis: apiReviewAnalysisWebsocket,
  cabinets: apiCabinetsWebsocket,
  skynet: apiSkynetWebsocket,
};

function connectWebSocket(path) {
  const { token, userInfo } = store.state;
  const wsPath = (apiConverter[path] || path) + '?user_id=' + userInfo.user.id;

  const ws = new WebSocket(wsPath);

  ws.onopen = () => {
    ws.send(
      JSON.stringify({
        Authorization: `JWT ${token}`,
        request_id: Date.now(),
        action: 'authenticate',
      })
    );
  };

  return ws;
}

export default connectWebSocket;
