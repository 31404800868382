<template>
  <div>
    <div key="phone" class="phone" v-if="!isDone">
      <CNumberField
        v-model="value.phone"
        @click="$emit('click')"
        :error="error"
      />
      <transition name="fade">
        <CPasswordInput
          v-if="isPasswordVisible"
          v-model="value.password"
          placeholder="Введите пароль"
        />
      </transition>
    </div>
    <div v-else key="done" class="done">
      <h3 class="done__title">Вы успешно авторизовались!</h3>
      <p class="done__description">
        Через несколько секунд вы перейдете на страницу оплаты
      </p>
    </div>
  </div>
</template>
<script>
import CNumberField from "@/components/UI/CNumberField.vue";
import CPasswordInput from "@/components/UI/CPasswordInput.vue";
export default {
  name: "TPhone",
  components: {
    CNumberField,
    CPasswordInput,
  },
  props: {
    error: String,
    isPasswordVisible: Boolean,
    isDone: Boolean,
  },
  data() {
    return {
      value: {
        phone: "",
        password: "",
      },
    };
  },
  watch: {
    value: {
      handler: function (value) {
        this.$emit("input", value);
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.phone {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.done {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
</style>
