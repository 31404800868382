import Vue from "vue";
import stock from "@/modules/stock";
import wbCards from "@/modules/wbCards";
import expenses from "@/modules/expenses";

export default {
  state: {
    notAvailableForL: stock.router.children.map((item) => item.name),
    notAvailableForM: [
      ...stock.router.children.map((item) => item.name),
      ...wbCards.router.children.map((item) => item.name),
      ...expenses.router.children.map((item) => item.name),
      "InternalAnalyticsAnalytics",
    ],
    notAvailableForFree: stock.router.children.map((item) => item.name),
  },
  getters: {},
  mutations: {
    setNotAvailableForL(state, values) {
      state.notAvailableForL = values;
    },
  },
  actions: {
    initializeNotAvailableForL({ commit }) {
      const qwe = stock.router.children.map((item) => item.name);

      commit("setNotAvailableForL", qwe);
    },
  },
};
