<template>
  <div class="name">
    <div class="name__steps">
      <WbCabinetsStep
        v-for="(item, index) of infoList"
        :key="index"
        :step="index + 1"
        :image="item.img"
        :description="item.description"
      />
    </div>

    <h5 class="name__title">Вставьте API-токен с WB</h5>
    <input
      @input="$emit('input', $event.target.value)"
      class="input"
      placeholder="Вставьте сюда полученный токен"
      :value="value"
    />
  </div>
</template>
<script>
import WbCabinetsStep from '../WbCabinetsStep.vue';
export default {
  name: 'WbCabinetesMainToken',
  props: ['value'],
  components: { WbCabinetsStep },
  data() {
    return {
      infoList: [
        {
          description:
            "Зайдите в ваш <a class='link' href='https://seller.wildberries.ru/' target='_blank'>Личный Кабинет</a> на портале Поставщиков Wildberries и откройте Настройки <a class='link' href='https://wiki.mamod.ai/ru/standart_token' target='_blank'>Подробнее</a>",
          img: `steps/images/newApi.png`,
        },
        {
          description:
            "Далее прейдите в раздел <b>'Доступ к новому API'</b>. Выберите Все опции, после чего нажмите 'Создать новый токен'",
          img: `steps/images/createTokens.png`,
        },
        {
          description:
            'После успешного создания нажмите на кнопку «Скопировать» и вставьте токен в поле ниже.',
          img: `steps/images/fullToken.png`,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.name {
  display: flex;
  flex-direction: column;
  gap: 20px;
  &__steps {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
  }
}
.input {
  background: #fff;
}
</style>
