<template>
  <div class="finall">
    <h2>Поздравляем! Вы успешно завершили этап создания нового кабинета!</h2>
    <p>Через пару секунд мы начнем собирать данные</p>
  </div>
</template>
<script>
import { setTimeout } from 'core-js';
import { mapMutations, mapActions } from 'vuex';
export default {
  name: 'WbCabinetesFinall',
  props: ['id'],
  methods: {
    ...mapMutations(['editCurrentWbToken']),
    ...mapActions(['getWbToken', 'patchCabinet']),
  },
  async mounted() {
    setTimeout(async () => {
      await this.patchCabinet({
        id: this.id,
        is_cabinet_done: true,
      });
      // await this.getWbToken();
      this.editCurrentWbToken({ id: this.id, isCreating: false });
      this.$emit('createDone');
    }, 2500);
  },
};
</script>
<style lang="scss" scoped>
.finall {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
