<template>
  <transition name="modalFade">
    <div class="modal" v-if="showModal || value" @mousedown="closeModal">
      <div class="modalBox" @mousedown.stop>
        <div class="close-modal" @click="closeModal">
          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.71277 9.77342C9.00567 10.0663 9.48054 10.0663 9.77343 9.77342C10.0663 9.48053 10.0663 9.00566 9.77343 8.71276L6.06114 5.00047L9.77342 1.28819C10.0663 0.995293 10.0663 0.52042 9.77342 0.227527C9.48053 -0.0653667 9.00566 -0.0653667 8.71276 0.227527L5.00048 3.93981L1.28815 0.227483C0.995258 -0.065411 0.520385 -0.0654107 0.227491 0.227483C-0.0654019 0.520376 -0.0654023 0.995249 0.227491 1.28814L3.93982 5.00047L0.227483 8.71281C-0.065411 9.0057 -0.0654107 9.48057 0.227483 9.77347C0.520376 10.0664 0.995249 10.0664 1.28814 9.77347L5.00048 6.06113L8.71277 9.77342Z" fill="#3A416F"/>
          </svg>
        </div>
        <div class="modal-icon">
          <div class="modal-iconSvg" v-if="icon" v-html="icon"></div>
          <div class="modal-iconSvg" v-else>
            <svg width="83" height="97" viewBox="0 0 83 97" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M62.4045 16.4751L62.3528 16.4461C62.3528 16.4461 62.3496 16.4461 62.3464 16.4461L50.036 9.36083C50.036 9.36083 50.0295 9.3576 50.0231 9.35437L49.952 9.31885C44.2005 6.42209 36.5275 7.2908 28.2764 12.629C11.6968 23.3505 -0.939735 48.0294 0.0549134 67.748C0.552237 77.5782 4.3403 84.3179 10.0692 87.2792L10.1209 87.3083C10.1209 87.3083 10.1241 87.3083 10.1273 87.3115L22.441 94.4C22.441 94.4 22.4442 94.4 22.4474 94.4L22.5088 94.4323C28.2603 97.3387 35.9398 96.4733 44.2005 91.1319C60.7801 80.4104 73.4167 55.7315 72.422 36.0129C71.9247 26.1827 68.1366 19.4429 62.4077 16.4816L62.4045 16.4751Z" fill="#EFEBEC"/>
              <path d="M22.0176 94.1707L10.127 87.305H10.1173L10.0624 87.2727C4.33676 84.3017 0.548695 77.562 0.0546004 67.7447C-0.387824 58.9511 1.88243 49.1758 5.99343 40.0948L6.07739 39.8945L18.3103 47.18C14.1896 56.2578 11.9258 66.0331 12.3682 74.8299C12.8526 84.447 16.4954 91.106 22.0176 94.1674V94.1707Z" fill="#E3D9DC"/>
              <path d="M44.2007 91.1285C60.7804 80.4069 73.4169 55.728 72.419 36.0095C71.4244 16.2909 57.1764 8.99571 40.5968 19.7172C24.0171 30.4388 11.3806 55.1177 12.3752 74.8363C13.3699 94.5581 27.6179 101.85 44.1975 91.1285H44.2007Z" fill="#E8E5E6"/>
              <path d="M58.2421 14.9479L37.461 57.75L32.9883 52.9027C31.6094 51.4043 29.6427 50.5453 27.592 50.5453C25.7384 50.5453 23.9655 51.2364 22.5994 52.4926C19.6252 55.2408 19.4411 59.9008 22.1861 62.8783L33.9701 75.6408C35.2747 77.0552 37.0509 77.8884 38.9724 77.9918C39.108 77.9982 39.2436 78.0015 39.3793 78.0015C39.7313 78.0015 40.0865 77.9756 40.445 77.924C42.8541 77.5655 44.9273 76.0477 45.9834 73.8614L69.8162 24.7716C67.3844 19.3301 63.3122 15.9425 58.2421 14.9414V14.9479Z" fill="#64454E"/>
              <path d="M75.1194 0.848684C74.9902 0.774408 74.8578 0.703362 74.7189 0.638775C71.5477 -0.895179 67.7176 0.428865 66.1869 3.59043L39.7416 58.0571L38.2399 56.4295C37.791 55.9451 37.2872 55.5446 36.7415 55.2314C35.9535 54.7793 32.6014 52.8384 32.0589 52.5252C29.7079 51.1688 26.6432 51.424 24.5312 53.3648C21.9477 55.7513 21.7894 59.7977 24.1727 62.3845L35.9567 75.1502C36.4056 75.6346 36.9191 76.0415 37.4778 76.3612C38.2464 76.8004 41.5855 78.7348 42.1345 79.0513C42.9968 79.5518 43.9688 79.8457 44.9893 79.9006C45.4091 79.9232 45.8322 79.9071 46.2681 79.8457C48.3608 79.5357 50.1595 78.2149 51.0767 76.3192L82.3596 11.8835C83.829 8.85108 82.6826 5.21803 79.8117 3.56459C79.6793 3.49031 75.9526 1.33632 75.1161 0.855143L75.1194 0.848684Z" fill="#3586ff"/>
              <path opacity="0.15" d="M75.1194 0.848684C74.9902 0.774408 74.8578 0.703362 74.7189 0.638775C71.5477 -0.895179 67.7176 0.428865 66.1869 3.59043L39.7416 58.0571L38.2399 56.4295C37.791 55.9451 37.2872 55.5446 36.7415 55.2314C35.9535 54.7793 32.6014 52.8384 32.0589 52.5252C29.7079 51.1688 26.6432 51.424 24.5312 53.3648C21.9477 55.7513 21.7894 59.7977 24.1727 62.3845L35.9567 75.1502C36.4056 75.6346 36.9191 76.0415 37.4778 76.3612C38.2464 76.8004 41.5855 78.7348 42.1345 79.0513C42.9968 79.5518 43.9688 79.8457 44.9893 79.9006C45.4091 79.9232 45.8322 79.9071 46.2681 79.8457C48.3608 79.5357 50.1595 78.2149 51.0767 76.3192L82.3596 11.8835C83.829 8.85108 82.6826 5.21803 79.8117 3.56459C79.6793 3.49031 75.9526 1.33632 75.1161 0.855143L75.1194 0.848684Z" fill="black"/>
              <path opacity="0.15" d="M42.1324 79.0446C41.5866 78.7281 38.2475 76.7905 37.4756 76.3545C36.917 76.0348 36.4035 75.6311 35.9546 75.1467L24.1706 62.3842C23.6345 61.7997 23.2309 61.1377 22.9531 60.4434L27.639 63.1463C27.9167 63.8439 28.3236 64.5059 28.8629 65.0904L40.6469 77.8529C41.0861 78.3276 41.5866 78.7281 42.1324 79.0478V79.0446Z" fill="black"/>
              <path opacity="0.15" d="M72.3524 4.33054C71.7518 4.86662 71.2448 5.52864 70.8734 6.294L43.6756 62.3172L39.7422 58.0512L66.1843 3.58455C66.5557 2.81596 67.0659 2.15717 67.6666 1.62109L72.3524 4.32731V4.33054Z" fill="black"/>
              <path d="M79.4064 3.34581C76.2352 1.81185 72.4051 3.1359 70.8744 6.29746L43.6766 62.3207L38.2383 56.4303C35.8615 53.85 31.8183 53.6853 29.2187 56.0751C26.6352 58.4616 26.4769 62.508 28.8602 65.0947L40.6442 77.8605C41.7745 79.0876 43.3182 79.811 44.9877 79.9014C45.4076 79.924 45.8306 79.9079 46.2666 79.8465C48.3592 79.5365 50.158 78.2157 51.0751 76.32L82.3581 11.8843C83.8953 8.71303 82.5712 4.88622 79.4064 3.35226V3.34581Z" fill="#3586ff"/>
            </svg>
          </div>
        </div>
        <div class="modal-title">
          <h4>{{ title }}</h4>
        </div>
        <div class="modal-text">
          <p v-if="text">{{ text }}</p>
          <slot />
        </div>
        <button type="button" class="button prime-button" @click="closeModal">Отлично, спасибо</button>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'success-modal',
    props: ['showModal', 'title', 'text', 'icon', 'value'],
    methods: {
      closeModal() {
        this.$emit('update:showModal', false)
        this.$emit('input', false)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .modalBox {
    width: 500px;
    padding: 30px;
    text-align: center;

    .close-modal {
      position: absolute;
      right: 10px;
      top: 20px;
      cursor: pointer;
      opacity: .5;
      height: 45px;
      width: 45px;
    }

    .modal-title {
      margin: 20px 0 10px;
      
      font-family: 'Manrope';
      font-size: 20px;
      letter-spacing: -0.4px;
    }

    .modal-text {
      margin-bottom: 20px;
      
      p {
      font-weight: 500;
      color: #67748E;
      }
    }

    button {
      width: 100%;
    }
  }
  @media (max-width: 492px) {
    .modalBox {
      width: auto;
      max-width: 95%;
      width: 100%;
    }
  }
</style>