<template>
  <Rates class="landing" />
</template>
<script>
import { mapActions } from 'vuex';
import Rates from '../Rates.vue';
export default {
  components: { Rates },
};
</script>
<style lang="scss">
.landing {
  background: #1b1921;

  .extend__wrapper-input {
    display: none;
  }
  .routes-banner {
    background: transparent;
    &__title,
    &__description {
      color: #c5d5e8;
    }
  }

  .main-rates {
    background: #2e2b3e;
    border: 1px solid #c5d5e8;
    border-radius: 0;
    &__wrapper {
      height: 180px;
      &:has(.main-rates__popular) {
        border-right: 1px solid #c5d5e8;
        .main-rates__header {
          border-right: none;
        }
      }
    }
    &__column {
      background: #2e2b3e;
    }
    &__header {
      color: #c5d5e8;
    }
    &__popular {
      background: radial-gradient(
        105.21% 734.49% at 68.95% 24.26%,
        #d4e0f4 0%,
        #d4e0f4 0.01%,
        #8ec2e0 100%
      );
      color: #1b1921;
      padding: 6px 18px;
      font-size: 16px;
      font-weight: 500;
      line-height: 17.6px;
      text-align: center;
      width: auto;
      border-radius: 5000px;
      svg {
        display: none;
      }
    }
  }
  .rates-header {
    &__title {
      color: #c5d5e8;
    }
    &__desc {
      color: #8370f4;
    }
    &__total {
      color: #c5d5e880;
    }
    &__button {
      background: #6d5adf33;
      color: #8370f4;
    }
  }
  .rates-item__dignifieds-item {
    background: #2e2b3e;
    color: #c5d5e8;
    border-right: none;
    &-extraBlock {
      background: #2e2b3e;
      color: #c5d5e8;
    }
    &.extraBlock {
      background: #2e2b3e;
      color: #c5d5e8;
      margin-left: 0;
      padding-left: 16px;
    }
    &.prime {
      background: #47416a;
    }
    &.err {
      background: #ec243c66;
    }
  }
  .calc {
    &__input,
    &__value,
    &__text {
      color: #c5d5e8;
    }
    &__circle {
      border: 1px solid #c5d5e8;
    }
    &__btn {
      color: #000;
    }
  }
}
</style>
