<template>
  <button @click="$emit('click')" :disabled="isLoading || disabled">
    <div class="slot" :class="{ hide: isLoading }">
      <slot />
    </div>
    <div class="spinner" :class="{ hide: !isLoading }">
      <spinner />
    </div>
  </button>
</template>

<script>
import Spinner from './Spinner.vue';
export default {
  name: 'button-loader',
  components: { Spinner },
  props: ['isLoading', 'disabled'],
};
</script>

<style lang="scss" scoped>
button {
  position: relative;

  .slot,
  .spinner {
    transition: all 0.4s ease;
  }

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .hide {
    opacity: 0;
  }
}
</style>
