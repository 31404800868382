<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="#E6EDF4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5556 7.55556C12.5556 7.24873 12.3068 7 12 7C11.6932 7 11.4444 7.24873 11.4444 7.55556V11.4444H7.55556C7.24873 11.4444 7 11.6932 7 12C7 12.3068 7.24873 12.5556 7.55556 12.5556H11.4444V16.4444C11.4444 16.7513 11.6932 17 12 17C12.3068 17 12.5556 16.7513 12.5556 16.4444V12.5556H16.4444C16.7513 12.5556 17 12.3068 17 12C17 11.6932 16.7513 11.4444 16.4444 11.4444H12.5556V7.55556Z" fill="#3A416F"/>
  </svg>
</template>

<script>
export default {
  name: 'PlusCircle',
}
</script>

<style lang="scss" scoped>

</style>