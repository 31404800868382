<template>
  <CloseSection
    class="close"
    buttonsAvailable
    :rateButtonText="rateButtonText"
    :ownRateBtnAction="ownRateBtnAction"
    @rateButtonClicked="$emit('rateButtonClicked')"
  >
    <slot></slot>
  </CloseSection>
</template>
<script>
import CloseSection from '@/components/ClosePage/CloseSection.vue';

export default {
  name: 'CloseBecauseTariff',
  components: {
    CloseSection,
  },
  props: {
    title: {
      type: String,
    },
    linkTitle: {
      type: String,
    },
    buttonsAvailable: {
      type: Boolean,
      default: false,
    },
    rateButtonText: {
      type: String,
    },
    ownRateBtnAction: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.close {
  position: relative;
  &__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>
