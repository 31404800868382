<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M14.1663 5.83325L5.83301 14.1666M5.83301 5.83325L14.1663 14.1666"
      stroke="#3A416F"
      stroke-opacity="0.4"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'CloseIcon',
};
</script>

<style></style>
