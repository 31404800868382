import customValidate from "./customValidate"

const allDirectives = {
  customValidate
}

const convertedDiretives = Object.entries(allDirectives).map(([directiveName, directive]) => {
  directive.name = directiveName

  return directive
})

export default convertedDiretives