<template>
  <div>
    <h4 class="name__title">Название для Вашего кабинета <span class="name__important">*</span></h4>
    <input
      @input="$emit('input', $event.target.value)"
      class="input"
      placeholder="Введите название для кабинета"
      :value="value"
    />
  </div>
</template>
<script>
export default {
  name: 'WbCabinetesName',
  props: ['value', 'error'],
};
</script>
<style lang="scss" scoped>
.input {
  background: #fff;
}
.name {
  &__important {
    color: rgb(173, 0, 0);
  }
}
</style>
