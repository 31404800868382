import axios from 'axios'
import API_ENV from '@/config/api.config'
import compareSKU from './exampleDatas/compareSKU'

let { apiSeoGroupComparison } = API_ENV

export default {
  state: {
    groups: {
      group_a: [],
      group_b: [],
    },
    data: {},
    selectedPhrases: []
  },
  getters: {
    validData(state) {
      let groupAStr = state.groups.group_a.join(', ')
      let groupBStr = state.groups.group_b.join(', ')

      return {
        group_a: groupAStr,
        group_b: groupBStr,
      }
    },
    isSelectedCompareSkuPhrase: state => phrase => state.selectedPhrases.includes(phrase)
  },
  mutations: {
    changeGroups(state, groups) {
      state.groups = groups
    },
    setCompareSKUData: (state, data) => state.data = data,
    setCompareSKUExampleData(state) {
      state.data = compareSKU
    },
    selectPhrase(state, phrase) {
      state.selectedPhrases.push(phrase)
    },
    deselectPhrase(state, phrase) {
      state.selectedPhrases = state.selectedPhrases.filter(selectedPhrase => selectedPhrase !== phrase)
    },
    clearComparisonSkuSelectedPhrases(state) {
      state.selectedPhrases = []
    }
  },
  actions: {
    seoGroupComparison({ commit, getters }) {
      return new Promise((resolve, reject) => {
        axios.post(apiSeoGroupComparison, getters.validData)
          .then(response => {
            commit('setCompareSKUData', response.data)
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    compareSkuTogglePhrase({ commit }, { phrase, isSelected }) {
      if (isSelected) commit('selectPhrase', phrase)
      else commit('deselectPhrase', phrase)
    }
  }
}