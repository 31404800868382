import axios from "axios";
import API_ENV from "@/config/api.config";

const { apiConverter } = API_ENV;

export default {
  state: {
    apiKey: "4e72897679ad73a56aee0c087122a4af",
  },
  getters: {},
  mutations: {},
  actions: {
    async getConverterInfo({ state }) {
      console.log("getConverterInfo");
      try {
        const response = await axios.get(
          `${apiConverter}?access_key=${state.apiKey}`
        );
        console.log("response", response);
      } catch (error) {
        console.log(console.error(new Error(error)));
      }
    },
  },
};
