<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
    <path
      d="M2.83268 5.83106C2.71651 5.82824 2.63215 5.82191 2.5563 5.80771C1.99433 5.7025 1.55502 5.28904 1.44324 4.76012C1.41602 4.63132 1.41602 4.47644 1.41602 4.16667C1.41602 3.8569 1.41602 3.70201 1.44324 3.57321C1.55502 3.04429 1.99433 2.63083 2.5563 2.52562C2.69315 2.5 2.85772 2.5 3.18685 2.5H13.8118C14.141 2.5 14.3055 2.5 14.4424 2.52562C15.0044 2.63083 15.4437 3.04429 15.5555 3.57321C15.5827 3.70201 15.5827 3.8569 15.5827 4.16667C15.5827 4.47644 15.5827 4.63132 15.5555 4.76012C15.4437 5.28904 15.0044 5.7025 14.4424 5.80771C14.3665 5.82191 14.2822 5.82824 14.166 5.83106M7.08268 9.16667H9.91602M2.83268 5.83333H14.166V11.3C14.166 12.4201 14.166 12.9802 13.9344 13.408C13.7307 13.7843 13.4056 14.0903 13.0057 14.282C12.5512 14.5 11.9561 14.5 10.766 14.5H6.23268C5.04257 14.5 4.44751 14.5 3.99295 14.282C3.59311 14.0903 3.26802 13.7843 3.06429 13.408C2.83268 12.9802 2.83268 12.4201 2.83268 11.3V5.83333Z"
      stroke="#3A416F"
      stroke-opacity="0.7"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'Archive',
};
</script>
