const compareSku = {
  "objects": [],
  "groupA": [
    {
      "title": "Рюкзак дошкольный детский для девочки подростка авокадо",
      "price": 486,
      "cover": "https://basket-02.wb.ru/vol172/part17237/17237847/images/big/1.jpg",
      "brand_id": 1348
    }
  ],
  "groupB": [
    {
      "title": "Рюкзак дошкольный детский для девочки подростка авокадо",
      "price": 699,
      "cover": "https://basket-02.wb.ru/vol172/part17237/17237850/images/big/1.jpg",
      "brand_id": 1348
    }
  ],
  "A": [
    {
      "phrase": "0 рюкзак детский",
      "frequency": 0
    },
    {
      "phrase": "0 рюкзак для девочек",
      "frequency": 0
    },
    {
      "phrase": "5 в 1 рюкзак для девочек",
      "frequency": 0
    },
    {
      "phrase": "5 в 1 рюкзак школьный",
      "frequency": 0
    },
    {
      "phrase": "7 в 1 рюкзак",
      "frequency": 50
    },
    {
      "phrase": "suro рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "suro рюкзак школьный",
      "frequency": 0
    },
    {
      "phrase": "suro рюкзак школьный детский городской для девочек подростка",
      "frequency": 0
    },
    {
      "phrase": "аксессуары для девочек 5 лет",
      "frequency": 100
    },
    {
      "phrase": "вместительный рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "гимнастический рюкзак для девочки",
      "frequency": 60
    },
    {
      "phrase": "городской рюкзак для девочек",
      "frequency": 0
    },
    {
      "phrase": "девочки 100 штук",
      "frequency": 0
    },
    {
      "phrase": "декатлон рюкзак детский",
      "frequency": 0
    },
    {
      "phrase": "детская рюкзак",
      "frequency": 85
    },
    {
      "phrase": "детские ранцы",
      "frequency": 105
    },
    {
      "phrase": "детские рюкзак",
      "frequency": 85
    },
    {
      "phrase": "детские рюкзаки в сад",
      "frequency": 0
    },
    {
      "phrase": "детские рюкзаки в садик для девочек",
      "frequency": 0
    },
    {
      "phrase": "детские рюкзаки в школу",
      "frequency": 55
    },
    {
      "phrase": "детские рюкзаки в школу для девочек",
      "frequency": 0
    },
    {
      "phrase": "детские рюкзаки для девочек",
      "frequency": 415
    },
    {
      "phrase": "детские рюкзаки для девочек 5 лет",
      "frequency": 0
    },
    {
      "phrase": "детские рюкзаки для девочек в садик",
      "frequency": 390
    },
    {
      "phrase": "детские рюкзаки для девочек маленькие",
      "frequency": 55
    },
    {
      "phrase": "детские рюкзаки для девочек на 2",
      "frequency": 0
    },
    {
      "phrase": "детские рюкзаки для девочек школьные",
      "frequency": 0
    },
    {
      "phrase": "детские рюкзаки для малышей машины",
      "frequency": 0
    },
    {
      "phrase": "детские рюкзачки",
      "frequency": 165
    },
    {
      "phrase": "детские рюкзачки в садик",
      "frequency": 150
    },
    {
      "phrase": "детские рюкзачки для девочек",
      "frequency": 90
    },
    {
      "phrase": "детские рюкзачки для девочек в сад",
      "frequency": 0
    },
    {
      "phrase": "детские спортивный рюкзаки",
      "frequency": 0
    },
    {
      "phrase": "детские школьные рюкзаки",
      "frequency": 55
    },
    {
      "phrase": "детские школьные рюкзаки для девочек",
      "frequency": 0
    },
    {
      "phrase": "детский маленький рюкзак",
      "frequency": 95
    },
    {
      "phrase": "детский рюкзак",
      "frequency": 3900
    },
    {
      "phrase": "детский рюкзак в сад",
      "frequency": 105
    },
    {
      "phrase": "детский рюкзак в садик для девочки",
      "frequency": 70
    },
    {
      "phrase": "детский рюкзак в садик для девочки 2 лет",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак в школу девочке",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак в школу для девочек",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак девочки",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак для девочек",
      "frequency": 80
    },
    {
      "phrase": "детский рюкзак для девочек в садик",
      "frequency": 70
    },
    {
      "phrase": "детский рюкзак для девочек в школу",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак для девочек школьный",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак для девочки",
      "frequency": 2165
    },
    {
      "phrase": "детский рюкзак для девочки в садик",
      "frequency": 1915
    },
    {
      "phrase": "детский рюкзак для девочки в садик 2",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак для девочки в садик 2 года",
      "frequency": 50
    },
    {
      "phrase": "детский рюкзак для девочки в садик дошкольный",
      "frequency": 60
    },
    {
      "phrase": "детский рюкзак для девочки в садик маленький",
      "frequency": 120
    },
    {
      "phrase": "детский рюкзак для девочки в школу",
      "frequency": 120
    },
    {
      "phrase": "детский рюкзак для девочки дошкольный",
      "frequency": 160
    },
    {
      "phrase": "детский рюкзак для девочки маленький",
      "frequency": 80
    },
    {
      "phrase": "детский рюкзак для девочки маленький в сад",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак для малыша 1 годик",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак для малышей девочки",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак для сада",
      "frequency": 150
    },
    {
      "phrase": "детский рюкзак для сада маленький",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак для спорта",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак дошкольный",
      "frequency": 50
    },
    {
      "phrase": "детский рюкзак дошкольный для девочек",
      "frequency": 90
    },
    {
      "phrase": "детский рюкзачок",
      "frequency": 365
    },
    {
      "phrase": "детский рюкзачок в садик",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзачок в садик для девочки",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзачок для девочки",
      "frequency": 210
    },
    {
      "phrase": "детский рюкзачок для девочки в садик",
      "frequency": 130
    },
    {
      "phrase": "детский рюкзачок для девочки дошкольный",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзачок для девочки маленький",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзачок дошкольный",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзачок маленький",
      "frequency": 0
    },
    {
      "phrase": "детский спортивный рюкзак",
      "frequency": 100
    },
    {
      "phrase": "детский спортивный рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "детский школьный рюкзак",
      "frequency": 60
    },
    {
      "phrase": "детский школьный рюкзак для девочки",
      "frequency": 50
    },
    {
      "phrase": "для первоклассника рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "для подростка девочки рюкзак",
      "frequency": 0
    },
    {
      "phrase": "для садика девочке рюкзак",
      "frequency": 0
    },
    {
      "phrase": "дошкольные рюкзаки",
      "frequency": 1030
    },
    {
      "phrase": "дошкольные рюкзаки для девочек",
      "frequency": 55
    },
    {
      "phrase": "дошкольный рюкзак",
      "frequency": 415
    },
    {
      "phrase": "дошкольный рюкзак 5 в 1",
      "frequency": 0
    },
    {
      "phrase": "дошкольный рюкзак для девочки",
      "frequency": 225
    },
    {
      "phrase": "дошкольный рюкзак для девочки в садик",
      "frequency": 0
    },
    {
      "phrase": "застежка для рюкзака рукоделие",
      "frequency": 0
    },
    {
      "phrase": "маленькие рюкзаки для девочек",
      "frequency": 135
    },
    {
      "phrase": "маленькие рюкзаки для девочек для детей",
      "frequency": 0
    },
    {
      "phrase": "маленькие рюкзаки для девочек модные",
      "frequency": 0
    },
    {
      "phrase": "маленькие рюкзаки для детей",
      "frequency": 0
    },
    {
      "phrase": "маленькие рюкзаки для детей девочек",
      "frequency": 0
    },
    {
      "phrase": "маленькие рюкзаки для подростков",
      "frequency": 90
    },
    {
      "phrase": "маленькие рюкзаки для подростков для девочек",
      "frequency": 0
    },
    {
      "phrase": "маленькие рюкзачки детские",
      "frequency": 0
    },
    {
      "phrase": "маленькие рюкзачки для девочек",
      "frequency": 55
    },
    {
      "phrase": "маленькие рюкзачки для девочек-подросток",
      "frequency": 0
    },
    {
      "phrase": "маленькие рюкзачки для подростков",
      "frequency": 0
    },
    {
      "phrase": "маленькие штучки для девочек",
      "frequency": 0
    },
    {
      "phrase": "маленький детский рюкзак",
      "frequency": 80
    },
    {
      "phrase": "маленький детский рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "маленький рюкзак в школу для девочки",
      "frequency": 0
    },
    {
      "phrase": "маленький рюкзак детский",
      "frequency": 70
    },
    {
      "phrase": "маленький рюкзак детский для девочек",
      "frequency": 0
    },
    {
      "phrase": "маленький рюкзак для девочек",
      "frequency": 80
    },
    {
      "phrase": "маленький рюкзак для девочек подростков",
      "frequency": 0
    },
    {
      "phrase": "маленький рюкзак для девочки",
      "frequency": 525
    },
    {
      "phrase": "маленький рюкзак для девочки в сад",
      "frequency": 0
    },
    {
      "phrase": "маленький рюкзак для девочки в садик",
      "frequency": 0
    },
    {
      "phrase": "маленький рюкзак для девочки для прогулки",
      "frequency": 0
    },
    {
      "phrase": "маленький рюкзак для девочки для прогулки для детей",
      "frequency": 0
    },
    {
      "phrase": "маленький рюкзак для девочки модный",
      "frequency": 85
    },
    {
      "phrase": "маленький рюкзачок детский",
      "frequency": 45
    },
    {
      "phrase": "маленький рюкзачок детский для девочек",
      "frequency": 0
    },
    {
      "phrase": "маленький рюкзачок для подростка",
      "frequency": 0
    },
    {
      "phrase": "маленький спортивный рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "маленький школьный рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "мелочь для подростка",
      "frequency": 0
    },
    {
      "phrase": "милые рюкзаки",
      "frequency": 270
    },
    {
      "phrase": "милые рюкзаки для девочки",
      "frequency": 0
    },
    {
      "phrase": "милые рюкзаки для подростков",
      "frequency": 0
    },
    {
      "phrase": "милый рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "милый рюкзак для девочки в школу",
      "frequency": 0
    },
    {
      "phrase": "милый рюкзак для подростка",
      "frequency": 0
    },
    {
      "phrase": "милый рюкзак для школы",
      "frequency": 135
    },
    {
      "phrase": "милый рюкзак маленький",
      "frequency": 0
    },
    {
      "phrase": "милый школьный рюкзак",
      "frequency": 60
    },
    {
      "phrase": "милый школьный рюкзак для девочек",
      "frequency": 0
    },
    {
      "phrase": "мини подарки детям в школу",
      "frequency": 55
    },
    {
      "phrase": "мини рюкзак",
      "frequency": 2440
    },
    {
      "phrase": "мини рюкзак детский",
      "frequency": 65
    },
    {
      "phrase": "мини рюкзак детский для девочек",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзак для девочки",
      "frequency": 320
    },
    {
      "phrase": "мини рюкзак для девочки 10 лет",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзак для девочки в садик",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзак для девочки на прогулку",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзак для девочки подростка",
      "frequency": 55
    },
    {
      "phrase": "мини рюкзак для подростка",
      "frequency": 115
    },
    {
      "phrase": "мини рюкзак для подростка девочки",
      "frequency": 45
    },
    {
      "phrase": "мини рюкзак для подростков",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзак дошкольный",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзак маленький",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзак спортивный",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзаки",
      "frequency": 155
    },
    {
      "phrase": "мини рюкзаки детские",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзаки для девочек",
      "frequency": 45
    },
    {
      "phrase": "мини рюкзаки для детей",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзаки для школы",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзачок",
      "frequency": 205
    },
    {
      "phrase": "мини рюкзачок для девочки",
      "frequency": 60
    },
    {
      "phrase": "мини рюкзачок для детей",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзачок подростковый",
      "frequency": 0
    },
    {
      "phrase": "мини-рюкзак",
      "frequency": 65
    },
    {
      "phrase": "мини-рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "модные аксессуары для подростков",
      "frequency": 50
    },
    {
      "phrase": "модные рюкзаки 2023",
      "frequency": 60
    },
    {
      "phrase": "модные рюкзаки для девочек",
      "frequency": 45
    },
    {
      "phrase": "модные рюкзаки для подростков",
      "frequency": 0
    },
    {
      "phrase": "модные рюкзаки для подростков 2023",
      "frequency": 0
    },
    {
      "phrase": "модные рюкзаки для подростков девочек",
      "frequency": 0
    },
    {
      "phrase": "модные рюкзаки молодежные",
      "frequency": 0
    },
    {
      "phrase": "модный рюкзак в школу для девочки подростка",
      "frequency": 0
    },
    {
      "phrase": "модный рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "модный рюкзак для девочки для прогулок",
      "frequency": 0
    },
    {
      "phrase": "модный рюкзак для девочки подростка в школу",
      "frequency": 0
    },
    {
      "phrase": "модный рюкзак для подростка",
      "frequency": 50
    },
    {
      "phrase": "модный рюкзак для подростка в школу для девочек",
      "frequency": 0
    },
    {
      "phrase": "модный рюкзак для подростка девочки",
      "frequency": 0
    },
    {
      "phrase": "модный школьный рюкзак для девочки подростка",
      "frequency": 0
    },
    {
      "phrase": "модный школьный рюкзак для подростка девочки",
      "frequency": 0
    },
    {
      "phrase": "молодежный рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "небольшие рюкзаки для подростков",
      "frequency": 0
    },
    {
      "phrase": "небольшой подарок",
      "frequency": 65
    },
    {
      "phrase": "небольшой подарок девочке",
      "frequency": 0
    },
    {
      "phrase": "небольшой подарок девушке",
      "frequency": 0
    },
    {
      "phrase": "небольшой рюкзак для девочки",
      "frequency": 45
    },
    {
      "phrase": "небольшой рюкзак для подростка",
      "frequency": 60
    },
    {
      "phrase": "новогодний рюкзак",
      "frequency": 205
    },
    {
      "phrase": "облегченный рюкзак для первоклассника",
      "frequency": 60
    },
    {
      "phrase": "первоклашка рюкзак",
      "frequency": 0
    },
    {
      "phrase": "повседневный рюкзак",
      "frequency": 45
    },
    {
      "phrase": "повседневный рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "подростковые рюкзаки для девочек",
      "frequency": 0
    },
    {
      "phrase": "подростковый рюкзак для девочек",
      "frequency": 50
    },
    {
      "phrase": "портфель школьный для девочки подростка 2023",
      "frequency": 0
    },
    {
      "phrase": "портфель школьный для подростка девочки 2023",
      "frequency": 0
    },
    {
      "phrase": "портфель школьный для подростка девочки маленький",
      "frequency": 0
    },
    {
      "phrase": "ранец детский",
      "frequency": 45
    },
    {
      "phrase": "ранец детский для девочек",
      "frequency": 0
    },
    {
      "phrase": "ранец детский школьный",
      "frequency": 0
    },
    {
      "phrase": "ранец для девочки",
      "frequency": 445
    },
    {
      "phrase": "ранец для девочки подростка",
      "frequency": 45
    },
    {
      "phrase": "ранец для первоклассника для девочки",
      "frequency": 700
    },
    {
      "phrase": "ранец школьный для девочки первоклассник",
      "frequency": 110
    },
    {
      "phrase": "ранец школьный для девочки синий",
      "frequency": 0
    },
    {
      "phrase": "ранцы для девочек",
      "frequency": 85
    },
    {
      "phrase": "рюкзак  для девочки",
      "frequency": 135
    },
    {
      "phrase": "рюкзак 10 литров детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак 2 класс",
      "frequency": 50
    },
    {
      "phrase": "рюкзак 2023 модный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак 4 в 1 для девочек подростков в школу",
      "frequency": 0
    },
    {
      "phrase": "рюкзак 5 в 1 для девочек",
      "frequency": 480
    },
    {
      "phrase": "рюкзак 5 в 1 для девочек школьный",
      "frequency": 85
    },
    {
      "phrase": "рюкзак 5в 1 для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак 6 в 1",
      "frequency": 90
    },
    {
      "phrase": "рюкзак 7 в 1",
      "frequency": 260
    },
    {
      "phrase": "рюкзак 7 в 1 для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак авокадо",
      "frequency": 245
    },
    {
      "phrase": "рюкзак авокадо детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак авокадо дошкольный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак авокадо школьный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак в детский сад",
      "frequency": 280
    },
    {
      "phrase": "рюкзак в поездку девочке",
      "frequency": 0
    },
    {
      "phrase": "рюкзак в сад для девочки",
      "frequency": 90
    },
    {
      "phrase": "рюкзак в садик для девочки",
      "frequency": 800
    },
    {
      "phrase": "рюкзак в школу девочке 10 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак в школу для девочки модный подростковый",
      "frequency": 0
    },
    {
      "phrase": "рюкзак вентилируемая спина",
      "frequency": 0
    },
    {
      "phrase": "рюкзак гимнастика для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак гимнастический детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак городской для девочки",
      "frequency": 65
    },
    {
      "phrase": "рюкзак городской маленький для подростка",
      "frequency": 0
    },
    {
      "phrase": "рюкзак девочка",
      "frequency": 45
    },
    {
      "phrase": "рюкзак девочка дошкольный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак девочка подросток",
      "frequency": 0
    },
    {
      "phrase": "рюкзак девочке",
      "frequency": 150
    },
    {
      "phrase": "рюкзак девочке в садик",
      "frequency": 70
    },
    {
      "phrase": "рюкзак девочке детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак девочке дошкольный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак девочке маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак девочки",
      "frequency": 140
    },
    {
      "phrase": "рюкзак девочки в садик",
      "frequency": 45
    },
    {
      "phrase": "рюкзак девочки маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак девочки подростка",
      "frequency": 75
    },
    {
      "phrase": "рюкзак девочки подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзак декатлон детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детская в школу для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детская для девочек",
      "frequency": 55
    },
    {
      "phrase": "рюкзак детская для девочек 5 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детская для девочек в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детские",
      "frequency": 75
    },
    {
      "phrase": "рюкзак детские в школу",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детские для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детские для девочек в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский",
      "frequency": 9290
    },
    {
      "phrase": "рюкзак детский в сад",
      "frequency": 70
    },
    {
      "phrase": "рюкзак детский в сад для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский девочке",
      "frequency": 55
    },
    {
      "phrase": "рюкзак детский девочке в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек",
      "frequency": 4265
    },
    {
      "phrase": "рюкзак детский для девочек в сад",
      "frequency": 295
    },
    {
      "phrase": "рюкзак детский для девочек в садик",
      "frequency": 1810
    },
    {
      "phrase": "рюкзак детский для девочек в садик маленький",
      "frequency": 135
    },
    {
      "phrase": "рюкзак детский для девочек в садик маленький 2 года",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек в садик с 2",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек в садик с предметами",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек в школу",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек городской",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек для садика",
      "frequency": 55
    },
    {
      "phrase": "рюкзак детский для девочек дошкольный",
      "frequency": 180
    },
    {
      "phrase": "рюкзак детский для девочек дошкольный 2 года",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек дошкольный 5 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек дошкольный 6 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек маленький 1 год",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек маленький 7 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек прогулочный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек спортивный",
      "frequency": 55
    },
    {
      "phrase": "рюкзак детский для девочек спортивный маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек школьный",
      "frequency": 145
    },
    {
      "phrase": "рюкзак детский для девочки 5 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочки в сад",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочки в садик",
      "frequency": 165
    },
    {
      "phrase": "рюкзак детский для девочки дошкольный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочки маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для малыша от 1 года",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для малышей 1",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для малышей 1 год",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для малышей в садик для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для малышей девочек",
      "frequency": 70
    },
    {
      "phrase": "рюкзак детский для малышей маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для сада",
      "frequency": 45
    },
    {
      "phrase": "рюкзак детский для сада девочке",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для садика девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для танцев",
      "frequency": 45
    },
    {
      "phrase": "рюкзак детский дошкольный",
      "frequency": 355
    },
    {
      "phrase": "рюкзак детский дошкольный в садик девочка",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский дошкольный для малыше",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский дошкольный маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский дошкольный спортивный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский машина",
      "frequency": 45
    },
    {
      "phrase": "рюкзак детский сад",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский спортивный",
      "frequency": 365
    },
    {
      "phrase": "рюкзак детский спортивный для девочек",
      "frequency": 65
    },
    {
      "phrase": "рюкзак детский спортивный дошкольный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский спортивный средний",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский школа",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский школьные для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский школьный",
      "frequency": 205
    },
    {
      "phrase": "рюкзак детский школьный 1-4",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский школьный городской",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский школьный для девочек",
      "frequency": 50
    },
    {
      "phrase": "рюкзак для 2 класса",
      "frequency": 90
    },
    {
      "phrase": "рюкзак для вещей детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для вещей малыша",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для воды детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочек",
      "frequency": 1730
    },
    {
      "phrase": "рюкзак для девочек 5 в 1",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочек в садик",
      "frequency": 290
    },
    {
      "phrase": "рюкзак для девочек дошкольный",
      "frequency": 100
    },
    {
      "phrase": "рюкзак для девочек маленький",
      "frequency": 65
    },
    {
      "phrase": "рюкзак для девочек маленький на 10 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочек подростков",
      "frequency": 440
    },
    {
      "phrase": "рюкзак для девочек подростков городской",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочек подростков повседневный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочек подростков повседневный маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочек подростков повседневный подарок",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочек подростков спортивный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочек подростков школьный повседневный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочке",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки",
      "frequency": 19005
    },
    {
      "phrase": "рюкзак для девочки 10 лет",
      "frequency": 65
    },
    {
      "phrase": "рюкзак для девочки 10 лет для прогулок",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки 10 лет маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки 10 лет модный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки 2 года в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки 4",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки 4 года",
      "frequency": 90
    },
    {
      "phrase": "рюкзак для девочки 5 в 1",
      "frequency": 70
    },
    {
      "phrase": "рюкзак для девочки 5 лет",
      "frequency": 105
    },
    {
      "phrase": "рюкзак для девочки 6 лет",
      "frequency": 105
    },
    {
      "phrase": "рюкзак для девочки в детский сад",
      "frequency": 80
    },
    {
      "phrase": "рюкзак для девочки в садик",
      "frequency": 5010
    },
    {
      "phrase": "рюкзак для девочки в садик 4 года",
      "frequency": 45
    },
    {
      "phrase": "рюкзак для девочки в садик 5 лет",
      "frequency": 70
    },
    {
      "phrase": "рюкзак для девочки в садик 6",
      "frequency": 45
    },
    {
      "phrase": "рюкзак для девочки в садик 6 лет",
      "frequency": 165
    },
    {
      "phrase": "рюкзак для девочки в садик маленький",
      "frequency": 210
    },
    {
      "phrase": "рюкзак для девочки в садик от 2",
      "frequency": 140
    },
    {
      "phrase": "рюкзак для девочки в садик от 2 лет",
      "frequency": 70
    },
    {
      "phrase": "рюкзак для девочки городской",
      "frequency": 50
    },
    {
      "phrase": "рюкзак для девочки городской маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки детский в сад",
      "frequency": 100
    },
    {
      "phrase": "рюкзак для девочки детский в садик",
      "frequency": 85
    },
    {
      "phrase": "рюкзак для девочки детский дошкольный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки детский маленький на 2 года",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки детский повседневный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки детский с карманами",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки детский школьный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки для прогулок",
      "frequency": 75
    },
    {
      "phrase": "рюкзак для девочки для спорта",
      "frequency": 60
    },
    {
      "phrase": "рюкзак для девочки для танцев",
      "frequency": 70
    },
    {
      "phrase": "рюкзак для девочки для танцев маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки для танцев подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки дошкольный",
      "frequency": 1405
    },
    {
      "phrase": "рюкзак для девочки дошкольный для танцев",
      "frequency": 60
    },
    {
      "phrase": "рюкзак для девочки дошкольный небольшой",
      "frequency": 170
    },
    {
      "phrase": "рюкзак для девочки дошкольный облегченный",
      "frequency": 75
    },
    {
      "phrase": "рюкзак для девочки маленький в садик",
      "frequency": 155
    },
    {
      "phrase": "рюкзак для девочки маленький для малышей",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки маленький для прогулок",
      "frequency": 140
    },
    {
      "phrase": "рюкзак для девочки маленький школьный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки малышам",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки мини",
      "frequency": 85
    },
    {
      "phrase": "рюкзак для девочки на прогулку",
      "frequency": 55
    },
    {
      "phrase": "рюкзак для девочки на танцы",
      "frequency": 85
    },
    {
      "phrase": "рюкзак для девочки небольшой",
      "frequency": 55
    },
    {
      "phrase": "рюкзак для девочки подростка маленький",
      "frequency": 90
    },
    {
      "phrase": "рюкзак для девочки подростка модные",
      "frequency": 100
    },
    {
      "phrase": "рюкзак для девочки подростка на прогулку",
      "frequency": 330
    },
    {
      "phrase": "рюкзак для девочки подростка повседневный",
      "frequency": 115
    },
    {
      "phrase": "рюкзак для девочки подростка повседневный школьный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки садик",
      "frequency": 55
    },
    {
      "phrase": "рюкзак для девочки спортивный",
      "frequency": 615
    },
    {
      "phrase": "рюкзак для девочки спортивный городской",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки спортивный для гимнастики",
      "frequency": 95
    },
    {
      "phrase": "рюкзак для девочки спортивный для танцев",
      "frequency": 85
    },
    {
      "phrase": "рюкзак для девочки спортивный на плавание",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки спортивный средний",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки школьный подростковый модный",
      "frequency": 140
    },
    {
      "phrase": "рюкзак для детей",
      "frequency": 315
    },
    {
      "phrase": "рюкзак для детей 10 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для детей в сад",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для детей детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для детей школьный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для детей школьный для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для дети",
      "frequency": 85
    },
    {
      "phrase": "рюкзак для детский для сада",
      "frequency": 80
    },
    {
      "phrase": "рюкзак для детского сада для девочки",
      "frequency": 65
    },
    {
      "phrase": "рюкзак для дошкольника",
      "frequency": 335
    },
    {
      "phrase": "рюкзак для дошкольников",
      "frequency": 60
    },
    {
      "phrase": "рюкзак для дошкольников девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для маленьких девочек",
      "frequency": 50
    },
    {
      "phrase": "рюкзак для маленькой девочки",
      "frequency": 60
    },
    {
      "phrase": "рюкзак для маленькой девочки в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для маленькой девочки детский сад",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для малыша",
      "frequency": 780
    },
    {
      "phrase": "рюкзак для малыша 1 год",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для малыша 1-2",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для малыша 1-2 года",
      "frequency": 70
    },
    {
      "phrase": "рюкзак для малыша 2",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для малыша в сад",
      "frequency": 105
    },
    {
      "phrase": "рюкзак для малыша маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для малышей",
      "frequency": 1845
    },
    {
      "phrase": "рюкзак для малышей в сад",
      "frequency": 300
    },
    {
      "phrase": "рюкзак для малышей в сад девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для малышей в садик для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для малышки",
      "frequency": 55
    },
    {
      "phrase": "рюкзак для малышки девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для одежды детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для первоклассника девочки",
      "frequency": 200
    },
    {
      "phrase": "рюкзак для первоклассника девочки маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для первоклассника девочки школьный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для первоклашки",
      "frequency": 45
    },
    {
      "phrase": "рюкзак для первоклашки девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для плавания спортивный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для подростка девочки",
      "frequency": 1660
    },
    {
      "phrase": "рюкзак для подростка девочки в школу модный",
      "frequency": 55
    },
    {
      "phrase": "рюкзак для подростка девочки маленький городской",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для подростка девочки модный",
      "frequency": 130
    },
    {
      "phrase": "рюкзак для подростка девочки модный в школу",
      "frequency": 55
    },
    {
      "phrase": "рюкзак для подростка маленький",
      "frequency": 50
    },
    {
      "phrase": "рюкзак для подростка маленький для прогулок",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для подростка маленький спортивный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для подростка модный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для подростка спортивный",
      "frequency": 60
    },
    {
      "phrase": "рюкзак для подростки девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для подростков девочек",
      "frequency": 395
    },
    {
      "phrase": "рюкзак для подростков девочек маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для подростков спортивный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для поездок вместительный",
      "frequency": 55
    },
    {
      "phrase": "рюкзак для поездок для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для прогулки для девочек",
      "frequency": 50
    },
    {
      "phrase": "рюкзак для прогулки для подростка",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для прогулок девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для прогулок для девочек",
      "frequency": 65
    },
    {
      "phrase": "рюкзак для прогулок для подростков",
      "frequency": 50
    },
    {
      "phrase": "рюкзак для прогулок маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для прогулок с малышом",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для путешествий детский",
      "frequency": 90
    },
    {
      "phrase": "рюкзак для ребенка",
      "frequency": 120
    },
    {
      "phrase": "рюкзак для ребенка 4 года",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для ребенка в сад",
      "frequency": 60
    },
    {
      "phrase": "рюкзак для ребенка девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для ребенка для спорта",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для сада",
      "frequency": 305
    },
    {
      "phrase": "рюкзак для сада для девочки",
      "frequency": 75
    },
    {
      "phrase": "рюкзак для садика девочки",
      "frequency": 55
    },
    {
      "phrase": "рюкзак для секций",
      "frequency": 60
    },
    {
      "phrase": "рюкзак для секций для детей",
      "frequency": 55
    },
    {
      "phrase": "рюкзак для спорта детский",
      "frequency": 120
    },
    {
      "phrase": "рюкзак для спорта детский для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для спорта для девочки",
      "frequency": 180
    },
    {
      "phrase": "рюкзак для спорта подростковый",
      "frequency": 95
    },
    {
      "phrase": "рюкзак для спорта спортивный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для спортивной одежды",
      "frequency": 50
    },
    {
      "phrase": "рюкзак для спортивной одежды в школу",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для спортивной формы детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для спортивной формы для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для танцев девочке",
      "frequency": 285
    },
    {
      "phrase": "рюкзак для танцев детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для танцев и гимнастики",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для танцевальной формы",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для формы детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для формы для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для формы на тренировку",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для формы спортивной",
      "frequency": 75
    },
    {
      "phrase": "рюкзак для школы для девочек 10 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для школы для девочки 10 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак дошкольника",
      "frequency": 170
    },
    {
      "phrase": "рюкзак дошкольника для девочки",
      "frequency": 65
    },
    {
      "phrase": "рюкзак дошкольников для девочки",
      "frequency": 65
    },
    {
      "phrase": "рюкзак дошкольный в садик",
      "frequency": 60
    },
    {
      "phrase": "рюкзак дошкольный для девочки 6 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак дошкольный для девочки в садик",
      "frequency": 215
    },
    {
      "phrase": "рюкзак к школе для первоклассника",
      "frequency": 0
    },
    {
      "phrase": "рюкзак крепкий",
      "frequency": 60
    },
    {
      "phrase": "рюкзак крепкий школьный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак маленький детский для девочек",
      "frequency": 45
    },
    {
      "phrase": "рюкзак маленький для девочки",
      "frequency": 490
    },
    {
      "phrase": "рюкзак маленький для девочки в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзак маленький для девочки для прогулки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак маленький для девочки дошкольный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак маленький для девочки подростка",
      "frequency": 130
    },
    {
      "phrase": "рюкзак маленький для девочки школьный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак маленький для подростка девочки",
      "frequency": 80
    },
    {
      "phrase": "рюкзак маленький туристический",
      "frequency": 0
    },
    {
      "phrase": "рюкзак маленький школьный",
      "frequency": 95
    },
    {
      "phrase": "рюкзак маленький школьный для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак малышам",
      "frequency": 60
    },
    {
      "phrase": "рюкзак малышам в сад",
      "frequency": 0
    },
    {
      "phrase": "рюкзак малышке",
      "frequency": 45
    },
    {
      "phrase": "рюкзак малышу",
      "frequency": 70
    },
    {
      "phrase": "рюкзак малышу в сад",
      "frequency": 0
    },
    {
      "phrase": "рюкзак милый",
      "frequency": 1270
    },
    {
      "phrase": "рюкзак милый для школы",
      "frequency": 65
    },
    {
      "phrase": "рюкзак милый маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак мини",
      "frequency": 1395
    },
    {
      "phrase": "рюкзак мини для девочки",
      "frequency": 95
    },
    {
      "phrase": "рюкзак мини для девочки маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак модный для подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзак молодежный для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак молодежный для подростка",
      "frequency": 155
    },
    {
      "phrase": "рюкзак на гимнастику девочке",
      "frequency": 0
    },
    {
      "phrase": "рюкзак на гимнастику детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак на девочку",
      "frequency": 60
    },
    {
      "phrase": "рюкзак на девочку 10 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак на девочку дошкольный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак на девочку подростка",
      "frequency": 0
    },
    {
      "phrase": "рюкзак на прогулку для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак на прогулку для подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзак на прогулку мини",
      "frequency": 0
    },
    {
      "phrase": "рюкзак небольшой для девочки",
      "frequency": 60
    },
    {
      "phrase": "рюкзак небольшой спортивный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак непромокаемый для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак новогодний детский",
      "frequency": 140
    },
    {
      "phrase": "рюкзак первоклассника для девочки",
      "frequency": 120
    },
    {
      "phrase": "рюкзак повседневный",
      "frequency": 315
    },
    {
      "phrase": "рюкзак повседневный городской",
      "frequency": 0
    },
    {
      "phrase": "рюкзак повседневный детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак повседневный спортивный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак подростковый для девочек",
      "frequency": 1245
    },
    {
      "phrase": "рюкзак подростковый для девочек маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак подростковый для девочки",
      "frequency": 150
    },
    {
      "phrase": "рюкзак подростку девочке",
      "frequency": 0
    },
    {
      "phrase": "рюкзак прогулочный детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак прогулочный для девочки подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзак прогулочный маленький для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак путешествия детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак пять в одном для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак ранец для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак ранец для первоклассника",
      "frequency": 0
    },
    {
      "phrase": "рюкзак рюкзак для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак с авокадо",
      "frequency": 340
    },
    {
      "phrase": "рюкзак с авокадо детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак с авокадо для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак с авокадо с школу",
      "frequency": 0
    },
    {
      "phrase": "рюкзак с авокадо школьный",
      "frequency": 50
    },
    {
      "phrase": "рюкзак с авокадо школьный для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак с машиной",
      "frequency": 45
    },
    {
      "phrase": "рюкзак с машиной детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак с машиной школьный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак с подарками на новый год",
      "frequency": 0
    },
    {
      "phrase": "рюкзак с принтом для подростка",
      "frequency": 125
    },
    {
      "phrase": "рюкзак с рисунками для подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзак с рисунком для подростка",
      "frequency": 0
    },
    {
      "phrase": "рюкзак светлый",
      "frequency": 555
    },
    {
      "phrase": "рюкзак светлый маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак светлый текстиль",
      "frequency": 0
    },
    {
      "phrase": "рюкзак светлый школьный",
      "frequency": 75
    },
    {
      "phrase": "рюкзак сетка",
      "frequency": 50
    },
    {
      "phrase": "рюкзак сетка для плавания",
      "frequency": 0
    },
    {
      "phrase": "рюкзак синий",
      "frequency": 350
    },
    {
      "phrase": "рюкзак синий для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак со спинкой для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак со спинкой для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак со спинкой для подростка",
      "frequency": 60
    },
    {
      "phrase": "рюкзак спортивный детская",
      "frequency": 0
    },
    {
      "phrase": "рюкзак спортивный детский",
      "frequency": 265
    },
    {
      "phrase": "рюкзак спортивный детский для гимнастики",
      "frequency": 0
    },
    {
      "phrase": "рюкзак спортивный детский для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак спортивный детский для девочки",
      "frequency": 50
    },
    {
      "phrase": "рюкзак спортивный для девочки",
      "frequency": 830
    },
    {
      "phrase": "рюкзак спортивный для девочки маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак спортивный для девочки подростка",
      "frequency": 0
    },
    {
      "phrase": "рюкзак спортивный для девочки средний",
      "frequency": 0
    },
    {
      "phrase": "рюкзак спортивный для подростка девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак спортивный для подростка маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак спортивный для подростка с принтом",
      "frequency": 0
    },
    {
      "phrase": "рюкзак спортивный маленький для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак сумка для девочки подростка",
      "frequency": 0
    },
    {
      "phrase": "рюкзак текстиль маленький",
      "frequency": 95
    },
    {
      "phrase": "рюкзак текстильный для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак текстильный спортивный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак туристический детский",
      "frequency": 45
    },
    {
      "phrase": "рюкзак через плечо детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак через плечо для девочки",
      "frequency": 65
    },
    {
      "phrase": "рюкзак школьника для девочки подростка",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьника для подростка",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный 7 в 1",
      "frequency": 50
    },
    {
      "phrase": "рюкзак школьный авокадо",
      "frequency": 65
    },
    {
      "phrase": "рюкзак школьный девочке 10 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный детский",
      "frequency": 495
    },
    {
      "phrase": "рюкзак школьный детский городской",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный детский городской для девочек подростка",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный детский для девочки",
      "frequency": 115
    },
    {
      "phrase": "рюкзак школьный для девочек детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный для девочек подростков модный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный для девочки 10 лет",
      "frequency": 80
    },
    {
      "phrase": "рюкзак школьный для девочки 5 в 1",
      "frequency": 90
    },
    {
      "phrase": "рюкзак школьный для девочки в садик",
      "frequency": 60
    },
    {
      "phrase": "рюкзак школьный для девочки маленький",
      "frequency": 80
    },
    {
      "phrase": "рюкзак школьный для девочки с авокадо",
      "frequency": 85
    },
    {
      "phrase": "рюкзак школьный для детей",
      "frequency": 75
    },
    {
      "phrase": "рюкзак школьный для первоклассника облегченный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный для подростка девочки модный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный для подростков девочек модный",
      "frequency": 380
    },
    {
      "phrase": "рюкзак школьный для подростков девочек модный 2023",
      "frequency": 60
    },
    {
      "phrase": "рюкзак школьный для подростков модный для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный маленький для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный небольшой для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный подростковый для девочки 4 в 1",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный с авокадо",
      "frequency": 130
    },
    {
      "phrase": "рюкзак школьный светлый",
      "frequency": 220
    },
    {
      "phrase": "рюкзак школьный синий для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный спортивный 5 в 1",
      "frequency": 0
    },
    {
      "phrase": "рюкзака детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки 2023 для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки в школу детям",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки детские",
      "frequency": 280
    },
    {
      "phrase": "рюкзаки детские в школу",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки детские в школу для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки детские для девочек",
      "frequency": 110
    },
    {
      "phrase": "рюкзаки детские для девочек в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки детские для девочек на прогулку",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки детские для девочек школьные",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки детские для сада",
      "frequency": 130
    },
    {
      "phrase": "рюкзаки детские для сада для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки детские школьные",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки детские школьные для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для девочек",
      "frequency": 2775
    },
    {
      "phrase": "рюкзаки для девочек 5 в 1",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для девочек в садик",
      "frequency": 120
    },
    {
      "phrase": "рюкзаки для девочек в садик 2 года",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для девочек в садик для 6 лет.",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для девочек для прогулки",
      "frequency": 130
    },
    {
      "phrase": "рюкзаки для девочек модные",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для девочек первоклассников",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для девочек подростков модные",
      "frequency": 75
    },
    {
      "phrase": "рюкзаки для девочек садик",
      "frequency": 145
    },
    {
      "phrase": "рюкзаки для девочки",
      "frequency": 125
    },
    {
      "phrase": "рюкзаки для девочки в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для девочки маленькие",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для девочки подростка",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для детей",
      "frequency": 210
    },
    {
      "phrase": "рюкзаки для детей спорт",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для малыша",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для малышей",
      "frequency": 150
    },
    {
      "phrase": "рюкзаки для малышей 4 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для малышей девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для подростка девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для подростков девочек школьный яркий",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для подростков для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для подростков спортивные",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для подростков спортивные синий",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для прогулок девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для садика",
      "frequency": 50
    },
    {
      "phrase": "рюкзаки для школы для детей",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки маленькие для девочек",
      "frequency": 70
    },
    {
      "phrase": "рюкзаки маленькие для прогулки",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки милые",
      "frequency": 90
    },
    {
      "phrase": "рюкзаки мини",
      "frequency": 70
    },
    {
      "phrase": "рюкзаки мини для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки мини для подростка",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки модные 2023",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки модные для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки модные для подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки подростковые для девочек",
      "frequency": 100
    },
    {
      "phrase": "рюкзаки подростковые для девочек для прогулки",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки спортивные для подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки спортивные молодежные",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки школьные для девочек подростков модные",
      "frequency": 0
    },
    {
      "phrase": "рюкзачек для девочки",
      "frequency": 65
    },
    {
      "phrase": "рюкзачки детские",
      "frequency": 0
    },
    {
      "phrase": "рюкзачки детские для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзачки детские для девочек маленькие",
      "frequency": 0
    },
    {
      "phrase": "рюкзачки для девочек",
      "frequency": 145
    },
    {
      "phrase": "рюкзачки для девочек в садик",
      "frequency": 45
    },
    {
      "phrase": "рюкзачки для девочек детские",
      "frequency": 0
    },
    {
      "phrase": "рюкзачки для девочек для детского сада",
      "frequency": 50
    },
    {
      "phrase": "рюкзачки для девочек маленькие",
      "frequency": 0
    },
    {
      "phrase": "рюкзачки для девочек подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзачки для маленьких девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзачки для малышей",
      "frequency": 250
    },
    {
      "phrase": "рюкзачки для малышей в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок в сад",
      "frequency": 50
    },
    {
      "phrase": "рюкзачок в садик для девочки",
      "frequency": 95
    },
    {
      "phrase": "рюкзачок детский",
      "frequency": 590
    },
    {
      "phrase": "рюкзачок детский в садик",
      "frequency": 65
    },
    {
      "phrase": "рюкзачок детский для девочек",
      "frequency": 60
    },
    {
      "phrase": "рюкзачок детский для девочек для малышей",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок детский для девочки",
      "frequency": 135
    },
    {
      "phrase": "рюкзачок детский для девочки 5 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок детский для девочки маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок детский маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для девочки",
      "frequency": 1305
    },
    {
      "phrase": "рюкзачок для девочки детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для девочки детский маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для девочки для прогулок",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для девочки дошкольный",
      "frequency": 55
    },
    {
      "phrase": "рюкзачок для девочки дошкольный 6 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для девочки маленький",
      "frequency": 135
    },
    {
      "phrase": "рюкзачок для девочки маленький для садика",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для девочки маленький подарочный",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для девочки мини",
      "frequency": 45
    },
    {
      "phrase": "рюкзачок для девочки подростка",
      "frequency": 110
    },
    {
      "phrase": "рюкзачок для девочки подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для девочки подростков для прогулок",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для девочки подростков мини",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для девочки подростков модный",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для девочки-подростка маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для малыша",
      "frequency": 140
    },
    {
      "phrase": "рюкзачок для малыша 1 год",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для малыша 1-2",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для малыша в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для малышей",
      "frequency": 210
    },
    {
      "phrase": "рюкзачок для малышей 1-2",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для малышей 1-2 года",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для малышей в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для малышей девочка",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для малышей маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для садика",
      "frequency": 130
    },
    {
      "phrase": "рюкзачок для садика для девочки",
      "frequency": 50
    },
    {
      "phrase": "рюкзачок маленький детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок маленький для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок маленький для девочки 10 лет",
      "frequency": 50
    },
    {
      "phrase": "светлый рюкзак",
      "frequency": 90
    },
    {
      "phrase": "светлый рюкзак школьный",
      "frequency": 0
    },
    {
      "phrase": "сетка для рюкзака",
      "frequency": 60
    },
    {
      "phrase": "синий рюкзак",
      "frequency": 105
    },
    {
      "phrase": "синий рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "синий рюкзак спортивный",
      "frequency": 0
    },
    {
      "phrase": "синий рюкзак школьный для девочки",
      "frequency": 0
    },
    {
      "phrase": "спортивные рюкзаки детские",
      "frequency": 0
    },
    {
      "phrase": "спортивные рюкзаки для девочек",
      "frequency": 0
    },
    {
      "phrase": "спортивные рюкзаки для подростков",
      "frequency": 0
    },
    {
      "phrase": "спортивные формы рюкзак",
      "frequency": 80
    },
    {
      "phrase": "спортивный детский рюкзак",
      "frequency": 135
    },
    {
      "phrase": "спортивный детский рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "спортивный рюкзак детский",
      "frequency": 160
    },
    {
      "phrase": "спортивный рюкзак для девочки",
      "frequency": 370
    },
    {
      "phrase": "спортивный рюкзак для девочки для гимнастики",
      "frequency": 0
    },
    {
      "phrase": "спортивный рюкзак для девочки для танцев",
      "frequency": 0
    },
    {
      "phrase": "спортивный рюкзак для девочки подростка",
      "frequency": 0
    },
    {
      "phrase": "спортивный рюкзак для подростка",
      "frequency": 65
    },
    {
      "phrase": "спортивный рюкзак для подростка девочки",
      "frequency": 0
    },
    {
      "phrase": "сумка для девочки подростков рюкзак",
      "frequency": 0
    },
    {
      "phrase": "сумка рюкзак для девочки подростков",
      "frequency": 0
    },
    {
      "phrase": "тряпочный рюкзак мини",
      "frequency": 0
    },
    {
      "phrase": "школьник рюкзаки девочки",
      "frequency": 0
    },
    {
      "phrase": "школьные рюкзаки для девочки 10 лет",
      "frequency": 0
    },
    {
      "phrase": "школьный детский рюкзак",
      "frequency": 50
    },
    {
      "phrase": "школьный детский рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "школьный портфель для девочек 10 лет",
      "frequency": 0
    },
    {
      "phrase": "школьный рюкзак 5 в 1 для девочки",
      "frequency": 0
    },
    {
      "phrase": "школьный рюкзак авокадо",
      "frequency": 0
    },
    {
      "phrase": "школьный рюкзак детский",
      "frequency": 65
    },
    {
      "phrase": "школьный рюкзак детский для девочек",
      "frequency": 0
    },
    {
      "phrase": "школьный рюкзак для девочки первоклассника",
      "frequency": 60
    },
    {
      "phrase": "школьный рюкзак для подростка девочки модный",
      "frequency": 70
    },
    {
      "phrase": "штучки для девочек подростков",
      "frequency": 295
    },
    {
      "phrase": "яркий рюкзак для девочки",
      "frequency": 0
    }
  ],
  "B": [
    {
      "phrase": "0 рюкзак для девочек",
      "frequency": 0
    },
    {
      "phrase": "7 в 1 рюкзак",
      "frequency": 50
    },
    {
      "phrase": "suro рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "suro рюкзак школьный",
      "frequency": 0
    },
    {
      "phrase": "suro рюкзак школьный детский городской для девочек подростка",
      "frequency": 0
    },
    {
      "phrase": "аксессуары для рюкзака рукоделие",
      "frequency": 0
    },
    {
      "phrase": "аксессуары для танцев",
      "frequency": 155
    },
    {
      "phrase": "аксессуары для танцев девочки",
      "frequency": 0
    },
    {
      "phrase": "гимнастический рюкзак для девочки",
      "frequency": 60
    },
    {
      "phrase": "городской рюкзак для девочек",
      "frequency": 0
    },
    {
      "phrase": "девочки 100 штук",
      "frequency": 0
    },
    {
      "phrase": "декатлон рюкзак детский",
      "frequency": 0
    },
    {
      "phrase": "детские рюкзаки в садик для девочек",
      "frequency": 0
    },
    {
      "phrase": "детские рюкзаки для девочек",
      "frequency": 415
    },
    {
      "phrase": "детские рюкзаки для девочек 5 лет",
      "frequency": 0
    },
    {
      "phrase": "детские рюкзаки для девочек в садик",
      "frequency": 390
    },
    {
      "phrase": "детские рюкзаки для девочек дошкольный",
      "frequency": 0
    },
    {
      "phrase": "детские рюкзаки для девочек маленькие",
      "frequency": 55
    },
    {
      "phrase": "детские рюкзаки для девочек на 2",
      "frequency": 0
    },
    {
      "phrase": "детские рюкзаки для девочек на 2 года",
      "frequency": 0
    },
    {
      "phrase": "детские рюкзаки для малышей машины",
      "frequency": 0
    },
    {
      "phrase": "детские рюкзачки для девочек",
      "frequency": 90
    },
    {
      "phrase": "детские рюкзачки для девочек в сад",
      "frequency": 0
    },
    {
      "phrase": "детский маленький рюкзак",
      "frequency": 95
    },
    {
      "phrase": "детский маленький рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак в садик для девочки",
      "frequency": 70
    },
    {
      "phrase": "детский рюкзак в садик для девочки 2 лет",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак девочки",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак для девочек",
      "frequency": 80
    },
    {
      "phrase": "детский рюкзак для девочек в садик",
      "frequency": 70
    },
    {
      "phrase": "детский рюкзак для девочки в сад",
      "frequency": 175
    },
    {
      "phrase": "детский рюкзак для девочки в садик",
      "frequency": 1915
    },
    {
      "phrase": "детский рюкзак для девочки в садик дошкольный",
      "frequency": 60
    },
    {
      "phrase": "детский рюкзак для девочки в садик маленький",
      "frequency": 120
    },
    {
      "phrase": "детский рюкзак для девочки дошкольный",
      "frequency": 160
    },
    {
      "phrase": "детский рюкзак для девочки дошкольный 2 года",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак для девочки дошкольный 5 лет",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак для девочки маленький",
      "frequency": 80
    },
    {
      "phrase": "детский рюкзак для девочки маленький в сад",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак для малыша",
      "frequency": 85
    },
    {
      "phrase": "детский рюкзак для малышей от года",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак для спорта",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак дошкольный",
      "frequency": 50
    },
    {
      "phrase": "детский рюкзак дошкольный для девочек",
      "frequency": 90
    },
    {
      "phrase": "детский рюкзак дошкольный для девочки",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзачок для девочки в садик",
      "frequency": 130
    },
    {
      "phrase": "детский рюкзачок для девочки дошкольный",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзачок для девочки маленький",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзачок маленький",
      "frequency": 0
    },
    {
      "phrase": "детский спортивный рюкзак",
      "frequency": 100
    },
    {
      "phrase": "детский спортивный рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "для первоклассника рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "для садика девочке рюкзак",
      "frequency": 0
    },
    {
      "phrase": "дошкольные рюкзаки для девочек",
      "frequency": 55
    },
    {
      "phrase": "дошкольный рюкзак 5 в 1",
      "frequency": 0
    },
    {
      "phrase": "дошкольный рюкзак для девочки",
      "frequency": 225
    },
    {
      "phrase": "дошкольный рюкзак для девочки в садик",
      "frequency": 0
    },
    {
      "phrase": "застежка для рюкзака рукоделие",
      "frequency": 0
    },
    {
      "phrase": "красные аксессуары для девочки",
      "frequency": 0
    },
    {
      "phrase": "красный рюкзак",
      "frequency": 280
    },
    {
      "phrase": "красный рюкзак в школу",
      "frequency": 0
    },
    {
      "phrase": "красный рюкзак спортивный",
      "frequency": 0
    },
    {
      "phrase": "маленькие рюкзаки для девочек",
      "frequency": 135
    },
    {
      "phrase": "маленькие рюкзачки детские",
      "frequency": 0
    },
    {
      "phrase": "маленькие рюкзачки для девочек",
      "frequency": 55
    },
    {
      "phrase": "маленькие штучки для девочек",
      "frequency": 0
    },
    {
      "phrase": "маленький детский рюкзак",
      "frequency": 80
    },
    {
      "phrase": "маленький детский рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "маленький рюкзак в школу",
      "frequency": 55
    },
    {
      "phrase": "маленький рюкзак в школу для девочки",
      "frequency": 0
    },
    {
      "phrase": "маленький рюкзак детский",
      "frequency": 70
    },
    {
      "phrase": "маленький рюкзак для девочек",
      "frequency": 80
    },
    {
      "phrase": "маленький рюкзак для девочек подростков",
      "frequency": 0
    },
    {
      "phrase": "маленький рюкзак для девочки в садик",
      "frequency": 0
    },
    {
      "phrase": "маленький рюкзак для девочки в школу",
      "frequency": 0
    },
    {
      "phrase": "маленький рюкзак для девочки для прогулки",
      "frequency": 0
    },
    {
      "phrase": "маленький рюкзак для девочки для прогулки для детей",
      "frequency": 0
    },
    {
      "phrase": "маленький рюкзак для девочки модный",
      "frequency": 85
    },
    {
      "phrase": "маленький рюкзак для девочки подростков",
      "frequency": 105
    },
    {
      "phrase": "маленький рюкзаки для девочек",
      "frequency": 0
    },
    {
      "phrase": "маленький рюкзачок детский",
      "frequency": 45
    },
    {
      "phrase": "маленький рюкзачок детский для девочек",
      "frequency": 0
    },
    {
      "phrase": "маленький рюкзачок для девочки",
      "frequency": 220
    },
    {
      "phrase": "маленький спортивный рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "маленький школьный рюкзак",
      "frequency": 65
    },
    {
      "phrase": "маленький школьный рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "мелочь для подростка",
      "frequency": 0
    },
    {
      "phrase": "милые рюкзаки",
      "frequency": 270
    },
    {
      "phrase": "милые рюкзаки для подростков",
      "frequency": 0
    },
    {
      "phrase": "милый рюкзак",
      "frequency": 800
    },
    {
      "phrase": "милый рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "милый рюкзак для девочки в школу",
      "frequency": 0
    },
    {
      "phrase": "милый рюкзак для подростка",
      "frequency": 0
    },
    {
      "phrase": "милый рюкзак маленький",
      "frequency": 0
    },
    {
      "phrase": "милый школьный рюкзак для девочек",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзак детский",
      "frequency": 65
    },
    {
      "phrase": "мини рюкзак детский для девочек",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзак для девочки",
      "frequency": 320
    },
    {
      "phrase": "мини рюкзак для девочки 10 лет",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзак для девочки в садик",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзак для девочки на прогулку",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзак для девочки подростка",
      "frequency": 55
    },
    {
      "phrase": "мини рюкзак для подростка",
      "frequency": 115
    },
    {
      "phrase": "мини рюкзак для подростка девочки",
      "frequency": 45
    },
    {
      "phrase": "мини рюкзак дошкольный",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзаки детские",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзаки для девочек",
      "frequency": 45
    },
    {
      "phrase": "мини рюкзаки для детей",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзаки для подростков",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзачок",
      "frequency": 205
    },
    {
      "phrase": "мини рюкзачок для девочки",
      "frequency": 60
    },
    {
      "phrase": "мини рюкзачок для детей",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзачок подростковый",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзачок спортивный",
      "frequency": 0
    },
    {
      "phrase": "мини-рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "мини-рюкзачок",
      "frequency": 45
    },
    {
      "phrase": "модные рюкзаки для девочек",
      "frequency": 45
    },
    {
      "phrase": "модные рюкзаки для девочек подростков",
      "frequency": 0
    },
    {
      "phrase": "модные рюкзаки для подростков 2023",
      "frequency": 0
    },
    {
      "phrase": "модные рюкзаки для подростков девочек",
      "frequency": 0
    },
    {
      "phrase": "модный рюкзак в школу для девочки подростка",
      "frequency": 0
    },
    {
      "phrase": "модный рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "модный рюкзак для девочки подростка в школу",
      "frequency": 0
    },
    {
      "phrase": "модный рюкзак для девочки подростков",
      "frequency": 0
    },
    {
      "phrase": "модный рюкзак для подростка в школу для девочек",
      "frequency": 0
    },
    {
      "phrase": "модный школьный рюкзак для девочки подростка",
      "frequency": 0
    },
    {
      "phrase": "модный школьный рюкзак для подростка девочки",
      "frequency": 0
    },
    {
      "phrase": "молодежный рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "небольшие рюкзаки для подростков",
      "frequency": 0
    },
    {
      "phrase": "небольшой подарок",
      "frequency": 65
    },
    {
      "phrase": "небольшой подарок девочке",
      "frequency": 0
    },
    {
      "phrase": "небольшой рюкзак для девочки",
      "frequency": 45
    },
    {
      "phrase": "небольшой рюкзак для подростка",
      "frequency": 60
    },
    {
      "phrase": "облегченный рюкзак для первоклассника",
      "frequency": 60
    },
    {
      "phrase": "первоклашка рюкзак",
      "frequency": 0
    },
    {
      "phrase": "повседневный рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "подростковый рюкзак для девочек",
      "frequency": 50
    },
    {
      "phrase": "портфель школьный для подростка девочки качественный",
      "frequency": 50
    },
    {
      "phrase": "портфель школьный для подростка девочки маленький",
      "frequency": 0
    },
    {
      "phrase": "ранец детский для девочек",
      "frequency": 0
    },
    {
      "phrase": "ранец для девочки подростка",
      "frequency": 45
    },
    {
      "phrase": "ранец школьный для девочки первоклассник",
      "frequency": 110
    },
    {
      "phrase": "ранец школьный для первоклассника девочки",
      "frequency": 0
    },
    {
      "phrase": "ранцы для девочек",
      "frequency": 85
    },
    {
      "phrase": "рюкзак  для девочки",
      "frequency": 135
    },
    {
      "phrase": "рюкзак 10 литров детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак 100 литров туристический",
      "frequency": 60
    },
    {
      "phrase": "рюкзак 4 в 1 для девочек подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзак 4 в 1 для девочек подростков в школу",
      "frequency": 0
    },
    {
      "phrase": "рюкзак 5 в 1 для девочек",
      "frequency": 480
    },
    {
      "phrase": "рюкзак 5 в 1 для девочек школьный",
      "frequency": 85
    },
    {
      "phrase": "рюкзак 5 в 1 для подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзак 5 в одном для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак 5в 1",
      "frequency": 45
    },
    {
      "phrase": "рюкзак 5в 1 для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак 6 в 1",
      "frequency": 90
    },
    {
      "phrase": "рюкзак 6 в 1 для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак 7 в 1",
      "frequency": 260
    },
    {
      "phrase": "рюкзак авокадо",
      "frequency": 245
    },
    {
      "phrase": "рюкзак авокадо детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак авокадо дошкольный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак авокадо школьный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак в поездку девочке",
      "frequency": 0
    },
    {
      "phrase": "рюкзак в сад для девочки",
      "frequency": 90
    },
    {
      "phrase": "рюкзак в садик для девочки",
      "frequency": 800
    },
    {
      "phrase": "рюкзак в школу девочке 10 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак в школу для девочки модный подростковый",
      "frequency": 0
    },
    {
      "phrase": "рюкзак гимнастика для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак гимнастический для девочки",
      "frequency": 260
    },
    {
      "phrase": "рюкзак городской для девочки",
      "frequency": 65
    },
    {
      "phrase": "рюкзак девочка",
      "frequency": 45
    },
    {
      "phrase": "рюкзак девочка дошкольный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак девочка подросток",
      "frequency": 0
    },
    {
      "phrase": "рюкзак девочке",
      "frequency": 150
    },
    {
      "phrase": "рюкзак девочке в садик",
      "frequency": 70
    },
    {
      "phrase": "рюкзак девочке детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак девочке дошкольный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак девочке маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак девочке спортивный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак девочки",
      "frequency": 140
    },
    {
      "phrase": "рюкзак девочки в садик",
      "frequency": 45
    },
    {
      "phrase": "рюкзак девочки маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак девочки подростка",
      "frequency": 75
    },
    {
      "phrase": "рюкзак декатлон детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детская для девочек",
      "frequency": 55
    },
    {
      "phrase": "рюкзак детская для девочек 5 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детская для девочек в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детские для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детские для девочек в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детские маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский в садик водонепроницаемый",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский девочке",
      "frequency": 55
    },
    {
      "phrase": "рюкзак детский девочке в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский девочке маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек",
      "frequency": 4265
    },
    {
      "phrase": "рюкзак детский для девочек в садик",
      "frequency": 1810
    },
    {
      "phrase": "рюкзак детский для девочек в садик маленький",
      "frequency": 135
    },
    {
      "phrase": "рюкзак детский для девочек в садик маленький 2 года",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек в садик с 2",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек в садик с 2 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек городской",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек для садика",
      "frequency": 55
    },
    {
      "phrase": "рюкзак детский для девочек дошкольный 2 года",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек дошкольный 5 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек маленький 1 год",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек прогулочный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек спортивный",
      "frequency": 55
    },
    {
      "phrase": "рюкзак детский для девочек спортивный маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочки 2 года",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочки в сад",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочки в садик",
      "frequency": 165
    },
    {
      "phrase": "рюкзак детский для девочки дошкольный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для малыша",
      "frequency": 70
    },
    {
      "phrase": "рюкзак детский для малышей девочек",
      "frequency": 70
    },
    {
      "phrase": "рюкзак детский для малышей маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для сада девочке",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для садика девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для танцев",
      "frequency": 45
    },
    {
      "phrase": "рюкзак детский дошкольный в садик девочка",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский дошкольный для девочек",
      "frequency": 95
    },
    {
      "phrase": "рюкзак детский дошкольный маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский дошкольный спортивный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский маленький в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский маленький для девочек",
      "frequency": 55
    },
    {
      "phrase": "рюкзак детский машина",
      "frequency": 45
    },
    {
      "phrase": "рюкзак детский спортивный для девочек",
      "frequency": 65
    },
    {
      "phrase": "рюкзак детский спортивный дошкольный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский спортивный средний",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский школьный 1-4",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский школьный городской",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для вещей детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочек",
      "frequency": 1730
    },
    {
      "phrase": "рюкзак для девочек 5 в 1",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочек в садик",
      "frequency": 290
    },
    {
      "phrase": "рюкзак для девочек дошкольный",
      "frequency": 100
    },
    {
      "phrase": "рюкзак для девочек маленький",
      "frequency": 65
    },
    {
      "phrase": "рюкзак для девочек маленький на 10 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочек маленький на прогулку",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки",
      "frequency": 19005
    },
    {
      "phrase": "рюкзак для девочки 10 лет",
      "frequency": 65
    },
    {
      "phrase": "рюкзак для девочки 10 лет маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки 10 лет школьный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки 2 года",
      "frequency": 80
    },
    {
      "phrase": "рюкзак для девочки 2 года в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки 4",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки 4 в 1",
      "frequency": 100
    },
    {
      "phrase": "рюкзак для девочки 4 года",
      "frequency": 90
    },
    {
      "phrase": "рюкзак для девочки 5 в 1",
      "frequency": 70
    },
    {
      "phrase": "рюкзак для девочки 5 лет",
      "frequency": 105
    },
    {
      "phrase": "рюкзак для девочки 6 лет",
      "frequency": 105
    },
    {
      "phrase": "рюкзак для девочки в детский сад",
      "frequency": 80
    },
    {
      "phrase": "рюкзак для девочки в садик",
      "frequency": 5010
    },
    {
      "phrase": "рюкзак для девочки в садик 4 года",
      "frequency": 45
    },
    {
      "phrase": "рюкзак для девочки в садик 5 лет",
      "frequency": 70
    },
    {
      "phrase": "рюкзак для девочки в садик 6",
      "frequency": 45
    },
    {
      "phrase": "рюкзак для девочки в садик 6 лет",
      "frequency": 165
    },
    {
      "phrase": "рюкзак для девочки в садик маленький",
      "frequency": 210
    },
    {
      "phrase": "рюкзак для девочки в садик от 2",
      "frequency": 140
    },
    {
      "phrase": "рюкзак для девочки в садик от 2 лет",
      "frequency": 70
    },
    {
      "phrase": "рюкзак для девочки городской",
      "frequency": 50
    },
    {
      "phrase": "рюкзак для девочки городской маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки детский",
      "frequency": 160
    },
    {
      "phrase": "рюкзак для девочки детский в сад",
      "frequency": 100
    },
    {
      "phrase": "рюкзак для девочки детский в садик",
      "frequency": 85
    },
    {
      "phrase": "рюкзак для девочки детский дошкольный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки детский маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки детский маленький на 2 года",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки детский повседневный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки детский с карманами",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки для прогулок",
      "frequency": 75
    },
    {
      "phrase": "рюкзак для девочки для спорта",
      "frequency": 60
    },
    {
      "phrase": "рюкзак для девочки для танцев",
      "frequency": 70
    },
    {
      "phrase": "рюкзак для девочки для танцев маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки для танцев подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки дошкольный для танцев",
      "frequency": 60
    },
    {
      "phrase": "рюкзак для девочки дошкольный небольшой",
      "frequency": 170
    },
    {
      "phrase": "рюкзак для девочки дошкольный облегченный",
      "frequency": 75
    },
    {
      "phrase": "рюкзак для девочки маленький в садик",
      "frequency": 155
    },
    {
      "phrase": "рюкзак для девочки маленький для малышей",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки маленький для прогулок",
      "frequency": 140
    },
    {
      "phrase": "рюкзак для девочки маленький подростковый",
      "frequency": 115
    },
    {
      "phrase": "рюкзак для девочки маленький школьный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки малышам",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки мини",
      "frequency": 85
    },
    {
      "phrase": "рюкзак для девочки мини для подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки на прогулку",
      "frequency": 55
    },
    {
      "phrase": "рюкзак для девочки на танцы",
      "frequency": 85
    },
    {
      "phrase": "рюкзак для девочки небольшой",
      "frequency": 55
    },
    {
      "phrase": "рюкзак для девочки подростка маленький",
      "frequency": 90
    },
    {
      "phrase": "рюкзак для девочки подростка маленький для прогулок",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки подростка модные",
      "frequency": 100
    },
    {
      "phrase": "рюкзак для девочки подростка на прогулку",
      "frequency": 330
    },
    {
      "phrase": "рюкзак для девочки подростка повседневный школьный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки подростка школьный повседневный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки садик",
      "frequency": 55
    },
    {
      "phrase": "рюкзак для девочки спортивный",
      "frequency": 615
    },
    {
      "phrase": "рюкзак для девочки спортивный городской",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки спортивный маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки спортивный на плавание",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки спортивный с карманами для плавания",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки спортивный средний",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки школьный подростковый модный",
      "frequency": 140
    },
    {
      "phrase": "рюкзак для детей 10 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для детей маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для детского сада для девочки",
      "frequency": 65
    },
    {
      "phrase": "рюкзак для дошкольника девочки",
      "frequency": 280
    },
    {
      "phrase": "рюкзак для дошкольников",
      "frequency": 60
    },
    {
      "phrase": "рюкзак для маленьких девочек",
      "frequency": 50
    },
    {
      "phrase": "рюкзак для маленькой девочки",
      "frequency": 60
    },
    {
      "phrase": "рюкзак для маленькой девочки в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для малышей в садик для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для малышей маленький",
      "frequency": 110
    },
    {
      "phrase": "рюкзак для малышки",
      "frequency": 55
    },
    {
      "phrase": "рюкзак для малышки девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для новорожденного малыша",
      "frequency": 70
    },
    {
      "phrase": "рюкзак для одежды детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для первоклассника девочки школьный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для первоклашки",
      "frequency": 45
    },
    {
      "phrase": "рюкзак для первоклашки девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для плавания детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для подростка девочки в школу модный",
      "frequency": 55
    },
    {
      "phrase": "рюкзак для подростка девочки модный",
      "frequency": 130
    },
    {
      "phrase": "рюкзак для подростка девочки модный в школу",
      "frequency": 55
    },
    {
      "phrase": "рюкзак для подростка девочки на прогулку",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для подростка маленький для прогулок",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для подростки девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для поездок вместительный",
      "frequency": 55
    },
    {
      "phrase": "рюкзак для поездок детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для прогулки для девочек",
      "frequency": 50
    },
    {
      "phrase": "рюкзак для прогулки маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для прогулок детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для прогулок для девочек",
      "frequency": 65
    },
    {
      "phrase": "рюкзак для прогулок для девочек подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для прогулок для подростков",
      "frequency": 50
    },
    {
      "phrase": "рюкзак для прогулок маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для путешествий детский",
      "frequency": 90
    },
    {
      "phrase": "рюкзак для ребенка 4 года",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для ребенка девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для сада для девочки",
      "frequency": 75
    },
    {
      "phrase": "рюкзак для садика девочки",
      "frequency": 55
    },
    {
      "phrase": "рюкзак для садика для девочки",
      "frequency": 515
    },
    {
      "phrase": "рюкзак для секций",
      "frequency": 60
    },
    {
      "phrase": "рюкзак для секций для детей",
      "frequency": 55
    },
    {
      "phrase": "рюкзак для спорта детский",
      "frequency": 120
    },
    {
      "phrase": "рюкзак для спорта детский для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для спорта для девочки",
      "frequency": 180
    },
    {
      "phrase": "рюкзак для спортивной формы в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для спортивной формы для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для танцев девочке",
      "frequency": 285
    },
    {
      "phrase": "рюкзак для танцев детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для танцевальной формы",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для формы для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для школы для девочки 10 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак дошкольника для девочки",
      "frequency": 65
    },
    {
      "phrase": "рюкзак дошкольников для девочки",
      "frequency": 65
    },
    {
      "phrase": "рюкзак дошкольный в садик",
      "frequency": 60
    },
    {
      "phrase": "рюкзак дошкольный для девочки",
      "frequency": 1355
    },
    {
      "phrase": "рюкзак дошкольный для девочки 2 года",
      "frequency": 0
    },
    {
      "phrase": "рюкзак к школе для первоклассника",
      "frequency": 0
    },
    {
      "phrase": "рюкзак красный",
      "frequency": 545
    },
    {
      "phrase": "рюкзак красный детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак красный для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак красный спортивный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак красный школьный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак крепкий",
      "frequency": 60
    },
    {
      "phrase": "рюкзак крепкий школьный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак маленький детский",
      "frequency": 190
    },
    {
      "phrase": "рюкзак маленький детский для девочек",
      "frequency": 45
    },
    {
      "phrase": "рюкзак маленький для девочки",
      "frequency": 490
    },
    {
      "phrase": "рюкзак маленький для девочки в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзак маленький для девочки детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак маленький для девочки дошкольный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак маленький для девочки подростка",
      "frequency": 130
    },
    {
      "phrase": "рюкзак маленький для девочки школьный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак маленький спортивный для подростка",
      "frequency": 0
    },
    {
      "phrase": "рюкзак маленький туристический",
      "frequency": 0
    },
    {
      "phrase": "рюкзак маленький школьный",
      "frequency": 95
    },
    {
      "phrase": "рюкзак маленький школьный для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак малышке",
      "frequency": 45
    },
    {
      "phrase": "рюкзак малышу маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак милый",
      "frequency": 1270
    },
    {
      "phrase": "рюкзак милый маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак мини для девочки",
      "frequency": 95
    },
    {
      "phrase": "рюкзак мини для девочки подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзак модный для девочек",
      "frequency": 100
    },
    {
      "phrase": "рюкзак молодежный для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак на гимнастику девочке",
      "frequency": 0
    },
    {
      "phrase": "рюкзак на гимнастику детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак на девочку",
      "frequency": 60
    },
    {
      "phrase": "рюкзак на девочку 10 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак на девочку 2 года",
      "frequency": 0
    },
    {
      "phrase": "рюкзак на девочку в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзак на девочку дошкольный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак на прогулку для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак на прогулку для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак на прогулку для подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзак на прогулку мини",
      "frequency": 0
    },
    {
      "phrase": "рюкзак на садик",
      "frequency": 50
    },
    {
      "phrase": "рюкзак на секцию",
      "frequency": 65
    },
    {
      "phrase": "рюкзак небольшой для девочки",
      "frequency": 60
    },
    {
      "phrase": "рюкзак новогодний детский",
      "frequency": 140
    },
    {
      "phrase": "рюкзак первоклассника для девочки",
      "frequency": 120
    },
    {
      "phrase": "рюкзак первоклассника маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак повседневный для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак подростковый для девочек для прогулки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак прогулочный детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак прогулочный для девочки подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзак прогулочный маленький для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак путешествия детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак пять в одном для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак ранец для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак рюкзак для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак с авокадо",
      "frequency": 340
    },
    {
      "phrase": "рюкзак с авокадо детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак с авокадо с школу",
      "frequency": 0
    },
    {
      "phrase": "рюкзак с авокадо школьный",
      "frequency": 50
    },
    {
      "phrase": "рюкзак с авокадо школьный для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак с игрушкой школьный",
      "frequency": 45
    },
    {
      "phrase": "рюкзак с машиной",
      "frequency": 45
    },
    {
      "phrase": "рюкзак с машиной детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак с машиной дошкольный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак с машиной школьный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак с рисунком маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак садик",
      "frequency": 60
    },
    {
      "phrase": "рюкзак садик для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак сетка для плавания",
      "frequency": 0
    },
    {
      "phrase": "рюкзак со спинкой для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак со спинкой для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак спортивный детский для гимнастики",
      "frequency": 0
    },
    {
      "phrase": "рюкзак спортивный детский для девочки",
      "frequency": 50
    },
    {
      "phrase": "рюкзак спортивный детский маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак спортивный для девочки",
      "frequency": 830
    },
    {
      "phrase": "рюкзак спортивный для девочки маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак спортивный для девочки с застежкой",
      "frequency": 0
    },
    {
      "phrase": "рюкзак спортивный для девочки средний",
      "frequency": 0
    },
    {
      "phrase": "рюкзак спортивный маленький детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак спортивный маленький для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак текстиль маленький",
      "frequency": 95
    },
    {
      "phrase": "рюкзак текстильный для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак текстильный маленький",
      "frequency": 50
    },
    {
      "phrase": "рюкзак туристический детский",
      "frequency": 45
    },
    {
      "phrase": "рюкзак через одно плечо для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак через плечо для девочки",
      "frequency": 65
    },
    {
      "phrase": "рюкзак школьника для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьника для девочки подростка",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный 10 лет",
      "frequency": 55
    },
    {
      "phrase": "рюкзак школьный 10 лет девочка",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный 5 в 1 для девочки",
      "frequency": 90
    },
    {
      "phrase": "рюкзак школьный 7 в 1",
      "frequency": 50
    },
    {
      "phrase": "рюкзак школьный авокадо",
      "frequency": 65
    },
    {
      "phrase": "рюкзак школьный девочке 10 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный детский городской для девочек подростка",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный для девочек подростков модный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный для девочки 5 в 1",
      "frequency": 90
    },
    {
      "phrase": "рюкзак школьный для девочки в садик",
      "frequency": 60
    },
    {
      "phrase": "рюкзак школьный для девочки маленький",
      "frequency": 80
    },
    {
      "phrase": "рюкзак школьный для девочки подростка модный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный для девочки с авокадо",
      "frequency": 85
    },
    {
      "phrase": "рюкзак школьный для первоклассника девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный для подростка девочки модный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный для подростков девочек модный",
      "frequency": 380
    },
    {
      "phrase": "рюкзак школьный для подростков девочек модный 2023",
      "frequency": 60
    },
    {
      "phrase": "рюкзак школьный для подростков модный для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный для подростков модный для девочек 2023",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный красный",
      "frequency": 60
    },
    {
      "phrase": "рюкзак школьный маленький для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный подростковый для девочки 4 в 1",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный с авокадо",
      "frequency": 130
    },
    {
      "phrase": "рюкзак школьный спортивный 5 в 1",
      "frequency": 0
    },
    {
      "phrase": "рюкзак яркий для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки 2023 для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки 5 в 1 для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки детские для девочек",
      "frequency": 110
    },
    {
      "phrase": "рюкзаки детские для девочек в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки детские для девочек на прогулку",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для девочек",
      "frequency": 2775
    },
    {
      "phrase": "рюкзаки для девочек в садик",
      "frequency": 120
    },
    {
      "phrase": "рюкзаки для девочек в садик 2 года",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для девочек для прогулки",
      "frequency": 130
    },
    {
      "phrase": "рюкзаки для девочек модные",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для девочек первоклассников",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для девочек подростков модные",
      "frequency": 75
    },
    {
      "phrase": "рюкзаки для девочек садик",
      "frequency": 145
    },
    {
      "phrase": "рюкзаки для девочки",
      "frequency": 125
    },
    {
      "phrase": "рюкзаки для девочки в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для девочки подростка",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для малышей 4 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для малышей от 1 года",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для прогулок девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки маленькие для девочек",
      "frequency": 70
    },
    {
      "phrase": "рюкзаки маленькие для прогулки",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки милые",
      "frequency": 90
    },
    {
      "phrase": "рюкзаки мини для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки мини для подростка",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки модные для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки подростковые для девочек",
      "frequency": 100
    },
    {
      "phrase": "рюкзаки подростковые для девочек для прогулки",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки школьные для девочек подростков модные",
      "frequency": 0
    },
    {
      "phrase": "рюкзачек для девочки",
      "frequency": 65
    },
    {
      "phrase": "рюкзачки детские для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзачки детские для девочек маленькие",
      "frequency": 0
    },
    {
      "phrase": "рюкзачки для девочек в садик",
      "frequency": 45
    },
    {
      "phrase": "рюкзачки для девочек для детского сада",
      "frequency": 50
    },
    {
      "phrase": "рюкзачки для девочек маленькие",
      "frequency": 0
    },
    {
      "phrase": "рюкзачки для маленьких девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок в садик для девочки",
      "frequency": 95
    },
    {
      "phrase": "рюкзачок детский для девочек для малышей",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок детский для девочки",
      "frequency": 135
    },
    {
      "phrase": "рюкзачок детский для девочки 2 годика",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок детский для девочки 5 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок детский для девочки маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок детский для девочки маленький в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок детский маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для девочки в садик",
      "frequency": 410
    },
    {
      "phrase": "рюкзачок для девочки в садик маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для девочки детский маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для девочки маленький",
      "frequency": 135
    },
    {
      "phrase": "рюкзачок для девочки маленький в детский сад",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для девочки маленький для садика",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для девочки мини",
      "frequency": 45
    },
    {
      "phrase": "рюкзачок для девочки подростков для прогулок",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для малыша 1 год",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для малышей 1-2 года",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для малышей маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для садика для девочки",
      "frequency": 50
    },
    {
      "phrase": "рюкзачок маленький детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок маленький детский для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок маленький для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок маленький для девочки 10 лет",
      "frequency": 50
    },
    {
      "phrase": "рюкзачок маленький для малышей",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок маленький для прогулок",
      "frequency": 0
    },
    {
      "phrase": "спортивные рюкзаки для девочек",
      "frequency": 0
    },
    {
      "phrase": "спортивный детский рюкзак",
      "frequency": 135
    },
    {
      "phrase": "спортивный детский рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "спортивный рюкзак детский",
      "frequency": 160
    },
    {
      "phrase": "спортивный рюкзак для девочки подростка",
      "frequency": 0
    },
    {
      "phrase": "текстильный рюкзак маленький",
      "frequency": 0
    },
    {
      "phrase": "тряпочный рюкзак мини",
      "frequency": 0
    },
    {
      "phrase": "туристический рюкзак 100 литров",
      "frequency": 55
    },
    {
      "phrase": "школьник рюкзаки девочки",
      "frequency": 0
    },
    {
      "phrase": "школьные рюкзаки маленькие",
      "frequency": 0
    },
    {
      "phrase": "школьный портфель для девочек 10 лет",
      "frequency": 0
    },
    {
      "phrase": "школьный рюкзак авокадо",
      "frequency": 0
    },
    {
      "phrase": "школьный рюкзак для первоклассника для девочки",
      "frequency": 0
    },
    {
      "phrase": "школьный рюкзак для подростка девочки модный",
      "frequency": 70
    },
    {
      "phrase": "школьный рюкзак маленький",
      "frequency": 55
    },
    {
      "phrase": "яркий рюкзак для девочки",
      "frequency": 0
    }
  ],
  "A_B": [
    {
      "phrase": "0 рюкзак для девочек",
      "frequency": 0
    },
    {
      "phrase": "7 в 1 рюкзак",
      "frequency": 50
    },
    {
      "phrase": "suro рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "suro рюкзак школьный",
      "frequency": 0
    },
    {
      "phrase": "suro рюкзак школьный детский городской для девочек подростка",
      "frequency": 0
    },
    {
      "phrase": "гимнастический рюкзак для девочки",
      "frequency": 60
    },
    {
      "phrase": "городской рюкзак для девочек",
      "frequency": 0
    },
    {
      "phrase": "девочки 100 штук",
      "frequency": 0
    },
    {
      "phrase": "декатлон рюкзак детский",
      "frequency": 0
    },
    {
      "phrase": "детские рюкзаки в садик для девочек",
      "frequency": 0
    },
    {
      "phrase": "детские рюкзаки для девочек",
      "frequency": 415
    },
    {
      "phrase": "детские рюкзаки для девочек 5 лет",
      "frequency": 0
    },
    {
      "phrase": "детские рюкзаки для девочек в садик",
      "frequency": 390
    },
    {
      "phrase": "детские рюкзаки для девочек маленькие",
      "frequency": 55
    },
    {
      "phrase": "детские рюкзаки для девочек на 2",
      "frequency": 0
    },
    {
      "phrase": "детские рюкзаки для малышей машины",
      "frequency": 0
    },
    {
      "phrase": "детские рюкзачки для девочек",
      "frequency": 90
    },
    {
      "phrase": "детские рюкзачки для девочек в сад",
      "frequency": 0
    },
    {
      "phrase": "детский маленький рюкзак",
      "frequency": 95
    },
    {
      "phrase": "детский рюкзак в садик для девочки",
      "frequency": 70
    },
    {
      "phrase": "детский рюкзак в садик для девочки 2 лет",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак девочки",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак для девочек",
      "frequency": 80
    },
    {
      "phrase": "детский рюкзак для девочек в садик",
      "frequency": 70
    },
    {
      "phrase": "детский рюкзак для девочки в садик",
      "frequency": 1915
    },
    {
      "phrase": "детский рюкзак для девочки в садик дошкольный",
      "frequency": 60
    },
    {
      "phrase": "детский рюкзак для девочки в садик маленький",
      "frequency": 120
    },
    {
      "phrase": "детский рюкзак для девочки дошкольный",
      "frequency": 160
    },
    {
      "phrase": "детский рюкзак для девочки маленький",
      "frequency": 80
    },
    {
      "phrase": "детский рюкзак для девочки маленький в сад",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак для спорта",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак дошкольный",
      "frequency": 50
    },
    {
      "phrase": "детский рюкзак дошкольный для девочек",
      "frequency": 90
    },
    {
      "phrase": "детский рюкзачок для девочки в садик",
      "frequency": 130
    },
    {
      "phrase": "детский рюкзачок для девочки дошкольный",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзачок для девочки маленький",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзачок маленький",
      "frequency": 0
    },
    {
      "phrase": "детский спортивный рюкзак",
      "frequency": 100
    },
    {
      "phrase": "детский спортивный рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "для первоклассника рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "для садика девочке рюкзак",
      "frequency": 0
    },
    {
      "phrase": "дошкольные рюкзаки для девочек",
      "frequency": 55
    },
    {
      "phrase": "дошкольный рюкзак 5 в 1",
      "frequency": 0
    },
    {
      "phrase": "дошкольный рюкзак для девочки",
      "frequency": 225
    },
    {
      "phrase": "дошкольный рюкзак для девочки в садик",
      "frequency": 0
    },
    {
      "phrase": "застежка для рюкзака рукоделие",
      "frequency": 0
    },
    {
      "phrase": "маленькие рюкзаки для девочек",
      "frequency": 135
    },
    {
      "phrase": "маленькие рюкзачки детские",
      "frequency": 0
    },
    {
      "phrase": "маленькие рюкзачки для девочек",
      "frequency": 55
    },
    {
      "phrase": "маленькие штучки для девочек",
      "frequency": 0
    },
    {
      "phrase": "маленький детский рюкзак",
      "frequency": 80
    },
    {
      "phrase": "маленький детский рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "маленький рюкзак в школу для девочки",
      "frequency": 0
    },
    {
      "phrase": "маленький рюкзак детский",
      "frequency": 70
    },
    {
      "phrase": "маленький рюкзак для девочек",
      "frequency": 80
    },
    {
      "phrase": "маленький рюкзак для девочек подростков",
      "frequency": 0
    },
    {
      "phrase": "маленький рюкзак для девочки в садик",
      "frequency": 0
    },
    {
      "phrase": "маленький рюкзак для девочки для прогулки",
      "frequency": 0
    },
    {
      "phrase": "маленький рюкзак для девочки для прогулки для детей",
      "frequency": 0
    },
    {
      "phrase": "маленький рюкзак для девочки модный",
      "frequency": 85
    },
    {
      "phrase": "маленький рюкзачок детский",
      "frequency": 45
    },
    {
      "phrase": "маленький рюкзачок детский для девочек",
      "frequency": 0
    },
    {
      "phrase": "маленький спортивный рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "маленький школьный рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "мелочь для подростка",
      "frequency": 0
    },
    {
      "phrase": "милые рюкзаки",
      "frequency": 270
    },
    {
      "phrase": "милые рюкзаки для подростков",
      "frequency": 0
    },
    {
      "phrase": "милый рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "милый рюкзак для девочки в школу",
      "frequency": 0
    },
    {
      "phrase": "милый рюкзак для подростка",
      "frequency": 0
    },
    {
      "phrase": "милый рюкзак маленький",
      "frequency": 0
    },
    {
      "phrase": "милый школьный рюкзак для девочек",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзак детский",
      "frequency": 65
    },
    {
      "phrase": "мини рюкзак детский для девочек",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзак для девочки",
      "frequency": 320
    },
    {
      "phrase": "мини рюкзак для девочки 10 лет",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзак для девочки в садик",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзак для девочки на прогулку",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзак для девочки подростка",
      "frequency": 55
    },
    {
      "phrase": "мини рюкзак для подростка",
      "frequency": 115
    },
    {
      "phrase": "мини рюкзак для подростка девочки",
      "frequency": 45
    },
    {
      "phrase": "мини рюкзак дошкольный",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзаки детские",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзаки для девочек",
      "frequency": 45
    },
    {
      "phrase": "мини рюкзаки для детей",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзачок",
      "frequency": 205
    },
    {
      "phrase": "мини рюкзачок для девочки",
      "frequency": 60
    },
    {
      "phrase": "мини рюкзачок для детей",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзачок подростковый",
      "frequency": 0
    },
    {
      "phrase": "мини-рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "модные рюкзаки для девочек",
      "frequency": 45
    },
    {
      "phrase": "модные рюкзаки для подростков 2023",
      "frequency": 0
    },
    {
      "phrase": "модные рюкзаки для подростков девочек",
      "frequency": 0
    },
    {
      "phrase": "модный рюкзак в школу для девочки подростка",
      "frequency": 0
    },
    {
      "phrase": "модный рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "модный рюкзак для девочки подростка в школу",
      "frequency": 0
    },
    {
      "phrase": "модный рюкзак для подростка в школу для девочек",
      "frequency": 0
    },
    {
      "phrase": "модный школьный рюкзак для девочки подростка",
      "frequency": 0
    },
    {
      "phrase": "модный школьный рюкзак для подростка девочки",
      "frequency": 0
    },
    {
      "phrase": "молодежный рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "небольшие рюкзаки для подростков",
      "frequency": 0
    },
    {
      "phrase": "небольшой подарок",
      "frequency": 65
    },
    {
      "phrase": "небольшой подарок девочке",
      "frequency": 0
    },
    {
      "phrase": "небольшой рюкзак для девочки",
      "frequency": 45
    },
    {
      "phrase": "небольшой рюкзак для подростка",
      "frequency": 60
    },
    {
      "phrase": "облегченный рюкзак для первоклассника",
      "frequency": 60
    },
    {
      "phrase": "первоклашка рюкзак",
      "frequency": 0
    },
    {
      "phrase": "повседневный рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "подростковый рюкзак для девочек",
      "frequency": 50
    },
    {
      "phrase": "портфель школьный для подростка девочки маленький",
      "frequency": 0
    },
    {
      "phrase": "ранец детский для девочек",
      "frequency": 0
    },
    {
      "phrase": "ранец для девочки подростка",
      "frequency": 45
    },
    {
      "phrase": "ранец школьный для девочки первоклассник",
      "frequency": 110
    },
    {
      "phrase": "ранцы для девочек",
      "frequency": 85
    },
    {
      "phrase": "рюкзак  для девочки",
      "frequency": 135
    },
    {
      "phrase": "рюкзак 10 литров детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак 4 в 1 для девочек подростков в школу",
      "frequency": 0
    },
    {
      "phrase": "рюкзак 5 в 1 для девочек",
      "frequency": 480
    },
    {
      "phrase": "рюкзак 5 в 1 для девочек школьный",
      "frequency": 85
    },
    {
      "phrase": "рюкзак 5в 1 для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак 6 в 1",
      "frequency": 90
    },
    {
      "phrase": "рюкзак 7 в 1",
      "frequency": 260
    },
    {
      "phrase": "рюкзак авокадо",
      "frequency": 245
    },
    {
      "phrase": "рюкзак авокадо детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак авокадо дошкольный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак авокадо школьный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак в поездку девочке",
      "frequency": 0
    },
    {
      "phrase": "рюкзак в сад для девочки",
      "frequency": 90
    },
    {
      "phrase": "рюкзак в садик для девочки",
      "frequency": 800
    },
    {
      "phrase": "рюкзак в школу девочке 10 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак в школу для девочки модный подростковый",
      "frequency": 0
    },
    {
      "phrase": "рюкзак гимнастика для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак городской для девочки",
      "frequency": 65
    },
    {
      "phrase": "рюкзак девочка",
      "frequency": 45
    },
    {
      "phrase": "рюкзак девочка дошкольный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак девочка подросток",
      "frequency": 0
    },
    {
      "phrase": "рюкзак девочке",
      "frequency": 150
    },
    {
      "phrase": "рюкзак девочке в садик",
      "frequency": 70
    },
    {
      "phrase": "рюкзак девочке детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак девочке дошкольный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак девочке маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак девочки",
      "frequency": 140
    },
    {
      "phrase": "рюкзак девочки в садик",
      "frequency": 45
    },
    {
      "phrase": "рюкзак девочки маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак девочки подростка",
      "frequency": 75
    },
    {
      "phrase": "рюкзак декатлон детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детская для девочек",
      "frequency": 55
    },
    {
      "phrase": "рюкзак детская для девочек 5 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детская для девочек в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детские для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детские для девочек в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский девочке",
      "frequency": 55
    },
    {
      "phrase": "рюкзак детский девочке в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек",
      "frequency": 4265
    },
    {
      "phrase": "рюкзак детский для девочек в садик",
      "frequency": 1810
    },
    {
      "phrase": "рюкзак детский для девочек в садик маленький",
      "frequency": 135
    },
    {
      "phrase": "рюкзак детский для девочек в садик маленький 2 года",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек в садик с 2",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек городской",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек для садика",
      "frequency": 55
    },
    {
      "phrase": "рюкзак детский для девочек дошкольный 2 года",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек дошкольный 5 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек маленький 1 год",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек прогулочный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек спортивный",
      "frequency": 55
    },
    {
      "phrase": "рюкзак детский для девочек спортивный маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочки в сад",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочки в садик",
      "frequency": 165
    },
    {
      "phrase": "рюкзак детский для девочки дошкольный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для малышей девочек",
      "frequency": 70
    },
    {
      "phrase": "рюкзак детский для малышей маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для сада девочке",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для садика девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для танцев",
      "frequency": 45
    },
    {
      "phrase": "рюкзак детский дошкольный в садик девочка",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский дошкольный маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский дошкольный спортивный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский машина",
      "frequency": 45
    },
    {
      "phrase": "рюкзак детский спортивный для девочек",
      "frequency": 65
    },
    {
      "phrase": "рюкзак детский спортивный дошкольный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский спортивный средний",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский школьный 1-4",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский школьный городской",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для вещей детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочек",
      "frequency": 1730
    },
    {
      "phrase": "рюкзак для девочек 5 в 1",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочек в садик",
      "frequency": 290
    },
    {
      "phrase": "рюкзак для девочек дошкольный",
      "frequency": 100
    },
    {
      "phrase": "рюкзак для девочек маленький",
      "frequency": 65
    },
    {
      "phrase": "рюкзак для девочек маленький на 10 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки",
      "frequency": 19005
    },
    {
      "phrase": "рюкзак для девочки 10 лет",
      "frequency": 65
    },
    {
      "phrase": "рюкзак для девочки 10 лет маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки 2 года в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки 4",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки 4 года",
      "frequency": 90
    },
    {
      "phrase": "рюкзак для девочки 5 в 1",
      "frequency": 70
    },
    {
      "phrase": "рюкзак для девочки 5 лет",
      "frequency": 105
    },
    {
      "phrase": "рюкзак для девочки 6 лет",
      "frequency": 105
    },
    {
      "phrase": "рюкзак для девочки в детский сад",
      "frequency": 80
    },
    {
      "phrase": "рюкзак для девочки в садик",
      "frequency": 5010
    },
    {
      "phrase": "рюкзак для девочки в садик 4 года",
      "frequency": 45
    },
    {
      "phrase": "рюкзак для девочки в садик 5 лет",
      "frequency": 70
    },
    {
      "phrase": "рюкзак для девочки в садик 6",
      "frequency": 45
    },
    {
      "phrase": "рюкзак для девочки в садик 6 лет",
      "frequency": 165
    },
    {
      "phrase": "рюкзак для девочки в садик маленький",
      "frequency": 210
    },
    {
      "phrase": "рюкзак для девочки в садик от 2",
      "frequency": 140
    },
    {
      "phrase": "рюкзак для девочки в садик от 2 лет",
      "frequency": 70
    },
    {
      "phrase": "рюкзак для девочки городской",
      "frequency": 50
    },
    {
      "phrase": "рюкзак для девочки городской маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки детский в сад",
      "frequency": 100
    },
    {
      "phrase": "рюкзак для девочки детский в садик",
      "frequency": 85
    },
    {
      "phrase": "рюкзак для девочки детский дошкольный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки детский маленький на 2 года",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки детский повседневный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки детский с карманами",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки для прогулок",
      "frequency": 75
    },
    {
      "phrase": "рюкзак для девочки для спорта",
      "frequency": 60
    },
    {
      "phrase": "рюкзак для девочки для танцев",
      "frequency": 70
    },
    {
      "phrase": "рюкзак для девочки для танцев маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки для танцев подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки дошкольный для танцев",
      "frequency": 60
    },
    {
      "phrase": "рюкзак для девочки дошкольный небольшой",
      "frequency": 170
    },
    {
      "phrase": "рюкзак для девочки дошкольный облегченный",
      "frequency": 75
    },
    {
      "phrase": "рюкзак для девочки маленький в садик",
      "frequency": 155
    },
    {
      "phrase": "рюкзак для девочки маленький для малышей",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки маленький для прогулок",
      "frequency": 140
    },
    {
      "phrase": "рюкзак для девочки маленький школьный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки малышам",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки мини",
      "frequency": 85
    },
    {
      "phrase": "рюкзак для девочки на прогулку",
      "frequency": 55
    },
    {
      "phrase": "рюкзак для девочки на танцы",
      "frequency": 85
    },
    {
      "phrase": "рюкзак для девочки небольшой",
      "frequency": 55
    },
    {
      "phrase": "рюкзак для девочки подростка маленький",
      "frequency": 90
    },
    {
      "phrase": "рюкзак для девочки подростка модные",
      "frequency": 100
    },
    {
      "phrase": "рюкзак для девочки подростка на прогулку",
      "frequency": 330
    },
    {
      "phrase": "рюкзак для девочки подростка повседневный школьный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки садик",
      "frequency": 55
    },
    {
      "phrase": "рюкзак для девочки спортивный",
      "frequency": 615
    },
    {
      "phrase": "рюкзак для девочки спортивный городской",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки спортивный на плавание",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки спортивный средний",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки школьный подростковый модный",
      "frequency": 140
    },
    {
      "phrase": "рюкзак для детей 10 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для детского сада для девочки",
      "frequency": 65
    },
    {
      "phrase": "рюкзак для дошкольников",
      "frequency": 60
    },
    {
      "phrase": "рюкзак для маленьких девочек",
      "frequency": 50
    },
    {
      "phrase": "рюкзак для маленькой девочки",
      "frequency": 60
    },
    {
      "phrase": "рюкзак для маленькой девочки в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для малышей в садик для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для малышки",
      "frequency": 55
    },
    {
      "phrase": "рюкзак для малышки девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для одежды детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для первоклассника девочки школьный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для первоклашки",
      "frequency": 45
    },
    {
      "phrase": "рюкзак для первоклашки девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для подростка девочки в школу модный",
      "frequency": 55
    },
    {
      "phrase": "рюкзак для подростка девочки модный",
      "frequency": 130
    },
    {
      "phrase": "рюкзак для подростка девочки модный в школу",
      "frequency": 55
    },
    {
      "phrase": "рюкзак для подростка маленький для прогулок",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для подростки девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для поездок вместительный",
      "frequency": 55
    },
    {
      "phrase": "рюкзак для прогулки для девочек",
      "frequency": 50
    },
    {
      "phrase": "рюкзак для прогулок для девочек",
      "frequency": 65
    },
    {
      "phrase": "рюкзак для прогулок для подростков",
      "frequency": 50
    },
    {
      "phrase": "рюкзак для прогулок маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для путешествий детский",
      "frequency": 90
    },
    {
      "phrase": "рюкзак для ребенка 4 года",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для ребенка девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для сада для девочки",
      "frequency": 75
    },
    {
      "phrase": "рюкзак для садика девочки",
      "frequency": 55
    },
    {
      "phrase": "рюкзак для секций",
      "frequency": 60
    },
    {
      "phrase": "рюкзак для секций для детей",
      "frequency": 55
    },
    {
      "phrase": "рюкзак для спорта детский",
      "frequency": 120
    },
    {
      "phrase": "рюкзак для спорта детский для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для спорта для девочки",
      "frequency": 180
    },
    {
      "phrase": "рюкзак для спортивной формы для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для танцев девочке",
      "frequency": 285
    },
    {
      "phrase": "рюкзак для танцев детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для танцевальной формы",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для формы для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для школы для девочки 10 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак дошкольника для девочки",
      "frequency": 65
    },
    {
      "phrase": "рюкзак дошкольников для девочки",
      "frequency": 65
    },
    {
      "phrase": "рюкзак дошкольный в садик",
      "frequency": 60
    },
    {
      "phrase": "рюкзак к школе для первоклассника",
      "frequency": 0
    },
    {
      "phrase": "рюкзак крепкий",
      "frequency": 60
    },
    {
      "phrase": "рюкзак крепкий школьный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак маленький детский для девочек",
      "frequency": 45
    },
    {
      "phrase": "рюкзак маленький для девочки",
      "frequency": 490
    },
    {
      "phrase": "рюкзак маленький для девочки в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзак маленький для девочки дошкольный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак маленький для девочки подростка",
      "frequency": 130
    },
    {
      "phrase": "рюкзак маленький для девочки школьный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак маленький туристический",
      "frequency": 0
    },
    {
      "phrase": "рюкзак маленький школьный",
      "frequency": 95
    },
    {
      "phrase": "рюкзак маленький школьный для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак малышке",
      "frequency": 45
    },
    {
      "phrase": "рюкзак милый",
      "frequency": 1270
    },
    {
      "phrase": "рюкзак милый маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак мини для девочки",
      "frequency": 95
    },
    {
      "phrase": "рюкзак молодежный для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак на гимнастику девочке",
      "frequency": 0
    },
    {
      "phrase": "рюкзак на гимнастику детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак на девочку",
      "frequency": 60
    },
    {
      "phrase": "рюкзак на девочку 10 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак на девочку дошкольный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак на прогулку для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак на прогулку для подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзак на прогулку мини",
      "frequency": 0
    },
    {
      "phrase": "рюкзак небольшой для девочки",
      "frequency": 60
    },
    {
      "phrase": "рюкзак новогодний детский",
      "frequency": 140
    },
    {
      "phrase": "рюкзак первоклассника для девочки",
      "frequency": 120
    },
    {
      "phrase": "рюкзак прогулочный детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак прогулочный для девочки подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзак прогулочный маленький для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак путешествия детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак пять в одном для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак ранец для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак рюкзак для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак с авокадо",
      "frequency": 340
    },
    {
      "phrase": "рюкзак с авокадо детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак с авокадо с школу",
      "frequency": 0
    },
    {
      "phrase": "рюкзак с авокадо школьный",
      "frequency": 50
    },
    {
      "phrase": "рюкзак с авокадо школьный для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак с машиной",
      "frequency": 45
    },
    {
      "phrase": "рюкзак с машиной детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак с машиной школьный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак сетка для плавания",
      "frequency": 0
    },
    {
      "phrase": "рюкзак со спинкой для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак со спинкой для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак спортивный детский для гимнастики",
      "frequency": 0
    },
    {
      "phrase": "рюкзак спортивный детский для девочки",
      "frequency": 50
    },
    {
      "phrase": "рюкзак спортивный для девочки",
      "frequency": 830
    },
    {
      "phrase": "рюкзак спортивный для девочки маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак спортивный для девочки средний",
      "frequency": 0
    },
    {
      "phrase": "рюкзак спортивный маленький для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак текстиль маленький",
      "frequency": 95
    },
    {
      "phrase": "рюкзак текстильный для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак туристический детский",
      "frequency": 45
    },
    {
      "phrase": "рюкзак через плечо для девочки",
      "frequency": 65
    },
    {
      "phrase": "рюкзак школьника для девочки подростка",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный 7 в 1",
      "frequency": 50
    },
    {
      "phrase": "рюкзак школьный авокадо",
      "frequency": 65
    },
    {
      "phrase": "рюкзак школьный девочке 10 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный детский городской для девочек подростка",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный для девочек подростков модный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный для девочки 5 в 1",
      "frequency": 90
    },
    {
      "phrase": "рюкзак школьный для девочки в садик",
      "frequency": 60
    },
    {
      "phrase": "рюкзак школьный для девочки маленький",
      "frequency": 80
    },
    {
      "phrase": "рюкзак школьный для девочки с авокадо",
      "frequency": 85
    },
    {
      "phrase": "рюкзак школьный для подростка девочки модный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный для подростков девочек модный",
      "frequency": 380
    },
    {
      "phrase": "рюкзак школьный для подростков девочек модный 2023",
      "frequency": 60
    },
    {
      "phrase": "рюкзак школьный для подростков модный для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный маленький для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный подростковый для девочки 4 в 1",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный с авокадо",
      "frequency": 130
    },
    {
      "phrase": "рюкзак школьный спортивный 5 в 1",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки 2023 для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки детские для девочек",
      "frequency": 110
    },
    {
      "phrase": "рюкзаки детские для девочек в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки детские для девочек на прогулку",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для девочек",
      "frequency": 2775
    },
    {
      "phrase": "рюкзаки для девочек в садик",
      "frequency": 120
    },
    {
      "phrase": "рюкзаки для девочек в садик 2 года",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для девочек для прогулки",
      "frequency": 130
    },
    {
      "phrase": "рюкзаки для девочек модные",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для девочек первоклассников",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для девочек подростков модные",
      "frequency": 75
    },
    {
      "phrase": "рюкзаки для девочек садик",
      "frequency": 145
    },
    {
      "phrase": "рюкзаки для девочки",
      "frequency": 125
    },
    {
      "phrase": "рюкзаки для девочки в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для девочки подростка",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для малышей 4 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для прогулок девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки маленькие для девочек",
      "frequency": 70
    },
    {
      "phrase": "рюкзаки маленькие для прогулки",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки милые",
      "frequency": 90
    },
    {
      "phrase": "рюкзаки мини для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки мини для подростка",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки модные для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки подростковые для девочек",
      "frequency": 100
    },
    {
      "phrase": "рюкзаки подростковые для девочек для прогулки",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки школьные для девочек подростков модные",
      "frequency": 0
    },
    {
      "phrase": "рюкзачек для девочки",
      "frequency": 65
    },
    {
      "phrase": "рюкзачки детские для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзачки детские для девочек маленькие",
      "frequency": 0
    },
    {
      "phrase": "рюкзачки для девочек в садик",
      "frequency": 45
    },
    {
      "phrase": "рюкзачки для девочек для детского сада",
      "frequency": 50
    },
    {
      "phrase": "рюкзачки для девочек маленькие",
      "frequency": 0
    },
    {
      "phrase": "рюкзачки для маленьких девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок в садик для девочки",
      "frequency": 95
    },
    {
      "phrase": "рюкзачок детский для девочек для малышей",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок детский для девочки",
      "frequency": 135
    },
    {
      "phrase": "рюкзачок детский для девочки 5 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок детский для девочки маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок детский маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для девочки детский маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для девочки маленький",
      "frequency": 135
    },
    {
      "phrase": "рюкзачок для девочки маленький для садика",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для девочки мини",
      "frequency": 45
    },
    {
      "phrase": "рюкзачок для девочки подростков для прогулок",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для малыша 1 год",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для малышей 1-2 года",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для малышей маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для садика для девочки",
      "frequency": 50
    },
    {
      "phrase": "рюкзачок маленький детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок маленький для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок маленький для девочки 10 лет",
      "frequency": 50
    },
    {
      "phrase": "спортивные рюкзаки для девочек",
      "frequency": 0
    },
    {
      "phrase": "спортивный детский рюкзак",
      "frequency": 135
    },
    {
      "phrase": "спортивный детский рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "спортивный рюкзак детский",
      "frequency": 160
    },
    {
      "phrase": "спортивный рюкзак для девочки подростка",
      "frequency": 0
    },
    {
      "phrase": "тряпочный рюкзак мини",
      "frequency": 0
    },
    {
      "phrase": "школьник рюкзаки девочки",
      "frequency": 0
    },
    {
      "phrase": "школьный портфель для девочек 10 лет",
      "frequency": 0
    },
    {
      "phrase": "школьный рюкзак авокадо",
      "frequency": 0
    },
    {
      "phrase": "школьный рюкзак для подростка девочки модный",
      "frequency": 70
    },
    {
      "phrase": "яркий рюкзак для девочки",
      "frequency": 0
    }
  ],
  "AminusB": [
    {
      "phrase": "0 рюкзак детский",
      "frequency": 0
    },
    {
      "phrase": "5 в 1 рюкзак для девочек",
      "frequency": 0
    },
    {
      "phrase": "5 в 1 рюкзак школьный",
      "frequency": 0
    },
    {
      "phrase": "аксессуары для девочек 5 лет",
      "frequency": 100
    },
    {
      "phrase": "вместительный рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "детская рюкзак",
      "frequency": 85
    },
    {
      "phrase": "детские ранцы",
      "frequency": 105
    },
    {
      "phrase": "детские рюкзак",
      "frequency": 85
    },
    {
      "phrase": "детские рюкзаки в сад",
      "frequency": 0
    },
    {
      "phrase": "детские рюкзаки в школу",
      "frequency": 55
    },
    {
      "phrase": "детские рюкзаки в школу для девочек",
      "frequency": 0
    },
    {
      "phrase": "детские рюкзаки для девочек школьные",
      "frequency": 0
    },
    {
      "phrase": "детские рюкзачки",
      "frequency": 165
    },
    {
      "phrase": "детские рюкзачки в садик",
      "frequency": 150
    },
    {
      "phrase": "детские спортивный рюкзаки",
      "frequency": 0
    },
    {
      "phrase": "детские школьные рюкзаки",
      "frequency": 55
    },
    {
      "phrase": "детские школьные рюкзаки для девочек",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак",
      "frequency": 3900
    },
    {
      "phrase": "детский рюкзак в сад",
      "frequency": 105
    },
    {
      "phrase": "детский рюкзак в школу девочке",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак в школу для девочек",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак для девочек в школу",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак для девочек школьный",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак для девочки",
      "frequency": 2165
    },
    {
      "phrase": "детский рюкзак для девочки в садик 2",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак для девочки в садик 2 года",
      "frequency": 50
    },
    {
      "phrase": "детский рюкзак для девочки в школу",
      "frequency": 120
    },
    {
      "phrase": "детский рюкзак для малыша 1 годик",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак для малышей девочки",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак для сада",
      "frequency": 150
    },
    {
      "phrase": "детский рюкзак для сада маленький",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзачок",
      "frequency": 365
    },
    {
      "phrase": "детский рюкзачок в садик",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзачок в садик для девочки",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзачок для девочки",
      "frequency": 210
    },
    {
      "phrase": "детский рюкзачок дошкольный",
      "frequency": 0
    },
    {
      "phrase": "детский школьный рюкзак",
      "frequency": 60
    },
    {
      "phrase": "детский школьный рюкзак для девочки",
      "frequency": 50
    },
    {
      "phrase": "для подростка девочки рюкзак",
      "frequency": 0
    },
    {
      "phrase": "дошкольные рюкзаки",
      "frequency": 1030
    },
    {
      "phrase": "дошкольный рюкзак",
      "frequency": 415
    },
    {
      "phrase": "маленькие рюкзаки для девочек для детей",
      "frequency": 0
    },
    {
      "phrase": "маленькие рюкзаки для девочек модные",
      "frequency": 0
    },
    {
      "phrase": "маленькие рюкзаки для детей",
      "frequency": 0
    },
    {
      "phrase": "маленькие рюкзаки для детей девочек",
      "frequency": 0
    },
    {
      "phrase": "маленькие рюкзаки для подростков",
      "frequency": 90
    },
    {
      "phrase": "маленькие рюкзаки для подростков для девочек",
      "frequency": 0
    },
    {
      "phrase": "маленькие рюкзачки для девочек-подросток",
      "frequency": 0
    },
    {
      "phrase": "маленькие рюкзачки для подростков",
      "frequency": 0
    },
    {
      "phrase": "маленький рюкзак детский для девочек",
      "frequency": 0
    },
    {
      "phrase": "маленький рюкзак для девочки",
      "frequency": 525
    },
    {
      "phrase": "маленький рюкзак для девочки в сад",
      "frequency": 0
    },
    {
      "phrase": "маленький рюкзачок для подростка",
      "frequency": 0
    },
    {
      "phrase": "милые рюкзаки для девочки",
      "frequency": 0
    },
    {
      "phrase": "милый рюкзак для школы",
      "frequency": 135
    },
    {
      "phrase": "милый школьный рюкзак",
      "frequency": 60
    },
    {
      "phrase": "мини подарки детям в школу",
      "frequency": 55
    },
    {
      "phrase": "мини рюкзак",
      "frequency": 2440
    },
    {
      "phrase": "мини рюкзак для подростков",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзак маленький",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзак спортивный",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзаки",
      "frequency": 155
    },
    {
      "phrase": "мини рюкзаки для школы",
      "frequency": 0
    },
    {
      "phrase": "мини-рюкзак",
      "frequency": 65
    },
    {
      "phrase": "модные аксессуары для подростков",
      "frequency": 50
    },
    {
      "phrase": "модные рюкзаки 2023",
      "frequency": 60
    },
    {
      "phrase": "модные рюкзаки для подростков",
      "frequency": 0
    },
    {
      "phrase": "модные рюкзаки молодежные",
      "frequency": 0
    },
    {
      "phrase": "модный рюкзак для девочки для прогулок",
      "frequency": 0
    },
    {
      "phrase": "модный рюкзак для подростка",
      "frequency": 50
    },
    {
      "phrase": "модный рюкзак для подростка девочки",
      "frequency": 0
    },
    {
      "phrase": "небольшой подарок девушке",
      "frequency": 0
    },
    {
      "phrase": "новогодний рюкзак",
      "frequency": 205
    },
    {
      "phrase": "повседневный рюкзак",
      "frequency": 45
    },
    {
      "phrase": "подростковые рюкзаки для девочек",
      "frequency": 0
    },
    {
      "phrase": "портфель школьный для девочки подростка 2023",
      "frequency": 0
    },
    {
      "phrase": "портфель школьный для подростка девочки 2023",
      "frequency": 0
    },
    {
      "phrase": "ранец детский",
      "frequency": 45
    },
    {
      "phrase": "ранец детский школьный",
      "frequency": 0
    },
    {
      "phrase": "ранец для девочки",
      "frequency": 445
    },
    {
      "phrase": "ранец для первоклассника для девочки",
      "frequency": 700
    },
    {
      "phrase": "ранец школьный для девочки синий",
      "frequency": 0
    },
    {
      "phrase": "рюкзак 2 класс",
      "frequency": 50
    },
    {
      "phrase": "рюкзак 2023 модный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак 7 в 1 для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак в детский сад",
      "frequency": 280
    },
    {
      "phrase": "рюкзак вентилируемая спина",
      "frequency": 0
    },
    {
      "phrase": "рюкзак гимнастический детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак городской маленький для подростка",
      "frequency": 0
    },
    {
      "phrase": "рюкзак девочки подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детская в школу для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детские",
      "frequency": 75
    },
    {
      "phrase": "рюкзак детские в школу",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский",
      "frequency": 9290
    },
    {
      "phrase": "рюкзак детский в сад",
      "frequency": 70
    },
    {
      "phrase": "рюкзак детский в сад для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек в сад",
      "frequency": 295
    },
    {
      "phrase": "рюкзак детский для девочек в садик с предметами",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек в школу",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек дошкольный",
      "frequency": 180
    },
    {
      "phrase": "рюкзак детский для девочек дошкольный 6 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек маленький 7 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек школьный",
      "frequency": 145
    },
    {
      "phrase": "рюкзак детский для девочки 5 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочки маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для малыша от 1 года",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для малышей 1",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для малышей 1 год",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для малышей в садик для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для сада",
      "frequency": 45
    },
    {
      "phrase": "рюкзак детский дошкольный",
      "frequency": 355
    },
    {
      "phrase": "рюкзак детский дошкольный для малыше",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский сад",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский спортивный",
      "frequency": 365
    },
    {
      "phrase": "рюкзак детский школа",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский школьные для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский школьный",
      "frequency": 205
    },
    {
      "phrase": "рюкзак детский школьный для девочек",
      "frequency": 50
    },
    {
      "phrase": "рюкзак для 2 класса",
      "frequency": 90
    },
    {
      "phrase": "рюкзак для вещей малыша",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для воды детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочек подростков",
      "frequency": 440
    },
    {
      "phrase": "рюкзак для девочек подростков городской",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочек подростков повседневный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочек подростков повседневный маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочек подростков повседневный подарок",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочек подростков спортивный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочек подростков школьный повседневный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочке",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки 10 лет для прогулок",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки 10 лет модный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки детский школьный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки дошкольный",
      "frequency": 1405
    },
    {
      "phrase": "рюкзак для девочки подростка повседневный",
      "frequency": 115
    },
    {
      "phrase": "рюкзак для девочки подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки спортивный для гимнастики",
      "frequency": 95
    },
    {
      "phrase": "рюкзак для девочки спортивный для танцев",
      "frequency": 85
    },
    {
      "phrase": "рюкзак для детей",
      "frequency": 315
    },
    {
      "phrase": "рюкзак для детей в сад",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для детей детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для детей школьный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для детей школьный для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для дети",
      "frequency": 85
    },
    {
      "phrase": "рюкзак для детский для сада",
      "frequency": 80
    },
    {
      "phrase": "рюкзак для дошкольника",
      "frequency": 335
    },
    {
      "phrase": "рюкзак для дошкольников девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для маленькой девочки детский сад",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для малыша",
      "frequency": 780
    },
    {
      "phrase": "рюкзак для малыша 1 год",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для малыша 1-2",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для малыша 1-2 года",
      "frequency": 70
    },
    {
      "phrase": "рюкзак для малыша 2",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для малыша в сад",
      "frequency": 105
    },
    {
      "phrase": "рюкзак для малыша маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для малышей",
      "frequency": 1845
    },
    {
      "phrase": "рюкзак для малышей в сад",
      "frequency": 300
    },
    {
      "phrase": "рюкзак для малышей в сад девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для первоклассника девочки",
      "frequency": 200
    },
    {
      "phrase": "рюкзак для первоклассника девочки маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для плавания спортивный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для подростка девочки",
      "frequency": 1660
    },
    {
      "phrase": "рюкзак для подростка девочки маленький городской",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для подростка маленький",
      "frequency": 50
    },
    {
      "phrase": "рюкзак для подростка маленький спортивный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для подростка модный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для подростка спортивный",
      "frequency": 60
    },
    {
      "phrase": "рюкзак для подростков девочек",
      "frequency": 395
    },
    {
      "phrase": "рюкзак для подростков девочек маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для подростков спортивный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для поездок для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для прогулки для подростка",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для прогулок девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для прогулок с малышом",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для ребенка",
      "frequency": 120
    },
    {
      "phrase": "рюкзак для ребенка в сад",
      "frequency": 60
    },
    {
      "phrase": "рюкзак для ребенка для спорта",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для сада",
      "frequency": 305
    },
    {
      "phrase": "рюкзак для спорта подростковый",
      "frequency": 95
    },
    {
      "phrase": "рюкзак для спорта спортивный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для спортивной одежды",
      "frequency": 50
    },
    {
      "phrase": "рюкзак для спортивной одежды в школу",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для спортивной формы детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для танцев и гимнастики",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для формы детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для формы на тренировку",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для формы спортивной",
      "frequency": 75
    },
    {
      "phrase": "рюкзак для школы для девочек 10 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак дошкольника",
      "frequency": 170
    },
    {
      "phrase": "рюкзак дошкольный для девочки 6 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак дошкольный для девочки в садик",
      "frequency": 215
    },
    {
      "phrase": "рюкзак маленький для девочки для прогулки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак маленький для подростка девочки",
      "frequency": 80
    },
    {
      "phrase": "рюкзак малышам",
      "frequency": 60
    },
    {
      "phrase": "рюкзак малышам в сад",
      "frequency": 0
    },
    {
      "phrase": "рюкзак малышу",
      "frequency": 70
    },
    {
      "phrase": "рюкзак малышу в сад",
      "frequency": 0
    },
    {
      "phrase": "рюкзак милый для школы",
      "frequency": 65
    },
    {
      "phrase": "рюкзак мини",
      "frequency": 1395
    },
    {
      "phrase": "рюкзак мини для девочки маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак модный для подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзак молодежный для подростка",
      "frequency": 155
    },
    {
      "phrase": "рюкзак на девочку подростка",
      "frequency": 0
    },
    {
      "phrase": "рюкзак небольшой спортивный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак непромокаемый для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак повседневный",
      "frequency": 315
    },
    {
      "phrase": "рюкзак повседневный городской",
      "frequency": 0
    },
    {
      "phrase": "рюкзак повседневный детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак повседневный спортивный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак подростковый для девочек",
      "frequency": 1245
    },
    {
      "phrase": "рюкзак подростковый для девочек маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак подростковый для девочки",
      "frequency": 150
    },
    {
      "phrase": "рюкзак подростку девочке",
      "frequency": 0
    },
    {
      "phrase": "рюкзак ранец для первоклассника",
      "frequency": 0
    },
    {
      "phrase": "рюкзак с авокадо для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак с подарками на новый год",
      "frequency": 0
    },
    {
      "phrase": "рюкзак с принтом для подростка",
      "frequency": 125
    },
    {
      "phrase": "рюкзак с рисунками для подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзак с рисунком для подростка",
      "frequency": 0
    },
    {
      "phrase": "рюкзак светлый",
      "frequency": 555
    },
    {
      "phrase": "рюкзак светлый маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак светлый текстиль",
      "frequency": 0
    },
    {
      "phrase": "рюкзак светлый школьный",
      "frequency": 75
    },
    {
      "phrase": "рюкзак сетка",
      "frequency": 50
    },
    {
      "phrase": "рюкзак синий",
      "frequency": 350
    },
    {
      "phrase": "рюкзак синий для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак со спинкой для подростка",
      "frequency": 60
    },
    {
      "phrase": "рюкзак спортивный детская",
      "frequency": 0
    },
    {
      "phrase": "рюкзак спортивный детский",
      "frequency": 265
    },
    {
      "phrase": "рюкзак спортивный детский для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак спортивный для девочки подростка",
      "frequency": 0
    },
    {
      "phrase": "рюкзак спортивный для подростка девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак спортивный для подростка маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак спортивный для подростка с принтом",
      "frequency": 0
    },
    {
      "phrase": "рюкзак сумка для девочки подростка",
      "frequency": 0
    },
    {
      "phrase": "рюкзак текстильный спортивный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак через плечо детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьника для подростка",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный детский",
      "frequency": 495
    },
    {
      "phrase": "рюкзак школьный детский городской",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный детский для девочки",
      "frequency": 115
    },
    {
      "phrase": "рюкзак школьный для девочек детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный для девочки 10 лет",
      "frequency": 80
    },
    {
      "phrase": "рюкзак школьный для детей",
      "frequency": 75
    },
    {
      "phrase": "рюкзак школьный для первоклассника облегченный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный небольшой для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный светлый",
      "frequency": 220
    },
    {
      "phrase": "рюкзак школьный синий для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзака детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки в школу детям",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки детские",
      "frequency": 280
    },
    {
      "phrase": "рюкзаки детские в школу",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки детские в школу для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки детские для девочек школьные",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки детские для сада",
      "frequency": 130
    },
    {
      "phrase": "рюкзаки детские для сада для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки детские школьные",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки детские школьные для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для девочек 5 в 1",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для девочек в садик для 6 лет.",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для девочки маленькие",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для детей",
      "frequency": 210
    },
    {
      "phrase": "рюкзаки для детей спорт",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для малыша",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для малышей",
      "frequency": 150
    },
    {
      "phrase": "рюкзаки для малышей девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для подростка девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для подростков девочек школьный яркий",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для подростков для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для подростков спортивные",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для подростков спортивные синий",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для садика",
      "frequency": 50
    },
    {
      "phrase": "рюкзаки для школы для детей",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки мини",
      "frequency": 70
    },
    {
      "phrase": "рюкзаки модные 2023",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки модные для подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки спортивные для подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки спортивные молодежные",
      "frequency": 0
    },
    {
      "phrase": "рюкзачки детские",
      "frequency": 0
    },
    {
      "phrase": "рюкзачки для девочек",
      "frequency": 145
    },
    {
      "phrase": "рюкзачки для девочек детские",
      "frequency": 0
    },
    {
      "phrase": "рюкзачки для девочек подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзачки для малышей",
      "frequency": 250
    },
    {
      "phrase": "рюкзачки для малышей в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок в сад",
      "frequency": 50
    },
    {
      "phrase": "рюкзачок детский",
      "frequency": 590
    },
    {
      "phrase": "рюкзачок детский в садик",
      "frequency": 65
    },
    {
      "phrase": "рюкзачок детский для девочек",
      "frequency": 60
    },
    {
      "phrase": "рюкзачок для девочки",
      "frequency": 1305
    },
    {
      "phrase": "рюкзачок для девочки детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для девочки для прогулок",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для девочки дошкольный",
      "frequency": 55
    },
    {
      "phrase": "рюкзачок для девочки дошкольный 6 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для девочки маленький подарочный",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для девочки подростка",
      "frequency": 110
    },
    {
      "phrase": "рюкзачок для девочки подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для девочки подростков мини",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для девочки подростков модный",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для девочки-подростка маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для малыша",
      "frequency": 140
    },
    {
      "phrase": "рюкзачок для малыша 1-2",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для малыша в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для малышей",
      "frequency": 210
    },
    {
      "phrase": "рюкзачок для малышей 1-2",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для малышей в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для малышей девочка",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для садика",
      "frequency": 130
    },
    {
      "phrase": "светлый рюкзак",
      "frequency": 90
    },
    {
      "phrase": "светлый рюкзак школьный",
      "frequency": 0
    },
    {
      "phrase": "сетка для рюкзака",
      "frequency": 60
    },
    {
      "phrase": "синий рюкзак",
      "frequency": 105
    },
    {
      "phrase": "синий рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "синий рюкзак спортивный",
      "frequency": 0
    },
    {
      "phrase": "синий рюкзак школьный для девочки",
      "frequency": 0
    },
    {
      "phrase": "спортивные рюкзаки детские",
      "frequency": 0
    },
    {
      "phrase": "спортивные рюкзаки для подростков",
      "frequency": 0
    },
    {
      "phrase": "спортивные формы рюкзак",
      "frequency": 80
    },
    {
      "phrase": "спортивный рюкзак для девочки",
      "frequency": 370
    },
    {
      "phrase": "спортивный рюкзак для девочки для гимнастики",
      "frequency": 0
    },
    {
      "phrase": "спортивный рюкзак для девочки для танцев",
      "frequency": 0
    },
    {
      "phrase": "спортивный рюкзак для подростка",
      "frequency": 65
    },
    {
      "phrase": "спортивный рюкзак для подростка девочки",
      "frequency": 0
    },
    {
      "phrase": "сумка для девочки подростков рюкзак",
      "frequency": 0
    },
    {
      "phrase": "сумка рюкзак для девочки подростков",
      "frequency": 0
    },
    {
      "phrase": "школьные рюкзаки для девочки 10 лет",
      "frequency": 0
    },
    {
      "phrase": "школьный детский рюкзак",
      "frequency": 50
    },
    {
      "phrase": "школьный детский рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "школьный рюкзак 5 в 1 для девочки",
      "frequency": 0
    },
    {
      "phrase": "школьный рюкзак детский",
      "frequency": 65
    },
    {
      "phrase": "школьный рюкзак детский для девочек",
      "frequency": 0
    },
    {
      "phrase": "школьный рюкзак для девочки первоклассника",
      "frequency": 60
    },
    {
      "phrase": "штучки для девочек подростков",
      "frequency": 295
    }
  ],
  "BminusA": [
    {
      "phrase": "аксессуары для рюкзака рукоделие",
      "frequency": 0
    },
    {
      "phrase": "аксессуары для танцев",
      "frequency": 155
    },
    {
      "phrase": "аксессуары для танцев девочки",
      "frequency": 0
    },
    {
      "phrase": "детские рюкзаки для девочек дошкольный",
      "frequency": 0
    },
    {
      "phrase": "детские рюкзаки для девочек на 2 года",
      "frequency": 0
    },
    {
      "phrase": "детский маленький рюкзак для девочки",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак для девочки в сад",
      "frequency": 175
    },
    {
      "phrase": "детский рюкзак для девочки дошкольный 2 года",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак для девочки дошкольный 5 лет",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак для малыша",
      "frequency": 85
    },
    {
      "phrase": "детский рюкзак для малышей от года",
      "frequency": 0
    },
    {
      "phrase": "детский рюкзак дошкольный для девочки",
      "frequency": 0
    },
    {
      "phrase": "красные аксессуары для девочки",
      "frequency": 0
    },
    {
      "phrase": "красный рюкзак",
      "frequency": 280
    },
    {
      "phrase": "красный рюкзак в школу",
      "frequency": 0
    },
    {
      "phrase": "красный рюкзак спортивный",
      "frequency": 0
    },
    {
      "phrase": "маленький рюкзак в школу",
      "frequency": 55
    },
    {
      "phrase": "маленький рюкзак для девочки в школу",
      "frequency": 0
    },
    {
      "phrase": "маленький рюкзак для девочки подростков",
      "frequency": 105
    },
    {
      "phrase": "маленький рюкзаки для девочек",
      "frequency": 0
    },
    {
      "phrase": "маленький рюкзачок для девочки",
      "frequency": 220
    },
    {
      "phrase": "маленький школьный рюкзак",
      "frequency": 65
    },
    {
      "phrase": "милый рюкзак",
      "frequency": 800
    },
    {
      "phrase": "мини рюкзаки для подростков",
      "frequency": 0
    },
    {
      "phrase": "мини рюкзачок спортивный",
      "frequency": 0
    },
    {
      "phrase": "мини-рюкзачок",
      "frequency": 45
    },
    {
      "phrase": "модные рюкзаки для девочек подростков",
      "frequency": 0
    },
    {
      "phrase": "модный рюкзак для девочки подростков",
      "frequency": 0
    },
    {
      "phrase": "портфель школьный для подростка девочки качественный",
      "frequency": 50
    },
    {
      "phrase": "ранец школьный для первоклассника девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак 100 литров туристический",
      "frequency": 60
    },
    {
      "phrase": "рюкзак 4 в 1 для девочек подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзак 5 в 1 для подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзак 5 в одном для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак 5в 1",
      "frequency": 45
    },
    {
      "phrase": "рюкзак 6 в 1 для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзак гимнастический для девочки",
      "frequency": 260
    },
    {
      "phrase": "рюкзак девочке спортивный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детские маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский в садик водонепроницаемый",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский девочке маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочек в садик с 2 лет",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для девочки 2 года",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский для малыша",
      "frequency": 70
    },
    {
      "phrase": "рюкзак детский дошкольный для девочек",
      "frequency": 95
    },
    {
      "phrase": "рюкзак детский маленький в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзак детский маленький для девочек",
      "frequency": 55
    },
    {
      "phrase": "рюкзак для девочек маленький на прогулку",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки 10 лет школьный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки 2 года",
      "frequency": 80
    },
    {
      "phrase": "рюкзак для девочки 4 в 1",
      "frequency": 100
    },
    {
      "phrase": "рюкзак для девочки детский",
      "frequency": 160
    },
    {
      "phrase": "рюкзак для девочки детский маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки маленький подростковый",
      "frequency": 115
    },
    {
      "phrase": "рюкзак для девочки мини для подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки подростка маленький для прогулок",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки подростка школьный повседневный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки спортивный маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для девочки спортивный с карманами для плавания",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для детей маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для дошкольника девочки",
      "frequency": 280
    },
    {
      "phrase": "рюкзак для малышей маленький",
      "frequency": 110
    },
    {
      "phrase": "рюкзак для новорожденного малыша",
      "frequency": 70
    },
    {
      "phrase": "рюкзак для плавания детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для подростка девочки на прогулку",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для поездок детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для прогулки маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для прогулок детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для прогулок для девочек подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзак для садика для девочки",
      "frequency": 515
    },
    {
      "phrase": "рюкзак для спортивной формы в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзак дошкольный для девочки",
      "frequency": 1355
    },
    {
      "phrase": "рюкзак дошкольный для девочки 2 года",
      "frequency": 0
    },
    {
      "phrase": "рюкзак красный",
      "frequency": 545
    },
    {
      "phrase": "рюкзак красный детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак красный для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак красный спортивный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак красный школьный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак маленький детский",
      "frequency": 190
    },
    {
      "phrase": "рюкзак маленький для девочки детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак маленький спортивный для подростка",
      "frequency": 0
    },
    {
      "phrase": "рюкзак малышу маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак мини для девочки подростков",
      "frequency": 0
    },
    {
      "phrase": "рюкзак модный для девочек",
      "frequency": 100
    },
    {
      "phrase": "рюкзак на девочку 2 года",
      "frequency": 0
    },
    {
      "phrase": "рюкзак на девочку в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзак на прогулку для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак на садик",
      "frequency": 50
    },
    {
      "phrase": "рюкзак на секцию",
      "frequency": 65
    },
    {
      "phrase": "рюкзак первоклассника маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак повседневный для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак подростковый для девочек для прогулки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак с игрушкой школьный",
      "frequency": 45
    },
    {
      "phrase": "рюкзак с машиной дошкольный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак с рисунком маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак садик",
      "frequency": 60
    },
    {
      "phrase": "рюкзак садик для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак спортивный детский маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзак спортивный для девочки с застежкой",
      "frequency": 0
    },
    {
      "phrase": "рюкзак спортивный маленький детский",
      "frequency": 0
    },
    {
      "phrase": "рюкзак текстильный маленький",
      "frequency": 50
    },
    {
      "phrase": "рюкзак через одно плечо для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьника для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный 10 лет",
      "frequency": 55
    },
    {
      "phrase": "рюкзак школьный 10 лет девочка",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный 5 в 1 для девочки",
      "frequency": 90
    },
    {
      "phrase": "рюкзак школьный для девочки подростка модный",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный для первоклассника девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный для подростков модный для девочек 2023",
      "frequency": 0
    },
    {
      "phrase": "рюкзак школьный красный",
      "frequency": 60
    },
    {
      "phrase": "рюкзак яркий для девочки",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки 5 в 1 для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзаки для малышей от 1 года",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок детский для девочки 2 годика",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок детский для девочки маленький в садик",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для девочки в садик",
      "frequency": 410
    },
    {
      "phrase": "рюкзачок для девочки в садик маленький",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок для девочки маленький в детский сад",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок маленький детский для девочек",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок маленький для малышей",
      "frequency": 0
    },
    {
      "phrase": "рюкзачок маленький для прогулок",
      "frequency": 0
    },
    {
      "phrase": "текстильный рюкзак маленький",
      "frequency": 0
    },
    {
      "phrase": "туристический рюкзак 100 литров",
      "frequency": 55
    },
    {
      "phrase": "школьные рюкзаки маленькие",
      "frequency": 0
    },
    {
      "phrase": "школьный рюкзак для первоклассника для девочки",
      "frequency": 0
    },
    {
      "phrase": "школьный рюкзак маленький",
      "frequency": 55
    }
  ],
  "HUINYA": [
    {
      "image1": "https://basket-02.wb.ru/vol172/part17237/17237847/images/big/1.jpg",
      "name1": "Рюкзак дошкольный детский для девочки подростка авокадо",
      "image2": "https://basket-02.wb.ru/vol172/part17237/17237850/images/big/1.jpg",
      "name2": "Рюкзак дошкольный детский для девочки подростка авокадо"
    }
  ],
  "circleA": 752,
  "circleB": 547,
  "circleAB": 421
}

export default compareSku