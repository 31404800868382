import axios from 'axios';
import API_ENV from '@/config/api.config';
import queryString from 'query-string';
import { analytics } from '@/mocks';

let { apiIAnalytics } = API_ENV;

function isEmptyChartData(chartData) {
  if (!chartData) return true;

  if (chartData.dataset) {
    const data = chartData.dataset.data;

    const counter = data.reduce((prev, cur) => prev + cur, 0);

    if (counter) return false;

    return true;
  }

  const datasets = chartData.datasets;

  for (const dataset of datasets) {
    const counter = dataset.data.reduce((prev, cur) => prev + cur, 0);

    // Если сумма значений в графике не ровна нулю
    if (counter) return false;
  }

  return true;
}

export default {
  state: {
    filters: {
      brand: null,
      cabinet: null,
      combine_colors: false,
      combine_sizes: false,
      no_organic: false,
      no_organic_expenses: false,
      no_organic_percents: false,
      no_organic_profit: false,
      no_selfbuy_expenses: false,
      no_selfbuy_percents: false,
      no_selfbuys: false,
      object: null,
      operation_time_range: null,
      order_time_range: null,
      product: null,
      product_age_input: null,
      product_age_select: null,
      report_id: null,
      sale_time_range: null,
    },
    analyticCards: [
      {
        title: 'Продажи',
        value: 0,
      },
      {
        title: 'Возвраты',
        value: 0,
      },
      {
        title: 'Чистая прибыль',
        value: 0,
      },
      {
        title: 'Закупочная стоимость',
        value: 0,
      },
      {
        title: 'Расходы',
        value: 0,
      },
    ],
    chartsData: {
      multiple: {
        labels: [],
        datasets: [
          {
            label: 'Заказы',
            data: [],
          },
          {
            label: 'Оборотка',
            data: [],
          },
          {
            label: 'Продажи',
            data: [],
          },
          {
            label: 'Прибыль',
            data: [],
          },
          {
            label: 'Возвраты',
            data: [],
          },
        ],
      },
      expense: {
        labels: ['Комиссия ВБ', 'Логистика', 'Налоги', 'Ежемесячные', 'По отчётам', 'На SKU'],
        datasets: [
          {
            data: [],
            backgroundColor: ['#49AAE0', '#97E3D5', '#E8A838', '#E8C1A0', '#F1E15B', '#F47560'],
            hoverOffset: 4,
          },
        ],
      },
      ABC: {
        labels: ['Группа A', 'Группа B', 'Группа C'],
        dataset: {
          data: [],
          backgroundColor: ['#49AAE0', '#97E3D5', '#F47560'],
          textColor: ['#fff', '##214D45', '#fff'],
        },
      },
      sales: null,
      finance: null,
    },
    tableData: null,
    isTableLoading: true,
    currentRequest: null,
    hiddenABC: [],
  },
  getters: {
    getAnalysisChartsData: ({ chartsData }) => chartsData,
    getAnalysisTableData: state => {
      const tableData = _.cloneDeep(state.tableData);
      const hiddenABC = state.hiddenABC;

      if (tableData)
        tableData.rowData = tableData.rowData.filter(row => !hiddenABC.includes(row.abc));

      return tableData;
    },
    getAnalysisCards: ({ analyticCards }) => analyticCards,
    getAnalysisTableLoading: ({ isTableLoading }) => isTableLoading,
  },
  mutations: {
    changeAnalysisChartsData(state, data) {
      state.chartsData = { ...state.chartsData, ...data };
    },
    setAnalysisTableLoading(state, isTableLoading) {
      state.isTableLoading = isTableLoading;
    },
    setAnalysisTableData(state, data) {
      state.tableData = data;
    },
    convertAnalyticCards(
      state,
      { sale_price, return_price, income, purchase_price, all_expenses },
    ) {
      state.analyticCards[0].value = sale_price;
      state.analyticCards[1].value = Math.abs(return_price);
      state.analyticCards[2].value = income;
      state.analyticCards[3].value = purchase_price;
      state.analyticCards[4].value = all_expenses;
    },
    setAnalyticCurrentRequest(state, request) {
      state.currentRequest = request;
    },
    setHiddenABC(state, hidden) {
      state.hiddenABC = hidden;
    },
  },
  actions: {
    async getAnalyticsData({ state, getters, commit, dispatch }, paramsArg) {
      if (state.currentRequest) state.currentRequest.cancel();

      const cancelToken = axios.CancelToken.source();
      commit('setAnalyticCurrentRequest', cancelToken);

      const params = { ...state.filters, ...paramsArg };

      try {
        commit('setAnalysisTableLoading', true);
        const result = await axios.get(`${apiIAnalytics}?${queryString.stringify(params)}`, {
          cancelToken: cancelToken.token,
        });

        if (!getters.getDashboardIsExampleData) {
          dispatch('convertOtherChartsData', result.data.graf_data);
          result.data.table_data = await dispatch(
            'convertAnalyticsTableData',
            result.data.table_data,
          );
        }

        commit('setAnalysisTableLoading', false);
        commit('setAnalyticCurrentRequest', null);
        return result.data;
      } catch (error) {
        if (error instanceof TypeError) return;

        commit('setAnalysisTableLoading', false);
        commit('showAlert');
        throw error;
      }
    },
    convertAnalyticsTableData({ commit, dispatch }, tableDataObject) {
      const rowData = [];
      const totalRow = {};
      let unfilledTax = true;
      let unfilledPurchase = true;

      for (const key in tableDataObject) {
        const row = tableDataObject[key];

        if (row.nalog) unfilledTax = false;
        if (row.purchase_price) unfilledPurchase = false;

        row.wbComision = Math.round(row.kvw_sales_commission + row.vw_nds);
        row.wbComisionPerUnit = Math.round(row.kvw_sales_commission_per_unit + row.vw_nds_per_unit);

        if (key == 'total') {
          for (const key in row) {
            let value = row[key];

            if (value == 'ИТОГО' && key !== 'nm_id') value = '';

            totalRow[key] = value;
          }

          totalRow.hidePhoto = true;

          continue;
        }

        rowData.push(row);
      }

      dispatch('convertExpenseChartsData', totalRow);
      commit('convertAnalyticCards', totalRow);

      const tableData = { rowData, totalRow, unfilledTax, unfilledPurchase };
      commit('setAnalysisTableData', tableData);

      return tableData;
    },
    convertOtherChartsData({ commit }, grafData) {
      const { linear_graf, abc_graf } = grafData;
      const labels = [];
      const salesDatasets = [
        {
          label: 'Заказы',
          data: [],
        },
        {
          label: 'Возвраты',
          data: [],
        },
        {
          label: 'Продажи',
          data: [],
        },
      ];
      const financeDatasets = [
        {
          label: 'Оборотка',
          data: [],
        },
        {
          label: 'Прибыль',
          data: [],
        },
      ];

      const sortedKeys = Object.keys(linear_graf).sort((a, b) => new Date(a) - new Date(b));

      for (const date of sortedKeys) {
        const dateValue = linear_graf[date];

        labels.push(date.split('-').reverse().join('.'));

        salesDatasets[0].data.push(Math.round(dateValue.orders_count));
        salesDatasets[1].data.push(Math.round(dateValue.return_amount));
        salesDatasets[2].data.push(Math.round(dateValue.sale_amount));
        financeDatasets[0].data.push(Math.round(dateValue.retail_amount));
        financeDatasets[1].data.push(Math.round(dateValue.income));
      }

      const ABC = {
        labels: ['Группа A', 'Группа B', 'Группа C'],
        dataset: {
          data: [],
          backgroundColor: ['#49AAE0', '#97E3D5', '#F47560'],
          textColor: ['#fff', '#214D45', '#fff'],
        },
      };

      ABC.dataset.data[0] = abc_graf.A || 0;
      ABC.dataset.data[1] = abc_graf.B || 0;
      ABC.dataset.data[2] = abc_graf.C || 0;

      const chartsData = {
        sales: { labels, datasets: salesDatasets },
        finance: { labels, datasets: financeDatasets },
        ABC,
      };

      commit('changeAnalysisChartsData', chartsData);
    },
    convertExpenseChartsData({ commit }, totalRow) {
      const {
        kvw_sales_commission,
        vw_nds,
        delivery_price,
        nalog,
        month_expense,
        report_expense,
        per_one_expense,
      } = totalRow;

      const expenseData = [
        Math.abs(kvw_sales_commission + vw_nds),
        delivery_price,
        nalog,
        month_expense,
        report_expense,
        per_one_expense,
      ].map(num => +num.toFixed(2));

      const expense = {
        labels: ['Комиссия ВБ', 'Логистика', 'Налоги', 'Ежемесячные', 'По отчётам WB', 'На SKU'],
        datasets: [
          {
            data: expenseData,
            backgroundColor: ['#49AAE0', '#97E3D5', '#E8A838', '#E8C1A0', '#F1E15B', '#F47560'],
            hoverOffset: 4,
          },
        ],
      };
      const chartsData = {
        expense,
      };

      commit('changeAnalysisChartsData', chartsData);
    },
    initializeAnalyticMockData({ getters, commit }) {
      const analyticCharts = getters.getAnalysisChartsData;

      for (const chartKey in analyticCharts) {
        const curValue = analyticCharts[chartKey];
        const mockData = analytics.chartsData[chartKey];

        if (isEmptyChartData(curValue) && mockData)
          commit('changeAnalysisChartsData', { [chartKey]: mockData });
      }

      commit('setAnalysisTableLoading', false);

      if (!getters.getAnalysisTableData?.rowData)
        commit('setAnalysisTableData', analytics.tableData);
    },
  },
};
