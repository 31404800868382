import axios from 'axios';
import API_ENV from '@/config/api.config';

const { apiReportingBalance, apiReportingDds, apiReportingOpiu, apiReportingReceipts } = API_ENV;
const apiBalanceExcel = apiReportingBalance + 'excel/';
const apiDdsExcel = apiReportingDds + 'excel/';
const apiOpiuExcel = apiReportingOpiu;

const convertDateToMonthAndDay = date => date.split('-').reverse().slice(0, 2).join('.');
const convertDateToMonthString = date => {
  const months = {
    0: 'Январь',
    1: 'Февраль',
    2: 'Март',
    3: 'Апрель',
    4: 'Май',
    5: 'Июнь',
    6: 'Июль',
    7: 'Август',
    8: 'Сентябрь',
    9: 'Октябрь',
    10: 'Ноябрь',
    11: 'Декабрь',
  };

  const monthNum = new Date(date).getMonth();

  return months[monthNum];
};

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getReportingBalance({ commit }, params) {
      try {
        const result = await axios.get(apiReportingBalance, { params });
        const balanceData = result.data;

        const addRow = {
          asset: 'Добавить',
          isAddAssetRow: true,
        };

        const rowData = {
          wb_wh_purchase_price: {
            asset: 'Себестоимость товаров на складах ВБ',
          },
          our_wh_purchase_price: {
            asset: 'Себестоимость товаров на моих складах',
          },
          our_wh_coming_purchase_price: {
            asset: 'Себестоимость товаров в пути к нам',
          },
          balance: {
            asset: 'Сумма средств на балансе WB',
            isEditable: true,
            isBalanceRow: true,
            idForEdit: {},
          },
        };
        const assetsRowData = {};
        const totalBalanceRow = {
          asset: 'Итого баланс',
          rowClass: 'isTotalRow',
          rowHeight: 32,
        };
        const dates = [];

        for (const row of balanceData) {
          const date = convertDateToMonthAndDay(row.date);
          dates.push(date);

          totalBalanceRow[date] = row.total_sum;

          rowData.balance.idForEdit[date] = row.id;

          for (const key in rowData) {
            rowData[key][date] = row[key];
          }

          for (const asset of row.assets) {
            const assetName = asset.name;

            if (!assetsRowData[assetName])
              assetsRowData[assetName] = { asset: assetName, isEditable: true, idForEdit: {} };

            assetsRowData[assetName][date] = asset.amount;
            assetsRowData[assetName].idForEdit[date] = asset.id;
          }
        }

        const resultRows = [];

        for (const row in rowData) resultRows.push(rowData[row]);
        for (const row in assetsRowData) resultRows.push(assetsRowData[row]);

        return { rowData: resultRows, dates, totalBalanceRow: [addRow, totalBalanceRow] };
      } catch (error) {
        const errorText = error.response?.data.error;
        commit('showAlert', errorText);
        throw error;
      }
    },
    async changeReportingBalanceAmount({ commit }, body) {
      try {
        const result = await axios.put(apiReportingBalance, body);

        return result.data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async createReportingAsset({ commit }, body) {
      try {
        const result = await axios.post(apiReportingBalance, body);

        return result.data;
      } catch (error) {
        const errorText = error.response?.data.detail;
        commit('showAlert', errorText);
        throw error;
      }
    },
    async changeReportingAssetValue({ commit }, { id, amount }) {
      try {
        const body = [{ id, amount }];

        const result = await axios.patch(apiReportingBalance, body);

        return result.data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async getReportingDds({ commit }, params) {
      try {
        const result = await axios.get(apiReportingDds, { params });

        const ddsData = result.data;

        const sumRow = {
          operation: 'Сумма средств на начало периода',
          isSumForBegiining: true,
          isEditable: true,
          idForEdit: {},
          monthById: {},
          monthNum: null,
        };
        const incomeFromWb = {
          operation: 'Поступления от WB',
        };

        const dates = [];
        const expenses = {};
        const receiptsRows = [];
        const expensesRows = [];
        const perionEndSum = {
          operation: 'Средства на конец периода',
          rowHeight: 32,
          rowClass: 'isTotalRow',
        };

        for (const dds of ddsData) {
          const month = dds.month;
          const monthNum = new Date(dds.date).getMonth();

          sumRow[month] = dds.amount;
          sumRow.idForEdit[month] = dds.id;
          sumRow.monthById[dds.id] = monthNum;

          perionEndSum[month] = dds.total_sum.toFixed(2);
          incomeFromWb[month] = dds.income_from_wb.toFixed(2);
          dates.push(month);

          for (const expense of dds.expenses) {
            if (!expenses[expense.category]) expenses[expense.category] = [];

            const expenseCategory = expenses[expense.category];

            const expenseCategoryItem = expenseCategory.find(
              el => el.operation == expense.expense_title,
            );

            if (!expenseCategoryItem) {
              expenseCategory.push({
                operation: expense.expense_title,
                [expense.month]: expense.total_sum,
                operationType: expense.operation,
              });
            } else {
              expenseCategoryItem[expense.month] = expense.total_sum;
            }
          }

          for (const receipt of dds.receipts) {
            const receiptRow = receiptsRows.find(el => el.operation == receipt.name);

            if (!receiptRow) {
              const row = {
                operation: receipt.name,
                [receipt.month]: receipt.total_sum,
              };

              receiptsRows.push(row);
            } else {
              receiptRow[receipt.month] = receipt.total_sum;
            }
          }
        }

        for (const category in expenses) {
          const categoryRow = {
            operation: category,
            rowClass: 'category-row',
            rowHeight: 26,
          };

          expensesRows.push(categoryRow, ...expenses[category]);
        }

        const resultRows = [
          sumRow,
          {
            isWriteDownsRow: true,
            operation: 'Списания',
            rowHeight: 50,
            rowClass: 'writeDownsRow',
          },
          ...expensesRows,
          {
            isEntriesRow: true,
            operation: 'Поступления',
            rowHeight: 50,
            rowClass: 'entriesRow',
          },
          incomeFromWb,
          {
            operation: 'Прочие поступления',
            rowClass: 'category-row',
            rowHeight: 26,
          },
          ...receiptsRows,
        ];

        return { rowData: resultRows, dates, perionEndSum: [perionEndSum] };
      } catch (error) {
        const errorText = error.response.data.error;
        commit('showAlert', errorText);
        throw error;
      }
    },
    async changeReportingDdsSumForBeginnig({ commit }, { id, amount }) {
      try {
        const result = await axios.patch(apiReportingDds + id, null, { params: { amount } });

        return result.data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async getReportingOpiu({ commit }, params) {
      try {
        const result = await axios.get(apiReportingOpiu, { params });
        const opiuData = result.data;

        const opiuRows = {
          turnover: {
            rowTitle: 'Выручка/оборот',
            rowClass: 'turnoverRow',
            rowHeight: 50,
          },
          sale_price: {
            rowTitle: 'Сумма продаж',
          },
          return_price: {
            rowTitle: 'Сумма возвратов',
          },
          retail_amount: {
            rowTitle: 'Оборот',
          },
          expenses: {
            rowTitle: 'Расходы',
            rowClass: 'expensesRow',
            rowHeight: 50,
          },
          wb_commission: {
            rowTitle: 'Комиссия WB',
          },
          delivery_price: {
            rowTitle: 'Затраты на логистику',
          },
          purchase_price: {
            rowTitle: 'Затраты на закупку товара',
          },
          month_expense: {
            rowTitle: 'Ежемесячные расходы',
          },
          report_expense: {
            rowTitle: 'Расходы по отчётам WB',
          },
          all_expenses: {
            rowTitle: 'Расходы на SKU',
          },
          empty1: {
            rowClass: 'category-row',
            rowHeight: 26,
          },
          for_pay: {
            rowTitle: 'Валовая прибыль (переводит WB)',
          },
          empty2: {
            rowClass: 'category-row',
            rowHeight: 26,
          },
          nalog: {
            rowTitle: 'Налоги',
          },
          empty3: {},
          price_add: {
            rowTitle: 'Наценка',
            rowHeight: 26,
            rowClass: 'category-row',
          },
          rent: {
            rowTitle: 'Рентабельность',
            rowHeight: 26,
            rowClass: 'category-row',
          },
          margin: {
            rowTitle: 'Маржинальность',
            rowHeight: 26,
            rowClass: 'category-row',
          },
        };

        const incomeRow = {
          rowTitle: 'Чистая прибыль',
          rowHeight: 32,
          rowClass: 'isTotalRow',
        };

        const dates = [];
        const rowData = [];

        for (const opiu of opiuData) {
          const date = convertDateToMonthString(opiu.date);
          dates.push(date);

          incomeRow[date] = opiu.income.toFixed(2);

          for (const field in opiuRows)
            opiuRows[field][date] = opiu[field] && opiu[field].toFixed(2);
        }

        for (const key in opiuRows) rowData.push(opiuRows[key]);

        return { rowData, dates, incomeRow: [incomeRow] };
      } catch (error) {
        const errorText = error.response.data.error;
        commit('showAlert', errorText);
        throw error;
      }
    },
    async createReportingReceipts({ commit }, body) {
      try {
        const result = await axios.post(apiReportingReceipts, body);

        return result.data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async exportReportingTable({ commit }, { type, date_from, date_to }) {
      const converJSONDateToLocale = (date = '') => date.split('-').reverse().slice(0, 2).join('.');

      try {
        const apiExcelTypes = {
          balance: apiBalanceExcel,
          dds: apiDdsExcel,
          opiu: apiOpiuExcel,
        };
        const apiExcel = apiExcelTypes[type];
        const params = { date_from, date_to };

        let result;

        if (type == 'opiu')
          result = await axios.post(apiExcel, null, { responseType: 'blob', params });
        else result = await axios.get(apiExcel, { responseType: 'blob', params });

        const url = window.URL.createObjectURL(new Blob([result.data]));
        const date = new Date().toLocaleDateString('ru');

        const link = document.createElement('a');
        link.href = url;
        const fileName = `${type} ${converJSONDateToLocale(date_from)} - ${converJSONDateToLocale(
          date_to,
        )}.xlsx`;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        return result.data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
  },
};
