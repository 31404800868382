import axios from 'axios';
import API_ENV from '@/config/api.config';

const { apiKeywordsList, apiKeywordsListDetail } = API_ENV;

export default {
  state: {
    currentSampleId: null,
    count: 0,
    keywordsSamples: [],
    selectedKeywordsSamples: [],
  },
  getters: {
    isSelectedSample: state => sample =>
      state.selectedKeywordsSamples.some(selectedSample => selectedSample.id == sample.id),
    getKeywordsSampleById: state => id =>
      state.keywordsSamples.find(sample => sample.id == id)?.keywords,
    currentKeywordsSampleId: state => state.currentSampleId,
  },
  mutations: {
    setKeywordsSamplesCount(state, count) {
      state.count = count;
    },
    changeKeywordsSample(state, { index, newSample }) {
      state.keywordsSamples[index] = newSample;
    },
    setKeywordsSamples(state, keywordsSamples) {
      state.keywordsSamples = keywordsSamples;
    },
    addKeywordsSampleToSelected(state, sample) {
      state.selectedKeywordsSamples.push(sample);
    },
    deleteKeywordsSampleFromSelected(state, sample) {
      state.selectedKeywordsSamples = state.selectedKeywordsSamples.filter(
        selectedSample => selectedSample.id != sample.id,
      );
    },
    unshiftNewKeywordExample(state) {
      if (state.keywordsSamples?.length) return;

      state.keywordsSamples.unshift({
        isExample: true,
        id: -999,
        title: 'пальто РФ',
        created_at: '2023-10-31T14:01:47.479621+03:00',
        updated_at: '2023-10-31T14:01:47.479734+03:00',
        owner: 24956,
        keywords: [],
      });
    },
    removeKeywordExample(state) {
      state.keywordsSamples = state.keywordsSamples.filter(el => !el?.isExample);
    },
    unshiftNewKeywordSample(state, newSample) {
      state.keywordsSamples.unshift(newSample);
    },
    deleteKeywordSampleById(state, sampleId) {
      state.keywordsSamples = state.keywordsSamples.filter(sample => sample.id !== sampleId);
    },
    clearSelectedKeywordsSamples(state) {
      state.selectedKeywordsSamples = [];
    },
    setCurrentSampleId(state, id) {
      state.currentSampleId = id;
    },
  },
  actions: {
    getKeywordsDetail({ commit }, phrases) {
      try {
        const result = axios.post(apiKeywordsListDetail, { phrases });

        return result;
      } catch (error) {
        console.error(error);
      }
    },
    addKeywordsSampleToSelected({ commit, getters }, sample) {
      if (getters.isSelectedSample(sample)) return;

      commit('addKeywordsSampleToSelected', sample);
    },
    deleteKeywordsSampleFromSelected({ commit }, sample) {
      commit('deleteKeywordsSampleFromSelected', sample);
    },
    getKeywordsSamplesRequest({ commit }, requestParams) {
      requestParams = requestParams || {};

      requestParams.pageNum = requestParams.pageNum || 1;
      requestParams.pageSize = requestParams.pageSize || 4;

      const { pageNum, pageSize } = requestParams;

      return new Promise((resolve, reject) => {
        axios
          .get(`${apiKeywordsList}?page_num=${pageNum}&page_size=${pageSize}`)
          .then(response => {
            const keywordsSamplesData = response.data;

            commit('setKeywordsSamplesCount', keywordsSamplesData.count);
            commit('setKeywordsSamples', keywordsSamplesData.results);

            resolve(response);
          })
          .catch(err => {
            commit('showAlert');
            reject(err);
          });
      });
    },
    async getOnceKeywordsList({ commit }, id) {
      try {
        const result = await axios.get(`${apiKeywordsList}${id}/`);

        return result;
      } catch (error) {
        console.error(error);
      }
    },
    async patchSampleKeywords({ commit, state }, { newKeywords, id }) {
      try {
        const result = await axios.patch(`${apiKeywordsList}${id}/`, { keywords: newKeywords });

        return result.data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    editSampleKeywords({ commit, state }, { newKeywords, id }) {
      const prevKeywords = state.selectedKeywordsSamples.find(sample => sample.id === id).keywords;
      const newSample = { keywords: Array(...new Set(prevKeywords.concat(newKeywords))) };

      return new Promise((resolve, reject) => {
        axios
          .patch(`${apiKeywordsList}${id}/`, newSample)
          .then(response => {
            const newSample = response.data;

            resolve(newSample);
          })
          .catch(err => {
            commit('showAlert');
            reject(err);
          });
      });
    },
    addKeywordsToselectedKeywordsSamples({ commit, dispatch, state }, newKeywords) {
      const samples = state.selectedKeywordsSamples;
      const allPromises = [];

      if (!samples.length) {
        commit('showAlert', 'Выберите список ключевых слов');
        return Promise.reject(false);
      }

      samples.forEach((sample, index) => {
        const newKeywordsInfo = { newKeywords, id: sample.id };

        const newPromice = dispatch('editSampleKeywords', newKeywordsInfo).then(res => {
          const newSampleInfo = { index, newSample: res };

          commit('changeKeywordsSample', newSampleInfo);
        });

        allPromises.push(newPromice);
      });

      return Promise.all(allPromises);
    },
    async createNewKeywordSample({ commit }, sampleName) {
      const newSamle = {
        title: sampleName,
        keywords: [],
      };
      try {
        const { data } = await axios.post(apiKeywordsList, newSamle);
        const newSampleData = data;
        commit('unshiftNewKeywordSample', newSampleData);
        return data;
      } catch (error) {
        commit('showAlert', error.response.data.errors);
        return error;
      }
    },
    getKeywordsDetailInfoData({ commit, getters }, keywordsSampleId) {
      const keywords = getters.getKeywordsSampleById(keywordsSampleId);

      if (!keywords.length) return Promise.resolve([]);

      return new Promise((resolve, reject) => {
        axios
          .post(apiKeywordsListDetail, { phrases: keywords })
          .then(response => {
            const result = response.data;

            resolve(result);
          })
          .catch(err => {
            commit('showAlert');
            reject(err);
          });
      });
    },
    deleteKeywordsSample({ commit }, sampleId) {
      console.log('sampleId: ', sampleId);
      return new Promise((resolve, reject) => {
        axios
          .delete(`${apiKeywordsList}${sampleId}/`, sampleId)
          .then(response => {
            commit('deleteKeywordSampleById', sampleId);

            resolve(response);
          })
          .catch(err => {
            commit('showAlert');
            reject(err);
          });
      });
    },
  },
};
