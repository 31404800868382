import axios from 'axios';
import API_ENV from '@/config/api.config';

let { apiPaySuccess, apiPayError, apiGetInvoice, apiGetPermissions } = API_ENV;

export default {
  state: {
    enableSections: {},
  },
  getters: {
    getEnableSections: state => state.enableSections,
  },
  mutations: {
    setEnableSections(state, sections) {
      state.enableSections = sections;
    },
  },
  actions: {
    payError({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(apiPayError)
          .then(response => resolve(response))
          .catch(err => {
            commit('showAlert');
            reject(err);
          });
      });
    },
    paySuccess({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(apiPaySuccess)
          .then(response => resolve(response))
          .catch(err => {
            commit('showAlert');
            reject(err);
          });
      });
    },
    getInvoice({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(apiGetInvoice, data, { responseType: 'blob' })
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `invoice.pdf`);

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);

            window.URL.revokeObjectURL(url);
            resolve();
          })
          .catch(error => {
            commit('showAlert');
            console.error(error);
            reject();
          });
      });
    },
    getInvoiceData({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(apiGetInvoice)
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            commit('showAlert');
            reject(err);
          });
      });
    },
    downloadInvoiceById({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${apiGetInvoice}${id}/`, { responseType: 'blob' })
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `invoice${id}.pdf`);

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);

            window.URL.revokeObjectURL(url);
            resolve(response.data);
          })
          .catch(err => {
            commit('showAlert');
            reject(err);
          });
      });
    },
    async getPermissions({ commit }) {
      try {
        const permissions = (await axios.get(apiGetPermissions)).data;

        commit('setEnableSections', permissions);

        return permissions;
      } catch (error) {
        throw error;
      }
    },
  },
};
