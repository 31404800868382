<template>
  <transition name="modalFade">
    <div
      class="modal"
      :class="currentClass"
      @mousedown="closeModalVisible"
      v-if="isModalVisible ? isModalVisible : isCurrentModalVisible"
    >
      <div class="modalBox" @mousedown.stop>
        <slot> </slot>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ModalApp",
  data() {
    return {
      isCurrentModalVisible: false,
    };
  },
  props: ["isModalVisible", "currentClass"],
  methods: {
    closeModalVisible() {
      this.$emit("closeModal", false);
    },
  },
};
</script>
