<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M12.9231 1H1.07692C0.791305 1 0.517386 1.11346 0.315423 1.31542C0.113461 1.51739 0 1.79131 0 2.07692V11.7692C0 12.0548 0.113461 12.3288 0.315423 12.5307C0.517386 12.7327 0.791305 12.8462 1.07692 12.8462H12.9231C13.2087 12.8462 13.4826 12.7327 13.6846 12.5307C13.8865 12.3288 14 12.0548 14 11.7692V2.07692C14 1.79131 13.8865 1.51739 13.6846 1.31542C13.4826 1.11346 13.2087 1 12.9231 1ZM12.9231 2.07692V8.99279L11.1684 7.23875C11.0684 7.13872 10.9496 7.05937 10.819 7.00524C10.6883 6.9511 10.5482 6.92324 10.4068 6.92324C10.2653 6.92324 10.1253 6.9511 9.9946 7.00524C9.86393 7.05937 9.7452 7.13872 9.64519 7.23875L8.29904 8.5849L5.3375 5.62337C5.13556 5.42156 4.86175 5.30819 4.57625 5.30819C4.29075 5.30819 4.01694 5.42156 3.815 5.62337L1.07692 8.36144V2.07692H12.9231ZM1.07692 9.88462L4.57692 6.38462L9.96154 11.7692H1.07692V9.88462ZM12.9231 11.7692H11.4847L9.06164 9.34615L10.4078 8L12.9231 10.516V11.7692ZM8.07692 5.03846C8.07692 4.87872 8.12429 4.72256 8.21304 4.58973C8.3018 4.45691 8.42794 4.35338 8.57553 4.29225C8.72311 4.23112 8.88551 4.21512 9.04219 4.24629C9.19887 4.27745 9.34278 4.35438 9.45574 4.46734C9.5687 4.58029 9.64562 4.72421 9.67679 4.88089C9.70795 5.03757 9.69196 5.19997 9.63083 5.34755C9.56969 5.49514 9.46617 5.62128 9.33335 5.71003C9.20052 5.79878 9.04436 5.84615 8.88462 5.84615C8.6704 5.84615 8.46496 5.76106 8.31349 5.60959C8.16202 5.45812 8.07692 5.25268 8.07692 5.03846Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: 'ImageIcon',
};
</script>
