<template>
  <div id="app">
    <div v-if="isFirstLoad" :class="{ land: isLanding }" class="app__loader">
      <img src="@/assets/img/mobileHeaderLogo.png" alt="logo" />
    </div>
    <template v-else-if="$route.name === 'ExternalLanding'">
      <ExternalLanding />
    </template>
    <v-app v-else>
      <component :is="layout">
        <router-view />
      </component>

      <v-alert
        class="app-alert"
        :class="{ show: isAlertVisible }"
        border="left"
        dense
        elevation="5"
        :type="alertType"
        >{{ alertText }}</v-alert
      >
      <CopyPopUpChip />

      <div id="stepNote"></div>
      <div id="columnTourEl"></div>
    </v-app>
  </div>
</template>

<script>
import AuthLayout from './layouts/AuthLayout.vue';
import AppLayout from './layouts/AppLayout.vue';
import ModalApp from './components/ModalApp.vue';
import { mapGetters } from 'vuex';
import CopyPopUpChip from './components/CopyPopUpChip.vue';
import ExternalLanding from './modules/externalAds/views/ExternalLanding.vue';
import LRates from './pages/landing/LRates.vue';

export default {
  name: 'App',
  components: {
    AuthLayout,
    AppLayout,
    ModalApp,
    CopyPopUpChip,
    ExternalLanding,
    LRates,
  },
  data() {
    return {
      url: process.env.VUE_APP_URL,
      isLanding: false,
      isFirstLoad: false,
    };
  },
  methods: {
    iframeEvent(event) {
      if (event.origin !== 'http://localhost:8082' && event.origin !== 'https://mamod.ai/') return;
      this.isLanding = true;
    },
  },
  computed: {
    ...mapGetters(['isAlertVisible', 'alertText', 'alertType']),
    layout() {
      const layoutName =
        this.$route.meta.layout === 'auth'
          ? 'auth'
          : this.$route.meta.layout === 'land'
          ? 'land'
          : 'app';
      return `${layoutName}-layout`;
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },
  created() {
    this.isFirstLoad = true;

    setTimeout(() => {
      this.isFirstLoad = false;
    }, 1000);
  },
  mounted() {
    if (window != window.top || document != top.document || self.location != top.location) {
      this.isLanding = true;
    }
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';
.v-application .rates-link {
  color: #3586ff !important;
}

.v-application--wrap {
  background: #f3f5f8;
}

.app {
  &__loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    &.land {
      background: #1b1921;
    }
    img {
      animation: pulse 5s infinite;
    }
  }
}
</style>
