<template>
  <div class="create">
    <div class="wrapper create__wrapp">
      <h3 :class="{ done: isFirstLoadDone }" class="create__title">Создайте Ваш кабинет</h3>
      <transition name="fade">
        <button v-if="isFirstLoadDone" @click="deleteCabinet" class="button create__delete">
          <CSpinner v-if="isDeleting" />
          <BasketIcon v-else />
        </button>
      </transition>
    </div>
    <transition-group class="create__wrapper" name="fade" tag="div">
      <div key="content" v-if="isFirstLoadDone" class="create__content">
        <component
          :is="stepsList[step].component"
          :id="cabinetId"
          @removeError="removeError"
          @createDone="$emit('createDone')"
          :phoneNumber="phoneNumber"
          hideButtons
          v-model.sync="stepsList[step].value"
          :error="stepsList[step].error"
        />
      </div>
      <div v-if="isFirstLoadDone" key="footer" class="create__bottom">
        <WbCompleateBar class="create__way" :step="step" :total-steps="stepsList.length - 1" />
        <div v-if="!stepsList[step].hideButtons" class="create__navigation">
          <buttonLoader
            v-for="(item, index) of controllButtons"
            class="button create__button"
            :key="index"
            @click="item.action"
            :disabled="item.disabled"
            :is-loading="isLoadingButtons"
          >
            {{ item.name }}
          </buttonLoader>
        </div>
      </div>
    </transition-group>
  </div>
</template>
<script>
import { mapMutations, mapActions } from 'vuex';
import WbCompleateBar from './WbCompleateBar.vue';
import WbCabinetesMainToken from './steps/WbCabinetesMainToken.vue';
import WbCabinetesName from './steps/WbCabinetesName.vue';
import WbCabinetesFinall from './steps/WbCabinetesFinall.vue';
import { BasketIcon } from '@/components/UI/icons';

import buttonLoader from '@/components/buttonLoader.vue';
import CSpinner from '@/components/UI/CSpinner.vue';

export default {
  name: 'WbCreateAccount',
  components: {
    WbCompleateBar,
    WbCabinetesMainToken,
    WbCabinetesName,
    WbCabinetesFinall,
    BasketIcon,
    buttonLoader,
    CSpinner,
  },
  props: {
    cabinet: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      step: 0,
      isLoadingButtons: false,
      cabinetId: this.cabinet.id,
      isFirstLoadDone: false,
      phoneNumber: Number,
      choosedOption: null,
      isDeleting: false,
    };
  },
  methods: {
    ...mapActions(['patchCabinet']),
    ...mapMutations(['editCurrentWbToken']),
    deleteCabinet() {
      this.$emit('deleteCabinet', this.cabinetId);
      this.isDeleting = true;
      setTimeout(() => {
        this.isDeleting = false;
      }, 3000);
    },
    removeError(obj) {
      obj.error = null;
    },
    nextStep() {
      this.stepsList[this.step]?.action();
    },
    prevStep() {
      if (this.step === 3) {
        this.choosedOption = null;
      }
      this.step--;
    },
  },
  computed: {
    stepsList() {
      return [
        {
          component: 'WbCabinetesName',
          value: this.cabinet.name,
          error: '',
          action: async () => {
            this.isLoadingButtons = true;
            const currentStep = this.stepsList[this.step];
            if (!currentStep.value) {
              this.$store.commit('showAlert', 'Дайте имя Вашему кабинету');
              this.$set(currentStep, 'error', 'Введите название кабинета');
              this.isLoadingButtons = false;
              return;
            }
            await this.patchCabinet({
              id: this.cabinetId,
              name: this.stepsList[this.step].value,
            });
            this.isLoadingButtons = false;

            this.step++;
          },
        },
        {
          component: 'WbCabinetesMainToken',
          value: this.cabinet.apiTokens.open_api_standart.title,
          action: async () => {
            this.isLoadingButtons = true;
            if (!this.stepsList[this.step].value) {
              this.$store.commit('showAlert', 'Введите API-токен по инструкции');
              this.isLoadingButtons = false;
              return;
            }
            try {
              const response = await this.patchCabinet({
                id: this.cabinetId,
                open_api_standart: this.stepsList[this.step].value,
              });
              this.step++;
            } catch (error) {
              this.$store.commit(
                'showAlert',
                Object.values(Object.values(error.response.data)[0])[0],
              );
              console.log(error);
            } finally {
              this.isLoadingButtons = false;
            }
          },
        },
        {
          component: 'WbCabinetesFinall',
          value: this.cabinet.name,
          hideButtons: true,
        },
      ];
    },

    controllButtons() {
      return [
        {
          name: 'Назад',
          disabled: this.step === 0,
          action: () => {
            this.prevStep();
          },
        },
        {
          name: 'Далее',
          disabled: this.step === this.totalSteps?.length,
          action: () => {
            this.nextStep();
          },
        },
      ];
    },
  },
  mounted() {
    setTimeout(() => {
      this.isFirstLoadDone = true;
    }, 1900);
  },
};
</script>
<style lang="scss" scoped>
.create {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 15px;
  &__wrapp {
    justify-content: flex-end;
  }
  &__delete {
    background: rgba(210, 38, 38, 0.1);
    padding: 5px;
  }
  &__button {
    color: #fff;
    background: #497ada;
  }
  &__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
    font-weight: 600;
    letter-spacing: -0.4px;
    color: #344767;
    transition: all 1.3s;
    text-align: left;
    &.done {
      font-size: 18px;
      left: 15px;
      top: 15px;
      transform: translate(0, 0);
    }
    &.static {
      position: static;
    }
  }
  &__content {
    margin-top: 50px;
  }
  &__bottom {
    display: flex;
    justify-content: center;
    margin-top: auto;
  }
  &__way {
    background: #fff;
    width: 40%;
    height: 20px;
  }
  &__navigation {
    display: flex;
    gap: 10px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 30px;
  }
  .input {
    background: #fff;
  }
  .done {
    opacity: 1;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 3.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
./steps/manual/WbCabinetesMain.vue
