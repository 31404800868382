import axios from 'axios';
import API_ENV from '@/config/api.config';

let { apiWarehouseCategories, apiLoadCategoriesFromWb } = API_ENV;

export default {
  state: {
    tableDataInfo: null,
    currentLimit: null,
    currentPage: null,
    isLoading: false,
    categoryInfo: null,
  },
  getters: {
    getStockProductCategories: ({ tableDataInfo }) => tableDataInfo?.items,
    getStockProductCategoryById:
      ({ tableDataInfo }) =>
      id =>
        tableDataInfo?.items.find(item => item.id == id),
    getStockProductCategoriesTotalPages: ({ tableDataInfo, currentLimit }) =>
      Math.ceil(tableDataInfo?.count / currentLimit),
    getStockProductCategoryIsLoading: ({ isLoading }) => isLoading,
    getCategoryInfo: ({ categoryInfo }) => categoryInfo,
  },
  mutations: {
    changeProductCategoriesTableInfo(state, data) {
      state.tableDataInfo = data;
    },
    setProductCategoriesTableExample(state) {
      if (!state.tableDataInfo?.count)
        state.tableDataInfo = {
          isExample: true,
          count: 1,
          items: [
            {
              id: 194,
              title: 'Новая категория складского товара №1',
              prod_cat_individual_num: 1,
              product_count: 0,
            },
          ],
        };
    },
    removeProductCategoriesTableExample(state) {
      if (state.tableDataInfo?.isExample) state.tableDataInfo = { count: 1, items: [] };
    },
    changeStockProductCategoriesLimit(state, limit) {
      state.currentLimit = limit;
    },
    changeStockProductCategoriesPage(state, page) {
      state.currentPage = page;
    },
    deleteProductCategoriesById(state, id) {
      if (state.tableDataInfo)
        state.tableDataInfo.items = state.tableDataInfo.items.filter(item => item.id != id);
    },
    editProductCategoriesById(state, { id, category }) {
      const index = state.tableDataInfo?.items.findIndex(item => item.id == id);

      if (index != null) state.tableDataInfo.items.splice(index, 1, category);
    },
    addStockCategory(state, category) {
      state.tableDataInfo.items.unshift(category);
    },
    changeStockProductCategoriesIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    changeCategoryInfo(state, info) {
      state.categoryInfo = info;
    },
    setCategoryInfoExampleData(state) {
      const exampleData = {
        id: 103,
        title: 'Маски многоразовые',
        product_count: 12,
        prod_cat_individual_num: 2,
        products: {
          links: {
            next: 'https://app.mamod.io/api/v1/warehouse/product_category/103?limit=10&page=2',
            previous: null,
          },
          count: 10,
          items: [
            {
              id: 341,
              title: 'Защитный экран/Маска защитная многоразовая для лица',
              internal_article: 'ЗЭ-08/Фиолетовый',
              label: '',
              size: '48',
              category: 103,
              photo: null,
              is_selected: true,
            },
            {
              id: 351,
              title: 'Защитный экран/Маска защитная многоразовая для лица',
              internal_article: 'ЗЭ-08/Белый',
              label: '',
              size: '48',
              category: 103,
              photo: null,
              is_selected: true,
            },
            {
              id: 339,
              title: 'Маска защитная многоразовая для лица из неопрена',
              internal_article: 'МСК-01/серый',
              label: '',
              size: '48',
              category: 103,
              photo: null,
              is_selected: true,
            },
          ],
          currentPage: 1,
          lastPage: 197,
        },
      };

      state.categoryInfo = exampleData;
    },
  },
  actions: {
    async getStockProductCategoriesRequest({ commit }, { limit, page }) {
      commit('changeStockProductCategoriesIsLoading', true);
      try {
        const result = await axios.get(apiWarehouseCategories, {
          params: { limit, page },
        });

        commit('changeProductCategoriesTableInfo', result.data);
        commit('changeStockProductCategoriesLimit', limit);
        commit('changeStockProductCategoriesPage', page);

        return result.data;
      } catch (error) {
        commit('showAlert');
        return error;
      } finally {
        commit('changeStockProductCategoriesIsLoading', false);
      }
    },
    async createProductCategory({ state, commit, dispatch }, title) {
      try {
        const result = await axios.post(apiWarehouseCategories, { title });

        // commit('addStockCategory', result.data)
        const reqData = {
          limit: state.currentLimit,
          page: state.currentPage,
        };
        dispatch('getStockProductCategoriesRequest', reqData);

        return result.data;
      } catch (error) {
        console.error(new Error(error));
        console.log('Error:', error);
        commit('showAlert', error.response.data.title[0]);
        throw error;
      }
    },
    async deleteProductCategory({ commit }, id) {
      try {
        const result = await axios.delete(`${apiWarehouseCategories}${id}`);

        commit('deleteProductCategoriesById', id);

        return result.data;
      } catch (error) {
        commit('showAlert');
        return error;
      }
    },
    async editProductCategory({ commit }, { id, title }) {
      try {
        const result = await axios.patch(`${apiWarehouseCategories}${id}`, {
          title,
        });

        commit('editProductCategoriesById', { id, category: result.data });

        return result.data;
      } catch (error) {
        commit('showAlert');
        return error;
      }
    },
    async setCategoriesFromWb({ commit }) {
      try {
        const result = await axios.post(apiLoadCategoriesFromWb);

        return result.data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async getCategoryInfoRequest({ commit }, { id, limit = 10, page = 1 }) {
      if (id == 'example') return commit('setCategoryInfoExampleData');

      try {
        const result = await axios.get(`${apiWarehouseCategories}${id}`, {
          params: { limit, page },
        });

        commit('changeCategoryInfo', result.data);

        return result.data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
  },
};
