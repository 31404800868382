<template>
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="42" height="42" rx="6" fill="#497ADA" fill-opacity="0.1"/>
    <path d="M21.2917 15.167H22.8667C23.8468 15.167 24.3368 15.167 24.7111 15.3577C25.0404 15.5255 25.3081 15.7932 25.4759 16.1225C25.6667 16.4969 25.6667 16.9869 25.6667 17.967V24.0337C25.6667 25.0138 25.6667 25.5038 25.4759 25.8781C25.3081 26.2074 25.0404 26.4751 24.7111 26.6429C24.3368 26.8337 23.8468 26.8337 22.8667 26.8337H19.1333C18.1532 26.8337 17.6632 26.8337 17.2888 26.6429C16.9596 26.4751 16.6919 26.2074 16.5241 25.8781C16.3333 25.5038 16.3333 25.0138 16.3333 24.0337V23.6253M23.3333 21.5837H20.7083M23.3333 19.2503H21.2917M23.3333 23.917H18.6667M17.5 19.8337V16.6253C17.5 16.1421 17.8918 15.7503 18.375 15.7503C18.8582 15.7503 19.25 16.1421 19.25 16.6253V19.8337C19.25 20.8002 18.4665 21.5837 17.5 21.5837C16.5335 21.5837 15.75 20.8002 15.75 19.8337V17.5003" stroke="#497ADA" stroke-width="0.98" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'UploadIcon',
}
</script>

<style lang="scss" scoped></style>
