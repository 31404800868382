<template>
	<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M10.2794 2.95139C10.8812 2.34954 11.857 2.34954 12.4589 2.95139L13.5486 4.04114C14.1505 4.64299 14.1505 5.61879 13.5486 6.22064L12.5824 7.18685C12.5065 7.14929 12.4268 7.10868 12.344 7.06511C11.7871 6.7719 11.1261 6.36038 10.6329 5.86713C10.1396 5.37388 9.72812 4.71297 9.43491 4.15602C9.39134 4.07326 9.35072 3.99345 9.31316 3.91759L10.2794 2.95139ZM9.92579 6.57424C10.5095 7.15791 11.2518 7.61729 11.8396 7.92961L8.04003 11.7292C7.80424 11.965 7.49833 12.118 7.16823 12.1651L4.87975 12.492C4.3712 12.5647 3.9353 12.1288 4.00795 11.6202L4.33488 9.33177C4.38203 9.00167 4.53499 8.69576 4.77078 8.45997L8.5704 4.66035C8.88271 5.24823 9.3421 5.99055 9.92579 6.57424ZM2.5 13.5C2.22386 13.5 2 13.7239 2 14C2 14.2761 2.22386 14.5 2.5 14.5H13.5C13.7761 14.5 14 14.2761 14 14C14 13.7239 13.7761 13.5 13.5 13.5H2.5Z" fill="#3A416F"/>
	</svg>
</template>

<script>
export default {
	name: 'EditIcon',
}
</script>

<style lang="scss" scoped></style>
