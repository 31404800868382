import axios from 'axios';
import API_ENV from '@/config/api.config';

const {
  apiExternalUrlsList,
  apiExternalAuditorium,
  apiExternalConversions,
  apiExternalDownload,
  apiGetInfoProduct,
  apiSendClick,
  apiGetUsersProducts,
} = API_ENV;

export default {
  namespaced: true,
  actions: {
    async getUsersProducts({ commit }, id) {
      try {
        const { data } = await axios.get(`${apiGetUsersProducts}?cabinet_id=${id}`);
        return data;
      } catch (error) {
        console.log('error', new Error(error));
      }
    },
    async sendClick({ commit }, obj) {
      const { id, ...rest } = obj;
      try {
        let queryString = '';
        if (Object.keys(rest).length > 0) {
          queryString =
            '?' +
            Object.entries(rest)
              .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
              .join('&');
        }
        const { data } = await axios.get(`${apiSendClick}${id}${queryString}`);
        return data;
      } catch (error) {
        console.log('error', new Error(error));
      }
    },
    async getInfo({ commit }, id) {
      try {
        const { data } = await axios.get(`${apiGetInfoProduct}${id}`);
        return data;
      } catch (error) {
        console.log('error', new Error(error));
      }
    },
    async getReport({ commit }, obj) {
      const queryParams = Object.entries(obj)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');
      try {
        const { data } = await axios(`${apiExternalDownload}?${queryParams}`, {
          responseType: 'arraybuffer',
        });
        const file = new Uint8Array(data);
        const blob = new Blob([file], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.xlsx');
        document.body.appendChild(link);
        link.click();
        return data;
      } catch (error) {
        commit('showAlert', error.response.data);
        console.log('error', new Error(error));
        throw error;
      } finally {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      }
    },
    async getAuditorium({ commit }, obj) {
      console.log('getAuditorium', obj);
      const queryParams = Object.entries(obj)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');
      try {
        const { data } = await axios.get(`${apiExternalAuditorium}?${queryParams}`);
        return data;
      } catch (error) {
        console.error(new Error(error));
        commit('showAlert');
      }
    },
    async getConversions({ commit }, obj) {
      console.log('getConversions', obj);
      const queryParams = Object.entries(obj)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');
      try {
        const { data } = await axios.get(`${apiExternalConversions}?${queryParams}`);
        return data;
      } catch (error) {}
    },
    async getCurrentUrl({ commit }, obj) {
      const { id, ...rest } = obj;
      const queryParams = Object.entries(rest)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');
      try {
        const { data } = await axios.get(`${apiExternalUrlsList}${id}?${queryParams}`);
        return data;
      } catch (error) {
        console.error(new Error(error));
        commit('showAlert');
      }
    },
    async getUrlsList({ commit }, filterParams) {
      try {
        if (filterParams) {
          const queryParams = Object.entries(filterParams)
            .filter(([key, value]) => value.length)
            .flatMap(([key, value]) => {
              if (typeof value == 'string') {
                return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
              }
              return value.map(item => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`);
            })
            .join('&');
          const { data } = await axios.get(`${apiExternalUrlsList}?${queryParams}`);
          return data;
        }

        const { data } = await axios.get(apiExternalUrlsList);
        return data;
      } catch (error) {
        console.error(new Error(error));
        commit('showAlert');
      }
    },
    async createUrl({ commit }, obj) {
      try {
        const { data } = await axios.post(apiExternalUrlsList, obj);
        return data;
      } catch (error) {
        console.error(new Error(error));
        throw error.response.data;
      }
    },
    async updateUrl({ commit }, { id, ...rest }) {
      try {
        const { data } = await axios.patch(`${apiExternalUrlsList}${id}`, rest);
        return data;
      } catch (error) {
        console.error(new Error(error));
        throw error.response.data;
      }
    },
    async deleteUrl({ commit }, id) {
      try {
        const { data } = await axios.delete(`${apiExternalUrlsList}${id}`);
        return data;
      } catch (error) {
        console.error(new Error(error));
        throw error.response.data;
      }
    },
  },
};
