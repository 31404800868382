<template>
	<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M3.66406 18.3341H18.3307M3.66406 18.3341V14.6674L10.9974 7.33409M3.66406 18.3341L7.33073 18.3341L14.6641 11.0007M10.9974 7.33409L13.627 4.70449L13.6286 4.70293C13.9905 4.34096 14.1718 4.15965 14.3808 4.09174C14.5649 4.03192 14.7633 4.03192 14.9474 4.09174C15.1562 4.1596 15.3373 4.3407 15.6988 4.70217L17.2936 6.29698C17.6566 6.65999 17.8382 6.84159 17.9062 7.05089C17.966 7.23499 17.966 7.43331 17.9062 7.61742C17.8383 7.82657 17.6569 8.00788 17.2944 8.37038L17.2936 8.37116L14.6641 11.0007M10.9974 7.33409L14.6641 11.0007" stroke="#3A416F" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
</template>

<script>
export default {
	name: 'EditOutline',
}
</script>

<style lang="scss" scoped></style>
