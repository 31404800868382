const Module = () => import('./Module.vue');
const GoodsTable = () => import('./views/goods/GoodsTable.vue');
const CreateGoods = () => import('./views/goods/GoodsCreate.vue');
const StocksList = () => import('./views/stocks/StocksList.vue');
const CreateStock = () => import('./views/stocks/CreateStock.vue');
const StockPage = () => import('./views/stocks/StockPage.vue');
const Categories = () => import('./views/categories/Main.vue');
const CategoryPage = () => import('./views/categories/CategoryPage.vue');
const DocumentsGroupsMain = () => import('./views/documents/index.vue');
const DocumentsGroups = () => import('./views/documents/Groups.vue');
const DocumentsGroupsCreate = () => import('./views/documents/GroupsCreate.vue');
const DocumentsGroupsEdit = () => import('./views/documents/GroupsEdit.vue');
const DocumentsList = () => import('./views/documents/List.vue');
const DocumentCreate = () => import('./views/documents/DocumentCreate.vue');
const DocumentEdit = () => import('./views/documents/DocumentEdit.vue');
const ProcurementPlanning = () => import('./views/procurement_planning/index.vue');
const SupplyPlanning = () => import('./views/supply_planning/index.vue');
const WbStocks = () => import('./views/wb-stocks/index.vue');

const stockRoutes = {
  path: '/stock',
  name: 'stocks',
  breadName: 'Складской учет',
  meta: {
    requiresAuth: true,
  },
  component: Module,
  redirect: to => ({ path: '/stock/goods' }),
  children: [
    {
      path: '/stock/goods',
      name: 'GoodsTable',
      component: GoodsTable,
      breadName: 'Товары',
      meta: {
        isCloseForShowroom: true,
        isCloseForMarkert: true,
        requiresAuth: true,
        sectionKey: 'product_management',
      },
    },
    {
      path: 'goods/new-goods',
      name: 'newGoods',
      breadName: 'Новый товар',
      component: CreateGoods,
      meta: {
        isCloseForShowroom: true,
        requiresAuth: true,
      },
    },
    {
      path: '/stock/list',
      name: 'GoodsStock',
      breadName: 'Склады',
      component: StocksList,
      meta: {
        isCloseForShowroom: true,
        //
        isCloseForMarkert: true,
        requiresAuth: true,
        sectionKey: 'product_management',
      },
    },
    {
      path: '/stock/wb-stocks',
      name: 'GoodsStockWB',
      breadName: 'Склады WB',
      component: WbStocks,
      meta: {
        isCloseForShowroom: true,
        isCloseForMarkert: true,
        requiresAuth: true,
        sectionKey: 'product_management',
      },
    },
    {
      path: '/stock/list/stock-info',
      name: 'GoodsStockInfo',
      breadName: 'Информация о складе',
      component: StockPage,
      children: [
        {
          path: ':id',
        },
      ],
      meta: {
        isCloseForShowroom: true,
        //
        isCloseForMarkert: true,
        requiresAuth: true,
      },
    },
    {
      path: '/stock/list/create-stock',
      name: 'GoodsStockCreate',
      breadName: 'Добавление склада',
      component: CreateStock,
      meta: {
        isCloseForShowroom: true,
        //
        isCloseForMarkert: true,
        requiresAuth: true,
      },
      children: [
        {
          path: ':id',
          breadName: 'Редактирование склада',
        },
      ],
    },
    {
      path: 'categories',
      name: 'GoodsCategories',
      breadName: 'Категории складских товаров',
      component: Categories,
      meta: {
        isCloseForShowroom: true,
        //
        isCloseForMarkert: true,
        requiresAuth: true,
        sectionKey: 'product_management',
      },
    },
    {
      path: '/stock/categories/category-info',
      name: 'GoodsCategoryInfo',
      breadName: 'Информация о категории',
      component: CategoryPage,
      children: [
        {
          path: ':id',
        },
      ],
      meta: {
        isCloseForShowroom: true,
        //
        isCloseForMarkert: true,
        requiresAuth: true,
      },
    },
    {
      path: '/stock/document-groups',
      name: 'GoodsDocGroups',
      breadName: 'Категории складских документов',
      component: DocumentsGroupsMain,
      redirect: to => ({ path: '/stock/document-groups/main' }),
      meta: {
        isCloseForShowroom: true,
        //
        isCloseForMarkert: true,
        requiresAuth: true,
      },
      children: [
        {
          path: '/stock/document-groups/main',
          name: 'GoodsDocGroupsMain',
          breadName: null,
          component: DocumentsGroups,
          meta: {
            isCloseForShowroom: true,
            //
            isCloseForMarkert: true,
            requiresAuth: true,
            sectionKey: 'product_management',
          },
        },
        {
          path: '/stock/document-groups/create',
          name: 'GoodsDocGroupsCreate',
          breadName: 'Добавление категории',
          component: DocumentsGroupsCreate,
          meta: {
            isCloseForShowroom: true,
            //
            isCloseForMarkert: true,
            requiresAuth: true,
          },
        },
        {
          path: '/stock/document-groups/edit/:id',
          name: 'GoodsDocGroupsEdit',
          breadName: 'Добавление категории',
          component: DocumentsGroupsEdit,
          meta: {
            isCloseForShowroom: true,
            //
            isCloseForMarkert: true,
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/stock/documents',
      breadName: 'Складские документы',
      component: DocumentsGroupsMain,
      redirect: to => ({ path: '/stock/documents/main' }),
      meta: {
        isCloseForShowroom: true,
        requiresAuth: true,
      },
      children: [
        {
          name: 'GoodsDocs',
          path: '/stock/documents/main',
          breadName: null,
          component: DocumentsList,
          meta: {
            isCloseForShowroom: true,
            //
            isCloseForMarkert: true,
            requiresAuth: true,
            sectionKey: 'product_management',
          },
        },
        {
          name: 'GoodsDocsCreate',
          path: '/stock/documents/create',
          breadName: 'Новый складской документ',
          component: DocumentCreate,
          meta: {
            isCloseForShowroom: true,
            //
            isCloseForMarkert: true,
            requiresAuth: true,
          },
        },
        {
          path: '/stock/documents/edit',
          breadName: '',
        },
        {
          path: '/stock/documents/edit/:id',
          breadName: '',
          component: DocumentEdit,
          props: route => {
            stockRoutes.children.forEach(i => {
              if (i.path === '/stock/documents')
                i.children.forEach(j => {
                  if (j.path === '/stock/documents/edit')
                    j.breadName = `Состав ID${route.params.id}`;
                });
            });
          },
          meta: {
            isCloseForShowroom: true,
            //
            isCloseForMarkert: true,
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/stock/procurement-planning',
      name: 'ProcurementPlanning',
      breadName: 'Планирование закупок',
      component: ProcurementPlanning,
      meta: {
        isCloseForShowroom: true,
        isCloseForMarkert: true,
        requiresAuth: true,
        sectionKey: 'supply_purchase_planning',
      },
    },
    ,
    {
      path: '/stock/supply-planning',
      name: 'SupplyPlanning',
      breadName: 'Планирование поставок',
      component: SupplyPlanning,
      meta: {
        isCloseForShowroom: true,
        isCloseForMarkert: true,
        requiresAuth: true,
        sectionKey: 'supply_purchase_planning',
      },
    },
  ],
};

export default stockRoutes;
