<template>
  <button @click="$router.push('/rates')" class="information">
    {{ textValue }}
  </button>
</template>
<script>
export default {
  name: 'HTariff',
  props: {
    tariff: String,
    dateEnd: String,
  },
  computed: {
    textValue() {
      if (!this.tariff) return 'Приобрести тариф';
      return this.tariff === 'free_3_days' || this.tariff === 'free_14_days'
        ? 'У Вас действует бесплатный тариф'
        : this.tariff
        ? `Тариф ${this.shortcutTariff(this.tariff)} до ${this.dateEnd}`
        : 'Приобрести тариф';
    },
  },
  methods: {
    shortcutTariff(string) {
      return string
        .split('')
        .filter(item => item !== ' ')
        .join('')
        .substring(0, 1);
    },
  },
};
</script>
<style lang="scss" scoped>
.information {
  padding: 10px;
  background: rgba(81, 60, 211, 0.2);
  border-radius: 6px;
  box-shadow: none;
  margin-bottom: 0;
  color: #513cd3;
  font-family: Manrope;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: -0.22px;
  transition: all 0.3s;
  &:hover {
    background: rgba(81, 60, 211, 0.6);
    color: #fff;
  }
}
</style>
