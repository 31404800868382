<template>
  <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.915 8.028C19.527 7.538 19.638 7.32 19.915 6.882C19.92 6.877 23.123 2.451 23.453 0.95L23.455 0.949C23.619 0.402 23.455 0 22.662 0H20.038C19.37 0 19.062 0.345 18.897 0.731C18.897 0.731 17.561 3.929 15.671 6.002C15.061 6.601 14.779 6.793 14.446 6.793C14.282 6.793 14.027 6.601 14.027 6.054V0.949C14.027 0.293 13.84 0 13.287 0H9.161C8.742 0 8.493 0.306 8.493 0.591C8.493 1.213 9.438 1.356 9.536 3.106V6.903C9.536 7.735 9.385 7.888 9.05 7.888C8.158 7.888 5.993 4.677 4.71 1.002C4.451 0.289 4.198 0.000999928 3.525 0.000999928H0.9C0.151 0.000999928 0 0.346 0 0.732C0 1.414 0.892 4.805 4.148 9.285C6.318 12.343 9.374 14 12.154 14C13.825 14 14.029 13.632 14.029 12.999C14.029 10.077 13.878 9.801 14.715 9.801C15.103 9.801 15.771 9.993 17.331 11.468C19.114 13.217 19.407 14 20.405 14H23.029C23.777 14 24.156 13.632 23.938 12.906C23.439 11.379 20.067 8.238 19.915 8.028Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'VK',
}
</script>

<style lang="scss" scoped></style>
