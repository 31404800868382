import axios from 'axios';
import API_ENV from '@/config/api.config';

let { apiReferral } = API_ENV;

export default {
	actions: {
		getReferralData({ commit }) {
			return new Promise((resolve, reject) => {
				axios.get(apiReferral).then(res => {
					resolve(res);
				})
				.catch(err => {
					commit('showAlert')
					reject(err)
				})
			});
		},
	},
};
