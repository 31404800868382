const Module = () => import('./Module.vue');
const ABTests = () => import('./views');

const mainRoutes = {
  path: '/generation',
  children: [
    {
      path: '',
      name: 'generationPage',
      component: ABTests,
      breadName: 'AI генерация описания',
      meta: {
        requiresAuth: true,
      },
    },
  ],
  component: Module,
};

export default mainRoutes;
