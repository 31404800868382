const products = [
  {
    isExample: true,
    id: 102,
    photo: 'https://basket-12.wbbasket.ru/vol1738/part173891/173891989/images/big/1.jpg',
    status_of_generating: 'GENERATING',
    sku: 173891989,
    marketplace: 'Wildberries',
    user: 24617,
    updated_at: '2024-05-06',
    loading_status: {
      is_title_loaded: true,
      is_description_loaded: true,
      is_pricing_loaded: true,
      is_rating_loaded: true,
      is_stock_loaded: true,
      is_params_loaded: true,
      is_sale_loaded: true,
      is_sale_max_loaded: true,
      is_video_loaded: true,
      is_photo_count_loaded: true,
      is_price_elasticity_loaded: true,
      is_charts_loaded: true,
      is_revenue_loaded: true,
      is_review_analysis_bad_loaded: true,
    },
  },
  {
    isExample: true,
    id: 77,
    photo: 'https://basket-05.wb.ru/vol725/part72596/72596098/images/big/1.jpg',
    status_of_generating: 'DONE',
    sku: 72596098,
    marketplace: 'Wildberries',
    user: 24617,
    updated_at: '2024-04-11',
    loading_status: {
      is_title_loaded: true,
      is_description_loaded: true,
      is_pricing_loaded: true,
      is_rating_loaded: true,
      is_stock_loaded: true,
      is_params_loaded: true,
      is_sale_loaded: true,
      is_sale_max_loaded: true,
      is_video_loaded: true,
      is_photo_count_loaded: true,
      is_price_elasticity_loaded: false,
      is_charts_loaded: false,
      is_revenue_loaded: true,
      is_review_analysis_bad_loaded: true,
    },
  },
  {
    isExample: true,
    id: 34,
    photo: 'https://basket-03.wb.ru/vol322/part32292/32292872/images/big/1.jpg',
    status_of_generating: 'DONE',
    sku: 32292872,
    marketplace: 'Wildberries',
    user: 24617,
    updated_at: '2024-04-11',
    loading_status: {
      is_title_loaded: true,
      is_description_loaded: true,
      is_pricing_loaded: true,
      is_rating_loaded: true,
      is_stock_loaded: true,
      is_params_loaded: true,
      is_sale_loaded: true,
      is_sale_max_loaded: true,
      is_video_loaded: true,
      is_photo_count_loaded: true,
      is_price_elasticity_loaded: true,
      is_charts_loaded: true,
      is_revenue_loaded: true,
      is_review_analysis_bad_loaded: true,
    },
  },
];

const ownGoods = [
  {
    isExample: true,
    cabinet_id: 1567,
    cabinet_name: 'Новый',
    nm_id: 17000753,
    name: 'Гирлянда новогодняя штора светодиодная',
    price: 1987,
    discount: 74,
    sale_price: 516,
    created_at: '2024-04-22',
    photos: [
      'https://basket-02.wbbasket.ru/vol170/part17000/17000753/images/big/1.jpg',
      'https://basket-02.wbbasket.ru/vol170/part17000/17000753/images/big/2.jpg',
      'https://basket-02.wbbasket.ru/vol170/part17000/17000753/images/big/3.jpg',
      'https://basket-02.wbbasket.ru/vol170/part17000/17000753/images/big/4.jpg',
      'https://basket-02.wbbasket.ru/vol170/part17000/17000753/images/big/5.jpg',
      'https://basket-02.wbbasket.ru/vol170/part17000/17000753/images/big/6.jpg',
      'https://basket-02.wbbasket.ru/vol170/part17000/17000753/images/big/7.jpg',
    ],
  },
  {
    isExample: true,
    cabinet_id: 1567,
    cabinet_name: 'Новый',
    nm_id: 17000754,
    name: 'Гирлянда новогодняя штора светодиодная',
    price: 2083,
    discount: 83,
    sale_price: 354,
    created_at: '2024-04-22',
    photos: [
      'https://basket-02.wbbasket.ru/vol170/part17000/17000754/images/big/1.jpg',
      'https://basket-02.wbbasket.ru/vol170/part17000/17000754/images/big/2.jpg',
      'https://basket-02.wbbasket.ru/vol170/part17000/17000754/images/big/3.jpg',
      'https://basket-02.wbbasket.ru/vol170/part17000/17000754/images/big/4.jpg',
      'https://basket-02.wbbasket.ru/vol170/part17000/17000754/images/big/5.jpg',
      'https://basket-02.wbbasket.ru/vol170/part17000/17000754/images/big/6.jpg',
      'https://basket-02.wbbasket.ru/vol170/part17000/17000754/images/big/7.jpg',
      'https://basket-02.wbbasket.ru/vol170/part17000/17000754/images/big/8.jpg',
    ],
  },
  {
    isExample: true,
    cabinet_id: 1567,
    cabinet_name: 'Новый',
    nm_id: 17000755,
    name: 'Гирлянда новогодняя штора светодиодная',
    price: 59,
    discount: 83,
    sale_price: 10,
    created_at: '2024-04-22',
    photos: [
      'https://basket-02.wbbasket.ru/vol170/part17000/17000755/images/big/1.jpg',
      'https://basket-02.wbbasket.ru/vol170/part17000/17000755/images/big/2.jpg',
      'https://basket-02.wbbasket.ru/vol170/part17000/17000755/images/big/3.jpg',
      'https://basket-02.wbbasket.ru/vol170/part17000/17000755/images/big/4.jpg',
      'https://basket-02.wbbasket.ru/vol170/part17000/17000755/images/big/5.jpg',
      'https://basket-02.wbbasket.ru/vol170/part17000/17000755/images/big/6.jpg',
      'https://basket-02.wbbasket.ru/vol170/part17000/17000755/images/big/7.jpg',
      'https://basket-02.wbbasket.ru/vol170/part17000/17000755/images/big/8.jpg',
    ],
  },
  {
    isExample: true,
    cabinet_id: 1567,
    cabinet_name: 'Новый',
    nm_id: 17000756,
    name: 'Гирлянда новогодняя штора светодиодная1',
    price: 8351,
    discount: 74,
    sale_price: 2171,
    created_at: '2024-04-22',
    photos: [
      'https://basket-02.wbbasket.ru/vol170/part17000/17000756/images/big/1.jpg',
      'https://basket-02.wbbasket.ru/vol170/part17000/17000756/images/big/2.jpg',
      'https://basket-02.wbbasket.ru/vol170/part17000/17000756/images/big/3.jpg',
      'https://basket-02.wbbasket.ru/vol170/part17000/17000756/images/big/4.jpg',
      'https://basket-02.wbbasket.ru/vol170/part17000/17000756/images/big/5.jpg',
      'https://basket-02.wbbasket.ru/vol170/part17000/17000756/images/big/6.jpg',
      'https://basket-02.wbbasket.ru/vol170/part17000/17000756/images/big/7.jpg',
      'https://basket-02.wbbasket.ru/vol170/part17000/17000756/images/big/8.jpg',
    ],
  },
];

const product = {
  id: 95,
  user: 24617,
  marketplace: 'Wildberries',
  keys_phrases: [241],
  status_of_generating: 'DONE',
  capital: 0,
  goods_delivery_qty: 0,
  sku: 162917738,
  current_sale_max: 52900,
  current_rating: 4.900000095367432,
  current_revenue: 25392000,
  predict_rating: 4.3,
  predict_sale_max: 4498,
  predict_sale: 0,
  predict_video: false,
  predict_photo_count: 5,
  current_pricing: { current_price: 1200, current_discount: 60, current_discount_price: 480 },
  predict_pricing: {
    is_blocked: true,
    predict_price: 317.97752808988764,
    predict_discount: 11,
    predict_discount_price: 283,
  },
  predict_price_elasticity: [
    { price: 6154, sales: 320 },
    { price: 5086, sales: 352 },
    { price: 4203, sales: 387 },
  ],
  predict_stock: [
    {
      name: 'Электросталь WB',
      value: 241,
      percent: 16.93548387096774,
      is_blocked: true,
      predict_count: 84,
    },
    {
      name: 'Коледино WB',
      value: 317,
      percent: 22.177419354838708,
      is_blocked: true,
      predict_count: 110,
    },
    {
      name: 'Чехов 2 WB',
      value: 109,
      percent: 7.661290322580645,
      is_blocked: true,
      predict_count: 38,
    },
    {
      name: 'Шушары WB',
      value: 107,
      percent: 7.661290322580645,
      is_blocked: true,
      predict_count: 38,
    },
    {
      name: 'Казань WB',
      value: 105,
      percent: 7.459677419354839,
      is_blocked: true,
      predict_count: 37,
    },
  ],
  predict_charts: [
    { date: '2024-02-10', rating: 4.2, revenue: 11264, predicted_sales: 32 },
    { date: '2024-03-11', rating: 4.7, revenue: 14080, predicted_sales: 40 },
    { date: '2024-09-03', rating: 4.7, revenue: 457952, predicted_sales: 1301 },
    { date: '2025-02-27', rating: 4.7, revenue: 902176, predicted_sales: 2563 },
    { date: '2025-08-23', rating: 4.8, revenue: 1346048, predicted_sales: 3824 },
    { date: '2026-02-16', rating: 4.8, revenue: 1790272, predicted_sales: 5086 },
  ],
  predict_params: [
    { name: 'Количество предметов в упаковке', value: null, is_blocked: true },
    { name: 'Ширина упаковки', value: null, is_blocked: true },
    { name: 'Высота упаковки', value: null, is_blocked: true },
    { name: 'Длина упаковки', value: null, is_blocked: true },
    { name: 'Длина предмета', value: null, is_blocked: true },
    { name: 'Ширина предмета', value: null, is_blocked: true },
    { name: 'Декоративные элементы', value: null, is_blocked: true },
    { name: 'Высота предмета', value: null, is_blocked: true },
    { name: 'Вид гирлянды', value: null, is_blocked: true },
    { name: 'Питание', value: null, is_blocked: true },
    { name: 'Материал изделия', value: null, is_blocked: true },
    { name: 'Вес товара с упаковкой (г)', value: null, is_blocked: true },
    { name: 'Цвет провода', value: null, is_blocked: true },
    { name: 'Срок годности', value: null, is_blocked: true },
    { name: 'Цвет ламп', value: null, is_blocked: true },
    { name: 'Тип гирлянды', value: null, is_blocked: true },
  ],
  current_params: [
    { name: 'Состав', value: 'хлопок 85%; полиамид 10%; эластан 5%', is_blocked: true },
    { name: 'Цвет', value: 'белый', is_blocked: true },
    { name: 'Особенности модели', value: 'серая подошва', is_blocked: true },
    { name: 'Страна производства', value: 'Китай', is_blocked: true },
    { name: 'Комплектация', value: 'Носки 10 пар', is_blocked: true },
  ],
  predict_description: {
    value:
      '"Светлана Водопад 3х3 - это замечательная добавка к вашему новогоднему декору. Это светодиодная гирлянда-занавес на окно, которая добавляет магическое мерцание в ваши праздники. Гирлянда состоит из 3х3 метров светодиодных ламп, создающих эффект водопада или дождика. Каждый светодиод излучает яркий, теплый свет, добавляя уют и радость. Гирлянда безопасна для использования, благодаря своей низкотемпературной работе. Она универсальна и идеально подходит для окон, дверей, стен или веранд. Новогодние гирлянды дождик- это идеальное решение для создания праздничной атмосферы в вашем доме или на улице. Этот продукт легко устанавливается и управляется, добавляя волшебство в вашу праздничную обстановку. С гирляндой Водопад 3х3, ваш дом будет светиться в яркие праздничные огоньки, создавая незабываемую атмосферу зимней сказки."',
    is_blocked: true,
  },
  predict_title: {
    value:
      'Светодиодная Гирлянда Водопад 3х3 - Оригинальное Новогоднее Украшение для Окон и Интерьера',
    is_blocked: true,
  },
  predict_revenue: 1272934,
  predict_review_analysis_bad: {
    details: {
      цвет: 0.4919,
      пришел: 0.5295,
      поломан: 0.6231,
      отношение: 0.4247,
      'пришел цвет': 0.6342,
      переключатель: 0.6668,
      отвратительное: 0.6881,
      'цвет переключатель': 0.7388,
      'переключатель поломан': 0.7499,
      'поломан отвратительное': 0.7781,
      'отвратительное отношение': 0.6956,
      'пришел цвет переключатель': 0.7749,
      'цвет переключатель поломан': 0.7763,
      'поломан отвратительное отношение': 0.7806,
      'переключатель поломан отвратительное': 0.8297,
    },
    conclusion:
      '1. Поломка переключателя.\n2. Нерабочий товар при доставке (возможно, поврежден в процессе пересылки).\n3. Недовольство цветом товара (возможно, не соответствует изображению на сайте).\n4. Отвратительное обслуживание или отношение со стороны продавца.\n5. Задержка/нерегулярности в доставке товара.',
    is_blocked: true,
  },
  updated_at: '2024-03-12',
  responsePhotos: {
    photos: [
      'https://basket-11.wbbasket.ru/vol1629/part162917/162917738/images/big/1.jpg',
      'https://basket-11.wbbasket.ru/vol1629/part162917/162917738/images/big/2.jpg',
      'https://basket-11.wbbasket.ru/vol1629/part162917/162917738/images/big/3.jpg',
      'https://basket-11.wbbasket.ru/vol1629/part162917/162917738/images/big/4.jpg',
      'https://basket-11.wbbasket.ru/vol1629/part162917/162917738/images/big/5.jpg',
      'https://basket-11.wbbasket.ru/vol1629/part162917/162917738/images/big/6.jpg',
      'https://basket-11.wbbasket.ru/vol1629/part162917/162917738/images/big/7.jpg',
      'https://basket-11.wbbasket.ru/vol1629/part162917/162917738/images/big/8.jpg',
      'https://basket-11.wbbasket.ru/vol1629/part162917/162917738/images/big/9.jpg',
    ],
  },
  responseStcok: [
    'Алексин WB',
    'Алматы Атакент WB',
    'Астана WB',
    'БайсеркеWB',
    'Барнаул WB',
    'Белая Дача WB',
    'Белые Столбы WB',
    'Внуково WB',
    'Волгоградская обл.WB',
    'Вёшки WB',
    'Домодедово WB',
    'Екатеринбург WB',
    'Казань WB',
    'Калуга WB',
    'Киров WB',
    'Клин WB',
    'Коледино WB',
    'Краснодар WB',
    'Крыловская WB',
    'Кузнецк WB',
    'Минск WB',
  ],
};

export default { products, ownGoods, product };
