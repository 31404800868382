<template>
	<svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M8 15H19M1 15H4M4 15V17M4 15V13M18 9H19M1 9H14M14 9V11M14 9V7M12 3H19M1 3H8M8 3V5M8 3V1" stroke="#3A416F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
</template>

<script>
export default {
	name: 'FilterIcon'
}
</script>

<style lang="scss" scoped>

</style>