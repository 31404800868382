import axios from 'axios';
import API_ENV from '@/config/api.config';
import productCardCheck from './exampleDatas/productCardCheck';
import { productInfo } from '@/mocks';

let { apiProductCard, apiSeoFrequency } = API_ENV;

export default {
  state: {
    productInfo: null,
    dataRequestsTable: [],
    dataWordsTable: [],
  },
  getters: {},
  mutations: {
    setDataRequestsTable(state, data) {
      state.dataRequestsTable = data;
    },
    setDataWordsTable(state, data) {
      state.dataWordsTable = data;
    },
    setExampleProductInfo(state) {
      state.productInfo = productInfo;
    },
    setProductCardInfo(state, info) {
      state.productInfo = info;
    },
  },
  actions: {
    getProductCardInfo({ commit, state }, sku) {
      return new Promise((resolve, reject) => {
        axios
          .post(apiProductCard, { sku })
          .then(response => {
            const data = response.data;

            commit('setProductCardInfo', data);

            resolve('response');
          })
          .catch(err => {
            commit('showAlert');
            reject(err);
          });
      });
    },
    getProductCardFrequency({ commit }, { phrases, isTour }) {
      return new Promise((resolve, reject) => {
        if (isTour) return resolve(productCardCheck);

        axios
          .post(apiSeoFrequency, { phrases })
          .then(response => {
            const data = response.data;

            resolve(data);
          })
          .catch(err => {
            commit('showAlert');
            reject(err);
          });
      });
    },
  },
};
