<template>
  <v-otp-input :error="Boolean(error)" v-model="code"></v-otp-input>
</template>

<script>
import CSmsField from "@/components/UI/CSmsField.vue";
export default {
  components: { CSmsField },
  name: "TCode",
  data() {
    return {
      code: "",
    };
  },
  props: {
    error: String,
  },
  watch: {
    code(value) {
      this.$emit("input", value);
      if (value.length === 6) {
        console.log("value", value);
        this.$emit("autoAction", value);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
