<template>
  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5729_39753)">
      <path
        d="M6.49999 3.67969L1.8636 7.79591C1.8636 7.80172 1.86225 7.81027 1.85954 7.82192C1.85688 7.83347 1.85547 7.84187 1.85547 7.84783V12.0159C1.85547 12.1664 1.90656 12.2969 2.00871 12.4067C2.11084 12.5166 2.23177 12.5719 2.37155 12.5719H5.46778V9.23728H7.53223V12.572H10.6284C10.7682 12.572 10.8893 12.5168 10.9913 12.4067C11.0934 12.297 11.1447 12.1665 11.1447 12.0159V7.84783C11.1447 7.82472 11.1417 7.80726 11.1365 7.79591L6.49999 3.67969Z"
        fill="#3A416F" />
      <path
        d="M12.9103 6.85708L11.1445 5.27665V1.73369C11.1445 1.65269 11.1204 1.58608 11.0718 1.53395C11.0237 1.48188 10.9619 1.45584 10.8865 1.45584H9.33834C9.26304 1.45584 9.20122 1.48188 9.15278 1.53395C9.10446 1.58608 9.08031 1.65272 9.08031 1.73369V3.42699L7.11288 1.65546C6.94105 1.50493 6.73675 1.42969 6.50019 1.42969C6.26365 1.42969 6.05938 1.50493 5.88738 1.65546L0.0896012 6.85708C0.0358556 6.90331 0.00639857 6.96554 0.000919507 7.0437C-0.00453131 7.12181 0.01425 7.19003 0.0573199 7.24788L0.557242 7.89049C0.600312 7.94256 0.656684 7.9744 0.726584 7.98605C0.791119 7.99189 0.855653 7.97158 0.920187 7.92528L6.50002 2.91464L12.0799 7.92525C12.123 7.96565 12.1793 7.98581 12.2492 7.98581H12.2735C12.3433 7.97437 12.3995 7.94232 12.4428 7.89037L12.9428 7.24785C12.9858 7.18988 13.0046 7.12178 12.999 7.04358C12.9935 6.96563 12.964 6.9034 12.9103 6.85708Z"
        fill="#3A416F" />
    </g>
    <defs>
      <clipPath id="clip0_5729_39753">
        <rect width="13" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'HomeIcon'
}
</script>

<style lang="scss" scoped></style>
