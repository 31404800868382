<template>
  <CBtn>
    <slot />
    <template #append>
      <svg
        style="margin-left: 6px"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
      >
        <path
          d="M9.99935 4.66675V16.3334M4.16602 10.5001H15.8327"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </template>
  </CBtn>
</template>

<script>
import { CBtn } from '.';
import { Plus } from '../icons';

export default {
  name: 'CAddBtn',
  components: { CBtn, Plus },
};
</script>

<style lang="scss" scoped></style>
