import axios from 'axios';
import queryString from 'query-string';

import API_ENV from '@/config/api.config';

let {
  apiPlanningDeliveryAvailability,
  apiPlanningDeliveryDistribution,
  apiPlanningDeliverySummary,
  apiPlanningDeliveryQuantity,
  apiPlanningDeliverySummaryByWarehouse,
  apiPlanningDeliveryTurnoverDynamics,
  apiPlanningWbStocks,
  apiPlanningTotalCost,
} = API_ENV;

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getPlanningDeliveryAvailability({ commit }, hideOos) {
      try {
        const result = await axios.get(
          `${apiPlanningDeliveryAvailability}${!hideOos ? '?out_of_stock_required=false' : ''}`,
        );

        return result.data;

        // return [
        //   {
        //     "wb_product": {
        //         "nm_id": 32269216,
        //         "name": "Бюстгальтер женский без косточек белый"
        //     },
        //     "warehouse": "Невинномысск",
        //     "amount": 0,
        //     "days": 0,
        //     "recommend": 0,
        //     "less_than_14": false,
        //     "less_than_30": false,
        //     "more_than_30": false,
        //     "out_of_stock": true
        //   },
        // ];
      } catch (error) {
        console.error(new Error(error));
      }
    },
    async getPlanningDeliveryDistribution({ commit }) {
      try {
        const result = await axios.get(apiPlanningDeliveryDistribution);

        return result.data;

        // return [{
        //   "stock_name": "string",
        //   "stock_quantity": 10,
        //   "share": 10
        // }]
      } catch (error) {
        console.error(new Error(error));
      }
    },
    async getPlanningDeliverySummary({ commit }, params) {
      try {
        const result = await axios.get(
          `${apiPlanningDeliverySummary}${
            params?.product_id ? 'by_product/' : 'by_warehouse/'
          }?limit=1000&page=0&${queryString.stringify(params)}`,
        );

        return result.data;
        // return {
        //   "links": {
        //     "next": "http://<host>/api/v1/warehouse/warehouse/?limit=2&page=4",
        //     "previous": "http://<host>/api/v1/warehouse/warehouse/?limit=2&page=2"
        //   },
        //   "count": 10,
        //   "items": [
        //     {
        //       "nm_id": 0,
        //       "refund_on_the_stock_s_way": 2147483647,
        //       "sale_on_the_client_s_way": 2147483647,
        //       "stock_qty": 0,
        //       "empty_stocks": [
        //         "string"
        //       ],
        //       "reserve": 0,
        //       "orders_by_day": 0
        //     }
        //   ],
        //   "currentPage": 3,
        //   "lastPage": 5
        // }
      } catch (error) {
        console.error(new Error(error));
      }
    },
    async getPlanningDeliveryQuantity({ commit }, params) {
      try {
        const result = await axios.get(`${apiPlanningDeliveryQuantity}`);

        return result.data;
        // return {
        //   less_than_14: 0,
        //   less_than_30: 0,
        //   more_than_30: 0,
        //   out_of_stock: 0
        // }
      } catch (error) {
        console.error(new Error(error));
      }
    },
    async getPlanningDeliveryTurnoverDynamics({ commit }) {
      try {
        const result = await axios.get(apiPlanningDeliveryTurnoverDynamics);

        return result.data;

        // return {
        //   avg_turnover: 1.6666666666666667,
        //   more_less_30: 2,
        //   more_then_30: 0,
        //   more_then_60: 0,
        //   more_then_120: 0,
        //   more_then_240: 0,
        // };
      } catch (error) {
        console.error(new Error(error));
      }
    },
    async getWbProductsForWbStocks({ commit }) {
      try {
        const result = await axios.get(apiPlanningWbStocks);

        return result.data?.items?.map(el => ({ ...el, name: el.product_name }));
      } catch (error) {
        console.error(new Error(error));
      }
    },
    async getWbProductsTotalCost({ commit }) {
      try {
        const result = await axios.get(apiPlanningTotalCost);

        return result.data;
      } catch (error) {
        console.error(new Error(error));
      }
    },
  },
};
