<template>
  <div class="sms">
    <input
      v-for="(digit, index) in length"
      :key="index"
      v-model="smsCode[index]"
      ref="digitInputs"
      @input="focusNext($event.target, index)"
      type="text"
      class="sms__input"
      maxlength="1"
      pattern="[0-9]"
    />
  </div>
</template>

<script>
export default {
  name: "CSmsField",
  props: {
    value: String,
  },
  data() {
    return {
      length: 6,
      smsCode: Array(6).fill(""),
    };
  },
  methods: {
    focusNext(input, index) {
      if (index < this.length - 1 && input.value !== "") {
        this.$refs.digitInputs[index + 1].focus();
      }
    },
  },
  watch: {
    smsCode(newValue) {
      if (!newValue.some((item) => item.trim() === "")) {
        console.log("input", newValue.join(""));
        this.$emit("input", newValue.join(""));
      }
    },
    value(newValue) {
      if (newValue.length === this.length) {
        this.smsCode = newValue.split("");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sms {
  display: flex;
  justify-content: center;
  &__input {
    width: 30px;
    height: 30px;
    margin: 0 5px;
    text-align: center;
    border: 1px solid #c9c9c9;
  }
}
</style>
