const InternalAnalyticsRoutes = {
  path: '/internal-analytics',
  name: 'internal-analytics',
  breadName: 'Аналитика',
  meta: {
    // isCloseForMagazine: true,
    requiresAuth: true,
  },
  component: () => import('./index').then(res => res.InternalAnalyticsMainPage),
  redirect: to => ({ path: '/internal-analytics/sales-and-orders' }),
  children: [
    {
      path: '/internal-analytics/geo-map',
      name: 'InternalAnalyticsGeoMap',
      breadName: 'География заказов',
      meta: {
        requiresAuth: true,
        isCloseForShowroom: true,
        sectionKey: 'unit_calc',
      },
      component: () => import('./index').then(res => res.InternalAnalyticsGeoMap),
    },
    {
      path: '/internal-analytics/geo-distribution',
      name: 'InternalAnalyticsGeoDistribution',
      breadName: 'География заказов',
      meta: {
        requiresAuth: true,
        isCloseForShowroom: true,
      },
      component: () => import('./index').then(res => res.InternalAnalyticsGeoDistribution),
    },
    {
      path: '/internal-analytics/unit-calc',
      name: 'InternalAnalyticsCalcUnit',
      breadName: 'География заказов',
      meta: {
        requiresAuth: true,
        sectionKey: 'unit_calc',
      },
      component: () => import('./index').then(res => res.InternalAnalyticsCalcUnit),
    },
    {
      path: '/internal-analytics/analytics',
      name: 'InternalAnalyticsAnalytics',
      breadName: 'Аналитика',
      meta: {
        requiresAuth: true,
        isCloseForMarkert: true,
        isCloseForShowroom: true,
        isCloseForL: true,
        sectionKey: 'financial_analytics',
      },
      component: () => import('./index').then(res => res.InternalAnalyticsAnalyticsMain),
    },
    {
      path: '/internal-analytics/sales-and-orders',
      name: 'InternalAnalyticsSales',
      breadName: 'Продажи и заказы',
      meta: {
        requiresAuth: true,
        isCloseForShowroom: true,
        sectionKey: 'internal_analytics',
      },
      component: () => import('./index').then(res => res.InternalAnalyticsSalesMain),
    },
    {
      path: 'sets',
      name: 'InternalAnalyticsSets',
      breadName: 'Комплекты',
      component: () => import('./index').then(res => res.InternalAnalyticsSetsMain),
    },
    {
      path: 'sets/correlation',
      name: 'InternalAnalyticsSetsCorrelation',
      breadName: 'Корреляции',
      component: () => import('./index').then(res => res.InternalAnalyticsSetsCorrelation),
    },
    {
      path: 'reporting',
      name: 'InternalAnalyticsReporting',
      meta: {
        isCloseForShowroom: true,
        isCloseForMarkert: true,
        requiresAuth: true,
        sectionKey: 'financial_reports',
        noPageTour: true,
      },
      breadName: 'Корреляции',
      component: () => import('./index').then(res => res.InternalAnalyticsReporting),
    },
  ],
};

export default InternalAnalyticsRoutes;
