import axios from 'axios';
import API_ENV from '@/config/api.config';
import { Object } from 'core-js';
import connectWebSocket from '@/utils/connectWebSocket';
import { aiProducts } from '@/mocks';

const {
  apiGetProducts,
  apiGetProductsList,
  apiGetProduct,
  apiProductPhoto,
  apiGetAIStockList,
  apiPathProduct,
  apiCreateProduct,
  apiSkynetRetrieveWebsocket,
} = API_ENV;

export default {
  state: {
    productFilters: {
      search: '',
      sort: 'gmv_grow_desc',
      period: 'week',
      limit: 8,
      page: 1,
    },
    productList: [],
    currentProduct: {},
    isLastPage: false,
  },
  getters: {
    isLastPage: ({ isLastPage }) => isLastPage,
    productList: ({ productList }) => productList,
    productFilters: ({ productFilters }) => productFilters,
    currentProduct: ({ currentProduct }) => currentProduct,
  },
  mutations: {
    UPDATE_LAST_PAGE(state, boolean) {
      state.isLastPage = boolean;
    },
    UPDATE_PRODUCTS_FILTER(state, obj) {
      state.productFilters = {
        ...state.productFilters,
        ...obj,
      };
    },
    UPDATE_PRODUCTS_LIST(state, obj) {
      state.productList.push(...obj);
    },
    EDIT_PRODUCTS_LIST(state, obj) {
      state.productList = obj;
    },
    SET_EXAMPLE_PRODUCT_LIST(state) {
      state.productList = aiProducts.ownGoods;
    },
    UPDATE_CURRENT_PRODUCT(state, obj) {
      state.currentProduct = {
        ...obj,
      };
    },
    RESTORE_CURRENT_PRODUCT(state) {
      state.currentProduct = {};
    },
    REMOVE_STOCK_ITEM(state, index) {
      state.currentProduct.predict_stock.splice(index, 1);
    },
    ADD_STOCK_ITEM(state) {
      state.currentProduct.predict_stock.push({
        name: '',
        value: 0,
        is_blocked: false,
      });
    },
    EDIT_PRODUCT_KEY(state, obj) {
      const { key, value } = obj;
      //В объекье ищется нужный ключ и подставляется ПОЛНОЕ value
      if (Object.keys(value).length) {
        Object.assign(state.currentProduct[key], value);
        return;
      } else {
        state.currentProduct[key] = value;
      }
    },
  },
  actions: {
    async createAIProduct({ commit }, obj) {
      const { id, ...rest } = obj;
      rest.marketplace = 'Wildberries';
      try {
        const { data } = await axios.post(`${apiCreateProduct}${id}/`, rest);
        return data;
      } catch (error) {
        console.error(new Error(error));
        commit('showAlert', error.response.data.errors);
        throw error;
      }
    },
    async getAIProductsTable({ commit }) {
      try {
        const { data } = await axios.get(apiGetProductsList);
        return data;
      } catch (error) {
        console.error(new Error(error));
      }
    },
    async getProduct({ commit, dispatch }, data) {
      try {
        // const { data } = await axios.get(`${apiGetProduct}${id}`);
        const editedStocks = {
          ...data,
          predict_stock: data.predict_stock.filter(item => item.value > 10),
        };
        const responsePhotos = await dispatch('getProductPhotos', data.sku);
        const responseStcok = await dispatch('getAIStockList');

        const fullResponse = {
          ...editedStocks,
          responsePhotos,
          responseStcok,
        };
        commit('UPDATE_CURRENT_PRODUCT', fullResponse);
      } catch (error) {
        console.error(new Error(error));
      }
    },
    async updateKeyProduct({ commit }, obj) {
      const { id, key } = obj;
      try {
        const { data } = await axios.get(`${apiGetProduct}${id}`);
        const currentKey = data[key];
        commit('EDIT_PRODUCT_KEY', { key, value: currentKey });
      } catch (error) {
        console.error(new Error(error));
      }
    },
    async getAIStockList({ commit }) {
      try {
        const { data } = await axios.get(apiGetAIStockList);
        return data;
      } catch (error) {
        console.error(new Error(error));
      }
    },
    async getProductList({ state, commit, getters }) {
      try {
        if (state.productFilters.search) {
          commit('UPDATE_PRODUCTS_FILTER', { page: 1, limit: 16 });
        }
        const paramsList = Object.entries(getters.productFilters)
          .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
          .join('&');
        const { data } = await axios.get(`${apiGetProducts}?${paramsList}`);
        if (state.productFilters.search) {
          commit('EDIT_PRODUCTS_LIST', data.items);
        } else {
          commit('UPDATE_PRODUCTS_LIST', data.items);
        }
        commit('UPDATE_LAST_PAGE', data.currentPage >= data.lastPage);
        return true;
      } catch (error) {
        console.error(new Error(error));
      }
    },

    async getProductPhotos({ commit }, id) {
      try {
        const response = await axios.get(`${apiProductPhoto}${id}/list/`);
        return response.data;
      } catch (error) {
        console.error(new Error(error));
      }
    },

    // PATCH
    async patchProductStock({ commit }, obj) {
      const { id, predict_stock } = obj;
      try {
        await axios.patch(`${apiPathProduct}${id}/`, {
          predict_stock,
        });
      } catch (error) {
        console.error(new Error(error));
      }
    },
    async patchProduct({ commit, state }, expectedKey) {
      const id = state.currentProduct.id;
      try {
        await axios.patch(`${apiPathProduct}${id}/`, {
          [expectedKey]: state.currentProduct[expectedKey],
        });
      } catch (error) {
        console.error(new Error(error));
      }
    },

    // WebSocketx
    connectAITasksWebSocket({ commit, dispatch }, id) {
      if (id == 'example') {
        commit('UPDATE_CURRENT_PRODUCT', aiProducts.product);

        return;
      }

      const ws = connectWebSocket(apiSkynetRetrieveWebsocket + id + '/');

      ws.addEventListener('message', message => {
        const data = JSON.parse(message.data);
        dispatch('getProduct', data);
      });

      return ws;
    },
  },
};
