const sales = {
  links: {
    next: 'https://app.mamod.io/api/v1/geography_sales_orders/sales/list/?limit=10&page=2',
    previous: null,
  },
  count: 2,
  items: [
    {
      gNumber: '8013886458142007453',
      date: '2024-02-09',
      nmId: 123445687,
      barcode: '2038457533992',
      warehouseName: 'Коледино',
      brand_title: 'tovar1001',
      object: 'Периферия',
      name: 'Карта памяти micro sd 64 Gb',
      photo: null,
      oblastOkrugName: 'Центральный Федеральный Округ',
      cabinet_id: 1456,
      cabinet_name: 'Тест истории остатков',
      priceWithDiscount: 464.14,
      srid: '213151231521123.0.0',
      is_selfbuy: null,
      spp_percent: '-',
      delivery_amount: 1,
      delivery_price: 43.5,
      profit: 420.64,
      purchase_price: 0,
    },
    {
      gNumber: '4478028245534918444',
      date: '2024-02-09',
      nmId: 153322214,
      barcode: '2037240941211',
      warehouseName: 'Электросталь',
      brand_title: 'tovar1001',
      object: 'Товары для отдыха',
      name: 'Решетка для гриля / мангала',
      photo: null,
      oblastOkrugName: 'Центральный Федеральный Округ',
      cabinet_id: 1456,
      cabinet_name: 'Тест истории остатков',
      priceWithDiscount: 456.31999999999994,
      srid: '8277257793324613244.0.0',
      is_selfbuy: null,
      spp_percent: '-',
      delivery_amount: 1,
      delivery_price: 71.47,
      profit: 374.85,
      purchase_price: 10,
    },
  ],
  currentPage: 1,
  lastPage: 4495,
};


export default {
  sales
}