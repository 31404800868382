<template>
  <div class="preview-thumbnail">
    <div class="preview-thumbnail__image">
      <img
        v-if="src && !errorImage"
        :src="src"
        @mouseover="mouseOver"
        @mousemove="mouseMove"
        @mouseout="mouseOut"
        @click="openModal"
        @error="errorImage = true"
      />
      <img v-else src="@/assets/img/noImageSquare.svg" alt="noImage" style="scale: 1.2" />
    </div>
    <CModal class="preview-thumbnail__modal" v-model="isModalOpen">
      <img :src="src" alt="" />
    </CModal>
  </div>
</template>

<script>
import CModal from './CModal.vue';

export default {
  name: 'CPreviewThumbnail',
  components: { CModal },
  props: {
    src: String,
    alt: String | Number,
    left: Boolean,
    top: Boolean,
    isCanBeOpen: Boolean,
  },
  data: () => ({
    pictureDiv: null,
    isModalOpen: false,
    errorImage: false,
  }),
  methods: {
    openModal() {
      if (!this.isCanBeOpen) return;
      this.isModalOpen = true;
    },
    mouseOver(e) {
      const pictureDiv = document.createElement('div');

      pictureDiv.classList.add('preview-thumbnail__preview');
      pictureDiv.innerHTML = `<img class="preview-thumbnail__preview-image" src="${e.target.currentSrc}" alt="" />`;
      pictureDiv.style.top = `${e.pageY}px`;
      pictureDiv.style.left = `${e.pageX}px`;
      this.pictureDiv = pictureDiv;

      document.getElementsByTagName('body')[0].appendChild(pictureDiv);

      setTimeout(() => pictureDiv.classList.add('show'), 5);
    },
    mouseMove(e) {
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;

      const leftPos = e.pageX + (e.pageX > windowWidth / 2 || this.left ? -320 : 20);
      const topPos = e.pageY + (e.pageY > windowHeight / 2 || this.top ? -320 : -20);

      this.pictureDiv.style.top = `${topPos}px`;
      this.pictureDiv.style.left = `${leftPos}px`;
    },
    mouseOut() {
      this.pictureDiv.classList.remove('show');

      setTimeout(() => {
        document.getElementsByTagName('body')[0].removeChild(this.pictureDiv);
      }, 60);
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-thumbnail {
  position: relative;

  &__image {
    width: 40px;
    height: 40px;
    border-radius: 6px;
    overflow: hidden;

    & img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>

<style lang="scss">
.preview-thumbnail {
  &__preview {
    position: absolute;
    width: 250px !important;
    border-radius: 8px;
    box-shadow: 0px -0.3337055444717407px 35.43374252319336px 0px rgba(75, 80, 90, 0.01),
      0px -1.1949671506881714px 67.16580200195312px 0px rgba(75, 80, 90, 0.02),
      0px -2.9669957160949707px 107.65782928466797px 0px rgba(75, 80, 90, 0.03),
      0px -9px 254px 0px rgba(75, 80, 90, 0.05);
    line-height: 0;
    opacity: 0;
    transition: all 0.15s ease;
    z-index: 999;
    overflow: hidden;
    transform: scale(0);
    pointer-events: none;

    &.show {
      opacity: 1;
      transform: scale(1);
    }

    & img {
      width: 100%;
    }
  }
  &__modal {
    .modalBox {
      max-width: max-content;
    }
  }
}
</style>
