// function getObjectByChainFields(initObject, chainArray) {
//   if (!chainArray.length) return initObject

//   return getObjectByChainFields(initObject[chainArray[0]], chainArray.slice(1))
// }

// function getModalObject(node, expression) {
//   const chainArray = expression.split('.')
//   const field = chainArray.splice(-1, 1)[0]

//   const modelObject = getObjectByChainFields(node, chainArray)

//   return [modelObject, field]
// }

const regExps = {
  number: /[0-9]+/,
  alphabet: /[a-zA-Zа-яА-ЯёЁ]+/
}

const getModifierRegExp = modifiers => regExps[Object.keys(modifiers)[0]]

export default {
  bind(input, binding, vNode) {
    const regExpValue = getModifierRegExp(binding.modifiers) || binding.arg
    // const [modelObject, field] = getModalObject(vNode.context, binding.expression)

    const regExp = new RegExp(regExpValue, 'g')

    input.addEventListener('input', () => {
      const clearValue = input.value.match(regExp)?.join('') || ''

      // modelObject[field] = clearValue
      vNode.context[binding.expression] = clearValue
      input.value = clearValue
    })
  }
}