import Vue from 'vue';
import Vuex from 'vuex';
import API_ENV from '@/config/api.config';
import axios from 'axios';

import profileSettigs from './modules/profileSettigs';
import referral from './modules/referral';
import rates from './modules/rates';
import Bill from './modules/bill';
import Auth from './modules/auth';
import seo from './modules/seo';
import positions from './modules/positions';
import actualBids from './modules/actualBids';
import productCard from './modules/productCard';
import productCardCheck from './modules/productCardCheck';
import autoanswers from './modules/autoanswers';
import wbToken from './modules/wbToken';
import keywords from './modules/keywords';
import collectionRequests from './modules/collectionRequests';
import reviewsAnalysis from './modules/reviewsAnalysis';
import adCabinet from './modules/adCabinet';
import externalAnalytics from './modules/externalAnalytics';
import alerts from './modules/alerts';
import eChapter from './modules/external/eChapter';
import PriceAnalysis from './modules/priceAnalysis';
import iSales from './modules/internal/sales';
import internalCalc from './modules/internal/calc';
import iSets from './modules/internal/sets';
import iAnalytics from './modules/internal/analytics';
import internalGeo from './modules/internal/geo';
import repricer from './modules/repricer';
import stage from './modules/stages/stage';
import converter from '@/components/modules/Converter/store/modules/converter';
import closeSection from './modules/closeSection';
import iReporting from './modules/internal/reporting';

const { apiGetUsersInfo, apiGetLimits } = API_ENV;

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  state: {
    userInfo: null,
    isAlertVisible: false,
    alertType: 'error',
    isCopyChipVisible: false,
    alertText: 'Что-то пошло не так!',
    isModalVisible: false,
    status: '',
    token: localStorage.getItem('token') || '',
    refreshToken: localStorage.getItem('refreshToken') || '',
    isGlobalTourActive: false,
    isPageTourActive: false,
    currency: '₽',
  },
  getters: {
    userCurrency: state => state.currency,
    userInfo: state => state.userInfo,
    isAlertVisible: state => state.isAlertVisible,
    alertType: state => state.alertType,
    alertText: state => state.alertText,
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    getModalVisible: state => state.isModalVisible,
    getToken: state => state.token,
    getRefreshToken: state => state.refreshToken,
    getGlobalTourActive: state => state.isGlobalTourActive,
    getPageTourActive: state => state.isPageTourActive,
    getAnyTourActive: (_, getters) => getters.getGlobalTourActive || getters.getPageTourActive,
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    CHANGE_MODAL_VISBLE(state, inf) {
      state.isModalVisible = inf;
    },
    auth_request(state) {
      state.status = 'loading';
    },
    auth_success(state, payload) {
      state.status = 'success';
      state.token = payload.token;
      // state.refreshToken = payload.refreshToken
    },
    auth_error(state) {
      state.status = 'error';
    },
    logout(state) {
      state.status = '';
      state.token = '';
    },
    showAlert(state, payload) {
      let text = null;
      let type = 'error';

      if (typeof payload === 'string') text = payload;
      if (Array.isArray(payload)) {
        text = payload[0];
        type = payload[1];
      }

      state.alertType = type || 'error';
      state.isAlertVisible = true;
      state.alertText = text || 'Что-то пошло не так!';
      setTimeout(() => {
        state.isAlertVisible = false;
        state.alertText = 'Что-то пошло не так!';
      }, 4000);
    },
    showCopyChip(state) {
      state.isCopyChipVisible = true;

      setTimeout(() => {
        state.isCopyChipVisible = false;
      }, 2000);
    },
    setGlobalTourActive(state, isActive) {
      state.isGlobalTourActive = isActive;
    },
    setPageTourActive(state, isActive) {
      state.isPageTourActive = isActive;
    },
    setCurrencySympbolByCabinets(state, cabinets) {
      for (const cabinet of cabinets) {
        const currency = cabinet.currency_name;

        if (currency == 'KZT') return (state.currency = '₸');
        else if (currency == 'BYN') return (state.currency = 'Br');
      }
    },
  },

  actions: {
    async getLimits() {
      try {
        const { data } = await axios.get(apiGetLimits);
        return data;
      } catch (error) {
        console.error(new Error(error));
        commit('showAlert', error.response.data);
      }
    },

    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit('logout');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        delete axios.defaults.headers.common['Authorization'];
        resolve();
      });
    },
    async getUserInfo({ commit }) {
      if (!localStorage.getItem('token')) return false;
      try {
        const response = await axios.get(apiGetUsersInfo);
        commit('setUserInfo', response.data);
        return response;
      } catch (error) {
        console.log('error', error);
        return error;
      }
    },
  },

  modules: {
    auth: Auth,
    rates,
    bill: Bill,
    referral,
    seo,
    profileSettigs,
    positions,
    actualBids,
    productCard,
    autoanswers,
    wbToken,
    keywords,
    collectionRequests,
    reviewsAnalysis,
    adCabinet,
    externalAnalytics,
    alerts,
    eChapter,
    PriceAnalysis,
    iSales,
    internalCalc,
    iSets,
    iAnalytics,
    converter,
    internalGeo,
    repricer,
    stage,
    closeSection,
    iReporting,
    productCardCheck,
  },
});
