<template>
  <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.0837 2.34814V5.61816C11.0837 6.06153 11.0837 6.28322 11.1699 6.45256C11.2458 6.60153 11.367 6.72264 11.5159 6.79854C11.6853 6.88482 11.907 6.88482 12.3503 6.88482H15.6203M6.33366 12.4264V14.8014M12.667 10.8431V14.8014M9.50033 8.86393V14.8014M15.8337 8.45878V14.1681C15.8337 15.4982 15.8337 16.1633 15.5748 16.6713C15.3471 17.1182 14.9838 17.4815 14.5369 17.7092C14.0288 17.9681 13.3638 17.9681 12.0337 17.9681H6.96699C5.63687 17.9681 4.97181 17.9681 4.46376 17.7092C4.01688 17.4815 3.65355 17.1182 3.42585 16.6713C3.16699 16.1633 3.16699 15.4982 3.16699 14.1681V5.93477C3.16699 4.60464 3.16699 3.93958 3.42585 3.43154C3.65355 2.98465 4.01688 2.62132 4.46376 2.39363C4.97181 2.13477 5.63687 2.13477 6.96699 2.13477H9.50965C10.0906 2.13477 10.381 2.13477 10.6543 2.20039C10.8967 2.25857 11.1283 2.35453 11.3408 2.48475C11.5805 2.63162 11.7859 2.837 12.1967 3.24776L14.7207 5.77177C15.1314 6.18253 15.3368 6.38791 15.4837 6.62759C15.6139 6.84009 15.7099 7.07175 15.768 7.31409C15.8337 7.58742 15.8337 7.87787 15.8337 8.45878Z" stroke="#3A416F" stroke-width="1.58333" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'DocumentIcon',
}
</script>

<style lang="scss" scoped></style>
