<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.94553 7.0387C7.67599 7.23183 7.63229 7.5841 7.84792 7.82551L10.8689 11.2077L7.84792 14.5899C7.63229 14.8313 7.67599 15.1836 7.94553 15.3767C8.21506 15.5698 8.60837 15.5307 8.824 15.2893L12.1573 11.5574C12.3399 11.353 12.3399 11.0625 12.1573 10.858L8.824 7.12613C8.60837 6.88471 8.21506 6.84557 7.94553 7.0387Z"
      fill="#3A416F"
      stroke="#3A416F"
      stroke-width="0.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowLeftIcon",
};
</script>

<style lang="scss" scoped></style>
