import axios from 'axios'
import API_ENV from '@/config/api.config'

let {
	apiProductCard,
	apiWbCard,
	apiRemoveWbCard,
	apiBarcodes,
	apiCardFilter,
	apiCharcsForCategory,
	apiContentCard,
	apiDirectoryColor,
	apiGenerateDescription,
	apiDirectoryKinds,
	apiCountries,
	apiFieldsSuggestions,
	apiCharcsbyobjectname,
} = API_ENV

export default {
	state: {
		expandedCell: -1,
		productCards: [],
		currentWbToken: null,
		productSubjectId: null,
		keywordsForEdit: {
			status: false,
			cardId: null,
			keywords: null,
		},
	},
	getters: {
		getExpandedCell: ({ expandedCell }) => expandedCell,
		getProductCards: ({ productCards }) => productCards,
		getCookieForRequest: ({ currentWbToken }) => `WBToken=${currentWbToken.cookie_wb_token}; x-supplier-id-external=${currentWbToken.cookie_x_supplier_id};`
	},
	mutations: {
		setKeywordsForEdit(state, value) {
			state.keywordsForEdit = value
		},
		setExpandedCell(state, data) {
			state.expandedCell = data
		},
		setProductCards(state, data) {
			state.productCards = data
		},
		setCurrentWbToken(state, token) {
			state.currentWbToken = token
		},
		setProductSubjectId(state, id) {
			state.productSubjectId = id
		}
	},
	actions: {
		async getWbCards({ commit }) {
			try {
				const result = await axios.get(apiWbCard)
				commit('setProductCards', result.data.map(item => {

					let obj = {
						product: { name: '', img: item.media_files[0]?.link, article: item.nm_id }
					}

					item.characteristics.forEach(el => {
						switch (el.name) {
							case 'Наименование':
								obj.product.selected = false
								obj.product.name = el.value || ''
								obj.product.media = item.media_files || []
								obj.product.img = ''
								break
							case 'Бренд':
								obj.brand = el.value
								break
							case 'Цвет':
								obj.color = el.value
								break
							case 'Предмет':
								obj.product.name = el.value
								break
						}
					})
					obj.vendor_code = item.vendor_code
					obj.wb_size = item.sizes[0]?.wb_size
					obj.skus = item.sizes[0]?.skus?.[0]
					obj.optimizationLvl = ''
					obj.status = { color: 'success', name: 'Открыто' }

					return { ...item, ...obj }
				}))
				return result
			} catch (error) {
				console.error(error)
				return error
			}
		},
		/**
		 *  
		 * @param {number} id - id card
		 * @returns 
		 */
		async getOnceWbCards({ commit }, { id }) {
			try {
				const result = await axios.get(`${apiWbCard}${id}/`)

				return result
			} catch (error) {
				console.error(error)
				return error
			}
		},
		/**
		 *  
		 * @param {number} id - id card
		 * @returns 
		 */
		async removeWbCards({ commit }, { id }) {
			try {
				const result = await axios.delete(`${apiRemoveWbCard}wb_card/${id}/delete/`)

				return result
			} catch (error) {
				console.error(error)
				return error
			}
		},
		/**
		 * 
		 * @param {number, string, array<string>} payload
		 * @returns 
		 */
		async updateMediaWbCards({ commit }, { id, vendor_code, data }) {
			try {
				const result = await axios.post(
					`${apiWbCard}${id}/media/update/`,
					{ vendor_code, data },
				)

				return result
			} catch (error) {
				console.error(error)
				return error
			}
		},
		/**
		 * 
		 * @param { number, array<string> } payload
		 * @returns 
		 */
		async uploadMediaWbCards({ commit }, { id, image }) {
			try {
				const result = await axios.post(
					`${apiWbCard}${id}/media/upload/`,
					image,
					{
						headers: {
							"Content-Type": "multipart/form-data",
						},
					}
				)

				return result
			} catch (error) {
				console.error(error)
				return error
			}
		},
		updateWbCards({ commit }, { id, request }) {
			return new Promise((resolve, reject) => {
				axios.put(`${apiWbCard}${id}/update/`, request)
					.then(response => {
						const result = response

						resolve(result)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		async uploadWbCards({ commit }, payload) {
			try {
				const result = await axios.post(`${apiWbCard}upload/`, payload)

				return result
			} catch (error) {
				console.error(error)
				return error
			}
		},
		async generateBarcode({ commit }, token) {
			try {
				if (!token) return { emptyToken: true }

				const result = await axios.post(apiBarcodes, {
					count: 1,
				}, {
					headers: { 'Authorization': token }
				})

				return result
			} catch (error) {
				console.error(error)
			}
		},
		async getFilter({ commit }, { token, vendor_code }) {
			try {
				const result = await axios.post(apiCardFilter, {
					vendorCodes: [vendor_code],
				}, {
					headers: { 'Authorization': token }
				})

				return result
			} catch (error) {
				console.error(error)
			}
		},
		async getProductCharacteristics({ commit }, { token, name }) {
			try {
				const result = await axios.get(`${apiCharcsForCategory}${name}`, {
					headers: { 'Authorization': token }
				})

				return result
			} catch (error) {
				console.error(error)
			}
		},
		async getProductSubjectId({ getters, commit }, objectName) {
			axios.post(apiCharcsbyobjectname, { object_name: objectName })
				.then(res => {
					commit('setProductSubjectId', res.data[0].objectId)
				})
		},
		async getFieldsSuggestions({ state, getters, commit }, fieldName) {
			try {
				const subjectId = state.productSubjectId

				const requestBody = { name: fieldName, object_id: subjectId }

				const result = await axios.post(apiFieldsSuggestions, requestBody)

				return result
			} catch (error) {
				console.error(error)
			}
		},
		async getContentCard({ commit }, { token, name }) {
			try {
				const result = await axios.get(`${apiContentCard}?top=50&name=${name}`, {
					headers: { 'Authorization': token }
				})

				return result
			} catch (error) {
				console.error(error)
			}
		},
		async getDirectoryKinds({ commit }, { token }) {
			try {
				const result = await axios.get(apiDirectoryKinds, {
					headers: { 'Authorization': token }
				})

				return result
			} catch (error) {
				console.error(error)
			}
		},
		async getWbCountries({ commit }, { token }) {
			try {
				const result = await axios.get(apiCountries, {
					headers: { 'Authorization': token }
				})

				return result
			} catch (error) {
				console.error(error)
			}
		},
		async generateDescription({ commit }, { title, keywords }) {
			try {
				const result = await axios.post(`${apiGenerateDescription}`, {
					title,
					keywords,
				})

				return result
			} catch (error) {
				console.error(error)
			}
		},
		async getProductColors({ commit }, token) {
			try {
				const result = await axios.get(`${apiDirectoryColor}`, {
					headers: { 'Authorization': token }
				})

				return result
			} catch (error) {
				console.error(error)
			}
		},
		async changeWbCardKeywords({ commit }, { id, keywords_list }) {
			try {
				const result = await axios.patch(`${apiWbCard}${id}/add_keywords_list/`, { keywords_list })

				return result
			} catch (error) {
				console.log(error)
			}
		},
	}
}