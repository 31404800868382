import axios from 'axios'
import API_ENV from '@/config/api.config'

let { apiActualBids } = API_ENV

export default {
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async setsActionName({ commit }) {
      try {
        const result = await axios.get(apiStockList)

        return result.data
      } catch (error) {
        commit('showAlert')
        throw error
      }
    },
  }
}