import axios from 'axios'
import API_ENV from '@/config/api.config'
import moment from 'moment'

let {
	apiAdvertisingCabinet,
	apiAdvertisingCabinetAdverts,
	apiSupplierSubject,
	apiProductsSubject,
} = API_ENV

export default {
	state: {
		curredCompanyId: null,
		companies: [],
		amount: 0,
		page: 1,
		numOfRows: 10,
		tableTotalPages: 0,
		fields: {
			amount: 'Бюджет',
			title: 'Название компании',
		},
		adCabinetSuccessModal: {
			show: false,
			toCreate: 0,
			created: 0,
		}
	},
	getters: {
		getCompanies: ({ companies }) => companies,
		getAmount: ({ amount }) => amount,
		getNumOfRows: ({ numOfRows }) => numOfRows,
		getPage: ({ page }) => page,
		getTableTotalPages: ({ tableTotalPages }) => tableTotalPages,
	},
	mutations: {
		showErrors(state, error) {
			const values = Object.values(error)
			let campaingKeys = []
			let campaingValues = []
			if (error?.campaing) {
				campaingKeys = Object.keys(error?.campaing)
				campaingValues = Object.values(error?.campaing)
			}
			if (campaingKeys.length) {
				this.commit('showAlert', `${state.fields[campaingKeys[0]]}: ${campaingValues[0]}`)
			} else {
				this.commit('showAlert', error?.error || values[0][0])
			}
		},
		setCompanies(state, companies) {
			state.companies = companies
		},
		setFakeCompanies(state, companies) {
			state.companies = [
				{
					isExample: true,
					id: 0,
					status: false,
					wb: 'ready',
					title: 'Рекламная кампания №1',
					campaing_type: 'search',
					campaing_strategy: 'holding_position',
					date_updated: moment().format('DD.MM.YYYY'),
				}
			];
		},
		removeFakeCompanies(state, companies) {
			state.companies = state.companies.filter(el => !el.isExample);
		},
		setAmount(state, amount) {
			state.amount = amount
		},
		setPage(state, page) {
			state.page = page
		},
		setNumOfRows(state, numOfRows) {
			state.numOfRows = numOfRows
		},
		setTableTotalPages(state, tableTotalPages) {
			state.tableTotalPages = tableTotalPages
		},
		removeCompany(state, id) {
			state.companies = state.companies.filter(item => item.id !== id)
		},
		toogleAdCabinetSuccessModal(state) {
			state.adCabinetSuccessModal.show = !state.adCabinetSuccessModal.show
		},
		changeAdCabinetSuccessModal(state, modalInfo) {
			state.adCabinetSuccessModal = modalInfo
		},
		changeCurredCompanyId(state, id) {
			state.curredCompanyId = id
		}
	},
	actions: {
		/**
			* 
			* @param {
			*  action: cut | increase;
			*  advertising_campaing: string;
			*  page_num: number;
			*  page_size: number;
			* } params
			*/
		async getAdvertisingCabinet({ commit }, params) {
			try {
				const result = await axios.get(`${apiAdvertisingCabinet}?advertising_campaing=${params.id}&page_num=${params.page_num}&page_size=${params.page_size}`)

				return result
			} catch (error) {
				commit('showErrors', error.response.data)
				console.error(error)
			}
		},
		/**
			* 
			* @param {
			*  campaing_strategy: holding_position;
			*  campaing_type: card_product;
			*  page_num: number;
			*  page_size: number;
			*  status: active | ended | paused | ready;
			* } params
			* 
			*/
		async getAdvertisingCabinetAdverts({ commit, dispatch }, params) {
			let result = await axios.get(`${apiAdvertisingCabinetAdverts}?${params}`)

			// for (let i = 0; i < result.data.results.length; i++) {
			// 	let res;
			// 	if (result.data.results[i].status !== 'ended')
			// 		res = await dispatch('getAdvertisingCabinetAdvertsDeposit', result.data.results[i].id);

			// 	if (res) {
			// 		result.data.results[i].budget = res?.data?.total || 0;
			// 	}
			// }

			commit('setCompanies', result.data.results)
			return result
		},
		/**
			*
			* @param {
			*   campaing: {
			*     title: string,
			*     campaing_strategy: holding_position,
			*     campaing_type: card_product,
			*     auto_deposit: boolean,
			*     max_bet: number,
			*     position: number
			*   },
			*   groups: [
			*     {
			*       nms: Array<number>,
			*       set: {
			*         kind_id: number,
			*         subject_id: number
			*       }
			*     }
			*   ]
			* } params
			*
			*/
		async createAdvertisingCabinetAdverts({ commit }, { params, oneRKoneSKU }) {
			try {
				const type = params.campaing.campaing_type
				const cabinetId = params.cabinet_id

				delete params.campaing.campaing_type
				delete params.cabinet_id
				
				const createType = oneRKoneSKU ? 'bulk-create' : 'create'

				const result = await axios.post(`${apiAdvertisingCabinetAdverts}/type/${type}/${createType}/?cabinet_id=${cabinetId}`, params)

				return result
			} catch (error) {
				console.error(error)
				commit('showErrors', error.response.data)
			}
		},
		async getAdvertisingCabinetAdvertsById({ commit }, id) {
			try {
				const result = await axios.get(`${apiAdvertisingCabinetAdverts}/${id}/`)

				return result
			} catch (error) {
				commit('showErrors', error.response.data)
				console.error(error)
			}
		},
		/**
			*
			* @param {
			*   id: string;
			*   campaing: {
			*     title: string,
			*     campaing_strategy: holding_position,
			*     campaing_type: card_product,
			*     auto_deposit: boolean,
			*     max_bet: number,
			*     position: number
			*   },
			*   groups: [
			*     {
			*       nms: Array<number>,
			*       set: {
			*         kind_id: number,
			*         subject_id: number
			*       }
			*     }
			*   ]
			* } params
			*
			*/
		async updateAdvertisingCabinetAdverts({ commit }, params) {
			try {
				const result = await axios.patch(`${apiAdvertisingCabinetAdverts}/${params.id}/`, params.params)

				return result
			} catch (error) {
				commit('showErrors', error.response.data)
				console.error(error)
			}
		},
		async deleteAdvertisingCabinetAdverts({ commit }, id) {
			try {
				const result = await axios.delete(`${apiAdvertisingCabinetAdverts}/${id}/`)

				return result
			} catch (error) {
				commit('showErrors', error.response.data)
				console.error(error)
			}
		},
		/**
		 * 
		 * @param {
		 * 	id: string;
		 * 	status: string;
		 * } params 
		 * @returns 
		 */
		async updateAdvertisingCabinetAdvertsStatus({ commit }, params) {
			try {
				const result = await axios.patch(`${apiAdvertisingCabinetAdverts}/${params.id}/status/${params.status}/`)

				return result
			} catch (error) {
				commit('showErrors', error.response.data)
				console.error(error)
			}
		},
		async getSupplierSubject({ commit }, { type }) {
			try {
				const result = await axios.get(`${apiSupplierSubject}?campaing_type=${type}`)

				return result
			} catch (error) {
				console.error('getSupplierSubject: ', error.response)
				commit('showErrors', error.response.data)
			}
		},
		async getSupplierSubjectById({ commit }, { id, type }) {
			try {
				const result = await axios.get(`${apiSupplierSubject}${id}/?campaing_type=${type}`)

				return result
			} catch (error) {
				console.error('getSupplierSubjectById: ', error)
				commit('showErrors', error.response.data)
			}
		},
		async getAdvertisingCabinetAdvertsDeposit({ commit }, id) {
			try {
				const result = await axios.get(`${apiAdvertisingCabinetAdverts}/${id}/deposit/`)

				commit('setAmount', result.data.total)

				return result
			} catch (error) {
				console.error('getSupplierSubjectById: ', error)
				commit('showErrors', error.response.data)
			}
		},
		/**
		 * 
		 * @param {
		 * 	id: string;
		 * 	req: {
		 * 		amount: number;
		 * 		deposit_type: number;
		 * 	}
		 * } params 
		 * @returns 
		 */
		async setAdvertisingCabinetAdvertsDeposit({ commit }, params) {
			try {
				const result = await axios.post(`${apiAdvertisingCabinetAdverts}/${params.id}/deposit/`, params.req)

				return result
			} catch (error) {
				commit('showErrors', error.response.data)
				console.error('Error setAdvertisingCabinetAdvertsDeposit: ', error)
			}
		},
		async getUserBalance({ commit }) {
			try {
				const result = await axios.get(`${apiAdvertisingCabinetAdverts}/user-balance`)

				return result
			} catch (error) {
				commit('showErrors', error.response.data)
				console.error(error)
			}
		},
		getWordSettings({ commit }, id) {
			return new Promise((resolve, reject) => {
				axios.get(`${apiAdvertisingCabinetAdverts}/${id}/stat-words/`)
					.then(response => {
						const result = response.data

						resolve(result)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		changeWordSettings({ state, commit }, { wordSettings, id }) {
			const phrase = wordSettings.phrase.filter(Boolean)
			const strong = wordSettings.strong.filter(Boolean)

			const phraseWordPromise = new Promise((resolve, reject) => {
				axios.patch(`${apiAdvertisingCabinetAdverts}/${id || state.curredCompanyId}/type/search/phrase/`, { phrase })
					.then(response => {
						const result = response.data

						resolve(result)
					})
					.catch(err => {
						commit('showAlert', 'Не удалось изменить минус слова')
						reject(err)
					})
			})

			const strongWordPromise = new Promise((resolve, reject) => {
				axios.patch(`${apiAdvertisingCabinetAdverts}/${id || state.curredCompanyId}/type/search/strong/`, { strong })
					.then(response => {
						const result = response.data

						resolve(result)
					})
					.catch(err => {
						commit('showAlert', 'Не удалось изменить минус слова')
						reject(err)
					})
			})

			return Promise.all([phraseWordPromise, strongWordPromise])
		},
		changePhrasesSettings({ state, commit }, phrasesNames) {
			const allPromises = []

			for (const phraseName in phrasesNames) {
				const promise = new Promise((resolve, reject) => {
					axios.patch(`${apiAdvertisingCabinetAdverts}/${state.curredCompanyId}/type/search/${phraseName}/`, { [phraseName]: phrasesNames[phraseName] })
						.then(response => {
							const result = response.data

							resolve(result)
						})
						.catch(err => {
							commit('showAlert', 'Не удалось изменить фразы')
							reject(err)
						})
				})

				allPromises.push(promise)
			}

			return Promise.all(allPromises)
		},
		changeFixedStatus({ state, commit }, status) {
			return new Promise((resolve, reject) => {
				axios.get(`${apiAdvertisingCabinetAdverts}/${state.curredCompanyId}/type/search/change-fixed/?fixed=${status}`)
					.then(response => {
						const result = response.data

						resolve(result)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
	}
}
