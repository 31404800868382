<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="11" r="11" fill="#EB8DC5" fill-opacity="0.1"/>
    <g clip-path="url(#clip0_6713_50887)">
    <path d="M8.25033 10.5423V12.3757M13.7503 9.62565V11.459M13.292 7.33398C14.4143 7.33398 15.0213 7.50575 15.3234 7.63898C15.3636 7.65672 15.3837 7.66559 15.4418 7.72099C15.4766 7.75419 15.5401 7.85162 15.5564 7.89686C15.5837 7.97232 15.5837 8.01357 15.5837 8.09607V13.0224C15.5837 13.4389 15.5837 13.6472 15.5212 13.7543C15.4577 13.8631 15.3964 13.9138 15.2775 13.9556C15.1606 13.9968 14.9246 13.9514 14.4526 13.8607C14.1222 13.7972 13.7304 13.7507 13.292 13.7507C11.917 13.7507 10.542 14.6673 8.70866 14.6673C7.58633 14.6673 6.97931 14.4956 6.67727 14.3623C6.63705 14.3446 6.61694 14.3357 6.55889 14.2803C6.5241 14.2471 6.46059 14.1497 6.44425 14.1044C6.41699 14.029 6.41699 13.9877 6.41699 13.9052L6.41699 8.97888C6.41699 8.56236 6.41699 8.3541 6.47945 8.24705C6.54299 8.13816 6.60426 8.08754 6.72319 8.04569C6.8401 8.00455 7.07609 8.04989 7.54808 8.14058C7.87844 8.20406 8.27025 8.25065 8.70866 8.25065C10.0837 8.25065 11.4587 7.33398 13.292 7.33398ZM12.1462 11.0007C12.1462 11.6335 11.6332 12.1465 11.0003 12.1465C10.3675 12.1465 9.85449 11.6335 9.85449 11.0007C9.85449 10.3678 10.3675 9.85482 11.0003 9.85482C11.6332 9.85482 12.1462 10.3678 12.1462 11.0007Z" stroke="#EB8DC5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_6713_50887">
    <rect width="11" height="11" fill="white" transform="translate(5.5 5.5)"/>
    </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'TicketCircle',
}
</script>

<style lang="scss" scoped></style>
