<template>
  <transition name="modalFade">
    <div
      v-if="isModalVisible || value"
      class="modal"
      :class="currentClass"
      @mousedown="closeModalVisible"
    >
      <div class="modalBox" @mousedown.stop :style="{ maxWidth: width && `${width}px` }">
        <div class="modal__header" v-if="!empty">
          <h2 class="modal__title">{{ modalTitle }}</h2>
          <span class="modal__close" @click="closeModalVisible">
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.49804 22.5C7.30379 22.3058 7.19467 22.0423 7.19467 21.7676C7.19467 21.4929 7.30379 21.2294 7.49804 21.0352L21.0332 7.5C21.2275 7.30575 21.4909 7.19662 21.7656 7.19662C22.0403 7.19662 22.3038 7.30575 22.498 7.5C22.6923 7.69425 22.8014 7.95771 22.8014 8.23242C22.8014 8.50713 22.6923 8.77059 22.498 8.96484L8.96289 22.5C8.76864 22.6943 8.50518 22.8034 8.23047 22.8034C7.95576 22.8034 7.69229 22.6943 7.49804 22.5Z"
                fill="#3A416F"
              />
              <path
                d="M21.0371 22.5L7.50195 8.96484C7.3077 8.77059 7.19857 8.50713 7.19857 8.23242C7.19857 7.95771 7.3077 7.69425 7.50195 7.5C7.6962 7.30575 7.95966 7.19662 8.23438 7.19662C8.50909 7.19662 8.77255 7.30575 8.9668 7.5L22.502 21.0352C22.6962 21.2294 22.8053 21.4929 22.8053 21.7676C22.8053 22.0423 22.6962 22.3058 22.502 22.5C22.3077 22.6943 22.0442 22.8034 21.7695 22.8034C21.4948 22.8034 21.2314 22.6943 21.0371 22.5Z"
                fill="#3A416F"
              />
            </svg>
          </span>
        </div>
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'CModal',
  props: {
    value: {
      type: Boolean,
    },
    isModalVisible: {
      type: Boolean,
    },
    currentClass: { type: String },
    modalTitle: { type: String },
    empty: { type: Boolean },
    width: Number | String,
  },
  methods: {
    closeModalVisible() {
      this.$emit('closeModal', false);
      this.$emit('input', false);
    },
  },
};
</script>

<style lang="scss">
.modal {
  &__header {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 25px;
  }
  &__title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.4px;
    color: #3a416f;
  }
  &__close {
    position: absolute;
    cursor: pointer;
    top: -2px;
    right: -5px;
    z-index: 9;
  }
  &Box {
    transition: height 3s;
  }
}
</style>

<docs lang="md">
```vue
<template>
  <div>
    <CButtonOutline @click="show = true"> Открыть модальное окно </CButtonOutline>

    <CModal
      :is-modal-visible="show"
      currentClass="current-class"
      modalTitle="Модальное окно"
      @closeModal="show = false"
    >
      Ваш контент
    </CModal>
  </div>
</template>

<script>
export default {
  data: () => ({
    show: false,
  }),
};
</script>
```
</docs>
