<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.1" cx="8" cy="8" r="8" fill="#3A416F"/>
    <path d="M6 6.4034C6.15445 5.93548 6.45931 5.54091 6.86057 5.28958C7.26184 5.03825 7.73361 4.94638 8.19235 5.03024C8.65108 5.11409 9.06717 5.36827 9.3669 5.74775C9.66664 6.12722 9.83069 6.60751 9.83 7.10354C9.83 8.5038 7.85916 9.20394 7.85916 9.20394M7.91172 12.0045H7.91829" stroke="#3A416F" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'QuestionCircle',
}
</script>

<style lang="scss" scoped></style>
