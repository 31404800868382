const Module = () => import('./Module.vue');
const GoodsParams = () => import('./views/GoodsParams.vue');
const ExpensesReports = () => import('./views/Reports/index.vue');
const ExpensesReportsMonthly = () => import('./views/Reports/Monthly.vue');
const ExpensesReportsWBReport = () => import('./views/Reports/WBReport.vue');
const ExpensesReportsCreate = () => import('./views/Reports/Create.vue');
const ExpensesReportsEdit = () => import('./views/Reports/Edit.vue');
const ExpensesCategories = () => import('./views/Categories/index.vue');
const ExpensesCategoriesMain = () => import('./views/Categories/Main.vue');
const ExpensesCategoriesCreate = () => import('./views/Categories/Create.vue');
const ExpensesCategoriesEdit = () => import('./views/Categories/Edit.vue');
const ExpensesEntriesCreate = () => import('./views/Entries/Create.vue');

const stockRoutes = {
  path: '/expenses',
  name: 'expenses',
  breadName: 'Финансы',
  meta: {
    requiresAuth: true,
    isCloseForShowroom: true,
  },
  component: Module,
  redirect: _ => ({ path: '/internal-analytics/analytics' }),
  children: [
    {
      path: 'goods-params',
      name: 'GoodsParams',
      breadName: 'Себестоимость товаров',
      component: GoodsParams,
      meta: {
        requiresAuth: true,
        isCloseForL: true,
        isCloseForMarkert: true,
        isCloseForShowroom: true,
        sectionKey: 'financial_analytics',
      },
    },
    {
      path: '/expenses/categories',
      name: 'ExpensesCategories',
      component: ExpensesCategories,
      breadName: 'Категории расходов',
      meta: {
        isCloseForShowroom: true,
        isCloseForMarkert: true,
        isCloseForL: true,
        requiresAuth: true,
      },
      redirect: _ => ({ path: '/expenses/categories/main' }),
      children: [
        {
          path: '/expenses/categories/main',
          name: 'ExpensesCategoriesMain',
          component: ExpensesCategoriesMain,
          breadName: null,
          meta: {
            isCloseForShowroom: true,
            isCloseForMarkert: true,
            requiresAuth: true,
          },
        },
        {
          path: '/expenses/categories/create',
          name: 'ExpensesCategoriesCreate',
          component: ExpensesCategoriesCreate,
          breadName: 'Добавить категорию',
          meta: {
            isCloseForShowroom: true,
            isCloseForL: true,
            isCloseForMarkert: true,
            requiresAuth: true,
          },
        },
        {
          path: '/expenses/categories/edit/:id',
          name: 'ExpensesCategoriesEdit',
          component: ExpensesCategoriesEdit,
          breadName: 'Редактировать категорию',
          meta: {
            isCloseForShowroom: true,
            isCloseForL: true,
            isCloseForMarkert: true,
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/expenses/reports',
      name: 'ExpensesReports',
      component: ExpensesReports,
      breadName: null,
      meta: {
        isCloseForShowroom: true,
        isCloseForMarkert: true,
        isCloseForL: true,
        requiresAuth: true,
      },
      redirect: _ => ({ path: '/expenses/reports/monthly' }),
      children: [
        {
          path: '/expenses/reports/monthly',
          name: 'ExpensesReportsMonthly',
          component: ExpensesReportsMonthly,
          breadName: 'Доходы и расходы',
          meta: {
            isCloseForShowroom: true,
            isCloseForMarkert: true,
            isCloseForL: true,
            requiresAuth: true,
            sectionKey: 'financial_analytics',
          },
        },
        {
          path: '/expenses/reports/wb-report',
          name: 'ExpensesReportsWBReport',
          component: ExpensesReportsWBReport,
          breadName: 'По отчетам WB',
          meta: {
            isCloseForShowroom: true,
            isCloseForMarkert: true,
            requiresAuth: true,
            sectionKey: 'internal_analytics',
          },
        },
        {
          path: '/expenses/reports/create',
          name: 'ExpensesReportsCreate',
          component: ExpensesReportsCreate,
          breadName: 'Добавить категорию',
          meta: {
            isCloseForShowroom: true,
            isCloseForMarkert: true,
            requiresAuth: true,
          },
        },
        {
          path: '/expenses/reports/edit/:type/:id',
          splitSymbols: '/:type/:id',
          name: 'ExpensesReportsEdit',
          component: ExpensesReportsEdit,
          breadName: 'Редактировать расход',
          meta: {
            isCloseForShowroom: true,
            isCloseForMarkert: true,
            requiresAuth: true,
          },
        },
        {
          path: '/entry/create',
          name: 'ExpensesEntriesCreate',
          component: ExpensesEntriesCreate,
          breadName: 'Добавить поступление',
          meta: {
            isCloseForShowroom: true,
            isCloseForMarkert: true,
            requiresAuth: true,
          },
        },
      ],
    },
  ],
};

export default stockRoutes;
