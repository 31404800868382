<template>
  <section class="content">
    <div class="wrapper content__wrapper">
      <CGallery
        class="content__gallery"
        :galleryList="Object.keys(product).length ? JSON.parse(product.wb_images) : []"
      />
      <ELInfo :product="product" class="content__info" />
    </div>
  </section>
</template>
<script>
import CGallery from '@/components/UI/CGallery.vue';
import ELInfo from './ELInfo.vue';
import { mapActions } from 'vuex';
export default {
  name: 'ELBody',
  components: {
    CGallery,
    ELInfo,
  },
  data() {
    return {
      product: {},
    };
  },
  methods: {
    ...mapActions('externalAds', ['getInfo', 'sendClick']),
  },
  async created() {
    const { product_data, pixel } = await this.sendClick({ id: this.$route.params.code });
    this.product = product_data;
    document.head.insertAdjacentHTML('beforeend', pixel);
  },
};
</script>
<style lang="scss" scoped>
.content {
  &__wrapper {
    gap: 30px;
    align-items: flex-start;
  }
  &__gallery {
    flex: 1;
  }
  &__info {
    flex: 1;
  }
}

section {
  padding: 0 15px;
}
@media (min-width: 319.98px) {
  section {
    padding: 0 calc(50% - 170px);
  }
  .content {
    &__wrapper {
      flex-direction: column;
    }
    &__gallery {
      max-width: 100%;
    }
  }
}
@media (min-width: 575.98px) {
  section {
    padding: 0 calc(50% - 270px);
  }
}

@media (min-width: 767.98px) {
  section {
    padding: 0 calc(50% - 360px);
  }
}

@media (min-width: 991.98px) {
  section {
    padding: 0 calc(50% - 480px);
  }
  .content {
    &__wrapper {
      flex-direction: row;
    }
  }
}

@media (min-width: 1199.98px) {
  section {
    padding: 0 calc(50% - 590px);
  }
}
</style>
