import axios from "axios";
import API_ENV from "@/config/api.config";
import moment from "moment";

let {
  apiGetGoodsList,
  apiGetCategoryInfo,
  apiGetCategoryChart,
  apiGetActiveCategory,
  apiGetGoodsListChart,
  apiGetCategory,
  apiGetFilterBrand,
  apiGetFilterStore,
  apiGetGoodDaysInfo,
  //Store
  apiGetShopsInfo,
  //Brands
  apiGetBrandsInfo,
  //Category
  apiGetStoreInfo,
  apiGetBrandInfo,

  //Good
  apiGetGoodInfo,
  apiGetGoodFeeds,
  apiGetProductContent,
} = API_ENV;

export default {
  state: {
    isGoodsListLoading: false,
    isTableLoading: true,
    //Brands
    choosedOption: {},
    selectedChartItem: {},
    //Good
    goodData: [],
    goodDaysData: [],
    goodAvailable: [],
    goodFeeds: {
      category: [],
      request: [],
    },
    goodProductContent: {},
    //Goods
    countAllGoods: "",
    goodsParams: {
      sort: "gmv_grow_desc",
      periodMeasure: "W",
      periodNumber: 1,
      periodType: "grow",
      isFiltered: false,
      offset: 0,
      limit: 40,
      period: 7,
      brands_ids: [],
      store_ids: [],
    },
    goodsList: [],
    filtredInfo: [],
    chartInfo: [],
    filtredDate: {
      end: moment().subtract(5, "days").format("YYYY-MM-DD"),
      start: moment().subtract(35, "days").format("YYYY-MM-DD"),
    },
    filtredTable: [],
    filters: {
      brands: [],
      category: [
        {
          id: 0,
          name: "Мужское",
          children: [
            {
              id: 1,
              name: "Куртки",
              children: [
                {
                  id: 2,
                  name: "Спортивные куртки",
                },
              ],
            },
          ],
        },
      ],
      supplier: [],
    },

    //Category
    params: {
      sort: "gmv_desc",
      metric: "gmv",
      categoryId: "",
      brands_ids: [],
      store_ids: [],
      type: "",
    },
    breadcrumbsCategory: [
      {
        name: "Все категории",
        value: "",
      },
    ],
    allCategoryForSearch: [],
  },
  getters: {
    isTableLoading: ({ isTableLoading }) => isTableLoading,
    isGoodsListLoading: ({ isGoodsListLoading }) => isGoodsListLoading,
    //Brands
    choosedOption: ({ choosedOption }) => choosedOption,
    selectedChartItem: ({ selectedChartItem }) => selectedChartItem,
    //Good
    goodAvailable: ({ goodAvailable }) => goodAvailable,
    goodData: ({ goodData }) => goodData,
    goodDaysData: ({ goodDaysData }) => goodDaysData,
    goodFeeds: ({ goodFeeds }) => goodFeeds,
    goodProductContent: ({ goodProductContent }) => goodProductContent,

    params: ({ params }) => params,
    goodsList: ({ goodsList }) => goodsList,
    filtredInfo: ({ filtredInfo }) => filtredInfo,
    chartInfo: ({ chartInfo }) => chartInfo,
    filtredDate: ({ filtredDate }) => filtredDate,
    filtredTable: ({ filtredTable }) => filtredTable,
    filters: ({ filters }) => filters,
    countAllGoods: ({ countAllGoods }) => countAllGoods,
    // Category
    breadcrumbsCategory: ({ breadcrumbsCategory }) => breadcrumbsCategory,
    allCategoryForSearch: ({ allCategoryForSearch }) => allCategoryForSearch,

    //Goods
    goodsParams: ({ goodsParams }) => goodsParams,
  },
  mutations: {
    change_is_goods_list_loading(state, bool) {
      state.isGoodsListLoading = bool;
    },
    change_table_loading(state, bool) {
      state.isTableLoading = bool;
    },
    clearEAnalyticsFilters(state) {
      state.params = {
        sort: "gmv_desc",
        metric: "gmv",
        categoryId: "",
        brands_ids: [],
        store_ids: [],
        type: "",
      };

      state.goodsParams.brands_ids = [];
      state.goodsParams.store_ids = [];
    },
    //Brands
    change_choosed_option(state, value) {
      console.log('change_choosed_option: ', value);
      state.choosedOption = value;
    },
    change_selected_chart(state, newItem) {
      state.selectedChartItem = newItem;
    },
    //Goods
    change_goods_params(state, data) {
      state.goodsParams = Object.assign(state.goodsParams, data);
    },
    //Category
    change_breadcrumbs_category(state, data) {
      if (!data.fromBreadcrumbs) {
        state.breadcrumbsCategory.push(data);
      } else {
        const clikedEl = state.breadcrumbsCategory.find(
          (item) => item.categoryId === data.categoryId
        );
        const index = state.breadcrumbsCategory.indexOf(clikedEl);
        state.breadcrumbsCategory.splice(index + 1);
      }
    },
    change_all_category_for_search(state, data) {
      state.allCategoryForSearch = data;
    },

    //Good
    change_good_available(state, data) {
      state.goodAvailable = data;
    },
    change_good_info(state, data) {
      state.goodData = data;
    },
    change_good_days_info(state, data) {
      state.goodDaysData = data;
    },
    change_good_feeds(state, data) {
      state.goodFeeds.category = data.categoryArray;
      state.goodFeeds.request = data.requestArray;
    },
    change_good_product_content(state, data) {
      state.goodProductContent = data;
    },

    change_params(state, newParams) {
      state.params = Object.assign(state.params, newParams);
    },
    change_goods_list(state, newList) {
      state.goodsList = [...state.goodsList, ...newList];
    },
    change_filtred_list(state, newList) {
      state.goodsList = newList;
    },
    change_filtred_info(state, info) {
      state.filtredInfo = info;
    },
    change_chart_info(state, info) {
      state.chartInfo = info;
    },
    change_filtred_date(state, date) {
      state.filtredDate = date;
    },
    change_filtred_table(state, data) {
      state.filtredTable = data;
    },
    change_filters(state, data) {
      if (data.brand) {
        Object.assign(state.filters, {
          brands: data?.brand,
        });
      }
      if (data.store) {
        Object.assign(state.filters, {
          supplier: data?.store,
        });
      }
    },
    change_count_all_goods(state, data) {
      state.countAllGoods = data;
    },
  },
  actions: {
    //Brand
    async GET_BRAND_FROM_SEARCH({ commit, state }, data) {
      try {
        let response;
        if (data.from == "brand") {
          response = await axios.get(`${apiGetFilterBrand}?name=${data.text}`);
        }
        return response.data;
      } catch (error) {}
    },
    EDIT_CHOOSED_OPTION({ commit }, data) {
      commit("change_choosed_option", data);
    },
    async GET_CURRENT_BRAND({ commit, state }, data) {
      let options = {};
      data.id ? (options.Ids = [data.id]) : (options.Ids = []);
      try {
        const response = await axios.post(
          `${apiGetBrandsInfo}?time_start=${state.filtredDate.start}&time_end=${
            state.filtredDate.end
          }&sort=${state.params.sort}&limit=${data?.limit || 40}&after=${
            data?.offset || 0
          }`,
          options
        );
        return response.data;
      } catch (error) {
        console.log("ОШИБКА", error);
      }
    },
    //Store
    async GET_STORE_FROM_SEARCH({ commit, state }, data) {
      console.log("GET_STORE_FROM_SEARCH", apiGetFilterStore);
      try {
        const response = await axios.get(
          `${apiGetFilterStore}?name=${data.text}`
        );
        return response.data;
      } catch (error) {}
    },
    async GET_STORE_TABLE({ commit, state }, data) {
      let options = {};
      data.id ? (options.Ids = [data.id]) : (options.Ids = []);
      try {
        const response = await axios.post(
          `${apiGetShopsInfo}?time_start=${state.filtredDate.start}&time_end=${
            state.filtredDate.end
          }&sort=${state.params.sort}&limit=${data?.limit || 40}&after=${
            data?.offset || 40
          }`,
          options
        );
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async GET_CATEGORY_FOR_SEARCH({ commit }) {
      try {
        const response = await axios.get(apiGetCategory);
        commit("change_all_category_for_search", response.data);
        return response.data;
      } catch (error) {}
    },
    getFirstLoadDate({ commit }, date) {
      commit("change_filtred_date", date);
    },
    changeParams({ commit, dispatch }, data) {
      try {
        commit("change_table_loading", true);
        if (data.from === "category") {
          commit("change_params", data);
          dispatch("GET_FILTRED_INFO");
          dispatch("GET_FILTRED_CHART");
          data.categoryId || data.fromBreadcrumbs
            ? commit("change_breadcrumbs_category", data)
            : "";
        } else if (data.from === "goods") {
          commit("change_goods_params", data);
          dispatch("GET_GOODS_LIST");
        }
      } catch (error) {
        console.log(error);
      }
    },

    async GET_STORE({ commit, state }) {
      try {
        const response = await axios.post(
          `${apiGetStoreInfo}?time_end=${state.filtredDate.end}&time_start=${state.filtredDate.start}&sort=${state.params.sort}`
        );
        commit("change_filtred_table", response.data.items);

        return response.data;
      } catch (error) {}
    },

    /**
     * Good
     * @param {*} param0
     * @returns
     */

    async GET_GOOD_LOST({ commit }, data) {
      try {
        const response = await axios.get(
          `${apiGetGoodsListChart}?sort=lostProfit&sku=${data.sku}`
        );
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async GET_GOOD_STOCKS({ commit }, data) {
      try {
        const response = await axios.get(
          `${apiGetGoodInfo}?type=info&sku=${data.sku}`
        );
      } catch (error) {}
    },
    async GET_GOOD_INFO({ commit }, data) {
      try {
        const response = await axios.get(
          `${apiGetGoodInfo}?type=info&sku=${data.sku}`
        );
        commit("change_good_info", response.data);
        return response.data;
      } catch (error) {}
    },
    async GET_GOOD_DAYS_INFO({ commit }, data) {
      try {
        const response = await axios.get(
          `${apiGetGoodDaysInfo}?sku=${data.sku}`
        );
        commit("change_good_days_info", response.data);
        return response.data;
      } catch (error) {}
    },

    async GET_GOOD_AVAILABILITY({ commit }, data) {
      try {
        const response = await axios.get(
          `${apiGetGoodInfo}?type=events&sku=${data.sku}`
        );
        commit("change_good_available", response.data);
        return response.data;
      } catch (error) {}
    },

    async GET_GOOD_FEEDS({ commit }, data) {
      try {
        const response = await axios.get(`${apiGetGoodFeeds}?sku=${data.sku}`);
        let categoryArray = [];
        //Выбираем первые объекты, к  которых есть путь и выносим их в отдельный массив
        for (let i = 0; response.data[i].feedPath.length; i++) {
          categoryArray.push(response.data[i]);
        }
        //Остальные объекты попадают во второй список
        response.data.splice(0, categoryArray.length);
        const array = {
          categoryArray,
          requestArray: response.data,
        };
        commit("change_good_feeds", array);
        return array;
      } catch (error) {
        console.log(error);
      }
    },
    async GET_GOOD_CONTENT({ commit }, sku) {
      try {
        const response = await axios.get(
          `${apiGetProductContent}?type=stat&sku=${sku}`
        );
        const { product, reference } = response.data;

        const rowData = [
          {
            indicator: "Длина названия",
            key: "titleWordsCount",
          },
          {
            indicator: "Длина описания",
            key: "descriptionWordsCount",
          },
          {
            indicator: "Характеристики",
            key: "characteristicsCount",
          },
          {
            indicator: "Изображения",
            key: "imagesCount",
          },
          {
            indicator: "Видео",
            key: "videosCount",
          },
        ];

        rowData.forEach((row) => {
          row.productValue = product[row.key];
          row.referenceValue = reference[row.key];
        });
        const rate = rowData.reduce(
          (prevValue, currRow) =>
            currRow.productValue >= currRow.referenceValue
              ? prevValue + 20
              : prevValue,
          0
        );
        const goodProductContent = { rowData, rate };
        commit("change_good_product_content", goodProductContent);

        return response;
      } catch (error) {
        console.log(error);
      }
    },

    async GET_ALL_FILTERS({ commit }) {
      try {
        const brandResponse = await axios.get(apiGetFilterBrand);
        const storeResponse = await axios.get(apiGetFilterStore);
        commit("change_filters", {
          brand: brandResponse.data,
          store: storeResponse.data,
        });
        return { brand: brandResponse.data, store: storeResponse.data };
      } catch (error) {
        return error;
      }
    },
    async GET_GOODS_LIST({ commit, state }) {
      try {
        const filters = {
          brandsIds: state?.goodsParams?.brands_ids || [],
          merchantsIds: state?.goodsParams?.store_ids || [],
        };
        commit("change_is_goods_list_loading", true);

        const params = new URLSearchParams();
        state?.goodsParams?.name
          ? params.append("name", state?.goodsParams?.name)
          : params.delete("name");
        const result = await axios.post(
          `${apiGetGoodsList}?periodType=grow&type=general&sort=${
            state?.goodsParams?.sort
          }&periodMeasure=${state?.goodsParams?.periodMeasure}&periodNumber=${
            state?.goodsParams?.periodNumber
          }&limit=${state?.goodsParams?.limit || 40}&after=${
            state?.goodsParams?.offset || 0
          }&${params}`,
          filters
        );
        commit("change_count_all_goods", result.data.total);
        !state?.goodsParams?.offset
          ? commit("change_filtred_list", result.data.items)
          : commit("change_goods_list", result.data.items);
        return result.data;
      } catch {
        (error) => {
          console.log(error);
          return error;
        };
      } finally {
        commit("change_is_goods_list_loading", false);
      }
    },
    async GET_GOODS_LIST_CHART({ commit }, data) {
      try {
        const to = moment().subtract(5, "days").format("YYYY-MM-DD");
        const from = moment().subtract(35, "days").format("YYYY-MM-DD");

        const result = await axios.get(
          `${apiGetGoodsListChart}?sku=${data.sku}&sort=${
            data?.sort || "gmv"
          }&date_start=${from}&date_end=${to}`
        );
        return result.data;
      } catch {
        (error) => {
          console.log(error);
        };
      }
    },
    GET_FILTRED_INFO({ commit, state }) {
      commit("change_table_loading", true);
      const params = new URLSearchParams();
      state?.params?.categoryId
        ? params.append("category_id", state?.params?.categoryId)
        : params.delete("category_id");
      state?.params?.type
        ? params.append("type", state?.params?.type)
        : params.delete("type");

      const data = {
        store_ids: state?.params?.store_ids,
        brands_ids: state?.params?.brands_ids,
      };

      function checkValid(value) {
        if (!isNaN(value) && value !== Infinity && value !== -Infinity) {
          return value.toFixed(2);
        } else {
          return 0;
        }
      }

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${apiGetCategoryInfo}?time_end=${state.filtredDate.end}&time_start=${state.filtredDate.start}&sort=${state.params.sort}&${params}`,
            data
          )
          .then((response) => {
            if (response.data.total) {
              commit("change_filtred_info", {
                info: {
                  revenue: {
                    value: response.data?.total?.gmv,
                    change: checkValid(
                      (response.data?.total?.growth?.gmv * 100) /
                        response.data?.total?.gmv
                    ),
                  },
                  sales: {
                    value: response.data.total?.sales,
                    change: checkValid(
                      (response.data.total?.growth?.sales * 100) /
                        response.data?.total?.sales
                    ),
                  },
                  reviews: {
                    value: response.data.total?.reviewCount,
                    change: checkValid(
                      (response.data.total?.growth?.reviewCount * 100) /
                        response.data.total?.reviewCount
                    ),
                  },
                  rates: {
                    value: response.data.total?.ratingAvg,
                    change: checkValid(
                      (response.data.total?.growth?.ratingAvg * 100) /
                        response.data.total?.ratingAvg
                    ),
                  },
                  rating: {
                    value: {
                      count_rat: response.data.total.reviewCount,
                      count_rat_as_five: response.data?.total?.ratingDistr[4],
                      count_rat_as_four: response.data?.total?.ratingDistr[3],
                      count_rat_as_free: response.data?.total?.ratingDistr[2],
                      count_rat_as_two: response.data?.total?.ratingDistr[1],
                      count_rat_as_one: response.data?.total?.ratingDistr[0],
                    },
                    change: -0.5,
                  },
                  products: {
                    value: response.data?.total?.productsAvailable,
                    change: checkValid(
                      (response.data.total?.growth?.productsAvailable * 100) /
                        response.data.total?.productsAvailable
                    ),
                  },
                  shops: {
                    value: response.data.total?.merchantsTotal,
                    change: checkValid(
                      (response.data.total?.growth?.merchantsTotal * 100) /
                        response.data.total?.merchantsTotal
                    ),
                  },
                  average_price: {
                    value: Math.floor(response.data.total?.wSoldPrice),
                    change: checkValid(
                      (response.data.total?.growth?.wSoldPrice * 100) /
                        response.data.total?.wSoldPrice
                    ),
                  },
                  average_sales: {
                    value: Math.floor(response.data.total?.specificSales),
                    change: checkValid(
                      (response.data.total?.growth?.specificSales * 100) /
                        response.data.total?.specificSales
                    ),
                  },
                  average_revenue: {
                    value: Math.floor(response.data.total?.specificGmv),
                    change: checkValid(
                      (response.data.total.growth?.specificGmv * 100) /
                        response.data.total?.specificGmv
                    ),
                  },

                  average_in_storage: {
                    value: Math.floor(response.data.total?.inventoryAvg),
                    change: checkValid(
                      (response.data.total?.growth?.inventoryAvg * 100) /
                        response.data.total?.inventoryAvg
                    ),
                  },
                  distribution__rates: response.data.ratings,
                },
              });
              commit("change_filtred_table", response.data.categories);
            } else if (response.data.merchants) {
              commit("change_filtred_table", response.data.merchants);
            } else if (response.data.brands) {
              commit("change_filtred_table", response.data.brands);
            }
            resolve(response.data);
          })
          .catch((error) => reject(error))
          .finally(() => {
            commit("change_table_loading", false);
          });
      });
    },
    GET_FILTRED_CHART({ commit, state }) {
      const params = new URLSearchParams();
      if (state.params.categoryId) {
        params.append("category_id", state.params.categoryId);
      } else {
        params.delete("category_id");
      }
      return new Promise((resolve, reject) => {
        const data = {
          store_ids: state?.params?.store_ids,
          brands_ids: state?.params?.brands_ids,
        };
        axios
          .post(
            `${apiGetCategoryChart}?time_end=${state.filtredDate.end}&time_start=${state.filtredDate.start}&sort=${state.params.metric}&${params}`,
            data
          )
          .then((response) => {
            commit("change_chart_info", {
              calledCategory: state.params.metric,
              data: response.data,
            });
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
