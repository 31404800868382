<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0003 9.16667V14.1667M10.0003 14.1667L12.5003 12.5M10.0003 14.1667L7.50033 12.5M10.8337 2.50072C10.7541 2.5 10.6647 2.5 10.5626 2.5H6.83382C5.9004 2.5 5.43334 2.5 5.07682 2.68166C4.76322 2.84144 4.50844 3.09623 4.34865 3.40983C4.16699 3.76635 4.16699 4.23341 4.16699 5.16683V14.8335C4.16699 15.7669 4.16699 16.2334 4.34865 16.5899C4.50844 16.9035 4.76322 17.1587 5.07682 17.3185C5.43299 17.5 5.89949 17.5 6.83109 17.5H13.1696C14.1012 17.5 14.567 17.5 14.9232 17.3185C15.2368 17.1587 15.4924 16.9035 15.6522 16.5899C15.8337 16.2337 15.8337 15.7679 15.8337 14.8363V7.7714C15.8337 7.66913 15.8336 7.57967 15.8329 7.5M10.8337 2.50072C11.0717 2.50289 11.2223 2.51154 11.366 2.54605C11.5361 2.58688 11.6985 2.65439 11.8477 2.74577C12.0158 2.8488 12.1602 2.99318 12.4482 3.28125L15.0528 5.88582C15.3411 6.17407 15.4844 6.3178 15.5875 6.48599C15.6788 6.63511 15.7464 6.79771 15.7873 6.96777C15.8218 7.11147 15.8306 7.26209 15.8329 7.5M10.8337 2.50072V4.83333C10.8337 5.76675 10.8337 6.23314 11.0153 6.58966C11.1751 6.90326 11.4299 7.15873 11.7435 7.31852C12.0997 7.5 12.5662 7.5 13.4978 7.5H15.8329M15.8329 7.5H15.8338" stroke="#497ADA" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'ExportIcon'
}
</script>

<style lang="scss" scoped></style>
