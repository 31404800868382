import _ from 'lodash';
import axios from 'axios';
import API_ENV from '@/config/api.config';
import queryString from 'query-string';

const {
  apiGeoDiagramList,
  apiGeoMapList,
  apiGeoOrdersList,
  apiGeoSalesList,
} = API_ENV;

export default {
  state: {
    geoDiagramList: null,
    geoMapList: null,
    geoOrdersList: null,
    geoSalesList: null,
  },
  getters: {
    geoDiagramList: ({ geoDiagramList }) => geoDiagramList,
    geoMapList: ({ geoMapList }) => geoMapList,
    geoOrdersList: ({ geoOrdersList }) => geoOrdersList,
    geoSalesList: ({ geoSalesList }) => geoSalesList,
  },
  mutations: {
    setGeoDiagramList(state, payload) {
      state.geoDiagramList = payload;
    },
    setGeoMapList(state, payload) {
      state.geoMapList = _.cloneDeep(payload);
    },
    setGeoOrdersList(state, payload) {
      state.geoOrdersList = payload;
    },
    setGeoSalesList(state, payload) {
      state.geoSalesList = payload;
    },
  },
  actions: {
    async getGeoDiagramList({ commit }, jsonQuery) {
      try {
        const query = queryString.stringify(jsonQuery);
        const result = await axios.get(`${apiGeoDiagramList}${query ? `?${query}` : ''}`);

        commit('setGeoDiagramList', result.data);

        return result.data;
      } catch (error) {
        console.error(new Error(error));
      }
    },
    async getGeoMapList({ commit }, jsonQuery) {
      try {
        const query = queryString.stringify(jsonQuery);
        const result = await axios.get(`${apiGeoMapList}${query ? `?${query}` : ''}`);

        commit('setGeoMapList', result.data);

        return result.data;
      } catch (error) {
        console.error(new Error(error));
      }
    },
    async getGeoOrdersList({ commit }, jsonQuery) {
      try {
        const query = queryString.stringify(jsonQuery);
        const result = await axios.get(`${apiGeoOrdersList}${query ? `?${query}` : ''}`);

        commit('setGeoOrdersList', result.data);

        return result.data;
      } catch (error) {
        console.error(new Error(error));
      }
    },
    async getGeoSalesList({ commit }, jsonQuery) {
      try {
        const query = queryString.stringify(jsonQuery);
        const result = await axios.get(`${apiGeoSalesList}${query ? `?${query}` : ''}`);

        commit('setGeoSalesList', result.data);

        return result.data;
      } catch (error) {
        console.error(new Error(error));
      }
    },
  },
};