import router from "@/router"
import store from "@/store"

const registerModule = module => {
  if (module.store) {
    for (const storeKey in module.store) {
      const moduleStore = module.store[storeKey]

      store.registerModule(storeKey, moduleStore)
    }
  }

  if (module.router) {
    router.addRoute(module.router)
  }
}

const registerModules = modules => {
  for (const moduleKey in modules) {
    const module = modules[moduleKey]

    registerModule(module)
  }
}

export default registerModules