<template>
  <div class="bar">
    <div
      class="bar__compleate"
      :style="{ width: `${(step / totalSteps) * 100}%` }"
    ></div>
    <div class="bar__value">{{ step }} / {{ totalSteps }}</div>
  </div>
</template>
<script>
export default {
  name: "WbCompleateBar",
  props: {
    step: {
      type: Number,
      default: 0,
    },
    totalSteps: {
      type: Number,
      default: 0,
    },
  },
};
</script>
<style lang="scss" scoped>
.bar {
  display: flex;
  position: relative;
  overflow: hidden;
  min-height: 10px;
  border-radius: 15px;
  &__value {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__compleate {
    background: #31c363;
    transition: all 0.8s;
  }
}
</style>
