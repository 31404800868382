<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M17.9 8.5L11.95 5L6 8.5V15.5L11.95 19L17.9 15.5V8.5Z"
      :stroke="color"
      stroke-linejoin="round"
    />
    <path
      d="M11.9504 11.2999V14.0999M14.7504 9.8999V14.0999M9.15039 12.6999V14.0999"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'DynamicIcon',
  props: {
    color: {
      type: String,
      default: '#31C463',
    },
  },
};
</script>
