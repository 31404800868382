const productInfo = {
  imt_id: 100278077,
  nm_id: 123456789,
  imt_name: 'Чехол на телефон Xiaomi 11 Note с принтом',
  subj_name: 'Чехлы-книжки для телефонов',
  subj_root_name: 'Смартфоны и аксессуары',
  vendor_code: 'print-proz-1606-17800-041-81',
  description:
    'Мягкий силиконовый чехол со стильным принтом превосходно подойдёт для защиты вашего телефона от повреждений при падениях и в процессе эксплуатации. Чехол не скользит в руке, а мягкая матовая поверхность обеспечивает приятные тактильные ощущения. Чехол не царапается и не теряет внешнего вида со временем. Идеально сидит на телефоне.',
  options: [
    {
      name: 'Комплектация',
      value: '1 чехол для телефона',
      charc_type: 1,
    },
    {
      name: 'Совместимость',
      value: 'Xiaomi Mi 9 Lite',
      is_unifying: true,
      charc_type: 1,
    },
    {
      name: 'Материал изделия',
      value: 'силикон',
      is_variable: true,
      charc_type: 1,
    },
    {
      name: 'Вид застежки',
      value: 'без застежки',
      charc_type: 1,
    },
    {
      name: 'Особенности чехла',
      value: 'Мягкий гибкий чехол',
      charc_type: 1,
    },
    {
      name: 'Декоративные элементы',
      value: 'принт',
      charc_type: 1,
    },
    {
      name: 'Цвет',
      value: 'прозрачный',
      is_variable: true,
      charc_type: 1,
    },
    {
      name: 'Страна производства',
      value: 'Россия',
      charc_type: 1,
    },
    {
      name: 'Вес товара с упаковкой (г)',
      value: '50 г',
      charc_type: 4,
    },
    {
      name: 'Длина упаковки',
      value: '2 см',
    },
    {
      name: 'Высота упаковки',
      value: '20 см',
    },
    {
      name: 'Ширина упаковки',
      value: '10 см',
    },
  ],
  certificate: {},
  nm_colors_names: 'прозрачный',
  colors: [111111111, 110520567, 110513530, 110210156, 111409454, 111316287, 110123917, 111310605],
  contents: '1 чехол для телефона',
  full_colors: [
    {
      nm_id: 111111111,
    },
    {
      nm_id: 110520567,
    },
    {
      nm_id: 110513530,
    },
    {
      nm_id: 110210156,
    },
    {
      nm_id: 111409454,
    },
    {
      nm_id: 111316287,
    },
    {
      nm_id: 110123917,
    },
    {
      nm_id: 111310605,
    },
  ],
  selling: {
    brand_name: 'NOOTO',
    brand_hash: '8604A0B59F7B94F9',
    supplier_id: 249809,
  },
  media: {
    photo_count: 5,
  },
  data: {
    subject_id: 7642,
    subject_root_id: 6258,
    chrt_ids: [201417002],
    tech_size: '0',
    skus: ['2037632602423'],
  },
  grouped_options: [
    {
      group_name: 'Материалы',
      options: [
        {
          name: 'Материал изделия',
          value: 'силикон',
          is_variable: true,
          charc_type: 1,
        },
      ],
    },
    {
      group_name: 'Дополнительная информация',
      options: [
        {
          name: 'Комплектация',
          value: '1 чехол для телефона',
          charc_type: 1,
        },
        {
          name: 'Совместимость',
          value: 'Xiaomi Mi 9 Lite',
          is_unifying: true,
          charc_type: 1,
        },
        {
          name: 'Вид застежки',
          value: 'без застежки',
          charc_type: 1,
        },
        {
          name: 'Особенности чехла',
          value: 'Мягкий гибкий чехол',
          charc_type: 1,
        },
        {
          name: 'Декоративные элементы',
          value: 'принт',
          charc_type: 1,
        },
        {
          name: 'Цвет',
          value: 'прозрачный',
          is_variable: true,
          charc_type: 1,
        },
        {
          name: 'Страна производства',
          value: 'Россия',
          charc_type: 1,
        },
        {
          name: 'Вес товара с упаковкой (г)',
          value: '50 г',
          charc_type: 4,
        },
      ],
    },
    {
      group_name: 'Габариты',
      options: [
        {
          name: 'Длина упаковки',
          value: '2 см',
        },
        {
          name: 'Высота упаковки',
          value: '20 см',
        },
        {
          name: 'Ширина упаковки',
          value: '10 см',
        },
      ],
    },
  ],
  title: 'Чехол на Xiaomi Mi 9 Lite с принтом',
  price: 200,
  cover: 'https://basket-07.wb.ru/vol1111/part111111/111111111/images/big/1.jpg',
  brand_id: 20556,
  all_phrases: [
    'mi 9 lite',
    'mi 9 lite чехол',
    'mi 9 lite чехол прозрачный',
    'mi 9 lite чехол силикон',
    'mi 9 чехол',
    'mi 9 чехол силикон',
    'nooto',
    'xiaomi mi 9',
    'xiaomi mi 9 lite',
    'xiaomi mi 9 lite чехол',
    'xiaomi mi 9 lite чехол прозрачный',
    'xiaomi mi 9 lite чехол силиконовый',
    'xiaomi mi 9 чехол',
    'xiaomi mi 9 чехол прозрачный',
    'xiaomi mi 9 чехол с рисунком',
    'ми 9 лайт чехол',
    'ми 9 чехол',
    'смартфон xiaomi mi 9 lite',
    'телефон xiaomi mi 9 lite',
    'чехол mi 9',
    'чехол mi 9 lite',
    'чехол mi 9 lite прозрачный',
    'чехол mi 9 lite силиконовый',
    'чехол xiaomi 9 lite',
    'чехол xiaomi 9 лайт',
    'чехол xiaomi mi 9',
    'чехол xiaomi mi 9 lite',
    'чехол xiaomi mi 9 lite бампер',
    'чехол xiaomi mi 9 lite матовый',
    'чехол xiaomi mi 9 lite прозрачный',
    'чехол для mi 9 lite',
    'чехол для mi 9 lite xiaomi',
    'чехол для xiaomi 9 lite',
    'чехол для xiaomi 9 лайт',
    'чехол для xiaomi mi 9',
    'чехол для xiaomi mi 9 lite',
    'чехол ми 9',
    'чехол ми 9 xiaomi',
    'чехол ми 9 лайт',
    'чехол ми 9 лайт xiaomi',
    'чехол на 9 lite',
    'чехол на mi 9',
    'чехол на mi 9 lite',
    'чехол на mi 9 lite xiaomi',
    'чехол на mi 9 lite прозрачный',
    'чехол на mi 9 lite с принтом',
    'чехол на xiaomi 9 lite',
    'чехол на xiaomi 9 лайт',
    'чехол на xiaomi mi 9',
    'чехол на xiaomi mi 9 light',
    'чехол на xiaomi mi 9 lite',
    'чехол на xiaomi mi 9 lite матовый',
    'чехол на xiaomi mi 9 lite прозрачный',
    'чехол на xiaomi ми 9 лайт',
    'чехол на ми 9',
    'чехол на ми 9 xiaomi',
    'чехол на ми 9 лайт',
    'чехол на ми 9 лайт прозрачный',
    'чехол на ми 9 прозрачный',
    'чехол на телефон xiaomi mi 9 lite',
  ],
  sku: '111111111',
};

export default productInfo;
