const routeToItems = {
  api_token_general: '/settings',
  cabinet_exists: '/settings',
  tg_chat_id_exists: '',
  purchase_price: '/expenses/goods-params',
  nalog: '/expenses/goods-params',
  expenses_month_status: '/expenses/reports/monthly',
  expenses_month_count: '/expenses/reports/monthly',
  parameters_added_nalog_purchase: 'expenses/goods-params',
  active_status: '/stock/list/create-stock',
  document_status: '/stock/documents/create',
};

const routeMark = {
  api_token_general: '.wbCabinets',
  cabinet_exists: '.wbCabinets',
  purchase_price: '.price',
  nalog: '.tax',
  parameters_added_nalog_purchase: '.options-forAll',
  expenses_month_count: '.reports-expenses__content',
};
const routeTooltip = {
  api_token_general:
    'API-токен «Стандартный» Добавьте официальный токен WB. С его помощью мы загрузим данные по вашему кабинету на сервис.',
  cabinet_exists:
    'Подключите свой кабинет на WB к нашему сервису. Для этого создайте новый кабинет в настройках.',
  tg_chat_id_exists:
    'Привяжите нашего бота-ассистента к своему аккаунту в Telegram. Он будет присылать коды подтверждения операций и важные уведомления.',
  purchase_price:
    'Заполните закупочную стоимость своих товаров, чтобы мы смогли рассчитать финансовую аналитику и дать рекомендации. Также вы можете импортировать закупочную стоимость всех товаров сразу, используя Excel-документ. Для этого нажмите в верхнем правом углу страницы, кнопку “<b>Импортировать Excel</b>” и следуйте подсказкам системы.',
  nalog:
    'Выберите систему налогообложения и введите значение. Мы учтем ваши расходы по налогам в аналитике.',
  expenses_month_status:
    'Введите данные о ежемесячных расходах по вашему бизнесу. Например аренда помещений или ФОТ.',
  expenses_month_count:
    'Поскольку мы загружаем информацию с WB за последние 100 дней, рекомендуем заполнить данные по расходам за три предыдущих месяца, чтобы получить точную информацию. Для выполнения условия, создайте минимум по одному расходу в каждом месяце.',
  parameters_added_nalog_purchase: `Примените введенные в параметрах товаров данные ко всем предыдущим поставкам. Это необходимо для того, чтобы вы смогли увидеть данные за последние три месяца в сводной аналитике. Ко всем последующим поставкам, значения будут применяться автоматически, до того момента, пока вы не захотите их изменить.
    `,
  active_status: `Создайте свой физический склад в нашей системе и настройте его параметры. Вы сможете следить за остатками товаров на нем, а также планировать закупки, видеть важные уведомления и получать рекомендации.
    После создания склада, вы можете загрузить актуальные остатки товаров с помощью Excel-файла. Для этого нажмите в верхнем правом углу страницы склада, кнопку “<b>Импортировать Excel</b>” и следуйте подсказкам системы. После успешной загрузки данных, шаг “Создание складского документа” будет пройден автоматически.`,
  document_status: `Загрузите актуальные остатки ваших товаров в сервис через Excel-файл(<b>Если вы не сделали этого ранее, при создании склада</b>). Для этого создайте новый складской документ на <b>корректировку</b> в статусе <b>утвержден</b>, затем нажмите в верхнем правом углу страницы, кнопку “<b>Импортировать Excel</b>” и следуйте подсказкам системы.
  Также вы можете внести в документ все товары, находящиеся сейчас на вашем складе, согласно их номенклатуре и количеству вручную. Это необходимо, чтобы завести товары вашего физического склада в систему. После этого на складе будут отображены все остатки по товарам. В дальнейшем весь складской учет можно вести посредством этих документов.
  `,
};
export { routeToItems, routeMark, routeTooltip };
