<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="width + 1"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M9.16699 3.8332H5.66699C4.26686 3.8332 3.5668 3.8332 3.03202 4.10568C2.56161 4.34536 2.17916 4.72782 1.93948 5.19822C1.66699 5.733 1.66699 6.43307 1.66699 7.8332V14.8332C1.66699 16.2333 1.66699 16.9334 1.93948 17.4682C2.17916 17.9386 2.56161 18.321 3.03202 18.5607C3.5668 18.8332 4.26686 18.8332 5.66699 18.8332H12.667C14.0671 18.8332 14.7672 18.8332 15.302 18.5607C15.7724 18.321 16.1548 17.9386 16.3945 17.4682C16.667 16.9334 16.667 16.2333 16.667 14.8332V11.3332M6.66697 13.8332H8.06242C8.47007 13.8332 8.6739 13.8332 8.86571 13.7871C9.03577 13.7463 9.19835 13.679 9.34747 13.5876C9.51566 13.4845 9.65979 13.3404 9.94804 13.0521L17.917 5.0832C18.6073 4.39284 18.6073 3.27355 17.917 2.5832C17.2266 1.89284 16.1073 1.89284 15.417 2.5832L7.44802 10.5521C7.15977 10.8404 7.01564 10.9845 6.91257 11.1527C6.82119 11.3018 6.75385 11.4644 6.71302 11.6345C6.66697 11.8263 6.66697 12.0301 6.66697 12.4378V13.8332Z"
      :stroke="color"
      stroke-width="1.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'Edit2',
  props: {
    color: {
      type: String,
      default: '#497ADA',
    },
    width: {
      type: String | Number,
      default: 20,
    },
  },
};
</script>
