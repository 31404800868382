<template>
  <div v-if="small && isLoading" class="smallDataLoader">
    <p class="smallLoadet-text">Загрузка данных{{ ellipsis }}</p>
    <div class="wrapper">
      <div class="success-rate">
        <div class="rate-valueRange" :style="`width: ${finalPrecent}%;`"></div>
      </div>
      <span class="value-text">{{ finalPrecent }}%</span>
    </div>
  </div>
  <!-- <div
    v-else-if="!$store.getters.hasFilledTokensCabinet"
    class="dataLoaderWrapper"
  >
    <p class="dataLoadet-text emptyToken">
      Для использования этого раздела необходимо добавить Api-токены в
      <router-link to="/settings" class="link">настройках</router-link>. Как это
      сделать можно прочесть
      <a href="https://wiki.mamod.ai/ru/office/api" target="_blank" class="link"
        >здесь</a
      >, или посмотреть
      <a href="https://app.mamod.ai/settings?startVideo=true" target="_blank" class="link">видео</a>
    </p>
  </div> -->
  <div v-else-if="isLoading" class="dataLoaderWrapper">
    <div class="dataLoader"></div>
    <div class="success-rate">
      <div class="rate-valueRange" :style="`width: ${finalPrecent}%;`"></div>
    </div>
    <span class="value-text">{{ finalPrecent }}%</span>
    <p v-if="text" v-html="text" class="dataLoadet-text"></p>
    <p v-else class="dataLoadet-text">
      Мы загружаем информацию по вашим товарам с WB. Скорость загрузки зависит от количества данных,
      но как правило занимает около 20 минут. Пока идет загрузка, вы можете
      <router-link to="/" class="link" style="color: #3586ff !important"
        >пройти обучение</router-link
      >
      по нашему сервису
    </p>
  </div>
</template>

<script>
export default {
  name: 'CDataLoader',
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    precent: Number,
    small: Boolean,
    text: String,
  },
  data: () => ({
    ellipsisNum: 0,
  }),
  computed: {
    ellipsis() {
      return '.'.repeat(this.ellipsisNum % 4);
    },
    finalPrecent() {
      return this.precent ?? this.$store.getters.mostCompletedCabinet?.api_loading_status;
    },
  },
  mounted() {
    setInterval(() => this.ellipsisNum++, 1000);
  },
};
</script>

<style lang="scss" scoped>
.link {
  color: #1976d2 !important;
}

@keyframes slider {
  from {
    left: -10px;
  }
  to {
    left: 120%;
  }
}

.smallDataLoader {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .value-text {
    font-size: 15px;
    left: 107%;
    bottom: -5px;
  }

  .success-rate {
    margin-top: 5px;
    width: 300px;
    height: 10px;
  }

  .smallLoadet-text {
    width: 131px;
  }
}

.dataLoaderWrapper {
  min-height: 500px;
  // position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  // backdrop-filter: blur(10px);
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.success-rate {
  position: relative;
  overflow: hidden;
  margin-top: 30px;
  border-radius: 20px;
  width: 300px;
  height: 15px;
  background: #ccc;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 59px;
    background: #fff;
    box-shadow: 0px 0px 17px 10px #ffffffde;
    transform: rotate(62deg);
    width: 0;
    height: 120%;
    animation: 5s cubic-bezier(0.11, 1.01, 0.2, 1.08) 0s infinite running slider;
  }
}

.rate-valueRange {
  height: 100%;
  border-radius: 20px;
  background: #3586ff;
  transition: all 0.3s ease;
}

.value-text {
  font-weight: 700;
  color: #353568;
  font-size: 18px;
}

.dataLoadet-text {
  margin-top: 40px;
  text-align: center;
  max-width: 600px;
  line-height: 1.3;

  &.emptyToken {
    margin-top: 0;
    font-size: 15px;
  }
}

/* HTML: <div class="loader"></div> */
.dataLoader {
  scale: 2;
  width: 90px;
  height: 14px;
  position: relative;
  clip-path: inset(-40px 0 -5px);
}
.dataLoader:before {
  content: '';
  position: absolute;
  inset: auto calc(50% - 17px) 0;
  height: 50px;
  --g: no-repeat linear-gradient(#ccc 0 0);
  background: var(--g), var(--g), var(--g), var(--g);
  background-size: 16px 14px;
  animation: l7-1 2s infinite linear, l7-2 2s infinite linear;
}
@keyframes l7-1 {
  0%,
  100% {
    background-position: 0 -50px, 100% -50px;
  }
  17.5% {
    background-position: 0 100%, 100% -50px, 0 -50px, 100% -50px;
  }
  35% {
    background-position: 0 100%, 100% 100%, 0 -50px, 100% -50px;
  }
  52.5% {
    background-position: 0 100%, 100% 100%, 0 calc(100% - 16px), 100% -50px;
  }
  70%,
  98% {
    background-position: 0 100%, 100% 100%, 0 calc(100% - 16px), 100% calc(100% - 16px);
  }
}
@keyframes l7-2 {
  0%,
  70% {
    transform: translate(0);
  }
  100% {
    transform: translate(200%);
  }
}
</style>
