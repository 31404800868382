<template>
  <div class="app-wrapper">
    <transition name="slide">
      <SideBar
        v-if="showSidebar || !isMobile"
        v-model="showSidebar"
        :isMobile="isMobile"
        @showModal="showModal"
        @changeRouteName="changeRouteName"
      />
    </transition>
    <div
      class="main-app"
      :class="{
        fullWidth: isMobile,
      }"
      :style="`min-width: ${minWidthMainBlock}`"
    >
      <AppHeader v-model="showSidebar" :isMobile="isMobile" :title="selectedRouteName" />
      <div class="main-section">
        <DevWorking v-if="isPageWroking" />
        <template v-else-if="isCloseSectionShown || isFirstLoad">
          <div v-if="isFirstLoad" class="app__loader">
            <img src="@/assets/img/mobileHeaderLogo.png" alt="logo" />
          </div>
          <CloseBecauseTariff
            v-else-if="!isEnableSection"
            buttonsAvailable
            rate-button-text="Докупить раздел"
          >
            Для работы с этим блоком
            <router-link to="/rates" class="link">купите</router-link> раздел «{{ sectionName }}»
          </CloseBecauseTariff>
          <CloseBecauseTariff v-else-if="isCloseForTariff" buttonsAvailable>
            В текущем тарифе данный раздел недоступен.
          </CloseBecauseTariff>
          <CloseBecauseTariff
            v-else-if="!personalInfo?.current_tariff && !isFirstLoad"
            buttonsAvailable
            rateButtonText="Выбрать тариф"
          >
            У вас не приобретен тариф.
          </CloseBecauseTariff>
          <CloseBecauseTariff
            v-else-if="isTokensRequirePage && isNotFilled"
            ownRateBtnAction
            rateButtonText="Подключить кабинет"
            @rateButtonClicked="changeVisibleForCreateCabinet"
          >
            <p style="font-size: 18px">
              У вас отсутствуют необходимые API-токены. Перейдите в
              <router-link class="rates-link" tag="a" to="/settings">настройки</router-link>
              , чтобы их добавить.
            </p>
          </CloseBecauseTariff>
          <CloseSection
            v-else-if="isTokensRequirePage && !$store.getters.hasCompletedCabinet"
            empty
          >
            <CDataLoader />
          </CloseSection>
          <router-view v-else class="main-contentView" />
        </template>
        <template v-else>
          <router-view class="main-contentView" />
          <WBCabinetsFirst
            v-if="
              userInfo.current_tariff &&
              isAddTokenModalVisible &&
              !findedCabinet?.is_modal_about_100_percent_loading_viewed
            "
            :cabinet="findedCabinet"
          />
          <CModal
            v-else-if="
              !findedCabinetToShowModal?.is_modal_about_100_percent_loading_viewed &&
              findedCabinetToShowModal?.api_loading_status === 100
            "
            v-model="isFreeTariffModalVisible"
            @closeModal="closeModal"
            modal-title="Поздравляем!"
          >
            <CInformation
              v-if="userInfo?.is_free_tariff_can_be_applied"
              text="Вам начислен бесплатный тариф на 5 дней!"
            />
            <p>Ваши данные успешно загружены! Теперь Вы можете начать работу вместе с Mamod!</p>
          </CModal>
        </template>
      </div>

      <FooterApp :isMobile="isMobile" />
    </div>
  </div>
</template>

<script>
import FooterApp from '@/components/FooterApp.vue';
import SideBar from '@/layouts/SideBar.vue';
import DevWorking from '@/components/ClosePage/DevWorking.vue';
import CloseBecauseTariff from '@/components/ClosePage/CloseBecauseTariff.vue';
import AppHeader from './AppHeader.vue';
import { mapActions, mapGetters } from 'vuex';
import CDataLoader from '@/components/UI/CDataLoader.vue';
import CloseSection from '@/components/ClosePage/CloseSection.vue';
import CModal from '@/components/UI/CModal.vue';
import WBCabinetsFirst from '@/pages/PersonalAccount/WBCabinetsFirst.vue';
import CInformaition from '@/components/UI/CInformaition.vue';

export default {
  name: 'AppLayout',

  components: {
    SideBar,
    FooterApp,
    DevWorking,
    CloseBecauseTariff,
    AppHeader,
    CDataLoader,
    CloseSection,
    CModal,
    WBCabinetsFirst,
    CInformaition,
  },
  data() {
    return {
      isCanBeFreeProlongateModal: false,
      isFreeTariffModalVisible: true,

      userId: Number,
      name: '',
      isFirstLoad: false,
      showSidebar: false,
      timeout: null,
      minWidthMainBlock: 'auto',
      isMobile: false,
      selectedRouteName: null,
      activeCabinets: [],
    };
  },
  methods: {
    ...mapActions([
      'getExtraFreeTariff',
      'getNotifications',
      'getUserInfo',
      'getPersonalInfo',
      'getAllActiveCabinets',
      'getWbToken',
      'patchCabinet',
      'connectStageWebSocket',
      'connectCabinetsWebSocket',
      'getStageList',
      'createWbTokens',
      'getPermissions',
    ]),
    showModal(payload) {
      console.log('Действие в дочернем компоненте выполнено с данными:', payload);
    },
    changeVisibleForCreateCabinet() {
      this.$emit('showModal');
    },
    async closeModal() {
      await this.patchCabinet({
        id: this.findedCabinetToShowModal.id,
        is_modal_about_100_percent_loading_viewed: true,
      });
      window.location.reload();
    },
    changeRouteName(val) {
      this.selectedRouteName = val;
    },
    detectMobile() {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
      ];

      return toMatch.some(toMatchItem => {
        return navigator.userAgent.match(toMatchItem);
      });
    },
    markSelectedBlock(selector) {
      const block = document.querySelector(selector);
      if (!block) return;
      this.scrollToElement(block);
    },
    markSelectedAnyBlock(selector, numberOfElement) {
      const blocks = document.querySelectorAll(selector);
      if (!blocks) return;
      const block = blocks[numberOfElement];
      this.scrollToElement(block);
    },
    scrollToElement(block) {
      block.scrollIntoView({ block: 'center', behavior: 'smooth' });
      block.classList.add('markedBlock');
      setTimeout(() => {
        document.body.addEventListener(
          'click',
          () => {
            block.classList.remove('markedBlock');
          },
          {
            once: true,
          },
        );
      }, 0);
    },
    setGlobalTourStatus() {
      const globalTourInfo = JSON.parse(localStorage.getItem('globalTour'));
      this.$store.commit('setGlobalTourActive', globalTourInfo?.isActive);
    },
    async getMainUserInfo() {
      await this.getPersonalInfo();

      const res = await this.getUserInfo();

      this.user_id = res.data.user.id;
      this.name = res.data.user.phone;
      console.log(' this.personalInfo', this.personalInfo);
      this.isCanBeFreeProlongateModal =
        res.data?.current_tariff?.is_tariff_prolongation && !this.personalInfo?.current_tariff;

      const allPromises = [];
      allPromises.push(this.connectStageWebSocket(), this.connectCabinetsWebSocket());

      await Promise.all(allPromises);
    },
  },
  watch: {
    async isFreeTariffModalVisible(newValue, oldValue) {
      if (!oldValue) return;
      if (oldValue === true && newValue === false) {
        this.findedCabinet.id;
        await this.patchCabinet({
          id: this.findedCabinet.id,
          is_modal_about_100_percent_loading_viewed: true,
        });
      }
    },
  },
  computed: {
    ...mapGetters([
      'personalInfo',
      'getPageTourActive',
      'getGlobalTourActive',
      'userInfo',
      'unconvertedCabinets',
      'getEnableSections',
    ]),
    findedCabinetToShowModal() {
      const findedCabinet = this.unconvertedCabinets[0];
      if (!findedCabinet) return;
      if (findedCabinet.is_modal_about_100_percent_loading_viewed === false) {
        return findedCabinet;
      }
    },
    findedCabinet() {
      return this.unconvertedCabinets.find(
        item => item.open_api_standart === null || item.api_loading_status < 100,
      );
    },
    isAddTokenModalVisible() {
      if (this.unconvertedCabinets.find(item => item.api_loading_status === 100)) return false;
      return (
        this.unconvertedCabinets[0]?.api_loading_status < 100 &&
        this.$route?.path !== '/study' &&
        this.$route.path !== '/rates'
      );
    },
    isCloseForTariff() {
      return (
        (this.personalInfo?.current_tariff?.tariff?.title === 'L' &&
          this.$route.meta.isCloseForL &&
          this.isCurrentWayClosed) ||
        (this.personalInfo?.current_tariff?.tariff?.title === 'M' &&
          this.$route.meta.isCloseForMarkert &&
          this.isCurrentWayClosed) ||
        (this.personalInfo?.current_tariff?.tariff?.title === 'S' &&
          this.$route.meta.isCloseForShowroom)
      );
    },
    isCurrentWayClosed() {
      const parentRoute = this.$route.path.split('/').filter(item => item)[0];
      const tariff = this.personalInfo?.current_tariff;
      if (
        (parentRoute === 'stock' &&
          tariff.product_management &&
          this.$route.name !== 'ProcurementPlanning') ||
        (parentRoute === 'wb-products' && tariff.universal_table_goods) ||
        parentRoute === 'expenses' ||
        (parentRoute === 'internal-analytics' && tariff.financial_analytics)
      ) {
        return false;
      } else {
        return true;
      }
    },
    isNoTariff() {
      return this.$route.meta.isCloseNoTariff;
    },
    isPageWroking() {
      return this.$route.meta.isWorkingNow;
    },
    isShowWithoutTariff() {
      const availableRoutesName = [
        'settings',
        'rates',
        'BillError',
        'checking-account',
        'home',
        'products',
        'AIProductsList',
        'AIProduct',
        'dashboard',
        'ExternalLink',
        'referals',
      ];

      return !availableRoutesName.includes(this.$route.name);
    },
    isNotFilled() {
      const hasFilledTokens = this.$store.getters.hasFilledTokensCabinet;

      return !hasFilledTokens;
    },
    isTokensRequirePage() {
      const tokensReqPagesName = [
        'expenses',
        'internal-analytics',
        'wbCards',
        'stocks',
        'autoanswers',
        'price-tasks',
        'AdCabinetMain',
        'abTests',
      ];
      const routeMainName = this.$route.matched[0]?.name;
      const isPageReqTokens = tokensReqPagesName.includes(routeMainName);
      return isPageReqTokens && this.$route.matched[1]?.name !== 'InternalAnalyticsCalcUnit';
    },
    isCloseSectionShown() {
      return (
        (this.isShowWithoutTariff || !this.isEnableSection) &&
        !this.getGlobalTourActive &&
        !this.getPageTourActive
      );
    },
    isEnableSection() {
      const routeSectionKey = this.$route.meta.sectionKey;

      if (!routeSectionKey) return true;

      return this.getEnableSections[routeSectionKey];
    },
    sectionName() {
      const tariffMapping = {
        financial_reports: 'Управленческая отчетность',
        financial_analytics: 'Финансовая аналитика',
        supply_purchase_planning: 'Расчет поставок и закупок',
        product_management: 'Складской учет',
        unit_calc: 'Юнит калькулятор',
        internal_analytics: 'Внутреняя аналитика',
        positions_quantity: 'Мониторинг позиций',
        seo: 'SEO',
        review_analysis: 'Анализ отзывов',
        autoanswer: 'Автоответы на отзывы',
        external_advertising: 'Аналитика внешней рекламы',
        ab_tests: 'A/B тесты изображений',
        auto_bidder: 'Автобидер',
        price_change_analysis: 'Репрайсер',
        ai_helper: 'Нейронный помошник MamodGPT (AI)',
        dashboard: 'Дашборд рекомендаций (AI)',
        cabinet_qty: 'Количество магазинов',
      };

      return tariffMapping[this.$route.meta.sectionKey];
    },
  },
  async created() {
    this.isFirstLoad = true;
    this.isMobile = this.detectMobile();
    this.setGlobalTourStatus();
    await this.getAllActiveCabinets();
    await this.getPermissions();
    let cabinet = await this.getWbToken();
    if (cabinet.length < 1) {
      try {
        await this.createWbTokens();
      } catch (error) {}
      cabinet = await this.getWbToken();
    }
    this.activeCabinets = cabinet;

    await this.getMainUserInfo();

    this.isFirstLoad = false;
  },

  updated() {
    const markBlock = this.$route.query.markBlock;
    if (this.$route.query.markBlock) {
      setTimeout(() => {
        this.markSelectedAnyBlock(markBlock, 1);
      }, 3000);
    } else {
      this.markSelectedBlock(markBlock);
    }

    this.getNotifications();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/animate.scss';
.app {
  &__loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    img {
      animation: pulse 5s infinite;
    }
  }
}
.main-section {
  position: relative;
  min-height: 80vh;
  &:has(.box__fake) {
    max-height: 100vh;
    overflow: hidden;
  }
}
.app-wrapper {
  margin-top: 10px;
}
.main-app {
  transition: all 0.3s;
  min-width: 360px;
  display: flex;
  flex-direction: column;

  &.fullWidth {
    margin-left: 0;
    padding: 0 10px 10px 10px;

    .main-contentView {
      padding-top: 92px;
      margin-bottom: 20px;
      min-height: 100vh;
    }
  }

  &__header {
    padding-top: 20px;
  }

  &__icon {
    cursor: pointer;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.25s;
}

.slide-enter,
	.slide-leave-to

	/* .fade-leave-active до версии 2.1.8 */ {
  transform: translateX(-100%);
}

@media screen and (max-width: 492px) {
  .main-app {
    &.fullWidth {
      .main-contentView {
        padding-top: 20px;
      }
    }
  }
}
</style>
<style lang="scss">
html:has(.app__loader) {
  overflow: hidden;
}

.main-section {
  .app__modal {
    .modalBox {
      width: auto;
      max-width: none;
      .modal {
        &__text {
          margin-top: 15px;
        }
        &__buttons {
          margin-top: 20px;
          .prolongate {
            padding: 14px 20px;
          }
        }
      }
    }
    &__text {
      margin-top: 30px;
    }
    &__buttons {
      margin-top: 15px;
      gap: 20px;
    }
  }
}

@media screen and (max-width: 992px) {
  .main-section {
    .app__modal {
      .modalBox {
        .modal {
          &__buttons {
            flex-direction: column;
            align-items: stretch;
            gap: 10px;
            .button {
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .main-section {
    .app__modal {
      .modalBox {
        width: 90%;
        .modal {
          &__buttons {
            .prolongate {
              padding: 14px 12px;
            }
          }
        }
      }
    }
  }
}
</style>
