import Vue from 'vue';
import Router from 'vue-router';
import store from '../store/index.js';
import axios from 'axios';

import InternalAnalyticsRoutes from '@/pages/InternalAnalytics/routes.js';
import PriceTasksRoute from '@/pages/PriceTasks/router.js';

Vue.use(Router);

let router = new Router({
  mode: 'history',
  routes: [
    // -- Внутренняя аналитика --
    InternalAnalyticsRoutes,

    // -- Репрайсер --
    PriceTasksRoute,
    {
      path: '/external-analytics',
      name: 'External',
      breadName: 'Внешняя аналитика',
      meta: { requiresAuth: true, isWorkingNow: true },
      redirect: { name: 'ExternalGoods' },
      component: () => import('../pages/ExternalAnalytics/Index.vue'),
      children: [
        {
          path: 'goods',
          name: 'ExternalGoods',
          breadName: 'Товары',
          meta: { requiresAuth: true, isWorkingNow: true },
          component: () => import('../pages/ExternalAnalytics/ExternalGoods.vue'),
        },
        {
          path: 'categories',
          breadName: 'Категории',
          name: 'ExternalCategories',
          meta: {
            isCloseForShowroom: true,
            isCloseForMarkert: true,
            requiresAuth: true,
            isWorkingNow: true,
          },
          component: () => import('../pages/ExternalAnalytics/ExternalCategories.vue'),
        },
        {
          path: 'store',
          name: 'ExternalStore',
          breadName: 'Товары',
          meta: {
            requiresAuth: true,
            isCloseForShowroom: true,
            isWorkingNow: true,
          },
          component: () => import('../pages/ExternalAnalytics/ExternalStore.vue'),
        },
        {
          path: 'brands',
          name: 'ExternalBrands',
          breadName: 'Товары',
          meta: { requiresAuth: true, isWorkingNow: true },
          component: () => import('../pages/ExternalAnalytics/ExternalBrands.vue'),
        },
        {
          path: ':id/good',
          name: 'ExternalGood',
          meta: {
            requiresAuth: true,
            isWorkingNow: true,
          },
          component: () => import('../pages/ExternalAnalytics/ExternalGood.vue'),
        },
      ],
    },
    {
      path: '/price-analysis',
      name: 'price-analysis',
      meta: {
        requiresAuth: true,
        sectionKey: 'price_change_analysis',
      },
      component: () => import('../pages/PriceAnalysis/PriceAnalysis.vue'),
    },

    {
      path: '/login',
      name: 'login',
      meta: { layout: 'auth' },
      component: () => import('../pages/Auth/Login.vue'),
    },
    {
      path: '/settings',
      name: 'settings',
      meta: {
        requiresAuth: true,
      },
      component: () => import('../pages/Settings.vue'),
    },
    // {
    //   path: '/registration',
    //   name: 'registration',
    //   meta: { layout: 'auth' },
    //   component: () => import('../pages/Auth/Register.vue'),
    // },
    {
      path: '/rates',
      name: 'rates',
      breadName: 'Тарифы',
      meta: {
        requiresAuth: false,
      },
      component: () => import('../pages/Rates.vue'),
    },
    {
      path: '/autoanswers',
      name: 'autoanswers',
      breadName: 'Автоответы',
      meta: {
        isCloseForShowroom: true,
        requiresAuth: true,
        sectionKey: 'autoanswer',
      },
      component: () => import('../pages/autoanswers/AutoAnswers.vue'),
    },
    {
      path: '/autoanswers/:id/edit-answers',
      name: 'edit-answers',
      breadName: 'Редактирование шаблона',
      meta: {
        isCloseForShowroom: true,
        requiresAuth: true,
      },
      component: () => import('../pages/autoanswers/EditAutoAnswers.vue'),
    },
    {
      path: '/autoanswers/:id/edit-answers/answers-history',
      name: 'answers-history',
      breadName: 'История ответов',
      meta: {
        isCloseForShowroom: true,
        requiresAuth: true,
      },
      component: () => import('../pages/autoanswers/AnswersHistory.vue'),
    },
    {
      path: '/autoanswers/:id/edit-answers/confirmation',
      name: 'answers-confirmation',
      breadName: 'Ожидают подтверждения',
      meta: {
        isCloseForShowroom: true,
        requiresAuth: true,
      },
      component: () => import('../pages/autoanswers/Confirmation.vue'),
    },
    {
      path: '/actual-rates',
      name: 'actual-rates',
      breadName: 'Актуальные ставки',
      meta: {
        isCloseForShowroom: true,
        sectionKey: 'auto_bidder',
      },
      component: () => import('../pages/ActualBids/ActualRates.vue'),
    },
    {
      path: '/actual-rates/result',
      name: 'actual-rates-result',
      breadName: 'Проверка ставок',
      meta: {
        requiresAuth: true,
      },
      component: () => import('../pages/ActualBids/ActualRatesResult.vue'),
    },
    {
      path: '/create-reviews',
      name: 'CreateReviews',
      meta: {
        requiresAuth: true,
      },
      component: () => import('../pages/Reviews.vue'),
    },
    {
      path: '/referals',
      name: 'referals',
      meta: {
        requiresAuth: true,
      },
      component: () => import('../pages/Referals.vue'),
    },
    {
      path: '/positions',
      name: 'positions',
      breadName: 'Позиции',
      meta: {
        requiresAuth: true,
        sectionKey: 'positions_quantity',
      },
      component: () => import('../pages/positions/Positions.vue'),
    },
    {
      path: '/positions/:id/details',
      name: 'positions-details',
      breadName: 'Статистика по позиции продукта',
      meta: {
        requiresAuth: true,
        // isWorkingNow: true,
      },
      component: () => import('../pages/positions/PositionsDetails.vue'),
    },
    {
      path: '/sku',
      name: 'sku',
      breadName: 'Сравнение с конкурентами',
      meta: {
        requiresAuth: true,
        sectionKey: 'seo',
        // isWorkingNow: true,
      },
      component: () => import('../pages/compareSku/SKU.vue'),
    },
    {
      path: '/sku/comparison-sku',
      name: 'comparison-sku',
      breadName: 'Анализ',
      meta: {
        requiresAuth: true,
        // isWorkingNow: true,
        isCloseForShowroom: true,
      },
      component: () => import('../pages/compareSku/ComparisonSku.vue'),
    },
    {
      path: '/ad-cabinet',
      name: 'ad-cabinet',
      breadName: 'Рекламный кабинет',
      meta: {
        requiresAuth: true,
        sectionKey: 'auto_bidder',
      },
      component: () => import('../pages/AdCabinet/index.vue'),
      redirect: to => {
        return { path: '/ad-cabinet/main' };
      },
    },
    {
      path: '/ad-cabinet/main',
      name: 'AdCabinetMain',
      meta: {
        requiresAuth: true,
      },
      component: () => import('../pages/AdCabinet/Main.vue'),
    },
    {
      path: '/ad-cabinet/create',
      name: 'AdCabinetCreate',
      breadName: 'Создание кампании',
      meta: {
        requiresAuth: true,
      },
      component: () => import('../pages/AdCabinet/Create.vue'),
    },
    {
      path: '/ad-cabinet/:id/edit',
      name: 'AdCabinetEdit',
      breadName: 'Редактирование кампании',
      meta: {
        requiresAuth: true,
      },
      component: () => import('../pages/AdCabinet/Edit.vue'),
    },
    {
      path: '/ad-cabinet/:id/statistics',
      name: 'AdCabinetStatistics',
      breadName: 'Статистика кампании',
      meta: {
        requiresAuth: true,
      },
      component: () => import('../pages/AdCabinet/Statistics.vue'),
    },
    {
      path: '/collection-requests',
      name: 'collection-requests',
      meta: {
        requiresAuth: true,
        sectionKey: 'seo',
      },
      component: () => import('../pages/CollectionRequests.vue'),
    },
    {
      path: '/keywords-list',
      name: 'keywords-list',
      meta: {
        requiresAuth: true,
        sectionKey: 'seo',
      },
      component: () => import('../pages/KeywordsList.vue'),
    },
    {
      path: '/bill/success',
      name: 'success',
      meta: {
        requiresAuth: true,
      },
      component: () => import('../pages/bill/BillSuccess.vue'),
    },
    {
      path: '/bill/error',
      redirect: '/rates',
      name: 'error',
      meta: {
        requiresAuth: true,
      },
      component: () => import('../pages/bill/BillError.vue'),
    },
    {
      path: '/reviews-analysis',
      name: 'ReviewsAnalysis',
      meta: {
        requiresAuth: true,
        sectionKey: 'review_analysis',
      },
      component: () => import('../pages/ReviewsAnalysis/Index.vue'),
      redirect: to => {
        return { path: '/reviews-analysis/' };
      },
      children: [
        {
          path: '/reviews-analysis/',
          name: 'AnalysisList',
          meta: {
            requiresAuth: true,
            sectionKey: 'review_analysis',
          },
          component: () => import('../pages/ReviewsAnalysis/AnalysisList.vue'),
        },
        {
          path: '/reviews-analysis/create-analysis/',
          name: 'AnalysisCreate',
          meta: {
            requiresAuth: true,
          },
          component: () => import('../pages/ReviewsAnalysis/AnalysisCreate.vue'),
        },
        {
          path: '/reviews-analysis/:id/edit-analysis/',
          name: 'AnalysisEdit',
          meta: {
            requiresAuth: true,
          },
          component: () => import('../pages/ReviewsAnalysis/AnalysisEdit.vue'),
        },
        {
          path: '/checking-account/',
          name: 'checking-account',
          component: () => import('../pages/CheckingAccount.vue'),
        },
      ],
    },
    {
      path: '/stages',
      name: 'stages',
      meta: {
        requiresAuth: true,
      },
      component: () => import('../pages/StagesPage.vue'),
    },
    {
      path: '/landing-rates',
      name: 'LRates',
      meta: {
        requiresAuth: false,
        layout: 'land',
      },
      component: () => import('../pages/landing/LRates.vue'),
    },
    {
      path: '/product-card',
      name: 'ProductCard',
      breadName: 'Анализ карточки товара',
      meta: {
        // isWorkingNow: true,
        requiresAuth: true,
        sectionKey: 'seo',
      },
      component: () => import('../pages/ProductCard/index.vue'),
      redirect: to => ({ path: '/product-card/main' }),
      children: [
        {
          path: '/product-card/main',
          name: 'ProductCardMain',
          meta: {
            requiresAuth: true,
            // isWorkingNow: true,
          },
          component: () => import('../pages/ProductCard/Main.vue'),
        },
        {
          path: '/product-card/Keywords',
          name: 'ProductCardKeywords',
          meta: {
            // isWorkingNow: true,
            requiresAuth: true,
          },
          component: () => import('../pages/ProductCard/Keywords.vue'),
        },
        {
          path: '/product-card/edit/:id',
          name: 'ProductCardEdit',
          meta: {
            // isWorkingNow: true,
            requiresAuth: true,
          },
          component: () => import('../pages/ProductCard/Edit.vue'),
        },
        {
          path: '/product-card/create/:id',
          name: 'ProductCardCreate',
          meta: {
            // isWorkingNow: true,
            requiresAuth: true,
          },
          component: () => import('../pages/ProductCard/Create.vue'),
        },
        {
          path: '/product-card/check',
          name: 'ProductCardCheck',
          meta: {
            // isWorkingNow: true,
            requiresAuth: true,
            sectionKey: 'seo',
          },
          component: () => import('../pages/ProductCard/Check.vue'),
        },
        {
          path: '/product-card/check/:id',
          name: 'ProductCardCheck',
          meta: {
            // isWorkingNow: true,
            requiresAuth: true,
          },
          component: () => import('../pages/ProductCard/Check.vue'),
        },
      ],
    },
    {
      path: '/product-card/result-of-checking',
      name: 'ProductCardResultCheck',
      breadName: 'Результат',
      meta: {
        // isWorkingNow: true,
        requiresAuth: true,
      },
      component: () => import('../pages/ProductCard/ResultCheck.vue'),
    },
  ],
});

/**
 * Предотвращение несанкционированного доступа
 */

router.beforeEach((to, from, next) => {
  if (from.path !== to.path) {
    window.scrollTo(0, 0);
    next();
  }

  axios.interceptors.response.use(
    response => {
      next();
      return response;
    },
    async err => {
      if (err.config.url.indexOf('account/token/refresh/') !== -1) {
        store.dispatch('logout');
        next('/login');
        return;
      }
      const originalRequest = err.config;
      if (
        err.response.status === 401 &&
        !err.request.responseURL.includes('wildberries') &&
        from.name !== 'LRates'
      ) {
        const refresh = localStorage.getItem('refreshToken');
        if (!originalRequest._retry && refresh) {
          try {
            const res = await store.dispatch('refreshToken', { refresh });
            originalRequest._retry = true;
            originalRequest.headers.Authorization = `JWT ${res.data.access}`;
            localStorage.setItem('token', res.data.access);
            localStorage.setItem('refreshToken', res.data.refresh);
            axios.defaults.headers.common['Authorization'] = `JWT ${res.data.access}`;
            await store.dispatch('getAllActiveCabinets');
            await store.dispatch('getPermissions');

            return axios(originalRequest);
          } catch (refreshError) {
            await store.dispatch('logout');
            router.push('/login');
            return Promise.reject(refreshError);
          }
        } else {
          if (router.currentRoute.name !== 'login') {
            store.dispatch('logout').then(() => {
              router.push('/login');
            });
          }
        }
      }
      throw err;
    },
  );
});

export default router;
