import axios from "axios";
import queryString from "query-string";

import API_ENV from "@/config/api.config";

const {
  apiExpensesReport,
  apiExpensesMonthly,
  apiExpensesWbReport,
  apiExpensesWbReportIds,
  apiExpensesRelatedProducts,
} = API_ENV;

export default {
  state: {
    monthList: null,
  },
  getters: {
    monthList: (state) => state.monthList,
  },
  mutations: {
    setReportsList(state, payload) {
      state.monthList = payload;
    },
  },
  actions: {
    async addNewReportExpenses({ commit }, { req, url, cabinetId }) {
      try {
        req.cabinet = cabinetId;
        const { data } = await axios.post(`${apiExpensesReport}/${url}/`, req);

        return data;
      } catch (error) {
        console.error("Error addNewReportExpenses: ", new Error(error));
        throw error;
      }
    },
    async editReportByTypeAndId({ commit }, { type, id, req }) {
      try {
        const { data } = await axios.patch(
          `${apiExpensesReport}/${type}/${id}`,
          req
        );

        return data;
      } catch (error) {
        console.error("Error editReportByTypeAndId: ", new Error(error));
      }
    },
    async deleteReport({ commit }, { id, type, req }) {
      try {
        const { data } = await axios.delete(
          `${apiExpensesReport}/${type}/${id}`
        );

        return data;
      } catch (error) {
        console.error("Error deleteReport: ", new Error(error));
      }
    },
    async getWbReportsIds() {
      try {
        const { data } = await axios.get(`${apiExpensesWbReportIds}/`);

        return data;
      } catch (error) {
        console.error("Error getWbReportsIds: ", new Error(error));
      }
    },
    async getRelatedProducts() {
      try {
        const { data } = await axios.get(`${apiExpensesRelatedProducts}`);
        console.log('data',data);
        return data;
      } catch (error) {
        console.error("Error getWbReportsIds: ", new Error(error));
      }
    },
    async getWbReports({ commit }, reportId) {
      try {
        const { data } = await axios.get(
          `${apiExpensesWbReport}/report/${reportId}`
        );

        return data;
      } catch (error) {
        console.error("Error getWbReports: ", new Error(error));
      }
    },
    async getReportsList({ commit }, { month, year, cabinetId }) {
      try {
        const { data } = await axios.get(
          `${apiExpensesMonthly}/report/${month}/${year}?${queryString.stringify(
            { cabinet_id: cabinetId }
          )}`
        );

        return data;
      } catch (error) {
        console.error("Error getReportsList: ", new Error(error));
      }
    },
    async getReportByTypeAndId({ commit }, { type, id }) {
      try {
        const { data } = await axios.get(`${apiExpensesReport}/${type}/${id}`);

        return data;
      } catch (error) {
        console.error("Error getReportByTypeAndId: ", new Error(error));
      }
    },
  },
};
