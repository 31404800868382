<template>
  <div class="login__wrapper">
    <div class="login-box__wrapper">
      <router-view @next="next" />
    </div>
    <div class="auth__right-column">
      <h2 class="auth__right-header">ВОЗМОЖНОСТИ</h2>
      <swiper
        :slides-per-view="1"
        :space-between="30"
        :loop="false"
        ref="slider"
        :pagination="true"
        :navigation="true"
        class="auth__swiper"
      >
        <swiper-slide v-for="(item, index) of swiperData" :key="index">
          <div class="auth__right-slide-header">
            <h3 class="auth__right-title">{{ item.title }}</h3>
            <p class="auth__right-subtitle">{{ item.subtitle }}</p>
          </div>
          <img
            :src="getImageUrl(item.image)"
            width="600"
            height="400"
            class="img-fluid w-100 mx-auto"
            blank="true"
          />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script>
import { Navigation, Pagination } from 'swiper';
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2';
import 'swiper/swiper-bundle.css';

SwiperCore.use([Navigation, Pagination]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperData: [
        {
          title: 'Дашборд рекомендаций',
          subtitle:
            'Соберите всю самую ценную и актуальную информацию по вашему бизнесу на одном экране.',
          image: 'dashboard',
        },
        {
          title: 'Помощь нейросети',
          subtitle:
            'Оптимизируйте карточки товаров, определяйте цены, распределяйте по складам и получайте прогнозы с помощью нейросетей.',
          image: 'neuralink',
        },
        {
          title: 'Сводные показатели',
          subtitle: 'Получите предельно точную и подробную аналитику своего бизнеса.',
          image: 'analytics',
        },
        {
          title: 'Складские товары',
          subtitle:
            'Управляете товарами и документами своего склада, настраивая под свою бизнес-модель.',
          image: 'stockGoods',
        },
        {
          title: 'Автоответы на отзывы',
          subtitle:
            'Создавайте собственные сценарии ответов на отзывы или используйте автогенерацию ответов на основе искусственного интеллекта.',
          image: 'autoanswers',
        },
        // {
        //   title: 'Автобидер',
        //   subtitle: 'Создавайте автоматические рекламные компании на ВБ.',
        //   image: 'dashboard'
        // },
      ],
    };
  },
  methods: {
    next() {
      this.$refs.slider.$el.swiper.slideNext();
    },
    getImageUrl(index) {
      return require(`@/assets/img/auth/${index}.jpg`);
    },
  },
};
</script>

<style lang="scss">
.img-fluid {
  max-width: 100%;
  height: auto;
}
.w-100 {
  width: 100%;
}
.ml-auto,
.mx-auto {
  margin-left: auto;
}
.mr-auto,
.mx-auto {
  margin-right: auto;
}

.auth__right-column {
  height: 100%;
  background: #f2f5f8;
  width: 60%;
  padding: 0 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .swiper-container {
    width: 100%;
    padding-bottom: 55px;
  }

  .swiper-slide {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .swiper-pagination-bullet {
    background: #cdd2d8;
    opacity: 1;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 5px;
  }
  .swiper-pagination-bullet-active {
    background: #3a416f;
  }
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 9px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    top: auto;
    bottom: 7px;
    background: rgba(58, 65, 111, 0.2);
    border-radius: 12.8px;
    width: 24px;
    height: 24px;
    z-index: 19;
    &::after {
      font-size: 9px;
      color: #3a416f;
    }
  }
  .swiper-button-prev {
    left: 35%;
    transform: translate(-50%, 0);
  }
  .swiper-button-next {
    right: 35%;
    transform: translate(50%, 0);
  }
  .swiper-button-prev::after {
    margin-left: -2px;
  }
  .swiper-button-next::after {
    margin-right: -2px;
  }

  @media (max-width: 500px) {
    display: none;
  }
}

.login-box__wrapper {
  @media (max-width: 500px) {
    width: 100% !important;
  }
}

.v-application .auth__right {
  &-header {
    padding: 6px;
    background: rgba(58, 65, 111, 0.1);
    border-radius: 8px;
    font-weight: 800;
    font-size: 8px;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 5px;
    color: #3a416f;
  }
  &-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.4px;
    color: #3a416f;
    text-align: center;
    margin-bottom: 10px;
  }

  &-subtitle {
    font-weight: 600;
    text-align: center;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.4px;
    color: rgba(58, 65, 111, 0.6);
    margin-bottom: 30px;
  }
  &-slide-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: auto;
  }
}
.auth-right {
  &__wrap {
    text-align: center;
    width: 86%;
    margin: 0 auto;
  }
  &__image {
    max-width: 500px;
  }
  &__title {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.375;
    letter-spacing: -0.05rem;
    color: #fff;
  }
  &__description {
    line-height: 1.625;
    font-weight: 400;
    font-size: 1rem;
    color: #fff;
    margin-top: 8px;
  }
}
</style>
