<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="#E6EDF4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17 11.5C17 11.7761 16.7513 12 16.4444 12L7.55556 12C7.24873 12 7 11.7761 7 11.5C7 11.2239 7.24873 11 7.55556 11L16.4444 11C16.7513 11 17 11.2239 17 11.5Z" fill="#3A416F"/>
  </svg>
</template>

<script>
export default {
  name: 'MinusCircle',
}
</script>

<style lang="scss" scoped></style>
