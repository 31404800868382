const API = process.env.VUE_APP_URL;
const WSPath = process.env.VUE_APP_WS_URL;
const BIDDER_API = process.env.VUE_APP_BIDDER_API;
const SIDE_API = 'https://auth.mplab.io/';
const FIXER_API = 'http://data.fixer.io/api/';

const API_ENV = {
  /**
   * Get user Info
   */
  apiGetUsersInfo: `${API}account/users/me/`,

  // WebSockets links
  apiStageWebsocket: `${WSPath}customer_journey/list/`,
  apiReviewAnalysisWebsocket: `${WSPath}review-analysis/tasks/`,
  apiCabinetsWebsocket: `${WSPath}cabinet/list/`,
  apiSkynetWebsocket: `${WSPath}neural_skynet/forecast/list/`,
  apiSkynetRetrieveWebsocket: `${WSPath}neural_skynet/forecast/`,

  // Автоответы
  apiAlerts: `${API}alerts/`,

  /**
   * Login/Regisration request
   */

  apiLogin: `${API}account/token/`,
  apiRefresh: `${API}account/token/refresh/`,
  apiWbTokens: `${API}account/users/cabinet/`,
  apiCheckWbTokens: `${API}wb-data/WBToken/check_cookie/`,
  apiCheckStandartWbToken: `${API}wb-data/WBToken/check_standart_token/`,
  apiRegistration: `${API}account/users/register/`,
  apiConfirmReg: `${API}account/users/register/activation/`,
  apiSendCodeAgain: `${API}account/users/register/send-sms-again/`,
  apiCheckCode: `${API}account/users/check-code/`,
  apiRestorePhone: `${API}account/users/password/reset/`,
  apiGetCountries: `${API}account/auth/get_country_code/`,
  apiReferralRegistration: `${API}account/users/referral-link/`,

  /**
   * WB tokens for Setting
   */

  apiGetSymbankNum: `${API}account/users/cabinet/bot/activation/`,

  /**
   * Auto answers request
   */
  apiGETAutoAnserTemplate: `${API}autoanswers/auto-answers/`,
  apiPOSTAutoAnserTemplate: `${API}autoanswers/auto-answers/`,
  apiAutoanswers: `${API}autoanswers/`,

  /**
   * Delivery
   */
  apiGetDelivery: `${API}delivery/`,
  apiExportDelivery: `${API}delivery/export`,

  /**
   * PVZ
   */
  apiGETAllPVZ: `${API}pvz/wildberries/all/`,
  apiGETUsersPVZ: `${API}pvz`,
  apiPOSTPVZ: `${API}pvz/`,
  apiDELETEPVZ: `${API}pvz/`,

  // Referral
  apiReferral: `${API}referral/`,

  // Referral
  apiPersonalRoom: `${API}personal-room/`,

  // SeoGroupComparison
  apiSeoGroupComparison: `${API}seo/sku-group-comparison/`,

  // ProductCard
  apiProductCard: `${API}seo/product-card/`,
  apiSeoFrequency: `${API}seo/frequency/`,

  // WbCard
  apiWbCard: `${API}wb_card/`,
  apiRemoveWbCard: `${API}`,
  apiCharcsbyobjectname: `${API}wb_card/char/`,
  apiFieldsSuggestions: `${API}wb_card/char/suggest/`,

  // WBApis
  apiBarcodes: 'https://suppliers-api.wildberries.ru/content/v1/barcodes',
  apiCardFilter: 'https://suppliers-api.wildberries.ru/content/v1/cards/filter',
  apiCharcsForSubCategory:
    'https://suppliers-api.wildberries.ru/content/v1/object/characteristics/list/filter',
  apiCharcsForCategory: 'https://suppliers-api.wildberries.ru/content/v1/object/characteristics/',
  apiDirectoryColor: 'https://suppliers-api.wildberries.ru/content/v1/directory/colors',
  apiContentCard: 'https://suppliers-api.wildberries.ru/content/v1/object/all',
  apiDirectoryKinds: 'https://suppliers-api.wildberries.ru/content/v1/directory/kinds',
  apiCountries: 'https://suppliers-api.wildberries.ru/content/v1/directory/countries',
  apiCollections: 'https://suppliers-api.wildberries.ru/content/v1/directory/collections',
  apiSeasons: 'https://suppliers-api.wildberries.ru/content/v1/directory/seasons',
  apiContents: 'https://suppliers-api.wildberries.ru/content/v1/directory/contents',
  apiBrands: 'https://suppliers-api.wildberries.ru/content/v1/directory/brands',

  apiGenerateDescription: `${API}wb_card/openai_description/`,

  // Reviews
  apiReviews: `${API}reviews/`,

  // Positions
  apiPositions: `${API}seo/positions/`,

  // Collection Requests
  apiCollectionRequests: `${API}seo/selection-requests/`,
  apiCollectionRequestsExport: `${API}seo/selection-requests-export/`,
  apiCollectionRequestsClusters: `${API}seo/selection-requests-normqueries/`,

  // ActualBids
  apiActualBids: `${API}actual-bids/`,

  // Services
  apiServices: `${API}marketing-services/`,

  /**
   * Rates - Тарифы
   */
  // Получение тарифов
  apiRates: `${API}billing/tariffs/`,
  // Оплата тарифов
  apiCreateOrder: `${API}billing/tariffs/orders/`,
  apiProlongateOrder: `${API}billing/tariffs/prolongation/`,
  apiCalculateOrder: `${API}billing/tariffs/orders/calculated/`,
  apiExtraFreeTariff: `${API}billing/tariffs/free-tariff/prolongation/`,
  // Перед страницей покупки
  apiPreorderPay: `${API}billing/tariffs/pay/`,
  apiUsePromocode: `${API}billing/tariffs/promocode/`,
  apiSendOrder: `${API}billing/tariffs/pay/payment-link/`,
  // Эндпоиниты после робокасы
  apiPaySuccess: `${API}billing/tariffs/pay/success/`,
  apiPayError: `${API}billing/tariffs/pay/error/`,
  apiGetInvoice: `${API}billing/get-invoice/`,
  apiGetLimits: `${API}billing/limits/new_additional`,
  apiGetPermissions: `${API}billing/get_permissions/`,

  /**
   * Ransoms - выкупы
   */
  apiGetUserGroups: `${API}buyouts/groups/`,
  apiCreateUserGroups: `${API}buyouts/`,
  apiRemoveGroups: `${API}buyouts/groups/`,
  apiCheckSKU: `${API}buyouts/sku-to-buyout/`,
  apiGetGroupInfo: `${API}buyouts/groups/`,
  apiRemoveGroupItem: `${API}buyouts/`,
  apiEditGroupItem: `${API}buyouts/`,
  apiCreateBuyoutItemOrder: `${API}buyouts/`,
  apiGetLastUserGroup: `${API}buyouts/groups/last-group/`,
  apiCheckPayout: `${API}buyouts/`,
  apiBadPayed: `${API}buyouts/bad-payed/set/`,
  apiGetEditPVZ: `${API}buyouts/`,
  apiPrivateStart: `${API}buyouts/private/start`,

  /**
   * AutoPay - Автовыкупы
   */
  apiAutoPay: `${API}auto-payment/`,
  apiAutoPayClear: `${API}auto-payment/clear/`,

  /**
   * Questions - Вопросы
   */
  apiGetQuestions: `${API}questions/`,
  apiCreateQuestion: `${API}questions/`,
  apiRemoveQuestion: `${API}questions/`,

  /**
   * Likes to Reviews - Лайки на отзывы
   */
  apiGetLikesReviews: `${API}likes-on-review/`,
  apiRemoveLikesReviews: `${API}likes-on-review/`,
  apiCreateTaskLikesReviews: `${API}likes-on-review/create-task/`,
  apiShowMoreLikesReviews: `${API}likes-on-review/show-more/`,
  apiPrimaryLikesReviews: `${API}likes-on-review/primary-reviews/`,

  /**
   * Favorits - Избранное
   */
  apiGetFavorits: `${API}favorite/`,
  apiCreateFavorits: `${API}favorite/`,
  apiDeleteFavorits: `${API}favorite/`,

  /**
   * Payment - Настройки оплаты
   */
  apiGetPayment: `${API}client-card/`,
  apiAddPayment: `${API}client-card/`,
  apiRemovePayment: `${API}client-card/`,

  apiYoomoney: `${API}yoomoney/`,

  // Keywords List
  apiKeywordsList: `${API}keywords-list/`,
  apiKeywordsListDetail: `${API}keywords-list/detail-info/`,
  /**
   * Reviews Analysis - Анализ отзывов
   */
  apiGetAllTask: `${API}review-analysis/tasks/`,

  apiCurrentAnalysis: `${API}review-analysis/task-detail/`,
  apiCreateAnalysis: `${API}review-analysis/tasks/`,
  apiGetCurrentInfoTask: `${API}review/review_analisys`,

  apiRemoveAnalysis: `${API}review-analysis/task-detail/`,

  apiExportAnalysis: `${API}review-analysis/export-task/`,

  apiAdvertisingCabinet: `${API}advertising-cabinet/actions/`,
  apiAdvertisingCabinetAdverts: `${API}advertising-cabinet/adverts`,
  apiSupplierSubject: `${API}advertising-cabinet/subjects/`,

  /**
   * External Analytics - Внешняя аналитика
   */
  apiGetGoodsList: `${SIDE_API}product_list`,
  apiGetGoodsListChart: `${SIDE_API}product_chart`,
  apiGetCategoryChart: `${SIDE_API}category_chart`,

  //filters
  //  apiGetActiveCategory: `${SIDE_API}filter`,
  apiGetFilterBrand: `${SIDE_API}brand`,
  apiGetFilterStore: `${SIDE_API}store`,

  //Good
  apiGetGoodInfo: `${SIDE_API}product_info`,
  apiGetGoodFeeds: `${SIDE_API}product_feeds`,
  apiGetGoodDaysInfo: `${SIDE_API}product_info_for_period`,
  apiGetProductContent: `${SIDE_API}product_content`,

  //Category
  apiGetCategory: `${SIDE_API}category`,
  apiGetCategoryInfo: `${SIDE_API}category_stat`,
  apiGetStoreInfo: `${SIDE_API}store_stat`,
  apiGetBrandInfo: `${SIDE_API}brand_stat`,

  //Store
  apiGetShopsInfo: `${SIDE_API}store_stat`,

  //Brands
  apiGetBrandsInfo: `${SIDE_API}brand_stat`,

  /**
   * PriceAnalysis
   */

  apiSendFile: `${API}analys/price_change/`,
  apiSendFileToWb: `${API}analys/send-to-wildberries/`,
  // unit calculator
  apiUnitCalculator: `${API}calculator/`,
  apiUnitCategories: `${API}categories/`,
  apiUnitCategoriesMerch: `${API}categories/merch`,
  // Goods
  apiWarehouseProduct: `${API}warehouse/product/`,
  apiWarehouseCategories: `${API}warehouse/product_category/`,
  apiGetProductFromWb: `${API}warehouse/load_wb_products_from_wb/`,
  apiSetEmptyCategory: `${API}warehouse/set_wb_categories_to_wh_products/`,
  apiGetWBCards: `${API}warehouse/get_wb_product_cards/`,

  // Stock
  apiStockList: `${API}warehouse/warehouse/`,
  apiStockTotalCost: `${API}warehouse/warehouses/total_cost`,
  apiSetCategoriesFromWb: `${API}warehouse/load_categories_from_wb/`,
  apiLoadCategoriesFromWb: `${API}warehouse/load_categories_from_wb/`,

  apiWarehouseForDoc: `${API}warehouse/warehouse/`,
  apiWarehouseDocument: `${API}warehouse/document/`,
  apiWarehouseDocumentCategory: `${API}warehouse/document_category/`,

  // Expenses
  apiProductParameters: `${API}expenses/products/list/`,
  apiExpenseType: `${API}expenses/types/by_product/`,
  apiUpdateSales: `${API}expenses/update-all-incomes/`,
  apiExpensesCategory: `${API}expenses/category`,
  apiExpensesReport: `${API}expenses`,
  apiExpensesMonthly: `${API}expenses/by_month`,
  apiExpensesWbReport: `${API}expenses/by_wbreport`,
  apiExpensesWbReportIds: `${API}expenses/get_wb_reports_ids`,
  apiExpensesRelatedProducts: `${API}internal_analytics/get_wb_products_from_account/`,

  apiStockPlanning: `${API}planning/purchase`,
  apiStockDelivery: `${API}planning/delivery`,
  apiStockPlaningUpload: `${API}planning/excel/upload/`,

  apiAllActiveCabinets: `${API}internal_analytics/get_all_active_cabinets/`,
  apiCabinetsWhithoutExcel: `${API}internal_analytics/get_cabinets_without_excel_file/`,
  apiUserBrandsForPlanning: `${API}internal_analytics/get_wb_brands_from_account/`,
  apiUserCategoriesForPlanning: `${API}internal_analytics/get_wb_categories_from_account/`,
  apiUserCardsForPlanning: `${API}internal_analytics/get_wb_products_from_account/`,
  apiWbStocksForPlanning: `${API}internal_analytics/get_wb_stock_names/`,
  apiMinimumDateForPurchePlanning: `${API}internal_analytics/get_minimum_date_for_purchase_planning/`,
  apiGetLastDateUploading: `${API}internal_analytics/get_last_date_excel_uploading/`,
  apiStocksAndRegions: `${API}internal_analytics/get_wb_stock_names_for_wh_create/`,
  apiPercentageByOkrug: `${API}internal_analytics/get_percentage_by_okrug/`,
  apiTopProducts: `${API}internal_analytics/get_top_products/`,
  apiDownloadOrderProducts: `${API}internal_analytics/excel/download_order_products`,
  apiDownloadRefundProducts: `${API}internal_analytics/excel/download_refund_products`,
  apiDownloadSellProducts: `${API}internal_analytics/excel/download_sell_products`,
  apiWarehouseAcceptance: `${API}internal_analytics/get_warehouse_acceptance/`,
  apiGetInfoProduct: `${API}internal_analytics/get_wb_product/`,

  // Analytics
  apiIAnalytics: `${API}analytics/get_data/`,

  // Sales and orders
  apiSalesAndOrders: `${API}geography_sales_orders/`,

  apiGeoDiagramList: `${API}geography_sales_orders/geo/diagram/list/`,
  apiGeoMapList: `${API}geography_sales_orders/geo/map/list/`,
  apiGeoOrdersList: `${API}geography_sales_orders/geo/orders/list/`,
  apiGeoSalesList: `${API}geography_sales_orders/geo/sales/list/`,
  //API FOR CONVERTER
  apiConverter: `${FIXER_API}latest`,

  // Wb Cards
  apiWbProductCard: `${API}wb-product-cards/`,
  apiDownloadSupply: `${API}planning/excel/download_deliver_planning/`,
  apiDownloadProcurement: `${API}planning/excel/download_from_wb/`,

  apiPlanningDeliveryAvailability: `${API}planning/delivery/main/availability/`,
  apiPlanningDeliveryDistribution: `${API}planning/delivery/main/distribution/`,
  apiPlanningDeliverySummary: `${API}planning/delivery/main/summary/`,
  apiPlanningDeliveryQuantity: `${API}planning/delivery/main/quantity/`,
  apiPlanningDeliverySummaryByWarehouse: `${API}planning/delivery/main/summary/by_warehouse/`,
  apiPlanningDeliveryTurnoverDynamics: `${API}planning/delivery/main/turnover_dynamics/`,
  apiPlanningTotalCost: `${API}planning/delivery/main/total_cost`,
  apiPlanningWbStocks: `${API}planning/get-all-wb-stock-history-products`,

  // Repricer
  apiRepricer: `${API}repricer/`,

  // Stage
  apiGetStage: `${API}customer_journey/list/`,

  // Dashboard
  apiDashboardAnalytics: `${API}analytics/get_analytics/`,
  apiRegionsOrdersDynamic: `${API}analytics/order_count_change/`,
  apiDashboardReputation: `${API}analytics/get_reputations/`,
  apiDashboardCardsImages: `${API}analytics/images_and_video_count/`,
  apiDashboardPromotions: `${API}analytics/get_promotions/`,
  apiDashboardSeoValues: `${API}analytics/get_seo_core/`,
  apiDashboardBadReviewsReasons: `${API}analytics/get_reason_bad_review/`,
  apiDashboardPredictedSales: `${API}analytics/get_predicted_sales/`,
  apiDashboardNmIdsForAI: `${API}analytics/get_nm_ids_for_seo/`,
  apiDashboardAddNmIdsForAI: `${API}analytics/nm_ids_for_AI/`,

  // AI Products
  apiGetProducts: `${API}neural_skynet/products/list/`,
  apiGetProductsList: `${API}neural_skynet/forecast/list/`,
  apiGetProduct: `${API}neural_skynet/forecast/`,
  apiCreateProduct: `${API}neural_skynet/forecast/create/`,
  apiProductPhoto: `${API}neural_skynet/user_photos/`,
  apiGetAIStockList: `${API}neural_skynet/stocks/list/`,
  apiPathProduct: `${API}neural_skynet/forecast/`,

  // Reporting
  apiReportingBalance: `${API}financial_reports/balance/`,
  apiReportingDds: `${API}financial_reports/cash_flow/`,

  // External Ads

  apiSendClick: `${API}traffic_analytics/`,
  apiReportingOpiu: `${API}financial_reports/opiu/`,
  apiReportingReceipts: `${API}financial_reports/receipts/`,

  // External Ads

  apiExternalUrlsList: `${API}traffic_analytics/urls/`,
  apiExternalAuditorium: `${API}traffic_analytics/urls/auditorium/`,
  apiExternalConversions: `${API}traffic_analytics/urls/conversions/`,
  apiExternalDownload: `${API}traffic_analytics/urls/reports/`,
  apiGetUsersProducts: `${API}internal_analytics/get_active_wb_products_from_account/`,

  // AB Test
  apiABTest: `${API}advertising-cabinet/ab-tests/`,
  apiABTestVariants: `${API}advertising-cabinet/ab-tests-variants/`,
  apiABTestsCampaigns: `${API}advertising-cabinet/ab-tests/get-all-campaigns/`,

  // Excel import
  apiExcelImportWarehouseProducts: `${API}excel_import/warehouse_product_count/`,
  apiExcelImportPurchasePrice: `${API}excel_import/purchase_price/`,
  apiExcelImportDocumentProduct: `${API}excel_import/document_product_count/`,

  // Ad Campaign
  apiAdCampaign: `${BIDDER_API}advertising/`,
  apiAdCampaignFinance: `${BIDDER_API}finance/`,
  apiAdCampaignCategories: `${BIDDER_API}wb/categories/`,
  apiAdStats: `${BIDDER_API}statistics/campaign/`,

  // AI Generashion
  apiAiGenerationData: `${API}neural_skynet/generate_description_with_photo/`,
  apiAiGenerationHistory: `${API}neural_skynet/get_generation_history/`,
};

export default API_ENV;
