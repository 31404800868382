const Module = () => import('./Module.vue');
const DashboardPage = () => import('./views/DashboardPage.vue');
const HomePage = () => import('./views/homePage.vue');

const mainRoutes = {
  path: '',
  children: [
    {
      path: '/study',
      name: 'home',
      component: HomePage,
      breadName: 'Главная',
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: DashboardPage,
      breadName: 'Дашборд рекомендаций',
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/',
      name: 'dashboard',
      component: DashboardPage,
      breadName: 'Дашборд рекомендаций',
      meta: {
        requiresAuth: true,
      },
    },
  ],
  component: Module,
};

export default mainRoutes;
