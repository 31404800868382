<template>
  <header class="header">
    <router-link to="/" class="header__logo">
      <img src="@/assets/img/logo.png" alt="Logo" />
    </router-link>
  </header>
</template>
<script>
export default {
  name: "ELHeader",
};
</script>
<style lang="scss" scoped>
.header {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  &__logo {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 5px;
    img {
      width: 200px;
    }
  }
}
</style>
