<template>
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M12 8V16M16 15V17C16 19.2091 14.2091 21 12 21C9.79086 21 8 19.2091 8 17V15M16 9V7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7V9" stroke="#3A416F" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
</template>

<script>
	export default {
		name: 'LinkIcon',
	}
</script>

<style lang="scss" scoped></style>
