<template>
  <section class="land">
    <ELHeader />
    <ELBody />
  </section>
</template>
<script>
import ELBody from '../components/landing/ELBody.vue';
import ELHeader from '../components/landing/ELHeader.vue';
export default {
  components: { ELHeader, ELBody },
  name: 'ExternalLanding',
};
</script>
<style lang="scss" scoped>
.land {
  background: #f3f5f8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
