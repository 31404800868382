<template>
	<svg width="38" height="10" viewBox="0 0 38 10" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M3.67367 0.30242C4.00387 0.300538 4.98519 0.216364 5.40086 1.55245C5.68086 2.45245 6.12688 3.92669 6.73893 5.97517H6.98819C7.64456 3.81552 8.09545 2.34127 8.34086 1.55245C8.76086 0.202446 9.81086 0.302446 10.2309 0.302446L13.4713 0.30245V9.90243H10.1686V4.245H9.94709L8.10599 9.90243H5.62113L3.78003 4.24081H3.55856V9.90243H0.255859V0.30245L3.67367 0.30242ZM18.2139 0.30245V5.96409H18.4773L20.7169 1.30856C21.1516 0.382101 22.0781 0.30245 22.0781 0.30245H25.2742V9.90245H21.9025V4.24081H21.639L19.4434 8.89633C19.0087 9.8186 18.0382 9.90245 18.0382 9.90245H14.8422V0.30245H18.2139ZM36.9282 4.86443C36.4579 6.13373 34.9809 7.04276 33.3457 7.04276H29.8098V9.90245H26.6036V4.86443H36.9282Z" fill="white"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M33.5005 0.302734H26.4355C26.6037 2.44002 28.5363 4.26967 30.5369 4.26967H37.1511C37.5328 2.49334 36.2188 0.302734 33.5005 0.302734Z" fill="url(#paint0_linear_4719_36091)"/>
		<defs>
		<linearGradient id="paint0_linear_4719_36091" x1="1104.48" y1="249.84" x2="26.4355" y2="249.84" gradientUnits="userSpaceOnUse">
		<stop stop-color="#868686"/>
		<stop offset="1" stop-color="#A8A8A8"/>
		</linearGradient>
		</defs>
	</svg>
</template>

<script>
export default {
	name: 'MIRCardLogoLight',
};
</script>
