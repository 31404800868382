<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="width"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M14.3496 15H12.2871L11.6895 13.1309H8.70117L8.10938 15H6.05859L9.11719 6.59766H11.3613L14.3496 15ZM11.2559 11.6777L10.3535 8.85352C10.2871 8.64258 10.2402 8.39062 10.2129 8.09766H10.166C10.1465 8.34375 10.0977 8.58789 10.0195 8.83008L9.10547 11.6777H11.2559ZM17.9941 6.59766V8.0625H17.291V13.5352H17.9941V15H14.6953V13.5352H15.3984V8.0625H14.6953V6.59766H17.9941Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'AI',
  props: {
    color: {
      type: String,
      default: '#3a416f',
    },
    width: {
      type: Number | String,
      default: 24,
    },
  },
};
</script>
