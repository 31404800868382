<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
		<g clip-path="url(#clip0_1154_16393)">
			<path
				d="M15.9734 9.06625L14.7589 2.17825C14.5938 1.2424 13.698 0.615226 12.7623 0.780268L2.34555 2.61706C1.40967 2.78207 0.782529 3.67769 0.947539 4.61354L1.03678 5.11973C0.427284 5.38561 0 5.99372 0 6.70008V13.5223C0 14.4726 0.773129 15.2457 1.72343 15.2457H12.064C13.0143 15.2457 13.7874 14.4726 13.7874 13.5223V11.2017L14.5754 11.0627C15.0287 10.9828 15.4238 10.7311 15.6878 10.354C15.9519 9.97691 16.0533 9.51954 15.9734 9.06625ZM14.9178 9.81481C14.7978 9.98621 14.6182 10.1006 14.4121 10.137L13.7874 10.2471V9.71134V7.57899V6.70005C13.7874 5.74975 13.0143 4.97662 12.064 4.97662H1.96615L1.87333 4.45029C1.83698 4.24423 1.88311 4.03635 2.00312 3.86492C2.12313 3.69352 2.30275 3.57911 2.50881 3.54276L12.9256 1.706C12.9714 1.69791 13.017 1.69403 13.062 1.69403C13.4348 1.69403 13.7662 1.96188 13.8331 2.34144L15.0476 9.22944C15.0839 9.43553 15.0378 9.64341 14.9178 9.81481ZM12.064 14.3056H1.72343C1.29147 14.3056 0.94005 13.9542 0.94005 13.5223V10.1814H12.8474V13.5222C12.8474 13.9542 12.4959 14.3056 12.064 14.3056ZM1.72343 5.9167H12.064C12.4959 5.9167 12.8474 6.26813 12.8474 6.70008V7.109H0.94005V6.70008C0.94005 6.26813 1.29147 5.9167 1.72343 5.9167ZM12.8474 8.04905V9.24135H0.94005V8.04905H12.8474Z"
				fill="#3A416F" />
			<path
				d="M4.52327 12.418H2.39092C2.13134 12.418 1.9209 12.6284 1.9209 12.888C1.9209 13.1476 2.13134 13.358 2.39092 13.358H4.52327C4.78285 13.358 4.9933 13.1476 4.9933 12.888C4.9933 12.6284 4.78285 12.418 4.52327 12.418Z"
				fill="#3A416F" />
		</g>
		<defs>
			<clipPath id="clip0_1154_16393">
				<rect width="16" height="16" fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>

<script>
export default {
	name: 'WalletIcon'
}
</script>

<style lang="scss" scoped></style>
